import { Grid } from "@mui/material";
import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { LogOut, LogIn } from "react-feather";
import { useParams, useRouteMatch } from "react-router-dom";

import {
  FIELD_INPUT_FIELDS,
  FIELD_INPUT_TYPE,
  FIELD_OUTPUT_FIELDS,
  FIELD_OUTPUT_TYPE,
} from "libs/constants/fields";
import { usePipelinesGet } from "libs/data/endpoints/pipelines/pipelines";
import { useGoogleAnalytics } from "libs/hooks";

import { InputCard } from "components/molecules";

const items = [
  {
    label: "Input",
    type: FIELD_INPUT_TYPE,
    fields: FIELD_INPUT_FIELDS,
    Icon: LogIn,
  },
  {
    label: "Output",
    type: FIELD_OUTPUT_TYPE,
    fields: FIELD_OUTPUT_FIELDS,
    Icon: LogOut,
  },
];

const PipelineConfiguration = () => {
  useGoogleAnalytics();

  const match = useRouteMatch();
  const { projectName, pipelineName } = useParams();

  const { data: pipeline } = usePipelinesGet(projectName, pipelineName);

  return (
    <Grid container spacing={2}>
      <BreadcrumbsItem to={match.url}>Input</BreadcrumbsItem>
      {pipeline &&
        items.map((item, key) => (
          <Grid key={key} item xs={6}>
            <InputCard
              item={item}
              type={pipeline[item.type]}
              fields={pipeline[item.fields]}
            />
          </Grid>
        ))}
    </Grid>
  );
};

export default PipelineConfiguration;
