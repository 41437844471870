import { useParams } from "react-router-dom";

import { PageHeader } from "components/molecules/PageLayout";
import { useInstanceTypeGroupsGet } from "libs/data/endpoints/instances/instances";

import { FormWrapper, Loader } from "components/atoms";
import { PageContainer } from "components/molecules";

import { useEditInstanceTypeGroup } from "./hooks/useEditInstanceTypeGroup";
import { InstanceTypeGroupBaseForm } from "../InstanceTypeGroupBaseForm";

export const InstanceTypeGroupEdit = () => {
  const { organizationName, projectName, groupId } =
    useParams<{
      organizationName: string;
      projectName: string;
      groupId: string;
    }>();

  const { data, isValidating } = useInstanceTypeGroupsGet(projectName, groupId);

  const handleOnSubmit = useEditInstanceTypeGroup(
    organizationName,
    projectName,
    groupId
  );

  const initialValues = {
    name: data?.name as string,
    instance_types:
      data?.instance_types?.map(({ id, priority }) => ({
        id,
        priority,
      })) ?? [],
  };

  return (
    <PageContainer>
      <PageHeader title="Edit instance type group" />
      {isValidating && <Loader />}
      {!isValidating && initialValues?.name && (
        <FormWrapper>
          <InstanceTypeGroupBaseForm
            initialValues={initialValues}
            onSubmit={handleOnSubmit}
            submitLabel="Save"
          />
        </FormWrapper>
      )}
    </PageContainer>
  );
};
