/**
 * Generated by orval 🍺
 * Do not edit manually.
 * UbiOps
 * OpenAPI spec version: v2.1
 */
import useSwr from "swr";

import { orvalAxios } from "../../axios/index";

import type { ErrorType } from "../../axios/index";
import type {
  DeploymentList,
  DeploymentsListParams,
  DeploymentCreateResponse,
  DeploymentCreate,
  DeploymentDetail,
  DeploymentUpdate,
  AuditList,
  DeploymentAuditEventsListParams,
  InheritedEnvironmentVariableList,
  EnvironmentVariableCopyBody,
  EnvironmentVariableList,
  EnvironmentVariableCreateBody,
  DeploymentVersionList,
  DeploymentVersionsListParams,
  DeploymentVersionDetail,
  DeploymentVersionCreate,
  DeploymentVersionUpdate,
  Activity,
  RevisionList,
  RevisionCreate,
  RevisionsFileUploadBody,
  RevisionRebuild,
  TemplateDeploymentList,
} from "../../models";
import type { SWRConfiguration, Key } from "swr";

// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * 
### Description
Deployments can be filtered according to the labels they have by giving labels as a query parameter. Deployments that have at least one of the labels on which is filtered, are returned.

### Optional Parameters
- `labels`: Filter on labels of the deployment. Should be given in the format 'label:label_value'. Separate multiple label-pairs with a comma (,). This parameter should be given as query parameter.

### Response Structure
A list of details of the deployments in the project
- `id`: Unique identifier for the deployment (UUID)
- `name`: Name of the deployment
- `project`: Project name in which the deployment is defined
- `input_type`: Type of the input of the deployment
- `output_type`: Type of the output of the deployment
- `input_fields`: The list of deployment input fields containing name and data_type. It is empty in case of plain input type deployments.
- `output_fields`: The list of deployment output fields containing name and data_type. It is empty in case of plain output type deployments.
- `description`: Description of the deployment
- `labels`: Dictionary containing key/value pairs where key indicates the label and value is the corresponding value of that label
- `creation_date`: The date when the deployment was created
- `last_updated`: The date when the deployment was last updated

#### Response Examples
```
[
  {
    "id": "903ccd12-81d1-46e1-9ac9-b9d70af118de",
    "name": "deployment-1",
    "project": "project-1",
    "description": "Temperature deployment",
    "input_type": "structured",
    "output_type": "structured",
    "input_fields": [
      {
        "name": "input-field-1",
        "data_type": "int"
      },
      {
        "name": "input-field-2",
        "data_type": "double"
      }
    ],
    "output_fields": [
      {
        "name": "output-field",
        "data_type": "double"
      }
    ],
    "labels": {
      "type": "deployment"
    },
    "creation_date": "2020-05-12T16:23:15.456812Z",
    "last_updated": "2020-06-22T18:04:76.123754Z"
  },
  {
    "id": "5f4e942f-d5b8-4d62-99b2-870c15a82127",
    "name": "deployment-2",
    "project": "project-1",
    "description": "Deployment two",
    "input_type": "structured",
    "output_type": "plain",
    "input_fields": [
      {
        "name": "input-field",
        "data_type": "int"
      }
    ],
    "output_fields": [],
    "labels": {
      "type": "deployment"
    },
    "creation_date": "2020-03-24T09:43:51.791253Z",
    "last_updated": "2020-05-19T11:52:21.163270Z"
  },
  {
    "id": "bd3fae9d-aeec-4cf3-8ef0-5f9224d41904",
    "name": "deployment-3",
    "description": "",
    "project": "project-1",
    "input_type": "plain",
    "output_type": "plain",
    "input_fields": [],
    "output_fields": [],
    "creation_date": "2020-06-18T08:32:14.876451Z",
    "last_updated": "2020-06-19T10:52:23.124784Z"
  }
]
```

 * @summary List deployments
 */
export const deploymentsList = (
  projectName: string,
  params?: DeploymentsListParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<DeploymentList[]>(
    { url: `/projects/${projectName}/deployments`, method: "get", params },
    options
  );
};

export const getDeploymentsListKey = (
  projectName: string,
  params?: DeploymentsListParams
) => [`/projects/${projectName}/deployments`, ...(params ? [params] : [])];

export type DeploymentsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof deploymentsList>>
>;
export type DeploymentsListQueryError = ErrorType<unknown>;

export const useDeploymentsList = <TError = ErrorType<unknown>>(
  projectName: string,
  params?: DeploymentsListParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof deploymentsList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!projectName;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getDeploymentsListKey(projectName, params) : null));
  const swrFn = () => deploymentsList(projectName, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Create a deployment by defining the input/output type and input/output fields. In case of **plain** type of input or output, input and output fields should not be given or passed as an empty list.

Possible data types for the input and output fields are:

- **int**: integer
- **string**: string
- **double**: double precision floating point
- **bool**: boolean value (True/False)
- **dict**: Python dictionary
- **file**: a file. This type of field can be used to pass files to the deployment. In deployment and pipeline requests, the path to the file in the bucket must be provided for this field.
- **array_int**: an array of integers
- **array_double**: an array of double precision floating points
- **array_string**: an array of strings
- **array_file**: an array of files

Possible widgets for the input fields are:

- **textbox**: textbox
- **numberbox**: numberbox
- **slider**: slider
- **dropdown**: dropdown
- **switch**: switch
- **button**: upload button
- **drawer**: drawer
- **image_preview**: image upload with preview

Possible widgets for the output fields are:

- **textbox**: textbox
- **button**: download button
- **image_preview**: image preview

### Required Parameters
- `name`: Name of the deployment. It is unique within a project.
- `input_type`: Type of the input of the deployment. It can be either structured or plain.
- `output_type`: Type of the output of the deployment. It can be either structured or plain.
- `input_fields`: The list of required deployment input fields. It must contain the fields: name and data_type, and it may contain the field: widget. The name of an input field is unique for a deployment.
- `output_fields`: The list of required deployment output fields. It must contain the fields: name and data_type, and it may contain the field: widget. The name of an output field is unique for a deployment.

### Optional Parameters
- `description`: Description of the deployment
- `labels`: Dictionary containing key/value pairs where key indicates the label and value is the corresponding value of that label

#### Request Examples
A deployment with structured input and output type
```
{
  "name": "deployment-1",
  "input_type": "structured",
  "output_type": "structured",
  "input_fields": [
    {
      "name": "input-field-1",
      "data_type": "int"
    },
    {
      "name": "input-field-2",
      "data_type": "double"
    }
  ],
  "output_fields": [
    {
      "name": "output-field",
      "data_type": "double"
    }
  ]
}
```

A deployment with plain input type
```
{
  "name": "deployment-1",
  "description": "Deployment one"
  "input_type": "plain",
  "output_type": "structured",
  "output_fields": [
    {
      "name": "output-field",
      "data_type": "double"
    }
  ]
}
```

A deployment with plain input and output type
```
{
  "name": "deployment-1",
  "input_type": "plain",
  "output_type": "plain"
  "labels": {
    "type": "deployment"
  }
}
```

A deployment with structured input and output type and field widgets
```
{
  "name": "deployment-1",
  "input_type": "structured",
  "output_type": "structured",
  "input_fields": [
    {
      "name": "input-field-1",
      "data_type": "int",
      "widget": {
        "type": "slider",
        "configuration": {"min": 0, "max": 10, "default": 4, "step": 2}
      }
    },
    {
      "name": "input-field-2",
      "data_type": "double",
      "widget": {
        "type": "numberbox",
        "configuration": {"min": 0, "max": 1, "default": 0.5, "step": 0.1}
      }
    }
  ],
  "output_fields": [
    {
      "name": "output-field",
      "data_type": "double",
      "widget": {
        "type": "textbox",
        "configuration": {}
      }
    }
  ]
}
```

### Response Structure
Details of the created deployment
- `id`: Unique identifier for the deployment (UUID)
- `name`: Name of the deployment
- `project`: Project name in which the deployment is created
- `input_type`: Type of the input of the deployment
- `output_type`: Type of the output of the deployment
- `input_fields`: The list of deployment input fields containing name and data_type
- `output_fields`: The list of deployment output fields containing name and data_type
- `description`: Description of the deployment
- `labels`: Dictionary containing key/value pairs where key indicates the label and value is the corresponding value of that label
- `creation_date`: The date when the deployment was created
- `last_updated`: The date when the deployment was last updated

#### Response Examples
```
{
  "id": "903ccd12-81d1-46e1-9ac9-b9d70af118de",
  "name": "deployment-1",
  "project": "project-1",
  "description": "",
  "input_type": "structured",
  "output_type": "structured",
  "input_fields": [
    {
      "name": "input-field-1",
      "data_type": "int",
      "widget": {}
    },
    {
      "name": "input-field-2",
      "data_type": "double",
      "widget": {}
    }
  ],
  "output_fields": [
    {
      "name": "output-field",
      "data_type": "double",
      "widget": {}
    }
  ],
  "labels": {
    "type": "deployment"
  },
  "creation_date": "2020-06-18T08:32:14.876451Z",
  "last_updated": "2020-06-18T08:32:14.876451Z"
}
```

 * @summary Create deployments
 */
export const deploymentsCreate = (
  projectName: string,
  deploymentCreate: DeploymentCreate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<DeploymentCreateResponse>(
    {
      url: `/projects/${projectName}/deployments`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: deploymentCreate,
    },
    options
  );
};

/**
 * 
### Description
Retrieve details of a single deployment in a project

### Response Structure
Details of a deployment
- `id`: Unique identifier for the deployment (UUID)
- `name`: Name of the deployment
- `project`: Project name in which the deployment is defined
- `input_type`: Type of the input of the deployment
- `output_type`: Type of the output of the deployment
- `input_fields`: The list of deployment input fields containing name, data_type and widget
- `output_fields`: The list of deployment output fields containing name, data_type and widget
- `description`: Description of the deployment
- `labels`: Dictionary containing key/value pairs where key indicates the label and value is the corresponding value of that label
- `creation_date`: The date when the deployment was created
- `last_updated`: The date when the deployment was last updated
- `default_version`: Default version of the deployment.  If it does not have a default version, it is not set.

#### Response Examples
```
{
  "id": "903ccd12-81d1-46e1-9ac9-b9d70af118de",
  "name": "deployment-1",
  "project": "project-1",
  "description": "",
  "input_type": "structured",
  "output_type": "structured",
  "input_fields": [
    {
      "name": "input-field-1",
      "data_type": "int",
      "widget": {}
    },
    {
      "name": "input-field-2",
      "data_type": "double",
      "widget": {}
    }
  ],
  "output_fields": [
    {
      "name": "output-field",
      "data_type": "double",
      "widget": {}
    }
  ],
  "labels": {
    "type": "deployment"
  },
  "creation_date": "2020-06-18T08:32:14.876451Z",
  "last_updated": "2020-06-19T10:52:23.124784Z",
  "default_version": "v1"
}
```

 * @summary Get details of a deployment
 */
export const deploymentsGet = (
  projectName: string,
  deploymentName: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<DeploymentDetail>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}`,
      method: "get",
    },
    options
  );
};

export const getDeploymentsGetKey = (
  projectName: string,
  deploymentName: string
) => [`/projects/${projectName}/deployments/${deploymentName}`];

export type DeploymentsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof deploymentsGet>>
>;
export type DeploymentsGetQueryError = ErrorType<unknown>;

export const useDeploymentsGet = <TError = ErrorType<unknown>>(
  projectName: string,
  deploymentName: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof deploymentsGet>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(projectName && deploymentName);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled ? getDeploymentsGetKey(projectName, deploymentName) : null);
  const swrFn = () =>
    deploymentsGet(projectName, deploymentName, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Update a deployment

### Optional Parameters
- `name`: New name for the deployment
- `description`: New description for the deployment
- `labels`: New dictionary containing key/value pairs where key indicates the label and value is the corresponding value of that label. The new labels will replace the existing value for labels.
- `default_version`: Name of a version of this deployment which will be assigned as default. Only **available** versions can be assigned as default.
- `input_fields`: New input fields for the deployment
- `output_fields`: New output fields for the deployment

Input and output fields can be updated (name, data type or widget), added or removed. For deployments that are attached in a pipeline or contain any input/output widgets, fields must be updated one at a time so that the updates can be performed while preserving the mapping/widgets.

#### Request Examples
```
{
  "name": "new-deployment-name"
}
```

### Response Structure
Details of the updated deployment
- `id`: Unique identifier for the deployment (UUID)
- `name`: Name of the deployment
- `project`: Project name in which the deployment is defined
- `input_type`: Type of the input of the deployment
- `output_type`: Type of the output of the deployment
- `input_fields`: The list of deployment input fields containing name, data_type and (optional) widget
- `output_fields`: The list of deployment output fields containing name, data_type and (optional) widget
- `description`: Description of the deployment
- `labels`: Dictionary containing key/value pairs where key indicates the label and value is the corresponding value of that label
- `creation_date`: The date when the deployment was created
- `last_updated`: The date when the deployment was last updated
- `default_version`: Default version of the deployment. If it does not have a default version, it is not set.

#### Response Examples
```
{
  "id": "903ccd12-81d1-46e1-9ac9-b9d70af118de",
  "name": "new-deployment-name",
  "project": "project-1",
  "description": "New deployment description",
  "input_type": "structured",
  "output_type": "structured",
  "input_fields": [
    {
      "name": "input-field-1",
      "data_type": "int",
      "widget": {}
    },
    {
      "name": "input-field-2",
      "data_type": "double",
      "widget": {}
    }
  ],
  "output_fields": [
    {
      "name": "output-field",
      "data_type": "double",
      "widget": {}
    }
  ],
  "labels": {
    "type": "deployment"
  },
  "creation_date": "2020-06-18T08:32:14.876451Z",
  "last_updated": "2020-06-19T10:52:23.124784Z",
  "default_version": "v1"
}
```

 * @summary Update a deployment
 */
export const deploymentsUpdate = (
  projectName: string,
  deploymentName: string,
  deploymentUpdate: DeploymentUpdate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<DeploymentDetail>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}`,
      method: "patch",
      headers: { "Content-Type": "application/json" },
      data: deploymentUpdate,
    },
    options
  );
};

/**
 * 
### Description
Delete a deployment. If any of the versions of the deployment are referenced in a pipeline, the deployment cannot be deleted.

 * @summary Delete a deployment
 */
export const deploymentsDelete = (
  projectName: string,
  deploymentName: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<void>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}`,
      method: "delete",
    },
    options
  );
};

/**
 * 
### Description
List all audit events for a deployment including versions

### Optional Parameters
The following parameters should be given as query parameters:
- `action`: Type of action. It can be one of: create, update, delete, info.
- `limit`: The maximum number of audit events given back, default is 50
- `offset`: The number which forms the starting point of the audit events given back. If offset equals 2, then the first 2 events will be omitted from the list.

### Response Structure
A list of details of the audit events for a deployment
- `id`: Unique identifier for the audit event (UUID)
- `date`: The date when the action was performed
- `action`: Type of action. It can be one of: create, update, delete, info. *info* action denotes that the action does not fall into create, update or delete categories.
- `user`: Email of the user who performed the action
- `event`: Description of the event
- `object_type`: Type of the object on which the action was performed
- `object_name`: Name of the object on which the action was performed. If the object is deleted at the time of listing audit events, this field is empty.

#### Response Examples
```
[
  {
    "id": "25750859-e082-44df-bde9-cd85ca3f869c",
    "date": "2020-10-23T12:03:55.675+00:00",
    "action": "delete",
    "user": "user@example.com",
    "event": "Deleted environment variable ENV_VAR for deployment deployment-1",
    "object_type": "deployment",
    "object_name": "deployment-1"
  },
  {
    "id": "ce81814d-b00c-4094-a483-814afdb80875",
    "date": "2020-10-23T12:04:28.645+00:00",
    "action": "create",
    "user": "user@example.com",
    "event": "Created version v1 for deployment deployment-1",
    "object_type": "deployment",
    "object_name": "audit-deployment"
  }
]
```

 * @summary List audit events for a deployment
 */
export const deploymentAuditEventsList = (
  projectName: string,
  deploymentName: string,
  params?: DeploymentAuditEventsListParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<AuditList[]>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/audit`,
      method: "get",
      params,
    },
    options
  );
};

export const getDeploymentAuditEventsListKey = (
  projectName: string,
  deploymentName: string,
  params?: DeploymentAuditEventsListParams
) => [
  `/projects/${projectName}/deployments/${deploymentName}/audit`,
  ...(params ? [params] : []),
];

export type DeploymentAuditEventsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof deploymentAuditEventsList>>
>;
export type DeploymentAuditEventsListQueryError = ErrorType<unknown>;

export const useDeploymentAuditEventsList = <TError = ErrorType<unknown>>(
  projectName: string,
  deploymentName: string,
  params?: DeploymentAuditEventsListParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof deploymentAuditEventsList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(projectName && deploymentName);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getDeploymentAuditEventsListKey(projectName, deploymentName, params)
        : null);
  const swrFn = () =>
    deploymentAuditEventsList(
      projectName,
      deploymentName,
      params,
      requestOptions
    );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Copy existing environment variables from a source object to the deployment. Variables of the deployment with the same name as ones from the source object will be overwritten with the new value. Only the copied variables are returned.

### Required Parameters
- `source_deployment`: The name of the deployment from which the variables will be copied

### Optional Parameters
- `source_version`: The version of the deployment from which the variables will be copied

#### Request Examples
Copy the environment variables from a deployment
```
{
  "source_deployment": "example-deployment"
}
```

Copy the environment variables from a version
```
{
  "source_deployment": "example-deployment",
  "source_version": "v1"
}
```

### Response Structure
A list of the copied variables described by the following fields:
- `id`: Unique identifier for the environment variable
- `name`: Variable name
- `value`: Variable value (will be null for secret variables)
- `secret`: Boolean that indicates if this variable contains sensitive information
- `inheritance_type`: Type of parent object that this variable is inherited from. Will be null for copied environment variables.
- `inheritance_name`: Name of the parent object that this variable is inherited from. Will be null for copied environment variables.

#### Response Examples
```
[
  {
    "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    "name": "deployment_specific_variable",
    "value": "some_value",
    "secret": false,
    "inheritance_type": null,
    "inheritance_name": null
  },
  {
    "id": "4c15a27e-25ea-4be0-86c7-f4790389d061",
    "name": "database_schema",
    "value": "public",
    "secret": false,
    "inheritance_type": null,
    "inheritance_name": null
  },
  {
    "id": "06c2c8be-507e-4fae-981d-54e94f22dab0",
    "name": "database_password",
    "value": null,
    "secret": true,
    "inheritance_type": null,
    "inheritance_name": null
  }
]
```

 * @summary Copy deployment environment variable
 */
export const deploymentEnvironmentVariablesCopy = (
  projectName: string,
  deploymentName: string,
  environmentVariableCopyBody: EnvironmentVariableCopyBody,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<InheritedEnvironmentVariableList[]>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/copy-environment-variables`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: environmentVariableCopyBody,
    },
    options
  );
};

/**
 * 
### Description
List the environment variables defined for the deployment. Includes environment variables defined at project level.

### Response Structure
A list of variables described by the following fields:
- `id`: Unique identifier for the environment variable
- `name`: Variable name
- `value`: Variable value (will be null for secret variables)
- `secret`: Boolean that indicates if this variable contains sensitive information
- `inheritance_type`: Type of parent object that this variable is inherited from - can be `project` or null if the variable was defined for the deployment directly
- `inheritance_name`: Name of the parent object that this variable is inherited from - will be null if the variable was defined for the deployment directly

#### Response Examples
```
[
  {
    "id": "4c15a27e-25ea-4be0-86c7-f4790389d061",
    "name": "database_schema",
    "value": "public",
    "secret": false,
    "inheritance_type": null,
    "inheritance_name": null
  },
  {
    "id": "06c2c8be-507e-4fae-981d-54e94f22dab0",
    "name": "database_password",
    "value": null,
    "secret": true,
    "inheritance_type": "project",
    "inheritance_name": "project_name"
  }
]
```

 * @summary List deployment environment variables
 */
export const deploymentEnvironmentVariablesList = (
  projectName: string,
  deploymentName: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<InheritedEnvironmentVariableList[]>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/environment-variables`,
      method: "get",
    },
    options
  );
};

export const getDeploymentEnvironmentVariablesListKey = (
  projectName: string,
  deploymentName: string
) => [
  `/projects/${projectName}/deployments/${deploymentName}/environment-variables`,
];

export type DeploymentEnvironmentVariablesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof deploymentEnvironmentVariablesList>>
>;
export type DeploymentEnvironmentVariablesListQueryError = ErrorType<unknown>;

export const useDeploymentEnvironmentVariablesList = <
  TError = ErrorType<unknown>
>(
  projectName: string,
  deploymentName: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof deploymentEnvironmentVariablesList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(projectName && deploymentName);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getDeploymentEnvironmentVariablesListKey(projectName, deploymentName)
        : null);
  const swrFn = () =>
    deploymentEnvironmentVariablesList(
      projectName,
      deploymentName,
      requestOptions
    );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Create an environment variable for the deployment. This variable will be inherited by all versions of this deployment. Variables inherited from the project can be shadowed by creating a variable with the same name.

### Required Parameters
- `name`: The name of the variable. The variable will have this name when accessed from your deployment code. The variable name should contain only letters and underscores, and not start or end with an underscore.
- `value`: The value of the variable as a string. It may be an empty string ("").
- `secret`: If this variable contains sensitive information, set this to true to hide it from other users.

#### Request Examples
```
{
  "name": "deployment_variable_a",
  "value": "some_value",
  "secret": false
}
```

### Response Structure
A list of variables described by the following fields:
- `id`: Unique identifier for the environment variable
- `name`: Variable name
- `value`: Variable value (will be null for secret variables)
- `secret`: Boolean that indicates if this variable contains sensitive information

#### Response Examples
```
{
"id": "7c28a2be-507e-4fae-981d-54e94f22dab0",
"name": "deployment_variable_a",
"value": "some_value",
"secret": false
}
```

 * @summary Create deployment environment variable
 */
export const deploymentEnvironmentVariablesCreate = (
  projectName: string,
  deploymentName: string,
  environmentVariableCreateBody: EnvironmentVariableCreateBody,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<EnvironmentVariableList>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/environment-variables`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: environmentVariableCreateBody,
    },
    options
  );
};

/**
 * 
### Description
Retrieve details of a deployment environment variable. This cannot be used to retrieve details of inherited variables.

### Response Structure
A list of variables described by the following fields:
- `id`: Unique identifier for the environment variable
- `name`: Variable name
- `value`: Variable value (will be null for secret variables)
- `secret`: Boolean that indicates if this variable contains sensitive information

#### Response Examples
```
[
  {
    "id": "4c15a27e-25ea-4be0-86c7-f4790389d061",
    "name": "database_schema",
    "value": "public",
    "secret": false
  }
]
```

 * @summary Get deployment environment variable
 */
export const deploymentEnvironmentVariablesGet = (
  projectName: string,
  deploymentName: string,
  id: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<EnvironmentVariableList>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/environment-variables/${id}`,
      method: "get",
    },
    options
  );
};

export const getDeploymentEnvironmentVariablesGetKey = (
  projectName: string,
  deploymentName: string,
  id: string
) => [
  `/projects/${projectName}/deployments/${deploymentName}/environment-variables/${id}`,
];

export type DeploymentEnvironmentVariablesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof deploymentEnvironmentVariablesGet>>
>;
export type DeploymentEnvironmentVariablesGetQueryError = ErrorType<unknown>;

export const useDeploymentEnvironmentVariablesGet = <
  TError = ErrorType<unknown>
>(
  projectName: string,
  deploymentName: string,
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof deploymentEnvironmentVariablesGet>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(projectName && deploymentName && id);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getDeploymentEnvironmentVariablesGetKey(
            projectName,
            deploymentName,
            id
          )
        : null);
  const swrFn = () =>
    deploymentEnvironmentVariablesGet(
      projectName,
      deploymentName,
      id,
      requestOptions
    );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Update an environment variable for the deployment. This cannot be used to update inherited variables; to change an inherited variable for a specific deployment you can create a variable with the same name for the deployment.

### Required Parameters
- `name`: The name of the variable. The variable will have this name when accessed from your deployment code. The variable name should contain only letters and underscores, and not start or end with an underscore.
- `value`: The value of the variable as a string. It may be an empty string ("").
- `secret`: If this variable contains sensitive information, set this to true to hide it from other users. Can be updated from false to true, but not from true to false (i.e. secrets will stay secrets).

#### Request Examples
```
{
  "name": "deployment_variable_a",
  "value": "some new value",
  "secret": false
}
```

### Response Structure
A list of variables described by the following fields:
- `id`: Unique identifier for the environment variable
- `name`: Variable name
- `value`: Variable value (will be null for secret variables)
- `secret`: Boolean that indicates if this variable contains sensitive information

#### Response Examples
```
{
  "id": "7c28a2be-507e-4fae-981d-54e94f22dab0",
  "name": "deployment_variable_a",
  "value": "some new value",
  "secret": false
}
```

 * @summary Update deployment environment variable
 */
export const deploymentEnvironmentVariablesUpdate = (
  projectName: string,
  deploymentName: string,
  id: string,
  environmentVariableCreateBody: EnvironmentVariableCreateBody,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<EnvironmentVariableList>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/environment-variables/${id}`,
      method: "patch",
      headers: { "Content-Type": "application/json" },
      data: environmentVariableCreateBody,
    },
    options
  );
};

/**
 * 
### Description
Delete an environment variable of the deployment

 * @summary Delete deployment environment variable
 */
export const deploymentEnvironmentVariablesDelete = (
  projectName: string,
  deploymentName: string,
  id: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<void>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/environment-variables/${id}`,
      method: "delete",
    },
    options
  );
};

/**
 * 
### Description
Versions can be filtered according to the labels they have by giving labels as a query parameter. Versions that have at least one of the labels on which is filtered, are returned.

### Optional Parameters
- `labels`: Filter on labels of the version. Should be given in the format 'label:label_value'. Separate multiple label-pairs with a comma (,). This parameter should be given as query parameter.

### Response Structure
A list of details of the versions
- `id`: Unique identifier for the deployment version (UUID)
- `deployment`: Deployment name to which the version is associated
- `version`: Version name
- `default`: A boolean indicating whether the version is the default version
- `description`: Description of the version
- `environment`: Environment of the version
- `environment_display_name`: Human readable name of the environment
- `status`: The status of the version
- `active_revision`: UUID of the active revision of the version. If no deployment files have been uploaded yet, it is None.
- `latest_revision`: UUID of the latest revision of the version. If no deployment files have been uploaded yet, it is None.
- `maximum_instances`: Upper bound of number of instances running
- `minimum_instances`: Lower bound of number of instances running
- `maximum_idle_time`: Maximum time in seconds a version stays idle before it is stopped
- `labels`: Dictionary containing key/value pairs where key indicates the label and value is the corresponding value of that label
- `creation_date`: The date when the version was created
- `last_updated`: The date when the version was last updated
- `monitoring`: Name of a notification group which contains contacts to send notifications when requests for the version fail and recover
- `default_notification_group`: Name of a notification group which contains contacts to send notifications when requests for the version are completed
- `request_retention_time`: Number of seconds to store requests to the version
- `request_retention_mode`: Mode of request retention for requests to the version. It can be one of the following:
    - *none* - the requests will not be stored
    - *metadata* - only the metadata of the requests will be stored
    - *full* - both the metadata and input/output of the requests will be stored
- `maximum_queue_size_express`: Maximum number of queued express requests for all instances of this deployment version
- `maximum_queue_size_batch`: Maximum number of queued batch requests for all instances of this deployment version
- `static_ip`: A boolean indicating whether the deployment version should get a static IP
- `restart_request_interruption`: A boolean indicating whether the requests should be restarted in case of an interruption
- `ports`: A list of ports to open up in the deployment

#### Response Examples
```
[
  {
    "id": "4ae7d14b-4803-4e16-b96d-3b18caa4b605",
    "deployment": "deployment-1",
    "version": "version-1",
    "default": true,
    "description": "",
    "environment": "python3-8",
    "environment_display_name": "Python 3.8",
    "status": "available",
    "active_revision": "da27ef7c-aa3f-4963-a815-6ebf1865638e",
    "latest_revision": "0f4a94c6-ec4c-4d1e-81d7-8f3e40471f75",
    "maximum_instances": 4,
    "minimum_instances": 1,
    "maximum_idle_time": 10,
    "labels": {
      "type": "version"
    },
    "creation_date": "2020-06-18T08:32:14.876451Z",
    "last_updated": "2020-06-19T10:52:23.124784Z",
    "monitoring": "notification-group-1",
    "default_notification_group": null,
    "request_retention_time": 604800,
    "request_retention_mode": "full",
    "maximum_queue_size_express": 100,
    "maximum_queue_size_batch": 100000,
    "static_ip": false,
    "restart_request_interruption": false,
    "ports": []
  },
  {
    "id": "24f6b80a-08c3-4d52-ac1a-2ea7e70f16a6",
    "deployment": "deployment-1",
    "version": "version-2",
    "default": false,
    "description": "",
    "environment": "r4-0",
    "environment_display_name": "R 4.0",
    "status": "available",
    "active_revision": "a74662be-c938-4104-872a-8be1b85f64ff",
    "latest_revision": "a74662be-c938-4104-872a-8be1b85f64ff",
    "maximum_instances": 5,
    "minimum_instances": 0,
    "maximum_idle_time": 10,
    "labels": {
      "type": "version"
    },
    "creation_date": "2020-05-12T16:23:15.456812Z",
    "last_updated": "2020-06-22T18:04:76.123754Z",
    "monitoring": "notification-group-2",
    "default_notification_group": "notification-group-2",
    "request_retention_time": 86400,
    "request_retention_mode": "metadata",
    "maximum_queue_size_express": 100,
    "maximum_queue_size_batch": 100000,
    "static_ip": true,
    "restart_request_interruption": false,
    "ports": []
  }
]
```

 * @summary List deployment versions
 */
export const deploymentVersionsList = (
  projectName: string,
  deploymentName: string,
  params?: DeploymentVersionsListParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<DeploymentVersionList[]>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/versions`,
      method: "get",
      params,
    },
    options
  );
};

export const getDeploymentVersionsListKey = (
  projectName: string,
  deploymentName: string,
  params?: DeploymentVersionsListParams
) => [
  `/projects/${projectName}/deployments/${deploymentName}/versions`,
  ...(params ? [params] : []),
];

export type DeploymentVersionsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof deploymentVersionsList>>
>;
export type DeploymentVersionsListQueryError = ErrorType<unknown>;

export const useDeploymentVersionsList = <TError = ErrorType<unknown>>(
  projectName: string,
  deploymentName: string,
  params?: DeploymentVersionsListParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof deploymentVersionsList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(projectName && deploymentName);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getDeploymentVersionsListKey(projectName, deploymentName, params)
        : null);
  const swrFn = () =>
    deploymentVersionsList(projectName, deploymentName, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Create a version for a deployment. The first version of a deployment is set as default.
Provide the parameter 'monitoring' as the name of a notification group to send monitoring notifications to. A notification will be sent in the case of a failed/recovered request. Pass `null` to switch off monitoring notifications for this version.
Provide the parameter 'default_notification_group' as the name of a notification group to send notifications when requests for the version are completed. Pass `null` to switch off request notifications for this version.

### Required Parameters
- `version`: Name of the version of the deployment

### Optional Parameters
- `environment`: Environment of the version. It can be either a base or a custom environment.
- `instance_type`: [DEPRECATED] The reserved instance type for the version
- `instance_type_group_id`: ID of the instance type group for the version
- `instance_type_group_name`: Name of the instance type group for the version. If there are multiple groups with the same name in the project, the first group found will be used.
- `maximum_instances`: Upper bound of number of instances running. The default value is 5. *Indicator of resource capacity:* if many deployment requests need to be handled in a short time, this number can be set higher to avoid long waiting times.
- `minimum_instances`: Lower bound of number of instances running. The default value is 0. Set this value greater than 0 to always have a always running version.
- `maximum_idle_time`: Maximum time in seconds a version stays idle before it is stopped. The default value is 300, the minimum value is 10 (300 for GPU deployments) and the maximum value is 3600. A high value means that the version stays available longer. Sending requests to a running version means that it will be already initialized and thus take a shorter timer.
- `description`: Description for the version
- `labels`: Dictionary containing key/value pairs where key indicates the label and value is the corresponding value of that label
- `monitoring`: Name of a notification group which contains contacts to send notifications when requests for the version fail and recover
- `default_notification_group`: Name of a notification group which contains contacts to send notifications when requests for the version are completed
- `request_retention_time`: Number of seconds to store requests to the version. It defaults to 604800 seconds (1 week).
- `request_retention_mode`: Mode of request retention for requests to the version. It can be one of the following:
    - *none* - the requests will not be stored
    - *metadata* - only the metadata of the requests will be stored
    - *full* - both the metadata and input/output of the requests will be stored
- `maximum_queue_size_express`: Maximum number of queued express requests for all instances of this deployment version
- `maximum_queue_size_batch`: Maximum number of queued batch requests for all instances of this deployment version
- `static_ip`: A boolean indicating whether the deployment version should get a static IP. It defaults to False.
- `restart_request_interruption`: A boolean indicating whether the requests should be restarted in case of an interruption. It defaults to False.
- `ports`: A list of ports to open up in the deployment. Each item must be a dictionary containing the fields `public_port`, `deployment_port` and `protocol`.

If the time that a request takes does not matter, keep the default values.

#### Request Examples
```
{
  "version": "version-1",
  "environment": "python3-8"
}
```

```
{
  "version": "version-1",
  "environment": "r4-0",
  "instance_type_group_id": "530c0878-d73c-4ea5-9f5d-f83bc1eeacd7"
}
```

```
  "version": "version-1",
  "environment": "python3-8-cuda",
  "instance_type_group_id": "6e7f011c-829e-43f9-b7cf-7f9283699777",
  "maximum_instances": 1
```

```
{
  "version": "version-1",
  "maximum_instances": 4,
  "minimum_instances": 1,
  "monitoring": "notification-group-1"
}
```

```
{
  "version": "version-1",
  "ports": [
    {
      "public_port": 2222,
      "deployment_port": 2222,
      "protocol": "tcp
    }
  ]
}
```

### Response Structure
Details of the created version
- `id`: Unique identifier for the deployment version (UUID)
- `deployment`: Deployment name to which the version is associated
- `version`: Version name
- `default`: A boolean indicating whether the version is the default version
- `description`: Description of the version
- `environment`: Environment of the version
- `environment_display_name`: Human readable name of the environment
- `status`: The status of the version
- `active_revision`: UUID of the active revision of the version. It is initialised as None since there are no deployment files uploaded for the version yet.
- `latest_revision`: UUID of the latest revision of the version. It is initialised as None since there are no deployment files uploaded for the version yet.
- `instance_type`: [DEPRECATED] The reserved instance type for the version
- `instance_type_group_id`: ID of the instance type group for the version
- `instance_type_group_name`: Name of the instance type group for the version
- `maximum_instances`: Upper bound of number of instances running
- `minimum_instances`: Lower bound of number of instances running
- `maximum_idle_time`: Maximum time in seconds a version stays idle before it is stopped
- `labels`: Dictionary containing key/value pairs where key indicates the label and value is the corresponding value of that label
- `creation_date`: The date when the version was created
- `last_file_upload`: The date when a deployment file was last uploaded for the version
- `last_updated`: The date when the version was last updated
- `monitoring`: Name of a notification group which contains contacts to send notifications when requests for the version fail and recover
- `default_notification_group`: Name of a notification group which contains contacts to send notifications when requests for the version are completed
- `request_retention_time`: Number of seconds to store requests to the version
- `request_retention_mode`: Mode of request retention for requests to the version. It can be one of the following: *none*, *metadata* or *full*.
- `maximum_queue_size_express`: Maximum number of queued express requests for all instances of this deployment version
- `maximum_queue_size_batch`: Maximum number of queued batch requests for all instances of this deployment version
- `has_request_method`: Whether the latest revision of the version has a 'request' method
- `has_requests_method`: Whether the latest revision of the version has a 'requests' method
- `static_ip`: A boolean indicating whether the deployment version should get a static IP
- `restart_request_interruption`: A boolean indicating whether the requests should be restarted in case of an interruption
- `ports`: A list of ports to open up in the deployment

#### Response Examples
```
{
  "id": "4ae7d14b-4803-4e16-b96d-3b18caa4b605",
  "deployment": "deployment-1",
  "version": "version-1",
  "default": true,
  "description": "",
  "environment": "python3-8",
  "environment_display_name": "Python 3.8",
  "status": "unavailable",
  "active_revision": null,
  "latest_revision": null,
  "instance_type": "512mb",
  "instance_type_group_id": "530c0878-d73c-4ea5-9f5d-f83bc1eeacd7",
  "instance_type_group_name": "512mb",
  "maximum_instances": 5,
  "minimum_instances": 0,
  "maximum_idle_time": 10,
  "labels": {
    "type": "version"
  },
  "creation_date": "2020-05-12T16:23:15.456812Z",
  "last_updated": "2020-05-12T16:23:15.456812Z",
  "last_file_upload": null,
  "monitoring": "notification-group-1",
  "default_notification_group": null,
  "request_retention_time": 604800,
  "request_retention_mode": "full",
  "maximum_queue_size_express": 100,
  "maximum_queue_size_batch": 100000,
  "has_request_method": null,
  "has_requests_method": null,
  "static_ip": false,
  "restart_request_interruption": false,
  "ports": []
}
```

 * @summary Create deployment versions
 */
export const deploymentVersionsCreate = (
  projectName: string,
  deploymentName: string,
  deploymentVersionCreate: DeploymentVersionCreate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<DeploymentVersionDetail>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/versions`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: deploymentVersionCreate,
    },
    options
  );
};

/**
 * 
### Description
Retrieve details of a version of a deployment in a project

### Response Structure
Details of a version
- `id`: Unique identifier for the version (UUID)
- `deployment`: Deployment name to which the version is associated
- `version`: Version name
- `default`: A boolean indicating whether the version is the default version
- `description`: Description of the version
- `environment`: Environment of the version
- `environment_display_name`: Human readable name of the environment
- `status`: The status of the version
- `active_revision`: UUID of the active revision of the version. If no deployment files have been uploaded yet, it is None.
- `latest_revision`: UUID of the latest build of the version. If no deployment files have been uploaded yet, it is None.
- `instance_type`: [DEPRECATED] The reserved instance type for the version
- `instance_type_group_id`: ID of the instance type group for the version
- `instance_type_group_name`: Name of the instance type group for the version
- `maximum_instances`: Upper bound of number of instances running
- `minimum_instances`: Lower bound of number of instances running
- `maximum_idle_time`: Maximum time in seconds a version stays idle before it is stopped
- `labels`: Dictionary containing key/value pairs where key indicates the label and value is the corresponding value of that label
- `creation_date`: The date when the version was created
- `last_updated`: The date when the version was last updated
- `last_file_upload`: The date when a deployment file was last uploaded for the version
- `monitoring`: Name of a notification group which contains contacts to send notifications when requests for the version fail and recover
- `default_notification_group`: Name of a notification group which contains contacts to send notifications when requests for the version are completed
- `request_retention_time`: Number of seconds to store requests to the version
- `request_retention_mode`: Mode of request retention for requests to the version. It can be one of the following:
    - *none* - the requests will not be stored
    - *metadata* - only the metadata of the requests will be stored
    - *full* - both the metadata and input/output of the requests will be stored
- `maximum_queue_size_express`: Maximum number of queued express requests for all instances of this deployment version
- `maximum_queue_size_batch`: Maximum number of queued batch requests for all instances of this deployment version
- `has_request_method`: Whether the latest revision of the version has a 'request' method
- `has_requests_method`: Whether the latest revision of the version has a 'requests' method
- `static_ip`: A boolean indicating whether the deployment version should get a static IP
- `restart_request_interruption`: A boolean indicating whether the requests should be restarted in case of an interruption
- `ports`: A list of ports to open up in the deployment

#### Response Examples
```
{
  "id": "4ae7d14b-4803-4e16-b96d-3b18caa4b605",
  "deployment": "deployment-1",
  "version": "version-1",
  "default": true,
  "description": "",
  "environment": "python3-8",
  "environment_display_name": "Python 3.7",
  "status": "available",
  "active_revision": "a74662be-c938-4104-872a-8be1b85f64ff",
  "latest_revision": "a74662be-c938-4104-872a-8be1b85f64ff",
  "instance_type": "512mb",
  "instance_type_group_id": "530c0878-d73c-4ea5-9f5d-f83bc1eeacd7",
  "instance_type_group_name": "512mb",
  "maximum_instances": 4,
  "minimum_instances": 1,
  "maximum_idle_time": 10,
  "labels": {
    "type": "version"
  },
  "creation_date": "2020-05-12T16:23:15.456812Z",
  "last_updated": "2020-06-22T18:04:76.123754Z",
  "last_file_uploaded": "2020-06-21T09:03:01.875391Z",
  "monitoring": "notification-group-1",
  "default_notification_group": null,
  "request_retention_time": 604800,
  "request_retention_mode": "full",
  "maximum_queue_size_express": 100,
  "maximum_queue_size_batch": 100000,
  "has_request_method": true,
  "has_requests_method": false,
  "static_ip": false,
  "restart_request_interruption": false,
  "ports": []
}
```

 * @summary Get deployment version
 */
export const deploymentVersionsGet = (
  projectName: string,
  deploymentName: string,
  version: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<DeploymentVersionDetail>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/versions/${version}`,
      method: "get",
    },
    options
  );
};

export const getDeploymentVersionsGetKey = (
  projectName: string,
  deploymentName: string,
  version: string
) => [
  `/projects/${projectName}/deployments/${deploymentName}/versions/${version}`,
];

export type DeploymentVersionsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof deploymentVersionsGet>>
>;
export type DeploymentVersionsGetQueryError = ErrorType<unknown>;

export const useDeploymentVersionsGet = <TError = ErrorType<unknown>>(
  projectName: string,
  deploymentName: string,
  version: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof deploymentVersionsGet>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false &&
    !!(projectName && deploymentName && version);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getDeploymentVersionsGetKey(projectName, deploymentName, version)
        : null);
  const swrFn = () =>
    deploymentVersionsGet(projectName, deploymentName, version, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Update a version of a deployment in a project. All necessary fields are validated again. When updating labels, the labels will replace the existing value for labels.
Provide the parameter 'monitoring' as the name of a notification group to send monitoring notifications to. A notification will be sent in the case of a failed/recovered request. Pass `null` to switch off monitoring notifications for this version.
Provide the parameter 'default_notification_group' as the name of a notification group to send notifications when requests for the version are completed. Pass `null` to switch off request notifications for this version.

### Optional Parameters
- `version`: New name for the version
- `instance_type`: [DEPRECATED] New instance type for the version
- `instance_type_group_id`: ID of the new instance type group for the version
- `instance_type_group_name`: Name of the new instance type group for the version. If there are multiple groups with the same name in the project, the first group found will be used.
- `maximum_instances`: New upper bound of number of instances running
- `minimum_instances`: New lower bound of number of instances running
- `maximum_idle_time`: New maximum time in seconds a version stays idle before it is stopped
- `description`: New description for the version
- `labels`: Dictionary containing key/value pairs where key indicates the label and value is the corresponding value of that label. The new labels will replace the existing value for labels.
- `monitoring`: Name of a notification group which contains contacts to send notifications when requests for the version fail and recover
- `default_notification_group`: Name of a notification group which contains contacts to send notifications when requests for the version are completed
- `request_retention_time`: Number of seconds to store requests to the version
- `request_retention_mode`: Mode of request retention for requests to the version. It can be one of the following:
    - *none* - the requests will not be stored
    - *metadata* - only the metadata of the requests will be stored
    - *full* - both the metadata and input/output of the requests will be stored
- `maximum_queue_size_express`: Maximum number of queued express requests for all instances of this deployment version
- `maximum_queue_size_batch`: Maximum number of queued batch requests for all instances of this deployment version
- `static_ip`: A boolean indicating whether the deployment version should get a static IP
- `restart_request_interruption`: A boolean indicating whether the requests should be restarted in case of an interruption
- `environment`: New environment for the version. It can be either a base or a custom environment.
- `ports`: A list of ports to open up in the deployment. Each item must be a dictionary containing the fields `public_port`, `deployment_port` and `protocol`. The new ports will replace the existing value for ports.

#### Request Examples
```
{
  "version": "new-version"
}
```

```
{
  "instance_type_group_id": "530c0878-d73c-4ea5-9f5d-f83bc1eeacd7",
  "maximum_instances": 4,
  "minimum_instances": 1,
  "monitoring": "notification-group-1"
}
```

### Response Structure
Details of the updated version
- `id`: Unique identifier for the deployment version (UUID)
- `deployment`: Deployment name to which the version is associated
- `version`: Version name
- `default`: A boolean indicating whether the version is the default version
- `description`: Description of the version
- `environment`: Environment of the version
- `environment_display_name`: Human readable name of the environment
- `status`: The status of the version
- `active_revision`: UUID of the active revision of the version. If no deployment files have been uploaded yet, it is None.
- `latest_revision`: UUID of the latest build of the version. If no deployment files have been uploaded yet, it is None.
- `instance_type`: [DEPRECATED] The reserved instance type for the version
- `instance_type_group_id`: ID of the instance type group for the version
- `instance_type_group_name`: Name of the instance type group for the version
- `maximum_instances`: Upper bound of number of instances running
- `minimum_instances`: Lower bound of number of instances running
- `maximum_idle_time`: Maximum time in seconds a version stays idle before it is stopped
- `labels`: Dictionary containing key/value pairs where key indicates the label and value is the corresponding value of that label
- `creation_date`: The date when the version was created
- `last_updated`: The date when the version was last updated
- `last_file_upload`: The date when a deployment file was last uploaded for the version
- `monitoring`: Name of a notification group which contains contacts to send notifications when requests for the version fail and recover
- `default_notification_group`: Name of a notification group which contains contacts to send notifications when requests for the version are completed
- `request_retention_time`: Number of seconds to store requests to the version
- `request_retention_mode`: Mode of request retention for requests to the version. It can be one of the following: *none*, *metadata* or *full*.
- `maximum_queue_size_express`: Maximum number of queued express requests for all instances of this deployment version
- `maximum_queue_size_batch`: Maximum number of queued batch requests for all instances of this deployment version
- `has_request_method`: Whether the latest revision of the version has a 'request' method
- `has_requests_method`: Whether the latest revision of the version has a 'requests' method
- `static_ip`: A boolean indicating whether the deployment version should get a static IP
- `restart_request_interruption`: A boolean indicating whether the requests should be restarted in case of an interruption
- `ports`: A list of ports to open up in the deployment

#### Response Examples
```
{
  "id": "4ae7d14b-4803-4e16-b96d-3b18caa4b605",
  "deployment": "deployment-1",
  "version": "version-1",
  "default": true,
  "description": "",
  "environment": "python3-8",
  "environment_display_name": "Python 3.8",
  "status": "available",
  "active_revision": "a74662be-c938-4104-872a-8be1b85f64ff",
  "latest_revision": "a74662be-c938-4104-872a-8be1b85f64ff",
  "instance_type": "512mb",
  "instance_type_group_id": "530c0878-d73c-4ea5-9f5d-f83bc1eeacd7",
  "instance_type_group_name": "512mb",
  "maximum_instances": 4,
  "minimum_instances": 1,
  "maximum_idle_time": 10,
  "labels": {
    "type": "version"
  },
  "creation_date": "2020-05-12T16:23:15.456812Z",
  "last_updated": "2020-06-23T18:04:76.123754Z",
  "last_file_uploaded": "2020-06-21T09:03:01.875391Z",
  "monitoring": "notification-group-1",
  "default_notification_group": null,
  "request_retention_time": 604800,
  "request_retention_mode": "full",
  "maximum_queue_size_express": 100,
  "maximum_queue_size_batch": 100000,
  "has_request_method": true,
  "has_requests_method": false,
  "static_ip": false,
  "restart_request_interruption": false,
  "ports": []
}
```

 * @summary Update deployment version
 */
export const deploymentVersionsUpdate = (
  projectName: string,
  deploymentName: string,
  version: string,
  deploymentVersionUpdate: DeploymentVersionUpdate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<DeploymentVersionDetail>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/versions/${version}`,
      method: "patch",
      headers: { "Content-Type": "application/json" },
      data: deploymentVersionUpdate,
    },
    options
  );
};

/**
 * 
### Description
Delete a deployment version. The version cannot be deleted if:
- It is referenced in a pipeline, it must be removed from the pipeline first.
- It is the default version of its deployment and is referenced in a request schedule.

 * @summary Delete deployment version
 */
export const deploymentVersionsDelete = (
  projectName: string,
  deploymentName: string,
  version: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<void>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/versions/${version}`,
      method: "delete",
    },
    options
  );
};

/**
 * 
### Description
Copy existing environment variables from a source object to the deployment version. Variables of the deployment version with the same name as ones from the source object will be overwritten with the new value. Only the copied variables are returned.

### Required Parameters
- `source_deployment`: The name of the deployment from which the variables will be copied

### Optional Parameters
- `source_version`: The version of the deployment from which the variables will be copied

#### Request Examples
Copy the environment variables from a deployment
```
{
  "source_deployment": "example-deployment"
}
```

Copy the environment variables from a deployment version
```
{
  "source_deployment": "example-deployment",
  "source_version": "v1"
}
```

### Response Structure
A list of the copied variables described by the following fields:
- `id`: Unique identifier for the environment variable
- `name`: Variable name
- `value`: Variable value (will be null for secret variables)
- `secret`: Boolean that indicates if this variable contains sensitive information
- `inheritance_type`: Type of parent object that this variable is inherited from. Will be null for copied environment variables.
- `inheritance_name`: Name of the parent object that this variable is inherited from. Will be null for copied environment variables.

#### Response Examples
```
[
  {
    "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    "name": "version_specific_variable",
    "value": "some_value",
    "secret": false,
    "inheritance_type": null,
    "inheritance_name": null
  },
  {
    "id": "4c15a27e-25ea-4be0-86c7-f4790389d061",
    "name": "database_schema",
    "value": "public",
    "secret": false,
    "inheritance_type": null,
    "inheritance_name": null
  },
  {
    "id": "06c2c8be-507e-4fae-981d-54e94f22dab0",
    "name": "database_password",
    "value": null,
    "secret": true,
    "inheritance_type": null,
    "inheritance_name": null
  }
]
```

 * @summary Copy deployment version environment variable
 */
export const deploymentVersionEnvironmentVariablesCopy = (
  projectName: string,
  deploymentName: string,
  version: string,
  environmentVariableCopyBody: EnvironmentVariableCopyBody,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<InheritedEnvironmentVariableList[]>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/versions/${version}/copy-environment-variables`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: environmentVariableCopyBody,
    },
    options
  );
};

/**
 * 
### Description
List the environment variables defined for the deployment version. Includes environment variables defined at project level and deployment level.

### Response Structure
A list of variables described by the following fields:
- `id`: Unique identifier for the environment variable
- `name`: Variable name
- `value`: Variable value (will be null for secret variables)
- `secret`: Boolean that indicates if this variable contains sensitive information
- `inheritance_type`: Type of parent object that this variable is inherited from - can be `project`, `deployment`, or null if the variable was defined for the version directly
- `inheritance_name`: Name of the parent object that this variable is inherited from - will be null if the variable was defined for the version directly

#### Response Examples
```
[
  {
    "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    "name": "deployment_version_specific_variable",
    "value": "some_value",
    "secret": false,
    "inheritance_type": null,
    "inheritance_name": null
  },
  {
    "id": "4c15a27e-25ea-4be0-86c7-f4790389d061",
    "name": "database_schema",
    "value": "public",
    "secret": false,
    "inheritance_type": "deployment",
    "inheritance_name": "deployment_name"
  },
  {
    "id": "06c2c8be-507e-4fae-981d-54e94f22dab0",
    "name": "database_password",
    "value": null,
    "secret": true,
    "inheritance_type": "project",
    "inheritance_name": "project_name"
  }
]
```

 * @summary List deployment version environment variables
 */
export const deploymentVersionEnvironmentVariablesList = (
  projectName: string,
  deploymentName: string,
  version: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<InheritedEnvironmentVariableList[]>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/versions/${version}/environment-variables`,
      method: "get",
    },
    options
  );
};

export const getDeploymentVersionEnvironmentVariablesListKey = (
  projectName: string,
  deploymentName: string,
  version: string
) => [
  `/projects/${projectName}/deployments/${deploymentName}/versions/${version}/environment-variables`,
];

export type DeploymentVersionEnvironmentVariablesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof deploymentVersionEnvironmentVariablesList>>
>;
export type DeploymentVersionEnvironmentVariablesListQueryError =
  ErrorType<unknown>;

export const useDeploymentVersionEnvironmentVariablesList = <
  TError = ErrorType<unknown>
>(
  projectName: string,
  deploymentName: string,
  version: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof deploymentVersionEnvironmentVariablesList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false &&
    !!(projectName && deploymentName && version);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getDeploymentVersionEnvironmentVariablesListKey(
            projectName,
            deploymentName,
            version
          )
        : null);
  const swrFn = () =>
    deploymentVersionEnvironmentVariablesList(
      projectName,
      deploymentName,
      version,
      requestOptions
    );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Create an environment variable for the deployment version. Variables inherited from the project or deployment can be shadowed by creating a variable with the same name.

### Required Parameters
- `name`: The name of the variable. The variable will have this name when accessed from your deployment code. The variable name should contain only letters and underscores, and not start or end with an underscore.
- `value`: The value of the variable as a string. It may be an empty string ("").
- `secret`: If this variable contains sensitive information, set this to true to hide it from other users.

#### Request Examples
```
{
  "name": "deployment_version_variable",
  "value": "another one",
  "secret": false
}
```

### Response Structure
A list of variables described by the following fields:
- `id`: Unique identifier for the environment variable
- `name`: Variable name
- `value`: Variable value (will be null for secret variables)
- `secret`: Boolean that indicates if this variable contains sensitive information

#### Response Examples
```
{
  "id": "54e94fbe-507e-4fae-981d-227c28a2dab0",
  "name": "deployment_version_variable",
  "value": "another one",
  "secret": false
}
```

 * @summary Create deployment version environment variable
 */
export const deploymentVersionEnvironmentVariablesCreate = (
  projectName: string,
  deploymentName: string,
  version: string,
  environmentVariableCreateBody: EnvironmentVariableCreateBody,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<EnvironmentVariableList>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/versions/${version}/environment-variables`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: environmentVariableCreateBody,
    },
    options
  );
};

/**
 * 
### Description
Retrieve details of a deployment version environment variable. This cannot be used to retrieve details of inherited variables.

### Response Structure
A list of variables described by the following fields:
- `id`: Unique identifier for the environment variable
- `name`: Variable name
- `value`: Variable value (will be null for secret variables)
- `secret`: Boolean that indicates if this variable contains sensitive information

#### Response Examples
```
[
  {
    "id": "4c15a27e-25ea-4be0-86c7-f4790389d061",
    "name": "database_schema",
    "value": "public",
    "secret": false
  }
]
```

 * @summary Get deployment version environment variable
 */
export const deploymentVersionEnvironmentVariablesGet = (
  projectName: string,
  deploymentName: string,
  version: string,
  id: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<EnvironmentVariableList>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/versions/${version}/environment-variables/${id}`,
      method: "get",
    },
    options
  );
};

export const getDeploymentVersionEnvironmentVariablesGetKey = (
  projectName: string,
  deploymentName: string,
  version: string,
  id: string
) => [
  `/projects/${projectName}/deployments/${deploymentName}/versions/${version}/environment-variables/${id}`,
];

export type DeploymentVersionEnvironmentVariablesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof deploymentVersionEnvironmentVariablesGet>>
>;
export type DeploymentVersionEnvironmentVariablesGetQueryError =
  ErrorType<unknown>;

export const useDeploymentVersionEnvironmentVariablesGet = <
  TError = ErrorType<unknown>
>(
  projectName: string,
  deploymentName: string,
  version: string,
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof deploymentVersionEnvironmentVariablesGet>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false &&
    !!(projectName && deploymentName && version && id);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getDeploymentVersionEnvironmentVariablesGetKey(
            projectName,
            deploymentName,
            version,
            id
          )
        : null);
  const swrFn = () =>
    deploymentVersionEnvironmentVariablesGet(
      projectName,
      deploymentName,
      version,
      id,
      requestOptions
    );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Update an environment variable for the deployment version. This cannot be used to update inherited variables; to change an inherited variable for a specific version you can create a variable with the same name for the deployment version.

### Required Parameters
- `name`: The name of the variable. The variable will have this name when accessed from your deployment code. The variable name should contain only letters and underscores, and not start or end with an underscore.
- `value`: The value of the variable as a string. It may be an empty string ("").
- `secret`: If this variable contains sensitive information, set this to true to hide it from other users. Can be updated from false to true, but not from true to false (i.e. secrets will stay secrets).

#### Request Examples
```
{
  "name": "deployment_version_variable",
  "value": "yet another one",
  "secret": false
}
```

### Response Structure
A list of variables described by the following fields:
- `id`: Unique identifier for the environment variable
- `name`: Variable name
- `value`: Variable value (will be null for secret variables)
- `secret`: Boolean that indicates if this variable contains sensitive information

#### Response Examples
```
{
  "id": "54e94fbe-507e-4fae-981d-227c28a2dab0",
  "name": "deployment_version_variable",
  "value": "yet another one",
  "secret": false
}
```

 * @summary Update deployment version environment variable
 */
export const deploymentVersionEnvironmentVariablesUpdate = (
  projectName: string,
  deploymentName: string,
  version: string,
  id: string,
  environmentVariableCreateBody: EnvironmentVariableCreateBody,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<EnvironmentVariableList>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/versions/${version}/environment-variables/${id}`,
      method: "patch",
      headers: { "Content-Type": "application/json" },
      data: environmentVariableCreateBody,
    },
    options
  );
};

/**
 * 
### Description
Delete an environment variable of a deployment version

 * @summary Delete deployment version environment variable
 */
export const deploymentVersionEnvironmentVariablesDelete = (
  projectName: string,
  deploymentName: string,
  version: string,
  id: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<void>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/versions/${version}/environment-variables/${id}`,
      method: "delete",
    },
    options
  );
};

/**
 * 
### Description
Retrieve the date of the last request to a deployment version in a project

### Response Structure
- `last_request`: The date when the last deployment version request was performed

#### Response Examples
```
{
  "last_request": "2020-09-01T08:58:00.000+00:00"
}
```

 * @summary Get deployment version activity
 */
export const deploymentVersionsActivityGet = (
  projectName: string,
  deploymentName: string,
  version: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<Activity>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/versions/${version}/last-request`,
      method: "get",
    },
    options
  );
};

export const getDeploymentVersionsActivityGetKey = (
  projectName: string,
  deploymentName: string,
  version: string
) => [
  `/projects/${projectName}/deployments/${deploymentName}/versions/${version}/last-request`,
];

export type DeploymentVersionsActivityGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof deploymentVersionsActivityGet>>
>;
export type DeploymentVersionsActivityGetQueryError = ErrorType<unknown>;

export const useDeploymentVersionsActivityGet = <TError = ErrorType<unknown>>(
  projectName: string,
  deploymentName: string,
  version: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof deploymentVersionsActivityGet>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false &&
    !!(projectName && deploymentName && version);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getDeploymentVersionsActivityGetKey(
            projectName,
            deploymentName,
            version
          )
        : null);
  const swrFn = () =>
    deploymentVersionsActivityGet(
      projectName,
      deploymentName,
      version,
      requestOptions
    );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
List all revisions associated with a version. A new revision is created every time a new deployment file is uploaded for a version.

### Response Structure
A list of details of the revisions
- `id`: Unique identifier for the revision (UUID)
- `version`: Version to which the revision is linked
- `creation_date`: The date when the revision was created
- `created_by`: The email of the user that uploaded the deployment file. In case the revision is created by a service, the field will have a "UbiOps" value.
- `status`: Status of the revision. Can be 'queued', 'building', 'success' or 'failed'.
- `error_message`: Error message which explains why the revision has failed. It is empty if the revision is successful.
- `has_request_method`: Whether the deployment code corresponding to the revision has a 'request' method
- `has_requests_method`: Whether the deployment code corresponding to the revision has a 'requests' method

#### Response Examples
```
[
  {
    "id": "7ead8a18-c1d2-4751-80d2-d8e0e0e2fed6",
    "version": "v1",
    "creation_date": "2020-12-23T16:15:11.181+00:00",
    "created_by": "UbiOps",
    "status": "building",
    "error_message": "",
    "has_request_method": true,
    "has_requests_method": false
  },
  {
    "id": "a009d7c9-67e4-4d3c-89fd-d3c8b07c7242",
    "version": "v1",
    "creation_date": "2020-12-23T16:35:13.069+00:00",
    "created_by": "test@example.com",
    "status": "success",
    "error_message": "",
    "has_request_method": true,
    "has_requests_method": false
  }
]
```

 * @summary List revisions
 */
export const revisionsList = (
  projectName: string,
  deploymentName: string,
  version: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<RevisionList[]>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/versions/${version}/revisions`,
      method: "get",
    },
    options
  );
};

export const getRevisionsListKey = (
  projectName: string,
  deploymentName: string,
  version: string
) => [
  `/projects/${projectName}/deployments/${deploymentName}/versions/${version}/revisions`,
];

export type RevisionsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof revisionsList>>
>;
export type RevisionsListQueryError = ErrorType<unknown>;

export const useRevisionsList = <TError = ErrorType<unknown>>(
  projectName: string,
  deploymentName: string,
  version: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof revisionsList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false &&
    !!(projectName && deploymentName && version);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getRevisionsListKey(projectName, deploymentName, version)
        : null);
  const swrFn = () =>
    revisionsList(projectName, deploymentName, version, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Upload a deployment file for a version. Uploading a deployment file will create a new revision and trigger a validation.
This file should contain the deployment that will be run. It should be provided as a zip and a template can be found on https://github.com/UbiOps/deployment-template. The file is saved under a directory in the storage specified in the settings.

It is **also possible** to provide a source version from which the deployment file will be copied. This will also create a new revision and trigger a validation.

### Optional Parameters
- `file`: Deployment file
- `source_deployment`: Name of the deployment from which the deployment file will be copied
- `source_version`: Version from which the deployment file will be copied
- `template_deployment_id`: UUID of a template deployment which will be used as the source of the deployment file

Either **file** or **source_deployment** and **source_version** must be provided. source_deployment and source_version must be provided together.

### Response Structure
- `success`: Boolean indicating whether the deployment file upload/copy succeeded or not
- `revision`: UUID of the created revision for the file upload
- `build`: [DEPRECATED] UUID of the created revision for the file upload

 * @summary Upload deployment file
 */
export const revisionsFileUpload = (
  projectName: string,
  deploymentName: string,
  version: string,
  revisionsFileUploadBody: RevisionsFileUploadBody,
  options?: SecondParameter<typeof orvalAxios>
) => {
  const formData = new FormData();
  if (revisionsFileUploadBody.file !== undefined) {
    formData.append("file", revisionsFileUploadBody.file);
  }
  if (revisionsFileUploadBody.source_deployment !== undefined) {
    formData.append(
      "source_deployment",
      revisionsFileUploadBody.source_deployment
    );
  }
  if (revisionsFileUploadBody.source_version !== undefined) {
    formData.append("source_version", revisionsFileUploadBody.source_version);
  }
  if (revisionsFileUploadBody.template_deployment_id !== undefined) {
    formData.append(
      "template_deployment_id",
      revisionsFileUploadBody.template_deployment_id
    );
  }

  return orvalAxios<RevisionCreate>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/versions/${version}/revisions`,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options
  );
};

/**
 * 
### Description
Retrieve details of a single revision of a version

### Response Structure
A dictionary containing details of the revision
- `id`: Unique identifier for the revision (UUID)
- `version`: Version to which the revision is linked
- `creation_date`: The date when the revision was created
- `created_by`: The email of the user that uploaded the deployment file. In case the revision is created by a service, the field will have a "UbiOps" value.
- `status`: Status of the revision. Can be 'queued', 'building', 'success' or 'failed'.
- `error_message`: Error message which explains why the revision has failed. It is empty if the revision is successful.
- `has_request_method`: Whether the deployment code corresponding to the revision has a 'request' method
- `has_requests_method`: Whether the deployment code corresponding to the revision has a 'requests' method

#### Response Examples
```
{
  "id": "a009d7c9-67e4-4d3c-89fd-d3c8b07c7242",
  "version": "v1",
  "creation_date": "2020-12-23T16:35:13.069+00:00",
  "created_by": "test@example.com",
  "status": "success",
  "error_message": "",
  "has_request_method": true,
  "has_requests_method": false
}
```

 * @summary Get revision
 */
export const revisionsGet = (
  projectName: string,
  deploymentName: string,
  version: string,
  revisionId: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<RevisionList>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/versions/${version}/revisions/${revisionId}`,
      method: "get",
    },
    options
  );
};

export const getRevisionsGetKey = (
  projectName: string,
  deploymentName: string,
  version: string,
  revisionId: string
) => [
  `/projects/${projectName}/deployments/${deploymentName}/versions/${version}/revisions/${revisionId}`,
];

export type RevisionsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof revisionsGet>>
>;
export type RevisionsGetQueryError = ErrorType<unknown>;

export const useRevisionsGet = <TError = ErrorType<unknown>>(
  projectName: string,
  deploymentName: string,
  version: string,
  revisionId: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof revisionsGet>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false &&
    !!(projectName && deploymentName && version && revisionId);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getRevisionsGetKey(projectName, deploymentName, version, revisionId)
        : null);
  const swrFn = () =>
    revisionsGet(
      projectName,
      deploymentName,
      version,
      revisionId,
      requestOptions
    );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Download the deployment file of a revision of a version

### Response Structure
 - `file`: Deployment file of the version

 * @summary Download deployment file
 */
export const revisionsFileDownload = (
  projectName: string,
  deploymentName: string,
  version: string,
  revisionId: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<Blob>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/versions/${version}/revisions/${revisionId}/download`,
      method: "get",
      responseType: "blob",
    },
    options
  );
};

export const getRevisionsFileDownloadKey = (
  projectName: string,
  deploymentName: string,
  version: string,
  revisionId: string
) => [
  `/projects/${projectName}/deployments/${deploymentName}/versions/${version}/revisions/${revisionId}/download`,
];

export type RevisionsFileDownloadQueryResult = NonNullable<
  Awaited<ReturnType<typeof revisionsFileDownload>>
>;
export type RevisionsFileDownloadQueryError = ErrorType<unknown>;

export const useRevisionsFileDownload = <TError = ErrorType<unknown>>(
  projectName: string,
  deploymentName: string,
  version: string,
  revisionId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof revisionsFileDownload>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false &&
    !!(projectName && deploymentName && version && revisionId);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getRevisionsFileDownloadKey(
            projectName,
            deploymentName,
            version,
            revisionId
          )
        : null);
  const swrFn = () =>
    revisionsFileDownload(
      projectName,
      deploymentName,
      version,
      revisionId,
      requestOptions
    );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Trigger a rebuild for a revision of a deployment

### Response Structure
Details of the created revision
- `id`: Unique identifier for the revision (UUID)
- `version`: Version to which the revision is linked
- `creation_date`: The date when the revision was created
- `created_by`: The email of the user that created the revision
- `status`: Status of the revision
- `error_message`: Error message which explains why the revision has failed. It is empty if the revision is successful.
- `has_request_method`: Whether the deployment code corresponding to the revision has a 'request' method
- `has_requests_method`: Whether the deployment code corresponding to the revision has a 'requests' method

#### Response Examples
```
{
  "id": "a009d7c9-67e4-4d3c-89fd-d3c8b07c7242",
  "version": "v1",
  "creation_date": "2022-12-23T16:35:13.069+00:00",
  "created_by": "test@example.com",
  "status": "building",
  "error_message": "",
  "has_request_method": true,
  "has_requests_method": false
}
```

 * @summary Rebuild revision
 */
export const revisionsRebuild = (
  projectName: string,
  deploymentName: string,
  version: string,
  revisionId: string,
  revisionRebuild: RevisionRebuild,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<RevisionList>(
    {
      url: `/projects/${projectName}/deployments/${deploymentName}/versions/${version}/revisions/${revisionId}/rebuild`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: revisionRebuild,
    },
    options
  );
};

/**
 * 
### Description
Get the list of all available template deployments

### Response Structure
- `id`: Unique identifier for the template deployment (UUID)
- `details`: A dictionary containing all the required fields to create a deployment and a deployment version for the template deployment

#### Response Examples
```	
[
  {
    "id": "acb0c49a-23f8-4b04-94ed-a9c1a5a0119b",
    "details": {
      "name": "deployment-2",
      "input_type": "structured",
      "output_type": "structured",
      "input_fields": [
        {
          "name": "field-1",
          "data_type": "int"
        }
      ],
      "output_fields": [
        {
          "name": "field-1",
          "data_type": "int"
        }
      ],
      "labels": {
        "template": "True"
      },
      "description": "",
      "version": {
        "name": "v2",
        "environment": "python3-11",
        "description": "",
        "labels": {
          "template": "True"
        },
        "instance_type_group_id": "530c0878-d73c-4ea5-9f5d-f83bc1eeacd7",
        "maximum_idle_time": "300",
        "maximum_instances": "1",
        "minimum_instances": "0",
        "request_retention_mode": "full",
        "request_retention_time": 604800
      }
    }
  }
]
```

 * @summary List template deployments
 */
export const templateDeploymentsList = (
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<TemplateDeploymentList[]>(
    { url: `/template-deployments`, method: "get" },
    options
  );
};

export const getTemplateDeploymentsListKey = () => [`/template-deployments`];

export type TemplateDeploymentsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof templateDeploymentsList>>
>;
export type TemplateDeploymentsListQueryError = ErrorType<unknown>;

export const useTemplateDeploymentsList = <
  TError = ErrorType<unknown>
>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof templateDeploymentsList>>,
    TError
  > & { swrKey?: Key; enabled?: boolean };
  request?: SecondParameter<typeof orvalAxios>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getTemplateDeploymentsListKey() : null));
  const swrFn = () => templateDeploymentsList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
