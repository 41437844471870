import LogsIcon from "@mui/icons-material/Subject";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { BaseButton } from "components/atoms/Button/BaseButton";
import { CONTINUOUS_REQUEST_DELAY } from "libs/constants/constants";
import {
  DEPLOYMENT_PERMISSIONS,
  PROJECT_PERMISSIONS,
} from "libs/constants/permissions";
import { usePermissionValidation } from "libs/data/customized/roles";
import {
  useDeploymentVersionsGet,
  useDeploymentVersionEnvironmentVariablesList,
} from "libs/data/endpoints/deployments/deployments";
import {
  useEnvironmentsGet,
  useEnvironmentBuildsGet,
} from "libs/data/endpoints/environments/environments";
import { RevisionListStatus } from "libs/data/models";
import { useInterval } from "libs/hooks";
import { DATE_TIME_FORMAT, getTzAwareDate } from "libs/utilities/date-util";
import { formatLabels } from "libs/utilities/labels-util";
import { LOADED } from "libs/utilities/request-statuses";
import { formatStatusLabel } from "libs/utilities/statuses";
import { routes } from "routes";
import { useGetPermissions } from "store/features/permissions";

import {
  Alert,
  Card,
  CopyToClipboardButton,
  DetailsItem,
  Dialog,
  DialogHeaderTitle,
  Link,
  OverflowTooltip,
  StatusIcon,
} from "components/atoms";
import { DescriptionBlock } from "components/molecules";

import { RunsOverview } from "./RunsOverview";
import { Logs } from "../../../logs";
import { loadLogs } from "../../../logs/utils";
import { FILE_REF } from "../../../storage/constants";
import { mapStorageLinkToAppLink } from "../../../storage/utils";
import { SYS_DEFAULT_BUCKET, TRAINING_DEPLOYMENT } from "../../constants";

import type { LogRecord } from "../../../logs/types";
import type { LogsCreate } from "libs/data/models";

export const ExperimentGeneral = () => {
  const [isLogsDialogOpen, setIsLogsDialogOpen] = useState(false);
  const [logs, setLogs] = useState<LogRecord[]>([]);
  const { organizationName, experimentName, projectName } =
    useParams<{
      experimentName: string;
      projectName: string;
      organizationName: string;
    }>();

  const { data: experimentDetails, mutate } = useDeploymentVersionsGet(
    projectName,
    TRAINING_DEPLOYMENT,
    experimentName
  );
  const { data: environment } = useEnvironmentsGet(
    projectName,
    experimentDetails?.environment ?? ""
  );
  const { data: environmentBuild } = useEnvironmentBuildsGet(
    projectName,
    experimentDetails?.environment ?? "",
    environment?.latest_revision ?? "",
    environment?.latest_build ?? ""
  );
  const { data: environmentVariables } =
    useDeploymentVersionEnvironmentVariablesList(
      projectName,
      TRAINING_DEPLOYMENT,
      experimentName
    );

  const [currentPermissions] = usePermissionValidation(
    projectName,
    Object.values(DEPLOYMENT_PERMISSIONS),
    TRAINING_DEPLOYMENT,
    "deployment"
  );
  const [projectPermissions] = useGetPermissions();

  const isRunning = useMemo(() => {
    return experimentDetails?.status === "building";
  }, [experimentDetails?.status]);

  const defaultBucket = useMemo(() => {
    const bucket = environmentVariables?.find(
      (item) => item.name === SYS_DEFAULT_BUCKET
    );
    if (bucket) {
      return { name: bucket.value, path: `${FILE_REF}${bucket.value}` };
    } else return { name: "default", path: `${FILE_REF}default` };
  }, [environmentVariables]);

  const getBuildLogs = useCallback(
    async (params: LogsCreate) => {
      const newLogs = await loadLogs(projectName, {
        ...params,
        filters: {
          deployment_name: TRAINING_DEPLOYMENT,
          deployment_version: experimentName,
        },
        limit: 100,
      });
      if (newLogs.length) setLogs(logs ? logs.concat(newLogs) : newLogs);
    },
    [experimentName, logs, projectName]
  );

  useInterval(
    () => {
      if (isRunning) {
        if (logs?.length) {
          getBuildLogs({
            id: logs[logs.length - 1].id,
            date_range: CONTINUOUS_REQUEST_DELAY,
          });
        } else {
          getBuildLogs({
            date: moment(experimentDetails?.creation_date).toISOString(),
            date_range: CONTINUOUS_REQUEST_DELAY,
          });
        }
      }
    },
    [isRunning, logs, getBuildLogs, experimentDetails?.creation_date],
    CONTINUOUS_REQUEST_DELAY,
    true
  );

  useInterval(
    () => {
      const createdRecently =
        moment().diff(experimentDetails?.creation_date, "seconds") < 30;

      if (
        createdRecently ||
        experimentDetails?.status === RevisionListStatus.building
      ) {
        mutate();
      }
    },
    [mutate, experimentDetails],
    CONTINUOUS_REQUEST_DELAY,
    true
  );

  return (
    <>
      {environmentBuild?.error_message && (
        <Alert variant="filled" severity="error">
          Last building failed: {environmentBuild?.error_message}
        </Alert>
      )}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Card>
            <DetailsItem title="Status">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <StatusIcon
                  label={formatStatusLabel(experimentDetails?.status)}
                  status={formatStatusLabel(experimentDetails?.status)}
                />
                {isRunning && (
                  <Tooltip title="Building logs">
                    <BaseButton
                      color="secondary"
                      size="small"
                      startIcon={<LogsIcon />}
                      onClick={() => setIsLogsDialogOpen(true)}
                    >
                      Logs
                    </BaseButton>
                  </Tooltip>
                )}
              </Box>
            </DetailsItem>
            <DetailsItem title="ID">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <OverflowTooltip title={experimentDetails?.id}>
                  {experimentDetails?.id}
                </OverflowTooltip>
                {experimentDetails?.id && (
                  <CopyToClipboardButton
                    defaultLabel="Copy ID"
                    contentToCopy={experimentDetails?.id}
                    size="small"
                    htmlColor="secondary"
                  />
                )}
              </Box>
            </DetailsItem>

            <DetailsItem title="Created">
              <Typography>
                {getTzAwareDate(experimentDetails?.creation_date).format(
                  DATE_TIME_FORMAT
                )}
              </Typography>
            </DetailsItem>
            <DetailsItem title="Edited">
              <Typography>
                {getTzAwareDate(experimentDetails?.last_updated).format(
                  DATE_TIME_FORMAT
                )}
              </Typography>
            </DetailsItem>
            <DetailsItem title="Environment">
              {experimentDetails?.environment &&
              environment?.base_environment ? (
                <Link
                  to={routes.organizations[":organizationName"](
                    organizationName
                  )
                    .projects[":projectName"](projectName)
                    .environments.custom[":environmentName"](
                      experimentDetails.environment
                    )
                    .details.general.index()}
                >
                  {experimentDetails?.environment_display_name}
                </Link>
              ) : (
                <Typography>
                  {experimentDetails?.environment_display_name}
                </Typography>
              )}
            </DetailsItem>
            <DetailsItem title="Instance type group">
              <Link
                to={routes.organizations[":organizationName"](organizationName)
                  .projects[":projectName"](projectName)
                  .projectSettings.instanceTypeGroups.index()}
              >
                {experimentDetails?.instance_type_group_name}
              </Link>
            </DetailsItem>
            <DetailsItem title="Default bucket">
              <Link
                to={mapStorageLinkToAppLink(
                  defaultBucket.path,
                  organizationName,
                  projectName
                )}
                color="secondary"
              >
                {defaultBucket.name}
              </Link>
            </DetailsItem>
          </Card>
        </Grid>

        <DescriptionBlock
          description={experimentDetails?.description}
          labels={
            experimentDetails?.labels
              ? formatLabels(experimentDetails?.labels)
              : []
          }
        />
        <Grid item xs={12}>
          <RunsOverview
            allowGet={
              currentPermissions[DEPLOYMENT_PERMISSIONS["version_request_get"]]
            }
            allowLogs={projectPermissions[PROJECT_PERMISSIONS["logs_get"]]}
            allowDelete={
              currentPermissions[
                DEPLOYMENT_PERMISSIONS["version_request_delete"]
              ]
            }
            allowUpdate={
              currentPermissions[
                DEPLOYMENT_PERMISSIONS["version_request_update"]
              ]
            }
          />
        </Grid>
      </Grid>
      <Dialog
        open={isLogsDialogOpen}
        onClose={() => setIsLogsDialogOpen(false)}
        Header={
          <>
            <DialogHeaderTitle>{experimentName} logs</DialogHeaderTitle>
            <StatusIcon
              label={experimentDetails?.status}
              status={experimentDetails?.status ?? ""}
            />
          </>
        }
        maxWidth="md"
        dialogBodyStyles={{
          paddingTop: 0,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "200px",
          maxHeight: "680px",
        }}
      >
        <Logs
          logSet={{ logs: logs }}
          autoLoading={isRunning}
          logsStatus={LOADED}
          refresh={false}
          displayNoLogsMessage={false}
          isBuildingLogs={true}
        />
      </Dialog>
    </>
  );
};
