import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Button, Grid, Typography, useTheme } from "@mui/material";

import { NavLink, InfoTooltip } from "components/atoms";

import type { AppThemeProps } from "assets/styles/theme/theme";

type CreateBatchRequestSectionProps = {
  objectType: "deployment" | "pipeline";
  createBatchRequestsUrl: string;
  onClickCreateBatchRequest: () => void;
};

export const CreateBatchRequestSection = ({
  objectType,
  createBatchRequestsUrl,
  onClickCreateBatchRequest,
}: CreateBatchRequestSectionProps) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <Grid item container spacing={1} alignItems="center">
      <Grid item xs={4} container alignItems="center">
        <Typography variant="h3">Batch requests</Typography>
        <InfoTooltip>
          Batch request allows to send multiple data points to the {objectType}.
        </InfoTooltip>
      </Grid>
      <Grid item xs={8} container justifyContent="flex-end">
        <Button
          startIcon={<OpenInNewIcon />}
          variant="contained"
          size="small"
          component={NavLink}
          to={createBatchRequestsUrl}
          onClick={onClickCreateBatchRequest}
          sx={{
            color: `${theme.palette.primary.contrastText} !important`,
          }}
        >
          Create batch requests
        </Button>
      </Grid>
    </Grid>
  );
};
