import { Box, ToggleButtonGroup, ToggleButton, useTheme } from "@mui/material";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";

import { spacing } from "assets/styles/theme";
import { DateTimeRangeInput } from "components/molecules/Fields/DateTimeRangeSelector";
import {
  END_DATE_FILTER,
  FIELD_DATE,
  PIPELINE_FILTER,
  PROGRESS_FILTER,
  START_DATE_FILTER,
  SUCCESS_FILTER,
} from "libs/constants/fields";

import "./RequestsFilter.scss";

import type { Filter } from "./RequestsToolbar";
import type { AppThemeProps } from "assets/styles/theme/theme";
import type { ReactElement } from "react";

export const RequestsFilter = ({
  setFilters,
  defaultValues,
  pipelineFilter = false,
  children,
}: RequestsFilterProps) => {
  const formMethods = useForm({ defaultValues });
  const theme = useTheme() as AppThemeProps;
  const { control, getValues, setValue } = formMethods;

  const status = useWatch({ control, name: "status" });

  const updateFilters = () => {
    setFilters(getValues() as Filter);
  };

  const handleDateFilterRemove = () => {
    setValue(START_DATE_FILTER, undefined);
    setValue(END_DATE_FILTER, undefined);
    updateFilters();
  };

  const buttonStyles = {
    color: theme.palette.text.secondary,
    borderColor: theme.palette.buttons.toggleButtonBorder,

    "&:hover": {
      backgroundColor: theme.palette.buttons.toggleButtonHover,
      color: theme.palette.text.primary,
      borderLeft: `1px solid ${theme.palette.buttons.toggleButtonBorder} !important`,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.buttons.toggleButtonHover,
      color: theme.palette.text.primary,
    },
    "&.Mui-selected:hover": {
      backgroundColor: theme.palette.buttons.toggleButtonHover,
      color: theme.palette.text.primary,
    },
  };

  return (
    <FormProvider {...formMethods}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        width="100%"
      >
        <Box display="flex" marginTop={spacing[12]}>
          <Controller
            control={control}
            name="status"
            render={({ onChange, onBlur, value, ref }) => (
              <ToggleButtonGroup
                onBlur={onBlur}
                onChange={(_, value) => {
                  onChange(value);
                  updateFilters();
                }}
                value={value}
                ref={ref}
                exclusive
                size="small"
                style={{ marginRight: spacing[8] }}
              >
                <ToggleButton
                  disableFocusRipple={true}
                  value={SUCCESS_FILTER[0]}
                  className="requests-filters"
                  sx={buttonStyles}
                >
                  All
                </ToggleButton>
                <ToggleButton
                  disableFocusRipple={true}
                  value={SUCCESS_FILTER[1]}
                  className={[
                    "requests-filters",
                    status === SUCCESS_FILTER[1] && "requests-filters--success",
                  ]
                    .filter((x) => x)
                    .join(" ")}
                  sx={buttonStyles}
                >
                  Success
                </ToggleButton>
                <ToggleButton
                  disableFocusRipple={true}
                  value={SUCCESS_FILTER[2]}
                  className={[
                    "requests-filters",
                    status === SUCCESS_FILTER[2] && "requests-filters--failed",
                  ]
                    .filter((x) => x)
                    .join(" ")}
                  sx={buttonStyles}
                >
                  Failed
                </ToggleButton>
                <ToggleButton
                  disableFocusRipple={true}
                  value={PROGRESS_FILTER[0]}
                  className={[
                    "requests-filters",
                    status === PROGRESS_FILTER[0] &&
                      "requests-filters--processing",
                  ]
                    .filter((x) => x)
                    .join(" ")}
                  sx={buttonStyles}
                >
                  Processing
                </ToggleButton>
                <ToggleButton
                  disableFocusRipple={true}
                  value={PROGRESS_FILTER[1]}
                  className={[
                    "requests-filters",
                    status === PROGRESS_FILTER[1] &&
                      "requests-filters--pending",
                  ]
                    .filter((x) => x)
                    .join(" ")}
                  sx={buttonStyles}
                >
                  Pending
                </ToggleButton>
              </ToggleButtonGroup>
            )}
          />

          {pipelineFilter && (
            <Box mr={1}>
              <Controller
                control={control}
                name="pipeline"
                render={({ onChange, onBlur, value, ref }) => (
                  <ToggleButtonGroup
                    onBlur={onBlur}
                    onChange={(_, value) => {
                      onChange(value);
                      updateFilters();
                    }}
                    value={value}
                    ref={ref}
                    exclusive
                    size="small"
                  >
                    <ToggleButton
                      disableFocusRipple={true}
                      value={PIPELINE_FILTER[0]}
                      className="requests-filters"
                      sx={buttonStyles}
                    >
                      All
                    </ToggleButton>
                    <ToggleButton
                      disableFocusRipple={true}
                      value={PIPELINE_FILTER[1]}
                      className="requests-filters"
                      sx={buttonStyles}
                    >
                      In pipeline requests
                    </ToggleButton>
                    <ToggleButton
                      disableFocusRipple={true}
                      value={PIPELINE_FILTER[2]}
                      className="requests-filters"
                      sx={buttonStyles}
                    >
                      Not in pipeline requests
                    </ToggleButton>
                  </ToggleButtonGroup>
                )}
              />
            </Box>
          )}
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems="baseline">
          <Controller
            control={control}
            name={FIELD_DATE}
            render={({ onChange }) => (
              <DateTimeRangeInput
                name={FIELD_DATE}
                startName={START_DATE_FILTER}
                endName={END_DATE_FILTER}
                handleDateChange={(value) => {
                  updateFilters();
                  onChange(value);
                }}
                defaultValue={
                  !!defaultValues.start_date && !!defaultValues.end_date
                    ? {
                        startDate: defaultValues.start_date,
                        endDate: defaultValues.end_date,
                      }
                    : undefined
                }
                setDefaultRange={handleDateFilterRemove}
              />
            )}
          />
          {children}
        </Box>
      </Box>
    </FormProvider>
  );
};

type RequestsFilterProps = {
  setFilters: (args: Filter) => void;
  defaultValues: {
    limit?: number;
    offset?: number;
    sort?: "asc" | "desc";
    object_type?: string;
    search_id?: string;
    success?: boolean;
    status?: string | boolean;
    pipeline?: string | boolean;
    start_date?: string;
    end_date?: string;
  };
  pipelineFilter?: boolean;
  children?: ReactElement;
};
