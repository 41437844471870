import { useCallback } from "react";

import {
  batchPipelineRequestsCreate,
  batchPipelineVersionRequestsCreate,
} from "libs/data/endpoints/pipeline-requests/pipeline-requests";

import type { DeploymentRequestsCreateDataBody } from "libs/data/models";

export const usePipelineBatchRequestsCreate = (
  projectName?: string,
  pipelineName?: string,
  version?: string | null,
  notificationGroup?: string,
  timeout?: number
) => {
  const pipelineBatchRequest = useCallback(
    (
      data: Array<DeploymentRequestsCreateDataBody>,
      onRequestHasBeenMade: () => void
    ) => {
      if (!projectName || !pipelineName) return Promise.reject();

      const promise = version
        ? batchPipelineVersionRequestsCreate(
            projectName,
            pipelineName,
            version,
            data,
            { timeout, notification_group: notificationGroup }
          )
        : batchPipelineRequestsCreate(projectName, pipelineName, data, {
            timeout,
            notification_group: notificationGroup,
          });

      onRequestHasBeenMade?.();

      return promise;
    },
    [pipelineName, notificationGroup, projectName, timeout, version]
  );

  return pipelineBatchRequest;
};
