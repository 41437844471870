import OrganizationIcon from "@mui/icons-material/Apartment";
import {
  List,
  ListItem,
  ListItemIcon,
  Typography,
  Tooltip,
  Box,
  useTheme,
} from "@mui/material";
import { isEmpty } from "lodash";
import { useMemo, useCallback, useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { AutoCompleteSelect } from "components/atoms/AutoCompleteSelect";
import {
  useOrganizationsFeaturesGet,
  useOrganizationsGet,
} from "libs/data/endpoints/organizations/organizations";
import { env, ENV_NAMES } from "libs/env";
import {
  setOrganization,
  setOrganizationFeatures,
  useGetCurrentOrganization,
  useGetOrganizations,
} from "store/features";
import { toggleTasksManager, useTaskManager } from "store/features/taskManager";

import { ConditionalWrapper } from "components/atoms";
import "./SidebarOrganizationSelect.scss";

import type { AppThemeProps } from "assets/styles/theme/theme";

interface SidebarOrganizationSelectProps {
  toggleSidebar: () => void;
  sidebarIsOpen: boolean;
}

const SidebarOrganizationSelect = ({
  sidebarIsOpen,
  toggleSidebar,
}: SidebarOrganizationSelectProps) => {
  const formMethods = useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedOrg, setSelectedOrg] = useState("");
  const theme = useTheme() as AppThemeProps;
  const { tasks } = useTaskManager();

  const { data: newOrganization } = useOrganizationsGet(selectedOrg, {
    swr: { enabled: !!selectedOrg },
  });

  const { data: newOrganizationFeatures } = useOrganizationsFeaturesGet(
    selectedOrg,
    { swr: { enabled: !!selectedOrg } }
  );

  const organizations = useGetOrganizations();
  const organization = useGetCurrentOrganization();

  useEffect(() => {
    if (newOrganization) {
      dispatch(setOrganization(newOrganization));
      newOrganizationFeatures &&
        dispatch(setOrganizationFeatures(newOrganizationFeatures));
    }
  }, [newOrganization, dispatch, newOrganizationFeatures]);

  const handleOrganizationChange = useCallback(
    ({ value: newOrganization }) => {
      env.set(ENV_NAMES.LAST_SEEN_PROJECT, null);

      setSelectedOrg(newOrganization);
      history.push(`/organizations/${newOrganization}/dashboard`);

      if (!isEmpty(tasks)) {
        toggleTasksManager();
      }
      toggleSidebar();
    },
    [history, tasks, toggleSidebar]
  );

  const organizationOptions = useMemo(() => {
    return organizations
      ? Object.values(organizations).map((org) => ({
          label: org.name,
          value: org.name,
        }))
      : [];
  }, [organizations]);

  return (
    <>
      {organization && (
        <List
          className="sidebar-org-select"
          sx={{
            bgcolor: theme.palette.sidebarMenu.organization,
            "& svg": { color: theme.palette.primary.contrastText },
          }}
        >
          <ConditionalWrapper
            condition={!sidebarIsOpen}
            wrapper={(children) => (
              <Tooltip title="Organization" placement="right">
                {children}
              </Tooltip>
            )}
          >
            <ListItem className="sidebar-org-select__listItem">
              <ListItemIcon className="sidebar-org-select__icon">
                <Link
                  to={`/organizations/${organization.name}/dashboard`}
                  style={{ display: "flex" }}
                >
                  <OrganizationIcon
                    fontSize="small"
                    sx={{ color: theme.palette.primary.contrastText }}
                  />
                </Link>
              </ListItemIcon>
              {sidebarIsOpen && (
                <Tooltip
                  placement="right"
                  title={
                    <Typography variant="h3">
                      {organization.name || ""}
                    </Typography>
                  }
                >
                  <Box width={"100%"}>
                    {organizations?.length > 1 ? (
                      <FormProvider {...formMethods}>
                        <Box width={"100%"}>
                          <AutoCompleteSelect
                            options={organizationOptions}
                            value={{
                              label: organization.name,
                              value: organization.name,
                            }}
                            onChange={handleOrganizationChange}
                            styles={{
                              "& .MuiAutocomplete-endAdornment": {
                                border: 0,
                              },
                              "& .MuiInputBase-root": {
                                color: theme.palette.primary.contrastText,
                              },
                            }}
                          />
                        </Box>
                      </FormProvider>
                    ) : (
                      <div className="sidebar-org-select__orgNameBlock">
                        <Typography
                          variant="h5"
                          noWrap
                          sx={{
                            color: theme.palette.sidebarMenu.organizationText,
                          }}
                        >
                          {organization.name || ""}
                        </Typography>
                      </div>
                    )}
                  </Box>
                </Tooltip>
              )}
            </ListItem>
          </ConditionalWrapper>
        </List>
      )}
    </>
  );
};

export default SidebarOrganizationSelect;
