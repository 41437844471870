import { useOrganizationUsersGet } from "libs/data/endpoints/organizations/organizations";
import { useUserGet } from "libs/data/endpoints/user/user";

import type { TError } from "libs/data/axios";

export const useOrganizationUser = (organizationName?: string) => {
  const { data: user } = useUserGet();
  const { data, error, ...rest } = useOrganizationUsersGet<TError>(
    organizationName || "",
    user?.id || ""
  );

  return {
    // if it returns 404 the user is a superuser so should be treated like an admin
    // if you're a superuser and you are already in the organization, the endpoint
    // won't give you 404 status code. So you won't be able to see the other pages
    // of the organization. Sorry!
    isAdmin: data?.admin || error?.status === 404,
    data,
    error,
    ...rest,
  };
};
