import { useCallback, useMemo } from "react";

import {
  getPossibleRequestRetentionTimes,
  requestRetentionModes,
} from "libs/utilities/labels-mapping";
import { useGetOrganizationFeatures } from "store/features";

export const useRetentionTimeSettings = () => {
  const organizationFeatures = useGetOrganizationFeatures();

  const possibleRequestRetentionTimesOptions = useMemo(
    () =>
      getPossibleRequestRetentionTimes(
        organizationFeatures?.max_retention_requests ?? 0
      ),
    [organizationFeatures]
  );

  const noRequestRetentionMode = useCallback(
    (retention_mode) => retention_mode === requestRetentionModes[0]?.value,
    []
  );

  const defaultRetentionTime = useMemo(
    () => possibleRequestRetentionTimesOptions.slice(-1)[0]?.value,
    [possibleRequestRetentionTimesOptions]
  );

  return {
    defaultRetentionTime,
    noRequestRetentionMode,
    possibleRequestRetentionTimesOptions,
  };
};
