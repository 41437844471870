import { useTheme } from "@mui/styles";

export const OperatorNodeRaiseError = () => {
  const theme = useTheme();

  return (
    <svg
      width="85"
      height="76"
      viewBox="0 0 85 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4316_26013)">
        <path
          d="M41.6422 75.7907C62.4314 75.7907 79.2844 58.9378 79.2844 38.1485C79.2844 17.3593 62.4314 0.506348 41.6422 0.506348C20.853 0.506348 4 17.3593 4 38.1485C4 58.9378 20.853 75.7907 41.6422 75.7907Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M39.0342 26.5952H44.2519V41.1717H39.0342V26.5952ZM39.0342 50.4477V45.1471H44.2519V50.4477H39.0342Z"
          fill="white"
        />
        <path
          d="M5 43.5C7.48528 43.5 9.5 41.4853 9.5 39C9.5 36.5147 7.48528 34.5 5 34.5C2.51472 34.5 0.5 36.5147 0.5 39C0.5 41.4853 2.51472 43.5 5 43.5Z"
          fill={theme.palette.primary.main}
          stroke="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4316_26013">
          <rect width="85" height="76" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
