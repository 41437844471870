import { Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import {
  CONTINUOUS_REQUEST_DELAY,
  REQUEST_DATA_MAX_SIZE,
} from "libs/constants/constants";
import {
  deploymentRequestsGet,
  deploymentVersionRequestsGet,
  useDeploymentRequestsGet,
  useDeploymentVersionRequestsGet,
} from "libs/data/endpoints/deployment-requests/deployment-requests";
import { useDeploymentsGet } from "libs/data/endpoints/deployments/deployments";
import {
  pipelineRequestsGet,
  pipelineVersionRequestsGet,
  usePipelineRequestsGet,
  usePipelineVersionRequestsGet,
} from "libs/data/endpoints/pipeline-requests/pipeline-requests";
import { usePipelinesGet } from "libs/data/endpoints/pipelines/pipelines";

import { Loader } from "components/atoms";

import { DeploymentRequestResults } from "./DeploymentRequestResults";
import { PipelineRequestResults } from "./PipelineRequestResults";
import { openDataInNewTab } from "./utils";

import type {
  DeploymentRequestCreateResponseResult,
  InputOutputFieldDetail,
  PipelineRequestCreateResponseResult,
} from "libs/data/models";

export type RequestResultsProps = {
  organizationName: string;
  projectName: string;
  resourceType?: "deployment" | "pipeline";
  resourceName?: string;
  resourceVersion?: string | null;
  requestId?: string;
  directResult?:
    | DeploymentRequestCreateResponseResult
    | PipelineRequestCreateResponseResult;
  fetchOnlyMetadata?: boolean;
};

export const RequestResults = ({
  resourceType,
  projectName,
  organizationName,
  resourceName,
  resourceVersion,
  requestId,
  directResult,
  fetchOnlyMetadata = true,
}: RequestResultsProps) => {
  const isDeployment = resourceType === "deployment";
  const isPipeline = resourceType === "pipeline";
  const isObjectDeletedAlready =
    resourceName === null || resourceVersion === null;
  const [data, setData] = useState<any>(undefined);
  const [metadataOnly, setMetadataOnly] = useState(fetchOnlyMetadata);

  const versionRequest = isDeployment
    ? useDeploymentVersionRequestsGet
    : usePipelineVersionRequestsGet;

  const resourceRequestGet = isDeployment
    ? useDeploymentRequestsGet
    : usePipelineRequestsGet;

  const resourceGet = isDeployment ? useDeploymentsGet : usePipelinesGet;

  const { data: versionRequestData, mutate: versionRequestMutate } =
    versionRequest(
      projectName || "",
      resourceName || "",
      resourceVersion || "",
      requestId || "",
      {
        metadata_only: metadataOnly,
      }
    );

  const { data: requestData, mutate: requestMutate } = resourceRequestGet(
    resourceVersion ? "" : projectName || "",
    resourceName || "",
    requestId || "",
    { metadata_only: metadataOnly }
  );

  const { data: resourceData } = resourceGet(projectName, resourceName || "");

  useEffect(() => {
    const _requestData = versionRequestData || requestData;

    if (directResult?.result && _requestData && !_requestData?.result) {
      _requestData.result = directResult.result;
    }
    if (
      _requestData?.input_size &&
      _requestData?.input_size > REQUEST_DATA_MAX_SIZE &&
      !metadataOnly &&
      !_requestData?.output_size
    ) {
      setMetadataOnly(true);
    }
    if (
      _requestData?.output_size &&
      _requestData.output_size > REQUEST_DATA_MAX_SIZE &&
      !metadataOnly
    ) {
      setMetadataOnly(true);
    }

    setData(_requestData ?? directResult);
  }, [directResult, metadataOnly, requestData, versionRequestData]);

  useEffect(() => {
    let intervalId: NodeJS.Timer | null = null;
    const mutate = resourceVersion ? versionRequestMutate : requestMutate;
    const requestIsUnderProcess =
      data?.status === "pending" || data?.status === "processing";

    if (data && requestIsUnderProcess) {
      if (intervalId) {
        clearInterval(intervalId);
      }

      // Refresh request if there are in-building-process
      intervalId = setInterval(() => {
        mutate();
      }, CONTINUOUS_REQUEST_DELAY);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [
    requestData,
    requestMutate,
    data,
    resourceName,
    resourceVersion,
    versionRequestMutate,
  ]);

  const handleViewHugeResult = useCallback(async () => {
    const versionRequest = isDeployment
      ? deploymentVersionRequestsGet
      : pipelineVersionRequestsGet;
    const resourceRequest = isDeployment
      ? deploymentRequestsGet
      : pipelineRequestsGet;
    let response;

    if (!resourceName || !requestId) return;
    if (resourceVersion) {
      response = await versionRequest(
        projectName,
        resourceName,
        resourceVersion,
        requestId
      );
    } else {
      response = await resourceRequest(projectName, resourceName, requestId);
    }

    openDataInNewTab(response);
  }, [isDeployment, projectName, requestId, resourceName, resourceVersion]);

  const isDataReady =
    data && resourceData?.input_fields && resourceData?.output_fields;

  return isObjectDeletedAlready ? (
    <Typography variant="body1" color="textPrimary">
      This {resourceType || "deployment/pipeline"} has been deleted and the
      object version fields are no longer available.
    </Typography>
  ) : !isDataReady ? (
    <Loader />
  ) : isDeployment ? (
    <DeploymentRequestResults
      organizationName={organizationName}
      projectName={projectName}
      results={data}
      inputFields={resourceData?.input_fields as InputOutputFieldDetail[]}
      outputFields={resourceData?.output_fields as InputOutputFieldDetail[]}
      handleViewHugeResult={handleViewHugeResult}
    />
  ) : isPipeline ? (
    <PipelineRequestResults
      organizationName={organizationName}
      projectName={projectName}
      results={data}
      inputFields={resourceData?.input_fields as InputOutputFieldDetail[]}
      outputFields={resourceData?.output_fields as InputOutputFieldDetail[]}
      handleViewHugeResult={handleViewHugeResult}
    />
  ) : null;
};
