import { OutlinedInput, Typography } from "@mui/material";
import { useCallback, useState } from "react";

import { Dialog, DialogWarningHeader, PrimaryButton } from "components/atoms";

interface DeleteProjectDialogProps {
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
  projectName?: string;
}

export const DeleteProjectDialog = ({
  onClose,
  onConfirm,
  open,
  projectName,
}: DeleteProjectDialogProps) => {
  const [disabled, setDisabled] = useState(true);

  const handleCheckProjectNameMatch = useCallback(
    ({ target: { value } }) => setDisabled(value !== projectName),
    [projectName]
  );

  return (
    <Dialog
      onClose={onClose}
      open={open}
      Header={<DialogWarningHeader title="Warning" />}
      Actions={
        <PrimaryButton onClick={onConfirm} disabled={disabled}>
          Delete
        </PrimaryButton>
      }
    >
      Are you sure you want to permanently delete project &quot;
      <b>{projectName}</b>&quot; and all of its content?{" "}
      <Typography color="error" display="inline" variant="h5">
        You won&apos;t be able to restore it.
      </Typography>
      <br />
      <br />
      <Typography gutterBottom>
        Please type <b>{projectName}</b> to confirm
      </Typography>
      <OutlinedInput
        fullWidth
        margin="dense"
        size="small"
        onChange={handleCheckProjectNameMatch}
      />
    </Dialog>
  );
};
