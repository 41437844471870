import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  Typography,
  AccordionSummary,
  Grid,
  useTheme,
} from "@mui/material";
import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { useAccordionStyles } from "assets/styles/componentStyles";
import { PageHeader } from "components/molecules/PageLayout";
import { STRUCTURED_TYPE } from "libs/constants/constants";
import {
  FIELD_NAME,
  FIELD_DESCRIPTION,
  FIELD_INPUT_TYPE,
  FIELD_INPUT_FIELDS,
  FIELD_OUTPUT_TYPE,
  FIELD_OUTPUT_FIELDS,
  FIELD_LABELS,
  FIELD_VERSION,
  FIELD_DEFAULT_MONITORING_GROUP,
  FIELD_MONITORING_GROUP,
  FIELD_RETENTION_MODE,
  FIELD_RETENTION_TIME,
  FIELD_VERSION_DESCRIPTION,
  FIELD_VERSION_LABELS,
} from "libs/constants/fields";
import { RootUrlContext } from "libs/contexts";
import {
  usePipelineCreate,
  usePipelineVersionCreate,
} from "libs/data/customized/pipeline";
import { useNotificationGroupsList } from "libs/data/endpoints/monitoring/monitoring";
import { gtmEvent, useGoogleAnalytics } from "libs/hooks";
import { explanations } from "libs/utilities/explanations";
import { formatInputOutputFields } from "libs/utilities/input-parser";
import { formatLabelsForRequest } from "libs/utilities/labels-util";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";
import { UNLOADED, LOADING } from "libs/utilities/request-statuses";

import {
  InputOutputField,
  FormSection,
  GeneralFieldsSection,
  PageContainer,
} from "components/molecules";
import {
  FormContainer,
  LabelsForm,
  RequestSettings,
  NotificationGroupsForm,
} from "components/organisms";

const fields = {
  [FIELD_NAME]: "",
  [FIELD_INPUT_TYPE]: STRUCTURED_TYPE,
};

const PipelineCreate = () => {
  useGoogleAnalytics();
  const dispatch = useDispatch();
  const theme = useTheme();

  const history = useHistory();
  const { projectName, pipelineName } = useParams();
  const rootUrl = useContext(RootUrlContext);
  const { data: notificationGroupsList } =
    useNotificationGroupsList(projectName);

  const classes = useAccordionStyles();
  const methods = useForm({
    mode: "onBlur",
    defaultValues: fields,
  });
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);

  const createPipeline = usePipelineCreate(projectName);
  const createPipelineVersion = usePipelineVersionCreate(
    projectName,
    pipelineName
  );

  const handlePipelineCreate = async (data) => {
    const newPipeline = {
      [FIELD_NAME]: data[FIELD_NAME],
      [FIELD_DESCRIPTION]: data[FIELD_DESCRIPTION],
      [FIELD_INPUT_TYPE]: data[FIELD_INPUT_TYPE],
      [FIELD_OUTPUT_TYPE]: data[FIELD_OUTPUT_TYPE],
      [FIELD_LABELS]: formatLabelsForRequest(data[FIELD_LABELS]),
    };
    if (
      data[FIELD_INPUT_TYPE] === STRUCTURED_TYPE &&
      data[FIELD_INPUT_FIELDS]
    ) {
      newPipeline[FIELD_INPUT_FIELDS] = formatInputOutputFields(
        data[FIELD_INPUT_FIELDS]
      );
    }
    if (
      data[FIELD_OUTPUT_TYPE] === STRUCTURED_TYPE &&
      data[FIELD_OUTPUT_FIELDS]
    ) {
      newPipeline[FIELD_OUTPUT_FIELDS] = formatInputOutputFields(
        data[FIELD_OUTPUT_FIELDS]
      );
    }

    setIsLoadingCreate(true);
    createPipeline(newPipeline)
      .then(async () => {
        const versionData = {
          [FIELD_VERSION]: data[FIELD_VERSION],
          [FIELD_DESCRIPTION]: data[FIELD_VERSION_DESCRIPTION],
          [FIELD_LABELS]: formatLabelsForRequest(data[FIELD_VERSION_LABELS]),
          [FIELD_RETENTION_MODE]: data[FIELD_RETENTION_MODE]?.value,
          [FIELD_RETENTION_TIME]: data[FIELD_RETENTION_TIME]?.value,
          [FIELD_MONITORING_GROUP]: data[FIELD_MONITORING_GROUP]?.value ?? null,
          [FIELD_DEFAULT_MONITORING_GROUP]:
            data[FIELD_DEFAULT_MONITORING_GROUP]?.value ?? null,
        };

        return createPipelineVersion(versionData, data[FIELD_NAME]).then(() => {
          gtmEvent("pipeline_version_create", {
            event_category: "pipeline_versions",
          });

          dispatch(createSuccessNotification("The pipeline was created"));

          history.push(
            `${rootUrl}/pipelines/${data[FIELD_NAME]}/versions/${data[FIELD_VERSION]}`
          );
        });
      })
      .catch((e) => {
        dispatch(createErrorNotification(e.message));
      })
      .finally(() => {
        setIsLoadingCreate(false);
      });
  };

  return (
    <PageContainer>
      <PageHeader title="Create new pipeline" />
      <div>
        <FormContainer
          onSubmit={handlePipelineCreate}
          buttonLabel="Create"
          formMethods={methods}
          status={isLoadingCreate ? LOADING : UNLOADED}
        >
          <GeneralFieldsSection
            title="Name your pipeline"
            description={explanations.pipelines.templates}
            validateValue="pipeline"
            namePlaceholder="Ex: my-pipeline-1"
            descriptionPlacholder="Ex: A pipeline that does XYZ"
          />

          <GeneralFieldsSection
            title="Name your version"
            description={explanations.pipelines.version}
            name={FIELD_VERSION}
            ruleName={FIELD_VERSION}
            descriptionName={FIELD_VERSION_DESCRIPTION}
            validateValue="pipeline version"
            namePlaceholder="Ex: my-pipeline-version-1"
            nameDefaultValue="v1"
            descriptionPlacholder="Ex: my-pipeline-version-1"
            commonProps={{
              InputLabelProps: { shrink: true },
            }}
          />

          <InputOutputField
            title="Input"
            description={explanations.pipelines.input}
            typeName={FIELD_INPUT_TYPE}
            fieldsName={FIELD_INPUT_FIELDS}
          />

          <InputOutputField
            title="Output"
            description={explanations.pipelines.output}
            typeName={FIELD_OUTPUT_TYPE}
            fieldsName={FIELD_OUTPUT_FIELDS}
          />

          <FormSection
            title="Labels"
            description={explanations.labels.description("pipeline")}
          >
            <LabelsForm name={FIELD_LABELS} />
          </FormSection>

          <div className={classes.accordionContainer}>
            <Accordion
              className={classes.accordionWrapper}
              sx={{
                background: theme.palette.background.paper,
                "& svg": {
                  color: theme.palette.text.primary,
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                classes={{ content: classes.accSummaryContent }}
              >
                <Typography
                  variant="h3"
                  sx={{ color: theme.palette.text.primary }}
                >
                  Optional / Advanced settings
                </Typography>
              </AccordionSummary>
              <Grid
                container
                spacing={5}
                className={classes.advancedSettingsGrid}
              >
                <FormSection
                  title="Version labels"
                  description={explanations.labels.description(
                    "pipelineVersion"
                  )}
                >
                  <LabelsForm name={FIELD_VERSION_LABELS} />
                </FormSection>

                <RequestSettings noQueueTime />

                <NotificationGroupsForm
                  notificationGroups={notificationGroupsList}
                />
              </Grid>
            </Accordion>
          </div>
        </FormContainer>
      </div>
    </PageContainer>
  );
};

export default PipelineCreate;
