/**
 * Generated by orval 🍺
 * Do not edit manually.
 * UbiOps
 * OpenAPI spec version: v2.1
 */
import useSwr from "swr";

import { orvalAxios } from "../../axios/index";

import type { ErrorType } from "../../axios/index";
import type {
  OrganizationList,
  OrganizationCreate,
  OrganizationDetail,
  OrganizationUpdate,
  UsageDetail,
  OrganizationFeature,
  ResourceUsage,
  OrganizationSubscriptionList,
  OrganizationUsage,
  OrganizationsUsageGetParams,
  OrganizationUserDetail,
  OrganizationUserCreate,
  OrganizationUserUpdate,
  Voucher,
} from "../../models";
import type { SWRConfiguration, Key } from "swr";

// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * 
### Description
List all organizations where the user making the request is a member

### Response Structure
A list of details of the organizations
- `id`: Unique identifier for the organization (UUID)
- `name`: Name of the organization
- `creation_date`: Date and time the organization was created

#### Response Examples
```
[
  {
    "id": "45a1f903-4146-4f15-be4a-302455cd6f7e",
    "name": "organization-name",
    "creation_date": "2020-03-23T11:47:15.436240Z"
  }
]
```

 * @summary List organizations
 */
export const organizationsList = (
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<OrganizationList[]>(
    { url: `/organizations`, method: "get" },
    options
  );
};

export const getOrganizationsListKey = () => [`/organizations`];

export type OrganizationsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof organizationsList>>
>;
export type OrganizationsListQueryError = ErrorType<unknown>;

export const useOrganizationsList = <TError = ErrorType<unknown>>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof organizationsList>>,
    TError
  > & { swrKey?: Key; enabled?: boolean };
  request?: SecondParameter<typeof orvalAxios>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getOrganizationsListKey() : null));
  const swrFn = () => organizationsList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Create a new organization. When a user creates an organization, s/he will automatically become an organization admin.

### Required Parameters
- `name`: Name of the organization. The name is globally unique. It can only consist of lowercase letters, numbers and dashes (-), and must start with a lowercase letter.
- `subscription`: Name of the subscription for the organization

### Optional Parameters
- `subscription_end_date`: End date of the subscription. The subscription will be cancelled on this date. A 'free' subscription cannot have a custom end_date as this subscription is always valid for a year.
If you are going to use a subscription other than the free subscription, you should provide the end date.

#### Request Examples
```
{
  "name": "test-organization",
  "subscription": "premium",
  "subscription_end_date": "2021-03-25"
}
```

```
{
  "name": "test-organization",
  "subscription": "premium",
  "subscription_end_date": "2021-03-25"
}
```

### Response Structure
Details of the created organization
- `id`: Unique identifier for the organization (UUID)
- `name`: Name of the organization
- `creation_date`: Date and time the organization was created

#### Response Examples
```
{
  "id": "abe2e406-fae5-4bcf-a3bc-956d756e4ecb",
  "name": "test-organization",
  "creation_date": "2020-03-25T15:43:46.101877Z"
}
```

 * @summary Create organizations
 */
export const organizationsCreate = (
  organizationCreate: OrganizationCreate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<OrganizationList>(
    {
      url: `/organizations`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: organizationCreate,
    },
    options
  );
};

/**
 * 
### Description
Get the details of an organization

### Response Structure
Details of the organization
- `id`: Unique identifier for the organization (UUID)
- `name`: Name of the organization
- `creation_date`: Time the organization was created
- `subscription`: Name of the subscription of the organization

#### Response Examples
```
{
  "id": "abe2e406-fae5-4bcf-a3bc-956d756e4ecb",
  "name": "test-organization",
  "creation_date": "2020-03-25T15:43:46.101877Z",
  "subscription": "free"
}
```

 * @summary Get details of an organization
 */
export const organizationsGet = (
  organizationName: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<OrganizationDetail>(
    { url: `/organizations/${organizationName}`, method: "get" },
    options
  );
};

export const getOrganizationsGetKey = (organizationName: string) => [
  `/organizations/${organizationName}`,
];

export type OrganizationsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof organizationsGet>>
>;
export type OrganizationsGetQueryError = ErrorType<unknown>;

export const useOrganizationsGet = <TError = ErrorType<unknown>>(
  organizationName: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof organizationsGet>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!organizationName;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getOrganizationsGetKey(organizationName) : null));
  const swrFn = () => organizationsGet(organizationName, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Update an organization. The user making the request must be admin of the organization. Users are able to update the name of the organization, but changes to the subscription can only be done by Dutch Analytics.
To delete the end date of the current subscription, give the 'subscription_end_date' parameter with value null.

### Optional Parameters
- `name`: New organization name
- `subscription`: New subscription
- `subscription_end_date`: End date of the new subscription. The required format is `YYYY-MM-DD`. The subscription will be cancelled on this date. If you are going to update the subscription plan of the organization to a subscription other than free, you have to provide the end date.
- `subscription_start_date`: Start date of the new subscription. The required format is `YYYY-MM-DD`. The subscription will start from the provided date. If you are going to update the subscription of the organization or schedule a subscription for a time in future, you have to provide the start date.

#### Request Examples


```
{
  "name": "new-organization-name"
}
```
```
{
  "subscription": "premium",
  "subscription_end_date": "2020-08-30",
  "subscription_start_date": "2020-07-30"
}
```
```
{
  "subscription_end_date": "2020-08-30",
  "subscription_start_date": "2020-07-30"
}
```

### Response Structure
Details of the organization
- `id`: Unique identifier for the organization (UUID)
- `name`: Name of the organization
- `creation_date`: Time the organization was created
- `subscription`: Name of the subscription

#### Response Examples
```
{
  "id": "abe2e406-fae5-4bcf-a3bc-956d756e4ecb",
  "name": "test-organization",
  "creation_date": "2020-03-25T15:43:46.101877Z",
  "subscription": "free"
}
```

 * @summary Update details of an organization
 */
export const organizationsUpdate = (
  organizationName: string,
  organizationUpdate: OrganizationUpdate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<OrganizationDetail>(
    {
      url: `/organizations/${organizationName}`,
      method: "patch",
      headers: { "Content-Type": "application/json" },
      data: organizationUpdate,
    },
    options
  );
};

/**
 * 
### Description
Get the details of credits usage per subscription period for an organization. It shows the details of the last year.

### Response Structure
- `period_start`: Start date of a subscription period
- `period_end`: End date of a subscription period
- `usage`: Actual usage of the organization in this subscription period
- `limit`: Allowed usage of the organization in this subscription period


#### Response Examples
```
[
  {
    "period_start": "2022-08-21T00:00:00.000",
    "period_end": "2022-09-21T00:00:00.000",
    "usage": 1.5,
    "limit": 2
  },
  {
    "period_start": "2022-07-21T00:00:00.000",
    "period_end": "2022-08-21T00:00:00.000",
    "usage": 1.2,
    "limit": 3
  },
  {
    "period_start": "2022-06-21T00:00:00.000",
    "period_end": "2022-07-21T00:00:00.000",
    "usage": 2.2,
    "limit": 3
  },
  {
    "period_start": "2022-05-21T00:00:00.000",
    "period_end": "2022-06-21T00:00:00.000",
    "usage": 2.9,
    "limit": 3
  }
]
```

 * @summary Get credits usage
 */
export const organizationsCreditsGet = (
  organizationName: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<UsageDetail[]>(
    { url: `/organizations/${organizationName}/credits`, method: "get" },
    options
  );
};

export const getOrganizationsCreditsGetKey = (organizationName: string) => [
  `/organizations/${organizationName}/credits`,
];

export type OrganizationsCreditsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof organizationsCreditsGet>>
>;
export type OrganizationsCreditsGetQueryError = ErrorType<unknown>;

export const useOrganizationsCreditsGet = <TError = ErrorType<unknown>>(
  organizationName: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof organizationsCreditsGet>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!organizationName;
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled ? getOrganizationsCreditsGetKey(organizationName) : null);
  const swrFn = () => organizationsCreditsGet(organizationName, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Get the features of an organization

### Response Structure
Details of the organization features
- `max_projects`: Maximum number of allowed projects to be created
- `max_users`: Maximum number of allowed users to be created
- `max_deployment_versions`: Maximum number of allowed versions to be created
- `max_retention_requests`: Maximum time in seconds for which the deployment/pipeline requests will be saved
- `max_retention_logs`: Maximum time in seconds for which the logs will be saved
- `max_deployment_concurrency`: Maximum max_instances that a deployment version can have
- `max_deployment_memory`: Maximum memory allocation that a deployment can have
- `max_credits`: Maximum usage of credits, calculated by multiplying the credit rate of a deployment instance type by the number of hours they are running
- `max_buckets`: Maximum number of allowed buckets to be created
- `feature_buckets_ubiops`: A boolean indicating whether UbiOps buckets can be created within the organization
- `feature_buckets_external`: A boolean indicating whether external buckets can be created within the organization
- `resource_gpu`: A boolean indicating whether organization has access to GPUs
- `max_environments`: Maximum number of allowed environments to be created
- `max_express_deployment_timeout`: Maximum time in seconds for an express deployment request timeout
- `max_batch_deployment_timeout`: Maximum time in seconds for a batch deployment request timeout
- `max_express_pipeline_timeout`: Maximum time in seconds for an express pipeline request timeout
- `max_batch_pipeline_timeout`: Maximum time in seconds for a batch pipeline request timeout

#### Response Examples
```
{
  "max_project": 10,
  "max_users": 2,
  "max_deployment_versions": 20,
  "max_retention_requests": 31536000,
  "max_retention_logs": 31536000,
  "max_deployment_concurrency": 20,
  "max_deployment_memory": 16384,
  "max_credits": 1000,
  "max_buckets": 10,
  "feature_buckets_ubiops": true,
  "feature_buckets_external": true,
  "resource_gpu": false,
  "max_environments": 10,
  "max_express_deployment_timeout": 3600,
  "max_batch_deployment_timeout": 259200,
  "max_express_pipeline_timeout": 7200,
  "max_batch_pipeline_timeout": 259200
}
```

 * @summary Get organization features
 */
export const organizationsFeaturesGet = (
  organizationName: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<OrganizationFeature>(
    { url: `/organizations/${organizationName}/features`, method: "get" },
    options
  );
};

export const getOrganizationsFeaturesGetKey = (organizationName: string) => [
  `/organizations/${organizationName}/features`,
];

export type OrganizationsFeaturesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof organizationsFeaturesGet>>
>;
export type OrganizationsFeaturesGetQueryError = ErrorType<unknown>;

export const useOrganizationsFeaturesGet = <TError = ErrorType<unknown>>(
  organizationName: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof organizationsFeaturesGet>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!organizationName;
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled ? getOrganizationsFeaturesGetKey(organizationName) : null);
  const swrFn = () =>
    organizationsFeaturesGet(organizationName, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
List the total number of resources used by this organization

### Response Structure
A list containing the number of
- projects
- users
- deployments
- deployment_versions
- pipelines
- pipeline_versions
- buckets
- environments

currently used by the organization.

#### Response Examples
```
{
  "projects": 5,
  "users": 3,
  "deployments": 30,
  "deployment_versions": 47,
  "pipelines": 2,
  "pipeline_versions": 4,
  "buckets": 2,
  "environments": 2
}
```

 * @summary Get resource usage
 */
export const organizationsResourceUsage = (
  organizationName: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<ResourceUsage>(
    { url: `/organizations/${organizationName}/resources`, method: "get" },
    options
  );
};

export const getOrganizationsResourceUsageKey = (organizationName: string) => [
  `/organizations/${organizationName}/resources`,
];

export type OrganizationsResourceUsageQueryResult = NonNullable<
  Awaited<ReturnType<typeof organizationsResourceUsage>>
>;
export type OrganizationsResourceUsageQueryError = ErrorType<unknown>;

export const useOrganizationsResourceUsage = <TError = ErrorType<unknown>>(
  organizationName: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof organizationsResourceUsage>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!organizationName;
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled ? getOrganizationsResourceUsageKey(organizationName) : null);
  const swrFn = () =>
    organizationsResourceUsage(organizationName, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
List the history of subscriptions of an organization. The user making the request must be admin of the organization.

### Response Structure
A list of details of the subscriptions that the organization has had
- `id`: Unique identifier for the organization (UUID)
- `subscription`: Name of the subscription
- `start_date`: Date the subscription started
- `end_date`: Date the subscription will end/ended
- `active`: A boolean indicating whether the subscription is active. The current subscription is the active subscription.
- `subscription_agreement_user`: Email of the user who accepted the terms of subscription
- `subscription_update_user`: Email of the user who updated the subscription to the current one

#### Response Examples
```
[
  {
    "id": "56b26bcb-34b6-4c4d-a4aa-9d625e0aa9e2",
    "subscription": "standard",
    "start_date": "2020-07-01",
    "end_date": "2020-12-01",
    "active": true,
    "subscription_agreement_user": "test-user@test.com",
    "subscription_update_user": ""test-user-2@test.com"
  },
  {
    "id": "3a27e5f8-b247-4866-a08c-5df136b6034c",
    "subscription": "free",
    "start_date": "2020-06-01",
    "end_date": "2020-07-01",
    "active": false,
    "subscription_agreement_user": "test-user@test.com",
    "subscription_update_user": ""
  }
]
```

 * @summary List subscriptions for an organization
 */
export const organizationSubscriptionsList = (
  organizationName: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<OrganizationSubscriptionList[]>(
    { url: `/organizations/${organizationName}/subscriptions`, method: "get" },
    options
  );
};

export const getOrganizationSubscriptionsListKey = (
  organizationName: string
) => [`/organizations/${organizationName}/subscriptions`];

export type OrganizationSubscriptionsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof organizationSubscriptionsList>>
>;
export type OrganizationSubscriptionsListQueryError = ErrorType<unknown>;

export const useOrganizationSubscriptionsList = <TError = ErrorType<unknown>>(
  organizationName: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof organizationSubscriptionsList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!organizationName;
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled ? getOrganizationSubscriptionsListKey(organizationName) : null);
  const swrFn = () =>
    organizationSubscriptionsList(organizationName, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Get credits usage for the organization

### Optional Parameters
- `start_date`: Start date for the usage data to be returned. If omitted, results are generated for current subscription period.
- `end_date`: End date for the usage data to be returned. If omitted, results are generated for current subscription period.
- `interval`: Interval for the usage data. It can be 'day' or 'month'. It defaults to 'month'.

If no **start_date** or **end_date** is given, the current subscription period is used, e.g. if the usage details are requested on 01-12-2020 and the subscription started on 20-11-2020, the results will contain data from 20-11-2020 to 20-12-2020.
When **start_date** and **end_date** are given, this month period is used, e.g. if 12-11-2020 is given as start date and 12-12-2020 as end date, the results will contain data from 12-11-2020 to 12-12-2020.

### Response Structure
- `interval`: Interval for the usage data
- `data_organization`: A list of dictionaries containing the organization usage for the given date range
- `data_projects`: A list of dictionaries containing the usage of each project in the organization for the given date range
- `data_projects_deleted`: A list of dictionaries containing the usage corresponds to deleted projects in the organization for the given date range

#### Response Examples
2019-08-01 as start date and 2019-09-01 as end date
```
{
  "interval": "month",
  "data_organization": [
    {
      "start_date": "2019-08-01T00:00:00Z",
      "end_date": "2019-09-01T00:00:00Z",
      "value": 600
    }
  ],
  "data_projects": [
    {
      "project_id": "4aa10f82-24f3-4872-8883-f7a40e3a0733",
      "project_name": "project-1",
      "data": [
        {
          "start_date": "2019-08-01T00:00:00Z",
          "end_date": "2019-09-01T00:00:00Z",
          "value": 200
        }
      ]
    },
    {
      "project_id": "7e6238f3-d2c7-4d7d-a003-3e06a1c7a348",
      "project_name": "project-2",
      "data": [
        {
          "start_date": "2019-08-01T00:00:00Z",
          "end_date": "2019-09-01T00:00:00Z",
          "value": 300
        }
      ]
    }
  ],
  "data_deleted_projects": [
    {
      "start_date": "2019-08-01T00:00:00Z",
      "end_date": "2019-09-01T00:00:00Z",
      "value": 100
    }
  ]
}
```

 * @summary Get organization usage
 */
export const organizationsUsageGet = (
  organizationName: string,
  params?: OrganizationsUsageGetParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<OrganizationUsage>(
    { url: `/organizations/${organizationName}/usage`, method: "get", params },
    options
  );
};

export const getOrganizationsUsageGetKey = (
  organizationName: string,
  params?: OrganizationsUsageGetParams
) => [`/organizations/${organizationName}/usage`, ...(params ? [params] : [])];

export type OrganizationsUsageGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof organizationsUsageGet>>
>;
export type OrganizationsUsageGetQueryError = ErrorType<unknown>;

export const useOrganizationsUsageGet = <TError = ErrorType<unknown>>(
  organizationName: string,
  params?: OrganizationsUsageGetParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof organizationsUsageGet>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!organizationName;
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled ? getOrganizationsUsageGetKey(organizationName, params) : null);
  const swrFn = () =>
    organizationsUsageGet(organizationName, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
List users and their details in an organization

### Response Structure
List of details of users
- `id`: Unique identifier for the user (UUID)
- `email`: Email of the user
- `name`: Name of the user
- `surname`: Surname of the user
- `admin`: Boolean value indicating whether the user is an admin of the organization or not

#### Response Examples
```
[
  {
    "id": "54977bc3-2c3b-4d8f-97c7-aff89a95bf21",
    "email": "user@example.com",
    "name": "user",
    "surname": "name",
    "admin": true
  },
  {
    "id": "abe2e406-fae5-4bcf-a3bc-956d756e4ecb",
    "email": "user2@example.com",
    "name": "user",
    "surname": "name",
    "admin": false
  }
]
```

 * @summary List the users in an organization
 */
export const organizationUsersList = (
  organizationName: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<OrganizationUserDetail[]>(
    { url: `/organizations/${organizationName}/users`, method: "get" },
    options
  );
};

export const getOrganizationUsersListKey = (organizationName: string) => [
  `/organizations/${organizationName}/users`,
];

export type OrganizationUsersListQueryResult = NonNullable<
  Awaited<ReturnType<typeof organizationUsersList>>
>;
export type OrganizationUsersListQueryError = ErrorType<unknown>;

export const useOrganizationUsersList = <TError = ErrorType<unknown>>(
  organizationName: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof organizationUsersList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!organizationName;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getOrganizationUsersListKey(organizationName) : null));
  const swrFn = () => organizationUsersList(organizationName, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Add a user to an organization as admin or normal user. The user making the request must be admin of the organization.
The user can later be assigned roles in the projects defined in the scope the organization, such as project-admin, project-viewer etc.

### Required Parameters
- `email`: Email of the user
- `admin`: Boolean value indicating whether the user is added as an admin of the organization or not

#### Request Examples
```
{
  "email": "test@example.com",
  "admin": false
}
```

### Response Structure
Details of the added user
- `id`: Unique identifier for the user (UUID)
- `email`: Email of the user
- `name`: Name of the user
- `surname`: Surname of the user
- `admin`: Boolean value indicating whether the user is an admin of the organization or not

#### Response Examples
```
{
  "id": "332d7432-2742-4177-99a9-139e91e0110c",
  "email": "test@example.com",
  "name": "user",
  "surname": "name",
  "admin": false
}
```

 * @summary Add a user to an organization
 */
export const organizationUsersCreate = (
  organizationName: string,
  organizationUserCreate: OrganizationUserCreate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<OrganizationUserDetail>(
    {
      url: `/organizations/${organizationName}/users`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: organizationUserCreate,
    },
    options
  );
};

/**
 * 
### Description
Get the details of a user in an organization. The user making the request must be admin of the organization.

### Response Structure
Details of the user
- `id`: Unique identifier for the user (UUID)
- `email`: Email of the user
- `name`: Name of the user
- `surname`: Surname of the user
- `admin`: Boolean value indicating whether the user is an admin of the organization or not

#### Response Examples
```
{
  "id": "332d7432-2742-4177-99a9-139e91e0110c",
  "email": "test@example.com",
  "name": "user",
  "surname": "name",
  "admin": false
}
```

 * @summary Get details of a user in an organization
 */
export const organizationUsersGet = (
  organizationName: string,
  userId: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<OrganizationUserDetail>(
    {
      url: `/organizations/${organizationName}/users/${userId}`,
      method: "get",
    },
    options
  );
};

export const getOrganizationUsersGetKey = (
  organizationName: string,
  userId: string
) => [`/organizations/${organizationName}/users/${userId}`];

export type OrganizationUsersGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof organizationUsersGet>>
>;
export type OrganizationUsersGetQueryError = ErrorType<unknown>;

export const useOrganizationUsersGet = <TError = ErrorType<unknown>>(
  organizationName: string,
  userId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof organizationUsersGet>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(organizationName && userId);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled ? getOrganizationUsersGetKey(organizationName, userId) : null);
  const swrFn = () =>
    organizationUsersGet(organizationName, userId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Update the admin status of a user in an organization. The user making the request must be admin of the organization.
It is not possible to change the last admin of an organization to a regular user.

### Required Parameters
- `admin`: Boolean value indicating whether the user is added as an admin of the organization or not

#### Request Examples
```
{
  "admin": true
}
```

### Response Structure
Details of the user
- `id`: Unique identifier for the user (UUID)
- `email`: Email of the user
- `name`: Name of the user
- `surname`: Surname of the user
- `admin`: Boolean value indicating whether the user is an admin of the organization or not

#### Response Examples
```
{
  "id": "332d7432-2742-4177-99a9-139e91e0110c",
  "email": "test@example.com",
  "name": "user",
  "surname": "name",
  "admin": true
}
```

 * @summary Update details of a user in an organization
 */
export const organizationUsersUpdate = (
  organizationName: string,
  userId: string,
  organizationUserUpdate: OrganizationUserUpdate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<OrganizationUserDetail>(
    {
      url: `/organizations/${organizationName}/users/${userId}`,
      method: "patch",
      headers: { "Content-Type": "application/json" },
      data: organizationUserUpdate,
    },
    options
  );
};

/**
 * 
### Description
Delete a user from an organization. The user making the request must be admin of the organization.
It is not possible to delete the last admin of an organization.

**When a user is deleted from an organization, all his roles from all projects defined in the scope of the organization are also deleted.**

 * @summary Delete a user from an organization
 */
export const organizationUsersDelete = (
  organizationName: string,
  userId: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<void>(
    {
      url: `/organizations/${organizationName}/users/${userId}`,
      method: "delete",
    },
    options
  );
};

/**
 * 
### Description
Get the description of a voucher from its code

### Response Structure
- `description`: Description of the voucher

#### Response Examples
```
{
  "description": "Voucher for workshop with GPUs"
}
```

 * @summary Get voucher
 */
export const vouchersGet = (
  code: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<Voucher>(
    { url: `/vouchers/${code}`, method: "get" },
    options
  );
};

export const getVouchersGetKey = (code: string) => [`/vouchers/${code}`];

export type VouchersGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof vouchersGet>>
>;
export type VouchersGetQueryError = ErrorType<unknown>;

export const useVouchersGet = <TError = ErrorType<unknown>>(
  code: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof vouchersGet>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!code;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getVouchersGetKey(code) : null));
  const swrFn = () => vouchersGet(code, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
