import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  roleAssignmentsDelete,
  useRoleAssignmentsPerObjectList,
} from "libs/data/endpoints/roles/roles";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import type { RoleAssignmentsPerObjectListResourceType } from "libs/data/models";

export const useRoleAssignmentsDelete = (
  projectName: string,
  userId?: string,
  resource?: string,
  resourceType?: RoleAssignmentsPerObjectListResourceType
) => {
  const dispatch = useDispatch();
  const { mutate: mutateUserRoleAssignment } = useRoleAssignmentsPerObjectList(
    projectName,
    {
      assignee: userId,
      assignee_type: "user",
    },
    { swr: { enabled: !!userId } }
  );

  const { mutate: mutateBucketRoleAssignment } =
    useRoleAssignmentsPerObjectList(
      projectName,
      {
        resource,
        resource_type: resourceType,
      },
      { swr: { enabled: !!resource } }
    );

  return useCallback(
    async (userId) => {
      if (!projectName) {
        return Promise.reject();
      }

      return roleAssignmentsDelete(projectName, userId)
        .then(async (result) => {
          dispatch(
            createSuccessNotification("The role assignment was deleted")
          );

          if (resourceType === "bucket") {
            await mutateBucketRoleAssignment();
          } else {
            await mutateUserRoleAssignment();
          }

          return result;
        })
        .catch((e) => {
          dispatch(createErrorNotification(e.message));
        });
    },
    [
      projectName,
      dispatch,
      mutateUserRoleAssignment,
      mutateBucketRoleAssignment,
      resourceType,
    ]
  );
};
