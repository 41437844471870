import styled from "@emotion/styled";
import { Grid, Box, Typography, useTheme } from "@mui/material";
import { Chart, registerables } from "chart.js";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useForm, FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";

import { borderRadius, spacing } from "assets/styles/theme";
import { SUCCESS_FILTER } from "libs/constants/fields";
import { PROJECT_PERMISSIONS } from "libs/constants/permissions";
import { useTimeSeriesDataList } from "libs/data/endpoints/metrics/metrics";
import { useProjectsUsageGet } from "libs/data/endpoints/projects/projects";
import {
  extractValueFromMetrics,
  getBarChartDatasetOptions,
  getBarChartOptions,
  getTotalNumberOfRequests,
  mapValuesToDates,
  getMaxDatasetValue,
} from "libs/utilities/metrics-helper";
import { routeMaker, routes } from "routes";
import { useGetPermissions } from "store/features/permissions";
import { useGetThemeMode } from "store/features/themeMode";

import { Alert, Loader, NavLink } from "components/atoms";
import { MetricGraph } from "components/molecules";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type { MetricGraphProps } from "components/molecules/MetricGraph/MetricGraph";
import type {
  ProjectsUsageGetParams,
  TimeSeriesDataListParams,
  TimeSeriesDataPointList,
} from "libs/data/models";

// Apply registrables for Chart.js
Chart.register(...registerables);

const LastWeekSummary = () => {
  const theme = useTheme() as AppThemeProps;
  const [graphs, setGraphs] = useState<MetricGraphProps[]>([]);
  const { projectName, organizationName } =
    useParams<{ projectName: string; organizationName: string }>();
  const methods = useForm();

  const options: ProjectsUsageGetParams | TimeSeriesDataListParams = useMemo(
    () => ({
      start_date: moment()
        .utc()
        .subtract(6, "days")
        .startOf("day")
        .toISOString(),
      end_date: moment().utc().endOf("day").toISOString(),
      interval: "day",
      unit_period: 86400,
      aggregation_period: 86400,
    }),
    []
  );
  const creditOptions: ProjectsUsageGetParams | TimeSeriesDataListParams =
    useMemo(
      () => ({
        start_date: moment()
          .utc()
          .subtract(6, "days")
          .startOf("day")
          .toISOString(),
        end_date: moment().utc().add(1, "day").endOf("day").toISOString(),
        interval: "day",
        unit_period: 86400,
        aggregation_period: 86400,
      }),
      []
    );
  const [projectPermissions, loadingPermissions] = useGetPermissions();

  const { data: credits } = useProjectsUsageGet(projectName, {
    ...creditOptions,
  });

  const { data: deploymentRequests } = useTimeSeriesDataList(projectName, {
    ...options,
    metric: "deployments.requests",
  });

  const { data: pipelineRequests } = useTimeSeriesDataList(projectName, {
    ...options,
    metric: "pipelines.requests",
  });

  const { data: deploymentFailedRequests } = useTimeSeriesDataList(
    projectName,
    {
      ...options,
      metric: "deployments.failed_requests",
    }
  );

  const { data: pipelineFailedRequests } = useTimeSeriesDataList(projectName, {
    ...options,
    metric: "pipelines.failed_requests",
  });

  const isLoaded =
    pipelineFailedRequests &&
    deploymentFailedRequests &&
    pipelineRequests &&
    deploymentRequests &&
    credits;

  const arrayOfFailedRequests = extractValueFromMetrics(
    (deploymentFailedRequests?.data_points || []).concat(
      pipelineFailedRequests?.data_points || []
    )
  );

  const arrayOfTotalRequests = extractValueFromMetrics(
    (deploymentRequests?.data_points || []).concat(
      pipelineRequests?.data_points || []
    )
  );

  const deploymentSuccessfulRequests = useMemo(() => {
    return (
      deploymentRequests?.data_points.map(
        (deploymentRequest: TimeSeriesDataPointList) => {
          const respectiveFailedDeploymentRequest =
            deploymentFailedRequests?.data_points.find(
              (request) => request.start_date === deploymentRequest.start_date
            );

          return {
            end_date: deploymentRequest.end_date,
            start_date: deploymentRequest.start_date,
            value:
              deploymentRequest.value -
              (respectiveFailedDeploymentRequest?.value || 0),
          };
        }
      ) || []
    );
  }, [deploymentFailedRequests?.data_points, deploymentRequests?.data_points]);

  const pipelineSuccessfulRequests = useMemo(() => {
    return (
      pipelineRequests?.data_points.map(
        (pipelineRequest: TimeSeriesDataPointList) => {
          const respectiveFailedPipelineRequest =
            pipelineFailedRequests?.data_points.find(
              (request) => request.start_date === pipelineRequest.start_date
            );

          return {
            end_date: pipelineRequest.end_date,
            start_date: pipelineRequest.start_date,
            value:
              pipelineRequest.value -
              (respectiveFailedPipelineRequest?.value || 0),
          };
        }
      ) || []
    );
  }, [pipelineFailedRequests?.data_points, pipelineRequests?.data_points]);

  const arrayOfSuccessfulRequests = extractValueFromMetrics(
    (deploymentSuccessfulRequests || []).concat(
      pipelineSuccessfulRequests || []
    )
  );

  useEffect(() => {
    if (isLoaded) {
      // we need all days from the past week
      // to maintain the bar alignment in the charts
      // and to display credit usage of days without requests
      const labels = [...Array(7).keys()].map((key) =>
        moment()
          .utc()
          .subtract(6 - key, "days")
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss[Z]")
      );
      const creditData = mapValuesToDates(credits?.data_project, labels);

      const maxCredit = Math.ceil(Math.max(...creditData.map((v) => v || 0)));

      const deploymentRequestData = mapValuesToDates(
        deploymentSuccessfulRequests,
        labels
      );
      const pipelineRequestData = mapValuesToDates(
        pipelineSuccessfulRequests,
        labels
      );
      const failedDeployments = mapValuesToDates(
        deploymentFailedRequests?.data_points,
        labels
      );
      const failedPipelines = mapValuesToDates(
        pipelineFailedRequests?.data_points,
        labels
      );

      const maxRequestValue = getMaxDatasetValue([
        deploymentRequestData,
        pipelineRequestData,
        failedDeployments,
        failedPipelines,
      ]);

      setGraphs([
        {
          data: {
            labels,
            datasets: [
              {
                data: deploymentRequestData,
                ...getBarChartDatasetOptions({
                  label: "Successful deployment",
                  color: theme.palette.success.main,
                }),
              },

              {
                data: pipelineRequestData,
                ...getBarChartDatasetOptions({
                  label: "Successful pipeline",
                  color: theme.palette.success.dark,
                }),
              },
              {
                data: failedDeployments,
                ...getBarChartDatasetOptions({
                  label: "Failed deployment",
                  color: theme.palette.error.main,
                }),
              },
              {
                data: failedPipelines,
                ...getBarChartDatasetOptions({
                  label: "Failed pipeline",
                  color: theme.palette.error.dark,
                }),
              },
            ],
          },
          options: getBarChartOptions({
            name: "Requests",
            stacked: true,
            precision: 0,
            crosshair: true,
            startDate: moment()
              .subtract(6, "days")
              .startOf("day")
              .toISOString(),
            endDate: moment().startOf("day").toISOString(),
            timeUnit: "day",
            theme: theme,
            maxY: maxRequestValue,
          }),
          title: "Daily requests",
          chartComponent: Bar,
          height: "100%",
          minHeight: "260px",
        },
        {
          data: {
            labels,
            datasets: [
              {
                data: creditData,
                ...getBarChartDatasetOptions({
                  label: projectName,
                  color: theme.palette.success.main,
                }),
              },
            ],
          },
          options: getBarChartOptions({
            name: "Compute credit usage (Units)",
            stacked: true,
            precision: 0,
            crosshair: true,
            startDate: moment()
              .subtract(6, "days")
              .startOf("day")
              .toISOString(),
            endDate: moment().startOf("day").toISOString(),
            timeUnit: "day",
            theme: theme,
            maxY: maxCredit,
          }),
          title: "Daily compute credit usage (Units)",
          chartComponent: Bar,
          height: "100%",
          minHeight: "260px",
        },
      ]);
    }
  }, [
    isLoaded,
    projectName,
    credits?.data_project,
    theme,
    deploymentFailedRequests?.data_points,
    pipelineFailedRequests?.data_points,
    deploymentSuccessfulRequests,
    pipelineSuccessfulRequests,
  ]);

  const monitoringLogsOverviewUrl = routes.organizations[":organizationName"](
    organizationName
  )
    .projects[":projectName"](projectName)
    .monitoring.requests.index();

  const noPermissions =
    projectPermissions[PROJECT_PERMISSIONS["metrics_get"]] === false;

  if (loadingPermissions || !isLoaded) {
    return <Loader />;
  }

  if (!loadingPermissions && noPermissions) {
    return (
      <Alert severity="warning">
        You currently don&apos;t have permission to see the deployment requests
        and credit usage.
      </Alert>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid container item spacing={2}>
        <SummaryBox
          to={routeMaker(monitoringLogsOverviewUrl, {
            status: SUCCESS_FILTER[1],
          })}
          color={theme.palette.success.main}
          requestNumbers={arrayOfSuccessfulRequests}
          title="Successful requests"
        />
        <SummaryBox
          to={routeMaker(monitoringLogsOverviewUrl, {
            status: SUCCESS_FILTER[2],
          })}
          color={theme.palette.error.main}
          requestNumbers={arrayOfFailedRequests}
          title="Failed requests"
        />
        <SummaryBox
          to={routeMaker(monitoringLogsOverviewUrl, {
            status: SUCCESS_FILTER[3],
          })}
          color={theme.palette.primary.main}
          textColor={theme.palette.text.primary}
          requestNumbers={arrayOfTotalRequests}
          title="Total requests"
        />
      </Grid>
      <Grid display="flex" flexDirection="column" item xs={12} gap={2}>
        <FormProvider {...methods}>
          {graphs.map(({ data, ...props }, key) => (
            <MetricGraph data={data} {...props} fullPageWidth key={key} />
          ))}
        </FormProvider>
      </Grid>
    </Grid>
  );
};

interface SummaryBoxProps {
  to: string;
  requestNumbers: (string | number | undefined)[];
  title: string;
  color: string;
  textColor?: string;
}

const SummaryBox = ({
  to,
  requestNumbers,
  title,
  color,
  textColor,
}: SummaryBoxProps) => {
  const theme = useTheme() as AppThemeProps;
  const themeMode = useGetThemeMode();

  return (
    <Grid item xs={4}>
      <StyledBox
        titleColor={
          themeMode.mode === "dark"
            ? theme.palette.text.primary
            : theme.palette.text.secondary
        }
        hoverColor={
          themeMode.mode === "dark"
            ? theme.palette.text.primary
            : theme.palette.primary.contrastText
        }
        color={color}
        textColor={textColor}
        component={({ titleColor: _t, hoverColor: _h, ...props }) => (
          <NavLink
            to={to}
            {...props}
            style={{
              color: theme.palette.text.primary,
            }}
          />
        )}
      >
        <Typography variant="h1" gutterBottom className="requests-number">
          {getTotalNumberOfRequests(requestNumbers as number[])}
        </Typography>
        <Typography className="requests-title">{title}</Typography>
      </StyledBox>
    </Grid>
  );
};

const StyledBox = styled(Box)<{
  color: string;
  hoverColor: string;
  titleColor: string;
  textColor: string | undefined;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: inherit;
  border-radius: ${borderRadius[5]};
  padding: ${spacing[8]};
  padding-bottom: ${spacing[16]};

  .requests-number {
    color: ${(props) => (props.textColor ? props.textColor : props.color)};
    margin-bottom: 0;
  }

  .requests-title {
    color: ${(props) => props.titleColor};
    font-weight: 700;
    text-align: center;
  }

  &:hover {
    background: ${(props) => props.color};
    .requests-title {
      color: ${(props) => props.hoverColor};
    }
    .requests-number {
      color: ${(props) => props.hoverColor};
    }
  }
`;

export default LastWeekSummary;
