import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import {
  useWebhookTestsGet,
  webhookTestsCreate,
} from "libs/data/endpoints/webhooks/webhooks";
import { WebhookTestDetailStatus } from "libs/data/models/webhookTestDetailStatus";
import { createErrorNotification } from "libs/utilities/notifications";

import type { AxiosError } from "axios";
import type { WebhookTestCreate } from "libs/data/models";

export const useTestWebHook = () => {
  const { projectName } = useParams<{ projectName: string }>();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] =
    useState<string | null | undefined>(undefined);
  const [isSuccess, setIsSuccess] = useState(false);

  const { data } = useWebhookTestsGet(projectName, id as string, {
    swr: { enabled: Boolean(id), refreshInterval: 2000, dedupingInterval: 0 },
  });

  useEffect(() => {
    if (data) {
      const isCompleted =
        data.status !== WebhookTestDetailStatus.pending &&
        data.status !== WebhookTestDetailStatus.processing;
      const completedWithSuccess = isCompleted && !data.error_message;

      if (isCompleted) {
        setId(null);
        setIsSuccess(completedWithSuccess);
        setIsLoading(false);
        setErrorMessage(data?.error_message);
      }
    }
  }, [data]);

  const testWebHook = async (payload: WebhookTestCreate) => {
    setIsLoading(true);
    setErrorMessage(undefined);
    setIsSuccess(false);
    try {
      const { id } = await webhookTestsCreate(projectName, payload);
      setId(id);
    } catch (e: unknown) {
      const error = e as AxiosError;
      dispatch(createErrorNotification(error.message));
      setIsLoading(false);
    }
  };

  return { testWebHook, isLoading, isSuccess, errorMessage };
};
