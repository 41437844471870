import PersonAdd from "@mui/icons-material/PersonAddRounded";
import { Chip, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useRouteMatch, useParams, useHistory } from "react-router-dom";

import { PageHeader } from "components/molecules/PageLayout";
import {
  useOrganizationUserDelete,
  useOrganizationUserUpdate,
} from "libs/data/customized/organization";
import { useOrganizationUsersList } from "libs/data/endpoints/organizations/organizations";
import { useUserGet } from "libs/data/endpoints/user/user";
import { useGoogleAnalytics } from "libs/hooks";
import { secretValues } from "libs/utilities/labels-mapping";
import { useGetCurrentOrganization } from "store/features";

import { HighlightedText, Icon, OverflowTooltip } from "components/atoms";
import { AdvancedTable, PageContainer } from "components/molecules";

const OrganizationUsersOverview = () => {
  useGoogleAnalytics();
  const { organizationName } = useParams();
  const { data: user } = useUserGet();
  const { data: users, error } = useOrganizationUsersList(organizationName);
  const usersLoading = !users && !error;

  const currentOrganization = useGetCurrentOrganization();

  const updateOrganizationUser = useOrganizationUserUpdate(organizationName);
  const deleteOrganizationUser = useOrganizationUserDelete(organizationName);

  const match = useRouteMatch();
  const history = useHistory();

  const columns = useMemo(
    () => [
      {
        title: "Email",
        field: "email",
        defaultSort: "asc",
        editable: "never",
        nowrap: true,
        render: ({ email }) => {
          return email === user?.email ? (
            <div display="flex" style={{ flexDirection: "column" }}>
              <HighlightedText>You</HighlightedText>
              <OverflowTooltip
                title={<Typography variant="h5">{email}</Typography>}
              >
                {email}
              </OverflowTooltip>
            </div>
          ) : (
            email
          );
        },
      },
      {
        title: "First name",
        field: "name",
        editable: "never",
        nowrap: true,
      },
      {
        title: "Last name",
        field: "surname",
        editable: "never",
        nowrap: true,
      },
      {
        title: "Admin",
        field: "admin",
        editable: "always",
        lookup: secretValues,
        initialEditValue: "false",
        render: (rowData) => (
          <>
            {rowData.admin && (
              <Chip variant="outlined" size="small" label="Admin" />
            )}
          </>
        ),
      },
      {
        title: "Status",
        field: "status",
        editable: "never",
        hidden: users?.findIndex((user) => user.status === "invited") === -1,
        render: (rowData) => (
          <>
            {rowData?.status === "invited" && (
              <Chip variant="outlined" size="small" label="Invited" />
            )}
          </>
        ),
      },
    ],
    [user?.email, users]
  );

  return (
    <PageContainer>
      <PageHeader title={organizationName} />
      <BreadcrumbsItem to={match.url}>Team</BreadcrumbsItem>
      <AdvancedTable
        title=""
        columns={columns}
        data={users}
        isLoading={usersLoading}
        localization={{
          body: {
            addTooltip: "Add user",
            deleteTooltip: "Remove user",
            editTooltip: "Edit user role",
          },
        }}
        editable={{
          isCreatable: true,
          isEditable: (rowData) => !rowData?.pending,
          onRowUpdate: (newUser, oldUser) =>
            new Promise((resolve) => {
              updateOrganizationUser(oldUser.id, { admin: newUser.admin });
              resolve();
            }),
          onRowDelete: (user) =>
            new Promise((resolve) => {
              deleteOrganizationUser(user.id);
              resolve();
            }),
        }}
        actions={[
          {
            icon: () => (
              <Icon component={PersonAdd} status="revert_icon_color" />
            ),
            tooltip: "Add user",
            isFreeAction: true,
            onClick: () => history.push(`${match.url}/create`),
            variant: "contained",
            disabled: currentOrganization.status !== "active",
          },
        ]}
        deletionText={(row) =>
          `Are you sure you want to delete user "${row?.email}"?`
        }
      />
    </PageContainer>
  );
};

export default OrganizationUsersOverview;
