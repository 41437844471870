import Plus from "@mui/icons-material/AddBoxRounded";
import { Link } from "@mui/material";
import { filter } from "lodash";
import { useEffect, useMemo, useState, useCallback } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useParams, useRouteMatch } from "react-router-dom";

import { IlluRoles } from "assets/images/IlluRoles";
import { USER_ROLE_PERMISSIONS } from "libs/constants/permissions";
import { useRoleAssignmentsDelete } from "libs/data/customized/roles";
import { useRoleAssignmentsPerObjectList } from "libs/data/endpoints/roles/roles";
import { useGetPermissions } from "store/features/permissions";

import { Icon, Loader } from "components/atoms";
import { AdvancedTable, EmptyOverview } from "components/molecules";

import { RoleAssignmentDialog, RoleDetailsDialog } from "../Dialogs";

import type {
  ProjectUserList,
  RoleAssignmentList,
  ServiceUserList,
} from "libs/data/models";

type UserDetailsProps = {
  currentUser?: ProjectUserList | ServiceUserList;
  title?: string;
  isUsedInForm?: boolean;
};

const UserDetails = ({
  currentUser,
  title = "",
  isUsedInForm = false,
}: UserDetailsProps) => {
  const { userId, projectName } =
    useParams<{ projectName: string; userId: string }>();
  const match = useRouteMatch();
  const [roleAssignments, setRoleAssignments] = useState<RoleAssignmentList[]>(
    []
  );
  const [currentRole, setCurrentRole] = useState("");
  const [showRoleDialog, setShowRoleDialog] = useState(false);
  const [roleAssignmentDialogOpen, setRoleAssignmentDialogOpen] =
    useState(false);

  const { data: userRoles } = useRoleAssignmentsPerObjectList(projectName, {
    assignee: userId ?? currentUser?.id,
    assignee_type: "user",
  });

  const roleAssignmentsDelete = useRoleAssignmentsDelete(
    projectName,
    userId ?? currentUser?.id
  );

  const [currentPermissions] = useGetPermissions();

  const onRoleDetailsClick = useCallback(
    (role) => {
      setCurrentRole(role);
      setShowRoleDialog(!showRoleDialog);
    },
    [setCurrentRole, setShowRoleDialog, showRoleDialog]
  );

  const columns = useMemo(() => {
    return [
      {
        title: "Role",
        field: "role",
        nowrap: true,
        render: (rowData: RoleAssignmentList) =>
          currentPermissions[USER_ROLE_PERMISSIONS["role_get"]] ? (
            <Link component="button" variant="h5">
              {rowData.role}
            </Link>
          ) : (
            rowData.role
          ),
        defaultSort: "asc",
      },
      {
        title: "Role level",
        field: "resource_type",
        nowrap: true,
      },
      {
        title: "Applied to",
        field: "resource",
        nowrap: true,
      },
    ];
  }, [currentPermissions]);

  useEffect(() => {
    if (userRoles) {
      setRoleAssignments(filter(userRoles, { assignee: currentUser?.id }));
    }
  }, [userRoles, currentUser]);

  const onDelete = async (userRole: RoleAssignmentList) => {
    await roleAssignmentsDelete(userRole.id);
  };

  return (
    <div>
      <BreadcrumbsItem to={match.url}>{title}</BreadcrumbsItem>
      {!userRoles ? (
        <Loader />
      ) : roleAssignments.length ? (
        <AdvancedTable
          title={isUsedInForm ? "" : title}
          columns={columns}
          data={roleAssignments}
          editable={{
            onRowDelete: (userRole: RoleAssignmentList) =>
              new Promise<void>((resolve) => {
                onDelete(userRole).then(() => resolve());
              }),
          }}
          actions={[
            {
              icon: () => (
                <Icon
                  component={Plus}
                  disabled={
                    !currentPermissions[
                      USER_ROLE_PERMISSIONS["assignment_create"]
                    ]
                  }
                />
              ),
              tooltip: `Assign role`,
              isFreeAction: true,
              onClick: () => {
                setRoleAssignmentDialogOpen(true);
              },
              disabled:
                !currentPermissions[USER_ROLE_PERMISSIONS["assignment_create"]],
            },
          ]}
          deletionText={(row: RoleAssignmentList) =>
            `Are you sure you want to remove the role "${row?.role}" from this user?`
          }
          options={{
            search: !isUsedInForm,
            paging: !isUsedInForm,
          }}
          onRowClick={
            currentPermissions[USER_ROLE_PERMISSIONS["role_get"]]
              ? (_: Event, rowData: RoleAssignmentList) =>
                  onRoleDetailsClick(rowData.role)
              : null
          }
        />
      ) : (
        <EmptyOverview
          isAllowed={
            !!currentPermissions[USER_ROLE_PERMISSIONS["assignment_create"]]
          }
          onClick={() => setRoleAssignmentDialogOpen(true)}
          message="No roles to display."
          buttonLabel="Assign roles to user"
          illustration={IlluRoles}
        />
      )}
      <RoleAssignmentDialog
        currentUser={currentUser}
        toggleDialog={() =>
          setRoleAssignmentDialogOpen(!roleAssignmentDialogOpen)
        }
        isOpen={roleAssignmentDialogOpen}
      />
      <RoleDetailsDialog
        currentRole={currentRole}
        open={showRoleDialog}
        toggleDialog={() => setShowRoleDialog(!showRoleDialog)}
      />
    </div>
  );
};

export default UserDetails;
