export const STRUCTURED_TYPE = "structured";
export const PLAIN_TYPE = "plain";
export const CONTINUOUS_REQUEST_DELAY = 6000; // 6 seconds
export const CONTINUOUS_INSTANCES_DELAY = 5000; // 5 seconds
export const BUILDING_DELAY = 3000; // 3 seconds
export const TIME_OUT_FILES = 3600000; // 1 hour
export const TIME_OUT_BROWSER = 3600000; // 1 hour

export const RANGE_LOGS_QUERY = 86400; //  24 hours limit used to query logs.
export const TIME_OUT_REQUESTS = 3600; // 1 hour
export const MAX_TIME_OUT_BATCH_REQUESTS = 259200; // 3 days
export const DEFAULT_TIME_OUT_BATCH_REQUESTS = 14400; // 4 hours
export const TIME_OUT_PIPELINE_REQUESTS = 7200; // 2 hours
export const REQUEST_DATA_MAX_SIZE = 10 ** 6; // 1MB
export const NAME_FIELD_MAX_CHARACTERS = 64; // 200 characters
export const DESCRIPTION_FIELD_MAX_CHARACTERS = 400; // 400 characters
export const TEXT_FIELD_MAX_CHARACTERS = 200; // 200 characters
export const DEFAULT_DEPLOYMENT_VERSION_INSTANCE_TYPE = "2048mb";
export const MAX_DEPLOYMENT_FILE_SIZE_IN_BYTE = 8589934592; // 8GB

export const DEFAULT_DEPLOYMENT_VERSION_IDLE_TIME = 300;

export const MIN_MAXIMUM_QUEUE_SIZE_EXPRESS = 1;
export const MAX_MAXIMUM_QUEUE_SIZE_EXPRESS = 1000;
export const DEFAULT_MAXIMUM_QUEUE_SIZE_EXPRESS = 100;
export const MIN_MAXIMUM_QUEUE_SIZE_BATCH = 1;
export const MAX_MAXIMUM_QUEUE_SIZE_BATCH = 1000000;
export const DEFAULT_MAXIMUM_QUEUE_SIZE_BATCH = 100000;

export const AUDIT_LOGS_LIMIT = 20; // number of audit logs per page.

export const ARRAY_STRING_TYPE = "array_string";
export const ARRAY_INT_TYPE = "array_int";
export const ARRAY_DICT_TYPE = "array_dict";
export const ARRAY_DOUBLE_TYPE = "array_double";
export const ARRAY_FILE_TYPE = "array_file";
export const INT_TYPE = "int";
export const DOUBLE_TYPE = "double";
export const BOOLEAN_TYPE = "bool";
export const STRING_TYPE = "string";
export const FILE_TYPE = "file";
export const DICT_TYPE = "dict";

// Environment
export const ENV_NAME = "name";
export const ENV_HOST = "host";
export const ENV_REMEMBER_ME = "remember_me";
export const ENV_LAST_LOGIN = "last_login";
export const ENV_REFRESH_TOKEN = "refresh_token";
export const ENV_ACTIVATION_TOKEN = "activation_token";
export const ENV_REDIRECT_URL = "redirect_url";
export const ENV_LAST_SEEN_PROJECT = "last_seen_project";
export const ENV_LAST_SEEN_ORGANIZATION = "last_seen_organization";
export const ENV_ACCESS_TOKEN = "access_token";
export const ENV_OAUTH_ENABLED = "oauth_enabled";
export const ENV_METRICS_AGGREGATION_ENABLED = "metrics_aggregation_enabled";
export const ENV_BILLING_ENABLED = "billing_enabled";
export const ENV_ON_PREMISE = "on_premise";
export const ENV_GOOGLE_ANALYTICS_TOKEN = "google_analytics_token";
export const ENV_SENTRY_DSN = "sentry_dsn";
export const ENV_REQUESTS_PAGE_ENABLED = "requests_page_enabled";
export const ENV_REQUESTS_PAGE_URL = "requests_page_url";

export const OBJECT_TYPES = [{ type: "deployment" }, { type: "pipeline" }];

// Editors view
export const TAB_VISUAL_EDITOR = "tabVisualEditor";
export const TAB_JSON_EDITOR = "tabJsonEditor";

export const TAB_SELECT_EXISTING = "tabSelectExisting";
export const TAB_CREATE_NEW = "tabCreateNew";

export const FOCUS_THROTTLE_INTERVAL = 360000;
export const DEDUPING_INTERVAL = 10000;

// bucket access roles
export const FILES_READER = "files-reader";
export const FILES_WRITER = "files-writer";
export const FILES_READER_RESTRICTED = "files-reader-restricted";
export const FILES_WRITER_RESTRICTED = "files-writer-restricted";

export const MAX_CREDIT_LIMIT = 1000000000;
export const UPLOAD_TASK = "UPLOAD_TASK";
export const REQUEST_TASK = "REQUEST_TASK";
