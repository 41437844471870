import { useTheme } from "@mui/styles";

export const SetupDatabase = () => {
  const theme = useTheme();

  return (
    <svg
      width="114"
      height="113"
      viewBox="0 0 114 113"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 32.9583C19 22.5529 36.005 14.125 57 14.125C77.995 14.125 95 22.5529 95 32.9583C95 43.3638 77.995 51.7917 57 51.7917C36.005 51.7917 19 43.3638 19 32.9583ZM57.38 84.75H57C36.005 84.75 19 76.3221 19 65.9167V80.0417C19 90.4471 36.005 98.875 57 98.875C57.475 98.875 57.95 98.875 58.3775 98.875C57.5225 95.8617 57 92.7071 57 89.4583C57 87.8575 57.1425 86.3038 57.38 84.75ZM95 56.8767C95 56.7354 95 56.6412 95 56.5V42.375C95 52.7804 77.995 61.2083 57 61.2083C36.005 61.2083 19 52.7804 19 42.375V56.5C19 66.9054 36.005 75.3333 57 75.3333C58.0925 75.3333 59.185 75.3333 60.2775 75.3333C65.645 64.1746 77.045 56.5 90.25 56.5C91.865 56.5 93.4325 56.6413 95 56.8767ZM113.05 96.05C113.525 96.05 113.525 96.5208 113.05 96.9917L108.3 104.996C107.825 105.467 107.35 105.467 106.875 105.467L101.175 103.583C99.75 104.525 98.8 104.996 97.375 105.938L96.425 112.058C96.425 112.529 95.95 113 95.475 113H85.975C85.5 113 85.025 112.529 84.55 112.058L83.6 105.938C82.175 105.467 80.75 104.525 79.8 103.583L74.1 105.938C73.625 105.938 73.15 105.938 72.675 105.467L67.925 97.4625C67.45 96.9917 67.925 96.5208 68.4 96.05L73.625 92.2833V87.575L68.4 83.8083C67.925 83.3375 67.925 82.8667 67.925 82.3958L72.675 74.3917C73.15 73.9208 73.625 73.9208 74.1 73.9208L79.8 76.275C81.225 75.3333 82.175 74.8625 83.6 73.9208L84.55 67.8C84.55 67.3292 85.025 66.8583 85.975 66.8583H95.475C95.95 66.8583 96.425 67.3292 96.425 67.8L97.375 73.9208C98.8 74.3917 100.225 75.3333 101.65 76.275L107.35 73.9208C107.825 73.9208 108.775 73.9208 108.775 74.3917L113.525 82.3958C114 82.8667 113.525 83.3375 113.05 83.8083L107.825 87.575V92.2833L113.05 96.05ZM97.375 89.4583C97.375 85.6917 94.05 82.3958 90.25 82.3958C86.45 82.3958 83.125 85.6917 83.125 89.4583C83.125 93.225 86.45 96.5208 90.25 96.5208C94.05 96.5208 97.375 93.225 97.375 89.4583Z"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
};
