import { env } from "libs/env";
import { ENV_NAMES } from "libs/env/env-names";

import { isArrayHasData, isArrayHasNoData } from "./utils";

export interface InputOutputField {
  name: string;
  data_type: string;
}

export const pythonCodeGenerator = ({
  deploymentName,
  inputFields,
  outputFields,
  inputType,
  outputType,
}: {
  deploymentName: string;
  inputFields: InputOutputField[];
  outputFields: InputOutputField[];
  inputType: string;
  outputType: string;
}) => `class Deployment:
\tdef __init__(self, base_directory, context):
\t\tprint("Initialising deployment ${deploymentName}")

\tdef request(self, data):
\t\tprint("Processing request for deployment ${deploymentName}")
${
  inputType === "plain"
    ? "\t\tyour_plain_input = data"
    : inputFields
        .map(
          (inputField: { name: string }) =>
            `\t\t${hyphenToUnderscore(inputField.name)}_value = data["${
              inputField.name
            }"]`
        )
        .join("\n")
}
         
\t\t# <YOUR CODE>

\t${
  isArrayHasData(outputFields)
    ? `\treturn {
\t\t\t# TODO fill in the values of your output fields
${outputFields
  .map(
    (outputField) =>
      `\t\t\t"${outputField.name}": ${hyphenToUnderscore(
        outputField.name
      )}_value`
  )
  .join(",\n")}
\t\t}`
    : outputType === "plain"
    ? `\treturn "your plain result"`
    : `\treturn {}`
}
\t`;

export const pythonRequestCodeGenerator = ({
  inputFields,
  endpointUrl,
  isBatch,
  isPlain,
}: {
  inputFields: InputOutputField[];
  endpointUrl: string;
  isBatch: boolean;
  isPlain: boolean;
}) => {
  const hasEmptyInput = isArrayHasNoData(inputFields);

  return `# Using a 'raw' HTTP request

import requests

${`# TODO insert your value(s) below
data = ${
  isPlain
    ? `""`
    : hasEmptyInput
    ? `{}`
    : `{
      ${inputFields
        .map((inputField) => `\t'${inputField.name}': YOUR_VALUE`)
        .join(",\n")}
}`
}`}

${
  !isBatch
    ? `# A direct request to the default version
receive = requests.post(
\turl="${endpointUrl}", 
\tjson=data,
\t# TODO paste your API token below
\theaders={'Authorization': 'Token YOUR_API_TOKEN'}
\ttimeout=TIMEOUT_VALUE # optional parameter (default is 3600)
)

print(receive.json())`
    : `# A batch request to the default version
receive = requests.post(
\turl="${endpointUrl}/batch", 
\tjson = [data],
\t# TODO paste your API token below
\theaders={'Authorization':'Token YOUR_API_TOKEN'}
\ttimeout=TIMEOUT_VALUE # optional parameter (default is 14400)
)

# Get the request id to retrieve the results
request_id = receive.json()[0]['id']

# TODO wait for the request to finish

# Retrieve the request results
results = requests.get(
\turl=f"${endpointUrl}/{request_id}",
\t# TODO paste your API token below
\theaders={'Authorization':'Token YOUR_API_TOKEN'}
)
print(results.json())`
}`;
};

export const rRequestCodeGenerator = ({
  inputFields,
  endpointUrl,
  isBatch,
  isPlain,
}: {
  inputFields: InputOutputField[];
  endpointUrl: string;
  isBatch: boolean;
  isPlain: boolean;
}) => {
  const hasEmptyInput = isArrayHasNoData(inputFields);

  return `# Using a 'raw' HTTP request

# TODO paste your API token below
headers <- httr::add_headers(Authorization="Token YOUR_API_TOKEN")
${
  isPlain
    ? `# TODO insert your value(s) below
body <- ${isBatch ? `list("")` : `""`}`
    : `${
        !hasEmptyInput
          ? `# TODO insert your value(s) below\nbody <- rjson::toJSON(${
              isBatch ? `list(` : ``
            }list(
${inputFields
  .map(
    (inputField) =>
      `\t${
        inputField.name?.includes("-")
          ? `"${inputField.name}"`
          : inputField.name
      } = YOUR_VALUE`
  )
  .join(",\n")}
)${isBatch ? `)` : ``})`
          : `body <- rjson::toJSON(${isBatch ? `list(NULL)` : `NULL`})`
      }`
}
${
  !isBatch
    ? `# A direct request to the default version
receive <- httr::POST(
\t"${endpointUrl}",
\theaders, httr::content_type_json(), body = body, encode = "json",
\ttimeout=TIMEOUT_VALUE # optional parameter (default is 3600)
)
content <- jsonlite::parse_json(httr::content(receive, "text", encoding = "UTF-8"))

print(content)`
    : `# A batch request to the default version
receive <- httr::POST(
\t"${endpointUrl}/batch",
\theaders, httr::content_type_json(), body = body, encode = "json",
\ttimeout=TIMEOUT_VALUE # optional parameter (default is 14400)
)
content <- jsonlite::parse_json(httr::content(receive, "text", encoding = "UTF-8"))

# Get the request id to retrieve the results
requestId <- content[[1]]$id

# TODO wait for the request to finish

# Retrieve the request results
receive <- httr::GET(
\tpaste0("${endpointUrl}/", requestId), 
\theaders, httr::content_type_json(), body = body, encode = "json"
)
results <- jsonlite::parse_json(httr::content(receive, "text", encoding = "UTF-8"))

print(results)`
}`;
};

export const javascriptRequestCodeGenerator = ({
  inputFields,
  endpointUrl,
  blobsUrl,
  isBatch,
  isPlain,
}: {
  inputFields: InputOutputField[];
  endpointUrl: string;
  blobsUrl: string;
  isBatch: boolean;
  isPlain: boolean;
}) => {
  const hasEmptyInput = isArrayHasNoData(inputFields);

  return `// Using JavaScript
// !!
// While running JavaScript, you should add your host as the allowed host for your UbiOps API Token
// Running on your LOCALHOST? For example, on port 3000? Add "<http://localhost:3000>" as allowed domain for your project via Project Admin > Project settings > Allowed domains
// If you don't do this, you will see this error in your browser console:
// "Cross-Origin Request Blocked: The Same Origin Policy disallows reading the remote resource at ${blobsUrl}. 
// (Reason: CORS header 'Access-Control-Allow-Origin' missing)."
// !!

// Helper function for creating a POST request
async function postRequest(url = "", data = ${
    isPlain ? `""` : `{}`
  }, headers = {}) {
\tconst response = await fetch(url, {
\t\tmethod: "POST",
\t\theaders: {
\t\t\t...headers,
\t\t\t// TODO paste your API token below
\t\t\tAuthorization: "Token YOUR_API_TOKEN",
\t\t},
\t\ttimeout: TIMEOUT_VALUE  // optional parameter (default is ${
    isBatch ? "14400" : "3600"
  })
\t\tbody: data,
\t});
\treturn response.json();
}
${
  isBatch
    ? `
// Helper function for creating a GET request
async function getRequest(url = "") {
\tconst response = await fetch(url, {
\t\tmethod: "GET",
\t\theaders: {
\t\t\t"Content-Type": "application/json",
\t\t\t// TODO paste your API token below
\t\t\tAuthorization: "Token YOUR_API_TOKEN",
\t\ttimeout: TIMEOUT_VALUE  // optional parameter (default is 14400)
\t\t}
\t});
\treturn response.json();
}
`
    : ``
}
${
  isPlain
    ? `// TODO insert your data below\nconst data = ""`
    : hasEmptyInput
    ? `const data = JSON.stringify({})`
    : `const data = JSON.stringify({
\t// TODO insert your value(s) below 
    ${inputFields
      .map(
        (inputField) =>
          `\t${
            inputField.name?.includes("-")
              ? `"${inputField.name}"`
              : inputField.name
          }: YOUR_VALUE`
      )
      .join(",\n")}
})`
}
    
// Using the postRequest function
${
  isBatch
    ? `postRequest(
\t\`${endpointUrl}/batch\`, data, {"Content-Type": "application/json"}
).then((response) => {
\t// TODO wait for the request to finish

\tconst requestId = response[0].id;
\t//Retrieve the request results
\tgetRequest(
\t\t\`${endpointUrl}/\${requestId}\`
\t).then(response => {
\t\tif (response.error_message) {
\t\t\tconsole.log("Request error:", response.error_message);
\t\t} else {
\t\t\tconsole.log("Request:", response);
\t\t}
\t})
})`
    : `postRequest(
\t\`${endpointUrl}\`, data, {"Content-Type": "application/json"}
).then((response) => console.log("Response:", response))`
}`;
};

export const pythonClientlibraryRequestCodeGenerator = ({
  inputFields,
  projectName,
  objectType,
  objectName,
  isBatch,
  isPlain,
}: {
  inputFields: InputOutputField[];
  projectName: string;
  objectType: string;
  objectName: string;
  isBatch: boolean;
  isPlain: boolean;
}) => {
  const hasEmptyInput = isArrayHasNoData(inputFields);

  return `# Using the Python Client Library

import ubiops

client = ubiops.ApiClient(ubiops.Configuration(
\thost="${env.get(ENV_NAMES.HOST)}",
\t# TODO paste your API token below
\tapi_key={"Authorization": "Token YOUR_API_TOKEN"})
)
api = ubiops.CoreApi(client)

${`data = ${
  isPlain
    ? `""`
    : hasEmptyInput
    ? `{}`
    : `{
\t# TODO insert your value(s) below
${inputFields
  .map((inputField) => `\t'${inputField.name}': YOUR_VALUE`)
  .join(",\n")}
}`
}`}

${
  !isBatch
    ? `# A direct request to the default version
request_result = api.${objectType}_requests_create(
\tproject_name="${projectName}",
\t${objectType}_name="${objectName}",
\tdata=data,
\ttimeout=TIMEOUT_VALUE # optional parameter (default is 3600)
)

print(request_result)`
    : `# A batch request to the default version
request_response = api.batch_${objectType}_requests_create(
\tproject_name="${projectName}", 
\t${objectType}_name="${objectName}", 
\tdata=[data],
\ttimeout=TIMEOUT_VALUE # optional parameter (default is 14400)
)
print(request_response)

# Get the request id to retrieve the results
request_id = request_response[0].id

# TODO wait for the request to finish

# Retrieve the request results
request_results = api.${objectType}_requests_get(
\tproject_name="${projectName}",
\t${objectType}_name="${objectName}",
\trequest_id=request_id
)
print(request_results)`
}`;
};

const hyphenToUnderscore = (string: string) => string.replace(/-/g, "_");
