import { Grid, Button, Box } from "@mui/material";
import { sortBy } from "lodash";
import { useMemo, useState } from "react";

import {
  AutoCompleteMultipleSelect,
  AutoCompleteSelect,
} from "components/atoms/AutoCompleteSelect";

import {
  PERIOD,
  MONTHS,
  MONTHDAYS,
  WEEKDAYS,
  HOURS,
  MINUTES,
} from "./constants";
import {
  DEFAULT_FIELDS,
  UNITS,
  getCronStringFromValues,
  getPeriodOptions,
  getOptionsFromLabels,
  getOptionsFromNumbers,
  setValuesFromCronString,
} from "./utils";

import type { AutocompleteSelectOption } from "components/atoms/AutoCompleteSelect/types";
import type { FormikProps, FormikValues } from "formik";

type Props = {
  name: string;
  control: FormikProps<FormikValues>;
  defaultValue: string;
};

type CronValue = {
  [MONTHS]: { label: string; value: number }[];
  [MONTHDAYS]: { label: string; value: number }[];
  [WEEKDAYS]: { label: string; value: number }[];
  [HOURS]: { label: string; value: number }[];
  [MINUTES]: { label: string; value: number }[];
  [PERIOD]: { label: string; value: number };
};

export const defaultCronValue: CronValue = {
  [MONTHS]: [],
  [MONTHDAYS]: [],
  [WEEKDAYS]: [],
  [HOURS]: [],
  [MINUTES]: [],
  [PERIOD]: { label: "week", value: 1 },
};

export const CronFields = ({ name, control, defaultValue }: Props) => {
  const defaultValues = setValuesFromCronString(control.values?.[name]);

  const [values, setValues] = useState<CronValue>(
    defaultValues ?? defaultCronValue
  );

  const fields = useMemo(() => {
    const periodForRender = values[PERIOD].label;

    return [
      {
        name: PERIOD,
        placeholder: DEFAULT_FIELDS.everyText,
        options: getPeriodOptions(DEFAULT_FIELDS.periods),
        prefix: DEFAULT_FIELDS.prefixPeriod,
      },
      {
        name: MONTHS,
        placeholder: DEFAULT_FIELDS.emptyMonths,
        options: getOptionsFromLabels(DEFAULT_FIELDS.months, UNITS[3]),
        isMulti: true,
        prefix: DEFAULT_FIELDS.prefixMonths,
        hidden: periodForRender !== "year",
      },
      {
        name: MONTHDAYS,
        placeholder: DEFAULT_FIELDS.emptyMonthDays,
        options: getOptionsFromNumbers(UNITS[2]),
        isMulti: true,
        prefix: DEFAULT_FIELDS.prefixMonthDays,
        hidden: periodForRender !== "year" && periodForRender !== "month",
      },
      {
        name: WEEKDAYS,
        placeholder: DEFAULT_FIELDS.emptyWeekDays,
        options: getOptionsFromLabels(DEFAULT_FIELDS.weekDays, UNITS[4]),
        isMulti: true,
        prefix:
          periodForRender === "week"
            ? DEFAULT_FIELDS.prefixWeekDays
            : DEFAULT_FIELDS.prefixWeekDaysForMonthAndYearPeriod,
        hidden:
          periodForRender !== "year" &&
          periodForRender !== "month" &&
          periodForRender !== "week",
      },
      {
        name: HOURS,
        placeholder: DEFAULT_FIELDS.emptyHours,
        options: getOptionsFromNumbers(UNITS[1]),
        isMulti: true,
        prefix: DEFAULT_FIELDS.prefixHours,
        hidden:
          periodForRender !== "year" &&
          periodForRender !== "month" &&
          periodForRender !== "week" &&
          periodForRender !== "day",
      },
      {
        name: MINUTES,
        placeholder:
          periodForRender === "hour"
            ? DEFAULT_FIELDS.emptyMinutesForHourPeriod
            : DEFAULT_FIELDS.emptyMinutes,
        isMulti: true,
        options: getOptionsFromNumbers(UNITS[0]),
        prefix:
          periodForRender === "hour"
            ? DEFAULT_FIELDS.prefixMinutesForHourPeriod
            : DEFAULT_FIELDS.prefixMinutes,
        suffix:
          periodForRender === "hour"
            ? DEFAULT_FIELDS.suffixMinutesForHourPeriod
            : null,
        hidden: periodForRender === "minute",
      },
    ];
  }, [values]);

  const onChange = (
    fieldName: keyof typeof values,
    value: { label: string; value: number } | { label: string; value: number }[]
  ) => {
    const newValue =
      fieldName === "period"
        ? { ...values, [fieldName]: value as { label: string; value: number } }
        : {
            ...values,
            [fieldName]: sortBy(
              [...(value as { label: string; value: number }[])],
              "value"
            ),
          };
    setValues(newValue);
    const newExpression = getCronStringFromValues(newValue);
    control.setFieldValue(name, newExpression);
  };

  const handleClear = async () => {
    await control.setFieldValue(name, defaultValue); // Clear cron string
    setValues(setValuesFromCronString(defaultValue) ?? defaultCronValue);
  };

  return (
    <Grid container wrap="wrap" spacing={1} alignItems="center">
      {fields.map(
        ({ hidden, prefix, suffix, name, placeholder, options, isMulti }) => (
          <Grid item key={name} hidden={hidden}>
            <Box display="flex" alignItems="center" minWidth={221}>
              {prefix && (
                <Box
                  mr={0.7}
                  display="flex"
                  justifyContent="flex-end"
                  minWidth={28}
                >
                  {prefix}
                </Box>
              )}
              {isMulti ? (
                <AutoCompleteMultipleSelect
                  options={options}
                  label={placeholder}
                  onChange={(value) =>
                    value &&
                    onChange(
                      name as keyof typeof values,
                      value as { label: string; value: number }[]
                    )
                  }
                  value={
                    values[
                      name as keyof typeof values
                    ] as AutocompleteSelectOption[]
                  }
                />
              ) : (
                <AutoCompleteSelect
                  options={options}
                  label={placeholder}
                  onChange={(value) =>
                    value &&
                    onChange(
                      name as keyof typeof values,
                      value as { label: string; value: number }
                    )
                  }
                  value={
                    values[
                      name as keyof typeof values
                    ] as AutocompleteSelectOption
                  }
                />
              )}

              {suffix && (
                <Box component="span" ml={0.7}>
                  {suffix}
                </Box>
              )}
            </Box>
          </Grid>
        )
      )}
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleClear}
        >
          Reset
        </Button>
      </Grid>
    </Grid>
  );
};
