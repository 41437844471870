import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Redirect, Switch, useParams, useRouteMatch } from "react-router-dom";

import { BaseUrlContext } from "libs/contexts";
import { routes } from "routes";

import { Route } from "components/utilities";

import RequestScheduleDetails from "./RequestScheduleDetails";
import { RequestScheduleDuplicate } from "./RequestScheduleDuplicate";
import { RequestScheduleUpdate } from "./RequestScheduleUpdate";
import RequestScheduleGeneral from "./general/RequestScheduleGeneral";
import { RequestSchedulePreviousRuns } from "./previous-runs/RequestSchedulePreviousRuns";

const basePath = routes.organizations[":organizationName"](":organizationName")
  .projects[":projectName"](":projectName")
  .requestSchedules[":scheduleName"](":scheduleName");

const RequestScheduleRoutesContainer = () => {
  const match = useRouteMatch();

  const { organizationName, projectName, scheduleName } =
    useParams<{
      organizationName: string;
      projectName: string;
      scheduleName: string;
    }>();

  const baseUrl = routes.organizations[":organizationName"](organizationName)
    .projects[":projectName"](projectName)
    .requestSchedules[":scheduleName"](scheduleName);

  return (
    <BaseUrlContext.Provider value={match.url}>
      <BreadcrumbsItem to={match.url}>{scheduleName}</BreadcrumbsItem>

      <Switch>
        <Route
          exact
          path={basePath.edit.index()}
          component={RequestScheduleUpdate}
        />
        <Route
          exact
          path={basePath.duplicate.index()}
          component={RequestScheduleDuplicate}
        />
        <RequestScheduleDetails>
          <Switch>
            <Route
              exact
              path={basePath.general.index()}
              component={RequestScheduleGeneral}
            />
            <Route
              exact
              path={basePath.previousRuns.index()}
              component={RequestSchedulePreviousRuns}
            />
            <Redirect to={baseUrl.general.index()} />
          </Switch>
        </RequestScheduleDetails>
        <Redirect to={baseUrl.general.index()} />
      </Switch>
    </BaseUrlContext.Provider>
  );
};

export default RequestScheduleRoutesContainer;
