import { IconButton as MuiIconButton, Tooltip } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useState } from "react";

import type { IconColor } from "./types";
import type { SvgIconTypeMap } from "@mui/material";
import type { OverridableComponent } from "@mui/material/OverridableComponent";
import type { AppThemeProps } from "assets/styles/theme/theme";
import type { ButtonProps } from "components/atoms/Button/types";
import type { CSSProperties } from "react";

export interface IconButtonProps extends ButtonProps {
  icon: OverridableComponent<SvgIconTypeMap>;
  hoverColor?: IconColor;
  htmlColor?: IconColor;
  style?: CSSProperties;
  fontSize?: "inherit" | "small" | "medium" | "large";
  tooltip?: string;
  iconColor?: string;
}

export const IconButton = ({
  icon: Icon,
  hoverColor,
  style,
  htmlColor = "inherit",
  fontSize,
  tooltip,
  iconColor,
  ...props
}: IconButtonProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme() as AppThemeProps;
  const muiIconColor = props.disabled
    ? "disabled"
    : isHovered
    ? hoverColor
    : htmlColor
    ? htmlColor
    : "primary";

  return tooltip ? (
    <Tooltip title={tooltip}>
      <span>
        <MuiIconButton
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={style}
          {...props}
        >
          <Icon
            color={muiIconColor}
            fontSize={fontSize}
            sx={{
              color: props.disabled
                ? theme.palette.disabled.primary
                : iconColor,
            }}
          />
        </MuiIconButton>
      </span>
    </Tooltip>
  ) : (
    <MuiIconButton
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={style}
      {...props}
    >
      <Icon
        color={muiIconColor}
        fontSize={fontSize}
        sx={{ color: iconColor }}
      />
    </MuiIconButton>
  );
};
