import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import WarningIcon from "@mui/icons-material/Warning";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/styles";

import { spacing } from "assets/styles/theme";

import type { CSSProperties } from "react";

type TestResultProps = {
  children: React.ReactNode;
  style?: CSSProperties;
  type?: "success" | "error";
};

export const TestResult = ({
  children,
  style = {},
  type = "success",
}: TestResultProps) => {
  const theme = useTheme();
  const color =
    type === "error" ? theme.palette.error.main : theme.palette.success.main;
  const background =
    type === "error" ? theme.palette.error.light : theme.palette.success.light;

  return (
    <Typography
      variant="h4"
      style={{
        ...style,
        padding: `${spacing[8]} ${spacing[16]}`,
        border: "2px solid",
        display: "flex",
        alignItems: "center",
        width: "fit-content",
        maxWidth: "100%",
        background,
        color,
        borderColor: color,
      }}
    >
      {type === "success" && (
        <CheckCircleOutlineIcon sx={{ marginRight: spacing[8] }} />
      )}
      {type === "error" && <WarningIcon sx={{ marginRight: spacing[8] }} />}
      <p
        style={{
          display: "inline-block",
          wordWrap: "break-word",
          margin: 0,
          maxWidth: "90%",
        }}
      >
        {children}
      </p>
    </Typography>
  );
};
