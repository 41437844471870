import {
  Box,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { Controller, useForm } from "react-hook-form";

import { useBackgroundColorByStatus } from "libs/hooks/useColorByStatus";
import { formatStatusLabel } from "libs/utilities/statuses";
import { capitalize } from "libs/utilities/utils";

import "./AdvancedToggleButtonGroup.scss";
import type { AppThemeProps } from "assets/styles/theme/theme";

type FormValues = { status: string };

type AdvancedToggleButtonGroupProps = {
  label: string;
  name: string;
  items: string[];
  setActiveButton: (data: FormValues) => void;
  defaultValues: FormValues;
};

const AdvancedToggleButtonGroup = ({
  label,
  name,
  items,
  setActiveButton,
  defaultValues,
}: AdvancedToggleButtonGroupProps) => {
  const { getValues, control } = useForm({ defaultValues });
  const theme = useTheme() as AppThemeProps;
  const backgroundByStatus = useBackgroundColorByStatus();

  const updateActiveButton = () => {
    setActiveButton(getValues());
  };

  return (
    <Box ml={2}>
      <Typography variant="subtitle2" gutterBottom={true}>
        {label}
      </Typography>
      <Box mr={1} mb={1} display="inline-block">
        <Controller
          control={control}
          name={name}
          render={({ onChange, onBlur, value, ref }) => (
            <ToggleButtonGroup
              onBlur={onBlur}
              onChange={(_, value) => {
                onChange(value);
                updateActiveButton();
              }}
              value={value}
              ref={ref}
              exclusive
              size="small"
            >
              {items &&
                items.map((item, index) => (
                  <ToggleButton
                    key={index}
                    disableFocusRipple={true}
                    value={item}
                    className={["toggle-button"].filter((x) => x).join(" ")}
                    sx={{
                      color: theme.palette.text.secondary,
                      borderColor: theme.palette.buttons.toggleButtonBorder,
                      "&:hover": {
                        backgroundColor:
                          theme.palette.buttons.toggleButtonHover,
                        color: theme.palette.text.primary,
                        borderLeft: `1px solid ${theme.palette.buttons.toggleButtonBorder} !important`,
                      },
                      "&.Mui-selected": {
                        backgroundColor: backgroundByStatus(item),
                        color: theme.palette.text.primary,
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor:
                          theme.palette.buttons.toggleButtonHover,
                        color: theme.palette.text.primary,
                        "&.Mui-selected, &.Mui-selected:hover": {
                          backgroundColor: backgroundByStatus(item),
                          color: theme.palette.primary.main,
                        },
                      },
                    }}
                  >
                    {capitalize(formatStatusLabel(item))}
                  </ToggleButton>
                ))}
            </ToggleButtonGroup>
          )}
        />
      </Box>
    </Box>
  );
};

export default AdvancedToggleButtonGroup;
