import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  projectsCreate,
  useProjectsList,
} from "libs/data/endpoints/projects/projects";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";
import { setProject, setProjects } from "store/features";

import type { AxiosError } from "axios";
import type { ProjectCreate } from "libs/data/models";

export const useProjectCreate = (organizationName: string) => {
  const dispatch = useDispatch();

  const { mutate } = useProjectsList(
    { organization: organizationName },
    { swr: { enabled: Boolean(organizationName) } }
  );

  return useCallback(
    async (data: ProjectCreate) => {
      try {
        const response = await projectsCreate(data);
        mutate().then((data) => data && dispatch(setProjects(data)));
        dispatch(createSuccessNotification(`Project ${data.name} created`));
        dispatch(setProject(response));
      } catch (err: unknown) {
        const error = err as AxiosError;
        dispatch(createErrorNotification(error.message));
      }
    },
    [dispatch, mutate]
  );
};
