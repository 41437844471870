import ClearIcon from "@mui/icons-material/Clear";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Search from "@mui/icons-material/Search";
import {
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  TablePagination,
  useTheme,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  LinearProgress,
  Select,
  MenuItem,
} from "@mui/material";
import { useMemo, useState } from "react";
import AnimateHeight from "react-animate-height";

import { spacing } from "assets/styles/theme";
import { LabelsList } from "components/organisms/LabelsFilterToolbar/LabelsList";
import { TRAINING_DEPLOYMENT } from "pages/organizations/:organizationName/projects/:projectName/training/constants";

import type { LabelFilter } from "components/organisms";
import { onFilterRemove } from "components/organisms";

import { BaseTableHead } from "./BaseTableHeader";
import { BaseTableTitle } from "./BaseTableTitle";
import { EmptyRow } from "./EmptyRow";
import { useSortedRowsByColumnName } from "./useSortedRowsByColumnName";

import type { BaseTableTitleProps } from "./BaseTableTitle";
import type { BaseColumn, BaseRow } from "./types";
import type { SxProps } from "@mui/material";
import type { AppThemeProps } from "assets/styles/theme/theme";

type BaseTableProps = {
  columns: BaseColumn[];
  data: any[] | undefined;
  onSelectAll?: (isAdd: boolean) => void;
  isAllChecked?: boolean;
  isAnyChecked?: boolean;
  MultiAction?: React.ElementType;
  tableStyles?: SxProps;
  filters?: LabelFilter[];
  setFilters?: React.Dispatch<React.SetStateAction<LabelFilter[]>>;
  hasSearchBar?: boolean;
  hasSearchField?: boolean;
  hasPagination?: boolean;
  preFillSearchTerm?: string;
  onRowClick?: (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    row: any
  ) => void;
  onRowsPerPageChange?: (pageSize: number) => void;
  defaultPageSize?: 5 | 10 | 25 | 50 | 100;
  rowsPerPageOptions?: number[];
  toolbar?: React.ElementType;
  action?: JSX.Element;
  defaultSortColumn?: string;
  defaultSortDirection?: "asc" | "desc";
  isLoading?: boolean;
  renderDetailPanel?: (rowData: BaseRow) => JSX.Element;
  expandedRowIndex?: number;
  clearExpandedRow?: () => void;
  editRowId?: string;
  editedRow?: BaseRow;
  setEditedRow?: (rowData: BaseRow) => void;
} & BaseTableTitleProps;

export function BaseTable({
  columns,
  data: rows,
  onRowClick,
  onSelectAll,
  isAllChecked,
  isAnyChecked,
  MultiAction,
  tableStyles = {},
  title,
  header,
  filters,
  setFilters,
  onRowsPerPageChange,
  hasSearchBar = true,
  hasSearchField = true,
  hasPagination = true,
  preFillSearchTerm,
  defaultPageSize,
  rowsPerPageOptions,
  toolbar,
  action,
  defaultSortColumn = "",
  defaultSortDirection,
  isLoading,
  renderDetailPanel,
  expandedRowIndex,
  clearExpandedRow,
  editRowId,
  editedRow,
  setEditedRow,
}: BaseTableProps) {
  const [search, setSearch] = useState<string | undefined>(preFillSearchTerm);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(defaultPageSize || 25);
  const theme = useTheme() as AppThemeProps;
  const {
    sortedRows,
    sortDirection,
    setSorting,
    columnName: sortColumn,
  } = useSortedRowsByColumnName({
    rows,
    columnName: defaultSortColumn,
    defaultSortDirection,
    columns,
  });

  const searchedRows = useMemo(() => {
    const filteredRows = filters?.length
      ? sortedRows?.filter((row) =>
          filters?.some(
            (filter) =>
              Object.keys(row["labels"]).includes(filter.key) &&
              Object.values(row["labels"]).includes(filter.value)
          )
        )
      : sortedRows;

    return search
      ? filteredRows?.filter((row) =>
          Object.values(row)
            .reduce(
              (prevVal: string, value) =>
                typeof value === "string" ? prevVal.concat(value) : prevVal,
              ""
            )
            .includes(search)
        )
      : filteredRows;
  }, [sortedRows, filters, search]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const size = parseInt(event.target.value, 10) as 10 | 25 | 50 | 100;
    onRowsPerPageChange?.(size);
    clearExpandedRow?.();
    setPageSize(size);
    setPage(0);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        {title ||
          (header && (
            <Box width={"50%"}>
              <BaseTableTitle title={title} header={header} />
            </Box>
          ))}
        {hasSearchBar && (
          <Grid
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            item
            width={header || title ? "50%" : "100%"}
            paddingBottom={spacing[16]}
          >
            <Grid>{action}</Grid>
            <Grid display="flex" alignItems="center">
              {hasSearchField && (
                <TextField
                  value={search || ""}
                  onChange={(event) => {
                    setSearch(event.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search sx={{ color: theme.palette.text.secondary }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setSearch(undefined)}>
                          <ClearIcon
                            sx={{ color: theme.palette.text.secondary }}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search"
                  id="standard-basic"
                  sx={{
                    width: 220,
                  }}
                  variant="standard"
                />
              )}
              {toolbar}
            </Grid>
          </Grid>
        )}
        <Box width={"100%"}>
          {filters && filters.length > 0 && setFilters && (
            <LabelsList
              labels={filters}
              onLabelDelete={onFilterRemove(setFilters)}
            />
          )}
        </Box>
      </Box>
      <TableContainer>
        <Table
          sx={{ minWidth: 300, ...tableStyles }}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <BaseTableHead
            isAllChecked={isAllChecked}
            isAnyChecked={isAnyChecked}
            columns={columns}
            onSelectAll={onSelectAll}
            MultiAction={MultiAction}
            sortDirection={sortDirection}
            setSorting={setSorting}
            sortColumn={sortColumn}
          />
          <TableBody
            sx={{
              height: !rows?.length ? spacing[220] : undefined,
              position: !rows?.length ? "relative" : undefined,
            }}
          >
            {!searchedRows?.length && <EmptyRow />}
            {searchedRows
              ?.slice(page * pageSize, page * pageSize + pageSize)
              .map((row: any, idx) => {
                const isRowEdit = editRowId && editRowId === row?.id;
                const isRowExpanded =
                  expandedRowIndex === page * pageSize + idx;

                const backgroundColor =
                  row.name === TRAINING_DEPLOYMENT
                    ? theme.palette.background.active
                    : idx % 2 === 0
                    ? theme.palette.background.tableRow
                    : "";
                const editingBackground =
                  editRowId && !isRowEdit && theme.palette.background.tableRow;

                return (
                  <>
                    <TableRow
                      hover
                      onClick={(event) => onRowClick?.(event, row)}
                      tabIndex={-1}
                      key={row.name}
                      sx={{
                        cursor: "pointer",
                        height: 50,
                        pointerEvents:
                          editRowId && !isRowEdit ? "none" : undefined,
                        filter:
                          editRowId && !isRowEdit
                            ? "brightness(90%)"
                            : undefined,
                        backgroundColor: editingBackground || backgroundColor,
                      }}
                    >
                      {columns.map(
                        (column: BaseColumn, columnIndex: number) => {
                          const isColumnEdit =
                            isRowEdit && column.editable && column.field;

                          return (
                            <>
                              {isColumnEdit && (
                                <TableCell
                                  align={column.align || "left"}
                                  width={column.width}
                                  key={column.field}
                                  sx={{
                                    "& .MuiLink-root": {
                                      color: theme.palette.text.primary,
                                      margin: spacing[8],
                                    },
                                  }}
                                >
                                  {!column.options && (
                                    <TextField
                                      style={{
                                        color: theme.palette.text.primary,
                                      }}
                                      fullWidth
                                      error={
                                        column.validate &&
                                        Boolean(column.validate?.(row))
                                      }
                                      helperText={column.validate?.(row)}
                                      value={editedRow?.[column.field]}
                                      onChange={(e) =>
                                        setEditedRow?.({
                                          ...editedRow,
                                          id: editedRow?.id || "",
                                          [column.field || ""]:
                                            e.target.value || "",
                                        })
                                      }
                                      variant="standard"
                                    />
                                  )}
                                  {column.options && (
                                    <Select
                                      value={String(editedRow?.[column.field])}
                                      variant="standard"
                                      onChange={(e) =>
                                        setEditedRow?.({
                                          ...editedRow,
                                          id: editedRow?.id || "",
                                          [column.field || ""]:
                                            e.target.value || "",
                                        })
                                      }
                                    >
                                      {column.options.map(
                                        ({ label, value }) => (
                                          <MenuItem
                                            key={value}
                                            value={value as string}
                                          >
                                            {label}
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                  )}
                                </TableCell>
                              )}
                              {!renderDetailPanel && !isColumnEdit && (
                                <TableCell
                                  align={column.align || "left"}
                                  width={column.width}
                                  key={column.field}
                                  onClick={(e) => {
                                    const selection = window
                                      .getSelection()
                                      ?.toString();
                                    const columnValue = column.render
                                      ? column.render(row)
                                      : row[column.field];
                                    // prevent row actions if user tries to copy
                                    if (
                                      columnValue &&
                                      selection &&
                                      (columnValue.includes(selection) ||
                                        selection.includes(columnValue))
                                    ) {
                                      e.preventDefault();
                                      e.stopPropagation();
                                    }
                                  }}
                                  sx={{
                                    "& .MuiLink-root": {
                                      color: theme.palette.text.primary,
                                    },
                                  }}
                                >
                                  {column?.render
                                    ? column.render(row)
                                    : row[column.field]}
                                </TableCell>
                              )}
                              {renderDetailPanel && columnIndex !== 0 && (
                                <TableCell
                                  align={column.align || "left"}
                                  width={column.width}
                                  key={column.field}
                                  sx={{
                                    "& .MuiLink-root": {
                                      color: theme.palette.text.primary,
                                    },
                                  }}
                                >
                                  {column?.render
                                    ? column.render(row)
                                    : row[column.field]}
                                </TableCell>
                              )}
                              {renderDetailPanel && columnIndex === 0 && (
                                <TableCell
                                  align={column.align || "left"}
                                  width="20px"
                                  key={column.field}
                                  sx={{
                                    paddingTop: spacing[12],
                                  }}
                                >
                                  <KeyboardArrowRight
                                    style={{
                                      transition: "all 0.2s linear",
                                      transform:
                                        expandedRowIndex ===
                                        page * pageSize + idx
                                          ? "rotate(90deg)"
                                          : "rotate(0deg)",
                                    }}
                                  />
                                </TableCell>
                              )}
                            </>
                          );
                        }
                      )}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{ borderBottom: 0 }}
                        padding="none"
                        colSpan={columns.length}
                      >
                        <AnimateHeight
                          duration={500}
                          height={isRowExpanded ? "auto" : 0}
                        >
                          {isRowExpanded &&
                            renderDetailPanel &&
                            renderDetailPanel(row)}
                        </AnimateHeight>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
          </TableBody>
        </Table>
        {isLoading && <LinearProgress />}
        {hasPagination && (
          <TablePagination
            component="div"
            count={searchedRows?.length ?? 0}
            page={page}
            onPageChange={(_e, newPage: number) => {
              clearExpandedRow?.();
              setPage(newPage);
            }}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
          />
        )}
      </TableContainer>
    </Box>
  );
}
