import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { AutoCompleteSelectHookForm } from "components/atoms/UncontrolledAutoComplete/AutoCompleteSelectHookForm";
import {
  FIELD_DEPLOYMENT_VERSION_INSTANCE_TYPE,
  FIELD_GPU_INSTANCE_ENABLED,
} from "libs/constants/fields";
import { useInstanceTypeGroupsList } from "libs/data/endpoints/instances/instances";

import { WarningText } from "components/atoms";

import type { AutocompleteSelectOption } from "components/atoms/AutoCompleteSelect";
import type { InstanceTypeCreate } from "libs/data/models";

type InstanceTypeFieldProps = {
  defaultValue?: string;
  isEditForm?: boolean;
  gpuEnabled?: boolean;
  isDeploymentVersionForm?: boolean;
};

export const InstanceTypeField = ({
  defaultValue,
  isEditForm = false,
}: InstanceTypeFieldProps) => {
  const { projectName } =
    useParams<{
      projectName: string;
      deploymentName: string;
      versionName: string;
    }>();
  const { data: instanceTypeGroups } = useInstanceTypeGroupsList(projectName, {
    limit: 50,
  });

  const instanceTypesList = instanceTypeGroups?.results;

  const { control, setValue } = useFormContext();
  const theme = useTheme();
  const instanceType: AutocompleteSelectOption | undefined = useWatch({
    control,
    name: FIELD_DEPLOYMENT_VERSION_INSTANCE_TYPE,
  });
  const gpuInstanceModeEnabled = useWatch({
    control,
    name: FIELD_GPU_INSTANCE_ENABLED,
  });

  const configuredInstanceTypes = useMemo(() => {
    let options;

    if (gpuInstanceModeEnabled) {
      options = instanceTypesList
        ? instanceTypesList.filter(
            (opt) => (opt?.instance_types?.[0].accelerator || 0) > 0
          )
        : [];
    } else {
      options = instanceTypesList
        ? instanceTypesList.filter(
            (opt) => (opt?.instance_types?.[0].accelerator || 0) === 0
          )
        : [];
    }

    options = options.map(({ id, name }) => ({
      label: name ?? "",
      value: id,
    }));

    const defaultOption = (defaultValue &&
      options?.find((opt) => [opt.label, opt.value].includes(defaultValue))) ||
      options[0] || { label: "", value: "" };

    if (!defaultValue) setValue(FIELD_DEPLOYMENT_VERSION_INSTANCE_TYPE, null);

    return {
      options,
      defaultOption,
    };
  }, [gpuInstanceModeEnabled, defaultValue, setValue, instanceTypesList]);

  const currentInstanceGroup = useMemo(
    () =>
      instanceTypesList?.find(
        (instance) => instance.id === instanceType?.value
      ),
    [instanceType?.value, instanceTypesList]
  );

  const uniquePriorities = [
    ...new Set(
      currentInstanceGroup?.instance_types?.map(
        (obj: InstanceTypeCreate) => obj.priority
      )
    ),
  ].sort();

  const priorityGroups = uniquePriorities.map(
    (priority) =>
      currentInstanceGroup?.instance_types?.filter(
        (obj: InstanceTypeCreate) => obj.priority === priority
      ) as InstanceTypeCreate[]
  );

  return (
    <>
      <Box maxWidth={spacing[310]}>
        <AutoCompleteSelectHookForm
          defaultValue={configuredInstanceTypes.defaultOption}
          name={FIELD_DEPLOYMENT_VERSION_INSTANCE_TYPE}
          label="Instance type (group)"
          options={configuredInstanceTypes.options}
        />
      </Box>
      {isEditForm &&
        configuredInstanceTypes.defaultOption &&
        currentInstanceGroup?.name !==
          configuredInstanceTypes.defaultOption?.value && (
          <WarningText>
            <Box mt={1} component="span">
              These changes will cause the version to restart and therefore be
              unavailable for ~30 seconds.
            </Box>
          </WarningText>
        )}
      <Accordion
        disableGutters
        elevation={0}
        square
        sx={{
          paddingTop: spacing[8],
          "&.MuiAccordion-root:before": { display: "none" },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore sx={{ color: theme.palette.primary.main }} />}
          sx={{
            padding: 0,
            justifyContent: "start",
            alignItems: "center",
            minHeight: spacing[32],
            flexDirection: "row-reverse",
            whiteSpace: "nowrap",
            "& > .MuiAccordionSummary-content": {
              margin: 0,
              marginLeft: spacing[8],
            },
          }}
        >
          Expand for details and credit rates associated with this instance type
          group
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: spacing[4],
          }}
        >
          {uniquePriorities.map((priority, i) => (
            <Grid key={priority} display="flex" marginBottom={spacing[12]}>
              <Grid marginRight={spacing[8]}>
                <Typography variant="h6">
                  Priority {(priority || 0) + 1}:
                </Typography>
              </Grid>
              <Grid>
                {(
                  priorityGroups[i] as unknown as {
                    display_name: string;
                    credit_rate: number;
                  }[]
                ).map(({ display_name, credit_rate }) => (
                  <Grid key={display_name}>
                    {display_name} for{" "}
                    <span
                      style={{
                        color: theme.palette.info.main,
                        fontStyle: "italic",
                        fontWeight: "bold",
                      }}
                    >
                      {credit_rate} credits/hr
                    </span>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}
        </AccordionDetails>
      </Accordion>
    </>
  );
};
