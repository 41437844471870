import {
  TextareaAutosize,
  InputLabel,
  FormHelperText,
  useTheme,
} from "@mui/material";
import { get as lodashGet } from "lodash";
import { useFormContext } from "react-hook-form";

import type { TextareaAutosizeProps } from "@mui/material";
import type { RegisterOptions } from "react-hook-form";

import "./Textarea.scss";

export type TextareaProps = TextareaAutosizeProps & {
  name: string;
  rules?: RegisterOptions;
  label?: string;
  defaultValue?: string;
};

export const Textarea = ({
  name,
  rules = {},
  label,
  defaultValue,
  ...props
}: TextareaProps) => {
  const { errors, register } = useFormContext();
  const error = lodashGet(errors, name);
  const theme = useTheme();

  return (
    <div
      className={["textarea", !!error && "textarea--error"]
        .filter(Boolean)
        .join(" ")}
    >
      {label && (
        <InputLabel
          shrink
          htmlFor={name}
          className="textarea__label"
          error={error}
        >
          {label}
        </InputLabel>
      )}

      <TextareaAutosize
        onResize={undefined}
        onResizeCapture={undefined}
        id={name}
        name={name}
        ref={register(rules)}
        defaultValue={defaultValue}
        className="textarea__input"
        {...props}
        style={{
          borderColor: `${
            error ? theme.palette.error.main : theme.palette.primary.main
          }`,
          background: "inherit",
        }}
      />
      <FormHelperText id={name} error={!!error} hidden={!error}>
        {error?.message}
      </FormHelperText>
    </div>
  );
};
