import { useTheme } from "@mui/styles";

export const IlluIncorrectPermissions = () => {
  const theme = useTheme();

  return (
    <svg
      width="307"
      height="213"
      viewBox="0 0 307 213"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4744_31820)">
        <path
          d="M60.9935 212.987C94.6792 212.987 121.987 210.913 121.987 208.355C121.987 205.797 94.6792 203.723 60.9935 203.723C27.3077 203.723 0 205.797 0 208.355C0 210.913 27.3077 212.987 60.9935 212.987Z"
          fill="#E6E6E6"
        />
        <path
          d="M46.6221 47.2573C57.2368 42.7426 70.4576 45.6031 83.3691 47.5884C83.4892 45.1842 84.9456 42.2581 83.42 40.5808C81.5666 38.543 81.7372 36.4018 82.8525 34.2484C85.7045 28.7416 81.6184 22.8821 77.4927 18.0099C76.5628 16.9143 75.3876 16.0534 74.0625 15.4972C72.7374 14.941 71.2998 14.7052 69.8664 14.809L62.2239 15.3549C60.3642 15.4877 58.5894 16.1854 57.137 17.3544C55.6846 18.5235 54.6239 20.1083 54.0968 21.8967C52.2719 24.3786 51.2952 26.8536 51.9101 29.3157C49.1767 31.1837 48.7175 33.4385 49.9458 35.9804C50.9873 37.5808 50.9762 39.1388 49.8984 40.6536C48.4829 42.5848 47.3987 44.7382 46.6904 47.0255L46.6221 47.2573Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M298.872 184.125H82.5309C80.607 184.125 78.7619 183.361 77.4015 182C76.0411 180.64 75.2769 178.795 75.2769 176.871C153.7 167.757 230.65 167.757 306.126 176.871C306.126 178.795 305.361 180.64 304.001 182C302.64 183.361 300.795 184.125 298.872 184.125Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M306.126 177.297L75.2769 176.87L102.031 131.853L102.159 131.64V32.4258C102.159 31.2216 102.396 30.0291 102.857 28.9165C103.318 27.8039 103.993 26.793 104.845 25.9414C105.697 25.0899 106.707 24.4145 107.82 23.9537C108.933 23.4929 110.125 23.2558 111.329 23.2559H268.793C269.997 23.2558 271.19 23.4929 272.302 23.9537C273.415 24.4145 274.426 25.0899 275.277 25.9414C276.129 26.793 276.804 27.8039 277.265 28.9165C277.726 30.0291 277.963 31.2216 277.963 32.4258V132.322L306.126 177.297Z"
          fill={theme.palette.primary.light}
        />
        <path
          d="M111.12 29.6562C110.328 29.6571 109.569 29.9721 109.009 30.5321C108.449 31.0921 108.134 31.8513 108.133 32.6432V123.105C108.134 123.897 108.449 124.656 109.009 125.216C109.569 125.776 110.328 126.091 111.12 126.092H270.282C271.074 126.091 271.833 125.776 272.393 125.216C272.953 124.656 273.268 123.897 273.269 123.105V32.6432C273.268 31.8513 272.953 31.0921 272.393 30.5321C271.833 29.9722 271.074 29.6572 270.282 29.6562H111.12Z"
          fill="white"
        />
        <path
          d="M111.787 138.039C111.543 138.039 111.303 138.11 111.097 138.242C110.891 138.374 110.727 138.562 110.624 138.784L102.38 156.706C102.291 156.901 102.251 157.115 102.266 157.33C102.281 157.544 102.35 157.751 102.465 157.931C102.581 158.112 102.741 158.261 102.929 158.364C103.117 158.467 103.329 158.521 103.543 158.521H276.908C277.126 158.521 277.341 158.465 277.532 158.359C277.722 158.253 277.882 158.099 277.997 157.914C278.112 157.728 278.177 157.516 278.187 157.298C278.197 157.08 278.151 156.863 278.053 156.668L269.092 138.747C268.986 138.534 268.823 138.354 268.621 138.229C268.419 138.104 268.185 138.038 267.947 138.039H111.787Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M190.061 28.1626C191.121 28.1626 191.981 27.3029 191.981 26.2424C191.981 25.182 191.121 24.3223 190.061 24.3223C189 24.3223 188.141 25.182 188.141 26.2424C188.141 27.3029 189 28.1626 190.061 28.1626Z"
          fill="white"
        />
        <path
          d="M173.021 161.936C172.739 161.936 172.466 162.029 172.243 162.2C172.019 162.371 171.858 162.611 171.785 162.883L169.717 170.563C169.666 170.753 169.659 170.952 169.697 171.145C169.735 171.338 169.817 171.519 169.937 171.675C170.056 171.831 170.21 171.957 170.386 172.044C170.563 172.131 170.756 172.177 170.953 172.177H210.28C210.484 172.177 210.685 172.128 210.866 172.034C211.048 171.941 211.204 171.805 211.322 171.639C211.441 171.473 211.518 171.28 211.547 171.078C211.576 170.876 211.556 170.67 211.489 170.478L208.83 162.797C208.743 162.545 208.58 162.327 208.363 162.173C208.146 162.019 207.887 161.936 207.621 161.936H173.021Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M277.963 130.146V131.853H102.031L102.164 131.64V130.146H277.963Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M273.056 59.739C289.552 59.739 302.925 46.366 302.925 29.8695C302.925 13.373 289.552 0 273.056 0C256.559 0 243.186 13.373 243.186 29.8695C243.186 46.366 256.559 59.739 273.056 59.739Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M285.43 42.8836H260.681C260.228 42.8831 259.795 42.7032 259.475 42.3832C259.155 42.0632 258.975 41.6293 258.974 41.1767V26.242C258.975 25.7895 259.155 25.3556 259.475 25.0356C259.795 24.7156 260.228 24.5356 260.681 24.5352H285.43C285.883 24.5356 286.316 24.7156 286.636 25.0356C286.956 25.3556 287.136 25.7895 287.137 26.242V41.1767C287.136 41.6293 286.956 42.0632 286.636 42.3832C286.316 42.7032 285.883 42.8831 285.43 42.8836ZM260.681 26.242V41.1767H285.431L285.43 26.242L260.681 26.242Z"
          fill="white"
        />
        <path
          d="M280.736 26.2423H265.375V19.415C265.375 14.47 268.605 10.8809 273.056 10.8809C277.506 10.8809 280.736 14.47 280.736 19.415L280.736 26.2423ZM267.082 24.5355H279.03V19.415C279.03 15.3952 276.573 12.5877 273.056 12.5877C269.538 12.5877 267.082 15.3953 267.082 19.415L267.082 24.5355Z"
          fill="white"
        />
        <path
          d="M273.055 34.9898C273.998 34.9898 274.762 34.2257 274.762 33.283C274.762 32.3403 273.998 31.5762 273.055 31.5762C272.113 31.5762 271.349 32.3403 271.349 33.283C271.349 34.2257 272.113 34.9898 273.055 34.9898Z"
          fill="white"
        />
        <path
          d="M252.204 95.4082H129.198C128.594 95.4075 128.015 95.1672 127.588 94.7402C127.161 94.3132 126.921 93.7342 126.92 93.1303V62.6177C126.921 62.0138 127.161 61.4348 127.588 61.0078C128.015 60.5808 128.594 60.3406 129.198 60.3398H252.204C252.808 60.3406 253.387 60.5808 253.814 61.0078C254.241 61.4348 254.481 62.0138 254.482 62.6177V93.1303C254.481 93.7342 254.241 94.3132 253.814 94.7402C253.387 95.1672 252.808 95.4075 252.204 95.4082ZM129.198 61.251C128.836 61.2514 128.489 61.3955 128.232 61.6518C127.976 61.908 127.832 62.2554 127.832 62.6177V93.1303C127.832 93.4926 127.976 93.84 128.232 94.0963C128.489 94.3525 128.836 94.4966 129.198 94.497H252.204C252.567 94.4966 252.914 94.3525 253.17 94.0963C253.427 93.84 253.571 93.4926 253.571 93.1303V62.6177C253.571 62.2554 253.427 61.908 253.17 61.6517C252.914 61.3955 252.567 61.2514 252.204 61.251L129.198 61.251Z"
          fill="#E6E6E6"
        />
        <path
          d="M148.311 85.9816C153.435 85.9816 157.59 81.8273 157.59 76.7027C157.59 71.5781 153.435 67.4238 148.311 67.4238C143.186 67.4238 139.032 71.5781 139.032 76.7027C139.032 81.8273 143.186 85.9816 148.311 85.9816Z"
          fill="#E6E6E6"
        />
        <path
          d="M169.74 70.5176C169.537 70.5173 169.336 70.557 169.148 70.6346C168.96 70.7122 168.789 70.826 168.645 70.9697C168.501 71.1133 168.387 71.2839 168.309 71.4717C168.231 71.6595 168.191 71.8608 168.191 72.0641C168.191 72.2674 168.231 72.4687 168.309 72.6564C168.387 72.8442 168.501 73.0148 168.645 73.1585C168.789 73.3021 168.96 73.416 169.148 73.4935C169.336 73.5711 169.537 73.6109 169.74 73.6105H242.646C243.056 73.6105 243.449 73.4476 243.739 73.1576C244.029 72.8676 244.192 72.4742 244.192 72.0641C244.192 71.6539 244.029 71.2606 243.739 70.9705C243.449 70.6805 243.056 70.5176 242.646 70.5176H169.74Z"
          fill="#E6E6E6"
        />
        <path
          d="M169.741 79.7969C169.331 79.7969 168.937 79.9598 168.647 80.2498C168.357 80.5398 168.194 80.9332 168.194 81.3434C168.194 81.7535 168.357 82.1469 168.647 82.4369C168.937 82.7269 169.331 82.8898 169.741 82.8898H201.112C201.522 82.8898 201.916 82.7269 202.206 82.4369C202.496 82.1469 202.659 81.7535 202.659 81.3434C202.659 80.9332 202.496 80.5398 202.206 80.2498C201.916 79.9598 201.522 79.7969 201.112 79.7969H169.741Z"
          fill="#E6E6E6"
        />
        <path
          d="M101.729 80.4212L78.99 92.5101L78.7021 82.436C86.1239 81.1971 93.1849 79.0399 99.7138 75.5281L102.092 69.6526C102.351 69.0124 102.772 68.4506 103.314 68.0223C103.856 67.594 104.5 67.314 105.183 67.2097C105.865 67.1054 106.564 67.1805 107.209 67.4276C107.854 67.6746 108.423 68.0852 108.862 68.6189C109.503 69.3997 109.824 70.3952 109.759 71.4036C109.693 72.4121 109.247 73.358 108.511 74.0499L101.729 80.4212Z"
          fill="#FFB8B8"
        />
        <path
          d="M46.1691 150.219C46.0032 149.61 45.968 148.974 46.0657 148.351C46.1635 147.728 46.392 147.132 46.7363 146.604L51.7313 138.938C52.9127 137.125 54.7256 135.815 56.818 135.263C58.9105 134.711 61.1335 134.956 63.0556 135.951C60.9564 139.524 61.249 142.659 63.7806 145.383C59.8506 147.301 56.2183 149.778 52.9963 152.735C52.4081 153.136 51.7326 153.39 51.0261 153.478C50.3196 153.565 49.6024 153.482 48.9345 153.235C48.2665 152.989 47.6671 152.587 47.186 152.062C46.705 151.537 46.3563 150.905 46.1691 150.219Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M102.764 140.196C102.008 141.456 100.941 142.5 99.6655 143.229C98.3899 143.958 96.9483 144.346 95.4792 144.357L62.2956 144.609L60.8564 136.262L75.5358 131.944L63.1591 122.446L76.6871 106.615L101.258 129.516C102.694 130.855 103.627 132.646 103.902 134.59C104.176 136.534 103.774 138.513 102.764 140.196Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M57.1148 197.281H49.3434C42.3657 149.452 35.2632 101.49 56.2513 83.5879L81.0047 87.6175L77.8386 108.629L64.0227 124.46L57.1148 197.281Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M63.9464 208.116C63.338 208.282 62.7014 208.317 62.0785 208.22C61.4555 208.122 60.8603 207.893 60.3319 207.549L51.8938 206.415C50.0805 205.234 48.7708 203.421 48.2186 201.328C47.6665 199.236 47.9112 197.013 48.9055 195.091C52.4789 197.19 55.6136 196.897 58.3376 194.365C60.2624 198.252 63.4337 198.015 66.4624 201.289C66.8634 201.877 67.118 202.552 67.2052 203.259C67.2923 203.965 67.2093 204.683 66.9631 205.351C66.717 206.019 66.3148 206.618 65.7901 207.099C65.2653 207.58 64.6332 207.929 63.9464 208.116Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M75.5361 51.6396L61.1445 48.1856C63.5347 43.2931 63.7316 37.8491 62.5837 32.0671L72.3699 31.7793C72.6766 38.9693 73.6042 45.6867 75.5361 51.6396Z"
          fill="#FFB8B8"
        />
        <path
          d="M79.4911 96.2843C69.0146 103.423 61.6122 96.5286 55.9633 85.891C56.7482 79.3817 55.4773 71.592 53.1233 63.1279C52.0765 59.3947 52.4673 55.4046 54.2187 51.9454C55.9701 48.4862 58.9547 45.8094 62.5834 44.4434L74.9601 49.6243C85.4694 58.1906 87.5379 67.4695 83.595 77.2561L79.4911 96.2843Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M49.3432 58.8342L37.83 64.8787L58.266 76.9676L61.1095 83.9816C61.2993 84.4498 61.3918 84.9517 61.3812 85.4567C61.3707 85.9618 61.2575 86.4594 61.0483 86.9192C60.8392 87.3791 60.5387 87.7915 60.1649 88.1314C59.7912 88.4713 59.3522 88.7315 58.8746 88.8962C58.3318 89.0834 57.753 89.1424 57.1835 89.0685C56.614 88.9947 56.0694 88.79 55.5922 88.4705C55.1151 88.151 54.7184 87.7254 54.4331 87.227C54.1479 86.7287 53.982 86.171 53.9482 85.5978L53.6607 80.7094L27.6868 72.138C26.8117 71.8492 26.0138 71.365 25.3534 70.7222C24.6931 70.0794 24.1878 69.2947 23.8756 68.4276C23.4206 67.1638 23.3975 65.7847 23.8098 64.5063C24.2221 63.2278 25.0466 62.1221 26.1542 61.3622L47.0406 47.0332L49.3432 58.8342Z"
          fill="#FFB8B8"
        />
        <path
          d="M60.8568 59.9852C56.0551 57.8809 51.6887 60.1668 46.1775 62L45.314 46.745C50.7864 43.8283 56.0041 43.0419 60.8568 45.3059L60.8568 59.9852Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M66.6005 39.3339C71.6752 39.3339 75.789 35.2201 75.789 30.1455C75.789 25.0708 71.6752 20.957 66.6005 20.957C61.5259 20.957 57.4121 25.0708 57.4121 30.1455C57.4121 35.2201 61.5259 39.3339 66.6005 39.3339Z"
          fill="#FFB8B8"
        />
        <path
          d="M77.5931 28.987C68.4958 29.878 61.5637 28.3902 57.1333 24.1538V20.7422H76.8211L77.5931 28.987Z"
          fill={theme.palette.primary.main}
        />
      </g>
      <defs>
        <clipPath id="clip0_4744_31820">
          <rect width="306.125" height="212.987" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
