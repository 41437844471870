import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  notificationGroupsDelete,
  useNotificationGroupsList,
} from "libs/data/endpoints/monitoring/monitoring";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

export const useNotificationGroupDelete = (projectName: string) => {
  const dispatch = useDispatch();
  const { data: notificationGroups, mutate } =
    useNotificationGroupsList(projectName);

  return useCallback(
    async (notificationGroupName: string) => {
      try {
        await notificationGroupsDelete(projectName, notificationGroupName);
        dispatch(createSuccessNotification("Notification group was deleted"));
        mutate(
          notificationGroups?.filter(
            (env) => env.name !== notificationGroupName
          )
        );
      } catch (err: any) {
        dispatch(createErrorNotification(err.message));
      }
    },
    [projectName, dispatch, mutate, notificationGroups]
  );
};
