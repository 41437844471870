import { Grid } from "@mui/material";
import { Formik } from "formik";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { ControlledEnvironmentSelect } from "components/molecules/Fields/CodeEnvironmentSelect/ControlledEnvironmentSelect";
import {
  FIELD_DEPLOYMENT_VERSION_BASE_ENVIRONMENT,
  FIELD_DESCRIPTION,
  FIELD_LABELS,
  FIELD_NAME,
} from "libs/constants/fields";
import { useOrganizationsFeaturesGet } from "libs/data/endpoints/organizations/organizations";
import { explanations } from "libs/utilities/explanations";

import { PrimaryButton } from "components/atoms";
import type { LabelsDict } from "components/molecules";
import {
  ControlledLabelsInput,
  FormikTextInput,
  FormSection,
} from "components/molecules";

import { CustomDependenciesSection } from "./CustomDependenciesSection";
import { EnvironmentBaseSchema, EnvironmentBaseValues } from "./constants";

import type { EnvironmentBaseProps } from "./constants";
import type { FormikProps, FormikValues, FormikHelpers } from "formik";

interface EnvironmentBaseFormProps {
  baseEnvironmentDisabled?: boolean;
  dependenciesRequired?: boolean;
  initialValues?: Partial<EnvironmentBaseProps>;
  onSubmit: (
    values: EnvironmentBaseProps,
    helpers: FormikHelpers<EnvironmentBaseProps>
  ) => void;
  submitLabel?: string;
}

export const EnvironmentBaseForm = ({
  baseEnvironmentDisabled,
  dependenciesRequired,
  initialValues = {},
  onSubmit,
  submitLabel,
}: EnvironmentBaseFormProps) => {
  const { organizationName, projectName } =
    useParams<{ organizationName: string; projectName: string }>();
  const { data: organizationFeatures } =
    useOrganizationsFeaturesGet(organizationName);
  const gpuEnabled = organizationFeatures?.resource_gpu ?? false;

  const mergedInitialValues: EnvironmentBaseProps = useMemo(() => {
    return {
      ...EnvironmentBaseValues,
      ...initialValues,
    } as EnvironmentBaseProps;
  }, [initialValues]);

  return (
    <Formik
      initialValues={mergedInitialValues}
      onSubmit={onSubmit}
      validationSchema={EnvironmentBaseSchema}
    >
      {(control: FormikProps<FormikValues>) => {
        const { handleChange, submitForm, values, setFieldValue } = control;

        return (
          <>
            <FormSection
              description={explanations.environments.nameYourEnvironment}
              title="Name your environment"
            >
              <FormikTextInput
                control={control}
                label="Name"
                name={FIELD_NAME}
                placeholder="Ex: example-environment"
                required
              />
              <FormikTextInput
                control={control}
                label="Description"
                name={FIELD_DESCRIPTION}
                placeholder="Ex: An environment that contains XYZ"
                minRows={3}
                multiline
              />
            </FormSection>
            <FormSection
              title="Base environment"
              description={explanations.environments.baseEnvironment}
            >
              <ControlledEnvironmentSelect
                disabled={baseEnvironmentDisabled}
                gpuEnabled={gpuEnabled}
                onChange={handleChange(
                  FIELD_DEPLOYMENT_VERSION_BASE_ENVIRONMENT
                )}
                projectName={projectName}
                value={values[FIELD_DEPLOYMENT_VERSION_BASE_ENVIRONMENT]}
              />
            </FormSection>
            <CustomDependenciesSection
              control={control}
              dependenciesRequired={dependenciesRequired}
            />
            <FormSection
              title="Version labels"
              description={explanations.labels.description("environments")}
            >
              <ControlledLabelsInput
                onChange={async (value: LabelsDict) => {
                  await setFieldValue(FIELD_LABELS, value);
                  await control.setFieldTouched(FIELD_LABELS, true);
                }}
                value={values[FIELD_LABELS]}
              />
            </FormSection>
            <Grid item container spacing={5}>
              <Grid item xs={12} sm={5} />

              <Grid item xs={12} sm={7}>
                <PrimaryButton
                  loading={control.isSubmitting}
                  onClick={submitForm}
                >
                  {submitLabel ?? "Create"}
                </PrimaryButton>
              </Grid>
            </Grid>
          </>
        );
      }}
    </Formik>
  );
};
