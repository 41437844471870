import styled from "@emotion/styled";
import { Dialog as MuiDialog, DialogContent } from "@mui/material";
import { useCallback, forwardRef } from "react";

import { ReactComponent as Close } from "assets/images/close.svg";
import { spacing } from "assets/styles/theme/spacing";
import appTheme from "assets/styles/theme/theme";

import type { ModalProps, DialogProps as MuiDialogProps } from "@mui/material";
import type { CSSProperties } from "react";

export interface DialogProps
  extends Omit<MuiDialogProps, "ref" | "container" | "scroll"> {
  container?: string;
  open: boolean;
  onClose: Exclude<ModalProps["onClose"], undefined>;
  disableBackdropClick?: boolean;
  disableEscapeKeyDown?: boolean;
  hideCloseButton?: boolean;
  dialogContentStyles?: CSSProperties;
}

export const StyledCloseIcon = styled(Close)<{ color: string }>`
  cursor: pointer;
  position: absolute;
  right: ${spacing[24]};
  top: ${spacing[16]};
  color: ${(props) => props.color};
`;

export const BaseDialog = forwardRef(function DialogForwardedRef(
  {
    container = ".page-container",
    open,
    onClose,
    disableBackdropClick,
    disableEscapeKeyDown,
    hideCloseButton,
    children,
    dialogContentStyles,
    ...props
  }: DialogProps,
  ref
) {
  const onCloseCallback = useCallback(
    (e, r) => {
      if (disableBackdropClick && r === "backdropClick") return;
      if (disableEscapeKeyDown && r === "escapeKeyDown") return;

      onClose(e, r);
    },
    [onClose, disableBackdropClick, disableEscapeKeyDown]
  );

  return (
    <MuiDialog
      ref={ref as any}
      open={open}
      onClose={onCloseCallback}
      container={() => document.querySelector(container)}
      scroll="paper"
      disableScrollLock
      {...props}
      sx={{
        ".MuiDialog-paper": {
          backgroundImage: "none",
        },
      }}
      PaperProps={{
        sx: (theme) => ({
          [theme.breakpoints.up("md")]: {
            marginLeft: "240px", // Adjust the left margin for the sidebar on larger screens
          },
        }),
      }}
    >
      {!hideCloseButton && (
        <StyledCloseIcon
          color={appTheme.palette.neutrals[500]}
          onClick={onCloseCallback as never}
        />
      )}
      <DialogContent
        style={{
          ...dialogContentStyles,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {children}
      </DialogContent>
    </MuiDialog>
  );
});
