import {
  Typography,
  Box,
  Button,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";

import { useUserUpdate } from "libs/data/customized/user";
import { useUserGet } from "libs/data/endpoints/user/user";
import { useGoogleAnalytics } from "libs/hooks";
import { useSignOut } from "libs/hooks/useSignOut";
import { formatLabel } from "libs/utilities/utils";
import validators from "libs/utilities/validators";

import { FormTextField, InfoAlert } from "components/atoms";
import { PasswordConfirmation } from "components/molecules";

const FIELD_OLD_PASSWORD = "old_password";

const PasswordUpdate = () => {
  useGoogleAnalytics();
  const { data: user, mutate } = useUserGet();

  const methods = useForm();
  const theme = useTheme();
  const signOut = useSignOut();

  const { reset, handleSubmit } = methods;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const updateUser = useUserUpdate(
    "Your password has been updated, log in with your new password."
  );

  const handlePasswordSave = async ({ old_password, new_password }) => {
    await updateUser({
      password: new_password,
      previous_password: old_password,
    });
    reset();
    mutate();
    signOut();
  };

  return (
    <Grid container direction="column" px={3}>
      <Typography variant="h3" gutterBottom>
        Change your password
      </Typography>
      {user?.authentication === "ubiops" ? (
        <Grid
          item
          sx={{
            width: isSmallScreen ? "100%" : "33%",
          }}
        >
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handlePasswordSave)}>
              <FormTextField
                id={FIELD_OLD_PASSWORD}
                name={FIELD_OLD_PASSWORD}
                label="Current password"
                type="password"
                rules={{
                  required: validators.required.message("current password"),
                }}
              />
              <PasswordConfirmation />
              <Box mt={1}>
                <Button color="primary" type="submit" variant="contained">
                  Save
                </Button>
              </Box>
            </form>
          </FormProvider>
        </Grid>
      ) : (
        <InfoAlert>
          Your account is managed by {formatLabel(user?.authentication)}. Go to
          your {formatLabel(user?.authentication)} account settings to change
          your settings.
        </InfoAlert>
      )}
    </Grid>
  );
};

export default PasswordUpdate;
