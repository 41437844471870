import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  notificationGroupsCreate,
  useNotificationGroupsList,
} from "libs/data/endpoints/monitoring/monitoring";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import type { NotificationGroupCreate } from "libs/data/models";

export const useNotificationGroupCreate = (projectName: string) => {
  const { mutate } = useNotificationGroupsList(projectName);
  const dispatch = useDispatch();

  return useCallback(
    async (data: NotificationGroupCreate) => {
      try {
        const result = await notificationGroupsCreate(projectName, data);

        await mutate();
        dispatch(
          createSuccessNotification(`Notification group has been created`)
        );

        return result;
      } catch (err: any) {
        dispatch(
          createErrorNotification(err?.message ?? "Something went wrong")
        );

        return;
      }
    },
    [dispatch, mutate, projectName]
  );
};
