import CheckIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import NotificationsOff from "@mui/icons-material/NotificationsOff";
import Send from "@mui/icons-material/Send";
import {
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  Icon,
  Radio,
  RadioGroup,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { TestResult } from "components/atoms/TestResult/TestResult";
import { ControlledHeadersInput } from "components/molecules/ControlledInputs/ControlledHeadersInput/ControlledHeadersInput";
import {
  FIELD_WEBHOOK_CALLBACKURL,
  FIELD_WEBHOOK_DESCRIPTION,
  FIELD_WEBHOOK_EVENT,
  FIELD_WEBHOOK_HEADER,
  FIELD_WEBHOOK_INCLUDE_RESULT,
  FIELD_WEBHOOK_LABELS,
  FIELD_WEBHOOK_NAME,
  FIELD_WEBHOOK_OBJECT_NAME,
  FIELD_WEBHOOK_OBJECT_SELECTION,
  FIELD_WEBHOOK_OBJECT_VERSION,
  FIELD_WEBHOOK_RETRY,
  FIELD_WEBHOOK_TIMEOUT,
} from "libs/constants/fields";
import { useTestWebHook } from "libs/data/customized/webhooks/useTestWebHook";
import {
  useDeploymentVersionsList,
  useDeploymentsList,
} from "libs/data/endpoints/deployments/deployments";
import {
  usePipelineVersionsList,
  usePipelinesList,
} from "libs/data/endpoints/pipelines/pipelines";
import { explanations } from "libs/utilities/explanations";
import validators from "libs/utilities/validators";

import { PrimaryButton, SecondaryButton } from "components/atoms";
import type { LabelsDict } from "components/molecules";
import {
  ControlledLabelsInput,
  FormSection,
  FormikSelect,
  FormikTextInput,
} from "components/molecules";

import {
  WEBHOOK_CREATE_VALUES,
  deploymentEventTriggers,
  eventTriggerLabels,
  pipelineEventTriggers,
  webHookCreateSchema,
  webHooksTimeOutDurations,
} from "./constants";

import type { EventKeys } from "./types";
import type { AppThemeProps } from "assets/styles/theme/theme";
import type { FormikHelpers, FormikProps, FormikValues } from "formik";
import type {
  DeploymentDetail,
  DeploymentVersionDetail,
  PipelineDetail,
  PipelineVersionDetail,
  WebhookCreate,
  WebhookHeader,
} from "libs/data/models";

type WebHookBaseFormProps = {
  handleOnSubmit: (data: WebhookCreate, actions: FormikHelpers<any>) => void;
  defaultValues?: WebhookCreate;
  isEditForm?: boolean;
};

export const WebHookBaseForm = ({
  handleOnSubmit,
  defaultValues,
  isEditForm = false,
}: WebHookBaseFormProps) => {
  const [objectTypeSelected, setObjectTypeSelected] = useState<string>(
    defaultValues?.object_type || "deployment"
  );
  const [selectedObjectName, setSelectedObjectName] = useState<string>(
    defaultValues?.object_name || ""
  );
  const [validUrl, setValidUrl] = useState<boolean | undefined>(undefined);

  const theme = useTheme() as AppThemeProps;

  const { projectName } =
    useParams<{ projectName: string; organizationName: string }>();

  const { data: deployments } = useDeploymentsList(projectName);
  const { data: pipelines } = usePipelinesList(projectName);
  const { testWebHook, isLoading, isSuccess, errorMessage } = useTestWebHook();

  const { data: deploymentVersions } = useDeploymentVersionsList(
    projectName,
    selectedObjectName ?? "",
    undefined,
    { swr: { enabled: objectTypeSelected === "deployment" } }
  );

  const { data: pipelineVersions } = usePipelineVersionsList(
    projectName,
    selectedObjectName ?? "",
    undefined,
    { swr: { enabled: objectTypeSelected === "pipeline" } }
  );

  const deploymentsToSelect = useMemo(() => {
    return objectTypeSelected === "deployment" && deployments
      ? deployments.map((deployment: DeploymentDetail) => {
          return {
            label: deployment.name,
            value: deployment.name,
          };
        })
      : [{ label: "", value: null }];
  }, [deployments, objectTypeSelected]);

  const pipelinesToSelect = useMemo(() => {
    return objectTypeSelected === "pipeline" && pipelines
      ? pipelines.map((pipeline: PipelineDetail) => {
          return {
            label: pipeline.name,
            value: pipeline.name,
          };
        })
      : [{ label: "", value: null }];
  }, [pipelines, objectTypeSelected]);

  const validate = (values: WebhookCreate) => {
    const errors = {} as { [key: string]: string };

    if (
      RegExp(validators.callbackUrl.pattern).test(
        values[FIELD_WEBHOOK_CALLBACKURL]
      )
    )
      setValidUrl(true);

    if (values[FIELD_WEBHOOK_CALLBACKURL] === "") {
      setValidUrl(undefined);
    } else if (!values[FIELD_WEBHOOK_CALLBACKURL]) {
      errors[FIELD_WEBHOOK_CALLBACKURL] = "Required";
      setValidUrl(false);
    } else if (
      !RegExp(validators.callbackUrl.pattern).test(
        values[FIELD_WEBHOOK_CALLBACKURL]
      )
    ) {
      errors[FIELD_WEBHOOK_CALLBACKURL] = validators.callbackUrl.message;
      setValidUrl(false);
    } else if (!values[FIELD_WEBHOOK_OBJECT_NAME]) {
      errors[FIELD_WEBHOOK_OBJECT_NAME] = "Required";
    } else {
      errors === null;
    }

    return errors;
  };

  const sortedVersions = useMemo(() => {
    const objectTypeVersions =
      objectTypeSelected === "deployment"
        ? deploymentVersions
        : pipelineVersions;

    const versionsToSelect = objectTypeVersions
      ? objectTypeVersions.map(
          (version: DeploymentVersionDetail | PipelineVersionDetail) => {
            return {
              label: version.version,
              value: version.version,
            };
          }
        )
      : [];

    return versionsToSelect;
  }, [deploymentVersions, objectTypeSelected, pipelineVersions]);

  const eventTriggers =
    objectTypeSelected === "deployment"
      ? deploymentEventTriggers
      : pipelineEventTriggers;

  const handleOnTest = (data: WebhookCreate) => {
    testWebHook({
      ...data,
      version: data[FIELD_WEBHOOK_OBJECT_VERSION] || null,
    });
  };

  return (
    <Formik
      onSubmit={handleOnSubmit}
      initialValues={defaultValues ?? WEBHOOK_CREATE_VALUES}
      validationSchema={webHookCreateSchema}
      validate={validate}
    >
      {(control: FormikProps<FormikValues>) => {
        const { submitForm, setFieldValue, values } = control;

        // convert event type after object type change
        // eg. from pipeline_request_started to deployment_request_started
        const changeEventTriggerType = (newType: "deployment" | "pipeline") => {
          const [oldTriggers, newTriggers] =
            newType === "deployment"
              ? [pipelineEventTriggers, deploymentEventTriggers]
              : [deploymentEventTriggers, pipelineEventTriggers];
          const currentEventTrigger = values[FIELD_WEBHOOK_EVENT];
          const currentEventKey = Object.keys(oldTriggers).find(
            (key) => oldTriggers[key as EventKeys] === currentEventTrigger
          ) as EventKeys;
          setFieldValue(FIELD_WEBHOOK_EVENT, newTriggers[currentEventKey]);
        };

        return (
          <>
            <FormSection
              description={explanations.webhooks.createForm.general}
              title="General"
            >
              <FormikTextInput
                control={control}
                label="Name"
                placeholder="Ex: webhook-name"
                name={FIELD_WEBHOOK_NAME}
                showErrors
                required
              />

              <FormikTextInput
                control={control}
                label="Description"
                name={FIELD_WEBHOOK_DESCRIPTION}
                minRows={3}
                multiline
              />
            </FormSection>
            <FormSection
              title="Callback URL"
              description={explanations.webhooks.createForm.callbackURL}
            >
              <Box display="flex" alignItems={"center"}>
                <FormikTextInput
                  control={control}
                  label="Callback URL"
                  name={FIELD_WEBHOOK_CALLBACKURL}
                  placeholder="https://example.com"
                  showErrors
                  required
                  sx={{
                    "& fieldset": {
                      border:
                        validUrl === true
                          ? `2px solid ${theme.palette.success.main} !important`
                          : validUrl === false
                          ? `2px solid ${theme.palette.error.main} !important`
                          : // initial border state:
                            null,
                    },
                    "& label": {
                      color:
                        validUrl === false
                          ? theme.palette.error.main
                          : "inherit",
                    },
                  }}
                />
                {validUrl && (
                  <Icon
                    component={CheckIcon}
                    color="success"
                    sx={{ marginLeft: spacing[4] }}
                  />
                )}
              </Box>
            </FormSection>
            <FormSection
              title="Event trigger"
              description={explanations.webhooks.createForm.eventTrigger}
            >
              <FormGroup>
                {Object.entries(eventTriggers).map((trigger) => {
                  const [triggerName, triggerValue] = trigger;
                  const isSwitchSelected =
                    values[FIELD_WEBHOOK_EVENT] === triggerValue;

                  return (
                    <Box
                      display="flex"
                      alignItems="center"
                      gap={2}
                      key={triggerName}
                    >
                      {isSwitchSelected ? (
                        <Icon
                          color="secondary"
                          component={NotificationsActiveIcon}
                        />
                      ) : (
                        <Icon color="disabled" component={NotificationsOff} />
                      )}

                      <FormControlLabel
                        control={
                          <Switch
                            color="secondary"
                            checked={isSwitchSelected}
                            onChange={() =>
                              setFieldValue(FIELD_WEBHOOK_EVENT, triggerValue)
                            }
                          />
                        }
                        label={
                          eventTriggerLabels[
                            triggerName as keyof typeof eventTriggerLabels
                          ]
                        }
                        value={triggerValue}
                      />
                    </Box>
                  );
                })}
              </FormGroup>
            </FormSection>
            <FormSection
              title="Object Selection"
              description={explanations.webhooks.createForm.objectSelection}
            >
              <RadioGroup name={FIELD_WEBHOOK_OBJECT_SELECTION} row>
                <FormControlLabel
                  value="deployment"
                  control={<Radio color="secondary" />}
                  label="Deployment"
                  checked={
                    values[FIELD_WEBHOOK_OBJECT_SELECTION] === "deployment"
                  }
                  onChange={async () => {
                    await setFieldValue(
                      FIELD_WEBHOOK_OBJECT_SELECTION,
                      "deployment"
                    );
                    await setFieldValue(FIELD_WEBHOOK_OBJECT_VERSION, "");
                    await setFieldValue(FIELD_WEBHOOK_OBJECT_NAME, "");
                    setObjectTypeSelected("deployment");
                    changeEventTriggerType("deployment");
                  }}
                  disabled={isEditForm}
                />
                <FormControlLabel
                  value="pipeline"
                  control={<Radio color="secondary" />}
                  label="Pipeline"
                  checked={
                    values[FIELD_WEBHOOK_OBJECT_SELECTION] === "pipeline"
                  }
                  onChange={async () => {
                    await setFieldValue(
                      FIELD_WEBHOOK_OBJECT_SELECTION,
                      "pipeline"
                    );
                    await setFieldValue(FIELD_WEBHOOK_OBJECT_VERSION, "");
                    await setFieldValue(FIELD_WEBHOOK_OBJECT_NAME, "");

                    setObjectTypeSelected("pipeline");
                    changeEventTriggerType("pipeline");
                    setSelectedObjectName("");
                  }}
                  disabled={isEditForm}
                />
              </RadioGroup>

              <FormikSelect
                name={FIELD_WEBHOOK_OBJECT_NAME}
                options={
                  objectTypeSelected === "deployment"
                    ? deploymentsToSelect
                    : pipelinesToSelect
                }
                label={objectTypeSelected}
                onChange={(newValue) =>
                  setSelectedObjectName(newValue?.value as string)
                }
                control={control}
                disabled={isEditForm}
                value={values[FIELD_WEBHOOK_OBJECT_NAME]}
              />
              <FormikSelect
                name={FIELD_WEBHOOK_OBJECT_VERSION}
                options={sortedVersions}
                label={"version"}
                control={control}
                disabled={isEditForm}
                value={values[FIELD_WEBHOOK_OBJECT_VERSION]}
              />
            </FormSection>
            <FormSection
              title="Headers"
              description={explanations.webhooks.createForm.headers}
            >
              <Grid container columnGap={2}>
                <Grid item>
                  <ControlledHeadersInput
                    onChange={async (value: WebhookHeader[]) => {
                      await setFieldValue(FIELD_WEBHOOK_HEADER, value);
                      await control.setFieldTouched(FIELD_WEBHOOK_HEADER, true);
                    }}
                    value={values.headers}
                  />
                </Grid>
              </Grid>
            </FormSection>
            <FormSection
              title="Payload"
              description={explanations.webhooks.createForm.payload}
            >
              <Typography>
                Should the result of the request be included in the webhook
                call? (Only possible for results of up to 16 kb)
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={<Switch color="secondary" />}
                  checked={values[FIELD_WEBHOOK_INCLUDE_RESULT]}
                  onChange={async (_event, checked) => {
                    await setFieldValue(FIELD_WEBHOOK_INCLUDE_RESULT, checked);
                  }}
                  label={values[FIELD_WEBHOOK_INCLUDE_RESULT] ? "Yes" : "No"}
                />
              </FormGroup>
            </FormSection>
            <FormSection
              title="Timeout & Retry"
              description={explanations.webhooks.createForm.timeOut}
            >
              <FormikSelect
                name={FIELD_WEBHOOK_TIMEOUT}
                options={webHooksTimeOutDurations}
                control={control}
                label="Timeout"
              />

              <Typography mt={2}>
                Should the webhook call be retried if it fails?
              </Typography>
              <FormGroup style={{ marginTop: "-14px" }}>
                <FormControlLabel
                  control={<Switch color="secondary" />}
                  label={values[FIELD_WEBHOOK_RETRY] ? "Yes" : "No"}
                  checked={values[FIELD_WEBHOOK_RETRY]}
                  onChange={async (_event, checked) => {
                    await setFieldValue(FIELD_WEBHOOK_RETRY, checked);
                  }}
                />
              </FormGroup>
            </FormSection>
            <FormSection
              title="Labels"
              description={explanations.webhooks.createForm.labels}
            >
              <ControlledLabelsInput
                onChange={async (value: LabelsDict) => {
                  await setFieldValue(FIELD_WEBHOOK_LABELS, value);
                  await control.setFieldTouched(FIELD_WEBHOOK_LABELS, true);
                }}
                value={values[FIELD_WEBHOOK_LABELS] || {}}
              />
            </FormSection>
            <FormSection
              title="Test your webhook"
              description={explanations.webhooks.createForm.testYourWebHook}
            >
              <SecondaryButton
                size="medium"
                style={{ width: "40%" }}
                startIcon={<Send />}
                loading={isLoading}
                onClick={() => handleOnTest(values as WebhookCreate)}
                disabled={
                  values[FIELD_WEBHOOK_OBJECT_NAME] === "" ||
                  values[FIELD_WEBHOOK_NAME] === ""
                }
              >
                Test webhook
              </SecondaryButton>
              <Typography variant="subtitle2">
                Any errors will be shown below
              </Typography>
              {isSuccess && <TestResult>Test successful</TestResult>}
              {errorMessage && (
                <TestResult type="error">{errorMessage}</TestResult>
              )}
            </FormSection>
            <Box justifyContent={"right"} width={"100%"} display={"flex"}>
              <PrimaryButton
                loading={control.isSubmitting}
                onClick={submitForm}
              >
                Save
              </PrimaryButton>
            </Box>
          </>
        );
      }}
    </Formik>
  );
};
