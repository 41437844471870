import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { PageHeader } from "components/molecules/PageLayout";
import {
  FIELD_DEFAULT_MONITORING_GROUP_ENABLED,
  FIELD_MONITORING_GROUP_ENABLED,
  FIELD_VERSION,
} from "libs/constants/fields";
import { usePipelineVersionsGet } from "libs/data/endpoints/pipelines/pipelines";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";
import { routes } from "routes";

import { FormWrapper } from "components/atoms";
import { PageContainer } from "components/molecules";

import { useUpdatePipelineVersion } from "./useUpdatePipelineVersion";
import { PipelineVersionBaseForm } from "../PipelineVersionBaseForm";

import type { PipelineVersionRouteParams } from "./types";
import type { PipelineVersionBaseProps } from "../PipelineVersionBaseForm/constants";
import type { AxiosError } from "axios";
import type { FormikHelpers } from "formik";

export const PipelineVersionUpdate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { organizationName, projectName, pipelineName, versionName } =
    useParams<PipelineVersionRouteParams>();
  const { data: pipelineVersion } = usePipelineVersionsGet(
    projectName,
    pipelineName,
    versionName
  );

  const updatePipelineVersion = useUpdatePipelineVersion();

  const handleOnSubmit = async (
    data: any,
    actions: FormikHelpers<PipelineVersionBaseProps>
  ) => {
    try {
      await updatePipelineVersion(data);
    } catch (e: unknown) {
      const error = e as AxiosError;
      createErrorNotification(error.message);

      return;
    }
    actions.setSubmitting(false);
    dispatch(createSuccessNotification("The pipeline version was updated"));
    history.push(
      routes.organizations[":organizationName"](organizationName)
        .projects[":projectName"](projectName)
        .pipelines[":pipelineName"](pipelineName)
        .versions[":versionName"](data[FIELD_VERSION])
        .index()
    );
  };

  return (
    <PageContainer>
      <PageHeader title={`Edit "${versionName}"`} />
      <FormWrapper>
        {pipelineVersion && (
          <PipelineVersionBaseForm
            onSubmit={handleOnSubmit}
            initialValues={{
              ...pipelineVersion,
              [FIELD_MONITORING_GROUP_ENABLED]: Boolean(
                pipelineVersion?.monitoring
              ),
              [FIELD_DEFAULT_MONITORING_GROUP_ENABLED]: Boolean(
                pipelineVersion?.default_notification_group
              ),
            }}
            submitText="Save"
          />
        )}
      </FormWrapper>
    </PageContainer>
  );
};
