import styled from "@emotion/styled";
import { Avatar as MuiAvatar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { readableColor } from "polished";

import { useStringToColor } from "assets/styles/theme/utils/useStringToColor";

import type { AvatarProps } from "@mui/material";

// @ts-ignore
const Avatar = styled<typeof MuiAvatar, { color: string }>(MuiAvatar, {
  shouldForwardProp: (prop) => prop !== "color",
})`
  color: ${({ color }: any) => readableColor(color)};
  background-color: ${({ color }: any) => color};
`;

type CharAvatarProps = {
  text: string;
  variant?: "circle" | "circular" | "rounded" | "square" | undefined;
  size?: "small" | "medium" | "large";
} & Omit<AvatarProps, "variant" | "color">;

export const CharAvatar = ({
  text,
  size = "medium",
  variant = "rounded",
  ...props
}: CharAvatarProps) => {
  const classes = useStyles();

  return (
    <Avatar
      // @ts-ignore
      color={useStringToColor(text)}
      variant={variant}
      className={`${classes[size]}`}
      {...props}
    >
      {text?.toUpperCase().charAt(0)}
    </Avatar>
  );
};

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    fontSize: "12px !important",
  },
  medium: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: "14px !important",
  },
  large: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: "18px !important",
  },
}));
