import CloudUploadIcon from "@mui/icons-material/CloudUploadRounded";
import { Typography, Stepper, Step, StepLabel } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useMemo } from "react";

import { DOC_LINKS } from "libs/constants/documentation-links";

import {
  ExternalLink,
  DialogWarningHeader,
  Dialog,
  PrimaryButton,
} from "components/atoms";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiStepIcon-completed": { color: theme.palette.success.main },
    "& .Mui-error .MuiStepIcon-completed": {
      color: theme.palette.error.main,
    },
  },
  warningIcon: {
    margin: "0 5px -5px 0",
  },
}));

type PackageValidationErrorDialogProps = {
  name?: string;
  fieldName?: string;
  failedSteps?: number[];
  fileStructureError?: string;
  dialogMethods: any;
};

export const PackageValidationErrorDialog = ({
  fieldName,
  failedSteps,
  fileStructureError,
  dialogMethods,
}: PackageValidationErrorDialogProps) => {
  const classes = useStyles();
  const { toggleDialog, open } = dialogMethods;

  const steps = useMemo(
    () => [
      {
        label: "Checking file format",
        errorReason: "Only zip format is allowed",
      },
      {
        label: "Checking file size",
        errorReason: "File size limit is 8 GB",
      },
      {
        label: "Checking directory structure",
        errorReason: fileStructureError,
      },
    ],
    [fileStructureError]
  );

  return (
    <Dialog
      open={open}
      onClose={toggleDialog}
      Header={<DialogWarningHeader title="Failed to validate zip file" />}
      Actions={
        <>
          {!!fieldName && (
            <label htmlFor={fieldName}>
              <PrimaryButton
                startIcon={<CloudUploadIcon />}
                onClick={toggleDialog}
              >
                Re-upload zip
              </PrimaryButton>
            </label>
          )}
        </>
      }
    >
      <Typography variant="body1">
        File failed to validate, please check the list below and make sure your
        file follows the{" "}
        <ExternalLink href={DOC_LINKS.DEPLOYMENT_PACKAGE_STRUCTURE}>
          correct package structure
        </ExternalLink>{" "}
        and upload your file again
      </Typography>
      <Stepper orientation="vertical" activeStep={-1}>
        {steps.map(({ label, errorReason }, index) => {
          const failedStep = failedSteps?.find((step) => step === index);

          if (failedStep !== undefined) {
            const labelProps = {
              error: true,
              optional: (
                <Typography variant="body2" color="error">
                  {errorReason}
                </Typography>
              ),
            };

            return (
              <Step key={index} completed className={classes.root}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          }

          return (
            <Step key={index} completed className={classes.root}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Dialog>
  );
};
