import GetResultsIcon from "@mui/icons-material/AssignmentRounded";
import CloseIcon from "@mui/icons-material/Close";
import RequestIcon from "@mui/icons-material/PublishRounded";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import { CONTINUOUS_REQUEST_DELAY } from "libs/constants/constants";
import {
  DEPLOYMENT_PERMISSIONS,
  PIPELINE_PERMISSIONS,
} from "libs/constants/permissions";
import { deploymentVersionRequestsGet } from "libs/data/endpoints/deployment-requests/deployment-requests";
import { pipelineVersionRequestsGet } from "libs/data/endpoints/pipeline-requests/pipeline-requests";
import { useInterval, useRetryRequest } from "libs/hooks";
import { createErrorNotification } from "libs/utilities/notifications";
import { ERROR, LOADED, LOADING } from "libs/utilities/request-statuses";
import { useGetCurrentProject } from "store/features";
import { useGetPermissions } from "store/features/permissions";
import { updateTask } from "store/features/taskManager";

import { StatusIcon, RetryIconSpinner } from "components/atoms";
import {
  RequestResultsDialog,
  shouldFetchMetadataOnly,
} from "components/organisms";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type { AxiosError } from "axios";

type RequestTaskItemProps = {
  organizationName: string;
  id: string;
  message: string;
  status: string;
  deleteTask: (id: string) => void;
  result?: any;
  requestParameters: any;
};

export const RequestTaskItem = ({
  organizationName,
  id,
  message,
  status,
  deleteTask,
  result,
  requestParameters,
}: RequestTaskItemProps) => {
  const dispatch = useDispatch();
  const theme = useTheme() as AppThemeProps;
  const { type, resourceName, resourceVersion } = requestParameters;
  const requestId = result?.responseData?.id;

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [projectName] = useState(useGetCurrentProject()?.name);
  const [error, setError] = useState(false);
  const [metadataOnly, setMetadataOnly] = useState(true);

  const mutateVersionRequest =
    type === "deployment"
      ? deploymentVersionRequestsGet
      : pipelineVersionRequestsGet;

  useInterval(
    async () => {
      if (status !== LOADED && status !== ERROR && requestId) {
        try {
          const response = await mutateVersionRequest(
            projectName ?? "",
            resourceName,
            resourceVersion,
            requestId,
            { metadata_only: true }
          );

          setMetadataOnly(shouldFetchMetadataOnly(response));
          if (response.status === "completed")
            dispatch(
              updateTask({
                id: id,
                status: LOADED,
                result: { ...result, responseData: response },
              })
            );
          else if (response.status === "failed")
            dispatch(
              updateTask({
                id: id,
                status: ERROR,
                result: { ...result, responseData: response },
              })
            );
          if (error) {
            setError(false);
          }
        } catch (error) {
          if (!error)
            dispatch(
              createErrorNotification(
                (error as AxiosError).message ?? "Something went wrong."
              )
            );

          setError(true);
        }
      }
    },
    [
      dispatch,
      id,
      mutateVersionRequest,
      projectName,
      requestId,
      resourceName,
      resourceVersion,
      result,
      status,
      error,
    ],
    CONTINUOUS_REQUEST_DELAY,
    true
  );

  const [currentPermissions] = useGetPermissions();
  const permissions =
    currentPermissions[DEPLOYMENT_PERMISSIONS["version_request_create"]] ||
    currentPermissions[PIPELINE_PERMISSIONS["version_request_create"]];

  const { onRetry, retrying } = useRetryRequest({
    projectName: projectName ?? "",
    requestParameters,
  });

  const handleDelete = useCallback(
    (e) => {
      e.stopPropagation();
      deleteTask(id);
    },
    [deleteTask, id]
  );

  const handleRetry = useCallback(
    (e) => {
      e.stopPropagation();
      onRetry({
        id: requestId,
        deployment: type === "deployment" ? resourceName : undefined,
        pipeline: type === "pipeline" ? resourceName : undefined,
        version: resourceVersion,
      });
    },
    [onRetry, resourceName, resourceVersion, requestId, type]
  );

  return (
    <>
      <RequestResultsDialog
        container=".main-layout"
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
        organizationName={organizationName}
        projectName={projectName ?? ""}
        resourceType={type}
        resourceName={resourceName}
        resourceVersion={resourceVersion}
        requestId={requestId}
        directResult={result?.responseData}
        fetchOnlyMetadata={metadataOnly}
      />

      <MenuItem divider dense onClick={() => setIsDialogOpen(true)}>
        <ListItemIcon>
          <StatusIcon
            status={
              status === LOADING
                ? "pending"
                : status === ERROR || result?.responseData?.success === false
                ? "failed"
                : "completed"
            }
          />
        </ListItemIcon>

        <ListItemText
          disableTypography
          primary={
            <Box display="flex" alignItems="center" mr={1}>
              <Box component={RequestIcon} fontSize="small" mr={1} />
              <Box
                component={Typography}
                variant="subtitle2"
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
              >
                {message}
              </Box>
            </Box>
          }
          secondary={
            <Box display="flex" alignItems="center" mr={2}>
              <Box
                component={Typography}
                variant="caption"
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
              >
                {`${type}: ${resourceName} - version: ${resourceVersion}`}
              </Box>
            </Box>
          }
        />

        <Tooltip title={status === LOADING ? "" : "View results"}>
          <span>
            <IconButton
              onClick={() => setIsDialogOpen(true)}
              color="secondary"
              disabled={status === LOADING}
            >
              <GetResultsIcon />
            </IconButton>
          </span>
        </Tooltip>

        {permissions && (
          <RetryIconSpinner
            disabled={status === LOADING}
            spinCondition={retrying}
            onClick={handleRetry}
          />
        )}

        <Tooltip title="Remove">
          <span>
            <IconButton edge="end" onClick={handleDelete}>
              <CloseIcon sx={{ color: theme.palette.text.secondary }} />
            </IconButton>
          </span>
        </Tooltip>
      </MenuItem>
    </>
  );
};
