import makeStyles from "@mui/styles/makeStyles";
import { SnackbarProvider } from "notistack";
import { AlertTriangle, Info, CheckCircle, AlertOctagon } from "react-feather";

import type { FC } from "react";

const useStyles = makeStyles({
  snackbar: {
    fontWeight: "bold",
  },
});

export const SnackbarCustomProvider: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={7000}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      iconVariant={{
        error: <AlertTriangle className="app__notification-icon" />,
        success: <CheckCircle className="app__notification-icon" />,
        warning: <AlertOctagon className="app__notification-icon" />,
        info: <Info className="app__notification-icon" />,
      }}
      classes={{
        variantSuccess: classes.snackbar,
        variantError: classes.snackbar,
        variantWarning: classes.snackbar,
        variantInfo: classes.snackbar,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};
