import styled from "@emotion/styled";
import PipelineIcon from "@mui/icons-material/AccountTreeRounded";
import Plus from "@mui/icons-material/AddBoxRounded";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UserIcon from "@mui/icons-material/People";
import DeploymentIcon from "@mui/icons-material/WidgetsRounded";
import { TreeItem, TreeView } from "@mui/lab";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { WarningIcon } from "components/atoms/Icon/WarningIcon";
import {
  deploymentVersionsList,
  useDeploymentsList,
} from "libs/data/endpoints/deployments/deployments";
import {
  pipelineVersionsList,
  usePipelinesList,
} from "libs/data/endpoints/pipelines/pipelines";
import { useProjectUsersList } from "libs/data/endpoints/projects/projects";
import { getRandomId } from "libs/utilities/utils";

import {
  Dialog,
  Icon,
  Loader,
  PrimaryButton,
  TextField,
} from "components/atoms";

import type { SvgIconTypeMap } from "@mui/material";
import type { OverridableComponent } from "@mui/material/OverridableComponent";
import type { AppThemeProps } from "assets/styles/theme/theme";
import type {
  DeploymentList,
  DeploymentVersionList,
  PipelineList,
  PipelineVersionList,
  ProjectUserList,
} from "libs/data/models";
import type { SyntheticEvent } from "react";

export type MonitoringObject = {
  id: string;
  type: string;
  label: string;
};
type AddObjectDialogProps = {
  open: boolean;
  onClose: () => void;
  onSelect: ({ id, type, label }: MonitoringObject) => void;
};

interface TreeItemRecord {
  parentId: string | null;
  label: string;
  id: string;
  objectId: string;
  type?: string;
  icon?: OverridableComponent<SvgIconTypeMap<any, "svg">>;
}

interface TreeData {
  records: TreeItemRecord[];
  selected: string;
  expanded: string[];
}

const initialTreeState = {
  records: [
    {
      label: "Deployments",
      parentId: null,
      id: "deployments",
      objectId: "deployments",
      icon: DeploymentIcon,
    },
    {
      label: "Pipelines",
      parentId: null,
      id: "pipelines",
      objectId: "pipelines",
      icon: PipelineIcon,
    },
    {
      label: "Users & Service users",
      parentId: null,
      id: "users",
      objectId: "users",
      icon: UserIcon,
    },
  ],
  selected: "",
  expanded: [],
};

export const AddObjectDialog = ({
  open,
  onClose,
  onSelect,
}: AddObjectDialogProps) => {
  const { projectName } = useParams<{ projectName: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedObject, setSelectedObject] =
    useState<{ id: string; type: string; label: string } | null>(null);
  const [tree, setTree] = useState<TreeData>(initialTreeState);

  const { data: pipelines } = usePipelinesList(projectName);
  const { data: deployments } = useDeploymentsList(projectName);
  const { data: users } = useProjectUsersList(projectName);

  const theme = useTheme() as AppThemeProps;

  useEffect(() => {
    if (deployments && pipelines && users) {
      setTree((tree) => {
        return {
          ...tree,
          records: tree.records
            .concat(
              getTreeItemFromObject(deployments, "deployment", "deployments")
            )
            .concat(getTreeItemFromObject(pipelines, "pipeline", "pipelines"))
            .concat(getTreeItemFromObject(users, "user", "users")),
        };
      });
    }

    return () => setTree(initialTreeState);
  }, [pipelines, deployments, users]);

  const handleSelect = async (
    _event: SyntheticEvent<Element, Event>,
    nodeId: string
  ) => {
    const currentNode = tree.records.find((record) => record.id === nodeId);
    const nodeType: string | undefined = currentNode?.type;
    const nodeIsAlreadyExpanded = tree.expanded.includes(nodeId);

    if (nodeType === "warning") return;
    // reset selection if object changes and it's not a version or user
    if (nodeType !== "user" && nodeType !== "version" && selectedObject)
      setSelectedObject(null);
    if (nodeIsAlreadyExpanded) {
      collapseNode(nodeId);
    } else {
      // it's the root element so only expand the menu
      if (currentNode?.parentId === null) {
        setTree((tree) => {
          return {
            ...tree,
            selected: nodeId,
            expanded: [...new Set([...tree.expanded, nodeId])],
          };
        });
      }
      // for users and versions the object is already selected
      else if (nodeType === "user" && currentNode) {
        setTree((tree) => {
          return {
            ...tree,
            selected: nodeId,
          };
        });
        setSelectedObject({
          id: currentNode?.objectId,
          type: nodeType,
          label: currentNode?.label as string,
        });
      } else if (nodeType === "version" && currentNode) {
        const parent = tree.records.find(
          (record) => record.id === currentNode?.parentId
        );
        setTree((tree) => {
          return {
            ...tree,
            selected: nodeId,
          };
        });
        setSelectedObject({
          id: currentNode?.objectId,
          type: parent?.type as string,
          label: parent?.label + " - " + currentNode?.label,
        });
      }
      // otherwise call for the versions and add to records
      else {
        const hasChildren = tree.records.find(
          (recod) => recod.parentId === nodeId
        );
        if (hasChildren) {
          setTree((tree) => ({
            ...tree,
            selected: nodeId,
            expanded: [...new Set([...tree.expanded, nodeId])],
          }));
        } else {
          setIsLoading(true);
          currentNode &&
            getObjectData(currentNode)
              .then((list) => {
                if (list.length) {
                  setTree((tree) => ({
                    selected: nodeId,
                    expanded: [...new Set([...tree.expanded, nodeId])],
                    records: tree.records.concat(
                      getTreeItemFromObject(list, "version", nodeId)
                    ),
                  }));
                } else
                  setTree((tree) => ({
                    selected: nodeId,
                    expanded: [...new Set([...tree.expanded, nodeId])],
                    records: tree.records.concat({
                      id: getRandomId(),
                      objectId: "",
                      label: `This ${currentNode.type} has no versions`,
                      type: "warning",
                      parentId: nodeId,
                    }),
                  }));
              })
              .finally(() => setIsLoading(false));
        }
      }
    }
  };

  const getObjectData = (currentNode: TreeItemRecord) => {
    const getVersions =
      currentNode.type === "deployment"
        ? deploymentVersionsList
        : pipelineVersionsList;

    return getVersions(projectName, currentNode.label);
  };

  const collapseNode = (nodeId: string) => {
    setTree((tree) => {
      return {
        ...tree,
        expanded: tree.expanded.filter((id) => id !== nodeId),
        selected: nodeId,
      };
    });
  };

  const getTreeItemFromObject = (
    objects: (
      | DeploymentList
      | PipelineList
      | ProjectUserList
      | DeploymentVersionList
      | PipelineVersionList
    )[],
    type: "deployment" | "pipeline" | "user" | "version",
    parentId: string
  ) => {
    return objects.map((item) => ({
      parentId,
      label:
        type === "user"
          ? ((item as ProjectUserList).email.includes("@serviceuser") &&
              (item as ProjectUserList).name) ||
            (item as ProjectUserList).email
          : type === "version"
          ? (item as DeploymentVersionList | PipelineVersionList).version
          : (item as DeploymentList | PipelineList | ProjectUserList).name ??
            "",
      id: getRandomId(),
      objectId: item.id,
      type,
    }));
  };

  const onObjectSelect = () => {
    selectedObject && onSelect(selectedObject);
    setTree((tree) => ({ ...tree, expanded: [], selected: "" }));
    setSelectedObject(null);
  };

  return (
    <Dialog
      title="Add data of an object"
      open={open}
      onClose={onClose}
      Actions={
        <PrimaryButton
          startIcon={<Plus />}
          disabled={!selectedObject}
          onClick={onObjectSelect}
        >
          Add object
        </PrimaryButton>
      }
    >
      <Box display="flex" flexDirection="column">
        <Typography variant="body2" textAlign="center">
          Select an object below of which the data should be added to your
          dashboard. <br /> You can also type in a custom value.
        </Typography>
        <Divider sx={{ marginY: spacing[12] }} />
        <Box
          style={{
            width: "50%",
            marginBottom: spacing[12],
          }}
        >
          <TextField
            name="search"
            label="Add object"
            size="small"
            value={searchTerm}
            onChange={({ target: { value } }) => setSearchTerm(value)}
          />
        </Box>
        {isLoading && (
          <Box
            position="absolute"
            top="50%"
            right="50%"
            style={{ background: "transparent", zIndex: "10" }}
          >
            <Loader />
          </Box>
        )}
        <StyledTreeView
          backgroundColor={theme.palette.secondary.light}
          expanded={tree.expanded}
          selected={tree.selected}
          onNodeSelect={handleSelect}
        >
          {renderTreeStructure(
            theme.palette.secondary.light,
            theme.palette.warning.main,
            tree.records,
            null,
            searchTerm
          )}
        </StyledTreeView>
      </Box>
    </Dialog>
  );
};

const renderTreeStructure = (
  backgroundColor: string,
  errorColor: string,
  records: TreeItemRecord[],
  parent: string | null,
  searchTerm: string
) => {
  return records
    .filter(({ parentId }) => parentId === parent)
    .filter(
      ({ label, type }) =>
        type === "warning" ||
        !type ||
        type === "version" ||
        label.includes(searchTerm)
    )
    .map((item) => {
      const isNotExpandable =
        item.type === "warning" ||
        item.type === "user" ||
        item.type === "version";
      const iconStyle = {
        display: isNotExpandable ? "none" : "",
      };

      return (
        <StyledTreeItem
          backgroundColor={backgroundColor}
          key={item.id}
          nodeId={item.id}
          label={renderLabel(item, errorColor)}
          collapseIcon={<ExpandMoreIcon style={iconStyle} />}
          expandIcon={<ChevronRightIcon style={iconStyle} />}
        >
          {renderTreeStructure(
            backgroundColor,
            errorColor,
            records,
            item.id,
            searchTerm
          )}
          {!isNotExpandable && <TreeItem nodeId={getRandomId()} />}
        </StyledTreeItem>
      );
    });
};

const renderLabel = (record: TreeItemRecord, errorColor: string) => (
  <Box display="flex" alignItems="center">
    <Box display="flex" mr={spacing[6]} className="icon">
      {record.type === "warning" && (
        <WarningIcon
          color={errorColor}
          style={{
            height: spacing[20],
            width: spacing[20],
            cursor: "initial",
          }}
        />
      )}
      {record.icon && (
        <Icon
          style={{ height: spacing[20], width: spacing[20] }}
          component={record.icon}
        />
      )}
    </Box>
    {record.label}
  </Box>
);

const StyledTreeView = styled(TreeView, {
  shouldForwardProp: (prop) => prop !== "backgroundColor",
})<{ backgroundColor: string }>`
  "& .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label":
    {
      background-color: ${(props) => props.backgroundColor},
    },
  "& .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label":
    {
      background-color: ${(props) => props.backgroundColor},
    },
  ".MuiTreeItem-root:focus > .MuiTreeItem-content .MuiTreeItem-label": {
    background-color: "unset",
  },
`;

const StyledTreeItem = styled(TreeItem, {
  shouldForwardProp: (prop) => prop !== "backgroundColor",
})<{ backgroundColor: string }>`
  padding: ${spacing[4]};
  "& .MuiTreeItem-content ": {
    "& :hover": {
      background-color: ${(props) => props.backgroundColor},
    },
  },
  "& .MuiTreeItem-content.icon": {
    "& :hover": {
      background: "initial",
      cursor: "initial",
    },
  },
  "& .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label":
    {
      background-color: ${(props) => props.backgroundColor},
    },
`;
