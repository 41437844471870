import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  organizationUsersCreate,
  useOrganizationUsersList,
} from "libs/data/endpoints/organizations/organizations";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import type { AxiosError } from "axios";
import type { OrganizationUserCreate } from "libs/data/models";

export const useOrganizationUserCreate = (name: string) => {
  const dispatch = useDispatch();
  const { mutate } = useOrganizationUsersList(name);

  return useCallback(
    async (data: OrganizationUserCreate) => {
      try {
        const response = await organizationUsersCreate(name, data);
        dispatch(createSuccessNotification("User was created."));
        mutate();

        return response;
      } catch (err: unknown) {
        const error = err as AxiosError;
        dispatch(createErrorNotification(error.message));

        return null;
      }
    },
    [dispatch, mutate, name]
  );
};
