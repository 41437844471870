import { Box, Divider } from "@mui/material";
import { Formik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { useRoleAssignmentsCreate } from "libs/data/customized/roles";
import { useDeploymentsList } from "libs/data/endpoints/deployments/deployments";
import { useEnvironmentsList } from "libs/data/endpoints/environments/environments";
import { useBucketsList } from "libs/data/endpoints/files/files";
import { usePipelinesList } from "libs/data/endpoints/pipelines/pipelines";
import { useRolesList } from "libs/data/endpoints/roles/roles";
import { explanations } from "libs/utilities/explanations";
import {
  resourceTypes,
  projectType,
  roleGroups,
} from "libs/utilities/labels-mapping";
import { useGetCurrentProject } from "store/features";

import { Alert, Dialog, Loader, PrimaryButton } from "components/atoms";
import { FormikSelect } from "components/molecules";

import type { AutocompleteSelectOption } from "components/atoms/AutoCompleteSelect";
import type { FormikProps, FormikValues, FormikHelpers } from "formik";
import type {
  BucketList,
  DeploymentDetail,
  EnvironmentList,
  PipelineList,
  ProjectUserList,
  RoleList,
} from "libs/data/models";

interface RoleAssignmentDialogProps {
  currentUser?: ProjectUserList;
  isServiceUser?: boolean;
  toggleDialog: () => void;
  isOpen: boolean;
}

const formatOptionsToSelect = (options: RoleList[]) => {
  const formattedOptions = options.map((option: { name: string }) => {
    return {
      ...option,
      label: option.name,
      value: option.name,
    };
  });

  return formattedOptions;
};

const getObjectGroup = (role: RoleList) => {
  const group =
    roleGroups.filter((roleGroup) => role.name.includes(roleGroup))[0] ||
    "custom";

  return group;
};
const FIELD_RESOURCE_TYPE = "resource_type";
const FIELD_RESOURCE_NAME = "resource_name";
const FIELD_ROLE = "role";

const defaultFormValues = {
  [FIELD_RESOURCE_TYPE]: resourceTypes[0].value,
  [FIELD_RESOURCE_NAME]: null,
  [FIELD_ROLE]: null,
};

export const RoleAssignmentDialog = ({
  currentUser,
  isServiceUser,
  toggleDialog,
  isOpen,
}: RoleAssignmentDialogProps) => {
  const { projectName, organizationName } =
    useParams<{ organizationName: string; projectName: string }>();

  const [objects, setObjects] = useState<
    DeploymentDetail[] | PipelineList[] | BucketList[] | EnvironmentList[]
  >([]);
  const [filteredGroupedRoles, setFilteredGroupedRoles] = useState<
    AutocompleteSelectOption[] | []
  >([]);

  const { data: projectRoles } = useRolesList(projectName);
  const project = useGetCurrentProject();

  const roleAssignmentsCreate = useRoleAssignmentsCreate(
    projectName,
    currentUser?.id
  );

  const { data: deployments, error: deploymentsError } =
    useDeploymentsList(projectName);

  const { data: pipelines, error: pipelinesError } =
    usePipelinesList(projectName);

  const { data: buckets, error: bucketsError } = useBucketsList(projectName);

  const { data: environments, error: environmentsError } =
    useEnvironmentsList(projectName);

  const isLoadingDeployments = !deployments && !deploymentsError;
  const isLoadingPipelines = !pipelines && !pipelinesError;
  const isLoadingBuckets = !buckets && !bucketsError;
  const isLoadingEnvrionments = !environments && !environmentsError;
  const updateRolesList = useCallback(
    (newType: string) => {
      // filtering out serviceUsers
      const projectRolesWithoutAdminRoles =
        (projectRoles &&
          projectRoles?.filter((role) =>
            isServiceUser
              ? role.name !== "project-admin"
              : role.name !== "service-user-project-admin"
          )) ||
        [];

      // Format state object:
      const formattedGroups = projectRolesWithoutAdminRoles.map((role) => {
        return {
          group: getObjectGroup(role),
          label: role.name || "",
          value: role.name || "",
        };
      });

      // filtering according to selected object type:
      let rolesFilteredBySelectedObjectType = formattedGroups;

      if (newType === "bucket") {
        rolesFilteredBySelectedObjectType = formattedGroups.filter(
          (role) => role.group === "custom" || role.group === "files"
        );
      } else if (newType !== "project") {
        rolesFilteredBySelectedObjectType = formattedGroups.filter(
          (role) =>
            role.group.includes(newType as string) || role.group === "custom"
        );
      }

      // sort roles by group name, then add roles from "custom" group
      const customRoles = rolesFilteredBySelectedObjectType.filter(
        (role) => role.group === "custom"
      );
      const sortedRules = rolesFilteredBySelectedObjectType
        .filter((role) => role.group !== "custom")
        .sort((a, b) => a.group.localeCompare(b.group));
      setFilteredGroupedRoles([...sortedRules, ...customRoles]);
    },
    [isServiceUser, projectRoles]
  );

  useEffect(() => {
    updateRolesList("project");
  }, [updateRolesList]);

  const onObjectTypeChange = (newType: string) => {
    let objectList:
      | DeploymentDetail[]
      | PipelineList[]
      | BucketList[]
      | EnvironmentList[] = [];
    switch (newType) {
      case "deployment":
        if (deployments?.length) objectList = deployments;
        break;
      case "pipeline":
        if (pipelines?.length) objectList = pipelines;
        break;
      case "bucket":
        if (buckets?.length) objectList = buckets;
        break;
      case "environment":
        if (environments?.length) objectList = environments;
        break;
      default:
        break;
    }
    updateRolesList(newType);
    setObjects(objectList);
  };

  const onAdd = (values: any, actions: FormikHelpers<any>) => {
    const { role, resource_type, resource_name } = values;
    let newRole: any = {
      assignee: currentUser?.id,
      assignee_type: "user",
      role: role,
    };

    if (resource_type !== "project") {
      newRole = {
        ...newRole,
        resource_type: resource_type,
        resource: resource_name,
      };
    }

    roleAssignmentsCreate(newRole);
    toggleDialog();
    actions.resetForm();
  };

  return (
    <Dialog
      title="Assign a role"
      open={isOpen}
      onClose={() => {
        toggleDialog();
      }}
    >
      <Formik onSubmit={onAdd} initialValues={defaultFormValues}>
        {(control: FormikProps<FormikValues>) => {
          const { setFieldValue, values, submitForm } = control;

          const changeType = (newType: AutocompleteSelectOption | null) => {
            newType && onObjectTypeChange(newType.value as string);
            setFieldValue(FIELD_RESOURCE_NAME, null);
            setFieldValue(FIELD_ROLE, null);
          };

          const validForm =
            (values[FIELD_RESOURCE_TYPE] === "project" ||
              (values[FIELD_RESOURCE_TYPE] !== "project" &&
                !!values[FIELD_RESOURCE_NAME])) &&
            !!values[FIELD_ROLE];

          return (
            <>
              {!project?.advanced_permissions && (
                <Alert severity="info">
                  {explanations.projects.simplePermissionsRoles(
                    `/organizations/${organizationName}/overview`
                  )}
                </Alert>
              )}

              {!!projectRoles?.length && resourceTypes && (
                <>
                  <Box my={2}>
                    <FormikSelect
                      label="Role level"
                      name={FIELD_RESOURCE_TYPE}
                      options={
                        project?.advanced_permissions
                          ? resourceTypes
                          : projectType
                      }
                      onChange={changeType}
                      control={control}
                    />
                  </Box>
                  {values[FIELD_RESOURCE_TYPE] !== "project" &&
                    !objects.length && (
                      <Alert severity="info">
                        {explanations.permissions.roleAssignment(
                          values[FIELD_RESOURCE_TYPE]
                        )}
                      </Alert>
                    )}
                  {values[FIELD_RESOURCE_TYPE] !== "project" &&
                    project?.advanced_permissions && (
                      <Box my={2}>
                        <FormikSelect
                          label="Applied to"
                          name={FIELD_RESOURCE_NAME}
                          options={formatOptionsToSelect(objects)}
                          control={control}
                        />
                      </Box>
                    )}
                  <Box my={2}>
                    <FormikSelect
                      label="Role"
                      name={FIELD_ROLE}
                      options={filteredGroupedRoles}
                      getOptionLabel={(option: { label: string }) =>
                        option.label
                      }
                      groupBy={(option: AutocompleteSelectOption) =>
                        option.group || ""
                      }
                      disabled={
                        values[FIELD_RESOURCE_TYPE] !== "project" &&
                        !objects.length
                      }
                      control={control}
                    />
                  </Box>
                  {(isLoadingDeployments ||
                    isLoadingPipelines ||
                    isLoadingBuckets ||
                    isLoadingEnvrionments) && <Loader />}
                </>
              )}
              <Divider style={{ marginBottom: spacing[16] }} />
              <PrimaryButton
                type="submit"
                disabled={!validForm}
                onClick={submitForm}
              >
                Assign
              </PrimaryButton>
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};
