import { createErrorNotification } from "libs/utilities/notifications";

import { signOut } from "./utilities";

import type { AxiosError } from "axios";
import type { AppStore } from "store/store";

export const errorHandler = (store: AppStore) => (error: AxiosError) => {
  const unauthorized = error?.response?.status === 401;
  // @ts-ignore
  const isTermsError = error?.response?.data?.error_type === "terms_conditions";
  if (unauthorized && !isTermsError) {
    signOut(store);
    store.dispatch(
      createErrorNotification(
        "Authentication failed. Your login session expired",
        {
          key: "login-session",
        }
      )
    );
    // @ts-ignore
  } else if (error.response?.status === 403 && error.config.method === "get") {
    store.dispatch(
      createErrorNotification(
        "Authorization failed. You don't have permission to view this resource",
        {
          key: "login-session",
        }
      )
    );
    // @ts-ignore
  } else if (error.response?.status === 429 && error.config.method === "get") {
    store.dispatch(
      createErrorNotification("You have reached your rate limit", {
        key: "rate-limited",
      })
    );
  }
};
