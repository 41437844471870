import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { useMemo } from "react";
import { useNodes } from "reactflow";

import { useAccordionStyles } from "assets/styles/componentStyles";
import { spacing } from "assets/styles/theme";
import { AutoCompleteSelectHookForm } from "components/atoms/UncontrolledAutoComplete/AutoCompleteSelectHookForm";
import {
  OBJECT_REFERENCE_TYPE_OPERATOR,
  OPERATOR_RAISE_ERROR,
} from "components/templates/Diagram/constants";
import { getObjectsWithoutDiamondsFromNodes } from "components/templates/Diagram/utils";

import { FormTextField } from "components/atoms";

import { OPERATOR_ONE_TO_MANY } from "../constants";
import { NodeTypes } from "../types";

import type { OperatorFormProps } from "./types";
import type { NodeDataType } from "../types";

const batchSizeFieldRules = {
  min: {
    value: 1,
    message: "The batch size must be greater than 0",
  },
};

export const OperatorOneToManyForm = ({ value }: OperatorFormProps) => {
  const nodes = useNodes<NodeDataType>();
  const theme = useTheme();
  const classes = useAccordionStyles();

  const pipelineDiagramObjects = getObjectsWithoutDiamondsFromNodes(nodes);

  const sourceObjectOptions = pipelineDiagramObjects.filter(
    (object) =>
      !(
        object.reference_type === OBJECT_REFERENCE_TYPE_OPERATOR &&
        object.reference_name === OPERATOR_RAISE_ERROR
      ) &&
      !(
        object.reference_type === OBJECT_REFERENCE_TYPE_OPERATOR &&
        object.reference_name === OPERATOR_ONE_TO_MANY
      ) &&
      object.name !== NodeTypes.pipeline_end
  );

  const options = useMemo(
    () =>
      sourceObjectOptions?.map(({ name, ...data }) => ({
        label: name,
        value: name,
        name,
        ...data,
      })),
    [sourceObjectOptions]
  );

  return (
    <>
      <AutoCompleteSelectHookForm
        id="sourceObject"
        name="sourceObject"
        label="Source object"
        placeholder="Select..."
        options={options}
        isSearchable
      />
      <Grid marginTop={spacing[16]} marginBottom={spacing[16]}>
        <Accordion disableGutters className={classes.accordionWrapper}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            classes={{ content: classes.accSummaryContent }}
            sx={{ "& svg": { fill: theme.palette.text.primary } }}
          >
            <Typography
              variant="h6"
              sx={{
                color: theme.palette.text.primary,
                marginRight: spacing[8],
              }}
            >
              Optional / Advanced settings
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              textAlign="center"
              marginBottom={spacing[16]}
            >
              UbiOps sets the optimal batch size automatically. If you wish to
              override it to ensure each batch contains a specific number of
              subrequests, you can do so below.
            </Typography>
            <FormTextField
              name="batch_size"
              id="batch_size"
              label="Batch size"
              rules={batchSizeFieldRules}
              type="number"
              inputProps={{ min: 1 }}
              defaultValue={value?.batch_size ?? null}
              placeholder="Automatic"
            />
          </AccordionDetails>
        </Accordion>
      </Grid>
    </>
  );
};
