import { Box, useTheme } from "@mui/material";
import { isEmpty } from "lodash";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Switch, useHistory, useRouteMatch } from "react-router-dom";

import { EngineeringTeamIllustration } from "assets/images/EngeringTeam";
import { IlluIncorrectPermissions } from "assets/images/IlluIncorrectPermissions";
import { spacing } from "assets/styles/theme";
import { BaseUrlContext, RootUrlContext } from "libs/contexts";
import { useOrganizationUser } from "libs/data/customized/user";
import {
  useOrganizationSubscriptionsList,
  useOrganizationsFeaturesGet,
  useOrganizationsGet,
  useOrganizationsList,
  useOrganizationsResourceUsage,
  useOrganizationsUsageGet,
} from "libs/data/endpoints/organizations/organizations";
import { useProjectsList } from "libs/data/endpoints/projects/projects";
import { OrganizationDetailStatus } from "libs/data/models";
import { ENV_NAMES, env } from "libs/env";
import { useInterval } from "libs/hooks";
import { explanations } from "libs/utilities/explanations";
import { routes } from "routes";
import {
  setOrganization,
  setOrganizationFeatures,
  setOrganizationResources,
  setOrganizationSubscription,
  setOrganizationUsage,
  setOrganizations,
  setProjects,
  useGetCurrentOrganization,
} from "store/features";

import {
  Dialog,
  DialogHeader,
  DialogHeaderTitle,
  FullScreenLoader,
} from "components/atoms";
import {
  MainLayout,
  NewAccountDialog,
  OrganizationSelectDialog,
} from "components/organisms";
import { Route } from "components/utilities";

import OrganizationRoutesContainer from "./:organizationName";
import { organizationsRoutes } from "./constants";

import type { OrganizationList } from "libs/data/models";

const basePath = routes.organizations[":organizationName"](":organizationName");
const baseUrl = routes.organizations;

const OrganizationsRoutesContainer = () => {
  const currentOrganization = useGetCurrentOrganization();
  const {
    data: organizations,
    error: organizationsError,
    isValidating: organizationsLoading,
    mutate: mutateList,
  } = useOrganizationsList({
    swr: { revalidateOnMount: true, revalidateOnFocus: true },
  });

  const previousOrganization = organizations?.find(({ name, id }) =>
    [name, id].includes(env.get(ENV_NAMES.LAST_SEEN_ORGANIZATION))
  );

  const savedOrganization =
    currentOrganization?.name || previousOrganization?.name;

  const [selectDialogOpen, setSelectDialogOpen] = useState(false);
  const [isNewAccountDialogOpen, setIsNewAccountDialogOpen] = useState(false);
  const [organizationName, setOrganizationName] = useState(
    savedOrganization ?? ""
  );

  const match = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();

  const { data: projects, isValidating: projectsLoading } = useProjectsList(
    {
      organization: savedOrganization,
    },
    {
      swr: {
        enabled: Boolean(savedOrganization),
        revalidateOnMount: true,
        revalidateOnFocus: true,
      },
    }
  );

  useEffect(() => {
    mutateList();
  }, [mutateList]);

  const {
    data: selectedOrganization,
    mutate,
    error: selectedOrganizationError,
  } = useOrganizationsGet(organizationName);

  const { isAdmin } = useOrganizationUser(currentOrganization?.name);

  const { data: organizationSubscription } = useOrganizationSubscriptionsList(
    currentOrganization?.name || organizationName,
    { swr: { enabled: isAdmin } }
  );

  const { data: organizationFeatures } = useOrganizationsFeaturesGet(
    currentOrganization?.name || organizationName
  );

  const { data: organizationUsage } = useOrganizationsUsageGet(
    currentOrganization?.name || organizationName,
    { interval: "day" },
    {
      swr: {
        dedupingInterval: 60 * 60,
      },
    }
  );

  const { data: organizationResources } = useOrganizationsResourceUsage(
    currentOrganization?.name || organizationName
  );

  useEffect(() => {
    if (currentOrganization) setOrganizationName(currentOrganization?.name);
  }, [currentOrganization]);

  useEffect(() => {
    if (organizationResources)
      dispatch(setOrganizationResources(organizationResources));
  }, [organizationResources, dispatch]);

  useEffect(() => {
    if (organizationUsage) dispatch(setOrganizationUsage(organizationUsage));
  }, [organizationUsage, dispatch]);

  useEffect(() => {
    if (organizationFeatures)
      dispatch(setOrganizationFeatures(organizationFeatures));
  }, [organizationFeatures, dispatch]);

  useEffect(() => {
    if (organizationSubscription)
      dispatch(setOrganizationSubscription(organizationSubscription));
  }, [organizationSubscription, dispatch]);

  useEffect(() => {
    projects && dispatch(setProjects(projects));
    organizations && dispatch(setOrganizations(organizations));
  }, [projects, dispatch, organizations]);

  useEffect(() => {
    if (selectedOrganization) dispatch(setOrganization(selectedOrganization));
  }, [selectedOrganization, dispatch]);

  useEffect(() => {
    if (!organizationsLoading && !organizationsError && isEmpty(organizations))
      setIsNewAccountDialogOpen(true);
  }, [organizations, organizationsLoading, organizationsError]);

  useEffect(() => {
    if (
      !selectedOrganization &&
      !selectedOrganizationError &&
      !isNewAccountDialogOpen &&
      !organizationsLoading &&
      !projectsLoading &&
      !isEmpty(organizations)
    ) {
      // Check localStorage for previously selected project; if it exists, navigate to it
      // Also check if the previously saved project hasn't been deleted
      const savedOrganization = organizations?.find(({ id, name }) =>
        [id, name].includes(env.get(ENV_NAMES.LAST_SEEN_ORGANIZATION))
      );
      const savedProject = projects?.find(
        ({ id }) => id === env.get(ENV_NAMES.LAST_SEEN_PROJECT)
      );

      if (savedOrganization) {
        savedProject
          ? history.replace(
              routes.organizations[":organizationName"](savedOrganization.name)
                .projects[":projectName"](savedProject.name)
                .index()
            )
          : history.replace(
              routes.organizations[":organizationName"](
                savedOrganization.name
              ).index()
            );
      } else {
        setSelectDialogOpen(true);
      }
    }
  }, [
    isNewAccountDialogOpen,
    history,
    organizationsLoading,
    organizations,
    projects,
    projectsLoading,
    selectedOrganization,
    selectedOrganizationError,
  ]);

  const selectOrganization = (organization: OrganizationList) => {
    setSelectDialogOpen(false);
    setOrganizationName(organization.name);
  };

  const isOrgBeingVerified = selectedOrganization?.status === "pending";

  useInterval(
    () => {
      if (isOrgBeingVerified) mutate();
    },
    [isOrgBeingVerified, mutate],
    6000
  );

  const displayLoader =
    (Boolean(savedOrganization) && projectsLoading) || organizationsLoading;

  if (organizationsError) {
    return null;
  } else {
    return (
      <BaseUrlContext.Provider value={match.url}>
        <FullScreenLoader displayed={displayLoader} />
        <RootUrlContext.Provider
          value={
            currentOrganization
              ? baseUrl[":organizationName"](currentOrganization.name).index()
              : baseUrl.index()
          }
        >
          <MainLayout routes={organizationsRoutes}>
            {(!match.isExact || currentOrganization) && (
              <Switch>
                <Route
                  path={basePath.index()}
                  component={OrganizationRoutesContainer}
                />

                {currentOrganization && (
                  <Redirect
                    to={baseUrl[":organizationName"](
                      currentOrganization.name
                    ).index()}
                  />
                )}
              </Switch>
            )}
            <OrganizationSelectDialog
              onClose={() => setSelectDialogOpen(false)}
              onOrganizationSelection={selectOrganization}
              open={selectDialogOpen}
            />

            <NewAccountDialog
              open={isNewAccountDialogOpen}
              closeDialog={setIsNewAccountDialogOpen}
              setOrganizationName={setOrganizationName}
            />

            <Dialog
              title="Your account is being verified"
              onClose={() => {}}
              dismissible={false}
              container=".main-layout"
              open={isOrgBeingVerified}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                gap={2}
              >
                <EngineeringTeamIllustration />
                {explanations.organizationVerification}
              </Box>
            </Dialog>

            <Dialog
              open={
                currentOrganization?.status ===
                OrganizationDetailStatus.suspended_manual
              }
              dismissible={false}
              onClose={() => {}}
              container=".main-layout"
              Header={
                <DialogHeader
                  header={
                    <DialogHeaderTitle
                      style={{
                        color: theme.palette.error.main,
                        textAlign: "center",
                      }}
                    >
                      Your account is blocked
                    </DialogHeaderTitle>
                  }
                />
              }
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                gap={2}
                padding={spacing[8]}
              >
                <Box
                  width={"100%"}
                  display="flex"
                  justifyContent={"center"}
                  alignItems="center"
                >
                  <IlluIncorrectPermissions />
                </Box>
                {explanations.suspendedOrganization}
              </Box>
            </Dialog>
          </MainLayout>
        </RootUrlContext.Provider>
      </BaseUrlContext.Provider>
    );
  }
};

export default OrganizationsRoutesContainer;
