import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { rolesDelete, useRolesList } from "libs/data/endpoints/roles/roles";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

export const useProjectRoleDelete = (projectName: string) => {
  const dispatch = useDispatch();
  const { mutate } = useRolesList(projectName);

  return useCallback(
    async (roleName) => {
      if (!projectName) {
        return Promise.reject();
      }

      return rolesDelete(projectName, roleName)
        .then(async (result) => {
          dispatch(createSuccessNotification("The project role was deleted"));
          await mutate();

          return result;
        })
        .catch((e) => {
          dispatch(createErrorNotification(e.message));
        });
    },
    [projectName, dispatch, mutate]
  );
};
