import {
  Checkbox,
  TableSortLabel,
  Box,
  TableCell,
  TableRow,
  TableHead,
} from "@mui/material";

import { spacing } from "assets/styles/theme";

import { InfoTooltip } from "components/atoms";

import type { BaseColumn } from "./types";

interface BaseTableHeadProps {
  columns: BaseColumn[];
  onSelectAll?: (isAdd: boolean) => void;
  isAllChecked?: boolean;
  isAnyChecked?: boolean;
  MultiAction?: React.ElementType;
  sortDirection?: "desc" | "asc";
  setSorting?: (columnName: string) => void;
  sortColumn?: string;
}

export function BaseTableHead(props: BaseTableHeadProps) {
  const {
    columns,
    onSelectAll,
    isAllChecked,
    isAnyChecked,
    MultiAction,
    sortDirection,
    setSorting,
    sortColumn,
  } = props;

  return (
    <TableHead>
      <TableRow>
        {columns.map((headCell) => {
          if (headCell.type === "checkbox") {
            return (
              <TableCell
                key={headCell.field}
                width={headCell?.width}
                align="left"
                padding={"normal"}
              >
                <Box
                  sx={{
                    height: 0,
                    transform: `translate(${spacing[16]}, -${spacing[32]})`,
                    position: "absolute",
                  }}
                >
                  {MultiAction && <MultiAction />}
                </Box>
                <Checkbox
                  sx={{
                    marginLeft: spacing[6],
                  }}
                  checked={isAllChecked}
                  indeterminate={isAnyChecked && !isAllChecked}
                  onChange={(_event, checked) => onSelectAll?.(checked)}
                />
              </TableCell>
            );
          }

          return (
            <TableCell
              key={headCell.field}
              width={headCell?.width}
              align="left"
              padding={"normal"}
              variant="head"
              sx={{ flexDirection: "row" }}
            >
              <Box display="flex" flexDirection="row" alignItems={"center"}>
                {headCell.title && (
                  <TableSortLabel
                    disabled={!headCell.title}
                    direction={sortDirection}
                    active={headCell.field === sortColumn}
                    onClick={() => setSorting?.(headCell.field)}
                  >
                    {headCell.title}
                  </TableSortLabel>
                )}
                {headCell.tooltip && (
                  <Box
                    marginLeft={"-24px"}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <InfoTooltip>{headCell.tooltip}</InfoTooltip>
                  </Box>
                )}
              </Box>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
