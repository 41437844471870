import { createContext, useState } from "react";

import type { EdgeDataType } from "./types";
import type { Dispatch, FC, SetStateAction } from "react";
import type { Edge } from "reactflow";

export const PendingEdgesContext = createContext<
  [
    Edge<EdgeDataType>[] | null,
    Dispatch<SetStateAction<Edge<EdgeDataType>[] | null>>
  ]
  // eslint-disable-next-line @typescript-eslint/no-empty-function
>([null, () => {}] as unknown as [
  Edge<EdgeDataType>[] | null,
  Dispatch<SetStateAction<Edge<EdgeDataType>[] | null>>
]);

export const PendingEdgesContextProvider: FC = ({ children }) => {
  const [pendingEdges, setEdgesSources] =
    useState<Edge<EdgeDataType>[] | null>(null);

  return (
    <PendingEdgesContext.Provider value={[pendingEdges, setEdgesSources]}>
      {children}
    </PendingEdgesContext.Provider>
  );
};
