import useSWR from "swr";

import { deploymentVersionRequestsBatchGet } from "libs/data/endpoints/deployment-requests/deployment-requests";

import type { orvalAxios } from "libs/data/axios";
import type { DeploymentRequestsBatchGetDataBody } from "libs/data/models";
import type { SWRConfiguration, Key } from "swr";

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

export const useDeploymentVersionRequestsBatchGet = <TError = unknown>(
  projectName: string,
  deploymentName: string,
  version: string,
  deploymentRequestsBatchGetDataBody?: DeploymentRequestsBatchGetDataBody,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof deploymentVersionRequestsBatchGet>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};
  const isEnabled =
    swrOptions?.enabled !== false &&
    !!(
      projectName &&
      deploymentName &&
      version &&
      deploymentRequestsBatchGetDataBody
    );
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? [
            `/projects/${projectName}/deployments/${deploymentName}/versions/${version}/requests/collect`,
          ]
        : null);
  const swrFn = () =>
    deploymentVersionRequestsBatchGet(
      projectName,
      deploymentName,
      version,
      deploymentRequestsBatchGetDataBody ?? [],
      requestOptions
    );

  const query = useSWR<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
