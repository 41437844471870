import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { Typography, Box, useTheme } from "@mui/material";

import { spacing, shadows } from "assets/styles/theme";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type { CSSProperties, ReactNode } from "react";

export type SidebarLeftExpandableProps = {
  isExpanded: boolean;
  toggleExpand: () => void;
  placement: "left" | "right";
  closeWhenCollapsed?: boolean;
  style?: CSSProperties;
  className?: string;
  children?: ReactNode;
};

export const SidebarLeftExpandable = ({
  isExpanded,
  toggleExpand,
  placement,
  closeWhenCollapsed = false,
  style,
  className,
  children,
}: SidebarLeftExpandableProps) => {
  const theme = useTheme() as AppThemeProps;

  return (closeWhenCollapsed && !isExpanded) || !children ? null : (
    <Box
      component="aside"
      bgcolor={theme.palette.background.default}
      display="flex"
      flexDirection="column"
      height="100%"
      boxShadow={shadows.primaryLight}
      maxWidth={320}
      style={style}
      className={className}
    >
      {children}

      <Box
        bgcolor={theme.palette.pipelineDiagram.sidebarButton}
        color={theme.palette.primary.contrastText}
        padding={spacing[4]}
        display="flex"
        marginTop={!isExpanded ? "auto" : undefined}
        className="cursor-pointer"
        onClick={() => toggleExpand()}
      >
        {isExpanded ? (
          <Box
            paddingX={spacing[32]}
            marginX="auto"
            display="inline-flex"
            alignItems="center"
          >
            <Typography variant="h3">Hide panel</Typography>
            <DoubleArrowIcon
              style={{
                transform: placement === "left" ? "rotate(180deg)" : "none",
                marginLeft: spacing[16],
                width: spacing[40],
                height: spacing[40],
              }}
            />
          </Box>
        ) : (
          <DoubleArrowIcon
            style={{
              transform: placement === "left" ? "none" : "rotate(180deg)",
              marginLeft: spacing[20],
              marginRight: spacing[20],
              width: spacing[40],
              height: spacing[40],
            }}
          />
        )}
      </Box>
    </Box>
  );
};
