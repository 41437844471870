import { useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";

import { getId } from "components/templates/Diagram/getId";

import { TextButton } from "components/atoms";

import { WarningErrorModal } from "./WarningErrorModal";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type { VariantType } from "notistack";

export interface WarningErrorStatusProps {
  errors: string[];
  warnings: string[];
  className?: string;
}

interface VisibleErrorsState {
  errorOne?: string;
  errorTwo?: string;
  list: { variant: VariantType; text: string }[];
  showMore?: string;
}

const baseClassName = "pipeline-alert";
const getClassName = (variant?: VariantType) =>
  `${baseClassName} ${baseClassName}-${variant ?? ""}`;

export const WarningErrorStatus = ({
  errors,
  warnings,
}: WarningErrorStatusProps) => {
  const theme = useTheme() as AppThemeProps;

  const [isWarningErrorModalOpen, setIsWarningErrorModalOpen] = useState(false);
  const hideWarningErrorModal = () => setIsWarningErrorModalOpen(false);
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const [visibleErrorsState, setVisibleErrorsState] =
    useState<VisibleErrorsState>({ list: [] });

  const cleanList = useMemo(() => {
    return [
      ...errors.map((error) => ({
        variant: "error" as VariantType,
        text: error,
      })),
      ...warnings.map((warning) => ({
        variant: "warning" as VariantType,
        text: warning,
      })),
    ];
  }, [warnings, errors]);

  const createAlert = useCallback(
    (index: number, key: string, message: string, variant: VariantType) => {
      enqueueSnackbar(
        <span
          id={`${baseClassName}-${index}`}
          className={getClassName(variant)}
        >
          {message}
        </span>,
        {
          key,
          persist: true,
          variant,
        }
      );
    },
    [enqueueSnackbar]
  );

  const handleShowingShowMore = useCallback(
    (key: string, showMoreCount: number) => {
      enqueueSnackbar(
        <TextButton
          onClick={() => setIsWarningErrorModalOpen(true)}
          style={{
            fontWeight: "bold",
            color: theme.palette.primary.contrastText,
            textTransform: "lowercase",
            height: "20px",
            alignSelf: "center",
            padding: 0,
          }}
        >
          <span id={`${baseClassName}-2`} className={getClassName("error")}>
            Click to see {showMoreCount} more
          </span>
        </TextButton>,
        {
          key,
          variant: "error",
          persist: true,
        }
      );
    },
    [enqueueSnackbar, theme]
  );

  const updateAlertContent = useCallback((index: number, message: string) => {
    const element = document.getElementById(`${baseClassName}-${index}`);

    if (element) {
      element.innerHTML = message;
    }
  }, []);

  /**
   * The snackbar component which we are using has several software design problems. Within this use effect we
   * side-step these problems using some less-than-ideal code. The result is a max of three alerts that are persisted
   * and only updated/removed to represent the latest errors.
   */
  useEffect(() => {
    if (JSON.stringify(cleanList) !== JSON.stringify(visibleErrorsState.list)) {
      setVisibleErrorsState((previousState) => {
        const errorOne = cleanList[0]?.text;
        const variantOne = cleanList[0]?.variant;
        const errorTwo = cleanList[1]?.text;
        const variantTwo = cleanList[1]?.variant;

        const alertOne = document.getElementById(`${baseClassName}-0`);
        const alertTwo = document.getElementById(`${baseClassName}-1`);
        const alertsCount = document.querySelectorAll(
          `.${baseClassName}`
        ).length;
        const updateAlertOne =
          alertOne &&
          errorOne &&
          alertOne.className.substr(getClassName().length) !== variantOne;
        const updateAlertTwo =
          alertTwo &&
          errorTwo &&
          alertTwo.className.substr(getClassName().length) !== variantTwo;
        // Sometimes the component does not correctly close alerts, in this case we reset the component
        const incorrectAlertCount =
          Math.min(previousState.list.length, 3) !== alertsCount;

        // We can only change the text of a notification, not the variant type
        // If alert one or alert two has changed its variant type (warning to error or vice versa), remove all alerts
        if (updateAlertOne || updateAlertTwo || incorrectAlertCount) {
          closeSnackbar();
          previousState = { list: [] };
        }

        // Create the alert if it doesn't yet exist
        if (errorOne && !previousState.errorOne) {
          // note that without random id's there may be conflicts
          // you cannot create a notification with the same id shortly after removing it
          previousState.errorOne = getId();
          createAlert(0, previousState.errorOne, errorOne, variantOne);

          // Close the alert if it no longer exists
        } else if (previousState.errorOne && !errorOne) {
          closeSnackbar(previousState.errorOne);

          // Update the alert if the message has changed
        } else {
          updateAlertContent(0, errorOne);
        }

        if (errorTwo && !previousState.errorTwo) {
          previousState.errorTwo = getId();
          createAlert(1, previousState.errorTwo, errorTwo, variantTwo);
        } else if (previousState.errorTwo && !errorTwo) {
          closeSnackbar(previousState.errorTwo);
        } else {
          updateAlertContent(1, errorTwo);
        }

        if (cleanList.length > 2 && !previousState.showMore) {
          previousState.showMore = getId();
          handleShowingShowMore(previousState.showMore, cleanList.length - 2);
        } else if (cleanList.length > 2) {
          updateAlertContent(2, `Click to see ${cleanList.length - 2} more`);
        } else if (previousState.showMore && cleanList.length <= 2) {
          closeSnackbar(previousState.showMore);
        }

        previousState.list = cleanList;

        return previousState;
      });
    }
  }, [
    cleanList,
    closeSnackbar,
    createAlert,
    handleShowingShowMore,
    updateAlertContent,
    visibleErrorsState.list,
  ]);

  return (
    <WarningErrorModal
      errors={errors}
      warnings={warnings}
      isOpen={isWarningErrorModalOpen}
      onClose={hideWarningErrorModal}
    />
  );
};
