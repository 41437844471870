import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Dialog, SecondaryButton } from "components/atoms";

import { RequestResults } from "./RequestResults";

import type { RequestResultsProps } from "./RequestResults";
import type { Dispatch, SetStateAction } from "react";

type RequestResultsDialogProps = RequestResultsProps & {
  container?: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  fetchOnlyMetadata?: boolean;
};

export const RequestResultsDialog = ({
  container,
  isOpen,
  setIsOpen,
  ...props
}: RequestResultsDialogProps) => {
  const {
    location: { pathname },
  } = useHistory();

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  useEffect(() => {
    onClose();
  }, [pathname, onClose]);

  return (
    <Dialog
      container={container}
      Actions={<SecondaryButton onClick={onClose}>Close</SecondaryButton>}
      maxWidth="lg"
      onClose={onClose}
      open={isOpen}
      title="Results"
    >
      <RequestResults {...props} />
    </Dialog>
  );
};
