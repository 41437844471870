import Plus from "@mui/icons-material/AddBoxRounded";
import { Box, Typography, Grid } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";

import { CurvedArrow } from "assets/images/CurvedArrow";
import { IlluGetResults } from "assets/images/IlluGetResults";
import { IlluGirl } from "assets/images/IlluGirl";
import { IlluPipeline } from "assets/images/IlluPipeline";
import { spacing } from "assets/styles/theme";
import { DOC_LINKS } from "libs/constants/documentation-links";

import { ExternalLink, PrimaryButton, SecondaryButton } from "components/atoms";

const contentList = [
  {
    Icon: IlluGirl,
    title: "Create a pipeline",
    subtitle: "Give it a name and specify the input of the pipeline",
  },
  {
    Icon: IlluPipeline,
    title: "Add & Connect deployments",
    subtitle: "Create workflows by connecting deployments to one another",
  },
  {
    Icon: IlluGetResults,
    title: "Make requests",
    subtitle: "Make requests to your pipelines",
  },
];

const PipelineEmptyOverview = ({ baseUrl, permission }) => {
  const history = useHistory();

  return (
    <Grid
      container
      spacing={4}
      display="flex"
      direction="column"
      alignItems="center"
    >
      <Grid item xs={12}>
        <Typography align="center" variant="h2">
          Create your first pipeline!
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography align="center" variant="body1">
          With pipelines you can build workflows around your deployments.
          Pipelines can receive requests just like deployments. UbiOps will
          manage the data flow. Use pipelines to create A/B tests for instance!
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        container
        display="flex"
        justifyContent="space-between"
        wrap="nowrap"
        sx={{
          width: "100%",
        }}
      >
        {contentList.map(({ Icon, title, subtitle, link, textLink }, key) => (
          <Grid
            item
            container
            spacing={2}
            key={key}
            wrap="nowrap"
            width={key === 1 ? "100%" : spacing[440]}
          >
            {key === 1 && (
              <Box
                display="flex"
                height="100%"
                alignItems="center"
                justifyContent="center"
              >
                <CurvedArrow />
              </Box>
            )}
            <Grid
              key={key}
              item
              xs={10}
              container
              spacing={2}
              display="flex"
              direction="column"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Grid>
                <Icon />
              </Grid>
              <Grid item>
                <Typography variant="h3">{title}</Typography>
              </Grid>
              <Grid item height={spacing[20]}>
                <Typography align="center" variant="body1">
                  {subtitle}{" "}
                  {link && (
                    <ExternalLink href={link} mr={1}>
                      {textLink}
                    </ExternalLink>
                  )}
                </Typography>
              </Grid>
            </Grid>
            {key === 1 && (
              <Box
                display="flex"
                height="100%"
                alignItems="center"
                justifyContent="center"
              >
                <CurvedArrow />
              </Box>
            )}
          </Grid>
        ))}
      </Grid>
      <Grid
        item
        xs={6}
        container
        spacing={2}
        style={{
          marginTop: spacing[20],
        }}
        display="flex"
        direction="column"
        alignItems="center"
      >
        <Grid item xs={12}>
          {!!permission && (
            <PrimaryButton
              startIcon={<Plus />}
              onClick={() => history.push(`${baseUrl}/create`)}
            >
              Create your first pipeline
            </PrimaryButton>
          )}
        </Grid>
        <Grid item xs={12}>
          <SecondaryButton
            href={DOC_LINKS.STARTER_TUTORIAL_PIPELINE}
            target="_blank"
          >
            Starter tutorial
          </SecondaryButton>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1} display="flex" alignItems="center">
            <Grid item>
              <Typography display="inline">Need some inspiration?</Typography>
            </Grid>
            <Grid item>
              <ExternalLink href={DOC_LINKS.TUTORIALS} mr={1}>
                Check our tutorials
              </ExternalLink>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

PipelineEmptyOverview.propTypes = {
  baseUrl: PropTypes.string,
  permission: PropTypes.bool,
};

export default PipelineEmptyOverview;
