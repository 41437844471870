import { useTheme } from "@mui/styles";

export const EmptyDatabase = () => {
  const theme = useTheme();

  return (
    <svg
      width="114px"
      height="114px"
      viewBox="0 0 114 114"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M94.811 62.0756V33.3401C94.811 22.9124 77.84 14.4663 56.887 14.4663C35.9339 14.4663 18.9629 22.9124 18.9629 33.3401V80.5247C18.9629 90.9525 35.9813 99.3986 56.887 99.3986C59.0676 99.3986 61.1534 99.3986 63.1918 99.1155C62.1963 96.2372 61.6275 93.1702 61.6275 89.9617V89.7257C60.1105 89.9617 58.5461 89.9617 56.887 89.9617C38.5412 89.9617 28.4439 82.884 28.4439 80.5247V70.0026C36.0761 73.683 46.0786 75.8063 56.887 75.8063C59.9683 75.8063 62.9074 75.6175 65.7991 75.2872C70.7766 67.1243 79.7836 61.6509 90.0705 61.6509C91.6823 61.6509 93.2467 61.8396 94.811 62.0756ZM85.33 59.0557C79.1673 63.5383 68.359 66.3694 56.887 66.3694C45.4149 66.3694 34.6066 63.5383 28.4439 59.0557V45.7969C35.4125 49.7132 45.5571 52.214 56.887 52.214C68.2168 52.214 78.3615 49.7132 85.33 45.7969V59.0557ZM56.887 42.7771C38.5412 42.7771 28.4439 35.6994 28.4439 33.3401C28.4439 30.9809 38.5412 23.9032 56.887 23.9032C75.2327 23.9032 85.33 30.9809 85.33 33.3401C85.33 35.6994 75.2327 42.7771 56.887 42.7771ZM96.7546 89.9617L106.852 99.9648L100.12 106.665L90.0705 96.6147L80.0206 106.665L73.3365 99.9648L83.3864 89.9617L73.3365 79.9585L80.0206 73.3055L90.0705 83.3086L100.12 73.3055L106.852 79.9585L96.7546 89.9617Z"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
};
