import { useMemo, useCallback, useState } from "react";
import { ReactFlowProvider, useReactFlow } from "reactflow";
import "./Diagram.scss";

import { operatorIsInvalid } from "components/templates/Diagram/utils";
import {
  usePipelineValidate,
  usePipelineVersionUpdate,
} from "libs/data/customized/pipeline";
import {
  usePipelinesGet,
  usePipelineVersionsGet,
} from "libs/data/endpoints/pipelines/pipelines";

import { FullScreenLoader } from "components/atoms";
import { ComposeProviders } from "components/utilities";

import { DiagramComponent } from "./DiagramComponent";
import { WarningErrorsParent } from "./DiagramWarningErrors/WarningErrorsParent";
import { PendingEdgesContextProvider } from "./PendingEdgesContext";
import { SidebarLeft } from "./SidebarLeft";
import {
  SidebarRightParent,
  SidebarRightStateContextProvider,
} from "./SidebarRight";
import { PageHeader } from "./Topbar";
import { ZoomContextProvider } from "./ZoomContext";
import { getCurrentAttachments } from "./getCurrentAttachments";
import { getCurrentObjects } from "./getCurrentObjects";
import { getInitialEdges } from "./getInitialEdges";
import { getInitialNodes } from "./getInitialNodes";

import type { EdgeDataType, NodeDataType } from "./types";
import type { CSSProperties } from "react";
import type { Node, Edge } from "reactflow";

export type DiagramProps = {
  organizationName: string;
  projectName: string;
  pipelineName: string;
  versionName: string;
  isReadonly?: boolean;
  onExit?: () => void;
  as?: keyof JSX.IntrinsicElements;
  style?: CSSProperties;
  className?: string;
};

const DiagramContextProvided = ({
  isReadonly = false,
  organizationName,
  projectName,
  pipelineName,
  versionName,
  onExit,
  as: As = "div",
  style,
  className,
}: DiagramProps) => {
  const { getNodes, getEdges, setNodes } =
    useReactFlow<NodeDataType, EdgeDataType>();

  const { data: pipeline } = usePipelinesGet(projectName, pipelineName, {
    swr: {
      revalidateOnFocus: true,
      revalidateOnMount: true,
    },
  });
  const { data: pipelineVersionDetail } = usePipelineVersionsGet(
    projectName,
    pipelineName,
    versionName,
    {
      swr: {
        revalidateOnFocus: true,
        revalidateOnMount: true,
      },
    }
  );

  const pipelineVersionsUpdate = usePipelineVersionUpdate(
    projectName,
    pipelineName,
    versionName
  );

  const [validatePipeline, pipelineStatus] = usePipelineValidate(
    projectName,
    pipelineName,
    versionName
  );
  const [isLoading, setIsLoading] = useState(false);

  const validateDiagram = useCallback(
    (canBeCancelled = true) => {
      const nodes = getNodes();

      const validNodes = nodes.filter(
        (node) => node.data.pipelineObject.name !== undefined
      );

      return validatePipeline(
        {
          objects: getCurrentObjects(validNodes),
          attachments: getCurrentAttachments(validNodes, getEdges()),
        },
        canBeCancelled
      );
    },
    [getEdges, getNodes, validatePipeline]
  );

  const setIsLoadingPromise = (bool: boolean) =>
    new Promise((resolve) => resolve(setIsLoading(bool)));

  const onSave = async () => {
    // remove invalid operators in case menu is still open
    setNodes((nodes) =>
      nodes.filter((node) => !operatorIsInvalid(node, getEdges()))
    );

    return setIsLoadingPromise(true).then(() => {
      return validateDiagram(false).then((result) => {
        if (!result?.errors.length) {
          pipelineVersionsUpdate({
            attachments: getCurrentAttachments(getNodes(), getEdges()),
            objects: getCurrentObjects(getNodes()),
          }).then(() => {
            setIsLoading(false);
            onExit?.();
          });
        }
      });
    });
  };

  const defaultNodes = useMemo<Node<NodeDataType>[]>(
    () =>
      getInitialNodes(
        isReadonly,
        organizationName,
        projectName,
        pipeline,
        pipelineVersionDetail
      ),
    [isReadonly, organizationName, projectName, pipeline, pipelineVersionDetail]
  );

  const { defaultDiamondNodes, defaultEdges } = useMemo<{
    defaultDiamondNodes: Node<NodeDataType>[];
    defaultEdges: Edge[];
  }>(
    () =>
      getInitialEdges(
        organizationName,
        projectName,
        pipelineVersionDetail,
        defaultNodes,
        isReadonly
      ),
    [
      defaultNodes,
      isReadonly,
      organizationName,
      pipelineVersionDetail,
      projectName,
    ]
  );

  const diagramDom = (
    <DiagramComponent
      defaultNodes={[...defaultNodes, ...defaultDiamondNodes]}
      defaultEdges={defaultEdges}
      organizationName={organizationName}
      projectName={projectName}
      pipelineName={pipelineName}
      versionName={versionName}
      isReadonly={isReadonly}
      validateDiagram={validateDiagram}
    />
  );

  return !defaultNodes.length ? null : isReadonly ? (
    diagramDom
  ) : (
    <>
      <FullScreenLoader displayed={isLoading} />
      <As style={style} className={className}>
        <PageHeader
          pipelineName={pipelineVersionDetail?.pipeline}
          versionName={pipelineVersionDetail?.version}
          errors={pipelineStatus.errors}
          lastSaved={pipelineVersionDetail?.last_updated}
          onSave={onSave}
          onExit={onExit}
        />

        <div
          style={{ display: "flex", position: "relative", overflow: "hidden" }}
          id="#diagram-canvas"
        >
          <WarningErrorsParent
            errors={pipelineStatus.errors}
            warnings={pipelineStatus.warnings}
          />
          <SidebarLeft
            projectName={projectName}
            pipelineVersionDetail={pipelineVersionDetail}
          />
          {diagramDom}
        </div>

        <SidebarRightParent />
      </As>
    </>
  );
};

export const Diagram = (props: DiagramProps) => (
  <ComposeProviders
    providers={[
      <ZoomContextProvider key={0} />,
      <ReactFlowProvider key={1} />,
      <SidebarRightStateContextProvider key={2} />,
      <PendingEdgesContextProvider key={3} />,
    ]}
  >
    <DiagramContextProvided {...props} />
  </ComposeProviders>
);
