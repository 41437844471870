import { useTheme } from "@mui/styles";

export const OperatorRaiseError = () => {
  const theme = useTheme();

  return (
    <svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_1767_19408)">
        <circle
          cx="40.3902"
          cy="39.5833"
          r="35.5553"
          fill={theme.palette.primary.main}
        />
        <path
          d="M37.9268 24.8704H42.8552V38.6387H37.9268V24.8704ZM37.9268 47.4004V42.3937H42.8552V47.4004H37.9268Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1767_19408"
          x="0.834961"
          y="0.228027"
          width="79.1104"
          height="79.1106"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1767_19408"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1767_19408"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
