/**
 * Generated by orval 🍺
 * Do not edit manually.
 * UbiOps
 * OpenAPI spec version: v2.1
 */
import useSwr from "swr";

import { orvalAxios } from "../../axios/index";

import type { ErrorType } from "../../axios/index";
import type {
  NotificationGroupList,
  NotificationGroupCreate,
  NotificationGroupUpdate,
} from "../../models";
import type { SWRConfiguration, Key } from "swr";

// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * 
### Description
List the notification groups in a project

### Response Structure
A list of details of the notification groups in the project
- `id`: Unique identifier for the notification group (UUID)
- `name`: Name of the notification group
- `contacts`: A list of contacts in the notification group

#### Response Examples
```
[
  {
    "id": "dc083d2a-74aa-4c49-8806-8adbeadca8a8",
    "name": "notification-group-1",
    "contacts": [
      {
        "type": "email",
        "configuration": {
          "email_address": "my.example.user@ubiops.com"
        }
      },
      {
        "type": "email",
        "configuration": {
          "email_address": "my.example.user.2@ubiops.com"
        }
      }
    ],
  },
  {
    "id": "7193ca09-d28b-4fce-a15a-11e0bc9f7f6f",
    "name": "notification-group-2",
     "contacts": [
      {
        "type": "email",
        "configuration": {
          "email_address": "my.example.user.3@ubiops.com"
        }
      }
    ]
  }
]
```

 * @summary List notification groups
 */
export const notificationGroupsList = (
  projectName: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<NotificationGroupList[]>(
    {
      url: `/projects/${projectName}/monitoring/notification-groups`,
      method: "get",
    },
    options
  );
};

export const getNotificationGroupsListKey = (projectName: string) => [
  `/projects/${projectName}/monitoring/notification-groups`,
];

export type NotificationGroupsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof notificationGroupsList>>
>;
export type NotificationGroupsListQueryError = ErrorType<unknown>;

export const useNotificationGroupsList = <TError = ErrorType<unknown>>(
  projectName: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof notificationGroupsList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!projectName;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getNotificationGroupsListKey(projectName) : null));
  const swrFn = () => notificationGroupsList(projectName, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Create a notification group by defining a name and a list of contacts

### Required Parameters
- `name`: Name of the notification group. It is unique within a project.

### Optional Parameters
- `contacts`: A list of dictionaries containing the following keys:
    - `type`: Type of the contact. It can be `email`.
    - `configuration`: A custom dictionary that contains required information for the type. For `email` type, it should contain the key `email_address`.

#### Request Examples
```
{
  "name": "notification-group-1",
  "contacts": [
    {
      "type": "email",
      "configuration": {
        "email_address": "my.example.user@ubiops.com"
      }
    },
    {
      "type": "email",
      "configuration": {
        "email_address": "my.example.user.2@ubiops.com"
      }
    }
  ]
}
```

### Response Structure
Details of the created notification group
- `id`: Unique identifier for the notification group (UUID)
- `name`: Name of the notification group
- `contacts`: A list of contacts in the notification group

#### Response Examples
```
{
  "id": "dc083d2a-74aa-4c49-8806-8adbeadca8a8",
  "name": "notification-group-1",
  "contacts": [
    {
      "type": "email",
      "configuration": {
        "email_address": "my.example.user@ubiops.com"
      }
    },
    {
      "type": "email",
      "configuration": {
        "email_address": "my.example.user.2@ubiops.com"
      }
    }
  ]
}
```

 * @summary Create notification groups
 */
export const notificationGroupsCreate = (
  projectName: string,
  notificationGroupCreate: NotificationGroupCreate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<NotificationGroupList>(
    {
      url: `/projects/${projectName}/monitoring/notification-groups`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: notificationGroupCreate,
    },
    options
  );
};

/**
 * 
### Description
Retrieve details of a single notification group in a project

### Response Structure
Details of a notification group
- `id`: Unique identifier for the notification group (UUID)
- `name`: Name of the notification group
- `contacts`: A list of contacts in the notification group

#### Response Examples
```
{
  "id": "dc083d2a-74aa-4c49-8806-8adbeadca8a8",
  "name": "notification-group-1",
  "contacts": [
    {
      "type": "email",
      "configuration": {
        "email_address": "my.example.user@ubiops.com"
      }
    },
    {
      "type": "email",
      "configuration": {
        "email_address": "my.example.user.2@ubiops.com"
      }
    }
  ]
}
```

 * @summary Get notification group
 */
export const notificationGroupsGet = (
  projectName: string,
  notificationGroupName: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<NotificationGroupList>(
    {
      url: `/projects/${projectName}/monitoring/notification-groups/${notificationGroupName}`,
      method: "get",
    },
    options
  );
};

export const getNotificationGroupsGetKey = (
  projectName: string,
  notificationGroupName: string
) => [
  `/projects/${projectName}/monitoring/notification-groups/${notificationGroupName}`,
];

export type NotificationGroupsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof notificationGroupsGet>>
>;
export type NotificationGroupsGetQueryError = ErrorType<unknown>;

export const useNotificationGroupsGet = <TError = ErrorType<unknown>>(
  projectName: string,
  notificationGroupName: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof notificationGroupsGet>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(projectName && notificationGroupName);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getNotificationGroupsGetKey(projectName, notificationGroupName)
        : null);
  const swrFn = () =>
    notificationGroupsGet(projectName, notificationGroupName, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Update a notification group

### Optional Parameters
- `name`: New name for the deployment
- `contacts`: A list of dictionaries containing the following keys:
    - `type`: Type of the contact. It can be `email`.
    - `configuration`: A custom dictionary that contains required information for the type. For `email` type, it should contain the key `email_address`.

#### Request Examples
```
{
  "name": "new-notification-group-name"
}
```

### Response Structure
Details of the updated notification group
- `id`: Unique identifier for the notification group (UUID)
- `name`: Name of the notification group
- `contacts`: A list of contacts in the notification group

#### Response Examples
```
{
  "id": "dc083d2a-74aa-4c49-8806-8adbeadca8a8",
  "name": "new-notification-group-name",
  "contacts": [
    {
      "type": "email",
      "configuration": {
        "email_address": "my.example.user@ubiops.com"
      }
    },
    {
      "type": "email",
      "configuration": {
        "email_address": "my.example.user.2@ubiops.com"
      }
    }
  ]
}
```

 * @summary Update notification group
 */
export const notificationGroupsUpdate = (
  projectName: string,
  notificationGroupName: string,
  notificationGroupUpdate: NotificationGroupUpdate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<NotificationGroupList>(
    {
      url: `/projects/${projectName}/monitoring/notification-groups/${notificationGroupName}`,
      method: "patch",
      headers: { "Content-Type": "application/json" },
      data: notificationGroupUpdate,
    },
    options
  );
};

/**
 * 
### Description
Delete a notification group

 * @summary Delete notification group
 */
export const notificationGroupsDelete = (
  projectName: string,
  notificationGroupName: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<void>(
    {
      url: `/projects/${projectName}/monitoring/notification-groups/${notificationGroupName}`,
      method: "delete",
    },
    options
  );
};
