import Tooltip from "@mui/material/Tooltip";
import { useRef, useEffect, useState } from "react";

import type { TypographyTypeMap } from "@mui/material";
import type { OverridableComponent } from "@mui/material/OverridableComponent";
import type { TooltipProps } from "@mui/material/Tooltip";
import type { FC, ReactNode } from "react";

import "./OverflowTooltip.scss";

interface OverflowTooltipProps {
  title?: ReactNode;
  component?: OverridableComponent<TypographyTypeMap<unknown, "span" | "div">>;
  placement?: TooltipProps["placement"];
}

const OverflowTooltip: FC<OverflowTooltipProps> = ({
  title,
  component: Component = "div",
  placement = "bottom",
  children,
  ...props
}) => {
  // Create Ref to container element
  const containerElementRef = useRef<HTMLElement>();
  // Whether the tooltip should be displayed or not
  const [hoverStatus, setHover] = useState(false);
  const compareSize = () => {
    // Check if the text fits in its parent element
    const compare =
      (containerElementRef.current?.scrollWidth || 0) >
      (containerElementRef.current?.clientWidth || 0);

    setHover(compare);
  };

  // compare once and add resize listener on "componentDidMount"
  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);
  }, []);

  // remove resize listener again on "componentWillUnmount"
  useEffect(
    () => () => {
      window.removeEventListener("resize", compareSize);
    },
    []
  );

  // Define state and function to update the value
  return (
    <Tooltip
      title={
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {" "}
          {title || children}{" "}
        </div>
      }
      disableHoverListener={!hoverStatus}
      placement={placement}
    >
      {/* @ts-ignore */}
      <Component
        // @ts-ignore
        ref={containerElementRef}
        className="overflow-tooltip__container"
        {...props}
      >
        {children}
      </Component>
    </Tooltip>
  );
};

export default OverflowTooltip;
