import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import { FormProvider } from "react-hook-form";

import { criticalButtonTheme } from "assets/styles/theme/critical-button";
import { LOADING, UNLOADED } from "libs/utilities/request-statuses";

import { FormWrapper, PrimaryButton } from "components/atoms";

import type { Theme } from "@mui/material";
import type { ButtonProps } from "components/atoms/Button/types";
import type { LOADED } from "libs/utilities/request-statuses";
import type { UseFormMethods, SubmitHandler } from "react-hook-form";

import "./FormContainer.scss";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export type FormContainerProps = {
  children?: React.ReactNode;
  onSubmit: SubmitHandler<any>;
  buttonDisabled?: boolean;
  buttonLabel?: React.ReactNode;
  buttonProps?: ButtonProps;
  formMethods: UseFormMethods<any>;
  status?: typeof UNLOADED | typeof LOADING | typeof LOADED;
  critical?: boolean;
  onBackButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
  backButtonLabel?: string;
  small?: boolean;
  fullWidth?: boolean;
};

export const FormContainer = ({
  children,
  onSubmit,
  buttonDisabled,
  buttonLabel = "Submit",
  buttonProps,
  formMethods,
  status = UNLOADED,
  critical = false,
  small = false,
  fullWidth = false,
}: FormContainerProps) => (
  <FormProvider {...formMethods}>
    <form onSubmit={formMethods.handleSubmit(onSubmit)}>
      <FormWrapper small={small} fullWidth={fullWidth}>
        {children}
        <div className="form-container__button-row">
          <div className="form-container__submit-button">
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={critical ? criticalButtonTheme : {}}>
                <PrimaryButton
                  type="submit"
                  disabled={status === LOADING || buttonDisabled}
                  {...buttonProps}
                >
                  {buttonLabel}
                </PrimaryButton>
              </ThemeProvider>
            </StyledEngineProvider>
          </div>
        </div>
      </FormWrapper>
    </form>
  </FormProvider>
);
