import { useTheme } from "@mui/styles";

export const OperatorFunction = () => {
  const theme = useTheme();

  return (
    <svg
      width="100%"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1767_19411)">
        <circle
          cx="40.3902"
          cy="39.5833"
          r="35.5553"
          fill={theme.palette.primary.main}
        />
        <path
          d="M40.6002 26.6625C39.1287 26.5294 37.8311 27.6077 37.6973 29.0853L37.3629 32.9325H41.1353V35.5949H37.1221L36.5335 42.344C36.266 45.2726 33.6708 47.4425 30.7278 47.1763C29.0557 47.0298 27.544 46.1113 26.6611 44.6869L28.6677 42.6901C29.1092 44.0879 30.6208 44.8733 32.012 44.4207C33.0554 44.1012 33.7912 43.1827 33.8848 42.1044L34.4467 35.5949H30.4335V32.9325H34.6875L35.0487 28.8457C35.3028 25.9171 37.8846 23.7472 40.841 23.9868C42.5265 24.1333 44.0515 25.0651 44.9344 26.5028L42.9278 28.4996C42.5934 27.4746 41.6838 26.7557 40.6002 26.6625ZM53.4156 37.7913L51.5294 35.9143L47.7436 39.6816L43.9579 35.9143L42.0449 37.7913L45.8575 41.5852L42.0449 45.3259L43.9579 47.2029L47.7436 43.4356L51.5294 47.2029L53.4156 45.3259L49.6298 41.5852L53.4156 37.7913Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1767_19411"
          x="0.834961"
          y="0.0307617"
          width="79.1104"
          height="79.1106"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1767_19411"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1767_19411"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
