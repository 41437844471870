import { Box, Typography, Link } from "@mui/material";

import { LINK_PRIVACY, LINK_SAAS } from "libs/constants/documentation-links";
import {
  FIELD_TERMS_CONDITIONS,
  FIELD_NEWSLETTER,
} from "libs/constants/fields";

import { Checkbox } from "components/atoms";

export const TCNewsletterCheckboxes = () => (
  <Box mt={2}>
    <Checkbox
      name={FIELD_TERMS_CONDITIONS}
      color="primary"
      rules={{ required: "You must agree to the terms and conditions." }}
      label={
        <Typography variant="body2">
          I agree with the{" "}
          <Link href={LINK_SAAS} target="_blank" underline="always">
            UbiOps SaaS Terms & Conditions
          </Link>{" "}
          and confirm that I have read the{" "}
          <Link href={LINK_PRIVACY} target="_blank" underline="always">
            Privacy Policy
          </Link>
          .
        </Typography>
      }
    />
    <Checkbox
      name={FIELD_NEWSLETTER}
      color="primary"
      label={
        <Typography variant="body2">
          Keep me posted on UbiOps product updates, news and special offers.
        </Typography>
      }
    />
  </Box>
);
