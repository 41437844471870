import { getFiltersFromUrl } from "../../logs/utils";

import type {
  DeploymentRequestBatchDetail,
  DeploymentRequestList,
} from "libs/data/models";

export interface TrainingRunParams {
  id: string;
  version: string;
}

export const transformTrainingRuns = (
  runs: DeploymentRequestList[],
  trainingRuns?: DeploymentRequestBatchDetail[]
) => {
  if (trainingRuns) {
    return (
      trainingRuns
        // eslint-disable-next-line unused-imports/no-unused-vars
        .map(({ status, time_completed, ...runDetails }) => {
          const run = runs?.find((item) => item.id === runDetails.id);

          return {
            name: runDetails.request_data?.name || "",
            status: run?.status,
            time_completed: run?.time_completed,
            ...runDetails,
          };
        })
    );
  } else return [];
};

export const getTrainingRunsFromUrl = (URLParams?: string) => {
  const { ids: idsFromUrl } = getFiltersFromUrl(new URLSearchParams(URLParams));

  if (idsFromUrl) {
    const separatedItems = idsFromUrl.split(",");

    return separatedItems.map((experimentAndRunId) => {
      const formattedExperimentAndRunId = experimentAndRunId.split(":");

      return {
        version: formattedExperimentAndRunId[0],
        id: formattedExperimentAndRunId[1],
      };
    });
  } else return [];
};

export const transformTrainingRunsToSearchParams = (
  trainingRuns: (
    | DeploymentRequestBatchDetail
    | { version: string; id: string }
  )[]
) => {
  const mergedTrainingRuns = trainingRuns.map((trainingRun) =>
    [trainingRun.version, trainingRun.id].join(":")
  );

  return mergedTrainingRuns.join(",");
};
