import styled from "@emotion/styled";
import { MTableToolbar } from "@material-table/core";
import { Box, useTheme } from "@mui/material";

import { LabelsList } from "./LabelsList";

import type { AppThemeProps } from "assets/styles/theme/theme";

type Props = {
  filters: { key: string; value: string }[];
  onFilterRemove: (key: string, value: string) => void;
};

export const LabelsFilterToolbar = ({
  filters,
  onFilterRemove,
  ...props
}: Props) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Box flexGrow="1">
          {/* this toolbar is being used for the search bar */}
          <S_Toolbar
            {...props}
            backgroundColor={theme.palette.table.searchIcon}
          />
        </Box>
      </Box>
      <Box>
        <LabelsList labels={filters} onLabelDelete={onFilterRemove} />
      </Box>
    </Box>
  );
};

const S_Toolbar = styled(MTableToolbar)`
  &.MuiToolbar-root.MuiToolbar-gutters {
    & svg[aria-label="Search"] {
      color: ${(props) => props.backgroundColor}
  }
`;
