import { createContext } from "react";

interface LoaderContext {
  displayed: boolean;
  showLoader: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LoaderContextGlobal = createContext<LoaderContext>({
  displayed: false,
  showLoader: (flag = false) => flag,
});

export const LoaderContext: React.FC<LoaderContext> = ({
  children,
  displayed,
  showLoader,
}) => (
  <LoaderContextGlobal.Provider value={{ displayed, showLoader }}>
    {children}
  </LoaderContextGlobal.Provider>
);
