import DateRangeIcon from "@mui/icons-material/DateRange";
import Trash from "@mui/icons-material/DeleteRounded";
import DownloadIcon from "@mui/icons-material/Download";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Grid,
  Card,
  Box,
  CardHeader,
  Menu,
  IconButton,
  MenuItem,
  useTheme,
  Typography,
  Tooltip,
} from "@mui/material";
import { useCallback, useState } from "react";

import { BarChartIcon } from "assets/images/BarChart";
import { LineChartIcon } from "assets/images/LineChart";
import { spacing } from "assets/styles/theme";
import { chartPlugins } from "libs/utilities/metrics-helper";

import { Dialog, Icon, RefreshButton, SecondaryButton } from "components/atoms";

import { MonthPicker } from "../Picker";

import type { DateRange } from "../Picker/types";
import type { AppThemeProps } from "assets/styles/theme/theme";
import type { ReactChild } from "react";

export type MetricGraphProps = {
  title?: string;
  chartComponent: any;
  options?: any;
  data?: any;
  fullPageWidth?: boolean;
  height?: string;
  minHeight?: string;
  border?: boolean;
  withDatePicker?: boolean;
  plugins?: any[];
  chartDates?: DateRange | null;
  defaultDates?: DateRange | null;
  setChartDates?: (dates: DateRange) => void;
  hasRemove?: boolean;
  hasMenu?: boolean;
  handleRemove?: (title: string) => void;
  onDownloadData?: () => void;
  setGraphType?: (title: string, options?: any) => void;
  noDataText?: string;
  customTitle?: ReactChild | null;
  onRefresh?: () => void;
};

export const MetricGraph = ({
  title,
  chartComponent: Component,
  fullPageWidth = false,
  border = true,
  height = "300px",
  options,
  data,
  withDatePicker,
  chartDates,
  setChartDates,
  plugins = chartPlugins,
  minHeight,
  hasRemove = false,
  hasMenu = false,
  handleRemove = () => {},
  onDownloadData,
  setGraphType,
  defaultDates,
  onRefresh,
  noDataText = "No data available for this time range",
  customTitle = null,
  ...props
}: MetricGraphProps) => {
  const [monthSelectDialogOpen, setMonthSelectDialogOpen] = useState(false);
  const [fullScreenDialogOpen, setFullScreenDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const theme = useTheme() as AppThemeProps;
  const refreshGraph = useCallback(() => {
    onRefresh?.();
    chartDates && setChartDates?.(chartDates);
  }, [chartDates, setChartDates, onRefresh]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // rename "rate" type charts if displayed as a bar chart
  const displayTitle =
    options?.isBarChart && title?.includes(" rate")
      ? title?.replace(" rate", "")
      : title;
  const graphHasNoData = !data?.labels?.length;

  return (
    <Grid item xs={12} md={fullPageWidth ? 12 : 6}>
      <Card
        variant={border ? "outlined" : "elevation"}
        elevation={0}
        style={{
          width: "100%",
          height: height,
          minHeight: minHeight,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          padding: 16,
          background: "transparent",
        }}
      >
        {(title || customTitle) && (
          <CardHeader
            style={{ padding: 0 }}
            title={customTitle || displayTitle}
            action={
              <Box display="flex">
                {withDatePicker && (
                  <SecondaryButton
                    onClick={() => {
                      setMonthSelectDialogOpen(true);
                    }}
                    startIcon={<DateRangeIcon />}
                  >
                    Time range
                  </SecondaryButton>
                )}
                {withDatePicker && (
                  <RefreshButton
                    onClick={refreshGraph}
                    tooltip="Refresh graph"
                  />
                )}
                {onDownloadData && (
                  <Tooltip title="Download data">
                    <IconButton size="small" onClick={onDownloadData}>
                      <DownloadIcon
                        sx={{ color: theme.palette.text.primary }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
                {setGraphType && (
                  <IconButton
                    onClick={() => setGraphType(title || "", options)}
                  >
                    {options?.isBarChart ? <LineChartIcon /> : <BarChartIcon />}
                  </IconButton>
                )}
                {hasMenu && (
                  <IconButton color="primary" onClick={handleClick}>
                    <MoreVertIcon />
                  </IconButton>
                )}
              </Box>
            }
          />
        )}

        <Menu
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={handleClose}
          elevation={2}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          style={{
            zIndex: 100,
          }}
        >
          <MenuItem
            onClick={() => {
              setFullScreenDialogOpen(true);
              handleClose();
            }}
            style={{ gap: 2 }}
          >
            <FullscreenIcon />
            <Typography variant="subtitle1">View full screen</Typography>
          </MenuItem>
          {hasRemove && (
            <MenuItem
              onClick={() => {
                title && handleRemove(title);
                handleClose();
              }}
              style={{ color: theme.palette.error.main, gap: 2 }}
            >
              <Trash />
              <Typography variant="subtitle1">Remove graph</Typography>
            </MenuItem>
          )}
        </Menu>

        <Box height="100%" style={{ overflow: "hidden" }}>
          {graphHasNoData && (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                position: "absolute",
                top: "45%",
                left: "30%",
                color: theme.palette.disabled.dark,
                gap: spacing[8],
              }}
            >
              <Icon component={ErrorOutlineIcon} />
              <Typography variant="body1">{noDataText}</Typography>
            </Box>
          )}
          <Component
            data={data}
            options={options}
            plugins={plugins}
            id={title}
            {...props}
          />
        </Box>
      </Card>
      <MonthPicker
        open={monthSelectDialogOpen}
        selectedMonth={chartDates ?? defaultDates}
        onClose={() => setMonthSelectDialogOpen(false)}
        onChange={(dates) => setChartDates?.(dates)}
      />
      <Dialog
        open={fullScreenDialogOpen}
        title={displayTitle}
        onClose={() => setFullScreenDialogOpen(false)}
        fullWidth
        maxWidth="lg"
        dialogBodyStyles={{ overflow: "hidden" }}
      >
        <Box maxHeight="60vh" minHeight="50vh" style={{ overflow: "hidden" }}>
          {graphHasNoData && (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                position: "absolute",
                top: "45%",
                left: "40%",
                color: theme.palette.disabled.dark,
                gap: spacing[8],
              }}
            >
              <Icon component={ErrorOutlineIcon} />
              <Typography variant="body1">{noDataText}</Typography>
            </Box>
          )}
          <Component
            data={data}
            options={options}
            plugins={plugins}
            id={title}
            {...props}
          />
        </Box>
      </Dialog>
    </Grid>
  );
};
