import CancelIcon from "@mui/icons-material/Cancel";
import { Grid, Box, IconButton, Link, Tabs, Tab } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useEffect, useMemo, useState } from "react";
import { useFieldArray, FormProvider, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";

import { borders, spacing } from "assets/styles/theme";
import { AutoCompleteSelectHookForm } from "components/atoms/UncontrolledAutoComplete/AutoCompleteSelectHookForm";
import { TAB_SELECT_EXISTING, TAB_CREATE_NEW } from "libs/constants/constants";
import {
  FIELD_NAME,
  FIELD_CONTACTS,
  FIELD_NOTIFICATION_GROUP,
} from "libs/constants/fields";
import { useNotificationGroupsList } from "libs/data/endpoints/monitoring/monitoring";
import { explanations } from "libs/utilities/explanations";
import {
  notificationGroupTypes,
  getLabel,
} from "libs/utilities/labels-mapping";
import validators from "libs/utilities/validators";

import {
  FormTextField,
  Dialog,
  PrimaryButton,
  DialogDescription,
  AddButton,
  SecondaryButton,
} from "components/atoms";

import type {
  NotificationGroupContact,
  NotificationGroupList,
} from "libs/data/models";
import type { UseFormMethods } from "react-hook-form";

const formRules = {
  email: {
    required: validators.required.message("contact email address"),
    pattern: {
      value: validators.email.pattern,
      message: validators.email.message,
    },
  },
  name: {
    required: validators.required.message("notification group name"),
    pattern: {
      value: validators.name.pattern,
      message: validators.name.message("notification group name"),
    },
  },
};

const useStyles = makeStyles({
  tab: {
    borderBottom: `${borders.tertiary}`,
  },
});

type NotificationGroupEditCreateProps = {
  isUpdate?: boolean;
  selectOrCreate?: boolean;
  requestMode?: boolean;
  currentVersion?: any;
  formMethods: UseFormMethods<{
    name: string;
    contacts: {
      type: {
        label: string;
        value: string;
      };
      value: string;
    }[];
  }>;
  isOpen: boolean;
  toggleDialog: () => void;
  updateSubmitCallback: (...args: any[]) => void;
  createSubmitCallback: (...args: any[]) => void;
  createAndSelectCallback: (...args: any[]) => void;
  requestMethods?: any;
  setGroupCallback?: (...args: any[]) => any;
  onCloseCallback?: (...args: any[]) => any;
  request?: any;
  selectedNotificationGroup?: NotificationGroupList;
};

const NotificationGroupEditCreate = ({
  isUpdate,
  selectOrCreate,
  requestMode,
  currentVersion,
  formMethods,
  isOpen,
  toggleDialog,
  updateSubmitCallback,
  createSubmitCallback,
  createAndSelectCallback,
  requestMethods,
  setGroupCallback,
  onCloseCallback,
  request,
  selectedNotificationGroup,
}: NotificationGroupEditCreateProps) => {
  const classes = useStyles();
  const { projectName } =
    useParams<{ organizationName: string; projectName: string }>();
  const { data: notificationGroups } = useNotificationGroupsList(projectName);
  const { control, setValue, clearErrors } = formMethods;

  const [activeTab, setActiveTab] =
    useState<typeof TAB_CREATE_NEW | typeof TAB_SELECT_EXISTING>(
      TAB_SELECT_EXISTING
    );

  const selectedNotificationGroupToChange = useWatch<{ value: string }>({
    name: FIELD_NOTIFICATION_GROUP,
    control,
  });

  const { fields, append, remove } = useFieldArray({
    name: FIELD_CONTACTS,
    control,
  });

  useEffect(() => {
    if (selectedNotificationGroup && isUpdate) {
      setValue(FIELD_NAME, selectedNotificationGroup.name);
      setValue(
        FIELD_CONTACTS,
        selectedNotificationGroup.contacts.map(
          (item: NotificationGroupContact) => {
            if (item.type === "email") {
              return {
                type: {
                  value: item.type,
                  label: getLabel(notificationGroupTypes, item.type),
                },
                value: item.configuration.email_address,
              };
            }

            return {};
          }
        )
      );
    }
  }, [selectedNotificationGroup, setValue, isUpdate]);

  const notificationGroupOptions = useMemo(() => {
    const options = Object.values(notificationGroups ?? {}).map((item) => ({
      label: item.name,
      value: item.name,
    }));
    options.push({
      label: "None",
      value: "",
    });

    return options;
  }, [notificationGroups]);

  const defaultNotificationGroup = useMemo(() => {
    if (request?.notification_group) {
      return {
        label: request?.notification_group,
        value: request?.notification_group,
      };
    }
    if (currentVersion?.default_notification_group) {
      return {
        label: currentVersion.default_notification_group,
        value: currentVersion.default_notification_group,
      };
    }

    return undefined;
  }, [currentVersion, request]);

  useEffect(() => {
    return () => {
      clearErrors(FIELD_CONTACTS);
    };
  }, [clearErrors]);

  useEffect(() => {
    if (selectedNotificationGroupToChange) {
      setGroupCallback &&
        setGroupCallback(selectedNotificationGroupToChange?.value);
    }
  }, [setGroupCallback, selectedNotificationGroupToChange]);

  const onToggle = () => {
    if (isOpen) {
      if (onCloseCallback) onCloseCallback();
    }
    toggleDialog();
  };

  const handleRowAdd = () => {
    setValue(FIELD_CONTACTS, null);
    append({
      type: {
        value: "email",
        label: getLabel(notificationGroupTypes, "email"),
      },
      value: "",
    });
  };

  const handleClearAll = () => {
    setValue(FIELD_CONTACTS, [
      {
        type: {
          value: "email",
          label: getLabel(notificationGroupTypes, "email"),
        },
        value: "",
      },
    ]);
  };

  const handleRowDelete = (index: number) => () => {
    remove(index);
  };

  const handleSubmit = (isCreateSelect: boolean) => {
    if (requestMode) {
      if (isCreateSelect) {
        return formMethods.handleSubmit(requestMethods.createAndUpdate)();
      } else {
        return requestMethods.selectAndUpdate(
          selectedNotificationGroupToChange
        );
      }
    } else {
      if (isUpdate) {
        return formMethods.handleSubmit(updateSubmitCallback)();
      } else if (isCreateSelect) {
        return formMethods.handleSubmit(createAndSelectCallback)();
      } else if (selectOrCreate) {
        onToggle();

        return;
      }
    }

    return formMethods.handleSubmit(createSubmitCallback)();
  };

  return (
    <FormProvider {...formMethods}>
      <Dialog
        title={`${isUpdate ? "Update" : "Add"} notification group`}
        open={isOpen}
        onClose={onToggle}
        Actions={
          <>
            <SecondaryButton onClick={onToggle} style={{ marginRight: 14 }}>
              Cancel
            </SecondaryButton>

            <PrimaryButton
              onClick={() =>
                handleSubmit(
                  selectOrCreate ? activeTab !== TAB_SELECT_EXISTING : true
                )
              }
              disabled={requestMode && request?.status === "completed"}
            >
              {selectOrCreate
                ? activeTab === TAB_SELECT_EXISTING
                  ? "Select"
                  : "Create and Select"
                : isUpdate
                ? "Update"
                : "Add"}
            </PrimaryButton>
          </>
        }
      >
        <DialogDescription style={{ marginBottom: 15 }}>
          {explanations.monitoring.newNotificationGroup}
        </DialogDescription>
        {selectOrCreate && (
          <Tabs
            indicatorColor="secondary"
            textColor="secondary"
            variant="fullWidth"
            value={activeTab}
            onChange={(_e, value) => setActiveTab(value)}
          >
            <Tab
              className={classes.tab}
              value={TAB_SELECT_EXISTING}
              label="Select existing"
            />
            <Tab
              className={classes.tab}
              value={TAB_CREATE_NEW}
              label="Create new"
            />
          </Tabs>
        )}

        {selectOrCreate && activeTab === TAB_SELECT_EXISTING && (
          <Box my={3}>
            <Grid item>
              <AutoCompleteSelectHookForm
                name={FIELD_NOTIFICATION_GROUP}
                label="Notification group"
                options={notificationGroupOptions}
                defaultValue={defaultNotificationGroup}
                withError={false}
              />
            </Grid>
          </Box>
        )}

        {selectOrCreate && activeTab !== TAB_CREATE_NEW ? null : (
          <Box mt={2}>
            <Grid item xs={9} style={{ display: isUpdate ? "none" : "block" }}>
              <FormTextField
                id={FIELD_NAME}
                name={FIELD_NAME}
                label="Notification group name"
                rules={formRules.name}
                placeholder="Ex: my-custom-group"
              />
            </Grid>

            <Box my={2}>
              {fields.map((item, idx) => {
                return (
                  <Box display="flex" alignItems="center" key={item.id} my={2}>
                    <Grid item xs={9}>
                      <FormTextField
                        name={`${FIELD_CONTACTS}[${idx}].value`}
                        label="Email"
                        placeholder="Ex: example@email.com"
                        defaultValue={item.value}
                        size="small"
                        margin="none"
                        rules={formRules.email}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        aria-label="Delete"
                        onClick={handleRowDelete(idx)}
                      >
                        <CancelIcon fontSize="small" />
                      </IconButton>
                    </Grid>
                  </Box>
                );
              })}

              <Box
                display="flex"
                alignItems="center"
                gap={spacing[32]}
                marginTop={2}
              >
                <AddButton onClick={handleRowAdd}>Add email</AddButton>

                <Link
                  component="button"
                  underline="always"
                  variant="button"
                  type="button"
                  onClick={handleClearAll}
                >
                  Clear all
                </Link>
              </Box>
            </Box>
          </Box>
        )}
      </Dialog>
    </FormProvider>
  );
};

export default NotificationGroupEditCreate;
