import CancelIcon from "@mui/icons-material/Cancel";
import CloudDownload from "@mui/icons-material/CloudDownloadRounded";
import LogsIcon from "@mui/icons-material/Subject";
import { Box, Tooltip } from "@mui/material";
import moment from "moment";
import { stringifyUrl } from "query-string";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { IlluRevisions } from "assets/images/IlluRevisions";
import { ENVIRONMENT_PERMISSIONS } from "libs/constants/permissions";
import { useEnvironmentRevisionBuildCancel } from "libs/data/customized/environment-revisions/useEnvironmentRevisionBuildCancel";
import { useEnvironmentRevisionRebuild } from "libs/data/customized/environment-revisions/useEnvironmentRevisionRebuild";
import { useEnvironmentRevisionDownload } from "libs/data/customized/environments/useEnvironmentRevisionDownload";
import { usePermissionValidation } from "libs/data/customized/roles";
import {
  environmentBuildsList,
  useEnvironmentRevisionsList,
  useEnvironmentsGet,
} from "libs/data/endpoints/environments/environments";
import { useInterval, useLogsUrl } from "libs/hooks";
import {
  DATE_TIME_FORMAT,
  getFormattedDate,
  getTzAwareDate,
} from "libs/utilities/date-util";
import { formatStatusLabel } from "libs/utilities/statuses";

import {
  HighlightedText,
  IconButton,
  Loader,
  RetryIconSpinner,
  StatusIcon,
} from "components/atoms";
import { BaseTable, EmptyOverview } from "components/molecules";

import type { BaseColumn } from "components/molecules/BaseTable";
import type {
  EnvironmentBuildList,
  EnvironmentRevisionDetail,
} from "libs/data/models";

interface LoadingDict {
  [id: string]: boolean;
}

interface BuildStatusDict {
  [id: string]: EnvironmentBuildList;
}

export const EnvironmentRevisions = () => {
  const history = useHistory();
  const { projectName, environmentName } =
    useParams<{ projectName: string; environmentName: string }>();
  const downloadRevision = useEnvironmentRevisionDownload(projectName);
  const { data: revisions, error } = useEnvironmentRevisionsList(
    projectName,
    environmentName
  );
  const [buildStatuses, setBuildStatuses] = useState<BuildStatusDict>({});

  const loadStatusForRevision = useCallback(
    async (id: string) => {
      try {
        const builds = await environmentBuildsList(
          projectName,
          environmentName,
          id
        );

        const latestBuild = builds?.sort(
          (a, b) =>
            moment(b.creation_date).unix() - moment(a.creation_date).unix()
        )?.[0];

        if (latestBuild) {
          setBuildStatuses((current) => ({
            ...current,
            [id as keyof BuildStatusDict]: latestBuild,
          }));
        }
      } catch {
        //
      }
    },
    [environmentName, projectName]
  );

  const { data: environmentDetails, mutate: mutateEnvironmentInfo } =
    useEnvironmentsGet(projectName, environmentName);

  // fetch status for pending builds
  const refreshBuildInfoIfNeeded = useCallback(() => {
    revisions?.map((revision: EnvironmentRevisionDetail) => {
      const buildInfo = buildStatuses[revision?.id || ""];
      const buildStatus = buildInfo?.status;
      const needsReload = !["success", "failed"].includes(
        buildStatus as string
      );
      if ((revision.id && needsReload) || (revision.id && !buildInfo)) {
        loadStatusForRevision(revision.id);
        // reload environment info to update active revision
        mutateEnvironmentInfo();
      }
    });
  }, [buildStatuses, loadStatusForRevision, revisions, mutateEnvironmentInfo]);

  // toggle build info fetch right after the load of revisions
  // and periodically after it
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(refreshBuildInfoIfNeeded, [revisions]);
  useInterval(refreshBuildInfoIfNeeded, [refreshBuildInfoIfNeeded], 5000);

  const rebuildEnvironmentRevision = useEnvironmentRevisionRebuild(
    projectName,
    environmentName
  );

  const cancelRevisionBuild = useEnvironmentRevisionBuildCancel(
    projectName,
    environmentName
  );

  const isLoading =
    (!revisions || Object.keys(buildStatuses).length !== revisions.length) &&
    !error;

  const [loading, setLoading] = useState<LoadingDict>({});

  const [currentPermissions] = usePermissionValidation(
    projectName,
    Object.values(ENVIRONMENT_PERMISSIONS),
    environmentName,
    "environment"
  );

  const revisionsWithStatus = useMemo(() => {
    return (
      revisions?.map((revision) => ({
        ...revision,
        status: buildStatuses[revision.id as keyof BuildStatusDict],
      })) ?? []
    );
  }, [buildStatuses, revisions]);

  const logsUrl = useLogsUrl({
    queryParameters: {},
  });

  const renderLogsIcon = useCallback(
    (rowData: EnvironmentRevisionDetail & { status: EnvironmentBuildList }) => {
      const hasExpired = rowData.expired;

      const fromDate = getTzAwareDate(rowData.creation_date);

      const requestUrl = stringifyUrl({
        url: logsUrl,
        query: {
          environment_build_id: rowData?.status?.id,
          from_date: getFormattedDate(fromDate),
          to_date: getFormattedDate(fromDate.clone().add(7, "days")),
        },
      });

      return (
        <IconButton
          onClick={() => history.push(requestUrl)}
          icon={LogsIcon}
          htmlColor="secondary"
          hoverColor="secondary"
          tooltip={
            hasExpired
              ? "Cannot view logs when environment has expired"
              : "View logs"
          }
          disabled={hasExpired}
        />
      );
    },
    [history, logsUrl]
  );

  const handleOnRetry = useCallback(
    async (id: string) => {
      setLoading((current) => ({ ...current, [id]: true }));
      await rebuildEnvironmentRevision(id);
      await loadStatusForRevision(id);
      setLoading((current) => ({ ...current, [id]: false }));
    },
    [loadStatusForRevision, rebuildEnvironmentRevision]
  );

  const columns = useMemo(
    () => [
      {
        title: "Created",
        field: "creation_date",
        type: "datetime",
        defaultSort: "desc",
        width: "25%",
        nowrap: true,
        render: ({ creation_date }: EnvironmentRevisionDetail) =>
          getTzAwareDate(creation_date).format(DATE_TIME_FORMAT),
      },
      {
        title: "Created by",
        field: "created_by",
        width: "15%",
        sorting: false,
        nowrap: true,
      },
      {
        title: "Status",
        field: "status",
        sorting: false,
        width: "20%",
        render: (
          rowData: EnvironmentBuildList & { status: EnvironmentBuildList }
        ) =>
          rowData?.status?.status ? (
            <StatusIcon
              label={formatStatusLabel(rowData?.status?.status)}
              status={rowData?.status?.status}
              displayError={true}
              errorMessage={rowData.error_message}
              animation={false}
            />
          ) : (
            ""
          ),
      },
      {
        disableClick: true,
        width: "40%",
        align: "right",
        render: (
          rowData: EnvironmentRevisionDetail & { build_id: string } & {
            status: EnvironmentBuildList;
          }
        ) => {
          const isRebuildEnabled = rowData?.status?.status === "building";
          const isDownloadDisabled =
            rowData.expired ||
            !currentPermissions[ENVIRONMENT_PERMISSIONS["version_download"]];

          return (
            <Box>
              {rowData.id === environmentDetails?.active_revision && (
                <HighlightedText>active revision</HighlightedText>
              )}
              {rowData.environment && (
                <IconButton
                  htmlColor="secondary"
                  hoverColor="secondary"
                  tooltip={
                    rowData.expired
                      ? "This revision has expired and is no longer available for download"
                      : "Download package"
                  }
                  icon={CloudDownload}
                  disabled={isDownloadDisabled}
                  onClick={() => {
                    downloadRevision(environmentName, rowData.id);
                  }}
                />
              )}
              {renderLogsIcon(rowData)}
              {!!rowData?.id && (
                <RetryIconSpinner
                  disabled={rowData.expired}
                  onClick={() => handleOnRetry(rowData.id as string)}
                  spinCondition={!!loading[rowData.id as keyof LoadingDict]}
                  tooltipText="Rebuild"
                />
              )}
              <Tooltip
                title={
                  isRebuildEnabled
                    ? "Cancel"
                    : "Revision build can only be cancelled when it's in pending or processing state"
                }
              >
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <IconButton
                    disabled={!isRebuildEnabled}
                    icon={CancelIcon}
                    onClick={() => {
                      cancelRevisionBuild(rowData.id, rowData.status.id);
                    }}
                  />
                </span>
              </Tooltip>
            </Box>
          );
        },
      },
    ],
    [
      environmentDetails?.active_revision,
      currentPermissions,
      renderLogsIcon,
      loading,
      downloadRevision,
      environmentName,
      handleOnRetry,
      cancelRevisionBuild,
    ]
  );

  return isLoading ? (
    <Loader />
  ) : !revisionsWithStatus?.length ? (
    <EmptyOverview
      message="No revisions to display."
      illustration={IlluRevisions}
    />
  ) : (
    <BaseTable columns={columns as BaseColumn[]} data={revisionsWithStatus} />
  );
};
