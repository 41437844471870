import { useState, useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  DEFAULT_TIME_OUT_BATCH_REQUESTS,
  TIME_OUT_BROWSER,
  TIME_OUT_REQUESTS,
  REQUEST_TASK,
} from "libs/constants/constants";
import {
  batchDeploymentVersionRequestsCreate,
  deploymentVersionRequestsCreate,
  deploymentVersionRequestsGet,
} from "libs/data/endpoints/deployment-requests/deployment-requests";
import {
  batchPipelineVersionRequestsCreate,
  pipelineVersionRequestsCreate,
  pipelineVersionRequestsGet,
} from "libs/data/endpoints/pipeline-requests/pipeline-requests";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";
import { ERROR, LOADING } from "libs/utilities/request-statuses";
import { getRandomId } from "libs/utilities/utils";
import { addTask, updateTask } from "store/features/taskManager";

import type { AxiosError } from "axios";
import type {
  DeploymentRequestBatchCreateResponse,
  DeploymentRequestCreateResponse,
  DeploymentRequestCreateResponseResult,
  DeploymentRequestSingleDetail,
  DeploymentRequestsCreateDataBody,
  PipelineRequestBatchCreateResponse,
  PipelineRequestCreateResponse,
  PipelineRequestSingleDetail,
} from "libs/data/models";

type RequestParameters = {
  type: "deployment" | "pipeline";
  resourceName?: string;
  resourceVersion?: string;
};

export const useRetryRequest = ({
  projectName,
  requestParameters,
  batchMode = false,
}: {
  projectName: string;
  requestParameters: RequestParameters;
  batchMode?: boolean;
}) => {
  const dispatch = useDispatch();
  const trackId = getRandomId();
  const [retrying, setRetrying] = useState(false);

  const retryRequest = useCallback(
    async (data) => {
      const type = data.deployment ? "deployment" : "pipeline";
      const request =
        type === "deployment"
          ? batchMode
            ? batchDeploymentVersionRequestsCreate(
                projectName,
                data.deployment,
                data.version,
                [data.request_data],
                { timeout: DEFAULT_TIME_OUT_BATCH_REQUESTS },
                { timeout: TIME_OUT_BROWSER }
              )
            : deploymentVersionRequestsCreate(
                projectName,
                data.deployment,
                data.version,
                data.request_data,
                { timeout: TIME_OUT_REQUESTS },
                { timeout: TIME_OUT_BROWSER }
              )
          : batchMode
          ? batchPipelineVersionRequestsCreate(
              projectName,
              data.pipeline,
              data.version,
              [data.request_data],
              { timeout: DEFAULT_TIME_OUT_BATCH_REQUESTS },
              { timeout: TIME_OUT_BROWSER }
            )
          : pipelineVersionRequestsCreate(
              projectName,
              data.pipeline,
              data.version,
              data.request_data,
              { pipeline_timeout: TIME_OUT_REQUESTS },
              { timeout: TIME_OUT_BROWSER }
            );

      const requestParameters: RequestParameters = {
        type: type,
        resourceName: type === "deployment" ? data.deployment : data.pipeline,
        resourceVersion: data.version,
      };

      dispatch(
        addTask({
          status: LOADING,
          type: REQUEST_TASK,
          id: trackId,
          message: `${type} request`,
          requestParameters,
        })
      );

      return request
        .then(
          (
            data:
              | DeploymentRequestBatchCreateResponse[]
              | DeploymentRequestCreateResponse
              | PipelineRequestBatchCreateResponse[]
              | PipelineRequestCreateResponse
          ) => {
            dispatch(createSuccessNotification(`Request retried`));

            if (data) {
              const parsedData = Array.isArray(data) ? data[0] : data;
              const result = {
                responseData: parsedData,
                requestData: parsedData,
                requestParameters,
              };

              dispatch(
                updateTask({
                  id: trackId,
                  status: LOADING,
                  result,
                })
              );
            }
          }
        )
        .catch((error: AxiosError) => {
          setRetrying(false);
          const result = {
            responseData: (error.response?.data || {
              id: "",
              success: false,
              error_message: error.message,
              result: null,
              version: data.version,
            }) as DeploymentRequestCreateResponseResult,
            requestData: data as DeploymentRequestsCreateDataBody,
            requestParameters,
          };
          dispatch(
            updateTask({
              id: trackId,
              status: ERROR,
              result,
            })
          );

          dispatch(createErrorNotification(error.message));
        });
    },
    [batchMode, dispatch, projectName, trackId]
  );

  const onRetry = useCallback(
    async ({ id: requestId, deployment, pipeline, version }) => {
      setRetrying(true);

      const request =
        requestParameters.type === "deployment"
          ? deploymentVersionRequestsGet(
              projectName,
              deployment,
              version,
              requestId
            )
          : pipelineVersionRequestsGet(
              projectName,
              pipeline,
              version,
              requestId
            );

      return request.then(
        (data: DeploymentRequestSingleDetail | PipelineRequestSingleDetail) => {
          setRetrying(false);
          retryRequest(data);
        }
      );
    },
    [requestParameters.type, projectName, retryRequest]
  );

  return { onRetry, retrying };
};
