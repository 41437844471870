import { BaseButtonWithTooltip } from "components/atoms/Button/BaseButtonWithTooltip";

import type { ButtonProps } from "components/atoms/Button/types";
import "./TextButton.scss";

export interface TextButtonProps extends ButtonProps {
  color?: "primary" | "secondary";
}

export const TextButton = ({
  color = "primary",
  style = {},
  ...props
}: TextButtonProps) => {
  return (
    <BaseButtonWithTooltip
      className="TextButton"
      color={color}
      style={{ textTransform: "uppercase", padding: "6px 8px", ...style }}
      {...props}
    />
  );
};
