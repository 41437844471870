import { useState } from "react";
import { useForm } from "react-hook-form";

import { FIELD_NAME, FIELD_CONTACTS } from "libs/constants/fields";
import { useNotificationGroupCreate } from "libs/data/customized/monitoring/useNotificationGroupCreate";
import { useNotificationGroupUpdate } from "libs/data/customized/monitoring/useNotificationGroupUpdate";
import { useGetCurrentProject } from "store/features";

import type {
  NotificationGroupCreate,
  NotificationGroupUpdate,
} from "libs/data/models";

interface UseNotificationGroupsProps {
  isUpdate: boolean;
  selectOrCreate?: boolean;
  requestMode?: boolean;
  request?: { id: string } & Record<string, unknown>;
  updateRequestCallback?: (id: string | undefined, key: string) => void;
  setGroupCallback?: (key: string) => void;
  onCloseCallback?: (key: any) => void;
}
export const useNotificationGroups = ({
  isUpdate,
  selectOrCreate,
  requestMode = false,
  request,
  updateRequestCallback,
  setGroupCallback,
  onCloseCallback,
}: UseNotificationGroupsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDialog = () => setIsOpen(!isOpen);

  const projectName = useGetCurrentProject()?.name;
  const createNotificationGroup = useNotificationGroupCreate(projectName ?? "");
  const updateNotificationGroup = useNotificationGroupUpdate(projectName ?? "");
  const formMethods = useForm({
    mode: "onBlur",
    defaultValues: {
      [FIELD_NAME]: "",
      [FIELD_CONTACTS]: [
        {
          type: {
            label: "Email",
            value: "email",
          },
          value: "",
        },
      ],
    },
    shouldUnregister: false,
  });

  const onAddNotificationGroup = async (data: NotificationGroupCreate) => {
    const newNotificationGroup = {
      [FIELD_NAME]: data[FIELD_NAME],
      [FIELD_CONTACTS]: data[FIELD_CONTACTS],
    };

    const contacts = (newNotificationGroup[FIELD_CONTACTS] ?? []).map(
      (contact) => ({
        type: "email",
        configuration: {
          // @ts-ignore
          email_address: contact?.value,
        },
      })
    );

    const response = await createNotificationGroup({
      name: newNotificationGroup[FIELD_NAME],
      contacts,
    });

    if (response !== undefined) {
      toggleDialog();
    }
  };

  const onUpdateNotificationGroupContacts = async (
    data: NotificationGroupUpdate
  ) => {
    const newNotificationGroup = {
      [FIELD_NAME]: data[FIELD_NAME],
      [FIELD_CONTACTS]: data[FIELD_CONTACTS],
    };

    const contacts = (newNotificationGroup[FIELD_CONTACTS] ?? []).map(
      (contact) => ({
        type: "email",
        configuration: {
          // @ts-ignore
          email_address: contact?.value,
        },
      })
    );

    const response = await updateNotificationGroup(
      newNotificationGroup[FIELD_NAME] as string,
      {
        ...newNotificationGroup,
        contacts,
      }
    );

    if (response !== undefined) {
      toggleDialog();
    }
  };

  const addAndSelectNotificationGroup = (data: NotificationGroupCreate) => {
    onAddNotificationGroup(data);

    setGroupCallback && setGroupCallback(data[FIELD_NAME]);
  };

  const addAndUpdateNotificationGroupForRequest = (
    data: NotificationGroupCreate
  ) => {
    onAddNotificationGroup(data);
    updateRequestCallback &&
      updateRequestCallback(request?.id, data[FIELD_NAME]);
  };

  const selectAndUpdateNotificationGroupForRequest = (notificationGroup: {
    value: string;
  }) => {
    updateRequestCallback &&
      updateRequestCallback(request?.id, notificationGroup?.value);
    toggleDialog();
  };

  const dialogProps = {
    isUpdate,
    selectOrCreate,
    requestMode,
    formMethods: formMethods,
    isOpen,
    toggleDialog,
    updateSubmitCallback: onUpdateNotificationGroupContacts,
    createSubmitCallback: onAddNotificationGroup,
    createAndSelectCallback: addAndSelectNotificationGroup,
    request,
    requestMethods: {
      createAndUpdate: addAndUpdateNotificationGroupForRequest,
      selectAndUpdate: selectAndUpdateNotificationGroupForRequest,
    },
    setGroupCallback: setGroupCallback,
    onCloseCallback,
  };

  return { dialogProps, toggleDialog };
};
