import {
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { DeploymentStepsIllustration } from "assets/images/DeploymentSteps";
import { Programmer } from "assets/images/Programmer";

const useStyles = makeStyles({
  root: {
    width: "100%",
    justifyContent: "center",
    marginBlockEnd: "20px",
  },
  card: {
    width: "60%",
  },
  title: {
    paddingBlockStart: "10px",
  },
  content: {
    display: "flex",
    height: "200px",
  },
  programmerIllu: {
    width: "270px",
    padding: "0px 60px",
  },
  deploymentIllu: {
    width: "300px",
    padding: "15px 35px",
  },
  action: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
});

const content = {
  deploymentCreate: {
    title: "Let's get started",
    description: (
      <Typography variant="h6" paragraph>
        Create a new deployment using the form below or load an example and see
        how it works.
      </Typography>
    ),
  },
  versionCreate: {
    title: "On to the version",
    description: (
      <Typography variant="h6" paragraph>
        {`We've created the deployment now, here we make the version. Normally you
          would upload the code here, but we already did it for you! Just `}
        <Typography component="span" variant="h5" color="secondary">
          click create
        </Typography>
      </Typography>
    ),
  },
};

type FirstDeploymentCardProps = {
  onAction?: () => void;
  versionCreate?: boolean;
};

const FirstDeploymentCard = ({
  onAction,
  versionCreate,
}: FirstDeploymentCardProps) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Card className={classes.card}>
        <Typography className={classes.title} variant="h2" align="center">
          {versionCreate
            ? content.versionCreate.title
            : content.deploymentCreate.title}
        </Typography>
        <CardContent className={classes.content}>
          <Box>
            {versionCreate ? (
              <div className={classes.programmerIllu}>
                <Programmer />
              </div>
            ) : (
              <div className={classes.deploymentIllu}>
                <DeploymentStepsIllustration />
              </div>
            )}
          </Box>
          <div className={classes.action}>
            {versionCreate
              ? content.versionCreate.description
              : content.deploymentCreate.description}

            {onAction && (
              <Button color="secondary" variant="contained" onClick={onAction}>
                Load example
              </Button>
            )}
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default FirstDeploymentCard;
