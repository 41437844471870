import {
  OperatorCollectSubRequests,
  OperatorConditionalLogic,
  OperatorCountMany,
  OperatorCreateSubRequests,
  OperatorFunction,
  OperatorNodeCollectSubRequests,
  OperatorNodeConditionalLogic,
  OperatorNodeCountMany,
  OperatorNodeCreateSubRequests,
  OperatorNodeFunction,
  OperatorNodeRaiseError,
  OperatorRaiseError,
} from "assets/images/operators/index";

import {
  OperatorConditionalForm,
  OperatorFunctionForm,
  OperatorManyToOneForm,
  OperatorOneToManyForm,
  OperatorRaiseErrorForm,
} from "./OperatorsForms";
import { getId } from "./getId";
import { NodeTypes } from "./types";

import type { NodeDataType } from "./types";
import type { OperatorFormProps } from "components/templates/Diagram/OperatorsForms/types";
import type { PipelineVersionObjectConfigurationList } from "libs/data/models";
import type { FunctionComponent, SVGProps } from "react";
import type React from "react";
import type { Node } from "reactflow";

export const DIAGRAM_SIDEBAR_PARENT_ID = "diagram-sidebar";

export const DIAGRAM_DRAG_ID_DEPLOYMENT = "drag-deployment-node";
export const DIAGRAM_DRAG_ID_PIPELINE = "drag-pipeline-node";
export const DIAGRAM_DRAG_ID_OPERATOR = "drag-operator-node";
export const DIAGRAM_DRAG_ID_VARIABLE = "drag-variable-node";

type SVGIconComponentType = FunctionComponent<SVGProps<SVGSVGElement>>;

export type OperatorType = {
  id: string;
  icon: SVGIconComponentType;
  node: SVGIconComponentType;
  title: string;
  subtitle: string;
  description: string;
  form?: React.FC<OperatorFormProps>;
  formTitle?: string;
  defaultConfig?: PipelineVersionObjectConfigurationList;
};

export const OBJECT_REFERENCE_TYPE_OPERATOR = "operator";

export const OPERATOR_IF_CONDITION = "if-condition";
export const OPERATOR_FUNCTION = "function";
export const OPERATOR_ONE_TO_MANY = "one-to-many";
export const OPERATOR_MANY_TO_ONE = "many-to-one";
export const OPERATOR_COUNT_MANY = "count-many";
export const OPERATOR_RAISE_ERROR = "raise-error";
export const OPERATOR_PIPELINE_VARIABLE = "pipeline-variable";

export const operators: OperatorType[] = [
  {
    id: OPERATOR_IF_CONDITION,
    icon: OperatorConditionalLogic,
    node: OperatorNodeConditionalLogic,
    title: "Conditional logic",
    subtitle: "Conditionally trigger the next object",
    description:
      "This operator triggers a next object in a pipeline when its Python expression evaluates to True. It will not output or pass any data.",
    form: OperatorConditionalForm,
    defaultConfig: {
      expression: "",
      input_fields: [],
      output_fields: [],
    },
  },
  {
    id: OPERATOR_FUNCTION,
    icon: OperatorFunction,
    node: OperatorNodeFunction,
    title: "Apply a function",
    subtitle: "Apply single-line expression",
    description:
      "This operator applies a single line expression to its input. You can use any Python expression.",
    form: OperatorFunctionForm,
    defaultConfig: {
      expression: "",
      input_fields: [],
      output_fields: [{ name: "output", data_type: "int" }],
    },
  },
  {
    id: OPERATOR_ONE_TO_MANY,
    icon: OperatorCreateSubRequests,
    node: OperatorNodeCreateSubRequests,
    title: "Create subrequests",
    subtitle: "Split request to multiple subrequests",
    description:
      "This operator splits a list of incoming requests into batches of separate requests to be processed in parallel by the next object. UbiOps sets the optimal batch size automatically.",
    form: OperatorOneToManyForm,
    defaultConfig: {
      //@ts-ignore
      batch_size: null,
      input_fields: [],
      output_fields: [],
    },
  },
  {
    id: OPERATOR_MANY_TO_ONE,
    icon: OperatorCollectSubRequests,
    node: OperatorNodeCollectSubRequests,
    title: "Collect subrequests",
    subtitle: "Combine subrequests to single request",
    description:
      "This operator aggregates all successfully processed subrequests created by the 'create subrequests' operator and combines the results in a single list to be passed to the next object.",
    form: OperatorManyToOneForm,
    defaultConfig: {
      input_fields: [],
      output_fields: [],
    },
  },
  {
    id: OPERATOR_COUNT_MANY,
    icon: OperatorCountMany,
    node: OperatorNodeCountMany,
    title: "Count subrequests",
    subtitle: "Count the number of subrequests",
    description:
      "This operator counts the number of successfully processed subrequests from the previous object and outputs the count as an integer.",
    defaultConfig: {
      input_fields: [],
      output_fields: [{ name: "output", data_type: "int" }],
    },
  },
  {
    id: OPERATOR_RAISE_ERROR,
    icon: OperatorRaiseError,
    node: OperatorNodeRaiseError,
    title: "Raise error",
    subtitle: "Raise an error",
    description:
      "This operator raises an error message and stops the current pipeline when triggered.",
    form: OperatorRaiseErrorForm,
    defaultConfig: {
      error_message: "",
      input_fields: [],
      output_fields: [],
    },
  },
];

export const createNewVariable = (
  organizationName: string,
  projectName: string,
  pipelineName: string,
  versionName: string,
  nodeName: string,
  position: { x: number; y: number }
): Node<NodeDataType> => {
  const id = getId();

  return {
    id,
    type: NodeTypes.variable,
    position: position,
    data: {
      new: false,
      isReadonly: false,
      type: NodeTypes.variable,
      pipelineObject: {
        id,
        name: nodeName,
        reference_name: OPERATOR_PIPELINE_VARIABLE,
        reference_type: OBJECT_REFERENCE_TYPE_OPERATOR,
        version: null,
        input_type: undefined,
        output_type: undefined,
        configuration: {
          input_fields: [],
          output_fields: [{ name: nodeName, data_type: "int" }],
        },
        input_fields: [],
        output_fields: [{ name: nodeName, data_type: "int" }],
      },
      organizationName,
      projectName,
      pipelineName,
      versionName,
    },
  };
};
