import { Typography, Box, Button, Tooltip, useTheme } from "@mui/material";
import { useState } from "react";

import { shadows, spacing } from "assets/styles/theme";
import { useIsMounted } from "libs/hooks";
import {
  getTzAwareDate,
  DATE_TIME_HUMAN_READABLE,
} from "libs/utilities/date-util";

import { Loader } from "components/atoms";

import { PageHeaderExitDialog } from "./TopbarExitDialog";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type { CSSProperties, FC } from "react";

export type PageHeaderProps = {
  pipelineName?: string;
  versionName?: string;
  errors?: string[];
  lastSaved?: string;
  onSave?: () => Promise<unknown>;
  onExit?: () => unknown | Promise<unknown>;
  style?: CSSProperties;
  className?: string;
};

export const PageHeader: FC<PageHeaderProps> = ({
  pipelineName,
  versionName,
  errors = [],
  lastSaved,
  onSave,
  onExit,
  style,
  className,
}) => {
  const isMounted = useIsMounted();
  const theme = useTheme() as AppThemeProps;

  const [isExitModalOpen, setIsExitModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const saveButton = (
    <Button
      color="secondary"
      variant="contained"
      size="large"
      disabled={isLoading || errors.length > 0}
      onClick={() => {
        if (errors.length > 0) return null;

        setIsLoading(true);

        return onSave?.().finally(() => {
          if (isMounted) setIsLoading(false);
        });
      }}
      style={{
        paddingRight: spacing[48],
        paddingLeft: spacing[48],
      }}
    >
      <Typography variant="h5">SAVE</Typography>
    </Button>
  );

  const tooltipTitle = `Unable to save pipeline because there ${
    errors.length === 1 ? `is an error` : `are ${errors.length} errors`
  }. Please fix the errors before saving`;

  return (
    <>
      <PageHeaderExitDialog
        isTouched={true}
        isOpen={isExitModalOpen}
        onClose={() => setIsExitModalOpen(false)}
        onExit={onExit}
      />

      <Box
        padding={`${spacing[12]} ${spacing[48]}`}
        boxShadow={shadows.primaryLight}
        height={spacing[64]}
        display="flex"
        alignItems="center"
        component="header"
        style={{
          background: theme.palette.background.paper,
          ...(style || {}),
        }}
        className={className}
      >
        <>
          <Typography variant="h3" style={{ display: "inline-block" }}>
            {`Editing pipeline`}
          </Typography>

          {pipelineName ? (
            <Typography
              variant="h3"
              color="secondary"
              display="inline"
              style={{ marginRight: spacing[4], marginLeft: spacing[4] }}
            >
              {`‘${pipelineName}’`}
            </Typography>
          ) : (
            <Loader
              display="inline-flex"
              size={spacing[24]}
              mx={spacing[8]}
              style={{
                verticalAlign: "middle",
                marginRight: spacing[4],
                marginLeft: spacing[4],
              }}
            />
          )}

          <Typography
            variant="h3"
            style={{
              display: "inline-block",
            }}
          >
            {`version`}
          </Typography>

          {versionName ? (
            <Typography
              variant="h3"
              color="secondary"
              display="inline"
              style={{ marginRight: spacing[32], marginLeft: spacing[4] }}
            >
              {versionName}
            </Typography>
          ) : (
            <Loader
              display="inline-flex"
              size={spacing[24]}
              mx={spacing[8]}
              style={{
                verticalAlign: "middle",
                marginRight: spacing[4],
                marginLeft: spacing[4],
              }}
            />
          )}
        </>

        <Box display="flex" alignItems="center" ml="auto">
          {!!lastSaved && (
            <>
              <Typography
                variant="h5"
                style={{ color: theme.palette.success.main }}
              >
                Last Saved: &nbsp;
              </Typography>
              <Typography
                variant="h5"
                sx={{ color: theme.palette.pipelineDiagram.dateSaved }}
              >
                {getTzAwareDate(lastSaved).format(DATE_TIME_HUMAN_READABLE)}
              </Typography>
            </>
          )}
          <Box ml={spacing[32]} mr={spacing[32]}>
            {errors?.length > 0 ? (
              <Tooltip title={tooltipTitle}>
                <div>{saveButton}</div>
              </Tooltip>
            ) : (
              saveButton
            )}
          </Box>
          <Box>
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={() => setIsExitModalOpen(true)}
              style={{
                paddingRight: spacing[48],
                paddingLeft: spacing[48],
              }}
            >
              <Typography variant="h5">EXIT</Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
