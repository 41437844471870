import { Checkbox, Link, Tooltip, Typography } from "@mui/material";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { renderTimestamp } from "libs/utilities/date-util";
import { formatStatusLabel } from "libs/utilities/statuses";

import { StatusIcon, TableLink } from "components/atoms";

import { RequestsRowActions } from "./RequestsRowActions";
import { getObjectLink } from "../RequestResultsDialog/utils";

import type { Query, RequestParameter, RequestRow } from "./types";

interface RequestsColumnsProps {
  allowGet: boolean;
  allowCreate: boolean;
  allowDelete: boolean;
  allowLogs: boolean;
  allowUpdate: boolean;
  logsUrl: string;
  requestParameters: RequestParameter;
  selectedRequests: string[];
  setSelectedRequest: (rowData: RequestRow) => void;
  setSelectedRequests: (ids: string[]) => void;
  notificationsDialogToggle: () => void;
  setIsRequestResultsDialogOpen: (isOpen: boolean) => void;
  setIsCancelRequestDialogOpen: (isOpen: boolean) => void;
  setIsDeleteDialogOpen: (isOpen: boolean) => void;
  query?: Query;
  setQuery?: (query: Query) => void;
  refresh: () => void;
  tableOptions?: {
    withoutMultiSelect?: boolean;
    withoutDeleteButton?: boolean;
    withoutNotificationButton?: boolean;
    withoutCancelButton?: boolean;
  };
  displayDeployment?: boolean;
}

export const useRequestsColumns = ({
  allowGet,
  allowCreate,
  allowDelete,
  allowLogs,
  allowUpdate,
  logsUrl,
  requestParameters,
  setSelectedRequest,
  setSelectedRequests,
  selectedRequests,
  notificationsDialogToggle,
  setIsRequestResultsDialogOpen,
  setIsCancelRequestDialogOpen,
  setIsDeleteDialogOpen,
  tableOptions,
  query,
  setQuery,
  refresh,
  displayDeployment = false,
}: RequestsColumnsProps) => {
  const { projectName, organizationName } =
    useParams<{ organizationName: string; projectName: string }>();

  const columns = useMemo(
    () => [
      {
        title: "Status",
        field: "status",
        disableSort: false,
        sorting: false,
        width: 40,
        editable: "never",
        render: (rowData: { status: string }) => (
          <StatusIcon
            label={rowData ? formatStatusLabel(rowData.status) : ""}
            status={rowData?.status}
          />
        ),
      },
      displayDeployment && {
        title: "Deployment",
        field: "deployment",
        width: 160,
        render: (rowData: { deployment: string }) => {
          const deploymentLink = getObjectLink(
            "deployment",
            organizationName,
            projectName,
            rowData.deployment
          );

          return allowGet ? (
            <Tooltip title={rowData.deployment}>
              <TableLink
                to={deploymentLink}
                variant="bold"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "inherit",
                  display: "block",
                }}
              >
                {rowData.deployment}
              </TableLink>
            </Tooltip>
          ) : (
            <Tooltip title={rowData.deployment}>
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "inherit",
                  display: "block",
                }}
              >
                {rowData.deployment}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        title: "Request ID",
        field: "id",
        sorting: false,
        editable: "never",
        nowrap: true,
        width: displayDeployment ? 150 : 310,
        render: (rowData: { id: string }) =>
          allowGet ? (
            <Tooltip title={rowData.id}>
              <Link
                component="button"
                variant="h5"
                align="left"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "inherit",
                }}
              >
                {rowData.id}
              </Link>
            </Tooltip>
          ) : (
            <Tooltip title={rowData.id}>
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "inherit",
                  display: "block",
                }}
              >
                {rowData.id}
              </Typography>
            </Tooltip>
          ),
      },
      {
        title: "Created",
        field: "time_created",
        type: "datetime",
        nowrap: true,
        enableSorting: true,
        width: displayDeployment ? 130 : 240,
        getSortDirection: () => query?.orderDirection || "desc",
        isSortingActive: () => true,
        setSorting: () => {
          if (query?.orderDirection === "asc") {
            setQuery?.({
              ...query,
              orderDirection: undefined,
            });
          } else if (query?.orderDirection === "desc") {
            setQuery?.({
              ...query,
              orderDirection: "asc",
            });
          } else {
            setQuery?.({
              ...query,
              orderDirection: "asc",
            });
          }
        },
        render: (rowData: any) => renderTimestamp(rowData, "time_created"),
      },
      {
        title: "Started",
        field: "time_started",
        sorting: false,
        type: "datetime",
        width: displayDeployment ? 130 : 240,
        nowrap: true,
        render: (rowData: any) => renderTimestamp(rowData, "time_started"),
      },
      {
        title: "Completed",
        field: "time_completed",
        sorting: false,
        type: "datetime",
        width: displayDeployment ? 120 : 200,
        nowrap: true,
        render: (rowData: any) => renderTimestamp(rowData, "time_completed"),
      },
      {
        sorting: false,
        disableClick: true,
        nowrap: true,
        align: "right",
        width: displayDeployment ? 240 : 420,
        cellStyle: { fontSize: "0.9em" },
        render: (rowData: RequestRow) => (
          <RequestsRowActions
            rowData={rowData}
            allowDelete={allowDelete}
            tableOptions={tableOptions}
            allowGet={allowGet}
            requestParameters={requestParameters}
            setSelectedRequest={setSelectedRequest}
            notificationsDialogToggle={notificationsDialogToggle}
            setIsRequestResultsDialogOpen={setIsRequestResultsDialogOpen}
            setIsCancelRequestDialogOpen={setIsCancelRequestDialogOpen}
            setIsDeleteDialogOpen={setIsDeleteDialogOpen}
            allowLogs={allowLogs}
            allowUpdate={allowUpdate}
            allowCreate={allowCreate}
            logsUrl={logsUrl}
            refresh={refresh}
          />
        ),
      },
    ],
    [
      displayDeployment,
      organizationName,
      projectName,
      allowGet,
      query,
      setQuery,
      allowDelete,
      tableOptions,
      requestParameters,
      setSelectedRequest,
      notificationsDialogToggle,
      setIsRequestResultsDialogOpen,
      setIsCancelRequestDialogOpen,
      setIsDeleteDialogOpen,
      allowLogs,
      allowUpdate,
      allowCreate,
      logsUrl,
      refresh,
    ]
  );
  if (tableOptions?.withoutMultiSelect) return columns;

  const multiSelectColumn = {
    title: "",
    field: "",
    type: "checkbox",
    nowrap: true,
    width: 10,
    render: (rowData: RequestRow) => (
      <td
        style={{
          paddingLeft: 5,
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Checkbox
          checked={Boolean(
            rowData?.id && selectedRequests.includes(rowData.id)
          )}
          onChange={(_event, checked) => {
            if (checked && rowData?.id) {
              setSelectedRequests([...selectedRequests, rowData?.id]);
            } else {
              setSelectedRequests(
                selectedRequests.filter((id) => id !== rowData?.id)
              );
            }
          }}
        ></Checkbox>
      </td>
    ),
  };

  return [multiSelectColumn, ...columns];
};
