import makeStyles from "@mui/styles/makeStyles";
import { SnackbarProvider } from "notistack";
import { AlertTriangle, AlertOctagon } from "react-feather";

import { WarningErrorStatus } from "./WarningErrorStatus";

const useStyles = makeStyles({
  snackbar: {
    fontWeight: "bold",
    minWidth: "100px",
  },
  root: {
    position: "absolute",
    top: "unset !important",
    marginTop: 20,
  },
});

type WarningErrorsParentProps = {
  warnings: string[];
  errors: string[];
};

export const WarningErrorsParent = ({
  warnings,
  errors,
}: WarningErrorsParentProps) => {
  const classes = useStyles();

  return (
    <SnackbarProvider
      domRoot={document.getElementById("#diagram-canvas") as HTMLElement}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      preventDuplicate
      dense
      iconVariant={{
        error: <AlertTriangle className="app__notification-icon" />,
        warning: <AlertOctagon className="app__notification-icon" />,
      }}
      classes={{
        variantError: classes.snackbar,
        variantWarning: classes.snackbar,
        containerRoot: classes.root,
      }}
    >
      <WarningErrorStatus warnings={warnings} errors={errors} />
    </SnackbarProvider>
  );
};
