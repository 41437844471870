import { CircularProgress, Box } from "@mui/material";

import type { BoxProps, CircularProgressProps } from "@mui/material";

export const Loader = ({
  size,
  ...props
}: BoxProps & Pick<CircularProgressProps, "size">) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    minHeight="100%"
    width={size}
    height={size}
    {...props}
  >
    <CircularProgress color="secondary" size={size} />
  </Box>
);
