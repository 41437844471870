import { parseConfigInputsOutputsToPipelineObject } from "components/templates/Diagram/EdgeCustom/utils";

import { OPERATOR_PIPELINE_VARIABLE } from "./constants";
import { getId } from "./getId";
import { NodeTypes } from "./types";

import type { NodeDataType } from "./types";
import type {
  PipelineDetail,
  PipelineVersionDetail,
  PipelineVersionObjectList,
} from "libs/data/models";
import type { Node } from "reactflow";

export const getInitialNodes = (
  isReadonly: boolean,
  organizationName: string,
  projectName: string,
  pipeline?: PipelineDetail,
  pipelineVersionDetail?: PipelineVersionDetail
): Node<NodeDataType>[] => {
  if (!pipelineVersionDetail || !pipeline) {
    return [];
  }

  const isObjectPipelineVariable = (obj: PipelineVersionObjectList) =>
    obj.reference_type === "operator" &&
    obj.reference_name === OPERATOR_PIPELINE_VARIABLE;

  return [
    {
      id: NodeTypes.pipeline_start,
      type: NodeTypes.pipeline_start,
      position: { x: 250, y: 0 },
      data: {
        isReadonly,
        type: NodeTypes.pipeline_start,
        organizationName,
        projectName,
        pipelineName: pipelineVersionDetail.pipeline,
        versionName: pipelineVersionDetail.version,
        pipelineObject: {
          id: NodeTypes.pipeline_start,
          name: NodeTypes.pipeline_start,
          version: null,
          reference_name: NodeTypes.pipeline_start,
          output_fields: pipeline.input_fields,
          output_type: pipeline.input_type,
        } as PipelineVersionObjectList,
      },
    },
    // @ts-ignore
    ...((pipelineVersionDetail.objects || []).map((pipelineObject) => {
      const type = isObjectPipelineVariable(pipelineObject)
        ? NodeTypes.variable
        : pipelineObject.reference_type === "operator"
        ? NodeTypes.operator
        : NodeTypes.deployment;

      pipelineObject = parseConfigInputsOutputsToPipelineObject(pipelineObject);

      return {
        id: getId(),
        type,
        position: { x: 250, y: 0 },
        width: 471,
        height: 218,
        data: {
          isReadonly,
          type,
          organizationName,
          projectName,
          pipelineName: pipelineVersionDetail.pipeline,
          versionName: pipelineVersionDetail.version,
          pipelineObject,
        } as unknown as Node<NodeDataType>["data"],
      } as Node<NodeDataType>;
    }) as Node<NodeDataType>[]),
    {
      id: NodeTypes.pipeline_end,
      type: NodeTypes.pipeline_end,
      position: { x: 525, y: 600 },
      data: {
        organizationName,
        projectName,
        pipelineName: pipelineVersionDetail.pipeline,
        versionName: pipelineVersionDetail.version,
        isReadonly,
        type: NodeTypes.pipeline_end,
        pipelineObject: {
          id: NodeTypes.pipeline_end,
          name: NodeTypes.pipeline_end,
          version: null,
          reference_name: NodeTypes.pipeline_end,
          // @ts-ignore
          input_fields: pipeline.output_fields,
          input_type: pipeline.output_type,
        },
      },
    },
  ];
};
