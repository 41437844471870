import { useTheme } from "@mui/styles";
import { groupBy } from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useGetSpecialColors } from "assets/styles/theme/utils/chartColors";
import {
  getMetricAggregation,
  getMetricsGraphOptions,
} from "libs/utilities/metrics-helper";

import type { MetricGraphProps } from "components/molecules";

import { useCachedMetrics } from "../metrics/useCachedMetrics";
import { useGraphType } from "../metrics/useGraphType";

import type { Graph } from "../metrics/useGraphType";
import type { AppThemeProps } from "assets/styles/theme/theme";
import type { TimeSeriesDataList } from "libs/data/models";
import type { Moment } from "moment";

type PipelineMetrics = {
  [key: string]: TimeSeriesDataList[];
};

export const usePipelineMetrics = (
  versionIds: (string | undefined)[],
  initialGraphs: Graph[],
  forceRefresh: boolean,
  startDate?: Moment,
  endDate?: Moment
) => {
  const { projectName } = useParams<{ projectName: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [pipelineMetrics, setPipelineMetrics] =
    useState<PipelineMetrics | null>(null);
  const [metricGraphs, setMetricGraphs] =
    useState<MetricGraphProps[] | null>(null);

  const colors = useGetSpecialColors();
  const theme = useTheme() as AppThemeProps;
  const cachedTimeSeriesDataList = useCachedMetrics(forceRefresh);
  const { graphs, setGraphType } = useGraphType(initialGraphs, "pipeline");

  // convert string array to primitive type
  // to cache the api calls
  const versionIdString = JSON.stringify(versionIds);

  useEffect(() => {
    setIsLoading(true);
    const aggregationPeriod =
      startDate && endDate
        ? getMetricAggregation(startDate, endDate)
        : undefined;
    const timeSeriesPayloads = versionIds
      .map((versionId) =>
        graphs.map(({ metric, isDynamicUnitPeriod, isBarChart }) => {
          const unit_period =
            isDynamicUnitPeriod && isBarChart ? aggregationPeriod : 60;

          return {
            metric: metric[0],
            labels: versionId ? `pipeline_version_id:${versionId}` : undefined,
            start_date: startDate?.seconds(0).milliseconds(0).toISOString(),
            end_date: endDate?.seconds(0).milliseconds(0).toISOString(),
            unit_period,
            aggregation_period: aggregationPeriod,
          };
        })
      )
      .flat();

    Promise.all(
      timeSeriesPayloads.map((payload) =>
        cachedTimeSeriesDataList(projectName, payload)
      )
    )
      .then((metrics) => {
        const metricsByName = groupBy(metrics, "metric");
        setPipelineMetrics(metricsByName);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectName, versionIdString, forceRefresh, graphs, startDate, endDate]);

  useEffect(() => {
    setMetricGraphs(
      graphs?.map((graph) =>
        getMetricsGraphOptions({
          ...graph,
          colors,
          theme,
          startDate: startDate?.toISOString(),
          endDate: endDate?.toISOString(),
          labels: graph?.labels || [graph?.title],
          unit:
            pipelineMetrics?.[
              graph.metric[0] as keyof typeof pipelineMetrics
            ]?.find(({ unit }) => !!unit)?.unit ?? "",
          datasets: graph.metric
            .map(
              (metric) =>
                pipelineMetrics?.[metric as keyof typeof pipelineMetrics]?.map(
                  (dataset) => dataset.data_points
                ) ?? []
            )
            .flat(),
        })
      ) as unknown as MetricGraphProps[]
    );
  }, [pipelineMetrics, colors, graphs, startDate, endDate, theme]);

  return { metricGraphs, isMetricGraphsLoading: isLoading, setGraphType };
};
