import { useTheme } from "@mui/styles";

export const OperatorConditionalLogic = () => {
  const theme = useTheme();

  return (
    <svg
      width="65"
      height="65"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1767_19403)">
        <circle
          cx="40.3902"
          cy="39.5833"
          r="35.5553"
          fill={theme.palette.primary.main}
        />
        <path
          d="M37.574 44.0643H41.2703V47.4966H37.574V44.0643ZM40.0382 24.6145C46.63 24.8662 49.5008 31.0444 45.5827 35.678C44.56 36.8221 42.909 37.5772 42.0958 38.5383C41.2703 39.4879 41.2703 40.632 41.2703 41.7761H37.574C37.574 39.8654 37.574 38.2522 38.3995 37.1081C39.2127 35.964 40.8637 35.289 41.8864 34.5339C44.8681 31.9711 44.1288 28.3443 40.0382 28.0468C39.0579 28.0468 38.1177 28.4084 37.4245 29.0521C36.7313 29.6958 36.3418 30.5688 36.3418 31.4791H32.6455C32.6455 29.6585 33.4244 27.9125 34.8108 26.6251C36.1972 25.3377 38.0775 24.6145 40.0382 24.6145Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1767_19403"
          x="0.834961"
          y="0.5"
          width="79.1104"
          height="79.1106"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1767_19403"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1767_19403"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
