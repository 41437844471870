import Plus from "@mui/icons-material/AddBoxRounded";
import { Box, Grid, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { IlluEmptyCustomEnvironments } from "assets/images/IlluEmptyCustomEnvironments";
import { borderRadius, getBorders } from "assets/styles/theme";
import { BaseUrlContext } from "libs/contexts";
import { useEnvironmentsList } from "libs/data/endpoints/environments/environments";
import { EnvironmentListStatus } from "libs/data/models";
import { DATE_TIME_FORMAT, getTzAwareDate } from "libs/utilities/date-util";
import { explanations } from "libs/utilities/explanations";
import {
  formatLabelsForFilter,
  renderLabels,
} from "libs/utilities/labels-util";

import {
  InfoTooltip,
  Loader,
  PrimaryButton,
  StatusIcon,
  TableLink,
} from "components/atoms";
import { BaseTable } from "components/molecules";
import { onFilterAdd } from "components/organisms";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type { BaseColumn } from "components/molecules/BaseTable";

export const CustomEnvironments = () => {
  const [filters, setFilters] = useState<{ key: string; value: string }[]>([]);
  const baseUrl = useContext(BaseUrlContext);
  const history = useHistory();
  const theme = useTheme() as AppThemeProps;
  const [polling, setPolling] = useState(false);

  const { projectName } = useParams<{ projectName: string }>();
  const { data: environments, error } = useEnvironmentsList(
    projectName,
    {
      environment_type: "custom",
      labels: formatLabelsForFilter(filters),
    },
    {
      swr: {
        refreshInterval: polling ? 3000 : 0,
        swrKey: `/projects/${projectName}/environments`,
      },
    }
  );
  const loading = environments === undefined && !error;

  useEffect(() => {
    const needsToReload = environments?.some(
      (env) => env.status === EnvironmentListStatus.building
    );
    if (needsToReload && !polling) {
      setPolling(true);
    }
    if (!needsToReload && polling) {
      setPolling(false);
    }
  }, [environments, polling]);

  const columns = [
    {
      title: "Name",
      width: "35%",
      field: "display_name",
      render: ({
        display_name,
        name,
      }: {
        display_name: string;
        name: string;
      }) => (
        <TableLink variant="bold" to={`${baseUrl}/custom/${name}/details`}>
          {display_name}
        </TableLink>
      ),
    },
    {
      title: "Status",
      width: "10%",
      field: "status",
      nowrap: true,
      render: ({ status }: { status: string }) => (
        <StatusIcon status={status} label={status} />
      ),
    },
    {
      title: "Created",
      width: "12%",
      field: "creation_date",
      nowrap: true,
      render: ({ creation_date }: { creation_date: string }) =>
        getTzAwareDate(creation_date).format(DATE_TIME_FORMAT),
    },
    {
      title: "Edited",
      width: "12%",
      field: "last_updated",
      nowrap: true,
      defaultSort: "desc",
      render: ({ last_updated }: { last_updated: string }) =>
        getTzAwareDate(last_updated).format(DATE_TIME_FORMAT),
    },
    {
      title: (
        <Grid container alignItems="center" component="span">
          <Grid item component="span">
            <p>Reference Name</p>
          </Grid>
          <Grid item component={InfoTooltip}>
            {explanations.environments.referenceName}
          </Grid>
        </Grid>
      ),
      width: "20%",
      field: "name",
    },
    {
      title: "Labels",
      width: "20%",
      field: "labels",
      render: renderLabels(onFilterAdd(setFilters)),
    },
  ];
  const handleCreate = () => {
    history.push(`${baseUrl}/custom/create`);
  };

  return loading ? (
    <Loader />
  ) : environments?.length ? (
    <BaseTable
      columns={columns as BaseColumn[]}
      data={environments}
      filters={filters}
      setFilters={setFilters}
    />
  ) : (
    <Box
      border={getBorders(theme).primary}
      borderRadius={borderRadius[5]}
      paddingY={8}
      paddingX={24}
      gap={4}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <IlluEmptyCustomEnvironments />
      {explanations.environments.customEnvironmentsEmptyPage.message}
      <PrimaryButton startIcon={<Plus />} onClick={handleCreate}>
        Create custom environment
      </PrimaryButton>
    </Box>
  );
};
