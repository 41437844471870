import { Box } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";

import { spacing } from "assets/styles/theme";
import { TertiaryButton } from "components/atoms/Button/TertiaryButton";
import { Dialog } from "components/atoms/Dialog";
import { DateTimeRangeSelector } from "components/molecules/Fields/DateTimeRangeSelector/DateTimeRangeSelector";
import {
  _FROM,
  _TO,
  defaultRange,
  getPredefinedRanges,
} from "components/molecules/Fields/DateTimeRangeSelector/constants";
import {
  endDateTimeFromDateTimeRange,
  formatDates,
  formRangeToPickerRange,
  predefinedTimeRanges,
  startDateTimeFromDateTimeRange,
  validateRange,
} from "components/molecules/Fields/DateTimeRangeSelector/utils";
import { useDeviceDetect } from "libs/hooks";

import { PrimaryButton } from "components/atoms";

import { DateChip } from "../DatePicker/DateChip";

import type { DateRange, FormRange, InternalDateTimeRange } from "./types";
import type { TimeRangeObject } from "components/molecules/Fields/DateTimeRangeSelector/utils";

export interface DateTimeRangeInputProps {
  name: string;
  handleDateChange?: (dateRange: DateRange) => void;
  hasRangeChip?: boolean;
  minDate?: Date;
  defaultValue?: FormRange;
  onRangeInputClick?: () => void;
  startName?: string;
  endName?: string;
  setDefaultRange?: (value?: InternalDateTimeRange) => void;
  withPredefinedRanges?: boolean;
  customRangeButtonText?: string;
  defaultSelectedRange?: PredefinedTimeRangeString;
}

export type PredefinedTimeRangeString =
  | "Last Week"
  | "Last Day"
  | "Last Hour"
  | "This Month"
  | "Custom";

export const DateTimeRangeInput = ({
  name,
  handleDateChange,
  hasRangeChip = true,
  minDate,
  defaultValue,
  onRangeInputClick,
  startName,
  endName,
  setDefaultRange,
  withPredefinedRanges = true,
  customRangeButtonText = "Custom",
  defaultSelectedRange,
}: DateTimeRangeInputProps) => {
  const { isMobile } = useDeviceDetect();
  const formMethods = useFormContext();
  const [selectedTimeRange, setSelectedTimeRange] =
    useState<PredefinedTimeRangeString | null>(defaultSelectedRange ?? null);
  const from_name = useMemo(() => startName ?? name + _FROM, [startName, name]);
  const to_name = useMemo(() => endName ?? name + _TO, [endName, name]);
  const { register, setValue, unregister, getValues, reset } = formMethods;

  useEffect(() => {
    register({ name });

    return () => unregister(name);
  }, [register, unregister, name]);

  const [open, setOpen] = useState(false);
  const [range, setRange] = useState<InternalDateTimeRange>(
    formRangeToPickerRange(defaultValue) ?? defaultRange
  );
  const [showChip, setShowChip] = useState(!!defaultValue);

  const handleOnFilter = (newRange?: InternalDateTimeRange) => {
    const dateRange = newRange || range;
    const from = startDateTimeFromDateTimeRange(dateRange);
    const to = endDateTimeFromDateTimeRange(dateRange);

    // sometimes `from` date is greater than `to` date because of date conversions
    // eg. between 0-1AM in utc+1 timezone
    // remove one day from start date to correct these invalid ranges
    const areDatesValid = new Date(from).getTime() < new Date(to).getTime();
    const previousStartDate = new Date(dateRange.startDate);
    previousStartDate.setDate(previousStartDate.getDate() - 1);

    const adjustedFrom = areDatesValid
      ? from
      : startDateTimeFromDateTimeRange({
          ...dateRange,
          startDate: previousStartDate,
        });

    setValue(from_name, adjustedFrom);
    setValue(to_name, to);
    handleDateChange && handleDateChange({ from: adjustedFrom, to });
    setOpen(false);
  };

  const clearRange = () => {
    if (setDefaultRange) {
      setDefaultRange(defaultRange);
    } else {
      handleOnFilter(defaultRange);
    }
    setRange(defaultRange);
    setShowChip(false);
    setSelectedTimeRange(null);
    reset();
  };

  const handleOnChange = (
    _range: InternalDateTimeRange,
    rangeString?: PredefinedTimeRangeString
  ) => {
    setRange(_range);

    if (rangeString) {
      setSelectedTimeRange(rangeString);
      setShowChip(false);

      // set selected ranges.
      // recalculate ranges after `Last hour` selection
      // to set minutes based on click time insted of rendered time
      if (rangeString === "Last Hour") {
        const newRanges = getPredefinedRanges();
        handleOnFilter(newRanges[0].range() as InternalDateTimeRange);
      } else handleOnFilter(_range);
    } else {
      setSelectedTimeRange("Custom");
      setShowChip(true);
    }

    if (rangeString === selectedTimeRange) {
      clearRange();
    }
  };

  // remove existing range selection if provided defaultValue is empty
  useEffect(() => {
    if (!defaultValue) {
      setRange(defaultRange);
    }
  }, [defaultValue, setRange]);

  const chipDates = useMemo(
    () => ({
      startDate:
        getValues(from_name) ||
        getValues(name)?.startDate ||
        defaultValue?.startDate,
      endDate:
        getValues(to_name) || getValues(name)?.endDate || defaultValue?.endDate,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getValues(from_name), getValues(to_name), getValues(name), defaultValue]
  );

  const handleDialogClose = () => {
    setOpen(false);
    if (selectedTimeRange !== "Custom") {
      setShowChip(false);
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      <Box
        display="flex"
        alignItems="center"
        columnGap={spacing[4]}
        flexShrink={0}
        marginBottom={spacing[8]}
        marginTop={spacing[8]}
      >
        {withPredefinedRanges &&
          predefinedTimeRanges.map((predefinedRange: TimeRangeObject) => {
            const rangeString = Object.keys(predefinedRange)[0];
            const rangeObject = Object.values(predefinedRange)[0];

            return (
              <TertiaryButton
                key={`timerangeButton-${rangeString}`}
                isSelected={selectedTimeRange === rangeString}
                onClick={() => {
                  handleOnChange(
                    rangeObject,
                    rangeString as PredefinedTimeRangeString
                  );
                }}
              >
                {rangeString}
              </TertiaryButton>
            );
          })}

        <TertiaryButton
          key={"timeRangeButton-custom"}
          isSelected={withPredefinedRanges && selectedTimeRange === "Custom"}
          onClick={() => {
            setOpen(true);
            onRangeInputClick?.();
            setShowChip(true);
          }}
        >
          {customRangeButtonText}
        </TertiaryButton>
      </Box>
      <input type="hidden" name={from_name} ref={register} />
      <input type="hidden" name={to_name} ref={register} />
      <Dialog
        Actions={
          <PrimaryButton
            disabled={!!validateRange(range)}
            onClick={() => handleOnFilter()}
          >
            Filter
          </PrimaryButton>
        }
        onClose={handleDialogClose}
        open={open}
        fullWidth={false}
        id="date-range-picker-dialog"
        maxWidth={!isMobile ? "lg" : undefined}
        dialogBodyStyles={isMobile ? { padding: 0, maxWidth: "90vw" } : {}}
      >
        <DateTimeRangeSelector
          name={name}
          onChange={handleOnChange}
          value={range}
          minDate={minDate}
          isMobile={isMobile}
        />
      </Dialog>

      <Box display="flex" width="110%" justifyContent="flex-end">
        {hasRangeChip && showChip && (
          <DateChip
            dates={formatDates(chipDates.startDate, chipDates.endDate)}
            handleClose={() => clearRange()}
          />
        )}
      </Box>
    </Box>
  );
};
