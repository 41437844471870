import byteSize from "byte-size";
import { isNull, orderBy } from "lodash";
import moment from "moment";
import { Line, Bar } from "react-chartjs-2";

import appTheme from "assets/styles/theme/theme";
import { deploymentMetricsGraphIds } from "libs/data/customized/deployment-metrics";
import { env } from "libs/env";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type { TooltipItem } from "chart.js";
import type {
  TimeSeriesDataListAggregationPeriod,
  TimeSeriesDataPointList,
  UsageDetail,
} from "libs/data/models";
import type { Moment } from "moment";
import type { MonitoringGraph } from "pages/organizations/:organizationName/projects/:projectName/monitoring/general/types";

export const getTotalNumberOfRequests = (requests: number[]) =>
  numberFormatter(sumArrayOfNumbers(requests));

const numberFormatter = (num: number) => {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(2) + "K"; // convert to K for number from > 1000 < 1 million
  } else if (num >= 1000000) {
    return (num / 1000000).toFixed(2) + "M"; // convert to M for number from > 1 million
  } else {
    return roundAccurately(num, 3); // if value < 1000, nothing to do
  }
};
const sumArrayOfNumbers = (numberArray: number[]) =>
  numberArray.reduce((a: number, b: number) => (isNaN(b) ? a : a + b), 0);

export const extractValueFromMetrics = (
  metrics: Array<{ value: number | string }>
) => {
  const data = metrics?.map(({ value }) => value);
  const isAllZero = !data?.some((value) => value !== 0);

  return isAllZero ? [] : data;
};

// gather all the different start dates
export const extractDistinctDatesFromMetrics = (
  datasets: TimeSeriesDataPointList[][],
  propertyName = "start_date" as "start_date" | "end_date"
) => {
  const distinctDates = new Set(
    datasets
      ?.map((dataset) => dataset?.map((data) => data?.[propertyName]))
      ?.flat()
  );

  return [...distinctDates];
};

// fill dataset with null values if there's no value for the given date
// to match datasets with different length by date
export const mapValuesToDates = (
  dataset: TimeSeriesDataPointList[],
  dates: string[],
  propertyName = "start_date" as "start_date" | "end_date"
) => {
  return dates.map((date) => {
    const valueOnDate = dataset.find((data) => data?.[propertyName] === date);

    if (valueOnDate?.value === 0) return 0;

    return valueOnDate?.value || null;
  });
};

const getTooltipLabel = (context: TooltipItem<any>, unit: string) => {
  const value = context.raw as number;
  const label = context.dataset.tooltipLabel ?? context.dataset.label;
  const tooltipValue = unit.includes("bytes")
    ? bytesFormatter(value)
    : numberFormatter(value);

  // hide tooltip if the value is null
  if (isNull(value)) return;

  if (unit.includes("bytes")) {
    return `${label}: ${tooltipValue}${unit.replace("bytes", "")}`;
  }

  return `${label}: ${tooltipValue} ${unit}`;
};

const getTooltipHeader = (context: TooltipItem<any>, unit: string) => {
  // @ts-expect-error context can be an object or array
  const label = context?.label || context?.[0]?.label || "";
  const index = label.indexOf(",");

  return unit === "day" ? label.slice(0, index) : label;
};

// sum every Nth value from an array and find the maximum of this sum list
export const getMaxDatasetValue = (datasets: (number | null)[][]) => {
  const datasetLength = datasets?.[0]?.length || 0;
  const sumValuesByDay = [];
  for (let i = 0; i < datasetLength; i++) {
    const dayValues = datasets.map((dataset) => dataset[i]);
    const sum = dayValues.reduce((a, b) => (a || 0) + (b || 0), 0);
    sumValuesByDay.push(sum);
  }

  return Math.max(...(sumValuesByDay as number[]));
};

// set dynamic units for credit graphs
export const getGraphsWithDynamicUnit = (graphs: MonitoringGraph[]) =>
  graphs.map((graph) => {
    const isCreditGraph =
      graph.metric?.[0] === deploymentMetricsGraphIds.credits;

    return {
      ...graph,
      isDynamicUnitPeriod: graph?.isDynamicUnitPeriod || isCreditGraph,
    };
  });

export const getStoredChartType = (
  storageKey: "pipeline" | "deployment" | "monitoring" | "training",
  metrics: {
    title: string;
    metric: string[];
    isBarChart?: boolean;
    labels?: string[];
  }[]
) => {
  const storedBarCharts = env.get("storedBarCharts") || [];

  return metrics.map((metric) => ({
    ...metric,
    isBarChart: Boolean(
      metric.isBarChart ||
        storedBarCharts.includes(`${storageKey}.${metric.title}`)
    ),
  }));
};

export const getMetricsGraphOptions = ({
  title,
  datasets,
  colors,
  isBarChart = false,
  labels,
  unit,
  startDate,
  endDate,
  theme,
}: {
  title: string;
  datasets: TimeSeriesDataPointList[][] | undefined;
  colors: string[];
  isBarChart?: boolean;
  labels: string[];
  unit?: string;
  startDate?: string;
  endDate?: string;
  theme: AppThemeProps;
}) => {
  const dateLabels = extractDistinctDatesFromMetrics(datasets || []);
  const datasetOptions = isBarChart
    ? getBarChartDatasetOptions
    : getLinearChartDatasetOptions;
  const chartOptions = isBarChart ? getBarChartOptions : getLinearChartOptions;

  const dateDifference = moment(endDate)?.diff(moment(startDate), "hours");

  const isDayRange = dateDifference >= 6 && dateDifference <= 24;
  const isHourRange = dateDifference >= 0 && dateDifference < 6;
  const isMultipeDaysRange = dateDifference > 24;

  const timeUnit =
    (isDayRange && "hour") ||
    (isHourRange && "minute") ||
    (isMultipeDaysRange && "day");

  return {
    data: {
      labels: dateLabels,
      tooltips: dateLabels,
      datasets: datasets?.map((dataset, i) => ({
        data: mapValuesToDates(dataset, dateLabels),
        ...datasetOptions({
          label: labels[i] || title,
          color: colors[i] || colors[0],
        }),
      })),
    },
    options: chartOptions({
      name: unit || title,
      crosshair: true,
      unit: unit,
      startDate,
      endDate,
      timeUnit,
      theme,
      stacked: isBarChart,
    } as any),
    title,
    chartComponent: isBarChart ? Bar : Line,
  };
};

export const getMetricAggregation = (
  startDate: Moment,
  endDate: Moment,
  isBarChart?: boolean
): TimeSeriesDataListAggregationPeriod => {
  const diffInHours = endDate.diff(startDate, "hour");

  // one minute records are deleted after 6 weeks
  const isOlderThanSixWeeks = moment().diff(startDate, "weeks") >= 6;
  if (diffInHours <= 1 && !isOlderThanSixWeeks) {
    // one minute
    return 60;
  } else if (diffInHours <= 1 && isOlderThanSixWeeks) {
    // one hour
    return 3600;
  }

  if (diffInHours <= 24) {
    // one hour
    return 3600;
  }

  if (isBarChart && diffInHours > 24) {
    // one day
    return 86400;
  }

  if (diffInHours < 182) {
    // one hour
    return 3600;
  }

  // one day
  return 86400;
};

export const bytesFormatter = (
  bytes: number | null | undefined,
  placeholder = ""
) => (bytes ? `${byteSize(bytes)}`.toUpperCase() : placeholder);

export const getCompute = (usage: TimeSeriesDataPointList[]) => {
  return usage?.reduce((prev, current) => prev + current.value, 0);
};

export const getComputeUsageDates = (usage: Array<UsageDetail>) => {
  const sortedUsage = orderBy(usage, "start_date", "desc");

  return {
    start_date: sortedUsage?.[0]?.period_start,
    end_date: sortedUsage?.[0]?.period_end,
  };
};

export const roundAccurately = (
  number: number,
  decimalPlaces: number
): number => {
  const roundedValue = Number(
    Math.round(Number(number + "e" + decimalPlaces)) + "e-" + decimalPlaces
  );

  return roundedValue < 0.001 && roundedValue > 0.0001
    ? roundAccurately(number, decimalPlaces + 1)
    : roundedValue;
};

export const getLinearChartDatasetOptions = ({
  label,
  color,
}: {
  label: string;
  color: string;
}) => ({
  label: label,
  lineTension: 0,
  borderCapStyle: "butt",
  borderDash: [],
  borderWidth: 1.3,
  borderDashOffset: 0.0,
  borderJoinStyle: "miter",
  pointBackgroundColor: color,
  pointBorderWidth: 1,
  pointHoverRadius: 3,
  pointHoverBorderWidth: 2,
  pointHoverBorderColor: color,
  pointRadius: 1.5,
  pointHitRadius: 10,
  backgroundColor: color,
  borderColor: color,
  pointBorderColor: color,
  pointHoverBackgroundColor: color,
  fill: false,
});

export const getLinearChartOptions = ({
  name,
  unit = "",
  crosshair = false,
  yAxisPrecision = 5,
  stacked = false,
  timeUnit = "day",
  startDate,
  endDate,
  theme,
}: {
  name: string;
  unit?: string;
  crosshair?: boolean;
  yAxisPrecision?: number;
  stacked?: boolean;
  timeUnit?: string;
  startDate?: string;
  endDate?: string;
  theme: AppThemeProps;
}) => {
  // break lines if there's a no data from a given time range
  const spanGaps =
    (timeUnit === "minute" && 1000 * 60) ||
    (timeUnit === "hour" && 1000 * 60 * 60) ||
    (timeUnit === "day" && 1000 * 60 * 60 * 24);

  const options = {
    maintainAspectRatio: false,
    isBarChart: false,
    spanGaps,
    normalized: true,
    plugins: {
      tooltip: {
        callbacks: {
          header: (context: TooltipItem<any>) =>
            getTooltipHeader(context, timeUnit),
          label: (context: TooltipItem<any>) => getTooltipLabel(context, unit),
        },
      },
      legend: {
        fullSize: true,
        align: "start",
        labels: {
          boxWidth: 20,
          color: theme.palette.charts.legend,
        },
      },
    },
    interaction: {
      intersect: false,
      mode: "index",
    },
    scales: {
      x: {
        type: "time",
        offset: true,
        time: {
          unit: timeUnit,
          displayFormats: {
            day: "MMM dd",
            minute: "HH:mm",
          },
        },
        min: startDate,
        max: endDate,
        ticks: {
          display: true,
          autoSkip: true,
          maxTicksLimit: 10,
          autoSkipPadding: 50,
          color: theme.palette.charts.ticks,
          padding: 8,
          maxRotation: 0,
        },
        grid: {
          drawOnChartArea: false,
          tickLength: 5,
        },
        stacked,
      },
      y: {
        position: "left",
        type: "linear",
        suggestedMin: 0,
        title: {
          display: true,
          text: name,
          color: theme.palette.charts.legend,
        },
        ticks: {
          callback: (value: number) =>
            unit.includes("bytes")
              ? bytesFormatter(value)
              : numberFormatter(value),
          autoSkipPadding: 50,
          precision: yAxisPrecision,
          padding: 8,
          color: theme?.palette.charts.ticks,
        },
        grid: {
          tickLength: 5,
          drawBorder: false,
          color: theme.palette.charts.grid,
        },
        min: 0,
        stacked,
      },
    },
  };

  if (crosshair) {
    options.plugins = {
      ...options.plugins,
      //@ts-ignore
      crosshair: {
        line: {
          color: theme.palette.charts.crosshair,
          width: 1,
        },
        sync: {
          enabled: true,
          group: 1,
          suppressTooltips: false,
        },
        zoom: {
          enabled: false,
        },
      },
    };
  }

  return options;
};

export const getBarChartOptions = ({
  name,
  stacked = false,
  crosshair = false,
  precision = 5,
  unit = "",
  timeUnit = "day",
  startDate,
  endDate,
  theme,
  maxY,
}: {
  name: string;
  stacked: boolean;
  crosshair: boolean;
  precision?: number;
  unit?: string;
  timeUnit?: string;
  startDate?: string;
  endDate?: string;
  theme: AppThemeProps;
  maxY?: number;
}) => {
  const options = {
    maintainAspectRatio: false,
    isBarChart: true,
    plugins: {
      legend: {
        fullSize: true,
        align: "start",
        labels: {
          boxWidth: 20,
          color: theme.palette.charts.legend,
        },
      },

      tooltip: {
        callbacks: {
          title: (context: TooltipItem<any>) =>
            getTooltipHeader(context, timeUnit),
          label: (context: TooltipItem<any>) => getTooltipLabel(context, unit),
        },
      },
      crosshair: {
        line: {
          color: theme.palette.charts.crosshair,
          width: 1,
        },
        sync: {
          enabled: true,
          group: 1,
          suppressTooltips: false,
        },
        zoom: {
          enabled: false,
        },
      },
    },
    interaction: {
      intersect: false,
      mode: "index",
    },
    scales: {
      x: {
        type: "time",
        offset: true,
        time: {
          unit: timeUnit,
          displayFormats: {
            day: "MMM dd",
            minute: "HH:mm",
          },
        },
        min: startDate,
        max: endDate,
        ticks: {
          display: true,
          autoSkip: true,
          maxTicksLimit: 10,
          autoSkipPadding: 10,
          padding: 8,
          maxRotation: 0,
          color: theme.palette.charts.ticks,
        },
        grid: {
          drawOnChartArea: false,
          tickLength: 5,
        },
        stacked,
      },
      y: {
        position: "left",
        type: "linear",
        suggestedMin: 0,
        min: 0,
        max: maxY,
        title: {
          display: true,
          text: name,
          color: theme.palette.charts.legend,
        },
        ticks: {
          callback: (value: number) =>
            unit.includes("bytes")
              ? bytesFormatter(value)
              : numberFormatter(value),
          autoSkipPadding: 50,
          precision,
          padding: 8,
          color: theme.palette.charts.ticks,
        },
        grid: {
          tickLength: 5,
          drawBorder: false,
          color: theme.palette.charts.grid,
        },
        stacked,
      },
    },
  };

  if (crosshair) {
    options.plugins = {
      ...options.plugins,
      crosshair: {
        line: {
          color: theme.palette.charts.crosshair,
          width: 1,
        },
        sync: {
          enabled: true,
          group: 1,
          suppressTooltips: false,
        },
        zoom: {
          enabled: false,
        },
      },
    };
  }

  return options;
};

export const getBarChartDatasetOptions = ({
  label,
  color,
  tooltipLabel,
}: {
  label: string;
  color: string;
  tooltipLabel?: string;
}) => ({
  label: label,
  tooltipLabel: tooltipLabel,
  lineTension: 0,
  borderCapStyle: "butt",
  borderDash: [],
  borderWidth: 1,
  borderDashOffset: 0.0,
  borderJoinStyle: "miter",
  pointBackgroundColor: color,
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointHoverBorderWidth: 2,
  pointHoverBorderColor: appTheme.palette.disabled.tertiaty,
  pointRadius: 1,
  pointHitRadius: 10,
  backgroundColor: color,
  borderColor: color,
  pointBorderColor: color,
  pointHoverBackgroundColor: color,
});

const addChartSpacing = (chart: any) => {
  if (chart.legend)
    chart.legend.afterFit = function () {
      this.height = this.height + 30;
    };
};

export const chartPlugins = [
  {
    beforeInit: (chart: any) => addChartSpacing(chart),
  },
];
