import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { DetailsContainer } from "components/molecules/PageLayout";
import { NOTIFICATION_GROUPS_PERMISSIONS } from "libs/constants/permissions";
import { useOrganizationUser } from "libs/data/customized/user";
import { useGoogleAnalytics } from "libs/hooks";
import { routes } from "routes";
import { useGetPermissions } from "store/features/permissions";

import { PageTabs } from "components/atoms";

import type { ReactElement } from "react";

const Monitoring = ({ children }: MonitoringProps) => {
  useGoogleAnalytics();

  const { organizationName, projectName } =
    useParams<{ organizationName: string; projectName: string }>();
  const { isAdmin } = useOrganizationUser(organizationName);

  const [currentPermissions] = useGetPermissions();

  const baseUrl = useMemo(
    () =>
      routes.organizations[":organizationName"](organizationName).projects[
        ":projectName"
      ](projectName).monitoring,
    [organizationName, projectName]
  );

  const tabs = useMemo(
    () => [
      {
        link: baseUrl.general.index(),
        label: "General",
      },
      {
        link: baseUrl.requests.index(),
        label: "Requests",
      },
      { link: baseUrl.activeInstances.index(), label: "Active instances" },
      {
        link: baseUrl.notificationGroup.index(),
        label: "Notification groups",
        disabled:
          !isAdmin || !currentPermissions[NOTIFICATION_GROUPS_PERMISSIONS.list],
      },
      { link: baseUrl.webHooks.index(), label: "Webhooks" },
      { link: baseUrl.metrics.index(), label: "Metrics" },
      { link: baseUrl.auditEvents.index(), label: "Audit events" },
    ],
    [currentPermissions, isAdmin, baseUrl]
  );

  return (
    <DetailsContainer title="Monitoring">
      <PageTabs tabs={tabs}>{children}</PageTabs>
    </DetailsContainer>
  );
};

type MonitoringProps = {
  children: ReactElement;
};

export default Monitoring;
