import { useTheme } from "@mui/styles";

export const OperatorNodeFunction = () => {
  const theme = useTheme();

  return (
    <svg
      width="85"
      height="76"
      viewBox="0 0 85 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4316_25980)">
        <path
          d="M42.6422 75.7387C63.4314 75.7387 80.2844 58.8858 80.2844 38.0965C80.2844 17.3073 63.4314 0.454346 42.6422 0.454346C21.853 0.454346 5 17.3073 5 38.0965C5 58.8858 21.853 75.7387 42.6422 75.7387Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M42.8647 28.6493C41.3068 28.5083 39.933 29.6499 39.7914 31.2142L39.4374 35.2872H43.4311V38.1059H39.1824L38.5593 45.2512C38.276 48.3517 35.5285 50.6489 32.4128 50.3671C30.6425 50.212 29.0421 49.2396 28.1074 47.7316L30.2318 45.6176C30.6991 47.0974 32.2995 47.9289 33.7724 47.4497C34.8771 47.1115 35.656 46.1391 35.7551 44.9975L36.3499 38.1059H32.1012V35.2872H36.6049L36.9873 30.9606C37.2563 27.86 39.9897 25.5628 43.1196 25.8165C44.904 25.9715 46.5186 26.9581 47.4533 28.4801L45.3289 30.5941C44.9749 29.509 44.0118 28.7479 42.8647 28.6493ZM56.4322 40.4313L54.4353 38.4441L50.4274 42.4325L46.4194 38.4441L44.3942 40.4313L48.4305 44.4479L44.3942 48.4081L46.4194 50.3952L50.4274 46.4068L54.4353 50.3952L56.4322 48.4081L52.4243 44.4479L56.4322 40.4313Z"
          fill="white"
        />
        <path
          d="M80 44.5C82.4853 44.5 84.5 42.4853 84.5 40C84.5 37.5147 82.4853 35.5 80 35.5C77.5147 35.5 75.5 37.5147 75.5 40C75.5 42.4853 77.5147 44.5 80 44.5Z"
          fill={theme.palette.primary.main}
          stroke="white"
        />
        <path
          d="M5 44.5C7.48528 44.5 9.5 42.4853 9.5 40C9.5 37.5147 7.48528 35.5 5 35.5C2.51472 35.5 0.5 37.5147 0.5 40C0.5 42.4853 2.51472 44.5 5 44.5Z"
          fill={theme.palette.primary.main}
          stroke="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4316_25980">
          <rect width="85" height="76" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
