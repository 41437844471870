import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  DEPLOYMENT_PERMISSIONS,
  PIPELINE_PERMISSIONS,
  PROJECT_PERMISSIONS,
} from "libs/constants/permissions";
import { usePermissionValidation } from "libs/data/customized/roles";
import { deploymentVersionsGet } from "libs/data/endpoints/deployments/deployments";
import { pipelineVersionsGet } from "libs/data/endpoints/pipelines/pipelines";
import { useRequestSchedulesGet } from "libs/data/endpoints/request-schedules/request-schedules";
import { useLogsUrl } from "libs/hooks";
import { useGetPermissions } from "store/features/permissions";

import { Loader } from "components/atoms";
import { Requests } from "components/organisms";

import type {
  DeploymentVersionDetail,
  PipelineVersionDetail,
} from "libs/data/models";

export const RequestSchedulePreviousRuns = () => {
  const [version, setVersion] =
    useState<PipelineVersionDetail | DeploymentVersionDetail | null>(null);
  const { projectName, scheduleName } =
    useParams<{
      projectName: string;
      scheduleName: string;
    }>();
  const { data, error } = useRequestSchedulesGet(projectName, scheduleName);
  const isLoading = !data && !error;
  const isDeployment = data?.object_type === "deployment";

  useEffect(() => {
    if (data && data.version) {
      if (isDeployment)
        deploymentVersionsGet(
          projectName,
          data?.object_name ?? "",
          data?.version ?? ""
        ).then((res) => setVersion(res));
      else
        pipelineVersionsGet(
          projectName,
          data?.object_name ?? "",
          data?.version ?? ""
        ).then((res) => setVersion(res));
    }
  }, [isDeployment, data, projectName]);

  const object = data?.object_type;

  const [projectPermissions] = useGetPermissions();

  const [currentPermissions] = usePermissionValidation(
    projectName,
    Object.values(isDeployment ? DEPLOYMENT_PERMISSIONS : PIPELINE_PERMISSIONS),
    data?.object_name,
    isDeployment ? "deployment" : "pipeline"
  );

  const logsUrl = useLogsUrl({
    queryParameters: {
      [`${object}_name`]: data?.object_name,
      [`${object}_version`]: data?.version,
      [`${object}_request_id`]: data?.id,
    },
  });

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Requests
          logsUrl={logsUrl}
          allowCreate={
            currentPermissions[
              PIPELINE_PERMISSIONS["version_request_create"]
            ] ||
            currentPermissions[DEPLOYMENT_PERMISSIONS["version_request_create"]]
          }
          allowGet={
            currentPermissions[PIPELINE_PERMISSIONS["version_request_get"]] ||
            currentPermissions[DEPLOYMENT_PERMISSIONS["version_request_get"]]
          }
          allowLogs={projectPermissions[PROJECT_PERMISSIONS["logs_get"]]}
          allowDelete={
            currentPermissions[
              PIPELINE_PERMISSIONS["version_request_delete"]
            ] ||
            currentPermissions[DEPLOYMENT_PERMISSIONS["version_request_delete"]]
          }
          allowUpdate={
            currentPermissions[DEPLOYMENT_PERMISSIONS["version_request_update"]]
          }
          requestParameters={{
            type: data?.object_type as "pipeline" | "deployment",
            resourceName: data?.object_name,
            version: data?.version,
            requestScheduleName: data?.name,
          }}
          version={version}
        />
      )}
    </>
  );
};
