import Plus from "@mui/icons-material/AddBoxRounded";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";
import * as cronParser from "cron-parser";
import { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";

import { DetailsContainer } from "components/molecules/PageLayout";
import { BaseUrlContext } from "libs/contexts";
import { useRequestSchedulesList } from "libs/data/endpoints/request-schedules/request-schedules";
import { useGoogleAnalytics } from "libs/hooks";
import {
  DATE_TIME_FORMAT,
  DATE_TIME_TZ_FORMAT,
  getTzAwareDate,
} from "libs/utilities/date-util";
import { useGetCurrentOrganization } from "store/features";

import {
  Loader,
  LabelChip,
  ButtonGroup,
  PrimaryButton,
  TableLink,
} from "components/atoms";
import { BaseTable } from "components/molecules";

import type { BaseColumn } from "components/molecules/BaseTable";
import type { ScheduleList } from "libs/data/models";

export const requestScheduleColumns = (
  baseUrl: string,
  isQuickViewBlock = false
) => [
  {
    title: "Name",
    field: "name",
    nowrap: true,
    render: ({ name }: { name: string }) => (
      <TableLink
        variant="bold"
        to={`${baseUrl}/${name}`}
        onClick={(e) => e.stopPropagation()}
      >
        {name}
      </TableLink>
    ),
    sorting: !isQuickViewBlock,
  },
  {
    title: "Object type",
    field: "object_type",
    hidden: isQuickViewBlock,
  },
  {
    title: "Object name",
    field: "object_name",
    nowrap: true,
    sorting: !isQuickViewBlock,
    cellStyle: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      maxWidth: 120,
    },
  },
  {
    title: "Version",
    field: "version",
    nowrap: true,
    sorting: !isQuickViewBlock,
    render: ({ version }: ScheduleList) =>
      version === null ? "default" : version,
  },
  {
    title: "Created",
    field: "creation_date",
    cellStyle: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      maxWidth: 100,
    },
    type: "datetime",
    nowrap: true,
    render: (rowData: ScheduleList) =>
      getTzAwareDate(rowData.creation_date).format(DATE_TIME_FORMAT),
    defaultSort: "desc",
  },
  {
    title: "Next run date",
    field: "schedule",
    type: "datetime",
    nowrap: true,
    cellStyle: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      maxWidth: 100,
    },
    render: ({ schedule, enabled }: ScheduleList) => {
      if (!enabled) {
        return "-";
      }
      const interval = cronParser.parseExpression(schedule ?? "", {
        currentDate: new Date(),
        utc: true,
      });

      return getTzAwareDate(new Date(interval.next().toString())).format(
        DATE_TIME_TZ_FORMAT
      );
    },
    sorting: !isQuickViewBlock,
  },

  {
    title: "Enabled",
    field: "enabled",
    render: (rowData: ScheduleList) =>
      rowData.enabled ? (
        <CheckIcon fontSize="small" color="success" />
      ) : (
        <CloseIcon fontSize="small" color="error" />
      ),
    sorting: !isQuickViewBlock,
  },
  {
    title: "Labels",
    field: "labels",
    render: ({ labels }: ScheduleList) => (
      <Grid container spacing={1}>
        {labels &&
          Object.keys(labels).map((key) => (
            <Grid item key={key}>
              <LabelChip key={key} label={key} value={labels[key]} />
            </Grid>
          ))}
      </Grid>
    ),
  },
];

const RequestSchedulesOverview = () => {
  useGoogleAnalytics();

  const history = useHistory();
  const baseUrl = useContext(BaseUrlContext);
  const { projectName } = useParams<{ projectName: string }>();
  const { data: requestSchedules } = useRequestSchedulesList(projectName);

  const currentOrganization = useGetCurrentOrganization();

  return (
    <DetailsContainer
      title="Request schedules"
      actions={
        <ButtonGroup>
          <PrimaryButton
            startIcon={<Plus />}
            link={`${baseUrl}/create`}
            disabled={currentOrganization?.status !== "active"}
            style={{ marginLeft: "auto" }}
          >
            Create
          </PrimaryButton>
        </ButtonGroup>
      }
    >
      {!requestSchedules ? (
        <Loader />
      ) : (
        <BaseTable
          columns={requestScheduleColumns(baseUrl) as BaseColumn[]}
          data={Object.values(requestSchedules)}
          onRowClick={(_: any, rowData: ScheduleList) =>
            history.push(`${baseUrl}/${rowData.name}`)
          }
        />
      )}
    </DetailsContainer>
  );
};

export default RequestSchedulesOverview;
