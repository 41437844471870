import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";

interface CustomToggleProps {
  isCustom: boolean;
  setCustom: (value: boolean) => void;
  label?: string;
}

export const CustomToggle = ({
  isCustom,
  setCustom,
  label,
}: CustomToggleProps) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column">
      <Typography
        variant="body2"
        component="span"
        marginBottom={theme.spacing(1)}
        marginTop={`-${theme.spacing(1)}`}
      >
        {label}
      </Typography>
      <ToggleButtonGroup
        onChange={() => setCustom(!isCustom)}
        value={isCustom ? "custom" : "all"}
        exclusive
        size="small"
      >
        <ToggleButton
          className={classes.button}
          disableFocusRipple={true}
          value="all"
          sx={{
            color: !isCustom
              ? theme.palette.text.primary
              : theme.palette.text.secondary,
          }}
        >
          Default
        </ToggleButton>
        <ToggleButton
          className={classes.button}
          disableFocusRipple={true}
          value="custom"
          sx={{
            color: isCustom
              ? theme.palette.text.primary
              : theme.palette.text.secondary,
          }}
        >
          Custom
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    height: "auto",
    fontSize: "0.9em",
    padding: "2px 11px",
  },
  active: {
    bacground: theme.palette.primary.main,
  },
}));
