import { useTheme } from "@mui/styles";

export const UploadFileCode = () => {
  const theme = useTheme();

  return (
    <svg
      width="108"
      height="107"
      viewBox="0 0 108 107"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M58.5 40.1253H83.25L58.5 15.6045V40.1253ZM27 8.91699H63L90 35.667V89.167C90 91.5318 89.0518 93.7998 87.364 95.472C85.6761 97.1442 83.3869 98.0836 81 98.0836H27C22.005 98.0836 18 94.0712 18 89.167V17.8337C18 12.8849 22.005 8.91699 27 8.91699ZM27.54 69.1045L44.37 85.7787L50.76 79.4924L40.275 69.1045L50.76 58.7166L44.37 52.4303L27.54 69.1045ZM77.76 69.1045L60.93 52.4303L54.54 58.7166L65.025 69.1045L54.54 79.4924L60.93 85.7787L77.76 69.1045Z"
        fill={theme.palette.primary.light}
      />
    </svg>
  );
};
