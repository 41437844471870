import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { TIME_OUT_FILES, UPLOAD_TASK } from "libs/constants/constants";
import { revisionsFileUpload } from "libs/data/endpoints/deployments/deployments";
import { createErrorNotification } from "libs/utilities/notifications";
import { ERROR, LOADED, LOADING } from "libs/utilities/request-statuses";
import { getRandomId } from "libs/utilities/utils";
import { addTask, updateTask } from "store/features/taskManager";

import type { AxiosError } from "axios";
import type { RevisionsFileUploadBody } from "libs/data/models";

export const useDeploymentVersionsFileUpload = (
  projectName: string,
  deploymentName: string
) => {
  const dispatch = useDispatch();
  const taskId = getRandomId();

  const updateTaskProgress = useCallback(
    (event) =>
      dispatch(
        updateTask({
          id: taskId,
          progress: Math.round((event.loaded * 100) / event.total),
        })
      ),
    [dispatch, taskId]
  );

  return useCallback(
    async (
      version: { version: string; deployment: string; id?: string },
      body: RevisionsFileUploadBody,
      fileName: string
    ) => {
      try {
        dispatch(
          addTask({
            id: taskId,
            message: fileName,
            type: UPLOAD_TASK,
            status: LOADING,
            version,
            progress: 0,
          })
        );
        await revisionsFileUpload(
          projectName,
          deploymentName,
          version.version,
          body,
          { onUploadProgress: updateTaskProgress, timeout: TIME_OUT_FILES }
        );
        dispatch(updateTask({ id: taskId, status: LOADED }));
      } catch (e) {
        dispatch(createErrorNotification((e as AxiosError)?.message));
        dispatch(updateTask({ id: taskId, status: ERROR }));
      }
    },
    [dispatch, taskId, projectName, deploymentName, updateTaskProgress]
  );
};
