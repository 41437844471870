import Plus from "@mui/icons-material/AddBoxRounded";
import { Box, Container, Typography } from "@mui/material";

import { PrimaryButton } from "components/atoms";

import type { ElementType, SVGProps } from "react";

type Props = {
  message: string;
  illustration: ElementType<SVGProps<SVGSVGElement>>;
  isAllowed?: boolean;
  buttonLabel?: string;
  onClick?: () => void;
  children?: React.ReactNode;
};

export const EmptyOverview = ({
  message,
  illustration,
  isAllowed,
  buttonLabel,
  onClick,
  children,
}: Props) => (
  <Box display="flex" alignItems="center" flexDirection="column" p={4}>
    <Box mb={2}>
      <Typography variant="h4">{message}</Typography>
    </Box>
    {isAllowed && (
      <PrimaryButton startIcon={<Plus />} onClick={onClick}>
        {buttonLabel}
      </PrimaryButton>
    )}
    {children}
    <Container sx={{ width: "500px", marginTop: "60px" }}>
      <Box component={illustration} width="100%" maxHeight="300px" mt={8} />
    </Container>
  </Box>
);
