import { useTheme } from "@mui/material";

import type { AppThemeProps } from "assets/styles/theme/theme";

export const IlluAddProject = () => {
  const theme = useTheme() as AppThemeProps;

  return (
    <svg
      width="184"
      height="179"
      viewBox="0 0 184 179"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11600_17010)">
        <path
          d="M173.109 49.4046C171.386 46.294 169.475 43.2919 167.386 40.415C165.922 38.3967 164.375 36.4457 162.746 34.5621H162.742C159.793 31.1522 156.586 27.9748 153.149 25.0582C152.446 24.4602 151.733 23.8745 151.011 23.3011C144.49 18.1084 137.259 13.8789 129.538 10.7418L129.606 22.0351L130.345 150.772H66.3114L62.7102 22.278L62.9785 11.1651C31.5848 24.3815 9.5417 55.4558 9.5417 91.6854C9.53585 99.1634 10.489 106.611 12.3777 113.846C13.1754 116.905 14.1412 119.917 15.2707 122.868C15.6784 123.94 16.1086 125.002 16.5612 126.054C16.7794 126.566 17.0016 127.075 17.232 127.581C17.5276 128.242 17.8355 128.901 18.1515 129.552C18.3961 130.064 18.6476 130.573 18.9058 131.078C19.2381 131.742 19.5813 132.4 19.9354 133.052C20.2086 133.564 20.4899 134.072 20.7754 134.578C25.3803 142.732 31.2606 150.093 38.1927 156.384C40.1682 158.178 42.224 159.879 44.36 161.486C59.4733 172.854 77.8663 179 96.7709 179C115.675 179 134.068 172.854 149.182 161.486C150.344 160.613 151.482 159.71 152.597 158.778C153.337 158.162 154.065 157.534 154.78 156.894C154.97 156.725 155.159 156.556 155.347 156.384C161.738 150.585 167.238 143.872 171.669 136.464C173.297 133.748 174.772 130.944 176.09 128.064C176.438 127.305 176.776 126.539 177.103 125.766C178.607 122.223 179.873 118.583 180.891 114.871C181.048 114.297 181.199 113.722 181.346 113.144V113.142C181.37 113.042 181.396 112.94 181.421 112.84C181.572 112.232 181.717 111.62 181.855 111.007V111.005C182.304 109.032 182.682 107.035 182.991 105.014C183.03 104.755 183.068 104.495 183.105 104.236C183.119 104.142 183.134 104.048 183.144 103.955V103.953C183.364 102.391 183.543 100.819 183.68 99.2343C183.892 96.7465 183.999 94.2303 184 91.6854C184.023 76.8938 180.274 62.3409 173.109 49.4046Z"
          fill={theme.palette.neutrals[100]}
        />
        <path
          d="M142.443 141.815C140.468 140.303 139.135 138.101 138.71 135.649C138.668 135.406 138.293 135.484 138.335 135.727C138.782 138.277 140.174 140.566 142.233 142.135C142.429 142.284 142.638 141.964 142.443 141.815Z"
          fill="white"
        />
        <path
          d="M89.073 29.9502C95.7661 29.9502 101.192 24.5191 101.192 17.8195C101.192 11.1198 95.7661 5.68872 89.073 5.68872C82.3799 5.68872 76.9541 11.1198 76.9541 17.8195C76.9541 24.5191 82.3799 29.9502 89.073 29.9502Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M126.178 17.1865C125.086 16.0318 123.934 14.9364 122.725 13.905C120.364 11.9009 117.75 10.1969 114.837 9.12344C113.74 8.71797 112.606 8.41931 111.452 8.23162C111.314 8.20918 111.177 8.18672 111.038 8.16835C111.028 8.16733 111.018 8.16733 111.008 8.16835C110.986 8.16231 110.965 8.15821 110.943 8.15611C110.659 8.11937 110.376 8.0908 110.09 8.0704C108.553 7.96811 107.01 8.10721 105.515 8.48264C104.648 8.70158 103.802 8.99659 102.987 9.36424C102.171 9.76445 101.379 10.2136 100.616 10.7091C100.207 10.9581 99.7847 11.1863 99.3521 11.3928C98.0891 11.9918 96.7522 12.42 95.3765 12.6662C94.4474 12.8331 93.5067 12.9259 92.563 12.9438C91.7434 12.9601 90.9707 12.9887 90.1939 13.2846C89.3649 13.5964 88.6203 14.098 88.0195 14.7493C87.4188 15.4006 86.9785 16.1835 86.7341 17.0355C86.7148 17.1112 86.7202 17.191 86.7496 17.2634C86.7789 17.3357 86.8307 17.3968 86.8972 17.4376C87.1072 17.5886 87.311 17.76 87.5333 17.8947C87.7294 17.9843 87.9387 18.0415 88.1531 18.0641C88.5037 18.1375 88.8551 18.209 89.2071 18.2784C90.5017 18.5355 91.8018 18.7634 93.1073 18.962C95.7333 19.36 98.3742 19.6402 101.03 19.8028C101.046 19.8052 101.063 19.8066 101.079 19.8069C104.079 19.9882 107.087 20.0175 110.09 19.8947C110.545 19.8763 110.998 19.8538 111.452 19.8273C113.346 19.7212 115.236 19.5538 117.12 19.3253C118.863 19.113 120.6 18.8498 122.329 18.5355C123.565 18.311 124.795 18.0607 126.021 17.7845C126.081 17.7669 126.135 17.7341 126.178 17.6895C126.221 17.6449 126.252 17.5899 126.268 17.5298C126.284 17.4697 126.284 17.4065 126.268 17.3464C126.252 17.2863 126.222 17.2312 126.178 17.1865Z"
          fill={theme.palette.neutrals[200]}
        />
        <path
          d="M100.989 33.3616C99.2347 31.483 97.3017 29.7797 95.2174 28.276C94.5263 27.7903 93.8148 27.3332 93.0828 26.9148C92.1855 26.3963 91.2536 25.9402 90.2938 25.5495C90.0797 25.4618 89.8636 25.3781 89.6475 25.2985C88.4197 24.8433 87.1462 24.5231 85.8493 24.3434C85.8391 24.3414 85.8289 24.3434 85.8187 24.3414C85.7983 24.3373 85.7759 24.3332 85.7555 24.3292C85.1268 24.2475 84.4933 24.2087 83.8594 24.2128C83.4048 24.2128 82.9481 24.2373 82.4955 24.2822C81.7642 24.3543 81.0392 24.4798 80.3262 24.6577C79.9735 24.7455 79.6228 24.8475 79.2783 24.9638C78.7758 25.1294 78.2824 25.3215 77.8002 25.5393C77.792 25.5434 77.7859 25.5455 77.7778 25.5495C76.9683 25.9459 76.1839 26.3916 75.4291 26.8842C75.4128 26.8944 75.3944 26.9046 75.3781 26.9148C74.5207 27.4282 73.6136 27.8535 72.6706 28.1842C71.8612 28.4675 71.0309 28.6872 70.1874 28.8413C69.2584 29.0086 68.3176 29.1007 67.3739 29.1168C66.5543 29.1331 65.7816 29.1617 65.0068 29.4597C64.1776 29.7714 63.4326 30.2729 62.8316 30.9242C62.2305 31.5755 61.7899 32.3585 61.545 33.2106C61.5261 33.2863 61.5317 33.366 61.561 33.4383C61.5904 33.5105 61.6419 33.5716 61.7081 33.6126C61.9181 33.7637 62.124 33.933 62.3442 34.0677C62.5401 34.158 62.7495 34.2152 62.964 34.2371C63.3147 34.3106 63.6653 34.3841 64.018 34.4534C65.3127 34.7106 66.6134 34.9378 67.9202 35.1351C69.7123 35.4065 71.5119 35.6235 73.3189 35.7861C74.1752 35.8657 75.0322 35.931 75.8898 35.982C78.0897 36.1146 80.2916 36.1663 82.4955 36.1371C82.9501 36.131 83.4048 36.1208 83.8594 36.1065C86.5588 36.0269 89.25 35.8242 91.933 35.4983C94.9236 35.1361 97.8939 34.6219 100.832 33.9575C100.892 33.9401 100.946 33.9076 100.989 33.8632C101.032 33.8187 101.063 33.7639 101.079 33.7039C101.095 33.644 101.095 33.581 101.079 33.521C101.063 33.461 101.032 33.4061 100.989 33.3616Z"
          fill={theme.palette.neutrals[200]}
        />
        <path
          d="M70.7819 0.805356L81.4584 148.488L60.1055 150.385V2.6843C62.6499 0.694853 66.3023 0.3836 70.7819 0.805356Z"
          fill={theme.palette.neutrals[300]}
        />
        <path
          d="M123.483 0.805356L112.806 148.488L134.159 150.385V2.6843C131.615 0.694853 127.962 0.3836 123.483 0.805356Z"
          fill={theme.palette.neutrals[300]}
        />
        <path
          d="M134.662 2.74878C133.999 1.12264 132.59 0 130.961 0H63.076C60.8109 0 58.9699 2.17542 58.9699 4.85193V151.636C58.9501 152.994 59.4573 154.307 60.3848 155.298C60.87 155.808 61.4845 156.177 62.1626 156.365C62.46 156.448 62.7673 156.489 63.076 156.488H130.961C131.27 156.489 131.577 156.448 131.875 156.365C133.701 155.874 135.067 153.942 135.067 151.636V4.85193C135.069 4.13122 134.931 3.41698 134.662 2.74878ZM133.705 151.636C133.705 153.424 132.474 154.879 130.961 154.879H63.076C61.8221 154.879 60.7599 153.879 60.4357 152.518C60.3665 152.229 60.3316 151.933 60.3318 151.636V4.85193C60.3318 3.06438 61.5632 1.6117 63.076 1.6117H130.961C131.24 1.61148 131.516 1.66125 131.777 1.75865C132.892 2.1682 133.705 3.39925 133.705 4.85193V151.636Z"
          fill={theme.palette.neutrals[300]}
        />
        <path
          d="M134.386 44.6173H59.6512V45.9816H134.386V44.6173Z"
          fill={theme.palette.neutrals[300]}
        />
        <path
          d="M134.386 16.4546H59.6512V17.8189H134.386V16.4546Z"
          fill={theme.palette.neutrals[300]}
        />
        <path
          d="M134.386 67.3555H59.6512V68.7198H134.386V67.3555Z"
          fill={theme.palette.neutrals[300]}
        />
        <path
          d="M83.9034 0.612183H82.5405V86.6828H83.9034V0.612183Z"
          fill={theme.palette.neutrals[300]}
        />
        <path
          d="M111.497 0.612183H110.134V86.6828H111.497V0.612183Z"
          fill={theme.palette.neutrals[300]}
        />
        <path
          d="M142.904 147.962H53.404C51.7781 147.964 50.2194 148.611 49.0697 149.762C47.92 150.913 47.2733 152.473 47.2714 154.101C47.2705 154.883 47.4208 155.659 47.7138 156.384C48.1691 157.522 48.9544 158.498 49.9685 159.185C50.9827 159.873 52.1792 160.241 53.404 160.241H142.904C143.975 160.241 145.027 159.96 145.955 159.427C146.884 158.893 147.657 158.126 148.197 157.201C148.351 156.939 148.484 156.666 148.597 156.384C148.89 155.659 149.04 154.883 149.039 154.101C149.037 152.473 148.389 150.913 147.239 149.762C146.089 148.611 144.53 147.964 142.904 147.962Z"
          fill={theme.palette.neutrals[300]}
        />
        <path
          d="M134.386 83.4995H60.1055V88.0471H134.386V83.4995Z"
          fill={theme.palette.neutrals[300]}
        />
        <path
          d="M96.5644 82.1353H97.9274C98.5901 82.1353 99.2256 82.3988 99.6942 82.8678C100.163 83.3369 100.426 83.9731 100.426 84.6364H94.0657C94.0657 84.308 94.1303 83.9827 94.2559 83.6793C94.3814 83.3758 94.5655 83.1001 94.7975 82.8678C95.0296 82.6356 95.305 82.4513 95.6082 82.3256C95.9113 82.1999 96.2363 82.1353 96.5644 82.1353Z"
          fill={theme.palette.neutrals[300]}
        />
        <path
          d="M98.5563 96.3249C106.331 96.3249 112.634 90.0161 112.634 82.2338C112.634 74.4515 106.331 68.1427 98.5563 68.1427C90.7816 68.1427 84.479 74.4515 84.479 82.2338C84.479 90.0161 90.7816 96.3249 98.5563 96.3249Z"
          fill="#805E5E"
        />
        <path
          d="M82.8526 68.6781C82.9591 67.0599 84.0281 65.404 87.4247 64.5388C87.4247 64.5388 93.2539 57.0775 101.175 60.9903C101.175 60.9903 105.015 60.329 107.863 64.3171C107.863 64.3171 110.686 62.6505 111.877 65.6047C111.877 65.6047 115.188 71.6306 113.969 77.777C112.75 83.9235 112.126 84.6905 112.126 84.6905C112.126 84.6905 113.68 71.9767 104.73 72.8583C95.7809 73.7399 88.5757 70.5896 86.549 77.4871C84.8661 83.2146 85.0781 86.1892 85.0781 86.1892C85.0781 86.1892 79.4504 80.4755 81.3638 75.9765C82.3425 73.6754 82.6885 71.1736 82.8526 68.6781Z"
          fill="#2F2E41"
        />
        <path
          d="M60.2746 159.241H138.14C137.62 153.296 137.279 130.569 137.279 129.073C137.279 122.476 131.886 115.277 131.886 115.277L129.956 114.208L113.835 109.174L109.663 103.75C109.226 103.18 108.664 102.719 108.019 102.404C107.374 102.089 106.665 101.928 105.948 101.934L92.7691 102.033C91.6094 102.042 90.4948 102.484 89.6438 103.272L81.4831 110.85L69.5139 116.572L69.4935 116.551L69.3685 116.644L67.5922 117.925L66.4496 118.751C66.4496 118.751 63.4522 122.351 61.0566 133.148C60.6961 134.771 60.4432 155.793 60.2746 159.241Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M156.163 156.384C155.975 156.556 155.785 156.725 155.596 156.894C154.878 157.533 154.15 158.161 153.412 158.778C152.297 159.709 151.159 160.611 149.997 161.486H45.1755C43.0409 159.882 40.9851 158.181 39.0082 156.384H156.163Z"
          fill={theme.palette.neutrals[300]}
        />
        <path
          d="M117.74 157.609H79.2068L80.0222 153.731H116.924L117.74 157.609Z"
          fill="#2F2E41"
        />
        <path
          d="M152.601 140.874H142.574C142.348 140.874 142.124 140.923 141.919 141.018C141.713 141.113 141.531 141.252 141.385 141.425C141.246 141.398 141.104 141.385 140.961 141.384C140.649 141.384 140.339 141.446 140.051 141.565C139.762 141.685 139.499 141.86 139.278 142.081C139.057 142.302 138.882 142.565 138.762 142.853C138.642 143.142 138.581 143.452 138.581 143.765C138.581 144.078 138.642 144.388 138.762 144.677C138.882 144.966 139.057 145.228 139.278 145.449C139.499 145.67 139.762 145.846 140.051 145.965C140.339 146.085 140.649 146.146 140.961 146.146C141.32 146.146 141.675 146.063 141.997 145.905L142.164 147.962L142.829 156.092C142.86 156.194 142.903 156.292 142.955 156.384C143.09 156.631 143.288 156.837 143.529 156.981C143.771 157.124 144.047 157.2 144.327 157.2H150.847C151.129 157.2 151.404 157.124 151.646 156.981C151.888 156.837 152.087 156.631 152.222 156.384C152.273 156.291 152.314 156.193 152.346 156.092L154.099 142.905C154.171 142.67 154.187 142.422 154.146 142.18C154.105 141.938 154.008 141.709 153.862 141.511C153.716 141.314 153.526 141.153 153.307 141.043C153.088 140.932 152.846 140.874 152.601 140.874ZM140.961 145.466C140.511 145.465 140.079 145.286 139.761 144.967C139.443 144.648 139.264 144.216 139.264 143.765C139.264 143.314 139.443 142.882 139.761 142.563C140.079 142.244 140.511 142.065 140.961 142.064C140.992 142.064 141.021 142.072 141.051 142.074C140.984 142.348 140.992 142.635 141.076 142.905L141.793 145.239C141.54 145.387 141.254 145.465 140.961 145.466Z"
          fill="#3F3D56"
        />
        <path
          d="M129.954 97.7261H66.4828C65.9374 97.7269 65.4147 97.9442 65.0291 98.3301C64.6435 98.7161 64.4265 99.2394 64.4256 99.7852V142.658C64.4262 143.204 64.6431 143.727 65.0287 144.113C65.4144 144.499 65.9373 144.716 66.4828 144.717H129.954C130.499 144.716 131.022 144.499 131.408 144.113C131.794 143.727 132.011 143.204 132.011 142.658V99.7852C132.01 99.2394 131.793 98.7161 131.408 98.3301C131.022 97.9442 130.499 97.7269 129.954 97.7261Z"
          fill="#3F3D56"
        />
        <path
          d="M105.366 132.078H90.5179C90.3079 132.079 90.1065 132.163 89.9582 132.311C89.8099 132.46 89.7267 132.662 89.7269 132.872V156.351H106.159V132.872C106.159 132.768 106.139 132.665 106.099 132.568C106.059 132.472 106.001 132.385 105.927 132.311C105.854 132.237 105.766 132.179 105.67 132.139C105.574 132.099 105.471 132.078 105.366 132.078ZM97.9921 145.419C97.5277 145.417 97.083 145.231 96.7547 144.903C96.4263 144.574 96.2409 144.129 96.2387 143.664V140.956C96.2393 140.491 96.4242 140.045 96.753 139.716C97.0817 139.387 97.5274 139.203 97.9921 139.203C98.4568 139.203 98.9024 139.387 99.2312 139.716C99.5599 140.045 99.7449 140.491 99.7454 140.956V143.664C99.7433 144.129 99.5579 144.574 99.2295 144.903C98.9012 145.231 98.4564 145.417 97.9921 145.419Z"
          fill="#2F2E41"
        />
        <path
          d="M89.6759 155.6V157.866C89.6761 157.983 89.7228 158.096 89.8057 158.179C89.8887 158.262 90.0011 158.308 90.1183 158.309H105.768C105.885 158.308 105.997 158.261 106.08 158.178C106.163 158.095 106.21 157.983 106.21 157.866V155.6H89.6759Z"
          fill="#3F3D56"
        />
        <path
          d="M128.604 142.21C129.158 142.21 129.606 141.761 129.606 141.207C129.606 140.653 129.158 140.204 128.604 140.204C128.051 140.204 127.602 140.653 127.602 141.207C127.602 141.761 128.051 142.21 128.604 142.21Z"
          fill="#2F2E41"
        />
        <path
          d="M74.4952 139.752C74.4491 139.752 74.4035 139.761 74.361 139.779C74.3184 139.796 74.2798 139.822 74.2472 139.855C74.2146 139.887 74.1888 139.926 74.1712 139.969C74.1536 140.011 74.1445 140.057 74.1445 140.103V141.909C74.1447 142.002 74.1818 142.091 74.2475 142.157C74.3132 142.222 74.4023 142.259 74.4952 142.259C74.588 142.259 74.6771 142.222 74.7428 142.157C74.8085 142.091 74.8456 142.002 74.8458 141.909V140.103C74.8458 140.057 74.8368 140.011 74.8191 139.969C74.8015 139.926 74.7757 139.887 74.7431 139.855C74.7106 139.822 74.6719 139.796 74.6294 139.779C74.5868 139.761 74.5412 139.752 74.4952 139.752Z"
          fill="#2F2E41"
        />
        <path
          d="M76.3994 139.752C76.3533 139.752 76.3077 139.761 76.2652 139.779C76.2226 139.796 76.184 139.822 76.1514 139.855C76.1189 139.887 76.093 139.926 76.0754 139.969C76.0578 140.011 76.0487 140.057 76.0487 140.103V141.909C76.0487 142.002 76.0857 142.091 76.1514 142.157C76.2172 142.223 76.3064 142.26 76.3994 142.26C76.4924 142.26 76.5816 142.223 76.6474 142.157C76.7131 142.091 76.7501 142.002 76.7501 141.909V140.103C76.7501 140.057 76.741 140.011 76.7234 139.969C76.7057 139.926 76.6799 139.887 76.6474 139.855C76.6148 139.822 76.5761 139.796 76.5336 139.779C76.491 139.761 76.4454 139.752 76.3994 139.752Z"
          fill="#2F2E41"
        />
        <path
          d="M78.3036 139.752C78.2576 139.752 78.212 139.761 78.1694 139.779C78.1269 139.796 78.0882 139.822 78.0556 139.855C78.0231 139.887 77.9972 139.926 77.9796 139.969C77.962 140.011 77.9529 140.057 77.9529 140.103V141.909C77.9532 142.002 77.9902 142.091 78.056 142.157C78.1217 142.222 78.2108 142.259 78.3036 142.259C78.3965 142.259 78.4855 142.222 78.5512 142.157C78.617 142.091 78.654 142.002 78.6543 141.909V140.103C78.6543 140.057 78.6452 140.011 78.6276 139.969C78.61 139.926 78.5841 139.887 78.5516 139.855C78.519 139.822 78.4803 139.796 78.4378 139.779C78.3953 139.761 78.3497 139.752 78.3036 139.752Z"
          fill="#2F2E41"
        />
        <path
          d="M80.2078 139.752C80.1618 139.752 80.1162 139.761 80.0736 139.779C80.0311 139.796 79.9924 139.822 79.9599 139.855C79.9273 139.887 79.9015 139.926 79.8838 139.969C79.8662 140.011 79.8571 140.057 79.8571 140.103V141.909C79.8574 142.002 79.8944 142.091 79.9602 142.157C80.0259 142.222 80.115 142.259 80.2078 142.259C80.3007 142.259 80.3897 142.222 80.4555 142.157C80.5212 142.091 80.5582 142.002 80.5585 141.909V140.103C80.5585 140.057 80.5494 140.011 80.5318 139.969C80.5142 139.926 80.4883 139.887 80.4558 139.855C80.4232 139.822 80.3846 139.796 80.342 139.779C80.2995 139.761 80.2539 139.752 80.2078 139.752Z"
          fill="#2F2E41"
        />
        <path
          d="M81.7271 156.588C84.6547 156.588 87.0279 154.213 87.0279 151.282C87.0279 148.352 84.6547 145.976 81.7271 145.976C78.7996 145.976 76.4263 148.352 76.4263 151.282C76.4263 154.213 78.7996 156.588 81.7271 156.588Z"
          fill="#805E5E"
        />
        <path
          d="M118.759 157.609C121.687 157.609 124.06 155.233 124.06 152.303C124.06 149.372 121.687 146.997 118.759 146.997C115.832 146.997 113.458 149.372 113.458 152.303C113.458 155.233 115.832 157.609 118.759 157.609Z"
          fill="#805E5E"
        />
      </g>
      <defs>
        <clipPath id="clip0_11600_17010">
          <rect width="184" height="179" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
