import { Box, Typography, Grid } from "@mui/material";
import { Code, dracula } from "react-code-blocks";
import { useEdges, useNodes } from "reactflow";

import { spacing } from "assets/styles/theme";

import {
  OPERATOR_FUNCTION,
  OPERATOR_IF_CONDITION,
  OPERATOR_MANY_TO_ONE,
  OPERATOR_ONE_TO_MANY,
  OPERATOR_RAISE_ERROR,
} from "../constants";

import type { OperatorType } from "../constants";
import type { EdgeDataType, NodeDataType } from "../types";
import type { PipelineVersionObjectConfigurationList } from "libs/data/models";

type OperatorConfigurationInfoProps = {
  operator: OperatorType;
  configuration: PipelineVersionObjectConfigurationList | undefined;
  id?: string;
};

export const OperatorConfigurationInfo = ({
  operator,
  configuration,
  id,
}: OperatorConfigurationInfoProps) => {
  const nodes = useNodes<NodeDataType>();
  const attachments = useEdges<EdgeDataType>();

  const attachment = attachments?.find(
    (attachment) => attachment.source === id
  );

  const target = nodes.find((obj) => obj.id === attachment?.target);
  const targetObjectName = target?.data?.pipelineObject?.name;

  return (
    <>
      <Typography variant="h6" color="textPrimary">
        Configuration
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        margin={`${spacing[12]} 0`}
      >
        {(operator?.id === OPERATOR_IF_CONDITION ||
          operator?.id === OPERATOR_FUNCTION) && (
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item>
              <Typography variant="subtitle2" color="textSecondary">
                Expression
              </Typography>
            </Grid>
            <Grid item>
              <Code
                customStyle={{ padding: "4px 8px" }}
                // todo: remove this once this has been addressed:
                // https://github.com/rajinwonderland/react-code-blocks/issues/95
                // @ts-ignore
                text={configuration?.expression}
                language="python"
                theme={dracula}
              />
            </Grid>
          </Grid>
        )}

        {operator?.id === OPERATOR_ONE_TO_MANY && (
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item>
              <Typography variant="subtitle2" color="textSecondary">
                Batch size
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle2" style={{ fontStyle: "italic" }}>
                {configuration?.batch_size}
              </Typography>
            </Grid>
          </Grid>
        )}
        {operator?.id === OPERATOR_MANY_TO_ONE && (
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item>
              <Typography variant="subtitle2" color="textSecondary">
                Destination object
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle2" style={{ fontStyle: "italic" }}>
                {targetObjectName}
              </Typography>
            </Grid>
          </Grid>
        )}

        {operator?.id === OPERATOR_RAISE_ERROR && (
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item>
              <Typography variant="subtitle2" color="textSecondary">
                Error message
              </Typography>
            </Grid>
            <Grid item>
              <Code
                customStyle={{ padding: "4px 8px" }}
                // todo: remove this once this has been addressed:
                // https://github.com/rajinwonderland/react-code-blocks/issues/95
                // @ts-ignore
                text={configuration?.error_message}
                theme={dracula}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};
