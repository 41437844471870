import {
  Typography,
  Grid,
  Card,
  CardContent,
  Switch,
  Box,
} from "@mui/material";
import CronParser from "cron-parser";
import { useContext, useEffect, useState } from "react";
import { LogIn } from "react-feather";
import { useParams } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { RootUrlContext } from "libs/contexts";
import { useRequestScheduleUpdate } from "libs/data/customized/request-schedules";
import { useDeploymentsGet } from "libs/data/endpoints/deployments/deployments";
import { usePipelinesGet } from "libs/data/endpoints/pipelines/pipelines";
import { useRequestSchedulesGet } from "libs/data/endpoints/request-schedules/request-schedules";
import {
  DATE_TIME_FORMAT,
  DATE_TIME_TZ_FORMAT,
  getTzAwareDate,
} from "libs/utilities/date-util";
import { formatLabels } from "libs/utilities/labels-util";

import { Alert, DetailsItem, Link } from "components/atoms";
import { DescriptionBlock, ResultsField } from "components/molecules";

import type { DeploymentDetail, PipelineDetail } from "libs/data/models";

const RequestScheduleGeneral = () => {
  const [object, setObject] =
    useState<DeploymentDetail | PipelineDetail | null>(null);
  const [nextRunDate, setNextRunDate] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);

  const rootUrl = useContext(RootUrlContext);
  const { organizationName, projectName, scheduleName } =
    useParams<{
      organizationName: string;
      projectName: string;
      scheduleName: string;
    }>();
  const {
    data: requestSchedule,
    error,
    mutate,
  } = useRequestSchedulesGet(projectName, scheduleName);
  const isLoading = !requestSchedule && !error;

  const updateRequestSchedule = useRequestScheduleUpdate(projectName);

  const { data: pipeline } = usePipelinesGet(
    projectName,
    requestSchedule?.object_name ?? "",
    { swr: { enabled: requestSchedule?.object_type === "pipeline" } }
  );

  const { data: deployment } = useDeploymentsGet(
    projectName,
    requestSchedule?.object_name ?? "",
    { swr: { enabled: requestSchedule?.object_type === "deployment" } }
  );

  useEffect(() => {
    if (deployment) {
      setObject(deployment);
    } else if (pipeline) {
      setObject(pipeline);
    }
  }, [deployment, pipeline]);

  useEffect(() => {
    if (!isLoading && requestSchedule?.schedule) {
      const interval = CronParser.parseExpression(requestSchedule?.schedule, {
        currentDate: new Date(),
        utc: true,
      });
      setNextRunDate(interval.next().toString());
    }
  }, [requestSchedule?.schedule, isLoading]);

  const handleChange = async (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setIsUpdating(true);
    await updateRequestSchedule(scheduleName, { enabled: checked });
    await mutate();
    setIsUpdating(false);
  };

  return (
    <>
      {requestSchedule?.enabled === false && (
        <Alert variant="filled" severity="warning">
          This schedule is currently disabled and will not trigger until you
          enable it.
        </Alert>
      )}
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Card variant="outlined">
            <CardContent>
              <DetailsItem title="Object type">
                <Typography>{requestSchedule?.object_type}</Typography>
              </DetailsItem>
              <DetailsItem title="Object name">
                <Link
                  to={`${rootUrl}/${requestSchedule?.object_type}s/${requestSchedule?.object_name}`}
                >
                  {requestSchedule?.object_name}
                </Link>
              </DetailsItem>
              <DetailsItem title="Version">
                <Link
                  to={`${rootUrl}/${requestSchedule?.object_type}s/${
                    requestSchedule?.object_name
                  }/versions/${requestSchedule?.version ?? ""}`}
                >
                  {requestSchedule?.version ?? (
                    <Box component="span" fontStyle="italic">
                      default
                    </Box>
                  )}
                </Link>
              </DetailsItem>
              <DetailsItem title="Schedule">
                <Typography>{requestSchedule?.schedule}</Typography>
              </DetailsItem>
              <DetailsItem title="Next run date">
                <Typography>
                  {requestSchedule?.enabled
                    ? getTzAwareDate(new Date(nextRunDate || "")).format(
                        DATE_TIME_TZ_FORMAT
                      )
                    : "-"}
                </Typography>
              </DetailsItem>
              <DetailsItem title="Timeout">
                <Typography>{requestSchedule?.timeout}</Typography>
              </DetailsItem>
              <DetailsItem title="Created">
                <Typography>
                  {getTzAwareDate(requestSchedule?.creation_date).format(
                    DATE_TIME_FORMAT
                  )}
                </Typography>
              </DetailsItem>
              <DetailsItem title="Enabled">
                <Switch
                  checked={requestSchedule?.enabled}
                  onChange={handleChange}
                  size="small"
                  color="secondary"
                  disabled={isUpdating}
                />
              </DetailsItem>
            </CardContent>
          </Card>
        </Grid>
        {requestSchedule?.request_data && (
          <Grid item xs={6}>
            <Card variant="outlined">
              <CardContent>
                <Box display="flex" gap={spacing[8]} marginBottom={2}>
                  <Grid item component={Box} display="flex">
                    <LogIn size={20} />
                  </Grid>
                  <Grid item xs component={Typography} variant="h3">
                    Request data
                  </Grid>
                </Box>
                {object && (
                  <ResultsField
                    fields={object?.input_fields}
                    data={requestSchedule?.request_data}
                    organizationName={organizationName}
                    projectName={projectName}
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
        )}
        <DescriptionBlock
          description={requestSchedule?.description}
          labels={formatLabels(requestSchedule?.labels || {})}
        />
      </Grid>
    </>
  );
};

export default RequestScheduleGeneral;
