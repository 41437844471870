import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "store/store";

export type Notification = {
  message: string;
  key: string;
  dismissed: boolean;
  options: {
    key?: string;
    variant: "default" | "success" | "error" | "info" | "warning";
    onClose?: (event: any, reason: any, key: string) => void;
    action?: (key: string) => void;
    onExited?: (event: any, key: string) => void;
  };
};

export interface Notifications {
  notifications: Notification[];
}

const initialState: Notifications = {
  notifications: [],
};

export const notifications = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    enqueueSnackbar: (state, action: PayloadAction<Notification>) => {
      state.notifications.unshift(action.payload);
    },
    removeSnackbar: (state, action: PayloadAction<string>) => {
      state.notifications = state.notifications
        .slice()
        .filter((notif) => notif.key !== action.payload);
    },
    closeSnackbar: (state, action: PayloadAction<string | null>) => {
      state.notifications = state.notifications
        .slice()
        .map((notification) =>
          !action.payload || notification.key === action.payload
            ? { ...notification, dismissed: true }
            : { ...notification }
        );
    },
  },
});

export const useGetNotifications = () => {
  return useSelector((store: RootState) => store.notifications.notifications);
};

export const { enqueueSnackbar, removeSnackbar, closeSnackbar } =
  notifications.actions;

export default notifications.reducer;
