import { Grid } from "@mui/material";
import { get } from "lodash";
import { useMemo } from "react";

import { AutoCompleteSelect } from "components/atoms/AutoCompleteSelect/AutoCompleteSelect";

import { InfoTooltip } from "components/atoms";

import { parseErrorFromControl } from "./utils";

import type {
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  SxProps,
} from "@mui/material";
import type { AutoCompleteSelectProps } from "components/atoms/AutoCompleteSelect";
import type { AutocompleteSelectOption } from "components/atoms/AutoCompleteSelect/types";
import type { FormikProps, FormikValues } from "formik";
import type { HTMLAttributes, ReactNode } from "react";

export type FormikSelectProps = Omit<
  AutoCompleteSelectProps,
  "error" | "name" | "onChange"
> & {
  control: FormikProps<FormikValues>;
  fastField?: boolean;
  name: string;
  showErrors?: boolean;
  disabled?: boolean;
  styles?: SxProps;
  onChange?: (selectedOption: AutocompleteSelectOption | null) => void;
  onInputChange?: (value: string) => void;
  renderOption?: (
    props: HTMLAttributes<HTMLLIElement>,
    option: AutocompleteSelectOption,
    state: AutocompleteRenderOptionState
  ) => ReactNode;
  renderInput?: (params: AutocompleteRenderInputParams) => JSX.Element;
  tooltip?: string;
  freeSolo?: boolean;
  disableInputChange?: boolean;
};

export const FormikSelect = ({
  control,
  name,
  showErrors = true,
  onChange,
  options,
  renderOption,
  onInputChange,
  tooltip,
  disableInputChange = false,
  ...props
}: FormikSelectProps) => {
  const error = useMemo(
    () => parseErrorFromControl(control, name),
    [control, name]
  );

  const selectValue = useMemo(
    () =>
      options.find((option) => option.value === get(control.values, name)) ||
      null,
    [control.values, name, options]
  );

  return (
    <Grid container alignItems={"center"}>
      <Grid item xs>
        <AutoCompleteSelect
          value={selectValue}
          onChange={async (newValue: AutocompleteSelectOption | null) => {
            await control.setFieldValue(name, newValue?.value);
            await control.setFieldTouched(name, true);
            onChange?.(newValue);
          }}
          onInputChange={async (newValue: string) => {
            if (!disableInputChange) {
              await control.setFieldValue(name, newValue);
              await control.setFieldTouched(name, true);
              onInputChange?.(newValue);
            }
          }}
          options={options}
          renderOption={renderOption}
          error={showErrors ? error : undefined}
          showErrors={showErrors}
          {...props}
        />
      </Grid>
      {tooltip && (
        <Grid component={InfoTooltip} item xs={1}>
          {tooltip}
        </Grid>
      )}
    </Grid>
  );
};
