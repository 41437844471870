import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  Typography,
  AccordionSummary,
  Grid,
  Box,
  useTheme,
} from "@mui/material";
import { sortBy } from "lodash";
import { useState, useEffect, useCallback, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";

import { useAccordionStyles } from "assets/styles/componentStyles";
import { spacing } from "assets/styles/theme";
import { PageHeader } from "components/molecules/PageLayout";
import { PortForwarding } from "components/molecules/PortForwarding";
import {
  DEFAULT_DEPLOYMENT_VERSION_IDLE_TIME,
  DEFAULT_DEPLOYMENT_VERSION_INSTANCE_TYPE,
  DEFAULT_MAXIMUM_QUEUE_SIZE_BATCH,
  DEFAULT_MAXIMUM_QUEUE_SIZE_EXPRESS,
} from "libs/constants/constants";
import {
  FIELD_VERSION,
  FIELD_DESCRIPTION,
  FIELD_DEPLOYMENT_VERSION_ENVIRONMENT,
  FIELD_DEPLOYMENT_VERSION_INSTANCE_TYPE,
  FIELD_DEPLOYMENT_VERSION_FILE,
  FIELD_LABELS,
  FIELD_RETENTION_MODE,
  FIELD_RETENTION_TIME,
  FIELD_DEPLOYMENT_VERSION_MIN_DEPLOY,
  FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY,
  FIELD_DEPLOYMENT_VERSION_IDLE_TIME,
  FIELD_MONITORING_GROUP,
  FIELD_DEFAULT_MONITORING_GROUP,
  FIELD_MONITORING_GROUP_ENABLED,
  FIELD_DEFAULT_MONITORING_GROUP_ENABLED,
  FIELD_MAXIMUM_QUEUE_SIZE_EXPRESS,
  FIELD_MAXIMUM_QUEUE_SIZE_BATCH,
  FIELD_STATIC_IP,
  FIELD_GPU_INSTANCE_ENABLED,
  FIELD_DEPLOYMENT_VERSION_FILE_HAS_REQUIREMENTS,
  FIELD_DEPLOYMENT_VERSION_DEPLOYMENT_PORT,
  FIELD_DEPLOYMENT_VERSION_PUBLIC_PORT,
  FIELD_DEPLOYMENT_VERSION_PROTOCOL,
} from "libs/constants/fields";
import { useDeploymentVersionsCreate } from "libs/data/customized/deployment-versions/useDeploymentVersionsCreate";
import { useDeploymentVersionsFileUpload } from "libs/data/customized/deployment-versions/useDeploymentVersionsFileUpload";
import { useDeploymentVersionsList } from "libs/data/endpoints/deployments/deployments";
import { useNotificationGroupsList } from "libs/data/endpoints/monitoring/monitoring";
import {
  useOrganizationsFeaturesGet,
  useOrganizationsResourceUsage,
} from "libs/data/endpoints/organizations/organizations";
import { gtmEvent, useGoogleAnalytics } from "libs/hooks";
import { explanations } from "libs/utilities/explanations";
import { defaultLanguageOption } from "libs/utilities/labels-mapping";
import {
  formatLabels,
  formatLabelsForRequest,
} from "libs/utilities/labels-util";
import { UNLOADED, LOADING } from "libs/utilities/request-statuses";
import { generateVersionName } from "libs/utilities/utils";
import validators from "libs/utilities/validators";
import { routes } from "routes";

import { Loader, Notes } from "components/atoms";
import {
  FormSection,
  FirstDeploymentCard,
  DeploymentVersionAdvancedParameters,
  GeneralFieldsSection,
  PageContainer,
  NetworkSettings,
  DeploymentVersionInstanceType,
  UploadPackageField,
  CodeEnvironmentSelect,
  DependencyInfo,
} from "components/molecules";
import {
  FormContainer,
  LabelsForm,
  RequestSettings,
  NotificationGroupsForm,
  UpgradeSubscriptionDialog,
} from "components/organisms";

import type {
  DeploymentVersionList,
  DeploymentVersionPort,
  TemplateDeploymentList,
} from "libs/data/models";
import type { DeploymentVersionCreateForm } from "pages/organizations/:organizationName/projects/:projectName/deployments/:deploymentName/versions/types";
import type { ProjectDetailsRouteParams } from "pages/organizations/:organizationName/projects/:projectName/types";
import type { ReactNode } from "react";

const defaultValues = {
  [FIELD_VERSION]: "v1",
  [FIELD_DEPLOYMENT_VERSION_ENVIRONMENT]: defaultLanguageOption,
  [FIELD_DEPLOYMENT_VERSION_INSTANCE_TYPE]:
    DEFAULT_DEPLOYMENT_VERSION_INSTANCE_TYPE,
  [FIELD_DEPLOYMENT_VERSION_MIN_DEPLOY]: 0,
  [FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY]: 1,
  [FIELD_DEPLOYMENT_VERSION_IDLE_TIME]: DEFAULT_DEPLOYMENT_VERSION_IDLE_TIME,
  [FIELD_MONITORING_GROUP]: null,
  [FIELD_DEFAULT_MONITORING_GROUP]: null,
  [FIELD_MAXIMUM_QUEUE_SIZE_EXPRESS]: DEFAULT_MAXIMUM_QUEUE_SIZE_EXPRESS,
  [FIELD_MAXIMUM_QUEUE_SIZE_BATCH]: DEFAULT_MAXIMUM_QUEUE_SIZE_BATCH,
} as any;

interface DeploymentVersionCreateProps {
  children?: ReactNode;
  deployment?: string; // This would only be used within the DeploymentCreate form, where a route param is not available
  isPage?: boolean;
  preloadedDeploymentChoice?: TemplateDeploymentList | undefined;
}

export const DeploymentVersionCreate = ({
  children,
  deployment,
  isPage = true,
  preloadedDeploymentChoice,
}: DeploymentVersionCreateProps) => {
  useGoogleAnalytics();
  const classes = useAccordionStyles();
  const theme = useTheme();
  const history = useHistory();
  const {
    organizationName,
    projectName,
    deploymentName: deploymentNameFromUrl,
  } = useParams<ProjectDetailsRouteParams & { deploymentName?: string }>();
  const deploymentName = (deployment ?? deploymentNameFromUrl) as string;

  const versionCreate = useDeploymentVersionsCreate(
    projectName,
    deploymentName,
    "deployment version"
  );
  const fileUpload = useDeploymentVersionsFileUpload(
    projectName,
    deploymentName
  );

  const [nameDefaultValue, setNameDefaultValue] =
    useState<string | undefined>(undefined);
  const [descriptionDefaultValue, setDescriptionDefaultValue] =
    useState(undefined);
  const [envDefaultValue, setEnvDefaultValue] =
    useState<string | undefined>(undefined);
  const [instanceDefaultValue, setInstanceDefaultValue] =
    useState<string | undefined>(undefined);
  const [defaultReqRetentionMode, setDefaultReqRetentionMode] = useState("");
  const [defaultReqRetentionTime, setDefaultReqRetentionTime] =
    useState<number | undefined>(undefined);
  const [deploymentTemplateId, setDeploymentTemplateId] = useState("");
  const [correctZipStructure, setCorrectZipStructure] = useState(false);
  const [isSubscriptionDialogOpen, setIsSubscriptionDialogOpen] =
    useState(false);
  const [latestVersion, setLatestVersion] =
    useState<DeploymentVersionList | null>(null);

  const { data: versions, error: versionsError } = useDeploymentVersionsList(
    projectName,
    deploymentName
  );
  const { data: organizationFeatures } =
    useOrganizationsFeaturesGet(organizationName);
  const gpuEnabled = organizationFeatures?.resource_gpu ?? false;

  const { data: organizationResources } =
    useOrganizationsResourceUsage(organizationName);

  const { data: notificationGroups } = useNotificationGroupsList(projectName);

  const methods = useForm({
    mode: "onBlur",
    shouldUnregister: false,
    defaultValues: {
      [FIELD_MONITORING_GROUP_ENABLED]: false,
      [FIELD_DEFAULT_MONITORING_GROUP_ENABLED]: false,
      [FIELD_MAXIMUM_QUEUE_SIZE_EXPRESS]: DEFAULT_MAXIMUM_QUEUE_SIZE_EXPRESS,
      [FIELD_MAXIMUM_QUEUE_SIZE_BATCH]: DEFAULT_MAXIMUM_QUEUE_SIZE_BATCH,
    } as DeploymentVersionCreateForm,
  });
  const { control, setValue } = methods;
  const versionFile = useWatch({
    control,
    name: FIELD_DEPLOYMENT_VERSION_FILE,
  }) as any;
  const fileHasRequirements = useWatch({
    control,
    name: FIELD_DEPLOYMENT_VERSION_FILE_HAS_REQUIREMENTS,
  });
  const gpuInstanceModeEnabled = useWatch({
    control,
    name: FIELD_GPU_INSTANCE_ENABLED,
  });

  const setPreloadedDeploymentVersionData = useCallback(() => {
    const version = preloadedDeploymentChoice?.details?.version;
    setNameDefaultValue(version.name);
    setDescriptionDefaultValue(version.description);
    setDefaultReqRetentionMode(version.request_retention_mode);
    setDefaultReqRetentionTime(version.request_retention_time);
    setLatestVersion(version);
    setEnvDefaultValue(version.environment);
    setInstanceDefaultValue(version.instance_type_group_name);
    setValue(FIELD_LABELS, formatLabels(version.labels ?? {}));
    setDeploymentTemplateId(preloadedDeploymentChoice?.id ?? "");
  }, [
    preloadedDeploymentChoice?.details?.version,
    preloadedDeploymentChoice?.id,
    setValue,
  ]);

  useEffect(() => {
    if (
      organizationResources?.deployment_versions &&
      organizationFeatures?.max_deployment_versions &&
      organizationResources?.deployment_versions >=
        organizationFeatures?.max_deployment_versions
    ) {
      setIsSubscriptionDialogOpen(true);
    }
  }, [
    organizationResources,
    organizationFeatures,
    setIsSubscriptionDialogOpen,
  ]);

  useEffect(() => {
    if (versions?.length && isPage) {
      const sortedVersions = sortBy(versions, ["creation_date"]);
      const latestVersion = sortedVersions[sortedVersions.length - 1];
      setLatestVersion(latestVersion);
      setNameDefaultValue(generateVersionName(latestVersion.version));
    } else if (preloadedDeploymentChoice) {
      setPreloadedDeploymentVersionData();
    } else {
      setLatestVersion(defaultValues);
      setNameDefaultValue(defaultValues[FIELD_VERSION]);
    }
  }, [
    isPage,
    preloadedDeploymentChoice,
    setPreloadedDeploymentVersionData,
    versions,
  ]);

  const handleVersionUpload = useCallback(
    (name) => {
      gtmEvent("deployment_version_create", {
        event_category: "deployment_versions",
      });

      if (versionFile?.length) {
        fileUpload(
          {
            version: name,
            deployment: deploymentName,
          },
          {
            file: versionFile[0],
          },
          versionFile[0]?.name
        );
      } else {
        fileUpload(
          {
            version: name,
            deployment: deploymentName,
          },
          { template_deployment_id: deploymentTemplateId },
          nameDefaultValue as string
        );
      }

      const baseUrl = routes.organizations[":organizationName"](
        organizationName
      )
        .projects[":projectName"](projectName)
        .deployments[":deploymentName"](deploymentName);

      const redirectUrl = deployment
        ? baseUrl.index()
        : baseUrl.versions[":versionName"](name).index();

      history.replace(redirectUrl);
    },
    [
      versionFile,
      organizationName,
      projectName,
      deploymentName,
      deployment,
      history,
      fileUpload,
      deploymentTemplateId,
      nameDefaultValue,
    ]
  );

  const onSubmit = async (data: any) => {
    const newVersion = {
      [FIELD_VERSION]: data[FIELD_VERSION],
      [FIELD_DEPLOYMENT_VERSION_ENVIRONMENT]:
        data[FIELD_DEPLOYMENT_VERSION_ENVIRONMENT].value,
      [FIELD_RETENTION_MODE]: data[FIELD_RETENTION_MODE].value,
      [FIELD_RETENTION_TIME]: data[FIELD_RETENTION_TIME]?.value,
      [FIELD_DEPLOYMENT_VERSION_INSTANCE_TYPE]:
        data[FIELD_DEPLOYMENT_VERSION_INSTANCE_TYPE]?.value,
      [FIELD_DEPLOYMENT_VERSION_MIN_DEPLOY]:
        data[FIELD_DEPLOYMENT_VERSION_MIN_DEPLOY],
      [FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY]:
        data[FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY],
      [FIELD_DEPLOYMENT_VERSION_IDLE_TIME]:
        data[FIELD_DEPLOYMENT_VERSION_IDLE_TIME],
      [FIELD_DESCRIPTION]: data[FIELD_DESCRIPTION],
      [FIELD_LABELS]: formatLabelsForRequest(data[FIELD_LABELS]),
      [FIELD_MONITORING_GROUP]: data[FIELD_MONITORING_GROUP]?.value ?? null,
      [FIELD_DEFAULT_MONITORING_GROUP]:
        data[FIELD_DEFAULT_MONITORING_GROUP]?.value ?? null,
      [FIELD_MAXIMUM_QUEUE_SIZE_EXPRESS]:
        data[FIELD_MAXIMUM_QUEUE_SIZE_EXPRESS],
      [FIELD_MAXIMUM_QUEUE_SIZE_BATCH]: data[FIELD_MAXIMUM_QUEUE_SIZE_BATCH],
      [FIELD_STATIC_IP]: data[FIELD_STATIC_IP],
      ports: [] as DeploymentVersionPort[],
    };
    // @ts-ignore
    delete newVersion[FIELD_DEPLOYMENT_VERSION_FILE];

    if (
      data[FIELD_DEPLOYMENT_VERSION_DEPLOYMENT_PORT] &&
      data[FIELD_DEPLOYMENT_VERSION_PUBLIC_PORT] &&
      !data[FIELD_STATIC_IP]
    ) {
      newVersion.ports = [
        {
          deployment_port: data[FIELD_DEPLOYMENT_VERSION_DEPLOYMENT_PORT],
          public_port: data[FIELD_DEPLOYMENT_VERSION_PUBLIC_PORT],
          protocol: data[FIELD_DEPLOYMENT_VERSION_PROTOCOL] || "tcp",
        },
      ] as DeploymentVersionPort[];
    }

    const response = await versionCreate(newVersion);
    if (response) handleVersionUpload(newVersion.version);
  };

  const parentFileFieldValidationChecker = useCallback(
    // Workaround function to pass props from child to parent
    (flag) => setCorrectZipStructure(flag),
    []
  );

  const fileFieldRules = useMemo(() => {
    if (!preloadedDeploymentChoice)
      return {
        required: validators.required.message("version package"),
        validate: {
          correctZipStructure: () => correctZipStructure,
        },
      };

    return null;
  }, [preloadedDeploymentChoice, correctZipStructure]);

  return (
    <PageContainer>
      {isPage && <PageHeader title="Create new version" />}
      <FormContainer
        onSubmit={onSubmit}
        buttonLabel="Create"
        formMethods={methods}
        status={!versions && !versionsError ? LOADING : UNLOADED}
      >
        {latestVersion && nameDefaultValue ? (
          <>
            {preloadedDeploymentChoice && <FirstDeploymentCard versionCreate />}

            <GeneralFieldsSection
              name={FIELD_VERSION}
              label="Version name"
              ruleName="version name"
              validateValue="deployment version"
              namePlaceholder="Ex: my-deployment-version-1"
              nameDefaultValue={nameDefaultValue}
              descriptionDefaultValue={descriptionDefaultValue}
              title="Name your version"
              description={explanations.deployments.templates}
              info={
                <Typography variant="body2">
                  Your deployment name: <b>{deployment ?? deploymentName}</b>
                </Typography>
              }
              notes={
                latestVersion !== defaultValues &&
                !preloadedDeploymentChoice && (
                  <Notes>
                    Your latest version was: <b>{latestVersion.version}</b>
                  </Notes>
                )
              }
            />

            <FormSection
              title="Deployment package"
              description={explanations.deployments.configuration}
            >
              <div className="required-field-with-asterisk">
                <UploadPackageField
                  hasRequirementsName={
                    FIELD_DEPLOYMENT_VERSION_FILE_HAS_REQUIREMENTS
                  }
                  name={FIELD_DEPLOYMENT_VERSION_FILE}
                  rules={fileFieldRules}
                  label="Deployment package (.zip)"
                  passFormValidation={parentFileFieldValidationChecker}
                  withLoadedExample={!!preloadedDeploymentChoice}
                />
              </div>{" "}
              {fileHasRequirements && <DependencyInfo />}
            </FormSection>

            <FormSection
              title="Environment settings"
              description={
                explanations.deployments.versions.environmentSettings
              }
            >
              <DeploymentVersionInstanceType
                preLoadedDefaultInstanceType={instanceDefaultValue}
                disableGPUToggle={!gpuEnabled}
              />
              <Grid item mt={-2}>
                <Typography variant="h6">Select code environment</Typography>
                <Box maxWidth={spacing[310]} mt={2}>
                  <CodeEnvironmentSelect
                    customEnvironmentsVisible={fileHasRequirements !== true}
                    defaultEnvironment={envDefaultValue}
                    deployment={deploymentName}
                    gpuEnabled={
                      (gpuEnabled as boolean) &&
                      (gpuInstanceModeEnabled as boolean)
                    }
                    name={FIELD_DEPLOYMENT_VERSION_ENVIRONMENT}
                    projectName={projectName}
                  />
                </Box>
              </Grid>
            </FormSection>

            <div className={classes.accordionContainer}>
              <Accordion className={classes.accordionWrapper}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  classes={{ content: classes.accSummaryContent }}
                  sx={{ "& svg": { fill: theme.palette.text.primary } }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      color: theme.palette.text.primary,
                    }}
                  >
                    Optional / Advanced settings
                  </Typography>
                </AccordionSummary>
                <Grid
                  container
                  spacing={5}
                  className={classes.advancedSettingsGrid}
                >
                  {children}

                  <RequestSettings
                    defaultMode={defaultReqRetentionMode}
                    defaultTime={defaultReqRetentionTime}
                    defaultQueueSize={DEFAULT_MAXIMUM_QUEUE_SIZE_EXPRESS}
                    defaultBatchQueueSize={DEFAULT_MAXIMUM_QUEUE_SIZE_BATCH}
                  />

                  <NetworkSettings />

                  <DeploymentVersionAdvancedParameters
                    version={latestVersion}
                  />

                  <NotificationGroupsForm
                    notificationGroups={notificationGroups}
                  />

                  <PortForwarding />

                  <FormSection
                    title="Labels"
                    description={explanations.labels.description(
                      "deploymentVersion"
                    )}
                  >
                    <LabelsForm name={FIELD_LABELS} />
                  </FormSection>
                </Grid>
              </Accordion>
            </div>
          </>
        ) : (
          <Loader />
        )}
      </FormContainer>
      <UpgradeSubscriptionDialog
        open={isSubscriptionDialogOpen}
        onClose={() => setIsSubscriptionDialogOpen(false)}
      >
        You have reached your current subscription limit of{" "}
        <b>
          {organizationFeatures?.max_deployment_versions} maximum deployment
          versions
        </b>
        . Please upgrade your subscription.
      </UpgradeSubscriptionDialog>
    </PageContainer>
  );
};
