import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box, Button, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { FIELD_TRAINING_SCRIPT } from "libs/constants/fields";
import { useRoleAssignmentsPerObjectList } from "libs/data/endpoints/roles/roles";
import { UploadFileDialog } from "pages/organizations/:organizationName/projects/:projectName/storage/buckets/:bucketName/dialogs/UploadFileDialog";
import { BucketTreeView } from "pages/organizations/:organizationName/projects/:projectName/storage/buckets/BucketTreeView";

import { Icon } from "components/atoms";

type FileInputFieldProps = {
  label: string;
  name: string;
  objectName?: string;
  objectType?: string;
  defaultFileName?: string;
};

export const FileInputField = ({
  label,
  name,
  objectName,
  objectType,
  defaultFileName,
}: FileInputFieldProps) => {
  const { setValue, register } = useFormContext();

  const [isUploadFileDialogOpen, setIsUploadFileDialogOpen] = useState(false);
  const [
    isBucketSelectForFileUploadDialogOpen,
    setIsBucketSelectForFileUploadDialogOpen,
  ] = useState(false);
  const [isExistingFileSelectDialogOpen, setIsExistingFileSelectDialogOpen] =
    useState(false);
  const [selectedBucket, setSelectedBucket] = useState("");
  const { projectName } = useParams<{ projectName: string }>();
  const [prefixes, setPrefixes] = useState("");
  const [inputFileName, setInputFileName] = useState(defaultFileName);
  const [inputFileCancelled, setInputFileCancelled] = useState(false);
  const { data: bucketPermissions } = useRoleAssignmentsPerObjectList(
    projectName,
    {
      assignee: objectName,
      assignee_type: "deployment",
    },
    { swr: { enabled: objectType === "deployment" && !!objectName } }
  );
  const isTrainingScript = name === FIELD_TRAINING_SCRIPT;
  const bucketsWithReadPermission = useMemo(() => {
    return bucketPermissions
      ? ([
          "default",
          ...(bucketPermissions ?? []).map((permission) => permission.resource),
        ].filter((x) => !!x) as string[])
      : undefined;
  }, [bucketPermissions]);

  const handleSelectedPath = (bucketName?: string, prefix?: string) => {
    setIsBucketSelectForFileUploadDialogOpen(false);
    setIsUploadFileDialogOpen(true);
    setSelectedBucket(bucketName ?? "");
    setPrefixes(prefix ?? "");
  };

  const handleSelectedFile = (
    bucketName?: string,
    prefix?: string,
    fileName?: string
  ) => {
    setIsExistingFileSelectDialogOpen(false);
    setInputFileName(
      fileName && !inputFileCancelled && bucketName
        ? `ubiops-file://${bucketName}/${prefix}${fileName}`
        : ""
    );
  };

  const handleExistingFileSelectClose = () => {
    setIsExistingFileSelectDialogOpen(false);
    setInputFileCancelled(true);
  };

  const handleBucketSelectForFileUploadClose = () => {
    setIsBucketSelectForFileUploadDialogOpen(false);
    setInputFileCancelled(true);
  };

  useEffect(() => {
    if (
      isExistingFileSelectDialogOpen ||
      isBucketSelectForFileUploadDialogOpen
    ) {
      // Reset this value when the dialogs are opened so the inputFileName can be set again
      setInputFileCancelled(false);
    }
  }, [isBucketSelectForFileUploadDialogOpen, isExistingFileSelectDialogOpen]);

  useEffect(() => {
    if (inputFileName !== "") {
      setValue(name, inputFileName);
    }
  }, [inputFileName, name, prefixes, setValue]);

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Box
          display="flex"
          justifyContent="space-around"
          alignItems="flex-end"
          padding={`${spacing[24]} ${spacing[12]}`}
        >
          <Box
            display="flex"
            flexDirection="column"
            gap={spacing[4]}
            alignItems="center"
          >
            <Typography variant="subtitle2" color="textSecondary">
              {label}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setIsBucketSelectForFileUploadDialogOpen(true)}
              startIcon={<CloudUploadIcon />}
            >
              Upload new file
            </Button>
          </Box>
          <Typography variant="subtitle1" color="textSecondary">
            OR
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsExistingFileSelectDialogOpen(true)}
          >
            Select existing file
          </Button>
        </Box>
        {inputFileName && (
          <Box display="flex" alignItems="center">
            <Icon component={CheckCircleRoundedIcon} status="available" />
            <Typography variant="body2" display="inline">
              {inputFileName}
            </Typography>
          </Box>
        )}
      </Box>

      <BucketTreeView
        bucketsWithPermission={bucketsWithReadPermission}
        projectName={projectName}
        onSelect={handleSelectedPath}
        filesAreSelectable={false}
        open={isBucketSelectForFileUploadDialogOpen}
        dialogTitle="Choose which bucket to upload a file to"
        actionButtonText="Upload file"
        onClose={handleBucketSelectForFileUploadClose}
      />

      <BucketTreeView
        bucketsWithPermission={bucketsWithReadPermission}
        projectName={projectName}
        onSelect={handleSelectedFile}
        open={isExistingFileSelectDialogOpen}
        dialogTitle="Browse your buckets for an existing file"
        actionButtonText="Select file"
        onClose={handleExistingFileSelectClose}
        filterFiles={isTrainingScript ? ["py", "zip"] : []}
      />

      <UploadFileDialog
        types={isTrainingScript ? ["py", "zip"] : undefined}
        bucketName={selectedBucket}
        prefix={prefixes}
        open={isUploadFileDialogOpen}
        closeDialog={() => setIsUploadFileDialogOpen(false)}
        handleSuccessfulUpload={(name: string) =>
          setInputFileName(
            `ubiops-file://${selectedBucket}/${
              prefixes ? `${prefixes}` : ""
            }${name}`
          )
        }
      />

      <input name={name} hidden ref={register} />
    </>
  );
};
