import { useTheme } from "@mui/styles";
import { groupBy } from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useGetSpecialColors } from "assets/styles/theme/utils/chartColors";
import {
  getMetricAggregation,
  getMetricsGraphOptions,
} from "libs/utilities/metrics-helper";

import type { MetricGraphProps } from "components/molecules";

import { useCachedMetrics } from "../metrics/useCachedMetrics";
import { useGraphType } from "../metrics/useGraphType";

import type { Graph } from "../metrics/useGraphType";
import type { AppThemeProps } from "assets/styles/theme/theme";
import type { TimeSeriesDataList } from "libs/data/models";
import type { Moment } from "moment";

type DeploymentMetrics = {
  [key: string]: TimeSeriesDataList[];
};

export const useDeploymentMetrics = (
  versionIds: (string | undefined)[],
  initialGraphs: Graph[],
  forceRefresh: boolean,
  startDate?: Moment,
  endDate?: Moment
) => {
  const { projectName } = useParams<{ projectName: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [deploymentMetrics, setDeploymentMetrics] =
    useState<DeploymentMetrics | null>(null);
  const [metricGraphs, setMetricGraphs] =
    useState<MetricGraphProps[] | null>(null);

  const colors = useGetSpecialColors();
  const theme = useTheme() as AppThemeProps;
  const cachedTimeSeriesDataList = useCachedMetrics(forceRefresh);
  const { graphs, setGraphType } = useGraphType(initialGraphs, "deployment");
  // convert string array to primitive type
  // to cache the api calls
  const versionIdString = JSON.stringify(versionIds);

  useEffect(() => {
    setIsLoading(true);

    const timeSeriesPayloads = versionIds
      .map((versionId) =>
        graphs.map(({ metric, isBarChart, isDynamicUnitPeriod }) => {
          const aggregationPeriod =
            startDate && endDate
              ? getMetricAggregation(startDate, endDate, isBarChart)
              : undefined;

          const unit_period =
            isDynamicUnitPeriod && isBarChart ? aggregationPeriod : 60;

          return {
            metric: metric[0],
            labels: versionId
              ? `deployment_version_id:${versionId}`
              : undefined,
            start_date: startDate?.seconds(0).milliseconds(0).toISOString(),
            end_date: endDate?.seconds(0).milliseconds(0).toISOString(),
            unit_period,
            aggregation_period: aggregationPeriod,
          };
        })
      )
      .flat();

    Promise.all(
      timeSeriesPayloads.map((payload) =>
        cachedTimeSeriesDataList(projectName, payload)
      )
    )
      .then((metrics) => {
        const metricsByName = groupBy(metrics, "metric");
        setDeploymentMetrics(metricsByName);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectName, versionIdString, forceRefresh, graphs, startDate, endDate]);

  useEffect(() => {
    setMetricGraphs(
      graphs?.map((graph) =>
        getMetricsGraphOptions({
          ...graph,
          colors,
          theme,
          startDate: startDate?.toISOString(),
          endDate: endDate?.toISOString(),
          labels: graph?.labels || [graph?.title],
          unit:
            deploymentMetrics?.[
              graph.metric[0] as keyof typeof deploymentMetrics
            ]?.find(({ unit }) => !!unit)?.unit ?? "",
          datasets: graph.metric
            .map(
              (metric) =>
                deploymentMetrics?.[
                  metric as keyof typeof deploymentMetrics
                ]?.map((dataset) => dataset.data_points) ?? []
            )
            .flat(),
        })
      ) as unknown as MetricGraphProps[]
    );
  }, [deploymentMetrics, colors, graphs, startDate, endDate, theme]);

  return { metricGraphs, isMetricGraphsLoading: isLoading, setGraphType };
};
