import { useTheme } from "@mui/styles";
import { groupBy } from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useGetSpecialColors } from "assets/styles/theme/utils/chartColors";
import {
  getMetricAggregation,
  getMetricsGraphOptions,
} from "libs/utilities/metrics-helper";

import type { MetricGraphProps } from "components/molecules";

import { useCachedMetrics } from "./useCachedMetrics";
import { useGraphType } from "./useGraphType";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type { TimeSeriesDataList } from "libs/data/models";
import type { Moment } from "moment";
import type { MonitoringObject } from "pages/organizations/:organizationName/projects/:projectName/monitoring/general/AddObjectDialog";

type MonitoringMetrics = {
  [key: string]: TimeSeriesDataList[];
};

export const useGetMonitoringMetrics = (
  objectsList: (MonitoringObject | undefined)[],
  initialGraphs: {
    metric: string[];
    title: string;
    labels?: string[];
    isBarChart?: boolean;
  }[],
  forceRefresh: boolean,
  startDate?: Moment,
  endDate?: Moment,
  removeGraph?: (metric: string) => void
) => {
  const { projectName } = useParams<{ projectName: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [monitoringMetrics, setMonitoringMetrics] =
    useState<MonitoringMetrics | null>(null);
  const [metricGraphs, setMetricGraphs] =
    useState<MetricGraphProps[] | null>(null);

  const colors = useGetSpecialColors();
  const theme = useTheme() as AppThemeProps;

  const cachedFetch = useCachedMetrics(forceRefresh);
  const { graphs, setGraphType } = useGraphType(initialGraphs, "monitoring");

  // convert string array to primitive type
  // to cache the api calls
  const objectIdString = JSON.stringify(objectsList);

  useEffect(() => {
    setIsLoading(true);

    const timeSeriesPayloads = objectsList
      .map((object) =>
        graphs.map(({ metric, isBarChart, isDynamicUnitPeriod }) => {
          const aggregationPeriod =
            startDate && endDate
              ? getMetricAggregation(startDate, endDate, isBarChart)
              : undefined;

          const unit_period =
            isDynamicUnitPeriod && isBarChart ? aggregationPeriod : 60;

          return {
            metric: metric[0],
            labels:
              object?.type === "deployment"
                ? `deployment_version_id:${object?.id}`
                : object?.type === "pipeline"
                ? `pipeline_version_id:${object?.id}`
                : object?.type === "user"
                ? `user_id:${object?.id}`
                : undefined,
            start_date: startDate?.seconds(0).milliseconds(0).toISOString(),
            end_date: endDate?.seconds(0).milliseconds(0).toISOString(),
            unit_period,
            aggregation_period: aggregationPeriod,
          };
        })
      )
      .flat();

    Promise.all(
      timeSeriesPayloads.map((payload) => cachedFetch(projectName, payload))
    )
      .then((metrics) => {
        const metricsByName = groupBy(metrics, "metric");
        setMonitoringMetrics(metricsByName);
        setIsLoading(false);
      })
      .catch(
        (err: { status: number; config: { params: { metric: string } } }) => {
          setIsLoading(false);
          if (err?.status === 404) {
            removeGraph?.(err.config.params.metric);
          }
        }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectName, objectIdString, forceRefresh, graphs, startDate, endDate]);

  useEffect(() => {
    setMetricGraphs(
      graphs?.map((graph) =>
        getMetricsGraphOptions({
          ...graph,
          colors,
          theme: theme,
          startDate: startDate?.toISOString(),
          endDate: endDate?.toISOString(),
          labels: graph?.labels || [graph?.title],
          unit:
            monitoringMetrics?.[
              graph.metric[0] as keyof typeof monitoringMetrics
            ]?.find(({ unit }) => !!unit)?.unit ?? "",
          datasets: graph.metric
            .map(
              (metric) =>
                monitoringMetrics?.[
                  metric as keyof typeof monitoringMetrics
                ]?.map((dataset) => dataset.data_points) ?? []
            )
            .flat(),
        })
      ) as unknown as MetricGraphProps[]
    );
  }, [monitoringMetrics, colors, graphs, startDate, endDate, theme]);

  return { metricGraphs, isMetricGraphsLoading: isLoading, setGraphType };
};
