import LogsIcon from "@mui/icons-material/SubjectRounded";
import { Typography, Grid, Tooltip, IconButton, Box } from "@mui/material";
import { useContext, useMemo } from "react";

import { RequestResultSection } from "components/molecules/RequestResults/RequestResultSection";
import { RootUrlContext } from "libs/contexts";
import { usePipelineVersionsGet } from "libs/data/endpoints/pipelines/pipelines";
import { PipelineVersionUpdateRequestRetentionMode } from "libs/data/models";
import { useIsMounted, useLogsUrl } from "libs/hooks";
import {
  FULL_DATE_TIME_FORMAT,
  getFormattedDate,
  getTzAwareDate,
} from "libs/utilities/date-util";
import { routes } from "routes";

import {
  Alert,
  StatusIcon,
  DetailsDialogItem,
  NavLink,
  Divider,
  OverflowTooltip,
  Link,
  CopyToClipboardButton,
} from "components/atoms";

import { PipelineObjectRequestsTable } from "./PipelineObjectRequestsTable";
import { getCreatedByFromOrigin } from "./utils";

import type {
  InputOutputFieldDetail,
  PipelineRequestDeploymentRequest,
  PipelineRequestOperatorRequest,
  PipelineRequestPipelineRequest,
  PipelineRequestSingleDetail,
  PipelineVersionObjectList,
} from "libs/data/models";

type PipelineResults = PipelineRequestSingleDetail & {
  pipeline_timeout: number;
  deployment_timeout: number;
};

type PipelineRequestResultsProps = {
  organizationName: string;
  projectName: string;
  results: PipelineResults;
  inputFields: InputOutputFieldDetail[];
  outputFields: InputOutputFieldDetail[];
  handleViewHugeResult: () => void;
};

export type PipelineSubRequestResultsRecord =
  PipelineRequestDeploymentRequest & {
    error_message?: string | null;
    object: PipelineVersionObjectList | undefined;
  };

export type PipelineRequestResultsRecord = PipelineRequestDeploymentRequest &
  PipelineRequestPipelineRequest &
  PipelineRequestOperatorRequest & {
    error_message?: string | null;
    object: PipelineVersionObjectList | undefined;
    subRequests?: PipelineSubRequestResultsRecord[];
    object_type: "deployment" | "pipeline" | "operator";
  };

export const PipelineRequestResults = ({
  organizationName,
  projectName,
  results,
  inputFields,
  outputFields,
  handleViewHugeResult,
}: PipelineRequestResultsProps) => {
  const {
    error_message,
    deployment_requests,
    status,
    time_created,
    time_started,
    time_completed,
    origin,
    version,
    pipeline,
    id,
    request_data: data,
    result,
    pipeline_requests,
    operator_requests,
    pipeline_timeout,
    deployment_timeout,
    input_size,
    output_size,
  } = results;

  const rootUrl = useContext(RootUrlContext);

  const { data: pipelineVersion } = usePipelineVersionsGet(
    projectName,
    pipeline || "",
    version || ""
  );
  const isMounted = useIsMounted();

  const redirectUrl = useMemo(
    () =>
      routes.organizations[":organizationName"](organizationName).projects[
        ":projectName"
      ](projectName),
    [organizationName, projectName]
  );

  const logsUrl = useLogsUrl({
    queryParameters: {
      pipeline_request_id: id,
      pipeline_name: pipeline,
      from_date: getFormattedDate(time_created),
      to_date: time_completed && getFormattedDate(time_completed),
    },
    redirectUrl: redirectUrl.index(),
  });

  const originIncludingType = {
    ...(origin || {}),
    type: origin?.request_schedule
      ? "request_schedule"
      : origin?.pipeline
      ? "pipeline"
      : "deployment",
  };

  const { createdBy, url: createdByUrl } = getCreatedByFromOrigin(
    // @ts-ignore
    originIncludingType,
    rootUrl
  );

  // @ts-ignore
  const objectsData: PipelineRequestResultsRecord[] = useMemo(() => {
    if (pipeline && version && pipelineVersion && isMounted) {
      const objects = pipelineVersion?.objects;

      return (
        deployment_requests?.map((entry) => ({
          ...entry,
          object_type: "deployment",
        })) || []
      )
        ?.concat(
          pipeline_requests?.map((entry) => ({
            ...entry,
            object_type: "pipeline",
          })) || []
        )
        .concat(
          operator_requests?.map((entry) => ({
            ...entry,
            object_type: "operator",
          })) || []
        )
        ?.map((entry) => {
          const object = objects?.find(
            (item) => item?.name === entry?.pipeline_object
          );

          return {
            ...entry,
            object,
          };
        });
    } else return [];
  }, [
    deployment_requests,
    isMounted,
    pipeline,
    pipelineVersion,
    version,
    pipeline_requests,
    operator_requests,
  ]);

  const mergeResults = useMemo(() => {
    const pipelineNameIsRemoved = objectsData.find(
      (x: PipelineRequestResultsRecord) => x.pipeline_object === null
    );
    const uniquePipelineObjects = [
      ...new Set(
        objectsData.map(
          (x) => x[pipelineNameIsRemoved ? x["object_type"] : "pipeline_object"]
        )
      ),
    ];

    return uniquePipelineObjects.map((pipelineObject) => {
      const records = objectsData.filter(
        (result: PipelineRequestResultsRecord) =>
          // @ts-ignore
          result[
            pipelineNameIsRemoved ? result["object_type"] : "pipeline_object"
          ] === pipelineObject
      );

      const record = { ...records[0] };

      if (records.length !== 1) {
        const objectWithError = [...records].find((x) => x.error_message);

        record.subRequests = [...records];
        record.error_message = objectWithError
          ? `Error on request ${objectWithError.id}: ${objectWithError.error_message}`
          : "";
        record.status = record.subRequests.some(
          (x) => x.status === "processing"
        )
          ? "processing"
          : record.subRequests.some((x) => x.status === "pending")
          ? "pending"
          : record.subRequests.some((x) => x.status === "failed")
          ? "failed"
          : record.subRequests.some(
              (x) =>
                x.status === "cancelled" || x.status === "cancelled_pending"
            )
          ? "cancelled"
          : "completed";
      }

      return record;
    });
  }, [objectsData]);

  const showResults =
    pipelineVersion?.request_retention_mode ===
    PipelineVersionUpdateRequestRetentionMode.full;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {error_message ? (
          <Alert severity="error">Error: {error_message}</Alert>
        ) : (deployment_requests || [])
            ?.concat(pipeline_requests || [])
            .concat(operator_requests || [])
            ?.some((obj) => !!obj?.error_message) ? (
          <Alert severity="error">
            Error: One of the objects completed with an error
          </Alert>
        ) : null}
      </Grid>
      <Grid item xs={6}>
        <DetailsDialogItem title="Request ID" isLoaded={!!id}>
          <Box display="flex" alignItems="center">
            <OverflowTooltip component={Typography}>{id}</OverflowTooltip>
            <CopyToClipboardButton
              defaultLabel="Copy ID"
              contentToCopy={id}
              size="small"
              htmlColor="secondary"
              hoverColor="secondary"
            />
            <Tooltip title="View logs">
              <IconButton
                edge="end"
                component={NavLink}
                size="small"
                to={logsUrl}
                color="secondary"
              >
                <LogsIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        </DetailsDialogItem>
        <DetailsDialogItem title="Pipeline" isLoaded={!!pipeline}>
          <Link
            to={redirectUrl.pipelines[":pipelineName"](pipeline || "").index()}
          >
            {pipeline}
          </Link>
        </DetailsDialogItem>
        <DetailsDialogItem title="Version" isLoaded={!!version}>
          <Link
            to={redirectUrl.pipelines[":pipelineName"](pipeline || "")
              .versions[":versionName"](version || "")
              .index()}
          >
            {version}
          </Link>
        </DetailsDialogItem>
        <DetailsDialogItem
          title="Pipeline timeout"
          isLoaded={!!pipeline_timeout}
        >
          {pipeline_timeout} seconds
        </DetailsDialogItem>
        <DetailsDialogItem
          title="Deployment timeout"
          isLoaded={!!deployment_timeout}
        >
          {deployment_timeout} seconds
        </DetailsDialogItem>
      </Grid>
      <Grid item xs={6}>
        <DetailsDialogItem title="Created" isLoaded={!!time_created}>
          <OverflowTooltip component={Typography}>
            {getTzAwareDate(time_created || undefined).format(
              FULL_DATE_TIME_FORMAT
            )}
          </OverflowTooltip>
        </DetailsDialogItem>
        <DetailsDialogItem title="Started" isLoaded={!!time_started}>
          <OverflowTooltip component={Typography}>
            {getTzAwareDate(time_started || undefined).format(
              FULL_DATE_TIME_FORMAT
            )}
          </OverflowTooltip>
        </DetailsDialogItem>
        <DetailsDialogItem title="Completed" isLoaded={!!time_completed}>
          <OverflowTooltip component={Typography}>
            {getTzAwareDate(time_completed || undefined).format(
              FULL_DATE_TIME_FORMAT
            )}
          </OverflowTooltip>
        </DetailsDialogItem>
        <DetailsDialogItem title="Created by" isLoaded={!!createdBy}>
          <OverflowTooltip component={Typography}>
            {createdByUrl ? (
              <Link to={createdByUrl}>{createdBy}</Link>
            ) : (
              createdBy
            )}
          </OverflowTooltip>
        </DetailsDialogItem>
        <DetailsDialogItem title="Status" isLoaded={!!status}>
          <StatusIcon label={status} status={status} />
        </DetailsDialogItem>
      </Grid>

      {showResults && (
        <>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <RequestResultSection
            data={data}
            result={result}
            error={!!error_message}
            inputFields={inputFields}
            outputFields={outputFields}
            projectName={projectName}
            organizationName={organizationName}
            inputSize={input_size}
            outputSize={output_size}
            handleViewHugeResult={handleViewHugeResult}
          />
        </>
      )}
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">Object results</Typography>
      </Grid>
      <Grid item xs={12}>
        <PipelineObjectRequestsTable
          results={mergeResults}
          projectName={projectName}
          organizationName={organizationName}
          pipelineName={pipeline || ""}
          pipelineVersion={version || ""}
        />
      </Grid>
    </Grid>
  );
};
