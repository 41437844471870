import Plus from "@mui/icons-material/AddBoxRounded";
import { isEmpty } from "lodash";
import React, { useContext, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { DetailsContainer } from "components/molecules/PageLayout";
import { PIPELINE_PERMISSIONS } from "libs/constants/permissions";
import { BaseUrlContext } from "libs/contexts";
import { usePipelinesList } from "libs/data/endpoints/pipelines/pipelines";
import { useGoogleAnalytics } from "libs/hooks";
import {
  getTzAwareDate,
  getTimeFromNow,
  DATE_TIME_FORMAT,
} from "libs/utilities/date-util";
import {
  formatLabelsForFilter,
  renderLabels,
} from "libs/utilities/labels-util";
import { useGetCurrentOrganization } from "store/features";
import { useGetPermissions } from "store/features/permissions";

import {
  ButtonGroup,
  TableLink,
  Loader,
  PrimaryButton,
} from "components/atoms";
import { BaseTable } from "components/molecules";
import { onFilterAdd } from "components/organisms";

import PipelineEmptyOverview from "./PipelineEmptyOverview";

const PipelinesOverview = () => {
  useGoogleAnalytics();

  const history = useHistory();
  const { projectName } = useParams();
  const baseUrl = useContext(BaseUrlContext);
  const currentOrganization = useGetCurrentOrganization();

  const [filters, setFilters] = useState([]);
  const [projectPermissions] = useGetPermissions();

  const { data: pipelines, error: pipelinesError } = usePipelinesList(
    projectName,
    { labels: formatLabelsForFilter(filters) },
    {
      swr: {
        swrKey: `/projects/${projectName}/pipelines`,
      },
    }
  );

  const isLoadingPipelines = !pipelines && !pipelinesError;

  const columns = useMemo(
    () => [
      {
        title: "Name",
        field: "name",
        width: "40%",
        nowrap: true,
        render: (rowData) => (
          <TableLink variant="bold" to={`${baseUrl}/${rowData.name}/general`}>
            {rowData.name}
          </TableLink>
        ),
      },
      {
        title: "Created",
        field: "creation_date",
        type: "datetime",
        nowrap: true,
        render: (rowData) =>
          getTzAwareDate(rowData.creation_date).format(DATE_TIME_FORMAT),
      },
      {
        title: "Edited",
        field: "last_updated",
        enableSorting: true,
        isSortingActive: () => true,
        type: "datetime",
        nowrap: true,
        render: (rowData) => getTimeFromNow(rowData.last_updated),
        defaultSort: "desc",
      },
      {
        title: "Labels",
        field: "labels",
        render: renderLabels(onFilterAdd(setFilters)),
      },
    ],
    [baseUrl]
  );

  const onRowClick = (e, rowData) => {
    const url = `${baseUrl}/${rowData.name}/general`;
    if (e.metaKey || e.ctrlKey) {
      e.preventDefault();
      e.stopPropagation();
      window.open(url, "_blank");
    } else {
      history.push(url);
    }
  };

  return (
    <DetailsContainer
      title="Pipelines"
      actions={
        <ButtonGroup>
          <PrimaryButton
            startIcon={<Plus />}
            link={`${baseUrl}/create`}
            disabled={
              !projectPermissions[PIPELINE_PERMISSIONS["create"]] ||
              currentOrganization?.status !== "active"
            }
            style={{ marginLeft: "auto" }}
          >
            Create
          </PrimaryButton>
        </ButtonGroup>
      }
    >
      {isLoadingPipelines ? (
        <Loader />
      ) : pipelines?.length === 0 && isEmpty(filters) ? (
        <PipelineEmptyOverview
          baseUrl={baseUrl}
          permission={
            !!projectPermissions[PIPELINE_PERMISSIONS["create"]] &&
            currentOrganization?.status === "active"
          }
        />
      ) : (
        <BaseTable
          columns={columns}
          data={pipelines}
          onRowClick={onRowClick}
          filters={filters}
          setFilters={setFilters}
          defaultSortColumn="last_updated"
          defaultSortDirection="asc"
        />
      )}
    </DetailsContainer>
  );
};

export default PipelinesOverview;
