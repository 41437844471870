import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { useMemo, useState } from "react";

import { spacing } from "assets/styles/theme";
import {
  FIELD_DESCRIPTION,
  FIELD_LABELS,
  FIELD_METRIC_TYPE,
  FIELD_NAME,
  FIELD_UNIT,
} from "libs/constants/fields";
import { MetricCreateMetricType } from "libs/data/models";
import { explanations } from "libs/utilities/explanations";

import { Icon, InfoTooltip, PrimaryButton } from "components/atoms";
import {
  FormSection,
  FormikSelect,
  FormikTextInput,
} from "components/molecules";

import {
  METRIC_CREATE_VALUES,
  metricCreateSchema,
  metricLevels,
} from "./constants";

import type {
  AutocompleteRenderInputParams,
  SvgIconTypeMap,
} from "@mui/material";
import type { OverridableComponent } from "@mui/material/OverridableComponent";
import type { AutocompleteSelectOption } from "components/atoms/AutoCompleteSelect";
import type { FormikHelpers, FormikProps, FormikValues } from "formik";
import type { MetricCreate, MetricDetail } from "libs/data/models";

type MetricBaseFormProps = {
  handleOnSubmit: (data: MetricCreate, actions: FormikHelpers<any>) => void;
  defaultValues?: MetricDetail;
  isEditForm?: boolean;
};

export const MetricBaseForm = ({
  handleOnSubmit,
  defaultValues,
  isEditForm,
}: MetricBaseFormProps) => {
  const [selectedLabel, setSelectedLabel] = useState<{
    label: string;
    value: string;
    icon?: OverridableComponent<SvgIconTypeMap<any, "svg">> & {
      muiName: string;
    };
  }>(
    defaultValues?.labels?.length
      ? (metricLevels.find(
          (level) => level.value === defaultValues?.labels?.[0]
        ) as {
          label: string;
          value: string;
          icon: OverridableComponent<SvgIconTypeMap<any, "svg">> & {
            muiName: string;
          };
        })
      : metricLevels[0]
  );

  const mergedInitialValues = useMemo(() => {
    return {
      ...METRIC_CREATE_VALUES,
      ...defaultValues,
    };
  }, [defaultValues]);

  return (
    <Formik
      onSubmit={handleOnSubmit}
      initialValues={mergedInitialValues}
      validationSchema={metricCreateSchema}
    >
      {(control: FormikProps<FormikValues>) => {
        const { submitForm, setFieldValue, values } = control;

        return (
          <>
            <FormSection
              description={explanations.customMetrics.createForm.general}
              title="General"
            >
              <FormikTextInput
                control={control}
                label="Name"
                placeholder="Ex: webhook-name"
                name={FIELD_NAME}
                // "custom." prefix is required
                onChange={(change: string) => {
                  const handleChange = control.handleChange(FIELD_NAME);
                  if (change.startsWith("custom.")) {
                    handleChange(change);
                  } else {
                    handleChange("custom.");
                  }
                }}
                showErrors
                required
              />

              <FormikTextInput
                control={control}
                label="Description"
                name={FIELD_DESCRIPTION}
                minRows={3}
                multiline
              />
            </FormSection>
            <FormSection
              title="Unit"
              description={explanations.customMetrics.createForm.unit}
            >
              <FormikTextInput
                control={control}
                label="Unit"
                name={FIELD_UNIT}
                placeholder="seconds"
                showErrors
              />
            </FormSection>
            <FormSection
              title="Type"
              description={explanations.customMetrics.createForm.type}
            >
              <RadioGroup
                name={FIELD_METRIC_TYPE}
                row
                style={{ alignItems: "center", gap: 2 }}
              >
                <FormControlLabel
                  value={MetricCreateMetricType.gauge}
                  control={<Radio color="secondary" />}
                  label="Gauge"
                  checked={
                    values[FIELD_METRIC_TYPE] === MetricCreateMetricType.gauge
                  }
                  onChange={async () => {
                    await setFieldValue(
                      FIELD_METRIC_TYPE,
                      MetricCreateMetricType.gauge
                    );
                  }}
                  style={{ margin: 0 }}
                  disabled={isEditForm}
                />
                <InfoTooltip>
                  {isEditForm
                    ? explanations.customMetrics.createForm.disabledField(
                        "Type"
                      )
                    : explanations.customMetrics.createForm.gauge}
                </InfoTooltip>

                <FormControlLabel
                  value={MetricCreateMetricType.delta}
                  control={<Radio color="secondary" />}
                  label="Delta"
                  checked={
                    values[FIELD_METRIC_TYPE] === MetricCreateMetricType.delta
                  }
                  onChange={async () => {
                    await setFieldValue(
                      FIELD_METRIC_TYPE,
                      MetricCreateMetricType.delta
                    );
                  }}
                  style={{ margin: 0 }}
                  disabled={isEditForm}
                />
                <InfoTooltip>
                  {" "}
                  {isEditForm
                    ? explanations.customMetrics.createForm.disabledField(
                        "Type"
                      )
                    : explanations.customMetrics.createForm.delta}
                </InfoTooltip>
              </RadioGroup>
            </FormSection>
            <FormSection
              title="Metric level"
              description={explanations.customMetrics.createForm.level}
            >
              <Box display="flex" width={"100%"} alignItems="center">
                <FormikSelect
                  name={FIELD_LABELS}
                  options={metricLevels}
                  control={control}
                  onChange={async (value) => {
                    setSelectedLabel(value as { label: string; value: string });
                    await setFieldValue(FIELD_LABELS, [value?.value]);
                  }}
                  disabled={isEditForm}
                  value={selectedLabel}
                  renderOption={(
                    props: any,
                    option: AutocompleteSelectOption
                  ) => {
                    return (
                      <Box
                        style={{
                          alignItems: "center",
                          display: "flex",
                          gridColumnGap: spacing[8],
                          paddingLeft: spacing[16],
                        }}
                        {...props}
                      >
                        <Icon
                          component={option.icon}
                          style={{ height: "25px", width: "25px" }}
                        />
                        <Typography variant="body2">{option.label} </Typography>
                      </Box>
                    );
                  }}
                  renderInput={(params: AutocompleteRenderInputParams) => (
                    <Box
                      style={{
                        alignItems: "center",
                        display: "flex",
                        gridColumnGap: spacing[8],
                      }}
                      {...params}
                    >
                      <TextField
                        value={selectedLabel.label}
                        label="Metric level"
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <Icon
                              component={selectedLabel?.icon || null}
                              style={{ height: "25px", width: "25px" }}
                            />
                          ),
                        }}
                      >
                        {selectedLabel.label}
                      </TextField>
                    </Box>
                  )}
                />
                {isEditForm && (
                  <Box>
                    <InfoTooltip>
                      {explanations.customMetrics.createForm.disabledField(
                        "Level"
                      )}
                    </InfoTooltip>
                  </Box>
                )}
              </Box>
            </FormSection>
            <Box justifyContent={"right"} width={"100%"} display={"flex"}>
              <PrimaryButton
                loading={control.isSubmitting}
                onClick={submitForm}
              >
                Save
              </PrimaryButton>
            </Box>
          </>
        );
      }}
    </Formik>
  );
};
