export const IlluEmptyCustomMetricsDialog = () => {
  return (
    <svg
      width="224"
      height="157"
      viewBox="0 0 224 157"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10592_47253)">
        <path
          d="M182.957 98.4626H38.6882C37.7034 98.4615 36.7592 98.07 36.0628 97.3739C35.3665 96.6778 34.9747 95.734 34.9736 94.7496V5.82768C34.9744 5.10649 35.2614 4.41506 35.7716 3.90509C36.2817 3.39512 36.9734 3.10825 37.6949 3.10742H183.904C184.638 3.10826 185.341 3.40008 185.86 3.91887C186.379 4.43766 186.671 5.14104 186.672 5.87471V94.7496C186.671 95.734 186.279 96.6778 185.583 97.3739C184.886 98.07 183.942 98.4615 182.957 98.4626Z"
          fill="#F2F2F2"
        />
        <path
          d="M177.695 93.2029H43.2395C41.2685 93.2029 39.665 91.702 39.665 89.8571V12.4228C39.665 11.0546 40.8532 9.94141 42.3138 9.94141H178.576C180.061 9.94141 181.269 11.0729 181.269 12.4637V89.8571C181.269 91.702 179.666 93.2029 177.695 93.2029Z"
          fill="white"
        />
        <path
          d="M186.62 5.61457H34.9209V3.72422C34.9223 2.73654 35.3156 1.78975 36.0146 1.09156C36.7135 0.393376 37.6609 0.000815994 38.649 0H182.892C183.88 0.000811239 184.827 0.39337 185.526 1.09156C186.225 1.78974 186.618 2.73653 186.62 3.72422V5.61457Z"
          fill="#CCCCCC"
        />
        <path
          d="M41.6967 3.77742C42.2293 3.77742 42.661 3.34586 42.661 2.81351C42.661 2.28116 42.2293 1.84961 41.6967 1.84961C41.1642 1.84961 40.7324 2.28116 40.7324 2.81351C40.7324 3.34586 41.1642 3.77742 41.6967 3.77742Z"
          fill="white"
        />
        <path
          d="M45.3569 3.77742C45.8895 3.77742 46.3212 3.34586 46.3212 2.81351C46.3212 2.28116 45.8895 1.84961 45.3569 1.84961C44.8243 1.84961 44.3926 2.28116 44.3926 2.81351C44.3926 3.34586 44.8243 3.77742 45.3569 3.77742Z"
          fill="white"
        />
        <path
          d="M49.017 3.77742C49.5496 3.77742 49.9813 3.34586 49.9813 2.81351C49.9813 2.28116 49.5496 1.84961 49.017 1.84961C48.4845 1.84961 48.0527 2.28116 48.0527 2.81351C48.0527 3.34586 48.4845 3.77742 49.017 3.77742Z"
          fill="white"
        />
        <path
          d="M162.894 80.2171H58.6975C57.653 80.2159 56.6516 79.8006 55.913 79.0624C55.1745 78.3241 54.759 77.3231 54.7578 76.2791V26.0625C54.7587 25.2816 55.0694 24.533 55.6218 23.9808C56.1741 23.4287 56.9231 23.1181 57.7042 23.1172H163.84C164.634 23.1181 165.395 23.4337 165.956 23.9946C166.517 24.5556 166.833 25.3162 166.834 26.1095V76.2791C166.833 77.3231 166.417 78.3241 165.679 79.0624C164.94 79.8006 163.939 80.2159 162.894 80.2171ZM57.7042 23.5672C57.0424 23.568 56.4079 23.8311 55.94 24.2989C55.472 24.7667 55.2088 25.4009 55.2081 26.0625V76.2791C55.2091 77.2038 55.5771 78.0904 56.2312 78.7443C56.8854 79.3982 57.7724 79.766 58.6975 79.767H162.894C163.819 79.766 164.706 79.3982 165.361 78.7443C166.015 78.0904 166.383 77.2038 166.384 76.2791V26.1095C166.383 25.4355 166.115 24.7893 165.638 24.3127C165.161 23.8361 164.515 23.568 163.84 23.5672H57.7042Z"
          fill="#CCCCCC"
        />
        <path
          d="M62.3003 76.2777H159.293C160.248 76.2777 161.165 75.8984 161.84 75.2232C162.516 74.548 162.895 73.6322 162.895 72.6772V30.6982C162.895 29.7432 162.516 28.8274 161.84 28.1522C161.165 27.477 160.248 27.0977 159.293 27.0977H62.3003C61.3449 27.0977 60.4288 27.477 59.7533 28.1522C59.0777 28.8274 58.6982 29.7432 58.6982 30.6982V72.6772C58.6982 73.6322 59.0777 74.548 59.7533 75.2232C60.4288 75.8984 61.3449 76.2777 62.3003 76.2777Z"
          fill="#F2F2F2"
        />
        <path
          d="M73.3132 39.3123C74.4322 39.3123 75.3394 38.4055 75.3394 37.287C75.3394 36.1685 74.4322 35.2617 73.3132 35.2617C72.1942 35.2617 71.2871 36.1685 71.2871 37.287C71.2871 38.4055 72.1942 39.3123 73.3132 39.3123Z"
          fill="#CCCCCC"
        />
        <path
          d="M73.3132 46.9627C74.4322 46.9627 75.3394 46.0559 75.3394 44.9374C75.3394 43.8189 74.4322 42.9121 73.3132 42.9121C72.1942 42.9121 71.2871 43.8189 71.2871 44.9374C71.2871 46.0559 72.1942 46.9627 73.3132 46.9627Z"
          fill="#CCCCCC"
        />
        <path
          d="M73.3132 54.6131C74.4322 54.6131 75.3394 53.7063 75.3394 52.5878C75.3394 51.4692 74.4322 50.5625 73.3132 50.5625C72.1942 50.5625 71.2871 51.4692 71.2871 52.5878C71.2871 53.7063 72.1942 54.6131 73.3132 54.6131Z"
          fill="#CCCCCC"
        />
        <path
          d="M73.3132 62.2654C74.4322 62.2654 75.3394 61.3587 75.3394 60.2401C75.3394 59.1216 74.4322 58.2148 73.3132 58.2148C72.1942 58.2148 71.2871 59.1216 71.2871 60.2401C71.2871 61.3587 72.1942 62.2654 73.3132 62.2654Z"
          fill="#CCCCCC"
        />
        <path
          d="M150.081 37.5126H83.4439C83.3842 37.5126 83.3269 37.4889 83.2847 37.4467C83.2425 37.4045 83.2188 37.3472 83.2188 37.2875C83.2188 37.2278 83.2425 37.1706 83.2847 37.1284C83.3269 37.0862 83.3842 37.0625 83.4439 37.0625H150.081C150.141 37.0625 150.198 37.0862 150.24 37.1284C150.282 37.1706 150.306 37.2278 150.306 37.2875C150.306 37.3472 150.282 37.4045 150.24 37.4467C150.198 37.4889 150.141 37.5126 150.081 37.5126Z"
          fill="#CCCCCC"
        />
        <path
          d="M150.081 45.163H83.4439C83.3842 45.163 83.3269 45.1392 83.2847 45.097C83.2425 45.0548 83.2188 44.9976 83.2188 44.9379C83.2188 44.8782 83.2425 44.821 83.2847 44.7788C83.3269 44.7366 83.3842 44.7129 83.4439 44.7129H150.081C150.141 44.7129 150.198 44.7366 150.24 44.7788C150.282 44.821 150.306 44.8782 150.306 44.9379C150.306 44.9976 150.282 45.0548 150.24 45.097C150.198 45.1392 150.141 45.163 150.081 45.163Z"
          fill="#CCCCCC"
        />
        <path
          d="M150.081 52.8133H83.4439C83.3842 52.8133 83.3269 52.7896 83.2847 52.7474C83.2425 52.7052 83.2188 52.648 83.2188 52.5883C83.2188 52.5286 83.2425 52.4714 83.2847 52.4292C83.3269 52.387 83.3842 52.3633 83.4439 52.3633H150.081C150.141 52.3633 150.198 52.387 150.24 52.4292C150.282 52.4714 150.306 52.5286 150.306 52.5883C150.306 52.648 150.282 52.7052 150.24 52.7474C150.198 52.7896 150.141 52.8133 150.081 52.8133Z"
          fill="#CCCCCC"
        />
        <path
          d="M150.081 60.4657H83.4439C83.3842 60.4657 83.3269 60.442 83.2847 60.3998C83.2425 60.3576 83.2188 60.3003 83.2188 60.2407C83.2188 60.181 83.2425 60.1237 83.2847 60.0815C83.3269 60.0393 83.3842 60.0156 83.4439 60.0156H150.081C150.141 60.0156 150.198 60.0393 150.24 60.0815C150.282 60.1237 150.306 60.181 150.306 60.2407C150.306 60.3003 150.282 60.3576 150.24 60.3998C150.198 60.442 150.141 60.4657 150.081 60.4657Z"
          fill="#CCCCCC"
        />
        <path
          d="M96.0508 68.1152H89.5221C89.2237 68.1149 88.9376 67.9962 88.7266 67.7853C88.5155 67.5744 88.3968 67.2884 88.3965 66.9901V60.9142C88.3968 60.6159 88.5155 60.3299 88.7266 60.119C88.9376 59.9081 89.2237 59.7894 89.5221 59.7891H96.0508C96.3492 59.7894 96.6353 59.9081 96.8463 60.119C97.0573 60.3299 97.176 60.6159 97.1764 60.9142V66.9901C97.176 67.2884 97.0573 67.5744 96.8463 67.7853C96.6353 67.9962 96.3492 68.1149 96.0508 68.1152Z"
          fill="#F2682A"
        />
        <path
          d="M110.684 68.1159H104.155C103.856 68.1155 103.57 67.9969 103.359 67.786C103.148 67.575 103.03 67.289 103.029 66.9907V53.2638C103.03 52.9655 103.148 52.6795 103.359 52.4686C103.57 52.2577 103.856 52.139 104.155 52.1387H110.684C110.982 52.139 111.268 52.2577 111.479 52.4686C111.69 52.6795 111.809 52.9655 111.809 53.2638V66.9907C111.809 67.289 111.69 67.575 111.479 67.786C111.268 67.9969 110.982 68.1155 110.684 68.1159Z"
          fill="#F2682A"
        />
        <path
          d="M125.542 68.1166H119.013C118.715 68.1162 118.429 67.9976 118.218 67.7866C118.007 67.5757 117.888 67.2897 117.888 66.9914V45.6134C117.888 45.3151 118.007 45.0291 118.218 44.8182C118.429 44.6073 118.715 44.4886 119.013 44.4883H125.542C125.84 44.4886 126.126 44.6073 126.338 44.8182C126.549 45.0291 126.667 45.3151 126.668 45.6134V66.9914C126.667 67.2897 126.549 67.5757 126.338 67.7866C126.126 67.9976 125.84 68.1162 125.542 68.1166Z"
          fill="#F2682A"
        />
        <path
          d="M140.851 68.1168H134.322C134.023 68.1165 133.737 67.9978 133.526 67.7869C133.315 67.5759 133.197 67.29 133.196 66.9916V38.1877C133.197 37.8894 133.315 37.6034 133.526 37.3924C133.737 37.1815 134.023 37.0628 134.322 37.0625H140.851C141.149 37.0628 141.435 37.1815 141.646 37.3924C141.857 37.6034 141.976 37.8894 141.976 38.1877V66.9916C141.976 67.29 141.857 67.5759 141.646 67.7869C141.435 67.9978 141.149 68.1165 140.851 68.1168Z"
          fill="#F2682A"
        />
        <path
          d="M150.081 68.1161H83.4439C83.3842 68.1161 83.3269 68.0924 83.2847 68.0502C83.2425 68.008 83.2188 67.9507 83.2188 67.891C83.2188 67.8314 83.2425 67.7741 83.2847 67.7319C83.3269 67.6897 83.3842 67.666 83.4439 67.666H150.081C150.141 67.666 150.198 67.6897 150.24 67.7319C150.282 67.7741 150.306 67.8314 150.306 67.891C150.306 67.9507 150.282 68.008 150.24 68.0502C150.198 68.0924 150.141 68.1161 150.081 68.1161Z"
          fill="#3F3D56"
        />
        <path
          d="M179.358 110.094C179.54 109.818 179.781 109.585 180.063 109.412C180.345 109.239 180.662 109.13 180.991 109.093C181.32 109.056 181.653 109.091 181.966 109.196C182.28 109.301 182.567 109.474 182.807 109.702L189.956 106.014L189.511 110.169L182.791 113.034C182.381 113.411 181.847 113.625 181.29 113.635C180.732 113.645 180.191 113.45 179.768 113.088C179.345 112.726 179.069 112.221 178.994 111.669C178.918 111.117 179.048 110.557 179.358 110.094Z"
          fill="#FFB8B8"
        />
        <path
          d="M185.509 111.644C185.347 111.517 185.227 111.343 185.166 111.146L184.597 109.31C184.536 109.111 184.538 108.898 184.602 108.7C184.666 108.502 184.789 108.329 184.955 108.203L190.749 103.828L197.024 92.1913C197.287 91.8529 197.614 91.5696 197.986 91.3575C198.359 91.1454 198.769 91.0087 199.195 90.9552C199.62 90.9017 200.051 90.9325 200.465 91.0457C200.878 91.159 201.265 91.3525 201.604 91.6153C201.942 91.878 202.226 92.2048 202.438 92.5771C202.65 92.9493 202.787 93.3597 202.84 93.7847C202.894 94.2098 202.863 94.6412 202.75 95.0544C202.636 95.4675 202.443 95.8544 202.18 96.1927L198.61 103.725C197.526 106.011 195.649 107.826 193.327 108.832L186.537 111.776C186.406 111.833 186.265 111.861 186.123 111.859C185.981 111.858 185.84 111.827 185.711 111.767C185.639 111.734 185.571 111.693 185.509 111.644Z"
          fill="#3F3D56"
        />
        <path
          d="M202.337 87.3352C205.391 87.3352 207.866 84.8607 207.866 81.8082C207.866 78.7558 205.391 76.2812 202.337 76.2812C199.283 76.2812 196.808 78.7558 196.808 81.8082C196.808 84.8607 199.283 87.3352 202.337 87.3352Z"
          fill="#FFB8B8"
        />
        <path
          d="M200.094 154.311L197.334 154.311L196.021 143.67L200.095 143.67L200.094 154.311Z"
          fill="#FFB8B8"
        />
        <path
          d="M195.363 153.523H200.686V156.873H192.012C192.012 156.434 192.098 155.998 192.267 155.591C192.435 155.185 192.682 154.816 192.993 154.505C193.305 154.194 193.674 153.947 194.081 153.778C194.487 153.61 194.923 153.523 195.363 153.523Z"
          fill="#2F2E41"
        />
        <path
          d="M209.1 154.314L206.34 154.313L205.027 143.672L209.101 143.672L209.1 154.314Z"
          fill="#FFB8B8"
        />
        <path
          d="M204.368 153.523H209.691V156.873H201.017C201.017 156.434 201.103 155.998 201.272 155.591C201.44 155.185 201.687 154.816 201.998 154.505C202.309 154.194 202.679 153.947 203.085 153.778C203.492 153.61 203.928 153.523 204.368 153.523Z"
          fill="#2F2E41"
        />
        <path
          d="M209.301 151.612L206.066 151.612C205.844 151.611 205.627 151.538 205.451 151.402C205.274 151.267 205.147 151.077 205.088 150.862L200.433 133.602C200.426 133.575 200.409 133.552 200.385 133.537C200.362 133.522 200.334 133.516 200.306 133.52C200.279 133.525 200.254 133.539 200.236 133.561C200.219 133.583 200.21 133.61 200.212 133.638L201.152 150.008C201.161 150.152 201.138 150.297 201.085 150.432C201.033 150.567 200.952 150.69 200.848 150.791C200.744 150.892 200.62 150.97 200.483 151.019C200.347 151.068 200.201 151.087 200.057 151.075L196.421 150.772C196.188 150.752 195.97 150.652 195.802 150.49C195.634 150.327 195.527 150.112 195.499 149.88L192.188 115.507L193.469 116.276L205.474 114.238L210.305 150.465C210.324 150.608 210.312 150.754 210.271 150.892C210.229 151.03 210.158 151.158 210.063 151.266C209.968 151.375 209.851 151.462 209.719 151.521C209.588 151.581 209.445 151.612 209.301 151.612Z"
          fill="#2F2E41"
        />
        <path
          d="M196.203 119.137C194.879 119.126 193.581 118.761 192.446 118.079C192.271 117.976 192.131 117.822 192.045 117.637C191.96 117.453 191.931 117.247 191.965 117.047C192.387 114.462 194.595 101.203 196.604 93.9744C197.422 91.0304 199.232 89.4447 201.982 89.2615C206.004 88.9978 209.58 92.1436 209.952 96.2825C210.609 103.584 207.941 113.959 206.763 118.08C206.718 118.239 206.634 118.385 206.519 118.504C206.404 118.623 206.261 118.712 206.104 118.764C205.946 118.815 205.778 118.827 205.615 118.798C205.452 118.769 205.298 118.701 205.168 118.599L202.768 116.733C202.62 116.62 202.437 116.563 202.252 116.57C202.066 116.578 201.889 116.651 201.751 116.775C199.728 118.579 197.806 119.137 196.203 119.137Z"
          fill="#3F3D56"
        />
        <path
          opacity="0.2"
          d="M204.141 99.8555L206.167 108.407L203.24 115.608L205.267 108.632L204.141 99.8555Z"
          fill="black"
        />
        <path
          d="M205.492 86.3526C205.984 86.3617 206.473 86.2733 206.93 86.0926C207.132 86.015 207.341 85.9513 207.541 85.8737C209.316 85.1886 210.485 83.2997 210.527 81.3988C210.569 79.4979 209.584 77.6528 208.122 76.4368C206.659 75.2208 204.765 74.5964 202.868 74.452C200.825 74.2964 198.525 74.8142 197.383 76.5149C197.079 76.9676 196.867 77.5169 197.047 78.0635C197.101 78.2284 197.197 78.3763 197.326 78.4924C197.836 78.9458 198.344 78.6049 198.864 78.5748C199.578 78.5334 200.22 79.1115 200.45 79.7886C200.681 80.4657 200.579 81.2112 200.393 81.9018C200.251 82.3174 200.162 82.749 200.127 83.1867C200.112 83.4062 200.153 83.6259 200.247 83.825C200.341 84.024 200.484 84.1959 200.662 84.3242C201.109 84.594 201.695 84.4379 202.142 84.1686C202.588 83.8993 202.974 83.525 203.457 83.3268C203.939 83.1287 204.587 83.1727 204.868 83.6121C204.957 83.7719 205.018 83.9463 205.047 84.1271C205.298 85.2736 205.241 85.2061 205.492 86.3526Z"
          fill="#2F2E41"
        />
        <path
          d="M196.922 146.592L162.298 134.539C161.704 134.331 161.217 133.897 160.943 133.33C160.669 132.764 160.631 132.112 160.837 131.518L169.835 105.693C169.987 105.258 170.305 104.901 170.72 104.701C171.136 104.5 171.613 104.472 172.049 104.623L207.843 117.084C208.286 117.239 208.649 117.562 208.853 117.985C209.057 118.407 209.085 118.893 208.932 119.335L199.944 145.131C199.737 145.725 199.302 146.212 198.735 146.486C198.169 146.76 197.516 146.798 196.922 146.592Z"
          fill="#E6E6E6"
        />
        <path
          d="M165.253 133.055L195.529 143.595C196.105 143.795 196.738 143.759 197.287 143.493C197.837 143.228 198.259 142.755 198.459 142.178L205.725 121.326C205.925 120.749 205.889 120.117 205.623 119.567C205.358 119.018 204.884 118.597 204.308 118.396L174.032 107.857C173.456 107.656 172.823 107.692 172.273 107.958C171.724 108.223 171.302 108.696 171.101 109.273L163.836 130.126C163.737 130.411 163.694 130.713 163.712 131.015C163.729 131.316 163.806 131.612 163.937 131.884C164.069 132.156 164.253 132.399 164.479 132.6C164.704 132.801 164.967 132.956 165.253 133.055Z"
          fill="white"
        />
        <path
          d="M172.856 118.404L171.357 117.882C171.275 117.853 171.209 117.794 171.171 117.716C171.134 117.639 171.128 117.55 171.157 117.468L171.679 115.97C171.707 115.888 171.767 115.822 171.844 115.784C171.922 115.747 172.011 115.742 172.092 115.77L173.592 116.292C173.673 116.32 173.74 116.38 173.777 116.457C173.815 116.535 173.82 116.624 173.792 116.705L173.269 118.204C173.241 118.285 173.182 118.352 173.104 118.389C173.026 118.427 172.937 118.432 172.856 118.404Z"
          fill="#F2682A"
        />
        <path
          d="M171.503 122.289L170.004 121.767C169.923 121.738 169.856 121.679 169.819 121.601C169.781 121.524 169.776 121.435 169.804 121.353L170.326 119.854C170.355 119.773 170.414 119.707 170.492 119.669C170.569 119.632 170.658 119.626 170.74 119.655L172.239 120.177C172.32 120.205 172.387 120.265 172.425 120.342C172.462 120.419 172.467 120.509 172.439 120.59L171.917 122.089C171.888 122.17 171.829 122.237 171.751 122.274C171.674 122.312 171.585 122.317 171.503 122.289Z"
          fill="#3F3D56"
        />
        <path
          d="M170.15 126.171L168.65 125.649C168.569 125.621 168.502 125.562 168.465 125.484C168.428 125.407 168.422 125.317 168.451 125.236L168.973 123.737C169.001 123.656 169.061 123.589 169.138 123.552C169.216 123.514 169.305 123.509 169.386 123.537L170.886 124.059C170.967 124.088 171.034 124.147 171.071 124.225C171.109 124.302 171.114 124.391 171.086 124.473L170.563 125.972C170.535 126.053 170.475 126.119 170.398 126.157C170.32 126.194 170.231 126.2 170.15 126.171Z"
          fill="#CCCCCC"
        />
        <path
          d="M182.931 121.072L175.707 118.557C175.667 118.543 175.629 118.522 175.597 118.493C175.566 118.465 175.539 118.43 175.521 118.392C175.502 118.354 175.491 118.312 175.489 118.269C175.486 118.227 175.492 118.184 175.506 118.144C175.52 118.103 175.542 118.066 175.571 118.034C175.599 118.002 175.634 117.976 175.672 117.958C175.711 117.939 175.752 117.929 175.795 117.926C175.838 117.924 175.88 117.93 175.921 117.944L183.145 120.459C183.185 120.473 183.223 120.495 183.255 120.523C183.287 120.551 183.313 120.586 183.331 120.624C183.35 120.663 183.361 120.704 183.363 120.747C183.366 120.79 183.36 120.832 183.346 120.873C183.332 120.913 183.31 120.95 183.281 120.982C183.253 121.014 183.218 121.04 183.18 121.058C183.141 121.077 183.1 121.088 183.057 121.09C183.014 121.092 182.972 121.086 182.931 121.072Z"
          fill="#CCCCCC"
        />
        <path
          d="M181.578 124.955L174.354 122.44C174.272 122.411 174.206 122.352 174.168 122.275C174.131 122.197 174.125 122.108 174.154 122.026C174.182 121.945 174.242 121.878 174.319 121.841C174.397 121.803 174.486 121.798 174.567 121.827L181.792 124.342C181.832 124.356 181.869 124.377 181.901 124.406C181.933 124.434 181.959 124.468 181.978 124.507C181.996 124.545 182.007 124.587 182.01 124.629C182.012 124.672 182.006 124.715 181.992 124.755C181.978 124.795 181.956 124.833 181.928 124.864C181.899 124.896 181.865 124.922 181.827 124.941C181.788 124.959 181.746 124.97 181.704 124.972C181.661 124.975 181.618 124.969 181.578 124.955Z"
          fill="#CCCCCC"
        />
        <path
          d="M180.226 128.838L173.001 126.323C172.92 126.294 172.853 126.235 172.816 126.157C172.778 126.08 172.773 125.991 172.801 125.909C172.83 125.828 172.889 125.761 172.967 125.724C173.044 125.686 173.133 125.681 173.215 125.709L180.439 128.224C180.52 128.253 180.587 128.312 180.625 128.39C180.662 128.467 180.667 128.556 180.639 128.638C180.611 128.719 180.551 128.786 180.474 128.823C180.396 128.861 180.307 128.866 180.226 128.838Z"
          fill="#CCCCCC"
        />
        <path
          d="M199.451 130.942C199.503 130.816 199.553 130.687 199.599 130.557C200.192 128.852 200.085 126.982 199.299 125.357C198.513 123.732 197.114 122.486 195.409 121.893L193.034 128.708L199.451 130.942Z"
          fill="#F2682A"
        />
        <path
          d="M192.344 129.122L194.718 122.306C193.464 121.869 192.108 121.809 190.82 122.133C189.531 122.457 188.365 123.151 187.466 124.129C186.567 125.107 185.974 126.327 185.761 127.638C185.547 128.949 185.722 130.294 186.263 131.507C186.805 132.719 187.69 133.747 188.809 134.463C189.929 135.18 191.233 135.553 192.562 135.536C193.89 135.52 195.185 135.116 196.287 134.373C197.388 133.63 198.248 132.581 198.761 131.356L192.344 129.122Z"
          fill="#3F3D56"
        />
        <path
          d="M200.844 119.346C200.818 119.016 200.863 118.684 200.979 118.374C201.094 118.064 201.276 117.783 201.511 117.551C201.747 117.319 202.031 117.141 202.342 117.03C202.654 116.919 202.987 116.878 203.316 116.909L206.664 109.598L208.88 113.141L205.361 119.542C205.271 120.091 204.983 120.589 204.551 120.941C204.118 121.292 203.572 121.473 203.015 121.449C202.459 121.425 201.93 121.197 201.53 120.81C201.13 120.422 200.886 119.901 200.844 119.346Z"
          fill="#FFB8B8"
        />
        <path
          d="M206.641 116.77C206.435 116.77 206.234 116.707 206.064 116.59L204.483 115.496C204.312 115.377 204.182 115.209 204.11 115.013C204.038 114.818 204.028 114.606 204.08 114.404L205.939 107.388L203.692 94.36C203.69 93.9313 203.772 93.5062 203.934 93.1092C204.096 92.7122 204.334 92.351 204.636 92.0462C204.937 91.7414 205.296 91.499 205.692 91.3329C206.087 91.1668 206.511 91.0802 206.94 91.0782C207.369 91.0761 207.794 91.1585 208.191 91.3208C208.588 91.4831 208.949 91.722 209.254 92.0238C209.558 92.3257 209.8 92.6846 209.966 93.08C210.132 93.4755 210.218 93.8997 210.219 94.3284L212.06 102.457C212.619 104.924 212.263 107.51 211.057 109.735L207.532 116.24C207.464 116.365 207.37 116.474 207.257 116.561C207.144 116.647 207.015 116.709 206.876 116.742C206.799 116.761 206.72 116.77 206.641 116.77Z"
          fill="#3F3D56"
        />
        <path
          d="M223.774 157.001H181.001C180.941 157.001 180.884 156.977 180.841 156.935C180.799 156.893 180.775 156.835 180.775 156.776C180.775 156.716 180.799 156.659 180.841 156.617C180.884 156.574 180.941 156.551 181.001 156.551H223.774C223.834 156.551 223.891 156.574 223.934 156.617C223.976 156.659 224 156.716 224 156.776C224 156.835 223.976 156.893 223.934 156.935C223.891 156.977 223.834 157.001 223.774 157.001Z"
          fill="#CCCCCC"
        />
        <path
          d="M110.433 154.2L113.193 154.2L114.506 143.559H110.433L110.433 154.2Z"
          fill="#A0616A"
        />
        <path
          d="M109.729 153.299L115.165 153.299C115.62 153.299 116.07 153.388 116.491 153.562C116.911 153.736 117.293 153.991 117.614 154.313C117.936 154.634 118.191 155.016 118.365 155.436C118.539 155.856 118.629 156.307 118.629 156.761V156.874L109.73 156.874L109.729 153.299Z"
          fill="#2F2E41"
        />
        <path
          d="M99.4018 154.2L102.162 154.2L103.475 143.559H99.4014L99.4018 154.2Z"
          fill="#A0616A"
        />
        <path
          d="M98.6982 153.299L104.134 153.299C104.589 153.299 105.039 153.388 105.459 153.562C105.88 153.736 106.262 153.991 106.583 154.313C106.905 154.634 107.16 155.016 107.334 155.436C107.508 155.856 107.598 156.307 107.598 156.761V156.874L98.6984 156.874L98.6982 153.299Z"
          fill="#2F2E41"
        />
        <path
          d="M110.984 80.5286C114.038 80.5286 116.514 78.0541 116.514 75.0016C116.514 71.9491 114.038 69.4746 110.984 69.4746C107.931 69.4746 105.455 71.9491 105.455 75.0016C105.455 78.0541 107.931 80.5286 110.984 80.5286Z"
          fill="#A0616A"
        />
        <path
          d="M114.703 151.903H109.581C109.447 151.903 109.314 151.876 109.191 151.825C109.067 151.773 108.955 151.697 108.861 151.602C108.766 151.507 108.692 151.394 108.642 151.269C108.592 151.145 108.567 151.012 108.568 150.878L108.866 126.568C108.867 126.481 108.835 126.398 108.776 126.334C108.718 126.271 108.637 126.232 108.551 126.227C108.465 126.221 108.379 126.248 108.313 126.303C108.246 126.358 108.203 126.437 108.193 126.523L105.212 151.013C105.182 151.258 105.063 151.484 104.878 151.648C104.693 151.812 104.454 151.903 104.206 151.903H99.0355C98.8958 151.903 98.7576 151.874 98.6297 151.818C98.5017 151.762 98.3866 151.68 98.2918 151.578C98.1969 151.475 98.1243 151.354 98.0785 151.223C98.0327 151.091 98.0147 150.951 98.0255 150.811L101.44 107.138L101.533 107.128L117.884 105.363L115.715 150.938C115.703 151.199 115.591 151.444 115.402 151.624C115.214 151.803 114.963 151.903 114.703 151.903Z"
          fill="#2F2E41"
        />
        <path
          d="M101.638 109.82C101.376 109.82 101.123 109.717 100.935 109.534C100.747 109.351 100.637 109.102 100.629 108.84C100.54 106.078 100.387 96.5231 102.165 89.5082C102.65 87.5919 103.739 85.8825 105.271 84.6328C106.803 83.3831 108.697 82.6593 110.673 82.5685C112.617 82.4623 114.546 82.9709 116.185 84.0221C117.823 85.0732 119.09 86.6136 119.803 88.4246C122.792 96.0088 125.616 105.357 122.095 106.824C117.482 108.745 105.318 109.602 101.7 109.818C101.68 109.82 101.659 109.82 101.638 109.82Z"
          fill="#3F3D56"
        />
        <path
          d="M122.772 69.4163C122.859 69.5055 122.953 69.5878 123.052 69.6623L122.472 80.8072L120.058 82.0427L121.447 85.9285L125.934 84.1564C126.296 84.0133 126.602 83.7564 126.806 83.4245C127.01 83.0926 127.101 82.7035 127.065 82.3156L125.89 69.6734C126.274 69.3866 126.562 68.9895 126.716 68.5349C126.869 68.0804 126.88 67.5899 126.748 67.1288C126.616 66.6678 126.346 66.2579 125.975 65.9537C125.604 65.6495 125.149 65.4654 124.671 65.426C124.192 65.3865 123.713 65.4936 123.298 65.7328C122.882 65.9721 122.548 66.3323 122.342 66.7655C122.136 67.1987 122.067 67.6843 122.144 68.1578C122.22 68.6313 122.439 69.0703 122.772 69.4163Z"
          fill="#A0616A"
        />
        <path
          d="M122.517 81.2214L124.215 86.0458C124.264 86.1831 124.282 86.3292 124.269 86.4741C124.255 86.619 124.211 86.7594 124.139 86.8856C124.066 87.0119 123.968 87.121 123.849 87.2057C123.731 87.2903 123.595 87.3484 123.453 87.3761L118.444 88.3455C117.74 88.5887 116.969 88.543 116.298 88.2183C115.628 87.8935 115.114 87.3163 114.869 86.6132C114.624 85.9102 114.667 85.1386 114.99 84.4677C115.313 83.7968 115.89 83.2813 116.592 83.0344L121.089 80.6619C121.218 80.594 121.359 80.5546 121.505 80.5465C121.65 80.5384 121.796 80.5617 121.931 80.6148C122.067 80.6679 122.189 80.7495 122.29 80.8542C122.391 80.9589 122.469 81.0841 122.517 81.2214Z"
          fill="#3F3D56"
        />
        <path
          d="M98.8213 69.4163C98.7344 69.5055 98.6407 69.5878 98.5408 69.6623L99.1213 80.8072L101.536 82.0427L100.146 85.9285L95.6597 84.1564C95.2972 84.0133 94.991 83.7564 94.787 83.4245C94.583 83.0926 94.4922 82.7035 94.5282 82.3156L95.7036 69.6734C95.319 69.3866 95.0309 68.9895 94.8775 68.5349C94.7242 68.0804 94.7129 67.5899 94.8453 67.1288C94.9776 66.6678 95.2472 66.2579 95.6183 65.9537C95.9893 65.6495 96.4442 65.4654 96.9225 65.426C97.4008 65.3865 97.8797 65.4936 98.2956 65.7328C98.7115 65.9721 99.0447 66.3323 99.2509 66.7655C99.457 67.1987 99.5263 67.6843 99.4496 68.1578C99.3729 68.6313 99.1537 69.0703 98.8213 69.4163Z"
          fill="#A0616A"
        />
        <path
          d="M99.6612 80.6148C99.7967 80.5617 99.9421 80.5384 100.087 80.5465C100.233 80.5546 100.375 80.594 100.503 80.662L105 83.0345C105.703 83.2814 106.279 83.7969 106.602 84.4678C106.925 85.1387 106.969 85.9103 106.724 86.6133C106.478 87.3164 105.964 87.8936 105.294 88.2183C104.624 88.5431 103.852 88.5889 103.148 88.3456L98.1397 87.3762C97.9968 87.3485 97.8615 87.2904 97.7431 87.2058C97.6247 87.1211 97.5259 87.012 97.4535 86.8857C97.3811 86.7595 97.3368 86.6191 97.3235 86.4742C97.3103 86.3293 97.3285 86.1832 97.3768 86.0459L99.0752 81.2215C99.1234 81.0842 99.2008 80.9589 99.3019 80.8542C99.403 80.7495 99.5256 80.6679 99.6612 80.6148Z"
          fill="#3F3D56"
        />
        <path
          d="M113.132 75.9434C113.103 75.2383 113.28 74.5401 113.642 73.934C114.003 73.3278 114.533 72.84 115.167 72.5302C116.848 71.6629 117.904 69.8129 117.441 68.1236C116.849 65.9613 113.905 64.5589 111.519 65.3029C110.874 65.532 110.251 65.8212 109.66 66.1668L108.101 67.0019C107.435 67.3351 106.793 67.7139 106.18 68.1356C105.261 68.8125 104.541 69.7236 104.094 70.7738C103.648 71.8239 103.492 72.9746 103.643 74.1057C103.95 76.3039 105.39 78.3199 107.361 79.7002C108.115 80.2634 108.986 80.6503 109.909 80.8323C110.854 80.9881 111.906 80.8111 112.597 80.2237C113.677 79.3057 113.565 77.7684 113.216 76.4845C113.17 76.3074 113.142 76.1261 113.132 75.9434Z"
          fill="#2F2E41"
        />
        <path
          d="M131.924 157.001H89.1499C89.0902 157.001 89.033 156.977 88.9907 156.935C88.9485 156.893 88.9248 156.835 88.9248 156.776C88.9248 156.716 88.9485 156.659 88.9907 156.617C89.033 156.574 89.0902 156.551 89.1499 156.551H131.924C131.984 156.551 132.041 156.574 132.083 156.617C132.125 156.659 132.149 156.716 132.149 156.776C132.149 156.835 132.125 156.893 132.083 156.935C132.041 156.977 131.984 157.001 131.924 157.001Z"
          fill="#CCCCCC"
        />
        <path
          d="M62.1145 115.947L26.8199 130.421C26.2138 130.669 25.5341 130.666 24.9299 130.413C24.3257 130.161 23.8464 129.679 23.5972 129.074L12.793 102.749C12.6114 102.305 12.6134 101.807 12.7985 101.365C12.9835 100.922 13.3367 100.571 13.7803 100.389L50.268 85.4261C50.7198 85.2415 51.2264 85.2435 51.6766 85.4317C52.1269 85.6199 52.4841 85.979 52.6699 86.4302L63.4622 112.726C63.7101 113.332 63.7074 114.011 63.4547 114.615C63.2021 115.219 62.7201 115.698 62.1145 115.947Z"
          fill="#E6E6E6"
        />
        <path
          d="M28.0979 127.229L58.9606 114.573C59.5483 114.332 60.0161 113.868 60.2612 113.282C60.5063 112.696 60.5086 112.037 60.2674 111.45L51.5431 90.1925C51.4237 89.9016 51.2482 89.6371 51.0265 89.414C50.8049 89.191 50.5414 89.0137 50.2513 88.8924C49.9611 88.7711 49.6498 88.7081 49.3353 88.707C49.0207 88.706 48.7091 88.7669 48.4181 88.8862L17.5554 101.542C17.2644 101.661 16.9998 101.837 16.7766 102.059C16.5535 102.28 16.3761 102.543 16.2548 102.833C16.1334 103.124 16.0704 103.435 16.0693 103.749C16.0683 104.063 16.1292 104.375 16.2486 104.666L24.9729 125.923C25.0923 126.214 25.2678 126.478 25.4895 126.701C25.7111 126.925 25.9746 127.102 26.2648 127.223C26.5549 127.344 26.8662 127.407 27.1807 127.408C27.4953 127.41 27.8069 127.349 28.0979 127.229Z"
          fill="white"
        />
        <path
          d="M51.3727 95.9897L50.9189 96.1758L50.95 96.2514L51.4037 96.0653L51.3727 95.9897Z"
          fill="#E6E6E6"
        />
        <path
          d="M50.0333 96.6264L49.1167 97.0023L49.0857 96.9267L50.0023 96.5508L50.0333 96.6264ZM48.2 97.3782L47.2834 97.7541L47.2523 97.6785L48.169 97.3026L48.2 97.3782ZM46.3667 98.13L45.4501 98.5059L45.419 98.4303L46.3357 98.0544L46.3667 98.13ZM44.5334 98.8818L43.6167 99.2577L43.5857 99.1821L44.5024 98.8062L44.5334 98.8818ZM42.7001 99.6336L41.7834 100.009L41.7524 99.9339L42.6691 99.558L42.7001 99.6336ZM40.8668 100.385L39.9501 100.761L39.9191 100.686L40.8357 100.31L40.8668 100.385ZM39.0334 101.137L38.1168 101.513L38.0858 101.437L39.0024 101.062L39.0334 101.137ZM37.2001 101.889L36.2835 102.265L36.2525 102.189L37.1691 101.813L37.2001 101.889ZM35.3668 102.641L34.4502 103.017L34.4191 102.941L35.3358 102.565L35.3668 102.641ZM33.5335 103.393L32.6169 103.768L32.5858 103.693L33.5025 103.317L33.5335 103.393ZM31.7002 104.144L30.7835 104.52L30.7525 104.445L31.6692 104.069L31.7002 104.144ZM29.8669 104.896L28.9502 105.272L28.9192 105.196L29.8359 104.821L29.8669 104.896ZM28.0336 105.648L27.1169 106.024L27.0859 105.948L28.0025 105.572L28.0336 105.648ZM26.2003 106.4L25.2836 106.776L25.2526 106.7L26.1692 106.324L26.2003 106.4ZM24.3669 107.152L23.4503 107.527L23.4193 107.452L24.3359 107.076L24.3669 107.152ZM22.5336 107.903L21.617 108.279L21.5859 108.204L22.5026 107.828L22.5336 107.903Z"
          fill="#E6E6E6"
        />
        <path
          d="M20.6696 108.58L20.2158 108.766L20.2468 108.841L20.7006 108.655L20.6696 108.58Z"
          fill="#E6E6E6"
        />
        <path
          d="M39.36 109.128L38.9062 109.314L38.9373 109.39L39.391 109.204L39.36 109.128Z"
          fill="#E6E6E6"
        />
        <path
          d="M38.0347 109.761L37.1316 110.131L37.1006 110.056L38.0036 109.686L38.0347 109.761ZM36.2286 110.502L35.3255 110.872L35.2945 110.796L36.1975 110.426L36.2286 110.502ZM34.4225 111.242L33.5195 111.613L33.4884 111.537L34.3915 111.167L34.4225 111.242ZM32.6164 111.983L31.7134 112.353L31.6823 112.278L32.5854 111.907L32.6164 111.983ZM30.8103 112.724L29.9073 113.094L29.8762 113.018L30.7793 112.648L30.8103 112.724ZM29.0042 113.464L28.1012 113.835L28.0702 113.759L28.9732 113.389L29.0042 113.464ZM27.1982 114.205L26.2951 114.575L26.2641 114.5L27.1671 114.129L27.1982 114.205ZM25.3921 114.946L24.489 115.316L24.458 115.24L25.361 114.87L25.3921 114.946Z"
          fill="#E6E6E6"
        />
        <path
          d="M23.5543 115.609L23.1006 115.795L23.1316 115.871L23.5854 115.684L23.5543 115.609Z"
          fill="#E6E6E6"
        />
        <path
          d="M31.8776 116.083L31.4238 116.27L31.4549 116.345L31.9086 116.159L31.8776 116.083Z"
          fill="#E6E6E6"
        />
        <path
          d="M30.5251 116.726L29.596 117.107L29.565 117.031L30.4941 116.65L30.5251 116.726ZM28.6669 117.488L27.7378 117.869L27.7068 117.793L28.6359 117.412L28.6669 117.488ZM26.8088 118.25L25.8797 118.631L25.8486 118.555L26.7777 118.174L26.8088 118.25Z"
          fill="#E6E6E6"
        />
        <path
          d="M24.9196 118.935L24.4658 119.121L24.4968 119.197L24.9506 119.011L24.9196 118.935Z"
          fill="#E6E6E6"
        />
        <path
          d="M34.2067 121.759L33.7529 121.945L33.784 122.021L34.2377 121.835L34.2067 121.759Z"
          fill="#E6E6E6"
        />
        <path
          d="M32.8552 122.402L31.9261 122.783L31.8951 122.707L32.8242 122.326L32.8552 122.402ZM30.997 123.164L30.0679 123.545L30.0369 123.469L30.966 123.088L30.997 123.164ZM29.1388 123.926L28.2097 124.307L28.1787 124.231L29.1078 123.85L29.1388 123.926Z"
          fill="#E6E6E6"
        />
        <path
          d="M27.2496 124.611L26.7959 124.797L26.8269 124.872L27.2807 124.686L27.2496 124.611Z"
          fill="#E6E6E6"
        />
        <path
          d="M29.4092 123.882L55.5632 113.156C56.1961 112.896 56.6999 112.395 56.964 111.764C57.2281 111.133 57.2309 110.423 56.9718 109.789L51.6875 96.9141L51.6364 96.9367L45.2183 112.276L40.458 110.287C40.3702 110.25 40.275 110.234 40.1799 110.24C40.0848 110.246 39.9923 110.274 39.9097 110.321C39.7858 110.396 39.6899 110.509 39.6369 110.644L36.2732 118.682L32.7928 117.228C32.7177 117.197 32.6372 117.18 32.5558 117.18C32.4743 117.18 32.3937 117.196 32.3184 117.226C32.243 117.257 32.1745 117.303 32.1168 117.36C32.059 117.417 32.0132 117.486 31.9818 117.561C31.9595 117.615 29.8135 122.878 29.4092 123.882Z"
          fill="#F2682A"
        />
        <path
          d="M29.3283 124.004L29.2529 123.973L31.9442 117.54C32.0155 117.37 32.1512 117.236 32.3216 117.166C32.492 117.096 32.6832 117.096 32.8532 117.167L36.2649 118.593L39.6094 110.599C39.6807 110.429 39.8164 110.295 39.9868 110.225C40.1572 110.155 40.3484 110.156 40.5185 110.226L45.2099 112.187L51.6611 96.7676L51.7365 96.7991L45.2538 112.294L40.4869 110.302C40.4127 110.271 40.333 110.255 40.2525 110.254C40.172 110.254 40.0922 110.27 40.0177 110.3C39.9432 110.331 39.8755 110.376 39.8184 110.432C39.7613 110.489 39.7159 110.556 39.6848 110.631L36.3088 118.7L32.8217 117.243C32.6717 117.18 32.503 117.18 32.3526 117.241C32.2023 117.303 32.0825 117.422 32.0196 117.572L29.3283 124.004Z"
          fill="#3F3D56"
        />
        <path
          d="M31.8925 116.693C32.2085 116.693 32.4647 116.437 32.4647 116.121C32.4647 115.805 32.2085 115.549 31.8925 115.549C31.5765 115.549 31.3203 115.805 31.3203 116.121C31.3203 116.437 31.5765 116.693 31.8925 116.693Z"
          fill="#3F3D56"
        />
        <path
          d="M39.3759 109.738C39.6919 109.738 39.9481 109.482 39.9481 109.166C39.9481 108.85 39.6919 108.594 39.3759 108.594C39.0599 108.594 38.8037 108.85 38.8037 109.166C38.8037 109.482 39.0599 109.738 39.3759 109.738Z"
          fill="#3F3D56"
        />
        <path
          d="M51.0273 96.5775C51.3433 96.5775 51.5994 96.3214 51.5994 96.0055C51.5994 95.6897 51.3433 95.4336 51.0273 95.4336C50.7113 95.4336 50.4551 95.6897 50.4551 96.0055C50.4551 96.3214 50.7113 96.5775 51.0273 96.5775Z"
          fill="#3F3D56"
        />
        <path
          d="M19.0007 115.472C19.2982 115.324 19.5595 115.113 19.7664 114.853C19.9733 114.593 20.1207 114.291 20.1982 113.968C20.2757 113.645 20.2814 113.309 20.2149 112.984C20.1484 112.658 20.0114 112.352 19.8134 112.085L21.5128 95.4766H17.8402L16.4897 111.694C16.0635 112.056 15.785 112.562 15.7069 113.115C15.6289 113.669 15.7567 114.232 16.0662 114.698C16.3758 115.163 16.8454 115.5 17.3863 115.642C17.9271 115.785 18.5016 115.724 19.0007 115.472Z"
          fill="#FFB8B8"
        />
        <path
          d="M22.3727 86.3093C25.2423 86.3093 27.5686 83.984 27.5686 81.1156C27.5686 78.2472 25.2423 75.9219 22.3727 75.9219C19.503 75.9219 17.1768 78.2472 17.1768 81.1156C17.1768 83.984 19.503 86.3093 22.3727 86.3093Z"
          fill="#FFB8B8"
        />
        <path
          d="M23.3514 105.148C23.1434 105.148 22.9412 105.08 22.7758 104.954C22.6104 104.828 22.4908 104.651 22.4356 104.45C22.0193 102.939 20.9363 100.382 19.2166 96.8527C18.8285 96.0547 18.6137 95.1838 18.5861 94.2971C18.5584 93.4103 18.7187 92.5277 19.0563 91.7072C19.3939 90.8868 19.9013 90.1469 20.5451 89.5362C21.1889 88.9255 21.9546 88.4577 22.792 88.1636C23.5855 87.8835 24.4272 87.7654 25.2672 87.8162C26.1072 87.8669 26.9285 88.0856 27.6825 88.4592C28.4365 88.8329 29.1079 89.3539 29.6569 89.9914C30.2059 90.6289 30.6215 91.3701 30.8789 92.171C32.2118 96.3482 32.1475 100.823 32.0579 102.552C32.0468 102.761 31.9677 102.96 31.8326 103.119C31.6976 103.278 31.5141 103.388 31.3103 103.433L23.5556 105.126C23.4885 105.141 23.4201 105.148 23.3514 105.148Z"
          fill="#FF6584"
        />
        <path
          d="M30.8608 154.467H33.4542L34.6882 144.467L30.8604 144.467L30.8608 154.467Z"
          fill="#FFB8B8"
        />
        <path
          d="M38.4561 156.875L30.3048 156.875L30.3047 153.727L35.3066 153.727C35.7202 153.727 36.1297 153.809 36.5118 153.967C36.8939 154.125 37.2411 154.357 37.5335 154.649C37.826 154.941 38.058 155.288 38.2163 155.67C38.3746 156.052 38.456 156.462 38.4561 156.875Z"
          fill="#2F2E41"
        />
        <path
          d="M4.92578 151.106L7.19865 152.355L13.0996 144.187L9.7455 142.344L4.92578 151.106Z"
          fill="#FFB8B8"
        />
        <path
          d="M10.4219 156.875L3.27897 152.95L4.79622 150.191L9.17937 152.6C9.9113 153.002 10.4534 153.678 10.6864 154.48C10.9194 155.282 10.8243 156.143 10.4219 156.875Z"
          fill="#2F2E41"
        />
        <path
          d="M30.7397 148.916C30.5086 148.916 30.2855 148.831 30.1119 148.679C29.9384 148.526 29.8263 148.316 29.7966 148.087L26.553 123.103C26.5387 122.992 26.4898 122.889 26.4133 122.808C26.3368 122.727 26.2366 122.672 26.1271 122.651C26.0176 122.631 25.9043 122.645 25.8034 122.692C25.7025 122.74 25.6192 122.818 25.5654 122.916L12.0476 147.431C11.936 147.631 11.7563 147.784 11.5411 147.863C11.326 147.942 11.0896 147.941 10.875 147.861L7.9285 146.739C7.80998 146.693 7.70169 146.625 7.60996 146.538C7.51824 146.45 7.44494 146.345 7.39438 146.229C7.34381 146.112 7.31699 145.987 7.3155 145.861C7.314 145.734 7.33787 145.608 7.38568 145.491L16.7498 122.497C16.7735 122.438 16.7896 122.377 16.7976 122.315C18.066 112.502 21.293 107.512 22.2518 106.216C22.3218 106.121 22.3679 106.011 22.3862 105.894C22.4044 105.777 22.3943 105.658 22.3567 105.545L22.2249 105.15C22.1723 104.993 22.1615 104.825 22.1936 104.663C22.2257 104.501 22.2997 104.35 22.4081 104.225C25.863 100.291 31.8848 102.438 31.9452 102.46L31.9754 102.471L31.9943 102.497C38.7465 111.753 36.2499 141.817 35.6809 147.75C35.6597 147.973 35.5603 148.181 35.4002 148.338C35.2402 148.494 35.0298 148.589 34.8064 148.606L30.8137 148.913C30.7888 148.915 30.7642 148.916 30.7397 148.916Z"
          fill="#2F2E41"
        />
        <path
          d="M42.5731 87.1504C42.5472 87.2645 42.5302 87.3804 42.5225 87.4972L33.4348 92.7376L31.2259 91.4666L28.8711 94.548L32.5627 97.1781C32.861 97.3906 33.2201 97.5006 33.5862 97.4917C33.9524 97.4828 34.3057 97.3554 34.5932 97.1287L43.9642 89.7389C44.3871 89.8954 44.8477 89.9195 45.2846 89.808C45.7215 89.6964 46.1141 89.4546 46.4102 89.1145C46.7063 88.7745 46.8917 88.3525 46.942 87.9045C46.9922 87.4565 46.9048 87.0039 46.6914 86.6068C46.478 86.2097 46.1487 85.887 45.7473 85.6815C45.3459 85.476 44.8915 85.3976 44.4444 85.4566C43.9974 85.5156 43.5788 85.7093 43.2446 86.0118C42.9103 86.3144 42.6761 86.7115 42.5731 87.1504Z"
          fill="#FFB8B8"
        />
        <path
          d="M33.1305 92.9847L30.1934 96.7898C30.1099 96.898 30.0039 96.987 29.8828 97.0507C29.7618 97.1143 29.6284 97.1512 29.4918 97.1586C29.3552 97.1661 29.2186 97.1441 29.0913 97.094C28.964 97.044 28.8489 96.9671 28.754 96.8686L25.429 93.4173C24.8773 92.9867 24.5189 92.3551 24.4324 91.6608C24.3459 90.9665 24.5383 90.2663 24.9675 89.7136C25.3967 89.161 26.0276 88.801 26.7219 88.7126C27.4163 88.6242 28.1173 88.8146 28.6714 89.2421L32.8417 91.573C32.9611 91.6398 33.0647 91.7315 33.1454 91.8419C33.2261 91.9523 33.2821 92.0788 33.3095 92.2128C33.3369 92.3467 33.3351 92.485 33.3042 92.6183C33.2734 92.7515 33.2141 92.8765 33.1305 92.9847Z"
          fill="#FF6584"
        />
        <path
          d="M21.7468 99.5957L17.0152 98.7417C16.8806 98.7174 16.7528 98.6644 16.6405 98.5863C16.5283 98.5082 16.4341 98.4068 16.3646 98.289C16.2951 98.1712 16.2517 98.0399 16.2375 97.9039C16.2234 97.7679 16.2387 97.6304 16.2825 97.5008L17.8163 92.9613C17.9446 92.2735 18.3404 91.6646 18.9172 91.2681C19.4939 90.8716 20.2044 90.7198 20.8928 90.8462C21.5813 90.9725 22.1915 91.3666 22.5897 91.942C22.9879 92.5175 23.1416 93.2272 23.0171 93.9157L22.8674 98.6894C22.8631 98.826 22.8293 98.9602 22.7685 99.0826C22.7076 99.2051 22.621 99.313 22.5147 99.399C22.4083 99.485 22.2847 99.5471 22.1522 99.5811C22.0197 99.615 21.8814 99.62 21.7468 99.5957Z"
          fill="#FF6584"
        />
        <path
          d="M23.1952 79.9221C24.5307 80.8357 26.2842 81.7749 27.6482 80.8019C28.0748 80.4771 28.3848 80.023 28.5316 79.5074C28.6784 78.9919 28.6543 78.4426 28.4628 77.9419C27.8096 76.075 26.0152 75.2531 24.2993 74.6231C22.0686 73.8041 19.6398 73.1525 17.3369 73.7391C15.0341 74.3256 12.9989 76.5543 13.4147 78.893C13.7489 80.7738 15.5238 82.3388 15.2719 84.2324C15.0183 86.1381 12.8742 87.1412 10.9813 87.4818C9.08838 87.8224 6.97019 87.9319 5.60984 89.2909C3.87447 91.0247 4.30782 94.1235 5.94073 95.954C7.57365 97.7845 10.0378 98.6142 12.4256 99.1783C15.5892 99.9257 19.011 100.318 22.0156 99.0781C25.0203 97.8378 27.3585 94.499 26.4881 91.3682C26.1204 90.0458 25.2543 88.9282 24.4173 87.8401C23.5803 86.752 22.7327 85.6142 22.4134 84.2793C22.1474 83.1669 22.3443 81.8753 23.0823 81.0408C23.2165 80.8947 23.301 80.7099 23.3236 80.5129C23.3462 80.3158 23.3057 80.1167 23.2081 79.9441L23.1952 79.9221Z"
          fill="#2F2E41"
        />
        <path
          d="M42.999 157.001H0.225126C0.165419 157.001 0.108157 156.977 0.0659376 156.935C0.0237184 156.893 0 156.835 0 156.776C0 156.716 0.0237184 156.659 0.0659376 156.617C0.108157 156.574 0.165419 156.551 0.225126 156.551H42.999C43.0587 156.551 43.116 156.574 43.1582 156.617C43.2004 156.659 43.2241 156.716 43.2241 156.776C43.2241 156.835 43.2004 156.893 43.1582 156.935C43.116 156.977 43.0587 157.001 42.999 157.001Z"
          fill="#CCCCCC"
        />
      </g>
      <defs>
        <clipPath id="clip0_10592_47253">
          <rect width="224" height="157" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
