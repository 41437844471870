export const IlluGetResults = () => {
  return (
    <svg
      width="165"
      height="157"
      viewBox="0 0 165 157"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11891_3058)">
        <path
          d="M164.788 78.3959C164.798 86.913 163.335 95.3739 160.457 103.44C160.292 103.901 160.123 104.361 159.95 104.819C156.903 112.823 152.496 120.3 146.906 126.953C145.236 128.941 143.468 130.848 141.6 132.672C139.732 134.5 137.771 136.238 135.717 137.887C124.259 147.116 110.342 153.161 95.4979 155.355H95.4954C93.6031 155.634 91.6922 155.851 89.7627 156.007C87.4652 156.191 85.1403 156.284 82.7879 156.286C71.8482 156.299 61.0169 154.227 50.9359 150.191C40.8549 146.156 31.7292 140.239 24.0996 132.792C23.5635 132.271 23.0354 131.743 22.5154 131.207C21.2199 129.872 19.9738 128.497 18.7769 127.079C18.1049 126.282 17.4491 125.472 16.8094 124.651C12.8693 119.594 9.59109 114.102 7.04704 108.294C5.96211 105.82 5.01466 103.294 4.20858 100.726C4.15035 100.544 4.09455 100.362 4.03875 100.18C3.91745 99.788 3.8002 99.3939 3.68698 98.9975C1.7571 92.284 0.782069 85.3552 0.787868 78.3959C0.787868 35.3789 37.5011 0.505859 82.7879 0.505859C128.075 0.505859 164.788 35.3789 164.788 78.3959Z"
          fill="#F2F2F2"
        />
        <path
          d="M35.5644 47.0629C35.4375 47.795 35.4811 48.5447 35.6922 49.2592C35.9032 49.9736 36.2767 50.6354 36.7861 51.1979C37.2956 51.7604 37.9287 52.21 38.6409 52.5149C39.353 52.8199 40.1269 52.9728 40.908 52.9629L53.9975 89.1409L62.3496 83.6886L46.2265 47.4543C46.122 46.209 45.5403 45.0433 44.5915 44.1781C43.6427 43.313 42.3927 42.8085 41.0785 42.7602C39.7643 42.712 38.4771 43.1233 37.4608 43.9163C36.4445 44.7093 35.7698 45.8289 35.5644 47.0629Z"
          fill="#A0616A"
        />
        <path
          d="M103.884 59.3036C98.0708 65.5855 83.7378 76.656 71.9303 84.2791C69.3781 85.9268 66.9423 87.4108 64.7371 88.6529C63.2475 89.4917 61.8622 90.2176 60.6152 90.8099C59.7783 91.2059 58.8643 91.4335 57.9305 91.4784C56.9966 91.5233 56.0631 91.3846 55.1883 91.0709C54.3135 90.7572 53.5164 90.2754 52.8467 89.6555C52.1771 89.0356 51.6495 88.291 51.2968 87.4684L43.1211 68.6803L45.9499 66.899L52.9247 62.5044L54.5089 61.5089L61.0495 76.3057L67.9467 71.047L72.2287 67.7816L91.7485 52.9018L93.4322 52.4271L97.36 56.0681L100.138 58.6445L102.751 59.0754C103.141 59.14 103.52 59.216 103.884 59.3036Z"
          fill="#F2682A"
        />
        <path
          d="M113.644 79.4443L112.911 127.241L53.7253 125.628L63.1772 105.809C63.1092 105.173 67.3257 100.71 67.3014 100.048C67.2286 98.1103 68.7522 96.3151 68.9997 94.2872C69.5043 90.1047 66.6149 74.3101 67.9468 71.047C68.5141 69.5899 69.2411 68.1934 70.1156 66.8806C70.8726 65.7929 71.6489 65.0601 72.3913 64.795L74.5795 59.9511L76.5204 57.1858L91.7486 52.9018L93.4323 52.4271L97.36 56.0681L100.138 58.6445L102.751 59.0754C103.141 59.14 103.52 59.216 103.884 59.3036C105.636 59.6875 107.27 60.4562 108.653 61.5481C113.214 65.2283 113.745 72.1508 113.644 79.4443Z"
          fill="#F2682A"
        />
        <path
          d="M147.633 126.882C145.964 128.87 144.196 130.777 142.328 132.601L135.928 132.606L101.509 132.643L95.3134 132.65L91.194 132.654L85.0658 132.659L80.352 132.664L71.645 132.673L66.5091 132.68L55.6938 132.689L50.5312 132.696L36.2249 132.71L27.2388 132.719L24.8274 132.721C24.2912 132.2 23.7631 131.672 23.2432 131.136C21.9477 129.801 20.7015 128.426 19.5046 127.008L24.4974 127.004L27.8987 127.002L35.7736 126.992L50.9703 126.978L56.1062 126.974L58.1829 126.972H58.1902L59.8205 126.969L60.5507 126.967L62.4212 126.965H63.2121L68.758 126.96L80.7208 126.946L85.9853 126.942L91.0266 126.937L95.7525 126.932L102.533 126.925L138.749 126.891L147.633 126.882Z"
          fill="#CCCCCC"
        />
        <path
          d="M65.7488 55.1146L65.7356 55.1158C65.0357 55.1776 64.3387 54.9729 63.7977 54.5465C63.2568 54.1202 62.9163 53.5072 62.8512 52.8424L61.4715 38.8546C61.2218 36.3487 61.505 33.8208 62.3045 31.421C63.8713 26.7374 67.2806 22.8087 71.8217 20.4539C74.1665 19.2411 76.7456 18.4919 79.4034 18.2514C90.2768 17.2674 94.2503 19.4185 100.967 35.1709C107.684 50.9233 102.349 49.18 102.349 49.18C102.382 49.5172 102.344 49.8572 102.237 50.18C102.025 50.8098 101.566 51.3377 100.954 51.6533C100.641 51.8149 100.296 51.9149 99.9406 51.9475C87.0859 55.0512 74.115 58.3183 65.7466 55.1148L65.7488 55.1146Z"
          fill="#2F2E41"
        />
        <path
          d="M89.6747 31.5054C91.2883 33.7732 92.1583 36.4444 92.1746 39.1815C92.191 41.9187 91.353 44.5991 89.7666 46.8842C88.1801 49.1692 85.9164 50.9565 83.2612 52.0202C80.6061 53.0839 77.6786 53.3764 74.8486 52.8607C72.9752 52.5251 71.1905 51.8397 69.598 50.8442C68.0055 49.8488 66.6367 48.563 65.5711 47.0615C63.9608 44.7944 63.0933 42.1253 63.0778 39.3905C63.0624 36.6558 63.8997 33.9779 65.4842 31.6945C67.0688 29.4112 69.3296 27.6246 71.9816 26.56C74.6336 25.4954 77.558 25.2005 80.3862 25.7124C82.2611 26.046 84.0476 26.7299 85.6421 27.7244C87.2366 28.719 88.6073 30.0043 89.6748 31.5059L89.6747 31.5054Z"
          fill="#9E616A"
        />
        <path
          d="M56.9132 40.4086C56.7159 38.4267 56.9399 36.4274 57.5721 34.5295C57.7187 29.8867 59.1368 25.6815 65.0983 25.858C66.9411 24.9057 68.9667 24.3153 71.0547 24.1219L74.0006 23.8455C74.0337 23.8424 74.0668 23.8394 74.0999 23.8364C76.141 23.656 78.1994 23.8592 80.1578 24.4344C82.1161 25.0096 83.936 25.9456 85.5136 27.1889C87.0911 28.4322 88.3954 29.9585 89.352 31.6806C90.3085 33.4027 90.8986 35.2869 91.0886 37.2256L91.1162 37.5058L84.8835 38.0846L82.201 32.6319L82.3334 38.3226L79.1143 38.6227L77.7615 35.8716L77.8275 38.7408C73.0479 42.7394 65.8001 42.949 56.9437 40.689L56.9132 40.4086Z"
          fill="#2F2E41"
        />
        <path
          d="M76.8501 53.682C76.797 54.1429 76.8812 54.6086 77.0931 55.0263C77.1998 55.2402 77.3376 55.4389 77.5024 55.6165C77.6433 55.7678 77.8027 55.9027 77.977 56.0184C78.4933 56.3599 79.1209 56.5154 79.747 56.4571C79.786 56.4532 79.825 56.4487 79.8638 56.4435C79.9027 56.4383 79.9415 56.4323 79.9802 56.4253C80.0193 56.4187 80.0581 56.411 80.0966 56.4024C80.1351 56.3937 80.1738 56.3843 80.2125 56.3742L82.3878 55.7952L84.8258 49.3384L85.3846 55.0042L95.2762 52.3883L92.9048 28.3449L76.0452 29.9229L76.5075 30.3458C78.0438 31.7662 79.1378 33.5617 79.6691 35.5346C80.1963 37.5339 80.3591 39.6042 80.1502 41.6557C79.9551 43.7301 79.5508 45.7818 78.9428 47.7828C78.4135 49.5546 77.7773 51.296 77.0371 52.9983C76.9414 53.2168 76.8785 53.4469 76.8501 53.682Z"
          fill="#2F2E41"
        />
        <path
          d="M79.0982 129.487C78.8084 129.487 78.5222 129.426 78.2604 129.307L31.5544 130.439C31.196 130.278 30.8975 130.017 30.6984 129.692C30.4992 129.366 30.4088 128.99 30.4391 128.615C30.4693 128.239 30.6187 127.881 30.8677 127.588C31.1166 127.295 31.4534 127.08 31.8334 126.972L62.3898 118.318C62.7062 118.228 63.0413 118.216 63.3641 118.283L110.63 123.755C111.048 123.841 111.425 124.054 111.704 124.363C111.982 124.671 112.147 125.058 112.172 125.464C112.198 125.87 112.083 126.272 111.845 126.61C111.607 126.948 111.259 127.202 110.854 127.335L79.7383 129.385C79.5324 129.453 79.3161 129.487 79.0982 129.487Z"
          fill="#3F3D56"
        />
        <path
          d="M80.8162 126.399C80.6053 126.399 80.3979 126.348 80.2138 126.25L42.2817 127.933C42.0663 127.819 41.8922 127.645 41.7822 127.435C41.6723 127.225 41.6314 126.988 41.665 126.756C41.6986 126.523 41.8051 126.306 41.9706 126.132C42.1361 125.958 42.353 125.835 42.593 125.78L56.3253 122.614C56.5396 122.565 56.7639 122.571 56.9748 122.632L92.2381 122.592C92.4891 122.665 92.709 122.812 92.8655 123.012C93.022 123.212 93.1069 123.455 93.1078 123.704C93.1088 123.954 93.0256 124.197 92.8706 124.398C92.7156 124.599 92.4968 124.748 92.2463 124.823L81.183 126.346C81.0643 126.381 80.9406 126.399 80.8162 126.399Z"
          fill="#2F2E41"
        />
        <path
          d="M76.8234 124.479C77.4799 124.881 78.2213 125.141 78.9953 125.242C79.7693 125.342 80.5571 125.28 81.3031 125.06C82.0492 124.84 82.7354 124.467 83.3134 123.968C83.8914 123.469 84.3471 122.856 84.6485 122.171L124.798 125.167L122.858 115.719L81.4457 115.344C80.2014 114.939 78.8441 114.982 77.6308 115.464C76.4176 115.947 75.4328 116.835 74.8631 117.961C74.2933 119.087 74.1782 120.372 74.5395 121.574C74.9007 122.775 75.7134 123.808 76.8234 124.479Z"
          fill="#A0616A"
        />
        <path
          d="M100.538 62.9469C100.538 62.9469 106.603 58.338 113.881 66.634C118.789 72.2287 128.258 106.05 129.043 120.065C129.092 120.952 128.949 121.839 128.62 122.671C128.292 123.502 127.787 124.261 127.136 124.898C126.485 125.535 125.702 126.038 124.837 126.375C123.972 126.711 123.043 126.875 122.108 126.854L100.708 126.5L98.3147 113.722L115.21 113.912L100.538 62.9469Z"
          fill="#F2682A"
        />
        <path
          d="M76.4817 129.719C75.7476 129.719 75.0209 129.579 74.3443 129.308L31.0537 130.247C29.9112 129.794 28.9628 128.989 28.3618 127.961C27.7608 126.933 27.5424 125.742 27.7419 124.582L26.1333 88.4685C26.3466 87.1786 27.0614 86.0115 28.1364 85.1978C29.2115 84.384 30.5687 83.9827 31.94 84.0731L72.1305 85.6565C72.902 85.7046 73.6542 85.9061 74.3384 86.2481C75.0226 86.59 75.6235 87.0648 76.1021 87.6415C76.5807 88.2183 76.9263 88.8841 77.1165 89.5959C77.3068 90.3077 77.3374 91.0496 77.2063 91.7733L81.9152 125.365C81.6937 126.582 81.0275 127.686 80.0336 128.482C79.0398 129.279 77.7821 129.717 76.4817 129.719Z"
          fill="#3F3D56"
        />
        <path
          d="M43.6282 21.9763L25.5615 21.9279C25.0772 21.9273 24.6124 22.1092 24.2691 22.4336C23.9257 22.758 23.7318 23.1985 23.7298 23.6585L23.6643 46.3826C23.6633 46.8421 23.8543 47.2832 24.1955 47.609C24.5367 47.9348 25.0001 48.1187 25.4838 48.1201L35.5058 48.1478L43.5529 48.1685C44.0371 48.1694 44.5018 47.9875 44.8448 47.663C45.1878 47.3384 45.3811 46.8978 45.3822 46.4379L45.387 45.0944L45.4501 23.7161C45.4511 23.256 45.2598 22.8143 44.9181 22.4881C44.5765 22.1619 44.1126 21.9778 43.6282 21.9763Z"
          fill="#E6E6E6"
        />
        <path
          d="M42.3544 23.3336L26.8277 23.2921C26.3743 23.2916 25.9392 23.4618 25.6177 23.7655C25.2962 24.0691 25.1145 24.4815 25.1125 24.9121L25.0689 39.5223C25.0658 41.441 25.8641 43.2825 27.2885 44.6429C28.7129 46.0032 30.6473 46.7714 32.6672 46.7789L35.6173 46.7859L42.2864 46.8043C42.74 46.8052 43.1753 46.6351 43.4969 46.3314C43.8185 46.0276 44.0001 45.6151 44.0016 45.1843L44.0065 43.714L44.0599 24.9628C44.0608 24.532 43.8818 24.1185 43.562 23.813C43.2422 23.5075 42.8079 23.3351 42.3544 23.3336Z"
          fill="white"
        />
        <path
          d="M30.3327 25.5059H26.7625C26.504 25.5059 26.2561 25.6086 26.0733 25.7913C25.8905 25.9741 25.7878 26.2221 25.7878 26.4806C25.7878 26.7391 25.8905 26.987 26.0733 27.1698C26.2561 27.3526 26.504 27.4553 26.7625 27.4553H30.3327C30.5912 27.4553 30.8391 27.3526 31.0219 27.1698C31.2047 26.987 31.3074 26.7391 31.3074 26.4806C31.3074 26.2221 31.2047 25.9741 31.0219 25.7913C30.8391 25.6086 30.5912 25.5059 30.3327 25.5059Z"
          fill="#F2682A"
        />
        <path
          d="M43.0022 29.4553H26.7625C26.6345 29.4553 26.5078 29.4805 26.3895 29.5295C26.2713 29.5784 26.1638 29.6502 26.0733 29.7407C25.9828 29.8313 25.911 29.9387 25.862 30.057C25.813 30.1752 25.7878 30.302 25.7878 30.43C25.7878 30.558 25.813 30.6847 25.862 30.803C25.911 30.9212 25.9828 31.0287 26.0733 31.1192C26.1638 31.2097 26.2713 31.2815 26.3895 31.3305C26.5078 31.3795 26.6345 31.4047 26.7625 31.4047H43.0022C43.2607 31.4047 43.5086 31.302 43.6914 31.1192C43.8742 30.9364 43.9769 30.6885 43.9769 30.43C43.9769 30.1715 43.8742 29.9235 43.6914 29.7407C43.5086 29.558 43.2607 29.4553 43.0022 29.4553Z"
          fill="#F2682A"
        />
        <path
          d="M43.0022 33.4047H26.7625C26.6345 33.4047 26.5078 33.4299 26.3895 33.4789C26.2713 33.5278 26.1638 33.5996 26.0733 33.6901C25.9828 33.7807 25.911 33.8881 25.862 34.0064C25.813 34.1246 25.7878 34.2514 25.7878 34.3794C25.7878 34.5074 25.813 34.6341 25.862 34.7524C25.911 34.8706 25.9828 34.9781 26.0733 35.0686C26.1638 35.1591 26.2713 35.2309 26.3895 35.2799C26.5078 35.3289 26.6345 35.3541 26.7625 35.3541H43.0022C43.2607 35.3541 43.5086 35.2514 43.6914 35.0686C43.8742 34.8858 43.9769 34.6379 43.9769 34.3794C43.9769 34.1209 43.8742 33.8729 43.6914 33.6901C43.5086 33.5074 43.2607 33.4047 43.0022 33.4047Z"
          fill="#F2682A"
        />
        <path
          d="M43.0006 37.3541H26.7609C26.5028 37.3541 26.2553 37.4566 26.0728 37.6391C25.8904 37.8216 25.7878 38.0691 25.7878 38.3271C25.7878 38.5852 25.8904 38.8327 26.0728 39.0152C26.2553 39.1977 26.5028 39.3002 26.7609 39.3002H43.0006C43.2586 39.3002 43.5061 39.1977 43.6886 39.0152C43.8711 38.8327 43.9736 38.5852 43.9736 38.3271C43.9736 38.0691 43.8711 37.8216 43.6886 37.6391C43.5061 37.4566 43.2586 37.3541 43.0006 37.3541Z"
          fill="#F2682A"
        />
        <path
          d="M43.0022 41.3002H26.7625C26.504 41.3002 26.2561 41.4029 26.0733 41.5857C25.8905 41.7685 25.7878 42.0164 25.7878 42.2749C25.7878 42.5334 25.8905 42.7814 26.0733 42.9642C26.2561 43.147 26.504 43.2496 26.7625 43.2496H43.0022C43.2607 43.2496 43.5086 43.147 43.6914 42.9642C43.8742 42.7814 43.9769 42.5334 43.9769 42.2749C43.9769 42.0164 43.8742 41.7685 43.6914 41.5857C43.5086 41.4029 43.2607 41.3002 43.0022 41.3002Z"
          fill="#F2682A"
        />
      </g>
      <defs>
        <clipPath id="clip0_11891_3058">
          <rect
            width="164"
            height="155.78"
            fill="white"
            transform="translate(0.787842 0.505859)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
