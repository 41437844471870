import jwt from "jsonwebtoken";
import moment from "moment";

import { env } from "libs/env";
import { ENV_NAMES } from "libs/env/env-names";
import { clearAuthState } from "store/features";

import { Axios } from "./axios";

import type { AxiosError } from "axios";
import type { AppStore } from "store/store";

export const getAuthorizationHeader = (accessToken: string) =>
  "Bearer " + accessToken;

export const getIsAccessTokenExpiresSoon = (accessToken: string) => {
  const decodedToken = jwt.decode(accessToken);

  if (typeof decodedToken === "string") return false;
  if (!decodedToken?.exp) return false;

  const expiration = moment.unix(decodedToken.exp);
  const soon = moment().add(30, "seconds");

  return expiration.isBefore(soon);
};

export const checkPermissionToRefreshAccessToken = (env: {
  get: (name: string) => string;
}) => {
  return (
    env.get(ENV_NAMES.REFRESH_TOKEN) &&
    (env.get(ENV_NAMES.REMEMBER_ME) ||
      moment().subtract(3, "hours").valueOf() <
        Number(env.get(ENV_NAMES.LAST_LOGIN)))
  );
};

export const parseError = (error: AxiosError<any, any> | undefined) => {
  const status = error?.response?.status || error?.code || error?.name || 0;
  const message =
    (!!error && Axios.isCancel(error) && "Request was canceled") ||
    (status === 403 &&
      "Authorization failed. You don't have permission to view this resource") ||
    (status === 429 && "You have reached your rate limit") ||
    (status === 413 && "File is too large to upload") ||
    error?.response?.data?.error ||
    error?.response?.statusText ||
    error?.message ||
    (!error?.response && "Could not reach UbiOps due to network issues") ||
    "Something went wrong";

  return {
    ...error,
    status,
    message,
  };
};

export const signOut = (store: AppStore) => {
  env.unset(ENV_NAMES.ACCESS_TOKEN);
  env.unset(ENV_NAMES.REFRESH_TOKEN);
  env.unset(ENV_NAMES.ACTIVATION_TOKEN);
  env.unset(ENV_NAMES.REMEMBER_ME);
  store.dispatch(clearAuthState());
};
