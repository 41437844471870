import styled from "@emotion/styled";
import Pipelines from "@mui/icons-material/AccountTreeRounded";
import LaunchIcon from "@mui/icons-material/Launch";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  ToggleButton,
  ToggleButtonGroup,
  Button,
  Grid,
  Typography,
  Box,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { FormProvider, useForm } from "react-hook-form";
import { useRouteMatch, useParams, useHistory } from "react-router-dom";

import { BaseUrlContext, RootUrlContext } from "libs/contexts";
import { usePipelinesGet } from "libs/data/endpoints/pipelines/pipelines";
import { env } from "libs/env";
import { ENV_NAMES } from "libs/env/env-names";
import { useGoogleAnalytics } from "libs/hooks";
import {
  pythonRequestCodeGenerator,
  rRequestCodeGenerator,
  javascriptRequestCodeGenerator,
  pythonClientlibraryRequestCodeGenerator,
} from "libs/utilities/code-snippets-generator";
import { formatInputOutputFields } from "libs/utilities/input-parser";
import { isObjectHasData } from "libs/utilities/utils";

import {
  Card,
  Divider,
  InfoTooltip,
  Loader,
  Link,
  CardAccordion,
  SecondaryButton,
} from "components/atoms";
import { MultiCodeBlock } from "components/molecules";

const BatchModeToggleButtonGroup = styled(ToggleButtonGroup)`
  height: 2rem;
`;

const PipelineUsing = () => {
  useGoogleAnalytics();
  const theme = useTheme();
  const { projectName, pipelineName } = useParams();
  const { data: pipeline } = usePipelinesGet(projectName, pipelineName);

  const methods = useForm({
    mode: "onChange",
  });
  const history = useHistory();

  const inputFields = useMemo(
    () => formatInputOutputFields(pipeline?.input_fields),
    [pipeline]
  );

  const match = useRouteMatch();

  const baseUrl = useContext(BaseUrlContext);
  const rootUrl = useContext(RootUrlContext);

  const blobsUrl = `${env.get(ENV_NAMES.HOST)}/projects/${projectName}/blobs`;
  const configureUrl = `${baseUrl}/use-pipeline/configure`;

  const baseEndpointUrl = `${env.get(
    ENV_NAMES.HOST
  )}/projects/${projectName}/pipelines/${pipelineName}`;

  const endpointUrl = `${baseEndpointUrl}/requests`;

  const simpleRequestUrl = `${env.get(
    ENV_NAMES.REQUESTS_PAGE_URL
  )}/projects/${projectName}/pipelines/${pipelineName}`;

  const [isLoading, setIsLoading] = useState(true);
  const [isBatch, setIsBatch] = useState(false);

  const isPlain = pipeline?.input_type === "plain";

  useEffect(() => {
    if (isObjectHasData(pipeline)) {
      setIsLoading(false);
    }
  }, [pipeline]);

  const codeBlocks = useMemo(
    () => [
      {
        title: "Python (client library)",
        language: "python",
        codeBlock: pythonClientlibraryRequestCodeGenerator({
          inputFields,
          projectName,
          objectType: "pipeline",
          objectName: pipelineName,
          isBatch,
          isPlain,
        }),
      },
      {
        title: "Python",
        language: "python",
        codeBlock: pythonRequestCodeGenerator({
          inputFields,
          endpointUrl,
          isBatch,
          isPlain,
        }),
      },
      {
        title: "R",
        language: "r",
        codeBlock: rRequestCodeGenerator({
          inputFields,
          endpointUrl,
          isBatch,
          isPlain,
        }),
      },
      {
        title: "JavaScript",
        language: "javascript",
        codeBlock: javascriptRequestCodeGenerator({
          inputFields,
          endpointUrl,
          blobsUrl,
          isBatch,
          isPlain,
        }),
      },
    ],
    [
      inputFields,
      endpointUrl,
      projectName,
      pipelineName,
      blobsUrl,
      isBatch,
      isPlain,
    ]
  );

  const handleTypeChange = (_, value) => {
    if (typeof value === "boolean" && isBatch !== value) {
      setIsBatch(value);
    }
  };

  return (
    <FormProvider {...methods}>
      <form>
        <BreadcrumbsItem to={match.url}>Use pipeline</BreadcrumbsItem>
        {isLoading ? (
          <Loader />
        ) : (
          <Grid container spacing={4}>
            {env.get(ENV_NAMES.REQUESTS_PAGE_ENABLED) && (
              <Grid item xs={12}>
                <Card
                  title="Pipeline interface"
                  icon={Pipelines}
                  iconColor="primary"
                >
                  <Grid container spacing={4}>
                    <Grid item>
                      <Box display="flex" alignItems="center">
                        <div>
                          <Button
                            startIcon={<SettingsIcon fontSize="small" />}
                            color="secondary"
                            variant="contained"
                            onClick={() => history.push(configureUrl)}
                          >
                            Configure pipeline interface
                          </Button>
                        </div>
                        <InfoTooltip>
                          Configure the input and output widgets shown on the
                          pipeline interface.
                        </InfoTooltip>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box display="flex" alignItems="center">
                        <div>
                          <SecondaryButton
                            startIcon={<LaunchIcon fontSize="small" />}
                            href={simpleRequestUrl}
                            target="_blank"
                          >
                            Go to pipeline interface
                          </SecondaryButton>
                        </div>
                        <InfoTooltip>
                          An interface for your deployment or pipeline which is
                          accessible with an API token.
                        </InfoTooltip>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        You can use our automatically generated interface for
                        your deployment or pipeline. For authentication, you
                        will need{" "}
                        <Link to={`${rootUrl}/settings/api-tokens/create`}>
                          an API token
                        </Link>
                        .
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            )}
            <Divider my={2} />
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <Card>
                  <Box display="flex" marginBottom={2}>
                    <CardAccordion
                      icon={<PlayArrowIcon />}
                      summary="Make requests to this pipeline from your code"
                      details={
                        <Typography variant="body1">
                          You can use the code snippets below for making a
                          request to your pipeline. You will need{" "}
                          <Link to={`${rootUrl}/settings/api-tokens/create`}>
                            to create an API token
                          </Link>{" "}
                          and paste the token in the indicated spot for them to
                          work. The token will need at least{" "}
                          <code>pipeline-request-user</code> rights.
                        </Typography>
                      }
                    />
                    <Box>
                      <BatchModeToggleButtonGroup
                        value={isBatch}
                        exclusive
                        onChange={handleTypeChange}
                      >
                        <ToggleButton
                          disableFocusRipple={true}
                          value={false}
                          sx={{ color: theme.palette.text.primary }}
                        >
                          Express
                        </ToggleButton>
                        <ToggleButton
                          disableFocusRipple={true}
                          value={true}
                          sx={{ color: theme.palette.text.primary }}
                        >
                          Batch
                        </ToggleButton>
                      </BatchModeToggleButtonGroup>
                    </Box>
                  </Box>
                  <Grid item xs={12}>
                    <MultiCodeBlock codeBlocks={codeBlocks} />
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        )}
      </form>
    </FormProvider>
  );
};

export default PipelineUsing;
