/**
 * Generated by orval 🍺
 * Do not edit manually.
 * UbiOps
 * OpenAPI spec version: v2.1
 */
import useSwr from "swr";

import { orvalAxios } from "../../axios/index";

import type { ErrorType } from "../../axios/index";
import type {
  PipelineRequestList,
  PipelineRequestsListParams,
  PipelineRequestCreateResponse,
  DeploymentRequestsCreateDataBody,
  PipelineRequestsCreateParams,
  PipelineRequestBatchCreateResponse,
  BatchDeploymentRequestsCreateDataBody,
  BatchPipelineRequestsCreateParams,
  PipelineRequestDetail,
  DeploymentRequestsBatchGetDataBody,
  PipelineRequestDelete,
  PipelineRequestSingleDetail,
  PipelineRequestsGetParams,
  OperatorRequestDetail,
  PipelineVersionObjectRequestsGetParams,
  PipelineVersionRequestsListParams,
  PipelineVersionRequestsCreateParams,
  BatchPipelineVersionRequestsCreateParams,
  PipelineVersionRequestsGetParams,
} from "../../models";
import type { SWRConfiguration, Key } from "swr";

// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * 
### Description
List all requests for the default version of a pipeline

### Optional Parameters
The following parameters should be given as query parameters:
- `status`: Status of the request. Can be 'pending', 'processing', 'failed' or 'completed'.
- `success`: [DEPRECATED] A boolean value that indicates whether the request was successful. This field is deprecated, use 'status' instead.
- `limit`: The maximum number of requests given back, default is 50
- `offset`: The number which forms the starting point of the requests given back. If offset equals 2, then the first 2 requests will be omitted from the list.
- `sort`: Direction of sorting according to the creation date of the request, can be 'asc' or 'desc'. The default sorting is done in descending order.
- `request_schedule`: The name of a request schedule that created requests
- `start_date`: Start date of the interval for which the requests are retrieved, looking at the creation date of the request
- `end_date`: End date of the interval for which the requests are retrieved, looking at the creation date of the request
- `search_id`: A string to search inside request ids. It will filter all request ids that contain this string

If no start or end date is provided, the most recent requests are returned.

### Response Structure
A list of dictionaries containing the details of the pipeline requests with the following fields:
- `id`: Unique identifier for the pipeline request
- `pipeline`: Name of the pipeline for which the request is made
- `version`: Name of the pipeline version for which the request was made
- `status`: Status of the request
- `success`: [DEPRECATED] A boolean value that indicates whether the request was successful. NULL if the request is not yet finished. This field is deprecated, use 'status' instead.
- `time_created`: Server time that the request was made (current time)
- `time_started`: Server time that the processing of the request was started
- `time_completed`: Server time that the processing of the request was completed
- `input_size`: Size of the request data
- `output_size`: Size of the result

#### Response Examples
```
[
  {
    "id": "69eca481-8576-49e8-8e20-ea56f2005bcb",
    "pipeline": "pipeline-1",
    "version": "v1",
    "status": "pending",
    "success": null,
    "time_created": "2020-03-28T20:00:26.613+00:00",
    "time_started": null,
    "time_completed": null,
    "input_size": 10,
    "output_size": null
  },
  {
    "id": "2521378e-263e-4e2e-85e9-a96254b36536",
    "pipeline": "pipeline-1",
    "version": "v1",
    "status": "completed",
    "success": true,
    "time_created": "2020-03-28T20:00:26.613+00:00",
    "time_started": "2020-03-28T20:00:41.276+00:00",
    "time_completed": "2020-03-28T20:00:42.241+00:00",
    "input_size": 10,
    "output_size": 10
  }
]
```

With start_date="2020-03-28T20:00:26+00:00" and end_date="2020-03-28T22:00:26+00:00":
```
[
  {
    "id": "69eca481-8576-49e8-8e20-ea56f2005bcb",
    "pipeline": "pipeline-1",
    "version": "v1",
    "status": "completed",
    "success": true,
    "time_created": "2020-03-28T20:00:43.613+00:00",
    "time_started": "2020-03-28T20:00:50.276+00:00",
    "time_completed": "2020-03-28T20:00:55.241+00:00",
    "input_size": 10,
    "output_size": 10
  },
  {
    "id": "2521378e-263e-4e2e-85e9-a96254b36536",
    "pipeline": "pipeline-1",
    "version": "v1",
    "status": "completed",
    "success": true,
    "time_created": "2020-03-28T21:12:45.613+00:00",
    "time_started": "2020-03-28T21:13:00.276+00:00",
    "time_completed": "2020-03-28T21:13:05.241+00:00",
    "input_size": 10,
    "output_size": 10
  }
]
```

 * @summary List pipeline requests
 */
export const pipelineRequestsList = (
  projectName: string,
  pipelineName: string,
  params?: PipelineRequestsListParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<PipelineRequestList[]>(
    {
      url: `/projects/${projectName}/pipelines/${pipelineName}/requests`,
      method: "get",
      params,
    },
    options
  );
};

export const getPipelineRequestsListKey = (
  projectName: string,
  pipelineName: string,
  params?: PipelineRequestsListParams
) => [
  `/projects/${projectName}/pipelines/${pipelineName}/requests`,
  ...(params ? [params] : []),
];

export type PipelineRequestsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof pipelineRequestsList>>
>;
export type PipelineRequestsListQueryError = ErrorType<unknown>;

export const usePipelineRequestsList = <TError = ErrorType<unknown>>(
  projectName: string,
  pipelineName: string,
  params?: PipelineRequestsListParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof pipelineRequestsList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(projectName && pipelineName);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getPipelineRequestsListKey(projectName, pipelineName, params)
        : null);
  const swrFn = () =>
    pipelineRequestsList(projectName, pipelineName, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Make a direct request to the default version of a pipeline. This method returns all the results of the deployment/operator/sub-pipeline requests made within the pipeline version.

### Required Parameters
The input for the request. In case of a structured pipeline, this is a dictionary which contains the input fields of the pipeline as keys. In case of a plain pipeline, give a string or list of strings.

### Optional Parameters
The following parameters should be given as query parameters:
- `pipeline_timeout`: Timeout for the entire pipeline request in seconds. The maximum allowed value is 7200 (2 hours) and the default value is 3600 (1 hour).
- `deployment_timeout`: Timeout for each deployment request in the pipeline in seconds. The maximum allowed value is 3600 (1 hour) and the default value is 300  (5 minutes).


#### Request Examples
A structured pipeline request
```
{
  "pipeline-input-field-1": 5.0,
  "pipeline-input-field-2": "N"
}
```

A plain pipeline request
```
example-plain-data
```

### Response Structure
- `id`: Unique identifier for the pipeline request
- `pipeline`: Name of the pipeline for which the request is made
- `version`: Name of the pipeline version for which the request is made
- `status`: Status of the pipeline request. It can be 'completed' or 'failed'.
- `success`: [DEPRECATED] A boolean value that indicates whether the pipeline request was successful. This field is deprecated, use 'status' instead.
- `error_message`: An error message explaining why the request has failed. NULL if the request was successful.
- `deployment_requests`: A list of dictionaries containing the results of the deployment requests made for the deployment objects in the pipeline. The dictionaries contain the following fields:
    - `id`: Unique identifier for the deployment request
    - `pipeline_object`: Name of the object in the pipeline
    - `deployment`: Name of the deployment the request was made to
    - `version`: Name of the version the request was made to
    - `status`: Status of the request. It can be 'completed' or 'failed'.
    - `success`: [DEPRECATED] A boolean value that indicates whether the request was successful. This field is deprecated, use 'status' instead.
    - `error_message`: An error message explaining why the request has failed. NULL if the request was successful.
    - `sequence_id`: The sequence id based on creation date and index of bulk creation, used for sorting
- `operator_requests`: A list of dictionaries containing the results of the operator requests made for the operator objects in the pipeline. The dictionaries contain the following fields:
    - `id`: Unique identifier for the operator request
    - `pipeline_object`: Name of the object in the pipeline
    - `operator`: Name of the operator the request was made to
    - `status`: Status of the request. It can be 'completed' or 'failed'.
    - `success`: [DEPRECATED] A boolean value that indicates whether the request was successful. This field is deprecated, use 'status' instead.
    - `error_message`: An error message explaining why the request has failed. NULL if the request was successful.
    - `sequence_id`: The sequence id based on creation date and index of bulk creation, used for sorting
- `pipeline_requests`: A list of dictionaries containing the results of the sub-pipeline requests made for the sub-pipeline objects in the pipeline. The dictionaries contain the following fields:
    - `id`: Unique identifier for the sub-pipeline request
    - `pipeline_object`: Name of the object in the pipeline
    - `pipeline`: Name of the sub-pipeline the request was made to
    - `version`: Name of the version the request was made to
    - `status`: Status of the request. It can be 'completed' or 'failed'.
    - `success`: [DEPRECATED] A boolean value that indicates whether the request was successful. This field is deprecated, use 'status' instead.
    - `error_message`: An error message explaining why the request has failed. NULL if the request was successful.
    - `sequence_id`: The sequence id based on creation date and index of bulk creation, used for sorting
- `result`: A dictionary (structured output type) or string (plain output type) containing the data connected to the pipeline end
- `pipeline_timeout`: Timeout of the pipeline request in seconds
- `deployment_timeout`: Timeout for each deployment request in this pipeline request in seconds

#### Response Examples
```
{
  "id": "286f771b-6617-4985-ab49-12ed720e62b1",
  "pipeline": "pipeline-1",
  "version": "v1",
  "status": "failed",
  "success": false,
  "error_message": "Error while processing a deployment request",
  "deployment_requests": [
    {
      "id": "a7524614-bdb7-41e1-b4c1-653bb72c30b4",
      "pipeline_object": "deployment-object-1",
      "deployment": "deployment-1",
      "version": "v1",
      "sequence_id": "16699092560130860",
      "status": "completed",
      "success": true,
      "error_message": null
    },
    {
      "id": "fe322c50-58f8-4e67-b7d6-cba14273874e",
      "pipeline_object": "deployment-object-2",
      "deployment": "deployment-2",
      "version": "v1",
      "sequence_id": "16699092560130861",
      "status": "failed",
      "success": false,
      "error_message": "Invalid message format"
    }
  ],
  "operator_requests": [
    {
      "id": "bd6d6ce5-ba9d-4c91-af61-0cf16f1f5452",
      "pipeline_object": "function-1",
      "operator": "function",
      "sequence_id": "16699092560130860",
      "status": "completed",
      "success": true,
      "error_message": null
    }
  ],
  "pipeline_requests": [
    {
      "id": "dd307a3e-6eb0-4a55-981b-52e277529df1",
      "pipeline_object": "sub-pipeline-object-1",
      "pipeline": "pipeline-1",
      "version": "v1",
      "sequence_id": "16699092560130890",
      "status": "completed",
      "success": true,
      "error_message": null
    },
    {
      "id": "411aa6f8-7706-45e7-9438-892e399947a1",
      "pipeline_object": "sub-pipeline-object-2",
      "pipeline": "pipeline-2",
      "version": "v1",
      "sequence_id": "16699092560130891",
      "status": "failed",
      "success": false,
      "error_message": "Invalid message format"
    }
  ],
  "result": {
    "output_field": 23.5
  },
  "pipeline_timeout": 300,
  "deployment_timeout": 300
}
```

 * @summary Create a pipeline request
 */
export const pipelineRequestsCreate = (
  projectName: string,
  pipelineName: string,
  deploymentRequestsCreateDataBody: DeploymentRequestsCreateDataBody,
  params?: PipelineRequestsCreateParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<PipelineRequestCreateResponse>(
    {
      url: `/projects/${projectName}/pipelines/${pipelineName}/requests`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: deploymentRequestsCreateDataBody,
      params,
    },
    options
  );
};

/**
 * 
### Description
Make a batch request to the default version of a pipeline. The request follows an asynchronous method, as the requests are queued in our back-end and can be collected at a later time using the pipeline request collect methods.

The maximum number of requests that can be created per batch is 250.

### Required Parameters
In case of structured input pipeline: A list of dictionaries, where each dictionary contains the input fields of the pipeline as keys. It is also possible to send a single dictionary as input.
In case of plain input pipeline: A list of strings. It is also possible to send a single string as input.

### Optional Parameters
These parameters should be given as query parameters
- `timeout`: Timeout for the entire pipeline request in seconds. The maximum allowed value is 172800 (48 hours) and the default value is 14400 (4 hours).
The deployment request timeouts default to 14400 seconds for deployments in the pipeline.
- `notification_group`: Name of a notification group to send notifications (e.g., emails) when the request is completed

#### Request Examples
Multiple structured batch pipeline requests
```
[
  {
    "pipeline-input-field-1": 5.0,
    "pipeline-input-field-2": "N",
    "pipeline-input-field-3": [0.25, 0.25, 2.1, 16.3]
  },
  {
    "pipeline-input-field-1": 3.0,
    "pipeline-input-field-2": "S",
    "pipeline-input-field-3": [4.23, 3.27, 2.41, 12.4]
  }
]
```

Multiple plain batch pipeline requests
```
[
  "plain-data-goes-here", "plain-example-data"
]
```

### Response Structure
A list of dictionaries containing the details of the created pipeline requests with the following fields:
- `id`: Unique identifier for the pipeline request, which can be used to collect the result
- `pipeline`: Name of the pipeline for which the request is made
- `version`: Name of the pipeline version for which the request is made
- `status`: Status of the request. Always 'pending' when initialised, later it can be 'processing', 'failed' or 'completed'.
- `time_created`: Server time that the request was made (current time)

#### Response Examples
```
[
  {
    "id": "69eca481-8576-49e8-8e20-ea56f2005bcb",
    "pipeline": "pipeline-1",
    "version": "v1",
    "status": "pending",
    "time_created": "2020-03-28T20:00:26.613+00:00"
  },
  {
    "id": "2521378e-263e-4e2e-85e9-a96254b36536",
    "pipeline": "pipeline-1",
    "version": "v1",
    "status": "pending",
    "time_created": "2020-03-28T20:00:26.613+00:00"
  }
]
```

 * @summary Create a batch pipeline request
 */
export const batchPipelineRequestsCreate = (
  projectName: string,
  pipelineName: string,
  batchDeploymentRequestsCreateDataBody: BatchDeploymentRequestsCreateDataBody,
  params?: BatchPipelineRequestsCreateParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<PipelineRequestBatchCreateResponse[]>(
    {
      url: `/projects/${projectName}/pipelines/${pipelineName}/requests/batch`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: batchDeploymentRequestsCreateDataBody,
      params,
    },
    options
  );
};

/**
 * 
### Description
Retrieve multiple pipeline requests for the default version of a pipeline. If one of the given pipeline requests does not exist, an error message is given and no request is returned. A maximum of 250 pipeline requests can be retrieved with this method. The pipeline requests are NOT returned in the order they are given in.

### Required Parameters
A list of ids of the requests

#### Request Examples
```
["2521378e-263e-4e2e-85e9-a96254b36536", "69eca481-8576-49e8-8e20-ea56f2005bcb"]
```

### Response Structure
A list of dictionaries containing the details of the retrieved pipeline requests with the following fields:
- `id`: Unique identifier for the pipeline request
- `pipeline`: Name of the pipeline for which the request is made
- `version`: Name of the pipeline version for which the request was made
- `status`: Status of the request. Can be 'pending', 'processing', 'failed' or 'completed'.
- `success`: [DEPRECATED] A boolean value that indicates whether the request was successful. This field is deprecated, use 'status' instead.
- `time_created`: Server time that the request was made (current time)
- `time_started`: Server time that the processing of the request was started
- `time_completed`: Server time that the processing of the request was completed
- `request_data`: A dictionary (structured input type) or string (plain input type) containing the data that was sent when the request was created
- `result`: A dictionary (structured output type) or string (plain output type) containing the data connected to the pipeline end
- `deployment_requests`: A list of requests to the deployments in the pipeline. With the deployment request ids provided in this list, it's possible to collect the results of the deployment requests separately.
- `operator_requests`: A list of requests of the operators in the pipeline. With the operator request ids provided in this list, it's possible to collect the results of the operator requests separately.
- `pipeline_requests`: A list of requests to the sub-pipelines in the pipeline. With the sub-pipeline request ids provided in this list, it's possible to collect the results of the sub-pipeline requests separately.
- `error_message`: An error message explaining why the request has failed. NULL if the request was successful.
- `pipeline_timeout`: Timeout of the pipeline request in seconds
- `deployment_timeout`: Timeout for each deployment request in this pipeline request in seconds
- `input_size`: Size of the request data
- `output_size`: Size of the result

#### Response Examples
```
[
  {
    "id": "69eca481-8576-49e8-8e20-ea56f2005bcb",
    "pipeline": "pipeline-1",
    "version": "v1",
    "status": "completed",
    "success": null,
    "time_created": "2020-063-28T20:00:26.613+00:00",
    "time_started": "2020-03-28T20:00:41.276+00:00",
    "time_completed": "2020-03-28T20:00:42.241+00:00",
    "request_data": {
      "input_field": 23.5
    },
    "deployment_requests": [
      {
        "id": "4b9c8a81-b3ef-437a-8d35-187490eda3e4",
        "pipeline_object": "deployment-1-v1-object",
        "deployment": "deployment-1",
        "version": "v1",
        "sequence_id": "16699092560130860",
        "status": "completed",
        "success": true,
        "error_message": null,
        "input_size": 10,
        "output_size": 10
      }
    ],
    "operator_requests": [
      {
        "id": "bd6d6ce5-ba9d-4c91-af61-0cf16f1f5452",
        "pipeline_object": "function-1",
        "operator": "function",
        "sequence_id": "16699092560130861",
        "status": "completed",
        "success": true,
        "error_message": null,
        "input_size": 10,
        "output_size": 10
      }
    ],
    "pipeline_requests": [
      {
        "id": "73d673b3-79e0-466e-af44-d841087a5c15",
        "pipeline_object": "sub-pipeline-1-v1-object",
        "pipeline": "pipeline-1",
        "version": "v1",
        "sequence_id": "16699092560130890",
        "status": "completed",
        "success": true,
        "error_message": null,
        "input_size": 10,
        "output_size": 10
      }
    ],
    "result": {
      "output_field": 23.5
    },
    "error_message": null,
    "pipeline_timeout": 300,
    "deployment_timeout": 300,
    "input_size": 20,
    "output_size": 21
  },
  {
    "id": "69eca481-8576-49e8-8e20-ea56f2005bcb",
    "pipeline": "pipeline-1",
    "version": "v1",
    "status": "processing",
    "success": null,
    "time_created": "2020-063-28T20:00:26.613+00:00",
    "time_started": "2020-03-28T20:00:41.276+00:00",
    "time_completed": null,
    "request_data": {
      "input_field": 23.5
    },
    "deployment_requests": [
      {
        "id": "5fa86ad1-7949-48f5-8e2c-210cce78f427",
        "pipeline_object": "deployment-1-v1-object",
        "deployment": "deployment-1",
        "version": "v1",
        "sequence_id": "16699092560130860",
        "status": "processing",
        "success": null,
        "error_message": null,
        "input_size": 10,
        "output_size": null
      }
    ],
    "operator_requests": [],
    "pipeline_requests": [],
    "result": null,
    "error_message": null,
    "pipeline_timeout": 300,
    "deployment_timeout": 300,
    "input_size": 20,
    "output_size": null
  }
]
```

 * @summary Retrieve multiple pipeline requests
 */
export const pipelineRequestsBatchGet = (
  projectName: string,
  pipelineName: string,
  deploymentRequestsBatchGetDataBody: DeploymentRequestsBatchGetDataBody,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<PipelineRequestDetail[]>(
    {
      url: `/projects/${projectName}/pipelines/${pipelineName}/requests/collect`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: deploymentRequestsBatchGetDataBody,
    },
    options
  );
};

/**
 * 
### Description
Delete multiple pipeline requests for the default version of a pipeline. If one of the given pipeline requests does not exist, an error message is given and no request is deleted. A maximum of 250 pipeline requests can be deleted with this method.

### Required Parameters
A list of ids of the requests

#### Request Examples
```
["2521378e-263e-4e2e-85e9-a96254b36536", "69eca481-8576-49e8-8e20-ea56f2005bcb"]
```

 * @summary Delete multiple pipeline requests
 */
export const pipelineRequestsBatchDelete = (
  projectName: string,
  pipelineName: string,
  deploymentRequestsBatchGetDataBody: DeploymentRequestsBatchGetDataBody,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<PipelineRequestDelete>(
    {
      url: `/projects/${projectName}/pipelines/${pipelineName}/requests/delete`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: deploymentRequestsBatchGetDataBody,
    },
    options
  );
};

/**
 * 
### Description
Get a request for the default version of a pipeline. With this method, the result of the request may be retrieved.

### Optional Parameters
The following parameters should be given as query parameters:
- `metadata_only`: A boolean value that indicates whether the response should include the request data and result. The default value is False.

### Response Structure
A dictionary containing the details of the pipeline request with the following fields:
- `id`: Unique identifier for the pipeline request
- `pipeline`: Name of the pipeline for which the request is made
- `version`: Name of the pipeline version for which the request was made
- `status`: Status of the request. Can be 'pending', 'processing', 'failed' or 'completed'.
- `success`: [DEPRECATED] A boolean value that indicates whether the request was successful. NULL if the request is not yet finished. This field is deprecated, use 'status' instead.
- `time_created`: Server time that the request was made (current time)
- `time_started`: Server time that the processing of the request was started
- `time_completed`: Server time that the processing of the request was completed
- `request_data`: A dictionary (structured input type) or string (plain input type) containing the data that was sent when the request was created
- `deployment_requests`: A list of requests of the deployments in the pipeline. With the deployment request ids provided in this list, it's possible to collect the results of the deployment requests separately.
- `operator_requests`: A list of requests of the operators in the pipeline. With the operator request ids provided in this list, it's possible to collect the results of the operator requests separately.
- `pipeline_requests`: A list of requests of the sub-pipelines in the pipeline. With the sub-pipeline request ids provided in this list, it's possible to collect the results of the sub-pipeline requests separately.
- `result`: A dictionary (structured output type) or string (plain output type) containing the data connected to the pipeline end
- `error_message`: An error message explaining why the request has failed. NULL if the request was successful.
- `pipeline_timeout`: Timeout of the pipeline request in seconds
- `deployment_timeout`: Timeout for each deployment request in this pipeline request in seconds
- `notification_group`: Name of a notification group to send notifications (e.g., emails) when the request is completed
- `origin`: A dictionary containing the information on where the request originated from. It contains:
    - the pipeline (and version) names if the request is directly made to the pipeline
    - the pipeline request id if the request is part of another pipeline request
    - the request schedule name if the request is created via a request schedule
    - a `created_by` field with the email of the user that created the request. In case the request is created by a service, the field will have a "UbiOps" value.
- `input_size`: Size of the request data
- `output_size`: Size of the result

#### Response Examples
```
{
  "id": "69eca481-8576-49e8-8e20-ea56f2005bcb",
  "pipeline": "pipeline-1",
  "version": "v1",
  "status": "completed",
  "success": null,
  "time_created": "2020-03-28T20:00:26.613+00:00",
  "time_started": "2020-03-28T20:00:41.276+00:00",
  "time_completed": "2020-03-28T20:00:42.241+00:00",
  "request_data": {
    "input_field": 23.5
  },
  "deployment_requests": [
    {
      "id": "4b9c8a81-b3ef-437a-8d35-187490eda3e4",
      "pipeline_object": "deployment-1-v1-object",
      "deployment": "deployment-1",
      "version": "v1",
      "sequence_id": "16699092560130860",
      "status": "completed",
      "success": true,
      "error_message": null,
      "input_size": 10,
      "output_size": 10
    }
  ],
  "operator_requests": [
    {
      "id": "bd6d6ce5-ba9d-4c91-af61-0cf16f1f5452",
      "pipeline_object": "function-1",
      "operator": "function",
      "sequence_id": "16699092560130861",
      "status": "completed",
      "success": true,
      "error_message": null,
      "input_size": 10,
      "output_size": 10
    }
  ],
  "pipeline_requests": [
    {
      "id": "a152612e-b5d1-4f44-a04b-fe4a26849b02",
      "pipeline_object": "sub-pipeline-1-v1-object",
      "pipeline": "pipeline-1",
      "version": "v1",
      "sequence_id": "1669909256013090",
      "status": "completed",
      "success": true,
      "error_message": null,
      "input_size": 10,
      "output_size": 10
    }
  ],
  "result": {
    "output_field": 23.5
  },
  "error_message": null,
  "pipeline_timeout": 300,
  "deployment_timeout": 300,
  "notification_group": "notification-group-1",
  "origin": {
    "pipeline": "pipeline-1",
    "pipeline_version": "v1",
    "created_by": "my.example.user@ubiops.com"
  },
  "input_size": 20,
  "output_size": 21
}
```

 * @summary Get a pipeline request
 */
export const pipelineRequestsGet = (
  projectName: string,
  pipelineName: string,
  requestId: string,
  params?: PipelineRequestsGetParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<PipelineRequestSingleDetail>(
    {
      url: `/projects/${projectName}/pipelines/${pipelineName}/requests/${requestId}`,
      method: "get",
      params,
    },
    options
  );
};

export const getPipelineRequestsGetKey = (
  projectName: string,
  pipelineName: string,
  requestId: string,
  params?: PipelineRequestsGetParams
) => [
  `/projects/${projectName}/pipelines/${pipelineName}/requests/${requestId}`,
  ...(params ? [params] : []),
];

export type PipelineRequestsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof pipelineRequestsGet>>
>;
export type PipelineRequestsGetQueryError = ErrorType<unknown>;

export const usePipelineRequestsGet = <TError = ErrorType<unknown>>(
  projectName: string,
  pipelineName: string,
  requestId: string,
  params?: PipelineRequestsGetParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof pipelineRequestsGet>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false &&
    !!(projectName && pipelineName && requestId);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getPipelineRequestsGetKey(
            projectName,
            pipelineName,
            requestId,
            params
          )
        : null);
  const swrFn = () =>
    pipelineRequestsGet(
      projectName,
      pipelineName,
      requestId,
      params,
      requestOptions
    );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Delete a request for the default version of a pipeline. This action deletes all the deployment requests in the pipeline.

 * @summary Delete a pipeline request
 */
export const pipelineRequestsDelete = (
  projectName: string,
  pipelineName: string,
  requestId: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<void>(
    {
      url: `/projects/${projectName}/pipelines/${pipelineName}/requests/${requestId}`,
      method: "delete",
    },
    options
  );
};

/**
 * 
### Description
Get a request for an operator object of a version of a pipeline. With this method, the result of the request may be retrieved.

### Optional Parameters
The following parameters should be given as query parameters:
- `metadata_only`: A boolean value that indicates whether the response should include the request data and result. The default value is False.

### Response Structure
A dictionary containing the details of the operator request with the following fields:
- `id`: Unique identifier for the pipeline version object request
- `pipeline_request_id`: Unique identifier for the pipeline request to which the object request belongs
- `pipeline`: Name of the pipeline for which the request is made
- `version`: Name of the pipeline version for which the request was made
- `object`: Name of the pipeline version object for which the request was made
- `operator`: Name of the pipeline operator for which the request was made
- `status`: Status of the request. Can be 'failed' or 'completed'.
- `success`: [DEPRECATED] A boolean value that indicates whether the request was successful. This field is deprecated, use 'status' instead.
- `time_created`: Server time that the request was made
- `time_started`: Server time that the processing of the request was started
- `time_completed`: Server time that the processing of the request was completed
- `request_data`: A dictionary containing the data that was sent when the request was created
- `result`: Request result value. NULL if the request failed.
- `error_message`: An error message explaining why the request has failed
- `input_size`: Size of the request data
- `output_size`: Size of the result

#### Response Examples
```
{
  "id": "69eca481-8576-49e8-8e20-ea56f2005bcb",
  "pipeline_request_id": "ce488fed-04c2-4ce5-a839-f6e5580ad40f",
  "pipeline": "pipeline-1",
  "version": "v1",
  "object": "function-1",
  "operator": "function",
  "status": "completed",
  "success": true,
  "time_created": "2020-03-28T20:00:26.613+00:00",
  "time_started": "2020-03-28T20:00:41.276+00:00",
  "time_completed": "2020-03-28T20:00:42.241+00:00",
  "request_data": {
    "input_field": 23.5
  },
  "result": {
    "output": 23.5
  },
  "error_message": "",
  "input_size": 20,
  "output_size": 15
}
```

 * @summary Get an operator request
 */
export const pipelineVersionObjectRequestsGet = (
  projectName: string,
  pipelineName: string,
  version: string,
  requestId: string,
  params?: PipelineVersionObjectRequestsGetParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<OperatorRequestDetail>(
    {
      url: `/projects/${projectName}/pipelines/${pipelineName}/versions/${version}/object-requests/${requestId}`,
      method: "get",
      params,
    },
    options
  );
};

export const getPipelineVersionObjectRequestsGetKey = (
  projectName: string,
  pipelineName: string,
  version: string,
  requestId: string,
  params?: PipelineVersionObjectRequestsGetParams
) => [
  `/projects/${projectName}/pipelines/${pipelineName}/versions/${version}/object-requests/${requestId}`,
  ...(params ? [params] : []),
];

export type PipelineVersionObjectRequestsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof pipelineVersionObjectRequestsGet>>
>;
export type PipelineVersionObjectRequestsGetQueryError = ErrorType<unknown>;

export const usePipelineVersionObjectRequestsGet = <
  TError = ErrorType<unknown>
>(
  projectName: string,
  pipelineName: string,
  version: string,
  requestId: string,
  params?: PipelineVersionObjectRequestsGetParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof pipelineVersionObjectRequestsGet>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false &&
    !!(projectName && pipelineName && version && requestId);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getPipelineVersionObjectRequestsGetKey(
            projectName,
            pipelineName,
            version,
            requestId,
            params
          )
        : null);
  const swrFn = () =>
    pipelineVersionObjectRequestsGet(
      projectName,
      pipelineName,
      version,
      requestId,
      params,
      requestOptions
    );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
List all requests for a pipeline version

### Optional Parameters
The following parameters should be given as query parameters:
- `status`: Status of the request. Can be 'pending', 'processing', 'failed' or 'completed'.
- `success`: [DEPRECATED] A boolean value that indicates whether the request was successful. This field is deprecated, use 'status' instead.
- `limit`: The maximum number of requests given back, default is 50
- `offset`: The number which forms the starting point of the requests given back. If offset equals 2, then the first 2 requests will be omitted from the list.
- `sort`: Direction of sorting according to the creation date of the request, can be 'asc' or 'desc'. The default sorting is done in descending order.
- `request_schedule`: The name of a request schedule that created requests
- `start_date`: Start date of the interval for which the requests are retrieved, looking at the creation date of the request
- `end_date`: End date of the interval for which the requests are retrieved, looking at the creation date of the request
- `search_id`: A string to search inside request ids. It will filter all request ids that contain this string

If no start or end date is provided, the most recent requests are returned.

### Response Structure
A list of dictionaries containing the details of the pipeline version requests with the following fields:
- `id`: Unique identifier for the pipeline version request
- `pipeline`: Name of the pipeline for which the request is made
- `version`: Name of the pipeline version for which the request was made
- `status`: Status of the request
- `success`: [DEPRECATED] A boolean value that indicates whether the request was successful. NULL if the request is not yet finished. This field is deprecated, use 'status' instead.
- `time_created`: Server time that the request was made (current time)
- `time_started`: Server time that the processing of the request was started
- `time_completed`: Server time that the processing of the request was completed
- `input_size`: Size of the request data
- `output_size`: Size of the result

#### Response Examples
```
[
  {
    "id": "69eca481-8576-49e8-8e20-ea56f2005bcb",
    "pipeline": "pipeline-1",
    "version": "v1",
    "status": "pending",
    "success": null,
    "time_created": "2020-03-28T20:00:26.613+00:00",
    "time_started": null,
    "time_completed": null,
    "input_size": 10,
    "output_size": null
  },
  {
    "id": "2521378e-263e-4e2e-85e9-a96254b36536",
    "pipeline": "pipeline-1",
    "version": "v1",
    "status": "completed",
    "success": true,
    "time_created": "2020-03-28T20:00:26.613+00:00",
    "time_started": "2020-03-28T20:00:41.276+00:00",
    "time_completed": "2020-03-28T20:00:42.241+00:00",
    "input_size": 10,
    "output_size": 10
  }
]
```

With start_date="2020-03-28T20:00:26+00:00" and end_date="2020-03-28T22:00:26+00:00":
```
[
  {
    "id": "69eca481-8576-49e8-8e20-ea56f2005bcb",
    "pipeline": "pipeline-1",
    "version": "v1",
    "status": "completed",
    "success": true,
    "time_created": "2020-03-28T20:00:43.613+00:00",
    "time_started": "2020-03-28T20:00:50.276+00:00",
    "time_completed": "2020-03-28T20:00:55.241+00:00",
    "input_size": 10,
    "output_size": 10
  },
  {
    "id": "2521378e-263e-4e2e-85e9-a96254b36536",
    "pipeline": "pipeline-1",
    "version": "v1",
    "status": "completed",
    "success": true,
    "time_created": "2020-03-28T21:12:45.613+00:00",
    "time_started": "2020-03-28T21:13:00.276+00:00",
    "time_completed": "2020-03-28T21:13:05.241+00:00",
    "input_size": 10,
    "output_size": 10
  }
]
```

 * @summary List pipeline version requests
 */
export const pipelineVersionRequestsList = (
  projectName: string,
  pipelineName: string,
  version: string,
  params?: PipelineVersionRequestsListParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<PipelineRequestList[]>(
    {
      url: `/projects/${projectName}/pipelines/${pipelineName}/versions/${version}/requests`,
      method: "get",
      params,
    },
    options
  );
};

export const getPipelineVersionRequestsListKey = (
  projectName: string,
  pipelineName: string,
  version: string,
  params?: PipelineVersionRequestsListParams
) => [
  `/projects/${projectName}/pipelines/${pipelineName}/versions/${version}/requests`,
  ...(params ? [params] : []),
];

export type PipelineVersionRequestsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof pipelineVersionRequestsList>>
>;
export type PipelineVersionRequestsListQueryError = ErrorType<unknown>;

export const usePipelineVersionRequestsList = <TError = ErrorType<unknown>>(
  projectName: string,
  pipelineName: string,
  version: string,
  params?: PipelineVersionRequestsListParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof pipelineVersionRequestsList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(projectName && pipelineName && version);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getPipelineVersionRequestsListKey(
            projectName,
            pipelineName,
            version,
            params
          )
        : null);
  const swrFn = () =>
    pipelineVersionRequestsList(
      projectName,
      pipelineName,
      version,
      params,
      requestOptions
    );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Make a direct request to a pipeline version. This method returns all the results of the deployment requests made within the pipeline version.

### Required Parameters
The input for the request. In case of a structured pipeline, this is a dictionary which contains the input fields of the pipeline as keys. In case of a plain pipeline, give a string or list of strings.

### Optional Parameters
The following parameters should be given as query parameters:
- `pipeline_timeout`: Timeout for the entire pipeline request in seconds. The maximum allowed value is 7200 (2 hours) and the default value is 3600 (1 hour).
- `deployment_timeout`: Timeout for each deployment request in the pipeline in seconds. The maximum allowed value is 3600 (1 hour) and the default value is 300 (5 minutes).


#### Request Examples
A structured pipeline request
```
{
  "pipeline-input-field-1": 5.0,
  "pipeline-input-field-2": "N"
}
```

A plain pipeline request
```
example-plain-data
```

### Response Structure
- `id`: Unique identifier for the pipeline request
- `pipeline`: Name of the pipeline for which the request is made
- `version`: Name of the pipeline version for which the request is made
- `status`: Status of the pipeline request. It can be 'completed' or 'failed'.
- `success`: [DEPRECATED] A boolean value that indicates whether the pipeline request was successful. This field is deprecated, use 'status' instead.
- `error_message`: An error message explaining why the request has failed. NULL if the request was successful.
- `deployment_requests`: A list of dictionaries containing the results of the deployment requests made for the deployment objects in the pipeline. The dictionaries contain the following fields:
    - `id`: Unique identifier for the deployment request
    - `pipeline_object`: Name of the object in the pipeline
    - `deployment`: Name of the deployment the request was made to
    - `version`: Name of the version the request was made to
    - `status`: Status of the request. It can be 'completed' or 'failed'.
    - `success`: [DEPRECATED] A boolean value that indicates whether the request was successful. This field is deprecated, use 'status' instead.
    - `error_message`: An error message explaining why the request has failed. NULL if the request was successful.
    - `sequence_id`: The sequence id based on creation date and index of bulk creation, used for sorting
- `operator_requests`: A list of dictionaries containing the results of the operator requests made for the operator objects in the pipeline. The dictionaries contain the following fields:
    - `id`: Unique identifier for the operator request
    - `pipeline_object`: Name of the object in the pipeline
    - `operator`: Name of the operator the request was made to
    - `status`: Status of the request. It can be 'completed' or 'failed'.
    - `success`: [DEPRECATED] A boolean value that indicates whether the request was successful. This field is deprecated, use 'status' instead.
    - `error_message`: An error message explaining why the request has failed. NULL if the request was successful.
    - `sequence_id`: The sequence id based on creation date and index of bulk creation, used for sorting
- `pipeline_requests`: A list of dictionaries containing the results of the sub-pipeline requests made for the sub-pipeline objects in the pipeline. The dictionaries contain the following fields:
    - `id`: Unique identifier for the sub-pipeline request
    - `pipeline_object`: Name of the object in the pipeline
    - `pipeline`: Name of the sub-pipeline the request was made to
    - `version`: Name of the version the request was made to
    - `status`: Status of the request. It can be 'completed' or 'failed'.
    - `success`: [DEPRECATED] A boolean value that indicates whether the request was successful. This field is deprecated, use 'status' instead.
    - `error_message`: An error message explaining why the request has failed. NULL if the request was successful.
    - `sequence_id`: The sequence id based on creation date and index of bulk creation, used for sorting
- `result`: A dictionary (structured output type) or string (plain output type) containing the data connected to the pipeline end
- `pipeline_timeout`: Timeout of the pipeline request in seconds
- `deployment_timeout`: Timeout for each deployment request in this pipeline request in seconds

#### Response Examples
```
{
  "id": "286f771b-6617-4985-ab49-12ed720e62b1",
  "project": "project-1",
  "pipeline": "pipeline-1",
  "version": "v1",
  "status": "failed",
  "success": false,
  "error_message": "Error while processing a deployment request",
  "deployment_requests": [
    {
      "id": "a7524614-bdb7-41e1-b4c1-653bb72c30b4",
      "pipeline_object": "deployment-object-1",
      "deployment": "deployment-1",
      "version": "v1",
      "sequence_id": "16699092560130860",
      "status": "completed",
      "success": true,
      "error_message": null
    },
    {
      "id": "fe322c50-58f8-4e67-b7d6-cba14273874e",
      "pipeline_object": "deployment-object-2",
      "deployment": "deployment-2",
      "version": "v1",
      "sequence_id": "16699092560130861",
      "status": "failed",
      "success": false,
      "error_message": "Invalid message format"
    }
  ],
  "operator_requests": [
    {
      "id": "bd6d6ce5-ba9d-4c91-af61-0cf16f1f5452",
      "pipeline_object": "function-1",
      "operator": "function",
      "sequence_id": "16699092560130860",
      "status": "completed",
      "success": true,
      "error_message": null
    }
  ],
  "pipeline_requests": [
    {
      "id": "dd307a3e-6eb0-4a55-981b-52e277529df1",
      "pipeline_object": "sub-pipeline-object-1",
      "pipeline": "pipeline-1",
      "version": "v1",
      "sequence_id": "16699092560130890",
      "status": "completed",
      "success": true,
      "error_message": null
    },
    {
      "id": "411aa6f8-7706-45e7-9438-892e399947a1",
      "pipeline_object": "sub-pipeline-object-2",
      "pipeline": "pipeline-2",
      "version": "v1",
      "sequence_id": "16699092560130891",
      "status": "failed",
      "success": false,
      "error_message": "Invalid message format"
    }
  ],
  "result": {
    "output_field": 23.5
  },
  "pipeline_timeout": 300,
  "deployment_timeout": 300
}
```

 * @summary Create a pipeline version request
 */
export const pipelineVersionRequestsCreate = (
  projectName: string,
  pipelineName: string,
  version: string,
  deploymentRequestsCreateDataBody: DeploymentRequestsCreateDataBody,
  params?: PipelineVersionRequestsCreateParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<PipelineRequestCreateResponse>(
    {
      url: `/projects/${projectName}/pipelines/${pipelineName}/versions/${version}/requests`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: deploymentRequestsCreateDataBody,
      params,
    },
    options
  );
};

/**
 * 
### Description
Make a batch request to a pipeline version. The request follows an asynchronous method, as the requests are queued in our back-end and can be collected at a later time using the pipeline version request collect methods.

The maximum number of requests that can be created per batch is 250.

### Required Parameters
In case of structured input pipeline: A list of dictionaries, where each dictionary contains the input fields of the pipeline as keys. It is also possible to send a single dictionary as input.
In case of plain input pipeline: A list of strings. It is also possible to send a single string as input.

### Optional Parameters
These parameters should be given as query parameters
- `timeout`: Timeout for the entire pipeline request in seconds. The maximum allowed value is 172800 (48 hours) and the default value is 14400 (4 hours).
The deployment request timeouts default to 14400 seconds for deployments in the pipeline.
- `notification_group`: Name of a notification group to send notifications (e.g., emails) when the request is completed

#### Request Examples
Multiple structured batch pipeline requests
```
[
  {
    "pipeline-input-field-1": 5.0,
    "pipeline-input-field-2": "N",
    "pipeline-input-field-3": [0.25, 0.25, 2.1, 16.3]
  },
  {
    "pipeline-input-field-1": 3.0,
    "pipeline-input-field-2": "S",
    "pipeline-input-field-3": [4.23, 3.27, 2.41, 12.4]
  }
]
```

Multiple plain batch pipeline requests
```
[
  "plain-data-goes-here", "plain-example-data"
]
```

### Response Structure
A list of dictionaries containing the details of the created pipeline version requests with the following fields:
- `id`: Unique identifier for the pipeline version request, which can be used to collect the result
- `pipeline`: Name of the pipeline for which the request is made
- `version`: Name of the pipeline version for which the request is made
- `status`: Status of the request. Always 'pending' when initialised, later it can be 'processing', 'failed' or 'completed'.
- `time_created`: Server time that the request was made (current time)

#### Response Examples
```
[
  {
    "id": "69eca481-8576-49e8-8e20-ea56f2005bcb",
    "pipeline": "pipeline-1",
    "version": "v1",
    "status": "pending",
    "time_created": "2020-03-28T20:00:26.613+00:00"
  },
  {
    "id": "2521378e-263e-4e2e-85e9-a96254b36536",
    "pipeline": "pipeline-1",
    "version": "v1",
    "status": "pending",
    "time_created": "2020-03-28T20:00:26.613+00:00"
  }
]
```

 * @summary Create a batch pipeline version request
 */
export const batchPipelineVersionRequestsCreate = (
  projectName: string,
  pipelineName: string,
  version: string,
  batchDeploymentRequestsCreateDataBody: BatchDeploymentRequestsCreateDataBody,
  params?: BatchPipelineVersionRequestsCreateParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<PipelineRequestBatchCreateResponse[]>(
    {
      url: `/projects/${projectName}/pipelines/${pipelineName}/versions/${version}/requests/batch`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: batchDeploymentRequestsCreateDataBody,
      params,
    },
    options
  );
};

/**
 * 
### Description
Retrieve multiple requests for a pipeline version. If one of the given pipeline requests does not exist, an error message is given and no request is returned. A maximum of 250 pipeline version requests can be retrieved with this method. The pipeline version requests are NOT returned in the order they are given in.

### Required Parameters
A list of ids of the requests

#### Request Examples
```
["2521378e-263e-4e2e-85e9-a96254b36536", "69eca481-8576-49e8-8e20-ea56f2005bcb"]
```

### Response Structure
A list of dictionaries containing the details of the retrieved pipeline requests with the following fields:
- `id`: Unique identifier for the pipeline request
- `pipeline`: Name of the pipeline for which the request is made
- `version`: Name of the pipeline version for which the request was made
- `status`: Status of the request. Can be 'pending', 'processing', 'failed' or 'completed'.
- `success`: [DEPRECATED] A boolean value that indicates whether the request was successful. NULL if the request is not yet finished. This field is deprecated, use 'status' instead.
- `time_created`: Server time that the request was made (current time)
- `time_started`: Server time that the processing of the request was started
- `time_completed`: Server time that the processing of the request was completed
- `request_data`: A dictionary (structured input type) or string (plain input type) containing the data that was sent when the request was created
- `result`: A dictionary (structured output type) or string (plain output type) containing the data connected to the pipeline end
- `deployment_requests`: A list of requests to the deployments in the pipeline. With the deployment request ids provided in this list, it's possible to collect the results of the deployment requests separately.
- `operator_requests`: A list of requests of the operators in the pipeline. With the operator request ids provided in this list, it's possible to collect the results of the operator requests separately.
- `pipeline_requests`: A list of requests to the sub-pipelines in the pipeline. With the sub-pipeline request ids provided in this list, it's possible to collect the results of the sub-pipeline requests separately.
- `error_message`: An error message explaining why the request has failed. NULL if the request was successful.
- `pipeline_timeout`: Timeout of the pipeline request in seconds
- `deployment_timeout`: Timeout for each deployment request in this pipeline request in seconds
- `input_size`: Size of the request data
- `output_size`: Size of the result

#### Response Examples
```
[
  {
    "id": "69eca481-8576-49e8-8e20-ea56f2005bcb",
    "pipeline": "pipeline-1",
    "version": "v1",
    "status": "completed",
    "success": null,
    "time_created": "2020-063-28T20:00:26.613+00:00",
    "time_started": "2020-03-28T20:00:41.276+00:00",
    "time_completed": "2020-03-28T20:00:42.241+00:00",
    "request_data": {
      "input_field": 23.5
    },
    "deployment_requests": [
      {
        "id": "4b9c8a81-b3ef-437a-8d35-187490eda3e4",
        "pipeline_object": "deployment-1-v1-object",
        "deployment": "deployment-1",
        "version": "v1",
        "sequence_id": "16699092560130860",
        "status": "completed",
        "success": true,
        "error_message": null,
        "input_size": 10,
        "output_size": 10
      }
    ],
    "operator_requests": [
      {
        "id": "bd6d6ce5-ba9d-4c91-af61-0cf16f1f5452",
        "pipeline_object": "function-1",
        "operator": "function",
        "sequence_id": "16699092560130861",
        "status": "completed",
        "success": true,
        "error_message": null,
        "input_size": 10,
        "output_size": 10
      }
    ],
    "pipeline_requests": [
      {
        "id": "73d673b3-79e0-466e-af44-d841087a5c15",
        "pipeline_object": "sub-pipeline-1-v1-object",
        "pipeline": "pipeline-1",
        "version": "v1",
        "sequence_id": "16699092560130890",
        "status": "completed",
        "success": true,
        "error_message": null,
        "input_size": 10,
        "output_size": 10
      }
    ],
    "result": {
      "output_field": 23.5
    },
    "error_message": null,
    "pipeline_timeout": 300,
    "deployment_timeout": 300,
    "input_size": 20,
    "output_size": 21
  },
  {
    "id": "69eca481-8576-49e8-8e20-ea56f2005bcb",
    "pipeline": "pipeline-1",
    "version": "v1",
    "status": "processing",
    "success": null,
    "time_created": "2020-063-28T20:00:26.613+00:00",
    "time_started": "2020-03-28T20:00:41.276+00:00",
    "time_completed": null,
    "request_data": {
      "input_field": 23.5
    },
    "deployment_requests": [
      {
        "id": "5fa86ad1-7949-48f5-8e2c-210cce78f427",
        "pipeline_object": "deployment-1-v1-object",
        "deployment": "deployment-1",
        "version": "v1",
        "sequence_id": "16699092560130850",
        "status": "processing",
        "success": null,
        "error_message": null,
        "input_size": 10,
        "output_size": null
      }
    ],
    "operator_requests": [],
    "pipeline_requests": [],
    "result": null,
    "error_message": null,
    "pipeline_timeout": 300,
    "deployment_timeout": 300,
    "input_size": 20,
    "output_size": null
  }
]
```

 * @summary Retrieve multiple pipeline version requests
 */
export const pipelineVersionRequestsBatchGet = (
  projectName: string,
  pipelineName: string,
  version: string,
  deploymentRequestsBatchGetDataBody: DeploymentRequestsBatchGetDataBody,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<PipelineRequestDetail[]>(
    {
      url: `/projects/${projectName}/pipelines/${pipelineName}/versions/${version}/requests/collect`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: deploymentRequestsBatchGetDataBody,
    },
    options
  );
};

/**
 * 
### Description
Delete multiple requests for a pipeline version. If one of the given pipeline requests does not exist, an error message is given and no request is deleted. A maximum of 250 pipeline requests can be deleted with this method.

### Required Parameters
A list of ids of the requests

#### Request Examples
```
["2521378e-263e-4e2e-85e9-a96254b36536", "69eca481-8576-49e8-8e20-ea56f2005bcb"]
```

 * @summary Delete multiple pipeline version requests
 */
export const pipelineVersionRequestsBatchDelete = (
  projectName: string,
  pipelineName: string,
  version: string,
  deploymentRequestsBatchGetDataBody: DeploymentRequestsBatchGetDataBody,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<PipelineRequestDelete>(
    {
      url: `/projects/${projectName}/pipelines/${pipelineName}/versions/${version}/requests/delete`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: deploymentRequestsBatchGetDataBody,
    },
    options
  );
};

/**
 * 
### Description
Get a request for a pipeline version. With this method, the result of a request may be retrieved.

### Optional Parameters
The following parameters should be given as query parameters:
- `metadata_only`: A boolean value that indicates whether the response should include the request data and result. The default value is False.

### Response Structure
A dictionary containing the details of the pipeline version request with the following fields:
- `id`: Unique identifier for the pipeline version request
- `pipeline`: Name of the pipeline for which the request is made
- `version`: Name of the pipeline version for which the request was made
- `status`: Status of the request. Can be 'pending', 'processing', 'failed' or 'completed'.
- `success`: [DEPRECATED] A boolean value that indicates whether the request was successful. NULL if the request is not yet finished. This field is deprecated, use 'status' instead.
- `time_created`: Server time that the request was made (current time)
- `time_started`: Server time that the processing of the request was started
- `time_completed`: Server time that the processing of the request was completed
- `request_data`: A dictionary (structured input type) or string (plain input type) containing the data that was sent when the request was created
- `deployment_requests`: A list of requests of the deployments in the pipeline. With the deployment request ids provided in this list, it's possible to collect the results of the deployment requests separately.
- `operator_requests`: A list of requests of the operators in the pipeline. With the operator request ids provided in this list, it's possible to collect the results of the operator requests separately.
- `pipeline_requests`: A list of requests of the sub-pipelines in the pipeline. With the sub-pipeline request ids provided in this list, it's possible to collect the results of the sub-pipeline requests separately.
- `result`: A dictionary (structured output type) or string (plain output type) containing the data connected to the pipeline end
- `error_message`: An error message explaining why the request has failed. NULL if the request was successful.
- `pipeline_timeout`: Timeout of the pipeline request in seconds
- `deployment_timeout`: Timeout for each deployment request in this pipeline request in seconds
- `notification_group`: Name of a notification group to send notifications (e.g., emails) when the request is completed
- `origin`: A dictionary containing the information on where the request originated from. It contains:
    - the pipeline (and version) names if the request is directly made to the pipeline
    - the pipeline request id if the request is part of another pipeline request
    - the request schedule name if the request is created via a request schedule
    - a `created_by` field with the email of the user that created the request. In case the request is created by a service, the field will have a "UbiOps" value.
- `input_size`: Size of the request data
- `output_size`: Size of the result

#### Response Examples
```
{
  "id": "69eca481-8576-49e8-8e20-ea56f2005bcb",
  "pipeline": "pipeline-1",
  "version": "v1",
  "status": "completed",
  "success": null,
  "time_created": "2020-03-28T20:00:26.613+00:00",
  "time_started": "2020-03-28T20:00:41.276+00:00",
  "time_completed": "2020-03-28T20:00:42.241+00:00",
  "request_data": {
    "input_field": 23.5
  },
  "deployment_requests": [
    {
      "id": "4b9c8a81-b3ef-437a-8d35-187490eda3e4",
      "pipeline_object": "deployment-1-v1-object",
      "deployment": "deployment-1",
      "version": "v1",
      "sequence_id": "16699092560130860",
      "status": "completed",
      "success": true,
      "error_message": null,
       "input_size": 10,
      "output_size": 10
    }
  ],
  "operator_requests": [
    {
      "id": "bd6d6ce5-ba9d-4c91-af61-0cf16f1f5452",
      "pipeline_object": "function-1",
      "operator": "function",
      "sequence_id": "16699092560130861",
      "status": "completed",
      "success": true,
      "error_message": null,
      "input_size": 10,
      "output_size": 10
    }
  ],
  "pipeline_requests": [
    {
      "id": "a152612e-b5d1-4f44-a04b-fe4a26849b02",
      "pipeline_object": "sub-pipeline-1-v1-object",
      "pipeline": "pipeline-1",
      "version": "v1",
      "sequence_id": "1669909256013090",
      "status": "completed",
      "success": true,
      "error_message": null,
      "input_size": 10,
      "output_size": 10
    }
  ],
  "result": {
    "output_field": 23.5
  },
  "error_message": null,
  "pipeline_timeout": 300,
  "deployment_timeout": 300,
  "notification_group": "notification-group-1",
  "origin": {
    "pipeline": "pipeline-1",
    "pipeline_version": "v1",
    "created_by": "my.example.user@ubiops.com"
  },
  "input_size": 20,
  "output_size": 21
}
```

 * @summary Get a pipeline version request
 */
export const pipelineVersionRequestsGet = (
  projectName: string,
  pipelineName: string,
  version: string,
  requestId: string,
  params?: PipelineVersionRequestsGetParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<PipelineRequestSingleDetail>(
    {
      url: `/projects/${projectName}/pipelines/${pipelineName}/versions/${version}/requests/${requestId}`,
      method: "get",
      params,
    },
    options
  );
};

export const getPipelineVersionRequestsGetKey = (
  projectName: string,
  pipelineName: string,
  version: string,
  requestId: string,
  params?: PipelineVersionRequestsGetParams
) => [
  `/projects/${projectName}/pipelines/${pipelineName}/versions/${version}/requests/${requestId}`,
  ...(params ? [params] : []),
];

export type PipelineVersionRequestsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof pipelineVersionRequestsGet>>
>;
export type PipelineVersionRequestsGetQueryError = ErrorType<unknown>;

export const usePipelineVersionRequestsGet = <TError = ErrorType<unknown>>(
  projectName: string,
  pipelineName: string,
  version: string,
  requestId: string,
  params?: PipelineVersionRequestsGetParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof pipelineVersionRequestsGet>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false &&
    !!(projectName && pipelineName && version && requestId);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getPipelineVersionRequestsGetKey(
            projectName,
            pipelineName,
            version,
            requestId,
            params
          )
        : null);
  const swrFn = () =>
    pipelineVersionRequestsGet(
      projectName,
      pipelineName,
      version,
      requestId,
      params,
      requestOptions
    );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Delete a request for a pipeline version. This action deletes all the deployment requests in the pipeline.

 * @summary Delete a pipeline version request
 */
export const pipelineVersionRequestsDelete = (
  projectName: string,
  pipelineName: string,
  version: string,
  requestId: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<void>(
    {
      url: `/projects/${projectName}/pipelines/${pipelineName}/versions/${version}/requests/${requestId}`,
      method: "delete",
    },
    options
  );
};
