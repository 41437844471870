import DeleteIcon from "@mui/icons-material/Delete";

import { spacing } from "assets/styles/theme";

import { useDeleteEdge } from "../useDeleteEdge";

export type EdgeDeleteButtonProps = {
  id: string;
};

export const EdgeDeleteButton = ({ id }: EdgeDeleteButtonProps) => {
  const deleteEdge = useDeleteEdge();

  return (
    <DeleteIcon
      onClick={() => deleteEdge(id)}
      style={{ marginLeft: spacing[4] }}
    />
  );
};
