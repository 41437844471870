import { Grid, Typography, useTheme } from "@mui/material";

import "./FormSection.scss";

import type { AppThemeProps } from "assets/styles/theme/theme";

export type FormSectionProps = {
  title?: React.ReactNode;
  description?: React.ReactNode;
  critical?: boolean;
  small?: boolean;
};

export const FormSection: React.FC<FormSectionProps> = ({
  title,
  description,
  critical = false,
  small = false,
  children,
}) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <Grid item container spacing={5} className="form-section">
      {title && (
        <Grid
          item
          className={
            small ? "form-section__header--small" : "form-section__header"
          }
          xs={12}
          sm={small ? 12 : 5}
          sx={{ borderRight: `${theme.palette.border.primary} 2px solid` }}
        >
          <Typography
            variant="h3"
            gutterBottom
            color={critical ? "error" : "default"}
          >
            {title}
          </Typography>
          {description}
        </Grid>
      )}
      <Grid item className="form-section__content" xs={12} sm={small ? 12 : 7}>
        {children}
      </Grid>
    </Grid>
  );
};
