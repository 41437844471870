import { MTableGroupRow, MTableCell } from "@material-table/core";
import Plus from "@mui/icons-material/AddBoxRounded";
import Trash from "@mui/icons-material/DeleteRounded";
import Edit from "@mui/icons-material/EditRounded";
import {
  Link,
  Box,
  Grid,
  Typography,
  Tooltip,
  IconButton,
  useTheme,
} from "@mui/material";
import { useEffect, useMemo, useState, useCallback } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useForm, FormProvider } from "react-hook-form";
import { useParams, useRouteMatch } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { AutoCompleteSelectMultipleHookForm } from "components/atoms/UncontrolledAutoComplete/AutoCompleteSelectMultipleHookForm";
import {
  FIELD_ADVANCED_PERMISSIONS,
  FIELD_NAME,
  FIELD_PERMISSIONS,
} from "libs/constants/fields";
import { USER_ROLE_PERMISSIONS } from "libs/constants/permissions";
import { useProjectRoleCreate } from "libs/data/customized/roles/useProjectRoleCreate";
import { useProjectRoleDelete } from "libs/data/customized/roles/useProjectRoleDelete";
import { useProjectRoleUpdate } from "libs/data/customized/roles/useProjectRoleUpdate";
import {
  projectsUpdate,
  useProjectsGet,
} from "libs/data/endpoints/projects/projects";
import {
  usePermissionsList,
  useRolesGet,
  useRolesList,
} from "libs/data/endpoints/roles/roles";
import { useGoogleAnalytics } from "libs/hooks";
import { explanations } from "libs/utilities/explanations";
import { roleGroups } from "libs/utilities/labels-mapping";
import { formatLabel } from "libs/utilities/utils";
import validators from "libs/utilities/validators";
import { useGetPermissions } from "store/features/permissions";

import {
  FormTextField,
  InfoTooltip,
  ActionDialog,
  Loader,
  FullScreenLoader,
  DeleteDialog,
  PrimaryButton,
  Switch,
  Chip,
} from "components/atoms";
import { AdvancedTable } from "components/molecules";
import { RoleDetailsDialog } from "components/organisms";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type { AutocompleteSelectOption } from "components/atoms/AutoCompleteSelect";
import type { PermissionList, RoleDetailList } from "libs/data/models";

const EDIT_ACTION_TYPE = "edit";
const VIEW_ACTION_TYPE = "view";

const permissionsRules = {
  required: validators.required.message("permissions"),
};

interface GroupedRole {
  name: string;
  id?: string;
  default?: boolean | string;
  group: string;
}

interface RoleDetailListForm extends Omit<RoleDetailList, "permissions"> {
  permissions?: AutocompleteSelectOption[];
}

const RolesOverview = () => {
  useGoogleAnalytics();

  const { projectName } = useParams<{ projectName: string }>();
  const { data: project, mutate: mutateProject } = useProjectsGet(projectName);
  const match = useRouteMatch();

  const createFormMethods = useForm();
  const updateFormMethods = useForm();

  const theme = useTheme() as AppThemeProps;

  const enableAdvancedPermissionsState = useMemo(
    () => Boolean(project?.[FIELD_ADVANCED_PERMISSIONS]),
    [project]
  );

  const [roleToDelete, setRoleToDelete] = useState<GroupedRole | null>(null);
  const [showRoleDialog, setShowRoleDialog] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [groupedData, setGroupedData] = useState<GroupedRole[]>([]);
  const [selectedRole, setSelectedRole] = useState<string>("");
  const [currentRolePermissions, setCurrentRolePermissions] =
    useState<{ name: string | null }[] | null>(null);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [permissionOptions, setPermissionOptions] = useState<PermissionList[]>(
    []
  );
  const [isTableDataLoading, setIsTableDataLoading] = useState<boolean>(true);

  const { data: permissions } = usePermissionsList();
  const [currentPermissions] = useGetPermissions();

  const { data: currentRole } = useRolesGet(projectName, selectedRole);

  const {
    data: projectRoles,
    error: projectRolesError,
    mutate: mutateRoles,
  } = useRolesList(projectName);
  const projectRoleCreate = useProjectRoleCreate(projectName);
  const projectRoleUpdate = useProjectRoleUpdate(
    projectName,
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    currentRole?.name
  );
  const projectRoleDelete = useProjectRoleDelete(projectName);

  useMemo(() => {
    if (!projectRolesError && !projectRoles) {
      setIsTableDataLoading(true);
    } else {
      setIsTableDataLoading(false);
    }
  }, [projectRolesError, projectRoles]);

  const onRoleDetailsClick = useCallback(
    ({ name }) => {
      // @ts-expect-error TS(2345): Argument of type '"view"' is not assignable to par... Remove this comment to see the full error message
      setActionType(VIEW_ACTION_TYPE);
      setSelectedRole(name);
    },
    [setActionType]
  );

  const columns = useMemo(
    () => [
      {
        title: "",
        field: "group",
        defaultGroupOrder: 0,
        nowrap: true,
        render: (group: string) => (
          <Typography variant="subtitle1">{formatLabel(group)}</Typography>
        ),
        customSort: (a: string, b: string) =>
          roleGroups.indexOf(a) - roleGroups.indexOf(b),
      },
      {
        title: "Name",
        field: "name",
        nowrap: true,
        render: (rowData: GroupedRole) =>
          currentPermissions[USER_ROLE_PERMISSIONS["role_get"]] ? (
            <Link
              component="button"
              variant="h5"
              onClick={() => onRoleDetailsClick(rowData)}
              sx={{ color: theme.palette.table.nameColumn }}
            >
              {rowData.name}
            </Link>
          ) : (
            rowData.name
          ),
        grouping: false,
      },
      {
        title: (
          <Grid container alignItems="center" component="span">
            <Grid item component="span">
              <p>Type</p>
            </Grid>
            <Grid item component={InfoTooltip}>
              {explanations.permissions.roles.type}
            </Grid>
          </Grid>
        ),
        field: "default",
        customSort: (
          a: { default: number; name: string },
          b: { default: number; name: string }
        ) => 100 * (a.default - b.default) + a.name.localeCompare(b.name),
        // sort first on 'default' false -> true, and then on role 'name'.
        // the value returned by localeCompare depends on the browser.
        defaultSort: "asc",
        editable: "never",
        width: "50%",
        nowrap: true,
        grouping: false,
        render: (rowData: { default: boolean }) => (
          <Chip
            label={rowData.default ? "Default" : "Custom"}
            variant="outlined"
            size="medium"
            sx={{
              width: "75px",
              height: "25px",
              backgroundColor: rowData.default
                ? "inherit"
                : theme.palette.neutrals[100],
            }}
          />
        ),
      },
      {
        disableClick: true,
        width: "8%",
        render: (rowData: GroupedRole) =>
          !rowData?.default && (
            <div className="actions_container">
              <Tooltip title="Edit role">
                <>
                  {/* @ts-expect-error TS(2769): No overload matches this call. */}
                  <IconButton
                    color="secondary"
                    status="none"
                    disabled={
                      !currentPermissions[USER_ROLE_PERMISSIONS["role_update"]]
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      // @ts-expect-error TS(2345): Argument of type '"edit"' is not assignable to par... Remove this comment to see the full error message
                      setActionType(EDIT_ACTION_TYPE);
                      setSelectedRole(rowData.name);
                      setUpdateDialogOpen(true);
                    }}
                  >
                    <Edit />
                  </IconButton>
                </>
              </Tooltip>
              <Tooltip title="Delete role">
                <span>
                  <IconButton
                    color="primary"
                    disabled={
                      !currentPermissions[USER_ROLE_PERMISSIONS["role_delete"]]
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      setRoleToDelete(rowData);
                      setDeleteDialogOpen(true);
                    }}
                  >
                    <Trash />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          ),
      },
    ],

    [
      currentPermissions,
      onRoleDetailsClick,
      theme.palette.neutrals,
      theme.palette.table.nameColumn,
    ]
  );

  // Reset action type when create and update dialogs are both closed
  useEffect(() => {
    if (!createDialogOpen && !updateDialogOpen) {
      setActionType(null);
    }
  }, [createDialogOpen, updateDialogOpen]);

  useEffect(() => {
    if (currentRole?.permissions) {
      setCurrentRolePermissions(
        currentRole.permissions.map((permission: any) => ({
          name: permission,
        }))
      );
    }
  }, [currentRole?.permissions]);

  useEffect(() => {
    if (projectRoles) {
      setGroupedData(
        projectRoles.map((role: RoleDetailList) => {
          let group = "other";
          for (const element of roleGroups) {
            if (role.name.includes(element)) {
              group = element;
            }
          }

          return { ...role, group };
        })
      );
    }
  }, [projectRoles, enableAdvancedPermissionsState, project]);

  useEffect(() => {
    if (currentRole) {
      if (actionType === EDIT_ACTION_TYPE) {
        setUpdateDialogOpen(true);
      } else if (actionType === VIEW_ACTION_TYPE) {
        setShowRoleDialog(true);
      }
    }
  }, [actionType, currentRole, setUpdateDialogOpen]);

  useEffect(() => {
    if (permissions) {
      setPermissionOptions(permissions);
    }
  }, [permissions, project]);

  const onCreate = (data: RoleDetailListForm) => {
    if (data[FIELD_PERMISSIONS]) {
      const newRole = {
        name: data[FIELD_NAME],
        permissions:
          (data[FIELD_PERMISSIONS].map(
            ({ value }) => value
          ) as PermissionList) || [],
      };
      projectRoleCreate(newRole as RoleDetailList);
    }

    setCreateDialogOpen(false);
  };

  const onUpdate = (data: RoleDetailListForm) => {
    if (data[FIELD_NAME] && data[FIELD_PERMISSIONS]) {
      const updatedRole = {
        name: data?.[FIELD_NAME],
        permissions: data[FIELD_PERMISSIONS].map(({ value }) => value || ""),
      };
      projectRoleUpdate(updatedRole);
    }

    setUpdateDialogOpen(false);
  };

  const onDelete = () => {
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    projectRoleDelete(roleToDelete.name);
    setDeleteDialogOpen(false);
  };

  const handleEnableAdvancedPermissions = async () => {
    setIsTableDataLoading(true);
    await projectsUpdate(projectName, {
      [FIELD_ADVANCED_PERMISSIONS]: !enableAdvancedPermissionsState,
    });
    await mutateProject();
    await mutateRoles();
    setIsTableDataLoading(false);
  };

  const permissionOptionList = useMemo(
    () =>
      permissionOptions.map(({ name = "" }) => ({
        label: name,
        value: name,
      })),
    [permissionOptions]
  );

  const currentPermissionOptions = useMemo(
    () =>
      (currentRolePermissions || []).map(({ name = "" }) => ({
        label: name,
        value: name,
      })),
    [currentRolePermissions]
  );

  return (
    <>
      <BreadcrumbsItem to={match.url}>Roles</BreadcrumbsItem>
      <FullScreenLoader displayed={isTableDataLoading} />
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Grid item>
          {enableAdvancedPermissionsState !== undefined && (
            <Switch
              checked={enableAdvancedPermissionsState}
              onChange={handleEnableAdvancedPermissions}
              disabled={isTableDataLoading}
              label="Enable advanced permissions"
              labelPlacement="start"
              infoTooltipText="We have a basic and an advanced permission system for UbiOps. Both work
            the same but the basic system is a lot simpler to use and suits most use
            cases. However, if you have more advanced needs you might need more
            granular permission control. In that case you can switch to the advanced
            permission system."
            />
          )}
        </Grid>
      </Grid>
      <AdvancedTable
        columns={columns}
        data={groupedData}
        options={{
          padding: "default",
          grouping: true,
          headerStyle: {
            borderBottom: "none",
          },
        }}
        title={
          <Tooltip
            title={
              !currentPermissions[USER_ROLE_PERMISSIONS["role_create"]]
                ? "You do not have permissions to perform this action."
                : enableAdvancedPermissionsState !== true
                ? "Please turn on the advanced permission system with the toggle on the right to be able to create custom roles."
                : ""
            }
          >
            <span>
              <PrimaryButton
                startIcon={<Plus />}
                onClick={() => setCreateDialogOpen(true)}
                disabled={
                  !currentPermissions[USER_ROLE_PERMISSIONS["role_create"]] ||
                  !project?.[FIELD_ADVANCED_PERMISSIONS]
                }
              >
                New role
              </PrimaryButton>
            </span>
          </Tooltip>
        }
        components={{
          Groupbar: () => <></>,
          GroupRow: (props: any) => {
            // eslint-disable-next-line react/prop-types
            props.groupData.isExpanded = true;

            return <MTableGroupRow {...props} />;
          },
          // `props.children` is an array only when coming from `GroupRow`.
          // It contains an Icon and a title that we don't want to display.
          // See https://github.com/material-table-core/core/blob/0e953441fd9f9912d8cf97db103a8e0cb4f43912/src/components/MTableGroupRow/index.js#L137-L151
          Cell: (props: any) => <MTableCell {...props}>{null}</MTableCell>,
        }}
        deletionText={(row: any) =>
          `Are you sure you want to delete role "${row?.name}"?`
        }
        onRowClick={(_: any, rowData: any) => onRoleDetailsClick(rowData)}
      />
      <FormProvider {...createFormMethods}>
        <ActionDialog
          open={createDialogOpen}
          onClose={() => setCreateDialogOpen(false)}
          dialogBodyStyles={{ maxHeight: spacing[310] }}
          onAction={createFormMethods.handleSubmit(onCreate)}
          actionButtonText="Create"
          dialogTitle="Create a new project role"
        >
          <form>
            <Box>
              <FormTextField
                id={FIELD_NAME}
                name={FIELD_NAME}
                label="Name"
                rules={{
                  required: validators.required.message("role name"),
                }}
                placeholder="Ex: my-custom-role"
              />
            </Box>
            <Box my={2}>
              <AutoCompleteSelectMultipleHookForm
                name={FIELD_PERMISSIONS}
                label="Permissions"
                ListboxProps={{ style: { maxHeight: spacing[200] } }}
                options={permissionOptionList || []}
                isSearchable
                isMulti
                rules={permissionsRules}
              />
            </Box>
          </form>
        </ActionDialog>
      </FormProvider>
      <FormProvider {...updateFormMethods}>
        <ActionDialog
          open={updateDialogOpen}
          onClose={() => setUpdateDialogOpen(false)}
          onAction={updateFormMethods.handleSubmit(onUpdate)}
          actionButtonText="Update"
          dialogBodyStyles={{ maxHeight: spacing[310] }}
          dialogTitle="Update the project role"
        >
          {!permissions || !currentRole || !currentRolePermissions ? (
            <Loader />
          ) : (
            <form>
              <Box>
                <FormTextField
                  id={FIELD_NAME}
                  name={FIELD_NAME}
                  label="Name"
                  rules={{
                    required: validators.required.message("role name"),
                  }}
                  defaultValue={currentRole?.name}
                  placeholder="Ex: my-custom-role"
                />
              </Box>
              <Box my={2}>
                {currentPermissionOptions && (
                  <AutoCompleteSelectMultipleHookForm
                    name={FIELD_PERMISSIONS}
                    label="Permissions"
                    ListboxProps={{ style: { maxHeight: spacing[200] } }}
                    options={permissionOptionList}
                    defaultValue={currentPermissionOptions}
                    isSearchable
                    isMulti
                    rules={permissionsRules}
                  />
                )}
              </Box>
            </form>
          )}
        </ActionDialog>
      </FormProvider>

      <DeleteDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(!deleteDialogOpen)}
        onDelete={onDelete}
      >
        Are you sure you want to delete custom role {`"`}
        <b>{(roleToDelete as any)?.name}</b>
        {`"`}?
      </DeleteDialog>
      <RoleDetailsDialog
        // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        currentRole={currentRole?.name}
        projectName={project?.name}
        open={showRoleDialog}
        toggleDialog={() => setShowRoleDialog(!showRoleDialog)}
      />
    </>
  );
};

export default RolesOverview;
