import { Grid, Box } from "@mui/material";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useRouteMatch } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { useGoogleAnalytics } from "libs/hooks";

import { Card } from "components/atoms";

import { ObjectsOverview } from "./ObjectsOverview";
import { PipelineDiagram } from "./PipelineDiagram";
import { PipelineVersionInfo } from "./PipelineVersionInfo";

import type { FC } from "react";

export const PipelineVersionGeneral: FC = () => {
  useGoogleAnalytics();
  const match = useRouteMatch();

  return (
    <Box className="pipeline-version-general">
      <BreadcrumbsItem to={match.url}>General</BreadcrumbsItem>
      <Grid container direction="column">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <PipelineVersionInfo />
          </Grid>

          <Grid item xs={6}>
            <Card title="Objects">
              <ObjectsOverview />
            </Card>
          </Grid>
        </Grid>

        <Grid item style={{ paddingTop: spacing[16] }}>
          <PipelineDiagram />
        </Grid>
      </Grid>
    </Box>
  );
};
