import { useTheme } from "@mui/styles";

export const CurvedArrow = () => {
  const theme = useTheme();

  return (
    <svg
      width="58"
      height="19"
      viewBox="0 0 58 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.343872 16.3729L1.44479 18.0425C1.64375 17.9113 21.0669 5.38643 44.3713 11.3792L38.347 18.0238C43.8341 16.1587 51.7185 15.5075 57.7729 15.714C53.3179 11.61 48.1636 5.60819 45.5683 0.426395L45.1832 9.52881C20.8572 3.11729 0.550841 16.236 0.343872 16.3729Z"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
};
