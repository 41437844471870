import Plus from "@mui/icons-material/AddBoxRounded";
import Trash from "@mui/icons-material/DeleteRounded";
import Edit from "@mui/icons-material/Edit";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import {
  Typography,
  IconButton,
  Tooltip,
  Box,
  Link,
  Grid,
} from "@mui/material";
import { compact, map } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useForm, FormProvider } from "react-hook-form";
import { useRouteMatch, useHistory, useParams } from "react-router-dom";

import { ReactComponent as DeleteIcon } from "assets/images/delete-white.svg";
import { spacing } from "assets/styles/theme";
import {
  FIELD_NAME,
  FIELD_ALLOWED_CORS_ORIGINS,
  FIELD_EXPIRY_DATE,
} from "libs/constants/fields";
import {
  useServiceUserDelete,
  useServiceUserUpdate,
} from "libs/data/customized/service-users";
import { useServiceUserReset } from "libs/data/customized/service-users/useServiceUserReset";
import { useServiceUsersList } from "libs/data/endpoints/service-users/service-users";
import { useGoogleAnalytics } from "libs/hooks";
import {
  DATE_TIME_FORMAT,
  getFormattedDate,
  getTzAwareDate,
  isDatePassed,
} from "libs/utilities/date-util";
import validators from "libs/utilities/validators";

import {
  CopyToClipboardButton,
  FormTextField,
  PrimaryButton,
  Dialog,
  DialogWarningHeader,
  ActionDialog,
} from "components/atoms";
import { BaseTable, DatePicker } from "components/molecules";
import {
  RoleAssignmentDialog,
  RoleDetailsDialog,
  UserRoleAssignments,
} from "components/organisms";

const ApiTokensOverview = () => {
  useGoogleAnalytics();

  const { projectName } = useParams();
  const match = useRouteMatch();
  const history = useHistory();

  const [current, setCurrent] = useState(null);
  const [token, setToken] = useState(null);
  const [roleAssignmentDialogOpen, setRoleAssignmentDialogOpen] =
    useState(false);
  const [isResetTokenDialogOpen, setIsResetTokenDialogOpen] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [currentRole, setCurrentRole] = useState(null);
  const [showRoleDialog, setShowRoleDialog] = useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [tokenToDelete, setTokenToDelete] = useState(null);

  const onRoleDetailsClick = useCallback(
    (role) => {
      setCurrentRole(role);
      setShowRoleDialog(!showRoleDialog);
    },
    [setCurrentRole, setShowRoleDialog, showRoleDialog]
  );

  const formMethods = useForm({
    mode: "onBlur",
    defaultValues: {
      [FIELD_NAME]: current?.[FIELD_NAME],
      [FIELD_EXPIRY_DATE]: current?.[FIELD_EXPIRY_DATE],
    },
  });

  const { reset, handleSubmit, setValue } = formMethods;

  const deleteServiceUser = useServiceUserDelete(projectName);
  const resetServiceUser = useServiceUserReset(projectName);
  const updateServiceUser = useServiceUserUpdate(projectName);

  const { data: serviceUsers, error: serviceUsersError } =
    useServiceUsersList(projectName);
  const isLoadingServiceUsers = !serviceUsers && !serviceUsersError;

  useEffect(() => {
    if (current) {
      const cors = current[FIELD_ALLOWED_CORS_ORIGINS];
      reset({
        [FIELD_ALLOWED_CORS_ORIGINS]: cors?.length
          ? cors.map((value) => ({
              value,
            }))
          : [{ value: "" }],
      });
    }
  }, [current, reset]);

  const resetToken = async () => {
    setIsConfirmDialogOpen(false);
    const response = await resetServiceUser(current.id);
    setCurrent(response);
    setIsResetTokenDialogOpen(true);
  };

  const editServiceUsersData = async (data) => {
    let newData = {
      [FIELD_NAME]: data[FIELD_NAME],
      [FIELD_EXPIRY_DATE]:
        data[FIELD_EXPIRY_DATE] || current?.[FIELD_EXPIRY_DATE] || null,
    };

    if (data[FIELD_ALLOWED_CORS_ORIGINS]) {
      newData[FIELD_ALLOWED_CORS_ORIGINS] = compact(
        map(data[FIELD_ALLOWED_CORS_ORIGINS], "value")
      );
    }

    setIsUpdating(true);
    setUpdateDialogOpen(false);
    await updateServiceUser(current.id, newData);
    setCurrent(null);
    setIsUpdating(false);
  };

  const columns = useMemo(
    () => [
      {
        title: "",
        field: "expand",
        width: "2%",
      },
      {
        title: "Name",
        field: "name",
        nowrap: true,
        render: (rowData) => <Link variant="h5">{rowData.name}</Link>,
        width: "20%",
      },

      {
        title: "Email",
        field: "email",
        editable: "never",
        nowrap: true,
        width: "30%",
      },
      {
        width: "20%",
        title: "Creation date",
        field: "creation_date",
        type: "datetime",
        nowrap: true,
        render: (rowData) =>
          rowData?.creation_date &&
          getTzAwareDate(rowData.creation_date).format(DATE_TIME_FORMAT),
        editable: "never",
      },
      {
        width: "30%",
        title: "Expiry date",
        field: "expiry_date",
        type: "datetime",
        nowrap: true,
        editable: "never",
        render: (rowData) =>
          rowData?.expiry_date ? (
            isDatePassed(rowData.expiry_date) ? (
              <Typography color="error">
                [Expired]{" "}
                {getTzAwareDate(rowData.expiry_date).format(DATE_TIME_FORMAT)}
              </Typography>
            ) : (
              getTzAwareDate(rowData.expiry_date).format(DATE_TIME_FORMAT)
            )
          ) : (
            "-"
          ),
      },
      {
        disableClick: true,
        width: "9%",
        render: (rowData) => {
          const onResetTokenClick = (item) => {
            setCurrent(item);
            setIsConfirmDialogOpen(true);
          };
          const onEditClick = (item) => {
            setCurrent(item);
            setUpdateDialogOpen(true);
          };

          return (
            <div className="actions_container">
              <Tooltip title="Reset token">
                <span>
                  <IconButton
                    color="secondary"
                    disabled={isDatePassed(rowData?.expiry_date)}
                    onClick={(e) => {
                      if (!isDatePassed(rowData?.expiry_date))
                        e.stopPropagation();
                      onResetTokenClick(rowData);
                    }}
                  >
                    <RefreshRoundedIcon />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title="Edit">
                <span>
                  <IconButton
                    color="secondary"
                    disabled={isDatePassed(rowData?.expiry_date)}
                    onClick={(e) => {
                      if (!isDatePassed(rowData?.expiry_date))
                        e.stopPropagation();
                      onEditClick(rowData);
                    }}
                  >
                    <Edit />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Delete">
                <span>
                  <IconButton
                    color="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setTokenToDelete(rowData);
                    }}
                  >
                    <Trash />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          );
        },
      },
    ],
    [setIsConfirmDialogOpen]
  );

  const handleDateChange = (value) => {
    if (value) {
      setValue(FIELD_EXPIRY_DATE, getFormattedDate(value));
    } else {
      setCurrent((prev) => ({
        ...prev,
        expiry_date: null,
      }));
    }
  };

  // Workaround to fix timepicker dropdown flickering while editing `Expiry date` on Firefox
  const dialogRef = React.createRef();
  useEffect(() => {
    if (dialogRef.current) {
      const element = dialogRef.current.children[2];
      element.removeAttribute("tabIndex");
    }
  }, [dialogRef]);

  return (
    <>
      <BreadcrumbsItem to={match.url}>API tokens</BreadcrumbsItem>
      <Box mt={spacing[16]}>
        <BaseTable
          columns={columns}
          data={serviceUsers ?? []}
          defaultSortColumn="creation_date"
          defaultSortDirection="asc"
          isLoading={isLoadingServiceUsers || isUpdating}
          onRowClick={(_e, rowData) => {
            if (rowData?.id !== token?.id) {
              setToken(rowData);
            } else {
              setToken(null);
            }
          }}
          action={
            <PrimaryButton
              startIcon={<Plus />}
              onClick={() => history.push(`${match.url}/create`)}
            >
              Add token
            </PrimaryButton>
          }
          expandedRowIndex={serviceUsers?.findIndex(
            (user) => user.id === token?.id
          )}
          clearExpandedRow={() => setToken(null)}
          renderDetailPanel={(rowData) => {
            return (
              <UserRoleAssignments
                currentUser={rowData}
                isServiceUser
                onRoleDetailsClick={onRoleDetailsClick}
                setRoleAssignmentDialogOpen={setRoleAssignmentDialogOpen}
              />
            );
          }}
          detailPanel={(member) => {
            return (
              <UserRoleAssignments
                currentUser={member.rowData}
                isServiceUser
                onRoleDetailsClick={onRoleDetailsClick}
                setRoleAssignmentDialogOpen={setRoleAssignmentDialogOpen}
              />
            );
          }}
        />
      </Box>
      {current && (
        <ActionDialog
          open={isConfirmDialogOpen}
          onClose={() => setIsConfirmDialogOpen(false)}
          Header={<DialogWarningHeader title="Are you sure?" />}
          Actions={
            <PrimaryButton onClick={resetToken}>Reset token</PrimaryButton>
          }
        >
          <Typography>
            Are you sure you want to reset the token of service user{" "}
            <Typography variant="h5" component="span" display="inline">
              {current.name}
            </Typography>
            ?
          </Typography>
          <Typography color="error">
            The current token will become unusable.
          </Typography>
        </ActionDialog>
      )}
      {current && (
        <Dialog
          open={isResetTokenDialogOpen}
          onClose={() => setIsResetTokenDialogOpen(false)}
          title="Token generated"
          disableEscapeKeyDown
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={spacing[4]}
          >
            <Typography>
              A token was successfully created for service user{" "}
              <Typography variant="h5" display="inline">
                {current.name}
              </Typography>
            </Typography>
            <Typography color="error" variant="h6">
              {`Please, copy the token. You won't be able to retrieve it again.`}
            </Typography>
            <Typography variant="h5">
              Token {current.token}
              <CopyToClipboardButton
                defaultLabel="Copy token to clipboard"
                contentToCopy={`Token ${current.token}`}
                color="secondary"
              />
            </Typography>
          </Box>
        </Dialog>
      )}
      {current && (
        <Dialog
          Actions={
            <PrimaryButton onClick={handleSubmit(editServiceUsersData)}>
              Save
            </PrimaryButton>
          }
          onClose={() => setUpdateDialogOpen(false)}
          open={updateDialogOpen}
          title="Edit"
        >
          <FormProvider {...formMethods}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box mb={1}>
                  <Typography variant="h4">Service user name</Typography>
                </Box>
                <FormTextField
                  name={FIELD_NAME}
                  label="Name"
                  rules={{
                    required: validators.required.message(FIELD_NAME),
                    pattern: {
                      value: validators.name.pattern,
                      message: validators.name.message(FIELD_NAME),
                    },
                    validate: validators.name.value("API token"),
                  }}
                  defaultValue={current.name}
                  placeholder="Ex: my-service-user-1"
                />
              </Grid>

              <Grid item xs={6}>
                <Box mt={2} mb={1}>
                  <Typography variant="h4">Expiry date</Typography>
                </Box>
                <DatePicker
                  name={FIELD_EXPIRY_DATE}
                  minDate={getTzAwareDate()}
                  onChange={handleDateChange}
                  date={
                    current?.[FIELD_EXPIRY_DATE] &&
                    new Date(current?.[FIELD_EXPIRY_DATE])
                  }
                />
              </Grid>
            </Grid>
          </FormProvider>
        </Dialog>
      )}
      <ActionDialog
        actionButtonIcon={<DeleteIcon />}
        dialogBodyStyles={{ padding: 0 }}
        open={Boolean(tokenToDelete)}
        onClose={() => setTokenToDelete(null)}
        Header={<DialogWarningHeader title="Remove service user" />}
        onAction={async () => {
          try {
            await deleteServiceUser(tokenToDelete.id);
          } finally {
            setTokenToDelete(null);
          }
        }}
        actionButtonText="Delete"
      >
        <Typography marginTop={spacing[16]} marginBottom={spacing[16]}>
          Are you sure you want to remove this service user?
        </Typography>
        <Typography marginBottom={spacing[16]} variant="h4">
          {tokenToDelete?.name}
        </Typography>
      </ActionDialog>
      {!!token && (
        <RoleAssignmentDialog
          currentUser={token}
          isServiceUser
          toggleDialog={setRoleAssignmentDialogOpen}
          isOpen={roleAssignmentDialogOpen}
        />
      )}
      <RoleDetailsDialog
        currentRole={currentRole}
        open={showRoleDialog}
        toggleDialog={() => setShowRoleDialog(!showRoleDialog)}
      />
    </>
  );
};

export default ApiTokensOverview;
