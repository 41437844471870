import makeStyles from "@mui/styles/makeStyles";

export const useAccordionStyles = makeStyles((theme) => ({
  advancedSettingsGrid: {
    width: "unset",
    margin: "unset",
  },
  accSummaryContent: {
    display: "contents",
  },
  accordionContainer: {
    padding: "0 20px",
  },
  accordionWrapper: {
    padding: "0 20px",
    borderColor: theme.palette.neutrals[200],
    boxShadow: "none",
    borderStyle: "solid",
    borderWidth: "1px",
    background: "transparent",
  },
}));
