import { useTheme } from "@mui/styles";

export const IlluTrainingDeployment = () => {
  const theme = useTheme();

  return (
    <svg
      width="266"
      height="204"
      viewBox="0 0 266 204"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6468_40228)">
        <path
          d="M258.072 156.954H8.13526C6.11925 156.952 4.18646 156.151 2.76093 154.727C1.33539 153.303 0.533526 151.372 0.53125 149.358V8.56445H265.676V149.358C265.674 151.372 264.872 153.303 263.446 154.727C262.021 156.151 260.088 156.952 258.072 156.954Z"
          fill="#E6E6E6"
        />
        <path
          d="M248.127 142.413H18.0832C17.0447 142.412 16.049 141.999 15.3146 141.266C14.5803 140.532 14.1672 139.537 14.166 138.5V27.019C14.1672 25.9814 14.5803 24.9867 15.3146 24.253C16.049 23.5193 17.0447 23.1066 18.0832 23.1055H248.127C249.166 23.1066 250.161 23.5193 250.896 24.253C251.63 24.9867 252.043 25.9814 252.044 27.019V138.5C252.043 139.537 251.63 140.532 250.896 141.266C250.161 141.999 249.166 142.412 248.127 142.413Z"
          fill="white"
        />
        <path
          d="M266.209 13.6961H0V7.59676C0.00227728 5.58268 0.804143 3.65173 2.22968 2.22755C3.65521 0.803377 5.588 0.00227511 7.60401 0H258.605C260.621 0.00227511 262.554 0.803377 263.979 2.22755C265.405 3.65173 266.207 5.58268 266.209 7.59676V13.6961Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M72.4963 99.7288H31.552C30.5134 99.7276 29.5178 99.3149 28.7834 98.5812C28.049 97.8476 27.6359 96.8528 27.6348 95.8153V68.9242C27.6359 67.8867 28.049 66.8919 28.7834 66.1583C29.5178 65.4246 30.5134 65.0119 31.552 65.0107H72.4963C73.5349 65.0119 74.5306 65.4246 75.2649 66.1583C75.9993 66.8919 76.4124 67.8867 76.4135 68.9242V95.8153C76.4124 96.8528 75.9993 97.8476 75.2649 98.5812C74.5306 99.3149 73.5349 99.7276 72.4963 99.7288Z"
          fill="#E6E6E6"
        />
        <path
          d="M236.396 69.3642H107.028C106.451 69.3642 105.896 69.1348 105.488 68.7266C105.079 68.3184 104.85 67.7648 104.85 67.1875C104.85 66.6102 105.079 66.0565 105.488 65.6483C105.896 65.2401 106.451 65.0107 107.028 65.0107H236.396C236.973 65.0107 237.528 65.2401 237.936 65.6483C238.345 66.0565 238.574 66.6102 238.574 67.1875C238.574 67.7648 238.345 68.3184 237.936 68.7266C237.528 69.1348 236.973 69.3642 236.396 69.3642Z"
          fill="#E6E6E6"
        />
        <path
          d="M236.395 84.9357H107.028C106.45 84.9357 105.896 84.7064 105.488 84.2982C105.079 83.89 104.85 83.3364 104.85 82.7591C104.85 82.1819 105.079 81.6282 105.488 81.22C105.896 80.8118 106.45 80.5825 107.028 80.5825H236.395C236.973 80.5825 237.527 80.8118 237.936 81.22C238.345 81.6282 238.574 82.1819 238.574 82.7591C238.574 83.3364 238.345 83.89 237.936 84.2982C237.527 84.7064 236.973 84.9357 236.395 84.9357Z"
          fill="#E6E6E6"
        />
        <path
          d="M236.396 100.507H107.028C106.451 100.507 105.896 100.278 105.488 99.8697C105.079 99.4615 104.85 98.9078 104.85 98.3305C104.85 97.7532 105.079 97.1996 105.488 96.7914C105.896 96.3831 106.451 96.1538 107.028 96.1538H236.396C236.973 96.1538 237.528 96.3831 237.936 96.7914C238.345 97.1996 238.574 97.7532 238.574 98.3305C238.574 98.9078 238.345 99.4615 237.936 99.8697C237.528 100.278 236.973 100.507 236.396 100.507Z"
          fill="#E6E6E6"
        />
        <path
          d="M216.132 251.127H210.38C209.861 251.124 209.361 250.928 208.979 250.576C208.597 250.225 208.36 249.744 208.314 249.227L203.44 190.571C203.426 190.401 203.349 190.241 203.224 190.124C203.099 190.007 202.935 189.941 202.763 189.938C202.592 189.935 202.426 189.996 202.297 190.108C202.168 190.221 202.085 190.377 202.065 190.547L195.065 249.3C195.004 249.804 194.762 250.268 194.382 250.604C194.003 250.941 193.513 251.127 193.005 251.127H185.943C185.396 251.126 184.871 250.909 184.482 250.523C184.094 250.137 183.874 249.614 183.871 249.067C183.855 245.376 183.83 241.659 183.805 237.941C183.581 204.827 183.349 170.586 190.27 157.172L190.333 157.05L215.307 156.623L215.37 156.76C222.926 173.257 220.633 210.11 218.415 245.75L218.201 249.184C218.168 249.71 217.935 250.203 217.551 250.564C217.167 250.925 216.659 251.126 216.132 251.127Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M204.301 112.536C210.553 112.536 215.62 107.473 215.62 101.228C215.62 94.9823 210.553 89.9194 204.301 89.9194C198.05 89.9194 192.982 94.9823 192.982 101.228C192.982 107.473 198.05 112.536 204.301 112.536Z"
          fill="#A0616A"
        />
        <path
          d="M188.869 159.128L189.181 146.163L186.935 136.047C186.368 133.542 186.401 130.939 187.032 128.449C187.663 125.96 188.873 123.654 190.565 121.72C192.21 119.796 194.288 118.288 196.628 117.318C198.968 116.349 201.505 115.946 204.03 116.142C204.193 116.155 204.357 116.168 204.521 116.183C206.679 116.377 208.776 117.003 210.686 118.024C212.597 119.045 214.282 120.44 215.642 122.126C217.03 123.821 218.062 125.777 218.677 127.88C219.291 129.982 219.476 132.186 219.22 134.361L216.504 158.079L188.869 159.128Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M179.254 182.365C179.788 181.949 180.224 181.42 180.531 180.817C180.839 180.214 181.01 179.551 181.033 178.874C181.056 178.198 180.931 177.524 180.665 176.902C180.4 176.279 180.001 175.722 179.497 175.27L185.869 160.099L177.459 161.677L172.696 175.842C171.992 176.738 171.642 177.862 171.712 178.999C171.782 180.137 172.267 181.209 173.075 182.013C173.883 182.817 174.958 183.297 176.097 183.362C177.236 183.427 178.359 183.072 179.254 182.365Z"
          fill="#A0616A"
        />
        <path
          d="M227.341 184.184C226.832 183.737 226.426 183.185 226.154 182.565C225.881 181.946 225.748 181.274 225.763 180.597C225.778 179.921 225.942 179.256 226.243 178.649C226.543 178.042 226.973 177.509 227.502 177.086L222.004 161.578L230.311 163.631L234.259 178.044C234.911 178.979 235.197 180.12 235.062 181.252C234.928 182.384 234.382 183.427 233.53 184.183C232.677 184.94 231.576 185.358 230.435 185.358C229.295 185.358 228.194 184.94 227.341 184.184Z"
          fill="#A0616A"
        />
        <path
          d="M180.266 174.931C179.974 174.931 179.686 174.87 179.419 174.75L174.57 172.581C174.104 172.372 173.731 172 173.523 171.533C173.315 171.067 173.287 170.541 173.444 170.055L181.577 144.816L184.72 125.801C184.832 125.125 185.078 124.479 185.445 123.9C185.812 123.322 186.291 122.823 186.855 122.434C187.419 122.044 188.055 121.772 188.727 121.633C189.398 121.494 190.09 121.492 190.762 121.625C191.434 121.759 192.073 122.027 192.639 122.412C193.206 122.798 193.689 123.293 194.06 123.869C194.431 124.445 194.683 125.089 194.799 125.764C194.916 126.439 194.896 127.13 194.739 127.797L189.963 148.189L182.25 173.464C182.121 173.888 181.858 174.26 181.501 174.525C181.144 174.789 180.711 174.931 180.266 174.931Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M226.518 174.931C226.073 174.932 225.64 174.789 225.283 174.525C224.926 174.261 224.663 173.889 224.534 173.464L216.826 148.204L212.045 127.797C211.888 127.13 211.868 126.439 211.985 125.764C212.101 125.089 212.353 124.445 212.724 123.869C213.095 123.293 213.578 122.798 214.145 122.412C214.711 122.027 215.35 121.759 216.022 121.625C216.694 121.492 217.386 121.494 218.058 121.633C218.729 121.772 219.365 122.044 219.929 122.434C220.493 122.823 220.972 123.322 221.339 123.901C221.706 124.479 221.952 125.125 222.064 125.801L225.215 144.849L233.34 170.055C233.497 170.541 233.469 171.067 233.261 171.533C233.053 172 232.68 172.373 232.214 172.581L227.365 174.751C227.099 174.87 226.81 174.931 226.518 174.931Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M204.534 88.7275C201.388 87.3661 197.279 89.0601 196.009 92.2415C195.219 94.2215 195.46 96.4547 195.896 98.5414C196.331 100.628 196.952 102.71 196.886 104.841C196.819 106.971 195.9 109.233 194.009 110.218C192.878 110.807 191.556 110.873 190.331 111.224C189.105 111.575 187.84 112.396 187.664 113.658C187.397 115.566 189.714 117.019 189.698 118.946C189.682 120.987 187.128 122.2 186.797 124.214C186.74 124.804 186.834 125.399 187.071 125.942C187.307 126.486 187.677 126.96 188.147 127.322C189.096 128.033 190.173 128.554 191.32 128.856C200.144 131.674 209.54 132.209 218.628 130.411C220.433 130.051 222.292 129.564 223.695 128.373C225.098 127.182 225.9 125.097 225.094 123.443C223.885 120.964 220.046 120.822 218.708 118.41C217.906 116.963 218.275 115.187 218.444 113.541C218.881 109.291 219.887 104.326 218.831 100.187C217.876 96.4467 215.803 91.6138 212.84 89.137C210.713 87.3597 206.654 88.1695 203.937 88.7177L204.534 88.7275Z"
          fill={theme.palette.primary.main}
        />
      </g>
      <defs>
        <clipPath id="clip0_6468_40228">
          <rect width="266" height="204" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
