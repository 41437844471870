import Edit from "@mui/icons-material/EditRounded";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useContext } from "react";
import { useHistory } from "react-router-dom";

import { BaseUrlContext } from "libs/contexts";

import { DetailsItem } from "components/atoms";

type NotificationGroupsInfoProps = {
  monitoring?: string;
  defaultNotificationGroup?: string;
};

const NotificationGroupsInfo = ({
  monitoring,
  defaultNotificationGroup,
}: NotificationGroupsInfoProps) => {
  const baseUrl = useContext(BaseUrlContext);
  const history = useHistory();

  const handleClick = () => {
    history.push(`${baseUrl}/edit`);
  };

  return (
    <>
      <DetailsItem title="Notification groups">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Failed requests</Typography>
          <Typography variant="subtitle2">
            {monitoring ?? "No group yet"}
          </Typography>
          <Tooltip title="Edit emails">
            <IconButton onClick={handleClick} color="secondary" size="small">
              <Edit fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </DetailsItem>
      <DetailsItem>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Finished requests</Typography>
          <Typography variant="subtitle2">
            {defaultNotificationGroup ?? "No group yet"}
          </Typography>
          <Tooltip title="Edit emails">
            <span>
              <IconButton onClick={handleClick} color="secondary" size="small">
                <Edit fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      </DetailsItem>
    </>
  );
};

export default NotificationGroupsInfo;
