import { useTheme } from "@mui/styles";

export const VariableIcon = () => {
  const theme = useTheme();

  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_5209_32254)">
        <rect
          x="4"
          width="40"
          height="40"
          rx="3"
          fill={theme.palette.primary.main}
        />
      </g>
      <path
        d="M32.5115 11C33.9015 13.71 34.4515 16.84 34.1015 20C33.9015 23.16 32.8015 26.29 30.9315 29L29.4015 28C31.0115 25.57 31.9515 22.8 32.1015 20C32.4415 17.2 31.9915 14.43 30.8015 12L32.5115 11ZM17.2715 11L18.8015 12C17.1915 14.43 16.2515 17.2 16.1015 20C15.7615 22.8 16.2215 25.57 17.4015 28L15.7115 29C14.3115 26.29 13.7515 23.17 14.1015 20C14.3015 16.84 15.4015 13.71 17.2715 11ZM24.1815 18.68L26.5015 15.45H29.0315L25.2515 20.45L27.4515 25.37H25.1915L23.8115 22L21.3815 25.33H18.8615L22.7615 20.21L20.6315 15.45H22.9015L24.1815 18.68Z"
        fill="#F9F9FC"
      />
      <defs>
        <filter
          id="filter0_d_5209_32254"
          x="0"
          y="0"
          width="48"
          height="48"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5209_32254"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5209_32254"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
