import BugReportIcon from "@mui/icons-material/BugReport";
import { Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import { Component } from "react";

import { LINK_CREATE_BUG } from "libs/constants/documentation-links";

import { SecondaryButton } from "components/atoms";

function ErrorUI() {
  return (
    <>
      <Typography variant="h3" gutterBottom color="primary">
        Something went wrong.
      </Typography>
      <SecondaryButton
        size="small"
        startIcon={<BugReportIcon />}
        href={LINK_CREATE_BUG}
        target="_blank"
      >
        Report a bug
      </SecondaryButton>
    </>
  );
}

const fallbackComponent = <ErrorUI />;

export class ErrorBoundary extends Component {
  render() {
    return (
      <Sentry.ErrorBoundary fallback={fallbackComponent}>
        {this.props.children}
      </Sentry.ErrorBoundary>
    );
  }
}
