import Plus from "@mui/icons-material/AddBoxRounded";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import { Box, Grid, useTheme } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import {
  FIELD_MONITORING_GROUP,
  FIELD_DEFAULT_MONITORING_GROUP,
  FIELD_MONITORING_GROUP_ENABLED,
  FIELD_DEFAULT_MONITORING_GROUP_ENABLED,
} from "libs/constants/fields";
import { useNotificationGroupsList } from "libs/data/endpoints/monitoring/monitoring";
import { useNotificationGroups } from "libs/hooks";
import { explanations } from "libs/utilities/explanations";

import { Icon, SecondaryButton } from "components/atoms";
import { ControlledSwitch, FormikSelect } from "components/molecules";
import { NotificationGroupEditCreate } from "components/organisms";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type { FormikProps, FormikValues } from "formik";

interface PipelineVersionNotificationProps {
  setFieldValue: (field: string, value: any) => void;
  control: FormikProps<FormikValues>;
  values: FormikValues;
}

export const PipelineVersionNotification = ({
  setFieldValue,
  control,
  values,
}: PipelineVersionNotificationProps) => {
  const { projectName } = useParams<{ projectName: string }>();
  const { data: notificationGroups } = useNotificationGroupsList(projectName);
  const monitoringGroupEnabled = values[FIELD_MONITORING_GROUP_ENABLED];
  const theme = useTheme() as AppThemeProps;
  const defaultGroupEnabled = values[FIELD_DEFAULT_MONITORING_GROUP_ENABLED];

  const notificationGroupOptions = useMemo(() => {
    return !notificationGroups
      ? []
      : notificationGroups?.map((item) => ({
          label: item.name,
          value: item.name,
        }));
  }, [notificationGroups]);

  const { dialogProps: createDialogProps, toggleDialog: createDialogToggle } =
    // @ts-ignore
    useNotificationGroups({
      isUpdate: false,
      onCloseCallback: () => {
        if (!notificationGroupOptions?.length) {
          setFieldValue(FIELD_MONITORING_GROUP_ENABLED, false);
          setFieldValue(FIELD_DEFAULT_MONITORING_GROUP_ENABLED, false);
        }
      },
    });

  useEffect(() => {
    // Open dialog when there is no notification group options and toggle is enabled
    if (
      !notificationGroupOptions?.length &&
      !createDialogProps.isOpen &&
      (monitoringGroupEnabled || defaultGroupEnabled)
    ) {
      createDialogToggle();
    }
  }, [
    createDialogToggle,
    monitoringGroupEnabled,
    defaultGroupEnabled,
    notificationGroupOptions,
    createDialogProps,
  ]);

  useEffect(() => {
    // set  monitoring group if it's toggled on
    if (
      monitoringGroupEnabled &&
      !values[FIELD_MONITORING_GROUP] &&
      notificationGroupOptions?.length
    ) {
      setFieldValue(
        FIELD_MONITORING_GROUP,
        notificationGroupOptions[notificationGroupOptions.length - 1].value
      );
    }
  }, [monitoringGroupEnabled, notificationGroupOptions, setFieldValue, values]);

  useEffect(() => {
    // set default monitoring group if it's toggled on
    if (
      defaultGroupEnabled &&
      !values[FIELD_DEFAULT_MONITORING_GROUP] &&
      notificationGroupOptions?.length
    ) {
      setFieldValue(
        FIELD_DEFAULT_MONITORING_GROUP,
        notificationGroupOptions[notificationGroupOptions.length - 1].value
      );
    }
  }, [defaultGroupEnabled, notificationGroupOptions, setFieldValue, values]);

  return (
    <>
      <Grid container direction="column" wrap="nowrap" spacing={3}>
        <Grid item>
          <Grid container flexWrap="nowrap">
            <Box display="flex" justifyContent="center" alignItems="center">
              {monitoringGroupEnabled ? (
                <Icon color="secondary" component={NotificationsActiveIcon} />
              ) : (
                <Icon
                  component={NotificationsOffIcon}
                  style={{ color: theme.palette.neutrals[500] }}
                />
              )}
            </Box>
            <Grid
              item
              xs={1}
              sx={{
                display: "flex",
                marginLeft: spacing[8],
                marginRight: spacing[8],
              }}
            >
              <ControlledSwitch
                name={FIELD_MONITORING_GROUP_ENABLED}
                value={values[FIELD_MONITORING_GROUP_ENABLED]}
                onChange={async (value: boolean) => {
                  await setFieldValue(FIELD_MONITORING_GROUP_ENABLED, value);
                  await control.setFieldTouched(
                    FIELD_MONITORING_GROUP_ENABLED,
                    true
                  );
                }}
              />
            </Grid>
            <Grid item xs={10}>
              <FormikSelect
                name={FIELD_MONITORING_GROUP}
                control={control}
                label="Failed requests notification"
                options={notificationGroupOptions}
                value={values[FIELD_MONITORING_GROUP]}
                tooltip={explanations.monitoring.monitoringGroup}
                disabled={!monitoringGroupEnabled}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container flexWrap="nowrap">
            <Box display="flex" justifyContent="center" alignItems="center">
              {defaultGroupEnabled ? (
                <Icon color="secondary" component={NotificationsActiveIcon} />
              ) : (
                <Icon
                  component={NotificationsOffIcon}
                  style={{ color: theme.palette.neutrals[500] }}
                />
              )}
            </Box>
            <Grid
              item
              xs={1}
              sx={{
                display: "flex",
                marginLeft: spacing[8],
                marginRight: spacing[8],
              }}
            >
              <ControlledSwitch
                name={FIELD_DEFAULT_MONITORING_GROUP_ENABLED}
                value={values[FIELD_DEFAULT_MONITORING_GROUP_ENABLED]}
                onChange={async (value: boolean) => {
                  await setFieldValue(
                    FIELD_DEFAULT_MONITORING_GROUP_ENABLED,
                    value
                  );
                  await control.setFieldTouched(
                    FIELD_DEFAULT_MONITORING_GROUP_ENABLED,
                    true
                  );
                }}
              />
            </Grid>
            <Grid item xs={10}>
              <FormikSelect
                name={FIELD_DEFAULT_MONITORING_GROUP}
                control={control}
                label="Finished requests notification"
                options={notificationGroupOptions}
                value={values[FIELD_DEFAULT_MONITORING_GROUP]}
                tooltip={explanations.monitoring.defaultNotificationGroup}
                disabled={!defaultGroupEnabled}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <SecondaryButton startIcon={<Plus />} onClick={createDialogToggle}>
            Create notification group
          </SecondaryButton>
        </Grid>
      </Grid>
      <NotificationGroupEditCreate {...createDialogProps} />
    </>
  );
};
