import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useRouteMatch } from "react-router-dom";

import { PageHeader } from "components/molecules/PageLayout";
import { useGoogleAnalytics } from "libs/hooks";

import { Divider } from "components/atoms";
import { PageContainer } from "components/molecules";

import DevicesOverview from "./DevicesOverview";
import PasswordUpdate from "./PasswordUpdate";

const SecurityDetails = () => {
  useGoogleAnalytics();

  const match = useRouteMatch();

  return (
    <PageContainer>
      <PageHeader title="Security" />
      <BreadcrumbsItem to={match.url}>Security</BreadcrumbsItem>
      <PasswordUpdate />
      <Divider my={3} />
      <DevicesOverview />
    </PageContainer>
  );
};

SecurityDetails.propTypes = {};

export default SecurityDetails;
