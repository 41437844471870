import Plus from "@mui/icons-material/AddBoxRounded";
import { Button, Grid, Typography } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";

import { STRUCTURED_TYPE } from "libs/constants/constants";
import { explanations } from "libs/utilities/explanations";
import { formatRequestData } from "libs/utilities/input-parser";

import { InfoTooltip } from "components/atoms";

import RequestCreate from "../RequestCreate/RequestCreate";

import type {
  DeploymentDetail,
  DeploymentRequestsCreateDataBody,
  PipelineDetail,
} from "libs/data/models";

const REQUEST_KEY = "1";

type CreateDirectRequestSectionProps =
  | {
      objectName: string;
      objectType: "deployment";
      inputType?: DeploymentDetail["input_type"];
      inputFields?: DeploymentDetail["input_fields"];
      onSubmit: (args: DeploymentRequestsCreateDataBody) => void;
    }
  | {
      objectName: string;
      objectType: "pipeline";
      inputType?: PipelineDetail["input_type"];
      inputFields?: PipelineDetail["input_fields"];
      onSubmit: (args: DeploymentRequestsCreateDataBody) => void;
    };

export const CreateDirectRequestSection = ({
  objectName,
  objectType,
  inputType = STRUCTURED_TYPE,
  inputFields = [],
  onSubmit,
}: CreateDirectRequestSectionProps) => {
  const formMethods = useForm({
    mode: "onBlur",
  });
  const { handleSubmit } = formMethods;

  const onRequestSending = ({ requests = [] }) => {
    // @ts-ignore
    const request = requests?.[REQUEST_KEY];
    const requestData = formatRequestData(inputFields, inputType, request);

    return onSubmit(requestData);
  };

  return (
    <Grid item container spacing={1} alignItems="center">
      <Grid item container>
        <Grid item>
          <Typography variant="h3">Direct request</Typography>
        </Grid>
        <Grid item component={InfoTooltip}>
          Direct request is used to send one data point to the {objectType}.
        </Grid>
      </Grid>
      <Grid item>{explanations.directRequests.createDirectRequest}</Grid>
      <Grid item xs={12}>
        <FormProvider {...formMethods}>
          <form>
            <RequestCreate
              objectName={objectName}
              objectType={objectType}
              fieldName={`requests[${REQUEST_KEY}]`}
              inputType={inputType}
              fields={inputFields}
            />
          </form>
        </FormProvider>
      </Grid>
      <Grid item>
        <Button
          startIcon={<Plus />}
          color="primary"
          variant="contained"
          size="small"
          onClick={handleSubmit(onRequestSending)}
        >
          Create direct request
        </Button>
      </Grid>
    </Grid>
  );
};
