import { useEffect } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useDispatch } from "react-redux";
import {
  Redirect,
  Switch,
  useParams,
  useHistory,
  useRouteMatch,
} from "react-router-dom";

import {
  BUCKET_PERMISSIONS,
  DEPLOYMENT_PERMISSIONS,
  ENVIRONMENT_PERMISSIONS,
  FILES_PERMISSIONS,
  IMPORT_EXPORT_PERMISSIONS,
  NOTIFICATION_GROUPS_PERMISSIONS,
  PIPELINE_PERMISSIONS,
  PROJECT_PERMISSIONS,
  SERVICE_USER_PERMISSIONS,
  USER_ROLE_PERMISSIONS,
  WEBHOOKS_PERMISSIONS,
} from "libs/constants/permissions";
import { BaseUrlContext, RootUrlContext } from "libs/contexts";
import { usePermissionValidation } from "libs/data/customized/roles";
import { useProjectsList } from "libs/data/endpoints/projects/projects";
import { configureSentryScope } from "libs/third-parties";
import { ProjectDashboard } from "pages/organizations/:organizationName/projects/:projectName/dashboard/ProjectDashboard";
import { DeploymentsPage } from "pages/organizations/:organizationName/projects/:projectName/deployments";
import { EnvironmentsRoutesContainer } from "pages/organizations/:organizationName/projects/:projectName/environments";
import { ImportsExportsPage } from "pages/organizations/:organizationName/projects/:projectName/imports-exports";
import Logging from "pages/organizations/:organizationName/projects/:projectName/logs/Logging";
import { MonitoringPage } from "pages/organizations/:organizationName/projects/:projectName/monitoring";
import { PipelinesPage } from "pages/organizations/:organizationName/projects/:projectName/pipelines";
import ProjectSettingsRoutesContainer from "pages/organizations/:organizationName/projects/:projectName/project-settings";
import { RequestSchedulesPage } from "pages/organizations/:organizationName/projects/:projectName/request-schedules";
import { StoragePage } from "pages/organizations/:organizationName/projects/:projectName/storage";
import { TrainingsRoutesContainer } from "pages/organizations/:organizationName/projects/:projectName/training";
import { routes } from "routes";
import {
  setProject,
  useGetCurrentProject,
  useGetProjects,
} from "store/features";
import { setPermissions } from "store/features/permissions";

import { Route } from "components/utilities";

import PermissionsRoutesContainer from "./settings/PermissionsRoutesContainer";

const basePath =
  routes.organizations[":organizationName"](":organizationName").projects[
    ":projectName"
  ](":projectName");

const ProjectRoutesContainer = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const { organizationName, projectName } =
    useParams<{ organizationName: string; projectName: string }>();

  const dispatch = useDispatch();
  const project = useGetCurrentProject();
  const projects = useGetProjects();

  const { data: projectsInOrg } = useProjectsList(
    { organization: organizationName },
    {
      swr: { enabled: !projects?.length && Boolean(organizationName) },
    }
  );

  const [generalPermissions] = usePermissionValidation(projectName, [
    ...Object.values(PROJECT_PERMISSIONS),
    ...Object.values(USER_ROLE_PERMISSIONS),
    ...Object.values(NOTIFICATION_GROUPS_PERMISSIONS),
    ...Object.values(IMPORT_EXPORT_PERMISSIONS),
    ...Object.values(WEBHOOKS_PERMISSIONS),
    ...Object.values(DEPLOYMENT_PERMISSIONS),
    ...Object.values(ENVIRONMENT_PERMISSIONS),
    ...Object.values(PIPELINE_PERMISSIONS),
    ...Object.values(BUCKET_PERMISSIONS),
    ...Object.values(FILES_PERMISSIONS),
    ...Object.values(SERVICE_USER_PERMISSIONS),
  ]);

  useEffect(() => {
    if (project?.id) {
      configureSentryScope({
        project: project?.id,
      });
    }
  }, [project?.id]);

  useEffect(() => {
    if (projects?.length || projectsInOrg) {
      const currentProjectInOrg = (projects || projectsInOrg)?.find(
        (project) =>
          project.name === projectName &&
          project.organization_name === organizationName
      );

      if (currentProjectInOrg) {
        dispatch(setProject(currentProjectInOrg));
        dispatch(setPermissions(generalPermissions));
      } else {
        history.push(routes.organizations.index());
      }
    }
  }, [
    dispatch,
    history,
    organizationName,
    projectName,
    projects,
    project,
    projectsInOrg,
    generalPermissions,
  ]);

  const baseUrl =
    routes.organizations[":organizationName"](organizationName).projects[
      ":projectName"
    ](projectName);

  return (
    <RootUrlContext.Provider value={match.url}>
      <BaseUrlContext.Provider value={match.url}>
        <BreadcrumbsItem to={match.url}>{project?.name}</BreadcrumbsItem>
        <Switch>
          <Route
            path={basePath.deployments.index()}
            component={DeploymentsPage}
          />
          <Route path={basePath.pipelines.index()} component={PipelinesPage} />
          <Route path={basePath.storage.index()} component={StoragePage} />
          <Route
            path={basePath.training.index()}
            component={TrainingsRoutesContainer}
          />
          <Route
            path={basePath.requestSchedules.index()}
            component={RequestSchedulesPage}
          />
          <Route
            path={basePath.importsExports.index()}
            component={ImportsExportsPage}
          />
          <Route strict path={basePath.logs.index()} component={Logging} />
          <Route
            strict
            path={basePath.monitoring.index()}
            component={MonitoringPage}
          />
          <Route
            path={basePath.environments.index()}
            component={EnvironmentsRoutesContainer}
          />
          <Route
            path={basePath.settings.index()}
            component={PermissionsRoutesContainer}
          />
          <Route
            path={basePath.projectSettings.index()}
            component={ProjectSettingsRoutesContainer}
          />
          <Route
            exact
            path={basePath.dashboard.index()}
            component={ProjectDashboard}
          />
          <Redirect to={baseUrl.dashboard.index()} />
        </Switch>
      </BaseUrlContext.Provider>
    </RootUrlContext.Provider>
  );
};

export default ProjectRoutesContainer;
