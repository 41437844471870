// only a few components refer to this theme directly (which they shouldn't).
// TO DO: components referring to this file directly, should use useTheme() instead.

import { lightPalette } from "./lightPalette";
import typography from "./typography";

const customPartOfTheme = {
  name: "UbiOpsLight",

  palette: lightPalette,

  breakpoints: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1440,
  },
  typography: typography,
};

export type AppThemeProps = typeof customPartOfTheme;

export default customPartOfTheme;
