import { Box } from "@mui/material";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { axios } from "libs/data/axios";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import { ActionDialog } from "components/atoms";

import type { RequestRow } from "./types";
import type { DeploymentRequestSingleDetail } from "libs/data/models";

interface RequestsCancelDialogProps {
  isOpen: boolean;
  onClose: () => void;
  selectedRequest: RequestRow | undefined;
  baseUrl: (name?: string, version?: string) => string;
}

export const RequestsCancelDialog = ({
  isOpen,
  onClose,
  selectedRequest,
  baseUrl,
}: RequestsCancelDialogProps) => {
  const dispatch = useDispatch();
  const cancelRequest = useCallback(
    (id) => {
      if (selectedRequest) {
        const { deployment, version } =
          selectedRequest as DeploymentRequestSingleDetail;
        axios({
          method: "PATCH",
          url: `${baseUrl(
            deployment ?? undefined,
            version ?? undefined
          )}/requests/${id}`,
          data: {
            status: "cancelled",
          },
        })
          .then(() => {
            dispatch(
              createSuccessNotification(`Request ${id} is being cancelled`)
            );
            onClose();
          })
          .catch(() => {
            dispatch(
              createErrorNotification(`Request ${id} cannot be cancelled`)
            );
            onClose();
          });
      }
    },
    [baseUrl, dispatch, onClose, selectedRequest]
  );

  return (
    <ActionDialog
      open={isOpen}
      onClose={onClose}
      onAction={() => cancelRequest(selectedRequest?.id)}
      actionButtonText="Cancel request"
    >
      <Box>
        Are you sure you want to cancel request <b>{selectedRequest?.id}</b>?
      </Box>
    </ActionDialog>
  );
};
