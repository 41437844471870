import RetryIcon from "@mui/icons-material/RefreshRounded";
import { Tooltip, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import type { IconButtonProps } from "@mui/material";

const useStyles = makeStyles({
  spin: {
    animation: "$spin 1.5s infinite",
    cursor: "not-allowed",
    pointerEvents: "none",
  },
  "@keyframes spin": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(360deg)",
    },
  },
});

type RetryIconSpinnerProps = {
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  spinCondition?: boolean;
  tooltipText?: string;
} & Omit<IconButtonProps, "onClick">;

export const RetryIconSpinner = ({
  disabled,
  onClick,
  spinCondition,
  tooltipText = "Retry",
  ...props
}: RetryIconSpinnerProps) => {
  const classes = useStyles();

  return (
    <Tooltip title={tooltipText}>
      <span>
        <IconButton
          color="secondary"
          disabled={disabled}
          onClick={onClick}
          className={spinCondition ? classes.spin : ""}
          {...props}
        >
          <RetryIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
};
