import Plus from "@mui/icons-material/AddBoxRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { Typography, Box, Button, useTheme } from "@mui/material";
import { Link, useHistory } from "react-router-dom";

import type { AppThemeProps } from "assets/styles/theme/theme";

import "./ClickableCard.scss";

type ClickableCardProps = {
  title: string;
  amount?: number;
  onClick?: () => void;
  overviewLink?: string;
  icon?: React.ReactElement;
  creationLink?: string;
  children?: React.ReactNode;
};

const ClickableCard = ({
  title,
  amount,
  icon,
  onClick,
  overviewLink,
  creationLink,
  children,
}: ClickableCardProps) => {
  const history = useHistory();
  const theme = useTheme() as AppThemeProps;

  return (
    <Box
      className="clickable-card"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      p={2}
      component={overviewLink ? Link : "div"}
      to={overviewLink}
      onClick={onClick}
      sx={{
        ":hover": { backgroundColor: theme.palette.neutrals[100] },
      }}
    >
      <Box display="flex" alignItems="center">
        {icon}
        <Box ml={2}>
          <Box component="span" mr={1}>
            {amount}
          </Box>
          <Typography id="org-name-title" variant="h5" display="inline">
            {title}
          </Typography>
          {children}
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        {creationLink && (
          <Box mr={2}>
            <Button
              variant="outlined"
              startIcon={<Plus />}
              color="secondary"
              size="small"
              onClick={(e) => {
                e.preventDefault();
                history.push(creationLink);
              }}
            >
              Create
            </Button>
          </Box>
        )}
        <Box>
          <ArrowForwardRoundedIcon />
        </Box>
      </Box>
    </Box>
  );
};

export default ClickableCard;
