import { Grid, Typography } from "@mui/material";
import { ErrorMessage, Formik } from "formik";
import { useMemo } from "react";

import {
  FIELD_DESCRIPTION,
  FIELD_LABELS,
  FIELD_RETENTION_MODE,
  FIELD_RETENTION_TIME,
  FIELD_VERSION,
} from "libs/constants/fields";
import { explanations } from "libs/utilities/explanations";
import { requestRetentionModes } from "libs/utilities/labels-mapping";
import { generateVersionName } from "libs/utilities/utils";

import { SecondaryButton } from "components/atoms";
import type { LabelsDict } from "components/molecules";
import {
  ControlledLabelsInput,
  FormikTextInput,
  FormSection,
} from "components/molecules";

import { PipelineVersionNotification } from "./PipelineVersionNotification";
import { PipelineVersionRetentionMode } from "./PipelineVersionRetentionMode";
import {
  PipelineVersionBaseSchema,
  pipelineVersionBaseValues,
} from "./constants";
import { useRetentionTimeSettings } from "./useRetentionTimeSettings";

import type { PipelineVersionBaseProps } from "./constants";
import type { FormikHelpers, FormikProps, FormikValues } from "formik";

interface PipelineVersionBaseFormProps {
  latestVersion?: string;
  onSubmit: (
    values: PipelineVersionBaseProps,
    formikHelpers: FormikHelpers<PipelineVersionBaseProps>
  ) => void;
  initialValues?: PipelineVersionBaseProps;
  submitText?: string;
}

export const PipelineVersionBaseForm = ({
  latestVersion,
  onSubmit,
  initialValues = {},
  submitText = "Create",
}: PipelineVersionBaseFormProps) => {
  const { defaultRetentionTime } = useRetentionTimeSettings();
  const mergedInitialValues: PipelineVersionBaseProps = useMemo(() => {
    const nextVersionName = latestVersion && generateVersionName(latestVersion);
    if (!nextVersionName)
      return {
        ...pipelineVersionBaseValues,
      };

    return {
      ...pipelineVersionBaseValues,
      [FIELD_VERSION]: nextVersionName,
      [FIELD_RETENTION_MODE]: requestRetentionModes[2].value,
      [FIELD_RETENTION_TIME]: defaultRetentionTime,
    } as PipelineVersionBaseProps;
  }, [defaultRetentionTime, latestVersion]);

  return (
    <Formik
      initialValues={{
        ...mergedInitialValues,
        ...initialValues,
      }}
      onSubmit={onSubmit}
      validationSchema={PipelineVersionBaseSchema}
    >
      {(control: FormikProps<FormikValues>) => {
        const { submitForm, setFieldValue, values } = control;

        return (
          <>
            <FormSection
              description={explanations.pipelines.templates}
              title="Name your version"
            >
              <FormikTextInput
                control={control}
                label="Name"
                name={FIELD_VERSION}
                showErrors
                required
              />
              <ErrorMessage
                name={FIELD_VERSION}
                component={(props) => (
                  <Typography
                    color="error"
                    variant="caption"
                    sx={{
                      marginTop: "-1em",
                    }}
                  >
                    {props.children}
                  </Typography>
                )}
              />
              <FormikTextInput
                control={control}
                label="Description"
                name={FIELD_DESCRIPTION}
                minRows={3}
                multiline
              />
            </FormSection>
            <FormSection
              title="Version labels"
              description={explanations.labels.description("pipelineVersion")}
            >
              <ControlledLabelsInput
                onChange={async (value: LabelsDict) => {
                  await setFieldValue(FIELD_LABELS, value);
                  await control.setFieldTouched(FIELD_LABELS, true);
                }}
                value={values[FIELD_LABELS]}
              />
            </FormSection>
            <FormSection
              title="Request settings"
              description={explanations.deployments.versions.requestsSettings}
            >
              <PipelineVersionRetentionMode control={control} values={values} />
            </FormSection>
            <FormSection
              title="Notifications"
              description={explanations.monitoring.general}
            >
              <PipelineVersionNotification
                setFieldValue={setFieldValue}
                control={control}
                values={values}
              />
            </FormSection>
            <Grid
              item
              container
              spacing={5}
              sx={{ justifyContent: "flex-end" }}
            >
              <Grid
                item
                container
                xs={12}
                sm={7}
                sx={{
                  justifyContent: "flex-end",
                }}
              >
                <SecondaryButton
                  loading={control.isSubmitting}
                  onClick={submitForm}
                >
                  {submitText}
                </SecondaryButton>
              </Grid>
            </Grid>
          </>
        );
      }}
    </Formik>
  );
};
