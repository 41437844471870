export const IlluMakeRequest = () => {
  return (
    <svg
      width="246"
      height="221"
      viewBox="0 0 246 221"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10961_80233)">
        <path
          d="M152.481 128.424H62.7407C59.6975 128.42 56.7799 127.209 54.628 125.056C52.4761 122.903 51.2656 119.984 51.2622 116.939V11.485C51.2656 8.44007 52.4761 5.52081 54.628 3.36769C56.7799 1.21457 59.6975 0.00343959 62.7407 0H146.23L163.96 14.1401V116.939C163.956 119.984 162.746 122.903 160.594 125.056C158.442 127.209 155.525 128.42 152.481 128.424Z"
          fill="#E6E6E6"
        />
        <path
          d="M62.741 3.30627C60.5738 3.30872 58.4961 4.17119 56.9637 5.70447C55.4313 7.23775 54.5693 9.31663 54.5669 11.485V116.939C54.5693 119.107 55.4313 121.186 56.9637 122.719C58.4961 124.252 60.5738 125.115 62.741 125.117H152.482C154.649 125.115 156.727 124.252 158.259 122.719C159.791 121.186 160.653 119.107 160.656 116.939V15.7329L145.074 3.30627H62.741Z"
          fill="white"
        />
        <path
          d="M135.027 33.9694H94.1693C93.9183 33.9694 93.6697 33.9199 93.4378 33.8238C93.2059 33.7277 92.9952 33.5868 92.8177 33.4092C92.6402 33.2316 92.4994 33.0208 92.4033 32.7888C92.3073 32.5567 92.2578 32.308 92.2578 32.0569C92.2578 31.8057 92.3073 31.557 92.4033 31.3249C92.4994 31.0929 92.6402 30.8821 92.8177 30.7045C92.9952 30.5269 93.2059 30.386 93.4378 30.2899C93.6697 30.1938 93.9183 30.1443 94.1693 30.1443H135.027C135.278 30.1443 135.527 30.1938 135.758 30.2899C135.99 30.386 136.201 30.5269 136.379 30.7045C136.556 30.8821 136.697 31.0929 136.793 31.3249C136.889 31.557 136.938 31.8057 136.938 32.0569C136.938 32.308 136.889 32.5567 136.793 32.7888C136.697 33.0208 136.556 33.2316 136.379 33.4092C136.201 33.5868 135.99 33.7277 135.758 33.8238C135.527 33.9199 135.278 33.9694 135.027 33.9694Z"
          fill="#CCCCCC"
        />
        <path
          d="M142.912 40.4243H94.1693C93.6623 40.4243 93.1761 40.2228 92.8177 39.8641C92.4592 39.5054 92.2578 39.0189 92.2578 38.5117C92.2578 38.0044 92.4592 37.518 92.8177 37.1593C93.1761 36.8006 93.6623 36.5991 94.1693 36.5991H142.912C143.419 36.5991 143.905 36.8006 144.263 37.1593C144.622 37.518 144.823 38.0044 144.823 38.5117C144.823 39.0189 144.622 39.5054 144.263 39.8641C143.905 40.2228 143.419 40.4243 142.912 40.4243Z"
          fill="#CCCCCC"
        />
        <path
          d="M135.027 62.8969H94.1693C93.6623 62.8969 93.1761 62.6954 92.8177 62.3367C92.4592 61.9781 92.2578 61.4916 92.2578 60.9843C92.2578 60.4771 92.4592 59.9906 92.8177 59.632C93.1761 59.2733 93.6623 59.0718 94.1693 59.0718H135.027C135.534 59.0718 136.02 59.2733 136.379 59.632C136.737 59.9906 136.938 60.4771 136.938 60.9843C136.938 61.4916 136.737 61.9781 136.379 62.3367C136.02 62.6954 135.534 62.8969 135.027 62.8969Z"
          fill="#CCCCCC"
        />
        <path
          d="M142.912 69.3519H94.1693C93.6623 69.3519 93.1761 69.1504 92.8177 68.7917C92.4592 68.433 92.2578 67.9465 92.2578 67.4393C92.2578 66.9321 92.4592 66.4456 92.8177 66.0869C93.1761 65.7282 93.6623 65.5267 94.1693 65.5267H142.912C143.419 65.5267 143.905 65.7282 144.263 66.0869C144.622 66.4456 144.823 66.9321 144.823 67.4393C144.823 67.9465 144.622 68.433 144.263 68.7917C143.905 69.1504 143.419 69.3519 142.912 69.3519Z"
          fill="#CCCCCC"
        />
        <path
          d="M135.027 91.8245H94.1693C93.6623 91.8245 93.1761 91.623 92.8177 91.2643C92.4592 90.9057 92.2578 90.4192 92.2578 89.912C92.2578 89.4047 92.4592 88.9182 92.8177 88.5596C93.1761 88.2009 93.6623 87.9994 94.1693 87.9994H135.027C135.534 87.9994 136.02 88.2009 136.379 88.5596C136.737 88.9182 136.938 89.4047 136.938 89.912C136.938 90.4192 136.737 90.9057 136.379 91.2643C136.02 91.623 135.534 91.8245 135.027 91.8245Z"
          fill="#CCCCCC"
        />
        <path
          d="M142.912 98.2794H94.1693C93.6623 98.2794 93.1761 98.0779 92.8177 97.7192C92.4592 97.3605 92.2578 96.874 92.2578 96.3668C92.2578 95.8595 92.4592 95.3731 92.8177 95.0144C93.1761 94.6557 93.6623 94.4542 94.1693 94.4542H142.912C143.419 94.4542 143.905 94.6557 144.263 95.0144C144.622 95.3731 144.823 95.8595 144.823 96.3668C144.823 96.874 144.622 97.3605 144.263 97.7192C143.905 98.0779 143.419 98.2794 142.912 98.2794Z"
          fill="#CCCCCC"
        />
        <path
          d="M77.1549 42.0441C80.8862 42.0441 83.9109 39.0177 83.9109 35.2843C83.9109 31.5509 80.8862 28.5244 77.1549 28.5244C73.4237 28.5244 70.3989 31.5509 70.3989 35.2843C70.3989 39.0177 73.4237 42.0441 77.1549 42.0441Z"
          fill="#F2682A"
        />
        <path
          d="M76.4855 37.8629C76.3335 37.8631 76.1856 37.8139 76.064 37.7227L76.0564 37.7171L74.4687 36.5018C74.3204 36.3878 74.2233 36.2195 74.1989 36.034C74.1745 35.8485 74.2247 35.6608 74.3385 35.5123C74.4523 35.3638 74.6203 35.2666 74.8057 35.242C74.9912 35.2174 75.1787 35.2675 75.3273 35.3812L76.3557 36.1703L78.7859 32.998C78.8422 32.9245 78.9125 32.8627 78.9927 32.8164C79.0729 32.77 79.1614 32.7399 79.2533 32.7278C79.3451 32.7156 79.4384 32.7217 79.5279 32.7456C79.6173 32.7696 79.7012 32.8109 79.7747 32.8673L79.7599 32.8884L79.7753 32.8675C79.9236 32.9815 80.0207 33.1498 80.0451 33.3353C80.0696 33.5208 80.0195 33.7085 79.9058 33.8571L77.0474 37.5867C76.9812 37.6727 76.8962 37.7422 76.7989 37.7899C76.7015 37.8377 76.5945 37.8624 76.4861 37.862L76.4855 37.8629Z"
          fill="white"
        />
        <path
          d="M77.8507 70.9716C81.582 70.9716 84.6067 67.9451 84.6067 64.2118C84.6067 60.4784 81.582 57.4519 77.8507 57.4519C74.1195 57.4519 71.0947 60.4784 71.0947 64.2118C71.0947 67.9451 74.1195 70.9716 77.8507 70.9716Z"
          fill="#CCCCCC"
        />
        <path
          d="M77.1549 99.8993C80.8862 99.8993 83.9109 96.8728 83.9109 93.1394C83.9109 89.406 80.8862 86.3795 77.1549 86.3795C73.4237 86.3795 70.3989 89.406 70.3989 93.1394C70.3989 96.8728 73.4237 99.8993 77.1549 99.8993Z"
          fill="#CCCCCC"
        />
        <path
          d="M163.615 14.5952H149.726C148.712 14.5952 147.74 14.1922 147.023 13.4749C146.306 12.7575 145.903 11.7846 145.903 10.7701V0.506287C145.903 0.461351 145.916 0.417321 145.94 0.379254C145.964 0.341186 145.998 0.310619 146.038 0.291056C146.079 0.271494 146.124 0.263728 146.168 0.268651C146.213 0.273574 146.255 0.290985 146.291 0.318889L163.763 14.1687C163.802 14.1996 163.83 14.2417 163.844 14.2894C163.858 14.3371 163.857 14.3879 163.841 14.4347C163.824 14.4816 163.794 14.5222 163.753 14.551C163.713 14.5798 163.664 14.5952 163.615 14.5952Z"
          fill="#E6E6E6"
        />
        <path
          d="M69.5985 148.182L16.5033 159.38C14.969 159.702 13.3696 159.402 12.0563 158.545C10.7429 157.688 9.82279 156.346 9.49773 154.811L0.386676 111.561C0.0650576 110.026 0.365211 108.426 1.22129 107.112C2.07736 105.798 3.41944 104.877 4.9531 104.552L58.0483 93.3538C59.5827 93.032 61.182 93.3323 62.4953 94.1889C63.8087 95.0454 64.7288 96.3883 65.0539 97.9228L74.1649 141.173C74.4866 142.708 74.1864 144.308 73.3303 145.622C72.4743 146.936 71.1322 147.857 69.5985 148.182Z"
          fill="white"
        />
        <path
          d="M69.5985 148.182L16.5033 159.38C14.969 159.702 13.3696 159.402 12.0563 158.545C10.7429 157.688 9.82279 156.346 9.49773 154.811L0.386676 111.561C0.0650576 110.026 0.365211 108.426 1.22129 107.112C2.07736 105.798 3.41944 104.877 4.9531 104.552L58.0483 93.3538C59.5827 93.032 61.182 93.3323 62.4953 94.1889C63.8087 95.0454 64.7288 96.3883 65.0539 97.9228L74.1649 141.173C74.4866 142.708 74.1864 144.308 73.3303 145.622C72.4743 146.936 71.1322 147.857 69.5985 148.182ZM5.09658 105.233C3.74336 105.52 2.55918 106.332 1.80382 107.492C1.04846 108.651 0.783608 110.063 1.06738 111.418L10.1784 154.667C10.4653 156.021 11.2772 157.206 12.436 157.962C13.5948 158.718 15.006 158.983 16.3598 158.699L69.455 147.501C70.8082 147.214 71.9924 146.402 72.7478 145.242C73.5032 144.083 73.768 142.671 73.4842 141.316L64.3732 98.0664C64.0863 96.7124 63.2745 95.5276 62.1156 94.7718C60.9568 94.016 59.5456 93.751 58.1918 94.0349L5.09658 105.233Z"
          fill="#3F3D56"
        />
        <path
          d="M54.7491 107.614L27.9527 113.266C27.7182 113.32 27.4718 113.284 27.2633 113.163C27.0548 113.043 26.8997 112.848 26.8294 112.618C26.7973 112.497 26.79 112.372 26.8079 112.249C26.8258 112.126 26.8686 112.008 26.9337 111.902C26.9987 111.796 27.0847 111.704 27.1863 111.632C27.2879 111.561 27.403 111.511 27.5246 111.485L54.8018 105.732C55.8668 106.333 55.5305 107.45 54.7491 107.614Z"
          fill="#CCCCCC"
        />
        <path
          d="M55.7399 112.317L28.9434 117.969C28.7089 118.023 28.4625 117.987 28.254 117.866C28.0455 117.746 27.8904 117.551 27.8202 117.32C27.788 117.2 27.7807 117.075 27.7986 116.952C27.8165 116.829 27.8593 116.711 27.9244 116.605C27.9894 116.499 28.0754 116.407 28.177 116.335C28.2786 116.264 28.3937 116.214 28.5153 116.188L55.7925 110.435C56.8575 111.036 56.5212 112.153 55.7399 112.317Z"
          fill="#CCCCCC"
        />
        <path
          d="M23.7148 121.868L15.5368 123.593C15.2697 123.649 14.9913 123.597 14.7627 123.448C14.5341 123.299 14.3739 123.065 14.3174 122.798L12.2832 113.142C12.2272 112.874 12.2794 112.596 12.4284 112.367C12.5774 112.138 12.811 111.978 13.078 111.921L21.256 110.197C21.5231 110.141 21.8014 110.193 22.03 110.342C22.2586 110.491 22.4188 110.725 22.4754 110.992L24.5096 120.648C24.5656 120.915 24.5134 121.194 24.3644 121.423C24.2154 121.652 23.9817 121.812 23.7148 121.868Z"
          fill="#CCCCCC"
        />
        <path
          d="M57.6908 121.963L16.7945 130.588C16.56 130.643 16.3136 130.606 16.1051 130.486C15.8966 130.365 15.7415 130.17 15.6712 129.94C15.6391 129.82 15.6318 129.694 15.6497 129.571C15.6676 129.448 15.7104 129.33 15.7754 129.224C15.8405 129.118 15.9264 129.027 16.0281 128.955C16.1297 128.883 16.2448 128.833 16.3664 128.807L57.7437 120.081C58.8088 120.681 58.4721 121.798 57.6908 121.963Z"
          fill="#CCCCCC"
        />
        <path
          d="M58.6815 126.668L17.7852 135.293C17.5507 135.347 17.3043 135.31 17.0958 135.19C16.8873 135.07 16.7322 134.875 16.662 134.644C16.6298 134.524 16.6225 134.399 16.6404 134.276C16.6583 134.153 16.7011 134.034 16.7662 133.928C16.8312 133.822 16.9172 133.731 17.0188 133.659C17.1204 133.588 17.2355 133.537 17.3571 133.512L58.7344 124.785C59.7995 125.386 59.4629 126.503 58.6815 126.668Z"
          fill="#CCCCCC"
        />
        <path
          d="M59.6722 131.369L18.7759 139.994C18.5414 140.048 18.295 140.012 18.0865 139.891C17.878 139.771 17.723 139.576 17.6527 139.346C17.6206 139.225 17.6132 139.1 17.6311 138.977C17.6491 138.854 17.6918 138.736 17.7569 138.63C17.8219 138.524 17.9079 138.432 18.0095 138.361C18.1111 138.289 18.2262 138.239 18.3479 138.213L59.7252 129.486C60.7902 130.087 60.4536 131.204 59.6722 131.369Z"
          fill="#CCCCCC"
        />
        <path
          d="M60.6629 136.073L19.7667 144.698C19.5322 144.752 19.2858 144.716 19.0772 144.595C18.8687 144.475 18.7137 144.28 18.6434 144.05C18.6113 143.929 18.6039 143.804 18.6219 143.681C18.6398 143.558 18.6826 143.44 18.7476 143.334C18.8127 143.228 18.8986 143.136 19.0002 143.065C19.1018 142.993 19.217 142.943 19.3386 142.917L60.7159 134.19C61.7809 134.791 61.4443 135.908 60.6629 136.073Z"
          fill="#CCCCCC"
        />
        <path
          d="M61.6532 140.774L20.7569 149.4C20.5224 149.454 20.276 149.417 20.0675 149.297C19.859 149.177 19.7039 148.982 19.6336 148.751C19.6015 148.631 19.5942 148.506 19.6121 148.383C19.63 148.26 19.6728 148.141 19.7379 148.035C19.8029 147.929 19.8889 147.838 19.9905 147.766C20.0921 147.695 20.2072 147.644 20.3288 147.619L61.7061 138.892C62.7712 139.493 62.4345 140.61 61.6532 140.774Z"
          fill="#CCCCCC"
        />
        <path
          d="M68.8723 119.243C68.7794 119.411 68.6999 119.587 68.6346 119.768L51.8923 123.925L48.9797 120.911L43.8579 124.714L48.4956 130.553C48.8703 131.024 49.3878 131.362 49.9704 131.514C50.553 131.666 51.1692 131.625 51.7267 131.398L69.8937 123.97C70.4908 124.41 71.2076 124.657 71.9487 124.679C72.6899 124.702 73.4202 124.497 74.0426 124.094C74.665 123.691 75.1499 123.108 75.433 122.422C75.716 121.736 75.7836 120.981 75.627 120.256C75.4703 119.53 75.0967 118.87 74.5558 118.363C74.015 117.855 73.3326 117.525 72.5993 117.415C71.866 117.305 71.1167 117.421 70.4509 117.747C69.7852 118.074 69.2345 118.595 68.8723 119.243Z"
          fill="#A0616A"
        />
        <path
          d="M11.7246 156.236C12.2466 156.075 12.7268 155.801 13.1316 155.434C13.5363 155.067 13.8557 154.615 14.0672 154.111C14.2787 153.607 14.3772 153.063 14.3558 152.517C14.3344 151.971 14.1935 151.436 13.9432 150.95L25.4649 118.908L17.4634 117.489L8.65401 149.432C7.86623 149.907 7.27992 150.654 7.00619 151.533C6.73246 152.411 6.79032 153.36 7.16881 154.198C7.54731 155.037 8.22013 155.708 9.05979 156.083C9.89945 156.459 10.8476 156.513 11.7246 156.236Z"
          fill="#A0616A"
        />
        <path
          d="M24.0016 124.596L16.1015 121.562C15.8767 121.476 15.6731 121.342 15.5047 121.17C15.3363 120.998 15.2069 120.791 15.1253 120.564C15.0438 120.338 15.0121 120.096 15.0323 119.856C15.0525 119.616 15.1242 119.383 15.2424 119.173L19.3868 111.821C19.8348 110.673 20.7195 109.749 21.8469 109.253C22.9744 108.756 24.2525 108.728 25.4012 109.173C26.5499 109.618 27.4754 110.5 27.9748 111.627C28.4742 112.753 28.5067 114.032 28.0652 115.183L26.2372 123.395C26.1848 123.631 26.0824 123.852 25.9369 124.044C25.7914 124.236 25.6062 124.394 25.3941 124.508C25.1819 124.622 24.9477 124.688 24.7074 124.704C24.4671 124.719 24.2264 124.682 24.0016 124.596Z"
          fill="#F2682A"
        />
        <path
          d="M41.8332 216.394L46.0976 216.394L48.1262 199.936L41.8325 199.936L41.8332 216.394Z"
          fill="#A0616A"
        />
        <path
          d="M40.7456 215.001L49.1436 215.001H49.144C49.8468 215.001 50.5427 215.139 51.192 215.408C51.8414 215.677 52.4314 216.072 52.9283 216.569C53.4253 217.066 53.8195 217.657 54.0885 218.306C54.3574 218.956 54.4959 219.652 54.4959 220.356V220.53L40.7459 220.53L40.7456 215.001Z"
          fill="#2F2E41"
        />
        <path
          d="M17.8332 216.394L22.0976 216.394L24.1262 199.936L17.8325 199.936L17.8332 216.394Z"
          fill="#A0616A"
        />
        <path
          d="M16.7451 215.001L25.1431 215.001H25.1435C25.8463 215.001 26.5422 215.139 27.1916 215.408C27.8409 215.677 28.4309 216.072 28.9278 216.569C29.4248 217.066 29.819 217.657 30.088 218.306C30.357 218.956 30.4954 219.652 30.4954 220.356V220.53L16.7454 220.53L16.7451 215.001Z"
          fill="#2F2E41"
        />
        <path
          d="M22.3035 209.77H16.907C16.6952 209.77 16.4856 209.727 16.2909 209.644C16.0962 209.56 15.9205 209.438 15.7743 209.285C15.6281 209.131 15.5146 208.95 15.4405 208.751C15.3665 208.553 15.3335 208.341 15.3436 208.129L17.9069 153.025L18.0684 153.021L44.1277 152.341L49.2271 207.477C49.2467 207.684 49.225 207.892 49.1634 208.09C49.1017 208.289 49.0013 208.473 48.8679 208.632C48.7345 208.791 48.5708 208.922 48.3864 209.017C48.202 209.112 48.0006 209.17 47.7937 209.186L41.7323 209.671C41.3515 209.701 40.9728 209.592 40.6674 209.362C40.362 209.132 40.151 208.799 40.0741 208.425L32.3209 169.175C32.2997 169.056 32.2365 168.948 32.1427 168.871C32.049 168.795 31.9309 168.754 31.8099 168.757H31.8078C31.6864 168.755 31.568 168.796 31.4744 168.873C31.3807 168.951 31.318 169.059 31.2977 169.179L23.8393 208.506C23.7687 208.862 23.5771 209.182 23.2971 209.413C23.0171 209.643 22.666 209.769 22.3035 209.77Z"
          fill="#2F2E41"
        />
        <path
          d="M32.1886 103.879C36.9069 103.879 40.7318 100.052 40.7318 95.3307C40.7318 90.6098 36.9069 86.7827 32.1886 86.7827C27.4704 86.7827 23.6455 90.6098 23.6455 95.3307C23.6455 100.052 27.4704 103.879 32.1886 103.879Z"
          fill="#A0616A"
        />
        <path
          d="M29.3241 159.483C25.2523 159.483 21.07 158.412 17.6029 155.245C17.4184 155.078 17.2759 154.869 17.1871 154.637C17.0983 154.404 17.0656 154.153 17.0917 153.906C17.4628 150.569 19.263 133.344 18.2492 121.837C18.0514 119.687 18.3555 117.522 19.1375 115.51C19.9196 113.498 21.1582 111.696 22.7556 110.246C24.325 108.798 26.2091 107.736 28.2592 107.142C30.3093 106.548 32.4693 106.439 34.5687 106.823C34.6751 106.842 34.7815 106.862 34.888 106.883C41.7276 108.207 46.4798 114.541 45.9421 121.615C45.1575 131.938 44.4541 145.901 45.5585 153.797C45.6043 154.12 45.5479 154.449 45.3973 154.739C45.2467 155.028 45.0094 155.263 44.7186 155.411C42.2056 156.681 35.9093 159.483 29.3241 159.483Z"
          fill="#F2682A"
        />
        <path
          d="M40.4719 125.211C40.2715 125.077 40.1022 124.902 39.9755 124.697C39.8489 124.492 39.7679 124.263 39.7382 124.024L38.6974 115.674C38.367 114.487 38.5206 113.217 39.1246 112.143C39.7286 111.069 40.7336 110.278 41.9194 109.944C43.1051 109.61 44.3748 109.76 45.4501 110.361C46.5254 110.963 47.3185 111.966 47.6555 113.152L51.0838 120.865C51.1816 121.085 51.2309 121.323 51.2283 121.564C51.2256 121.805 51.1711 122.043 51.0684 122.26C50.9658 122.478 50.8174 122.672 50.6334 122.827C50.4494 122.982 50.234 123.096 50.0021 123.161L41.8499 125.431C41.6179 125.495 41.3747 125.509 41.1369 125.471C40.8991 125.433 40.6723 125.344 40.4719 125.211Z"
          fill="#F2682A"
        />
        <path
          d="M29.2065 103.758C28.3979 103.773 27.5943 103.628 26.8422 103.33C26.5113 103.202 26.1678 103.098 25.8374 102.97C22.9203 101.842 20.9987 98.7337 20.9298 95.6054C20.861 92.4771 22.4801 89.4408 24.8844 87.4398C27.2887 85.4387 30.4028 84.4114 33.521 84.1738C36.8799 83.9179 40.6621 84.7702 42.539 87.5691C43.0386 88.3141 43.388 89.218 43.092 90.1175C43.0033 90.389 42.845 90.6324 42.6328 90.8234C41.7946 91.5695 40.9589 91.0085 40.1044 90.9589C38.93 90.8908 37.8751 91.842 37.4959 92.9563C37.1168 94.0706 37.2837 95.2974 37.5898 96.4339C37.8231 97.1178 37.9701 97.8282 38.0275 98.5485C38.0522 98.9097 37.9843 99.2713 37.8302 99.5988C37.6762 99.9264 37.4411 100.209 37.1472 100.42C36.4133 100.864 35.4495 100.607 34.7151 100.164C33.9808 99.7211 33.3463 99.1049 32.5531 98.7788C31.7599 98.4527 30.6942 98.5251 30.2325 99.2481C30.0858 99.5112 29.9865 99.7981 29.9391 100.096C29.5253 101.982 29.6204 101.871 29.2065 103.758Z"
          fill="#2F2E41"
        />
        <path
          d="M66.9272 221H0.839043C0.746792 221 0.65832 220.963 0.593088 220.898C0.527857 220.833 0.491211 220.744 0.491211 220.652C0.491211 220.56 0.527857 220.471 0.593088 220.406C0.65832 220.341 0.746792 220.304 0.839043 220.304H66.9272C67.0195 220.304 67.1079 220.341 67.1731 220.406C67.2384 220.471 67.275 220.56 67.275 220.652C67.275 220.744 67.2384 220.833 67.1731 220.898C67.1079 220.963 67.0195 221 66.9272 221Z"
          fill="#CCCCCC"
        />
        <path
          d="M236.093 149.177L188.768 175.737C187.4 176.503 185.784 176.695 184.275 176.27C182.766 175.845 181.486 174.839 180.718 173.472L159.107 134.922C158.342 133.553 158.15 131.936 158.575 130.426C158.999 128.916 160.005 127.636 161.371 126.867L208.697 100.307C210.065 99.5407 211.681 99.3492 213.19 99.7739C214.699 100.199 215.978 101.205 216.747 102.572L238.358 141.122C239.123 142.491 239.314 144.108 238.89 145.618C238.465 147.128 237.46 148.408 236.093 149.177Z"
          fill="white"
        />
        <path
          d="M236.093 149.177L188.768 175.737C187.4 176.503 185.784 176.695 184.275 176.27C182.766 175.845 181.486 174.839 180.718 173.472L159.107 134.922C158.342 133.553 158.15 131.936 158.575 130.426C158.999 128.916 160.005 127.636 161.371 126.867L208.697 100.307C210.065 99.5407 211.681 99.3492 213.19 99.7739C214.699 100.199 215.978 101.205 216.747 102.572L238.358 141.122C239.123 142.491 239.314 144.108 238.89 145.618C238.465 147.128 237.46 148.408 236.093 149.177ZM161.712 127.475C160.506 128.153 159.619 129.282 159.244 130.615C158.87 131.947 159.038 133.374 159.714 134.582L181.325 173.131C182.003 174.338 183.131 175.226 184.463 175.6C185.795 175.975 187.22 175.806 188.428 175.13L235.753 148.569C236.959 147.891 237.846 146.762 238.221 145.429C238.595 144.097 238.426 142.67 237.751 141.462L216.14 102.913C215.462 101.706 214.333 100.818 213.002 100.444C211.67 100.069 210.244 100.238 209.037 100.914L161.712 127.475Z"
          fill="#3F3D56"
        />
        <path
          d="M209.807 114.902L185.923 128.307C185.715 128.429 185.469 128.468 185.234 128.415C184.999 128.363 184.793 128.223 184.657 128.024C184.591 127.919 184.546 127.801 184.527 127.679C184.507 127.556 184.513 127.43 184.543 127.31C184.573 127.189 184.628 127.076 184.704 126.977C184.779 126.878 184.874 126.796 184.983 126.735L209.295 113.09C210.491 113.345 210.504 114.511 209.807 114.902Z"
          fill="#CCCCCC"
        />
        <path
          d="M212.157 119.094L188.273 132.499C188.065 132.621 187.819 132.659 187.584 132.607C187.349 132.554 187.143 132.415 187.007 132.216C186.941 132.111 186.896 131.993 186.877 131.87C186.857 131.748 186.863 131.622 186.893 131.501C186.924 131.381 186.978 131.268 187.054 131.169C187.129 131.07 187.224 130.988 187.333 130.927L211.645 117.282C212.841 117.536 212.854 118.703 212.157 119.094Z"
          fill="#CCCCCC"
        />
        <path
          d="M184.448 137.783L177.159 141.874C176.921 142.008 176.639 142.041 176.377 141.967C176.114 141.893 175.891 141.718 175.757 141.48L170.932 132.873C170.799 132.635 170.766 132.353 170.84 132.091C170.914 131.828 171.089 131.605 171.327 131.471L178.616 127.38C178.854 127.247 179.135 127.214 179.398 127.287C179.661 127.361 179.883 127.536 180.017 127.774L184.842 136.381C184.975 136.62 185.009 136.901 184.935 137.164C184.861 137.427 184.686 137.649 184.448 137.783Z"
          fill="#CCCCCC"
        />
        <path
          d="M216.9 127.716L180.448 148.174C180.24 148.296 179.994 148.335 179.759 148.282C179.524 148.23 179.318 148.09 179.182 147.891C179.116 147.786 179.071 147.669 179.052 147.546C179.032 147.423 179.037 147.298 179.068 147.177C179.098 147.056 179.153 146.943 179.229 146.844C179.304 146.746 179.399 146.663 179.507 146.603L216.388 125.904C217.584 126.158 217.596 127.325 216.9 127.716Z"
          fill="#CCCCCC"
        />
        <path
          d="M219.25 131.909L182.798 152.367C182.591 152.489 182.345 152.528 182.11 152.475C181.875 152.423 181.669 152.283 181.533 152.084C181.466 151.979 181.422 151.862 181.402 151.739C181.382 151.616 181.388 151.491 181.418 151.37C181.449 151.249 181.504 151.136 181.579 151.038C181.655 150.939 181.75 150.857 181.858 150.796L218.739 130.097C219.934 130.351 219.947 131.518 219.25 131.909Z"
          fill="#CCCCCC"
        />
        <path
          d="M221.599 136.099L185.147 156.558C184.94 156.68 184.694 156.718 184.459 156.666C184.224 156.613 184.018 156.473 183.882 156.275C183.815 156.17 183.771 156.052 183.751 155.929C183.732 155.806 183.737 155.681 183.768 155.56C183.798 155.44 183.853 155.327 183.928 155.228C184.004 155.129 184.099 155.047 184.207 154.986L221.088 134.287C222.284 134.542 222.296 135.708 221.599 136.099Z"
          fill="#CCCCCC"
        />
        <path
          d="M223.95 140.292L187.498 160.75C187.29 160.873 187.044 160.911 186.809 160.859C186.574 160.806 186.368 160.666 186.232 160.467C186.165 160.362 186.121 160.245 186.101 160.122C186.082 159.999 186.087 159.874 186.118 159.753C186.148 159.633 186.203 159.52 186.278 159.421C186.354 159.322 186.449 159.24 186.557 159.179L223.438 138.48C224.634 138.735 224.646 139.901 223.95 140.292Z"
          fill="#CCCCCC"
        />
        <path
          d="M226.299 144.483L189.847 164.941C189.64 165.063 189.394 165.102 189.159 165.049C188.924 164.997 188.717 164.857 188.582 164.658C188.515 164.553 188.471 164.436 188.451 164.313C188.431 164.19 188.437 164.064 188.467 163.944C188.498 163.823 188.552 163.71 188.628 163.611C188.704 163.513 188.798 163.43 188.907 163.37L225.788 142.671C226.983 142.925 226.996 144.092 226.299 144.483Z"
          fill="#CCCCCC"
        />
        <path
          d="M219.94 160.187C220.138 159.749 220.238 159.273 220.233 158.792C220.228 158.311 220.117 157.837 219.909 157.403C219.7 156.97 219.4 156.587 219.028 156.282C218.656 155.978 218.221 155.758 217.755 155.64L216.764 143.99L211.997 147.753L213.669 158.239C213.522 159.035 213.672 159.858 214.09 160.551C214.509 161.244 215.167 161.76 215.94 162C216.713 162.24 217.547 162.188 218.284 161.854C219.021 161.52 219.61 160.927 219.94 160.187Z"
          fill="#FFB8B8"
        />
        <path
          d="M216.854 154.42L212.895 154.892C212.694 154.916 212.49 154.898 212.296 154.84C212.102 154.781 211.922 154.683 211.768 154.552C211.613 154.421 211.488 154.259 211.399 154.077C211.31 153.895 211.259 153.696 211.25 153.494L210.673 140.129L203.168 119.613C202.867 118.977 202.73 118.275 202.769 117.572C202.808 116.869 203.021 116.187 203.391 115.588C203.575 115.298 203.785 115.026 204.02 114.776C204.482 114.278 205.057 113.899 205.697 113.672C206.336 113.445 207.021 113.376 207.693 113.47C208.386 113.563 209.046 113.819 209.62 114.218C210.194 114.617 210.664 115.147 210.992 115.764L215.887 126.026C217.934 130.318 218.9 135.047 218.702 139.799L218.15 153.02C218.136 153.366 218 153.695 217.766 153.951C217.533 154.207 217.217 154.372 216.874 154.417L216.854 154.42Z"
          fill="#F2682A"
        />
        <path
          d="M230.916 212.182L227.245 213.794L219.283 200.394L224.701 198.015L230.916 212.182Z"
          fill="#FFB8B8"
        />
        <path
          d="M233.414 215.332L221.577 220.53L221.511 220.38C221.246 219.775 221.102 219.123 221.088 218.462C221.074 217.801 221.191 217.144 221.431 216.528C221.671 215.912 222.03 215.349 222.487 214.872C222.944 214.395 223.491 214.013 224.096 213.747L231.326 210.572L233.414 215.332Z"
          fill="#2F2E41"
        />
        <path
          d="M181.632 216.642H177.622L175.715 201.169L181.632 201.17L181.632 216.642Z"
          fill="#FFB8B8"
        />
        <path
          d="M182.654 220.53L169.727 220.53V220.366C169.727 219.705 169.857 219.05 170.11 218.44C170.363 217.829 170.734 217.274 171.201 216.806C171.668 216.339 172.223 215.968 172.833 215.715C173.444 215.462 174.098 215.332 174.759 215.332L182.654 215.332L182.654 220.53Z"
          fill="#2F2E41"
        />
        <path
          d="M220.663 211.352L198.33 161.485C198.29 161.392 198.222 161.313 198.135 161.26C198.048 161.207 197.947 161.183 197.845 161.19C197.744 161.197 197.647 161.236 197.568 161.3C197.49 161.365 197.433 161.453 197.406 161.551L183.799 209.716C183.709 210.053 183.502 210.347 183.215 210.545C182.928 210.743 182.579 210.832 182.233 210.795L174.801 210.052C174.418 210.012 174.066 209.824 173.819 209.528C173.573 209.232 173.451 208.852 173.48 208.468C175.844 179.051 179.57 151.287 190.733 133.112L190.792 133.015L207.677 136.328L230.22 208.153C230.331 208.522 230.293 208.921 230.114 209.264C229.935 209.606 229.63 209.865 229.263 209.985L222.465 212.171C222.117 212.284 221.739 212.263 221.405 212.112C221.072 211.96 220.807 211.689 220.663 211.352Z"
          fill="#2F2E41"
        />
        <path
          d="M198.954 107.568C203.389 107.568 206.985 103.97 206.985 99.5313C206.985 95.093 203.389 91.495 198.954 91.495C194.518 91.495 190.922 95.093 190.922 99.5313C190.922 103.97 194.518 107.568 198.954 107.568Z"
          fill="#FFB8B8"
        />
        <path
          d="M189.695 134.503L189.593 134.482L189.569 134.38C188.486 129.809 188.461 124.82 189.492 119.553C190.015 116.86 191.471 114.439 193.603 112.715C195.735 110.992 198.406 110.076 201.147 110.13C204.121 110.19 206.959 111.385 209.081 113.47C211.203 115.555 212.448 118.373 212.562 121.347C212.907 130.407 209.206 138.261 209.169 138.34L209.114 138.455L189.695 134.503Z"
          fill="#F2682A"
        />
        <path
          d="M168.165 149.792C168.64 149.721 169.095 149.548 169.496 149.283C169.897 149.017 170.235 148.668 170.487 148.258C170.738 147.848 170.897 147.388 170.951 146.91C171.006 146.432 170.955 145.948 170.802 145.492L180.053 138.348L174.309 136.376L166.405 143.462C165.656 143.77 165.046 144.341 164.69 145.069C164.335 145.796 164.258 146.629 164.475 147.409C164.692 148.189 165.187 148.863 165.867 149.302C166.547 149.741 167.365 149.915 168.165 149.792Z"
          fill="#FFB8B8"
        />
        <path
          d="M173.217 144.404C172.916 144.582 172.561 144.647 172.217 144.586C171.872 144.525 171.56 144.343 171.338 144.073L168.797 140.998C168.668 140.842 168.572 140.661 168.517 140.466C168.461 140.271 168.446 140.067 168.472 139.866C168.499 139.665 168.567 139.472 168.672 139.298C168.777 139.125 168.916 138.975 169.081 138.858L179.997 131.134L193.168 113.709C193.54 113.112 194.055 112.616 194.667 112.268C195.279 111.92 195.968 111.731 196.672 111.717C197.014 111.715 197.357 111.744 197.694 111.806C198.362 111.925 198.992 112.204 199.53 112.619C200.067 113.034 200.496 113.573 200.78 114.189C201.078 114.822 201.221 115.516 201.196 116.214C201.172 116.913 200.981 117.596 200.64 118.206L190.888 134.009L190.866 134.043L173.217 144.404Z"
          fill="#F2682A"
        />
        <path
          d="M200.39 99.0326C199.798 98.0583 198.492 97.783 197.358 97.8942C196.223 98.0054 195.12 98.4042 193.981 98.3954C190.817 98.3708 188.374 94.5121 189.705 91.6395C190.796 89.2836 193.52 88.2491 195.985 87.4371C201.153 85.7348 207.09 84.0991 211.78 86.8594C213.274 87.765 214.5 89.0534 215.33 90.5916C216.161 92.1297 216.566 93.8618 216.503 95.6089C216.398 97.7812 215.566 100.013 216.27 102.07C217.081 104.445 219.653 105.718 222.084 106.335C226.345 107.415 230.838 107.241 235.126 108.209C239.413 109.178 243.807 111.751 244.963 115.994C245.722 118.781 244.93 121.782 243.618 124.356C242.28 126.981 240.419 129.306 238.152 131.187C235.884 133.068 233.256 134.466 230.43 135.296C227.603 136.126 224.637 136.369 221.713 136.012C218.789 135.654 215.968 134.703 213.424 133.217C208.241 130.188 204.498 125.07 202.267 119.494C201.002 116.242 200.112 112.855 199.613 109.401C199.113 106.168 199.537 102.9 200.442 99.756C200.485 99.6386 200.502 99.5134 200.493 99.3887C200.484 99.2641 200.449 99.1427 200.39 99.0326Z"
          fill="#2F2E41"
        />
        <path
          d="M229.365 221H163.277C163.184 221 163.096 220.963 163.031 220.898C162.965 220.833 162.929 220.744 162.929 220.652C162.929 220.56 162.965 220.471 163.031 220.406C163.096 220.341 163.184 220.304 163.277 220.304H229.365C229.457 220.304 229.545 220.341 229.611 220.406C229.676 220.471 229.713 220.56 229.713 220.652C229.713 220.744 229.676 220.833 229.611 220.898C229.545 220.963 229.457 221 229.365 221Z"
          fill="#CCCCCC"
        />
      </g>
      <defs>
        <clipPath id="clip0_10961_80233">
          <rect
            width="245"
            height="221"
            fill="white"
            transform="translate(0.258789)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
