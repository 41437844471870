import axios from "axios";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { UPLOAD_TASK } from "libs/constants/constants";
import { filesUpload, useFilesList } from "libs/data/endpoints/files/files";
import { env, ENV_NAMES } from "libs/env";
import { createErrorNotification } from "libs/utilities/notifications";
import { LOADED, LOADING } from "libs/utilities/request-statuses";
import { getRandomId } from "libs/utilities/utils";
import { PROVIDERS } from "pages/organizations/:organizationName/projects/:projectName/storage/constants";
import { addTask, updateTask } from "store/features/taskManager";

import type { AxiosError } from "axios";

export const useFileUpload = (projectName: string, bucketName: string) => {
  const dispatch = useDispatch();
  const { mutate } = useFilesList(projectName, bucketName);
  const taskId = getRandomId();

  const updateTaskProgress = useCallback(
    (event) =>
      dispatch(
        updateTask({
          id: taskId,
          progress: Math.round((event.loaded * 100) / event.total),
        })
      ),
    [dispatch, taskId]
  );

  const uploadFile = useCallback(
    async (provider: string, url: string, file: File, filePath: string) => {
      dispatch(
        addTask({
          id: taskId,
          message: file.name,
          type: UPLOAD_TASK,
          status: LOADING,
          bucket: {
            name: bucketName,
            prefix: filePath ? `files/${filePath}` : `files`,
          },
          progress: 0,
        })
      );

      if (provider !== PROVIDERS.azure) {
        return await axios.put(url, file, {
          onUploadProgress: updateTaskProgress,
        });
      } else {
        const options = {
          headers: {
            "x-ms-version": "2020-04-08",
            "x-ms-blob-type": "BlockBlob",
          },
          onUploadProgress: updateTaskProgress,
        };

        return await axios.put(url, file, options);
      }
    },
    [bucketName, dispatch, taskId, updateTaskProgress]
  );

  return useCallback(
    async (fullFileName: string, file: File) => {
      const filePath = fullFileName.substring(0, fullFileName.lastIndexOf("/"));
      try {
        const response = await filesUpload(
          projectName,
          bucketName,
          encodeURIComponent(fullFileName),
          {}
        );
        if (response?.url) {
          const proxyEnabled = env.get(ENV_NAMES.FILES_PROXY_ENABLED);

          if (response.provider === PROVIDERS.ubiops || !proxyEnabled) {
            await uploadFile(response.provider, response.url, file, filePath);

            dispatch(
              updateTask({
                id: taskId,
                status: LOADED,
              })
            );
          } else {
            const withoutHttps = response.url.replace(/^https?:\/\//, "");
            const proxyHostUrl = env.get(ENV_NAMES.FILES_PROXY_HOST);
            const proxyUrl = `https://${proxyHostUrl}/${withoutHttps}`;

            await uploadFile(response.provider, proxyUrl, file, filePath);

            dispatch(
              updateTask({
                id: taskId,
                status: LOADED,
              })
            );
          }
        }
        mutate();
      } catch (e) {
        dispatch(createErrorNotification((e as AxiosError)?.message));
      }
    },
    [projectName, bucketName, mutate, uploadFile, dispatch, taskId]
  );
};
