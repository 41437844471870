/**
 * Generated by orval 🍺
 * Do not edit manually.
 * UbiOps
 * OpenAPI spec version: v2.1
 */

export type WebhookTestDetailStatus =
  typeof WebhookTestDetailStatus[keyof typeof WebhookTestDetailStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WebhookTestDetailStatus = {
  pending: "pending",
  processing: "processing",
  completed: "completed",
  failed: "failed",
  cancelled: "cancelled",
  cancelled_pending: "cancelled_pending",
  offloaded: "offloaded",
  offloaded_pending: "offloaded_pending",
} as const;
