import { MTableEditField } from "@material-table/core";
import Plus from "@mui/icons-material/AddBoxRounded";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Trash from "@mui/icons-material/DeleteRounded";
import Edit from "@mui/icons-material/Edit";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { USER_ROLE_PERMISSIONS } from "libs/constants/permissions";
import {
  useRoleAssignmentsDelete,
  useRoleAssignmentUpdate,
} from "libs/data/customized/roles";
import { useDeploymentsList } from "libs/data/endpoints/deployments/deployments";
import { useRoleAssignmentsPerObjectList } from "libs/data/endpoints/roles/roles";
import { explanations } from "libs/utilities/explanations";
import {
  fileAccessRoleOptions,
  fileAccessRoles,
} from "libs/utilities/labels-mapping";
import { routes } from "routes";
import { useGetPermissions } from "store/features/permissions";

import {
  Card,
  InfoAlert,
  PrimaryButton,
  TableLink,
  InfoTooltip,
  CardHeader,
  CardHeaderTitle,
  DialogWarningHeader,
  Dialog,
} from "components/atoms";
import { BaseTable } from "components/molecules";

import { AddDeploymentPermissionDialog } from "./dialogs/AddDeploymentPermissionDialog";
import { bucketNameIsDefault } from "../../utils";

import type { BaseColumn, BaseRow } from "components/molecules/BaseTable";
import type { RoleAssignmentList } from "libs/data/models";

export const Permissions = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [rowToDelete, setRowToDelete] =
    useState<RoleAssignmentList | null>(null);
  const [editRowId, setEditRowId] = useState<string | undefined>(undefined);
  const [editedRow, setEditedRow] = useState<BaseRow | undefined>(undefined);

  const { projectName, bucketName, organizationName } =
    useParams<{
      organizationName: string;
      projectName: string;
      bucketName: string;
    }>();

  const roleAssignmentsDelete = useRoleAssignmentsDelete(
    projectName,
    undefined,
    bucketName,
    "bucket"
  );

  const updateRoleAssignment = useRoleAssignmentUpdate(
    projectName,
    bucketName,
    "bucket"
  );

  const [permissions] = useGetPermissions();

  const { data: bucketRoles } = useRoleAssignmentsPerObjectList(projectName, {
    resource: bucketName,
    resource_type: "bucket",
  });
  const { data: deployments } = useDeploymentsList(projectName);

  const availableDeployments = useMemo(() => {
    const existingDeploymentNames = bucketRoles
      ?.filter((r) => r.assignee_type === "deployment")
      .map((r) => r.assignee);

    return (deployments ?? [])
      .filter((d) => !existingDeploymentNames?.includes(d.name))
      .sort((a, b) => (a.name > b.name ? 1 : -1));
  }, [bucketRoles, deployments]);

  const getDeploymentUrl = useCallback(
    (deployment: string) =>
      routes.organizations[":organizationName"](organizationName)
        .projects[":projectName"](projectName)
        .deployments[":deploymentName"](deployment)
        .general.index(),
    [organizationName, projectName]
  );

  const handleSave = useCallback(async () => {
    const data = editedRow as RoleAssignmentList;
    await updateRoleAssignment(editedRow?.id, {
      assignee: data?.assignee,
      assignee_type: data?.assignee_type,
      role: data?.role,
      resource: data?.resource,
      resource_type: data?.resource_type,
    });
    setEditRowId(undefined);
    setEditedRow(undefined);
  }, [editedRow, updateRoleAssignment]);

  const columns = useMemo(
    () => [
      {
        title: "Deployment",
        field: "assignee",
        defaultSort: "asc",
        width: "30%",
        nowrap: true,
        render: (rowData: { assignee: string }) =>
          editRowId ? (
            rowData.assignee
          ) : (
            <TableLink to={getDeploymentUrl(rowData.assignee)}>
              {rowData.assignee}
            </TableLink>
          ),
      },
      {
        title: "Access roles",
        field: "role",
        nowrap: true,
        editable: true,
        options: fileAccessRoleOptions,
        lookup: fileAccessRoleOptions,
        render: (rowData: { role: string }) => {
          return fileAccessRoles[rowData.role as keyof typeof fileAccessRoles];
        },
        editComponent: (props: any) => {
          return <MTableEditField {...props} variant="standard" />;
        },
      },
      {
        width: "4%",
        field: "",
        nowrap: true,
        render: (rowData: RoleAssignmentList) => {
          return (
            <Grid
              className="actions_container"
              alignItems="flex-end"
              paddingLeft={editRowId === rowData?.id ? spacing[12] : undefined}
            >
              <Tooltip title={editRowId === rowData?.id ? "Save" : "Edit"}>
                <span>
                  {editRowId === rowData?.id ? (
                    <IconButton
                      color="inherit"
                      onClick={() => {
                        handleSave();
                      }}
                    >
                      <CheckIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      color="secondary"
                      onClick={() => {
                        setEditRowId(rowData?.id);
                        setEditedRow(rowData);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  )}
                </span>
              </Tooltip>
              <Tooltip
                style={{
                  paddingLeft:
                    editRowId === rowData?.id ? spacing[4] : undefined,
                }}
                title={editRowId === rowData?.id ? "Cancel" : "Delete"}
              >
                <span>
                  {editRowId === rowData?.id ? (
                    <IconButton
                      color="inherit"
                      onClick={() => {
                        setEditRowId(undefined);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setRowToDelete(rowData);
                      }}
                    >
                      <Trash />
                    </IconButton>
                  )}
                </span>
              </Tooltip>
            </Grid>
          );
        },
      },
    ],
    [editRowId, getDeploymentUrl, handleSave]
  );

  const handleDelete = async () => {
    await roleAssignmentsDelete(rowToDelete?.id);
    setRowToDelete(null);
  };

  return (
    <>
      <Card>
        <Box marginBottom={2}>
          <CardHeader
            header={
              <>
                <CardHeaderTitle variant="h2">Permissions</CardHeaderTitle>
                <InfoTooltip>
                  {explanations.storage.deploymentPermissions}
                </InfoTooltip>
                <Box display="flex" marginLeft="auto">
                  <PrimaryButton
                    startIcon={<Plus />}
                    disabled={
                      !permissions[USER_ROLE_PERMISSIONS.assignment_create] ||
                      bucketNameIsDefault(bucketName)
                    }
                    onClick={() => setDialogOpen(true)}
                  >
                    Add Deployment
                  </PrimaryButton>
                </Box>
              </>
            }
          />
        </Box>
        {bucketNameIsDefault(bucketName) && (
          <InfoAlert>{explanations.storage.bucketPermissions}</InfoAlert>
        )}
        <BaseTable
          columns={columns as unknown as BaseColumn[]}
          data={bucketRoles?.filter(
            ({ assignee_type }) => assignee_type === "deployment"
          )}
          editRowId={editRowId}
          editedRow={editedRow}
          setEditedRow={setEditedRow}
        />
      </Card>
      <AddDeploymentPermissionDialog
        availableDeployments={availableDeployments}
        bucketName={bucketName}
        onClose={() => setDialogOpen(false)}
        open={dialogOpen}
        projectName={projectName}
      />
      <Dialog
        Actions={<PrimaryButton onClick={handleDelete}>Remove</PrimaryButton>}
        Header={<DialogWarningHeader title="Warning" />}
        open={!!rowToDelete}
        onClose={() => setRowToDelete(null)}
      >
        <Typography textAlign="center">
          Are you certain you want to revoke this deployment&apos;s access to
          bucket <b>{bucketName}</b>?
        </Typography>
      </Dialog>
    </>
  );
};
