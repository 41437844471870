import Close from "@mui/icons-material/Close";
import ReloadIcon from "@mui/icons-material/ReplayRounded";
import {
  IconButton,
  ThemeProvider,
  StyledEngineProvider,
  Typography,
  Button,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useForm } from "react-hook-form";
import { useRouteMatch } from "react-router-dom";

import { criticalButtonTheme } from "assets/styles/theme/critical-button";
import { PageHeader } from "components/molecules/PageLayout";
import { LINK_PRIVACY } from "libs/constants/documentation-links";
import {
  FIELD_NAME,
  FIELD_SURNAME,
  FIELD_EMAIL,
  FIELD_NEWSLETTER,
  FIELD_PHONE,
} from "libs/constants/fields";
import {
  COOKIE_NAMES,
  allCookies,
  requiredCookies,
  useCustomCookies,
} from "libs/cookies";
import { useUserUpdate } from "libs/data/customized/user";
import { useUserGet } from "libs/data/endpoints/user/user";
import { useGoogleAnalytics } from "libs/hooks";
import { getTzAwareDate } from "libs/utilities/date-util";
import { getChanges } from "libs/utilities/patch-helper";
import { LOADED } from "libs/utilities/request-statuses";
import validators from "libs/utilities/validators";

import {
  FormTextField,
  Divider,
  Checkbox,
  Notes,
  ExternalLink,
  Dialog,
  SecondaryButton,
} from "components/atoms";
import { FormSection, PageContainer } from "components/molecules";
import { FormContainer } from "components/organisms";

import { useDeleteUser } from "../../../libs/data/customized/user/useDeleteUser";
import { useSignOut } from "../../../libs/hooks/useSignOut";

const AccountDetails = () => {
  useGoogleAnalytics();
  const { data: user, mutate } = useUserGet();

  const updateUser = useUserUpdate();
  const deleteUser = useDeleteUser();
  const signOut = useSignOut();

  const match = useRouteMatch();

  const [cookies, setCookie, removeCookie] = useCustomCookies();

  const [defaultCookiesPreference] = useState(
    !cookies[COOKIE_NAMES.COOKIE_CONSENT]?.marketing
  );

  const formMethods = useForm();
  const deleteFormMethods = useForm();

  const [dialogOpen, setDialogOpen] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const onRowDelete = () => {
    setDialogOpen(true);
  };

  const onDelete = async () => {
    await deleteUser();
    signOut();
    setDialogOpen(false);
  };

  const onSubmit = async (data: Record<string, string>) => {
    if (!user) return;
    const updatedUser = {
      ...data,
    };
    delete updatedUser[COOKIE_NAMES.COOKIES_PREFERENCE];
    const changes = getChanges(
      updatedUser,
      user as unknown as Record<string, string>
    );
    if (Object.keys(changes).length) {
      await updateUser(updatedUser);
      mutate();
    }

    const expires = new Date(getTzAwareDate().add(1, "years").toDate());

    const allCookiesEnabled = cookies[COOKIE_NAMES.COOKIE_CONSENT]?.marketing;

    // remove all marketing cookies
    if (data[COOKIE_NAMES.COOKIES_PREFERENCE]) {
      Object.keys(cookies).forEach((cookie) => {
        if (
          ![COOKIE_NAMES.COOKIE_CONSENT, COOKIE_NAMES.STATE_KEY].includes(
            cookie
          )
        ) {
          removeCookie(cookie);
          removeCookie(cookie, { path: "/", domain: ".ubiops.dev" });
          removeCookie(cookie, { path: "/", domain: ".ubiops.com" });
        }
      });
    }

    setCookie(
      COOKIE_NAMES.COOKIE_CONSENT,
      data[COOKIE_NAMES.COOKIES_PREFERENCE] ? requiredCookies : allCookies,
      {
        expires,
      }
    );

    if (data[COOKIE_NAMES.COOKIES_PREFERENCE] === allCookiesEnabled) {
      enqueueSnackbar("Your cookies preference has been saved.", {
        variant: "success",
        persist: Boolean(data[COOKIE_NAMES.COOKIES_PREFERENCE]),
        action: (key) => (
          <>
            <Button
              color="inherit"
              variant="outlined"
              size="small"
              onClick={() => window.location.reload()}
              startIcon={<ReloadIcon fontSize="small" />}
            >
              Reload to apply
            </Button>
            <IconButton
              onClick={() => closeSnackbar(key)}
              size="small"
              color="inherit"
            >
              <Close fontSize="small" />
            </IconButton>
          </>
        ),
      });
    }
  };

  return (
    <PageContainer>
      <PageHeader title="Account" />
      <BreadcrumbsItem to={match.url}>Account</BreadcrumbsItem>
      <FormContainer
        onSubmit={onSubmit}
        buttonLabel="Save"
        formMethods={formMethods}
        status={LOADED}
      >
        {user && (
          <FormSection title="Main settings">
            {user.authentication === "ubiops" && (
              <FormTextField
                id={FIELD_EMAIL}
                name={FIELD_EMAIL}
                label="Email"
                rules={{
                  required: validators.required.message(FIELD_EMAIL),
                  pattern: {
                    value: validators.email.pattern,
                    message: validators.email.message,
                  },
                }}
                defaultValue={user.email}
                autoCapitalize="none"
              />
            )}
            <FormTextField
              id={FIELD_NAME}
              name={FIELD_NAME}
              label="First name"
              rules={{
                required: validators.required.message(FIELD_NAME),
              }}
              defaultValue={user.name}
            />
            <FormTextField
              id={FIELD_SURNAME}
              name={FIELD_SURNAME}
              label="Last name"
              rules={{
                required: validators.required.message(FIELD_SURNAME),
              }}
              defaultValue={user.surname}
            />
            <FormTextField
              id={FIELD_PHONE}
              name={FIELD_PHONE}
              label="Phone number"
              rules={{
                pattern: {
                  value: validators.phone.pattern,
                  message: validators.phone.message,
                },
              }}
              defaultValue={user.phone}
            />
            <Checkbox
              name={FIELD_NEWSLETTER}
              color="primary"
              defaultChecked={user.newsletter}
              label={
                <Typography variant="body2">
                  Keep me posted on UbiOps product updates, news and special
                  offers.
                </Typography>
              }
            />
            <Checkbox
              name={COOKIE_NAMES.COOKIES_PREFERENCE}
              color="primary"
              defaultChecked={defaultCookiesPreference}
              label={
                <Typography variant="body2">
                  Decline the use of cookies.
                </Typography>
              }
            />
            <Notes component="div" ml={4} mt={-3}>
              <p>
                To find out more about the cookies we use, see our{" "}
                <ExternalLink href={LINK_PRIVACY}>Privacy Policy</ExternalLink>.
              </p>
              <p>
                If you decline, a single cookie will be used in your browser to
                remember your preference not to be tracked.
              </p>
            </Notes>
          </FormSection>
        )}
      </FormContainer>
      <Divider my={3} />
      <FormContainer
        onSubmit={onRowDelete}
        buttonLabel="Delete"
        formMethods={deleteFormMethods}
        status={LOADED}
        critical
      >
        <FormSection title="Delete account" critical={true}>
          {`Deleting an account will permanently erase the user from the file
          system. You won't be able to restore it.`}
        </FormSection>
      </FormContainer>
      <Dialog
        onClose={() => setDialogOpen(false)}
        open={dialogOpen}
        title="Warning"
        maxWidth="xs"
        Actions={
          <>
            <SecondaryButton
              onClick={() => setDialogOpen(false)}
              style={{ marginRight: 14 }}
            >
              Cancel
            </SecondaryButton>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={criticalButtonTheme}>
                <SecondaryButton onClick={onDelete}>Delete</SecondaryButton>
              </ThemeProvider>
            </StyledEngineProvider>
          </>
        }
      >
        Are you sure you want to delete your account permanently?
      </Dialog>
    </PageContainer>
  );
};

export default AccountDetails;
