import { useTheme } from "@mui/styles";

export const OperatorNodeConditionalLogic = () => {
  const theme = useTheme();

  return (
    <svg
      width="85"
      height="76"
      viewBox="0 0 85 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4316_25975)">
        <path
          d="M42.6422 76.2844C63.4314 76.2844 80.2844 59.4314 80.2844 38.6422C80.2844 17.853 63.4314 1 42.6422 1C21.853 1 5 17.853 5 38.6422C5 59.4314 21.853 76.2844 42.6422 76.2844Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M39.6601 46.1212H43.5734V49.755H39.6601V46.1212ZM42.269 25.5298C49.2477 25.7963 52.287 32.3371 48.1389 37.2427C47.0562 38.4539 45.3083 39.2533 44.4474 40.2708C43.5734 41.2762 43.5734 42.4874 43.5734 43.6987H39.6601C39.6601 41.6759 39.6601 39.968 40.5341 38.7567C41.395 37.5455 43.1429 36.8308 44.2256 36.0314C47.3824 33.3182 46.5997 29.4785 42.269 29.1636C41.2311 29.1636 40.2357 29.5464 39.5019 30.2279C38.768 30.9093 38.3557 31.8336 38.3557 32.7973H34.4424C34.4424 30.8699 35.267 29.0213 36.7347 27.6584C38.2025 26.2955 40.1932 25.5298 42.269 25.5298Z"
          fill="white"
        />
        <path
          d="M80 42.5C82.4853 42.5 84.5 40.4853 84.5 38C84.5 35.5147 82.4853 33.5 80 33.5C77.5147 33.5 75.5 35.5147 75.5 38C75.5 40.4853 77.5147 42.5 80 42.5Z"
          fill={theme.palette.primary.main}
          stroke="white"
        />
        <path
          d="M5 42.5C7.48528 42.5 9.5 40.4853 9.5 38C9.5 35.5147 7.48528 33.5 5 33.5C2.51472 33.5 0.5 35.5147 0.5 38C0.5 40.4853 2.51472 42.5 5 42.5Z"
          fill={theme.palette.primary.main}
          stroke="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4316_25975">
          <rect width="85" height="76" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
