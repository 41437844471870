import Plus from "@mui/icons-material/AddBoxRounded";
import { Typography, Box, Icon, useTheme } from "@mui/material";
import React, { useContext, useState } from "react";
import { Link as LinkIcon } from "react-feather";
import { useHistory } from "react-router-dom";

import { BaseUrlContext } from "libs/contexts";
import { useDeviceDelete, useDeviceUpdate } from "libs/data/customized/devices";
import { useDevicesList, useUserGet } from "libs/data/endpoints/user/user";
import { formatLabel } from "libs/utilities/utils";

import { StatusIcon, Loader, InfoAlert } from "components/atoms";
import { AdvancedTable } from "components/molecules";

const columns = [
  {
    title: "Status",
    field: "confirmed",
    editable: "never",
    width: "250px",
    render: (rowData) => (
      <>
        <StatusIcon
          label={!rowData?.confirmed ? "not confirmed" : "confirmed"}
          status={!rowData?.confirmed ? "failed" : "available"}
        />
      </>
    ),
    size: "small",
  },
  {
    title: "Name",
    field: "name",
    defaultSort: "asc",
  },
];

const DevicesOverview = () => {
  const [loading, setLoading] = useState(false);
  const baseUrl = useContext(BaseUrlContext);
  const history = useHistory();
  const { data: user } = useUserGet();
  const theme = useTheme();
  const { data: devices, error } = useDevicesList();
  const isLoadingDevices = !devices && !error;

  const deleteDevice = useDeviceDelete(true);
  const updateDevice = useDeviceUpdate();

  const onRowUpdate = async (oldDevice, newDevice) => {
    if (oldDevice.name !== newDevice.name) {
      setLoading(true);
      await updateDevice(oldDevice.name, { name: newDevice.name });
      setLoading(false);
    }
  };

  const onRowDelete = async (item) => {
    setLoading(true);
    await deleteDevice(item.name);
    setLoading(false);
  };

  return (
    <Box px={3}>
      <Typography variant="h3" gutterBottom>
        Two-Factor authentication
      </Typography>
      {user?.authentication === "ubiops" ? (
        isLoadingDevices ? (
          <Loader />
        ) : (
          <AdvancedTable
            columns={columns}
            isLoading={loading}
            editable={{
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  onRowDelete(oldData);
                  resolve();
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  onRowUpdate(oldData, newData);
                  resolve();
                }),
            }}
            data={devices}
            actions={[
              {
                icon: () => <Icon component={Plus} />,
                tooltip: "Add device",
                isFreeAction: true,
                onClick: () => {
                  history.push(`${baseUrl}/2fa/create`);
                },
              },
              (rowData) => ({
                icon: () => <LinkIcon color={theme.palette.primary.light} />,
                tooltip: "Confirm device",
                onClick: (_, rowData) => {
                  history.push("2fa/create", rowData.name);
                },
                hidden: rowData?.confirmed,
              }),
            ]}
            deletionText={(row) =>
              `Are you sure you want to delete device "${row?.name}"?`
            }
            options={{
              search: false,
              paging: false,
            }}
          />
        )
      ) : (
        <InfoAlert>
          Your account is managed by {formatLabel(user?.authentication)}. Go to
          your {formatLabel(user?.authentication)} account settings to change
          your settings.
        </InfoAlert>
      )}
    </Box>
  );
};

export default DevicesOverview;
