import { useState, useEffect } from "react";

import type { MonitoringObject } from "./AddObjectDialog";

const useAddObjects = (key = "session-added-objects", initialValue = []) => {
  const [selectedObjects, setSelectedObjects] = useState<MonitoringObject[]>(
    () => {
      const storedValue = sessionStorage.getItem(key);

      return storedValue ? JSON.parse(storedValue) : initialValue;
    }
  );

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(selectedObjects));
  }, [key, selectedObjects]);

  return { selectedObjects, setSelectedObjects };
};

export default useAddObjects;
