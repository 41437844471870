import { useTheme } from "@mui/styles";

export const OperatorNodeCreateSubRequests = () => {
  const theme = useTheme();

  return (
    <svg
      width="85"
      height="80"
      viewBox="5 0 85 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4316_25938)">
        <path
          d="M45.7478 79.4956C67.6999 79.4956 85.4956 61.6999 85.4956 39.7478C85.4956 17.7957 67.6999 0 45.7478 0C23.7957 0 6 17.7957 6 39.7478C6 61.6999 23.7957 79.4956 45.7478 79.4956Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M65.4106 33H40.5566V47.6864H65.4106"
          stroke="white"
          strokeWidth="3"
        />
        <path d="M48.5 33V48.2513" stroke="white" strokeWidth="3" />
        <path d="M56.5 33V48.2513" stroke="white" strokeWidth="3" />
        <path
          d="M36.1746 41.3796C36.6042 40.95 36.6042 40.2536 36.1746 39.824L29.1743 32.8236C28.7447 32.3941 28.0482 32.3941 27.6186 32.8236C27.1891 33.2532 27.1891 33.9497 27.6186 34.3793L33.8412 40.6018L27.6186 46.8243C27.1891 47.2539 27.1891 47.9504 27.6186 48.38C28.0482 48.8096 28.7447 48.8096 29.1743 48.38L36.1746 41.3796ZM24 41.7018H35.3968V39.5018H24V41.7018Z"
          fill="white"
        />
        <g filter="url(#filter0_d_4316_25938)">
          <path
            d="M85 44.5C87.4853 44.5 89.5 42.4853 89.5 40C89.5 37.5147 87.4853 35.5 85 35.5C82.5147 35.5 80.5 37.5147 80.5 40C80.5 42.4853 82.5147 44.5 85 44.5Z"
            fill={theme.palette.primary.main}
          />
          <path
            d="M85 44.5C87.4853 44.5 89.5 42.4853 89.5 40C89.5 37.5147 87.4853 35.5 85 35.5C82.5147 35.5 80.5 37.5147 80.5 40C80.5 42.4853 82.5147 44.5 85 44.5Z"
            stroke="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_4316_25938"
          x="76"
          y="35"
          width="18"
          height="18"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4316_25938"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4316_25938"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_4316_25938">
          <rect width="90" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
