import { Box, Typography } from "@mui/material";

import { spacing } from "assets/styles/theme";

import type { ReactNode } from "react";

export type BaseTableTitleProps = {
  title?: string;
  header?: ReactNode;
};

export const BaseTableTitle = ({ title, header }: BaseTableTitleProps) => {
  return (
    <Box paddingY={spacing[16]}>
      {header ? header : <Typography variant="h3">{title}</Typography>}
    </Box>
  );
};
