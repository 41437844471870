import { Button, CircularProgress, Box, Grid } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";

import { useGoogleAnalytics } from "libs/hooks";

import { AuthenticationLink, Divider } from "components/atoms";
import { PasswordConfirmation } from "components/molecules";
import { AuthenticationContainer } from "components/organisms";

import { usePasswordResetToken } from "../../../libs/data/customized/user/usePasswordResetToken";

const PasswordResetToken = () => {
  useGoogleAnalytics();
  const { resetPasswordToken, isLoading } = usePasswordResetToken();

  const { token } = useParams<{ token: string }>();

  const methods = useForm({
    mode: "onChange",
  });
  const { handleSubmit } = methods;

  const handleResetPassword = ({ new_password }: { new_password: string }) => {
    resetPasswordToken(new_password, token);
  };

  return (
    <AuthenticationContainer title="Changing your password">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleResetPassword)}>
          <Grid container direction="column">
            <PasswordConfirmation />
            <Box mt={2}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress color="secondary" size={20} />
                ) : (
                  "Reset password"
                )}
              </Button>
            </Box>
            <Divider my={3} />
            <Box textAlign="center">
              <Box component="span">Already have login and password? </Box>
              <AuthenticationLink to="/sign-in">Sign in.</AuthenticationLink>
            </Box>
          </Grid>
        </form>
      </FormProvider>
    </AuthenticationContainer>
  );
};

export default PasswordResetToken;
