import { useTheme } from "@mui/styles";

export const EnvironmentRequirementsFileIllustration = () => {
  const theme = useTheme();

  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9923 5.00833L9.44425 0.983333C9.17246 0.675 8.79782 0.5 8.40848 0.5H1.6723C0.864255 0.5 0.203125 1.25 0.203125 2.16667V13.8333C0.203125 14.75 0.864255 15.5 1.6723 15.5H11.9565C12.7646 15.5 13.4257 14.75 13.4257 13.8333V6.19167C13.4257 5.75 13.2715 5.325 12.9923 5.00833ZM3.14148 3.83333H8.2836V5.5H3.14148V3.83333ZM10.4874 12.1667H3.14148V10.5H10.4874V12.1667ZM10.4874 8.83333H3.14148V7.16667H10.4874V8.83333Z"
        fill={theme.palette.text.primary}
      />
    </svg>
  );
};
