/* eslint-disable react/prop-types */
import styled from "@emotion/styled";
import MaterialTable, {
  MTableActions,
  MTableEditRow,
  MTableAction,
  MTableToolbar,
  MTableBody,
} from "@material-table/core";
import Plus from "@mui/icons-material/AddBoxRounded";
import Trash from "@mui/icons-material/DeleteRounded";
import Edit from "@mui/icons-material/EditRounded";
import { Paper, Box, Typography, useTheme } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { set, findIndex } from "lodash";
import PropTypes from "prop-types";
import React, { useState, useMemo } from "react";

import { borders } from "assets/styles/theme";
import { TRAINING_DEPLOYMENT } from "pages/organizations/:organizationName/projects/:projectName/training/constants";

import { MaterialIcon, OverflowTooltip, PrimaryButton } from "components/atoms";

import { EmptyTable } from "./EmptyTable";

import "./AdvancedTable.scss";

const styles = () => ({
  paginationRoot: {
    borderBottom: 0,
  },
});

const AdvancedTable = ({
  columns,
  data,
  options,
  components,
  icons,
  localization,
  deletionText,
  editable,
  onRowsPerPageChange,
  ...props
}) => {
  const { pageSize, rowStyle, ...otherOptions } = options || {};
  const [currPageSize, setCurrPageSize] = useState(pageSize || 20);
  const theme = useTheme();
  const tableIcons = useMemo(
    () => ({
      Add: () => <Plus />,
      Delete: ({ disabled }) => (
        <MaterialIcon
          component={Trash}
          color="primary"
          disabled={disabled}
          sx={{ color: `${theme.palette.table.deleteIcon} !important` }}
        />
      ),
      Edit: ({ disabled }) => (
        <MaterialIcon
          component={Edit}
          disabled={disabled}
          htmlColor={theme.palette.secondary.main}
          hoverColor={theme.palette.secondary.main}
        />
      ),
      ...icons,
    }),
    [icons, theme]
  );

  const tableOptions = useMemo(
    () => ({
      actionsColumnIndex: -1,
      emptyRowsWhenPaging: false,
      showFirstLastPageButtons: false,
      pageSize: currPageSize,
      headerStyle: {
        borderBottom: borders.secondary,
      },
      toolbarButtonAlignment: "left",
      addRowPosition: "first",
      cellStyle: {
        fontSize: "0.95em",
      },
      overflowY: "hidden",
      draggable: false,
      loadingType: "linear",
      rowStyle: (rowData) => {
        return {
          backgroundColor:
            rowData.name === TRAINING_DEPLOYMENT
              ? theme.palette.neutrals[100]
              : "",
          color:
            rowData.name === TRAINING_DEPLOYMENT
              ? theme.palette.neutrals[400]
              : "",
          height: 50,
          ...rowStyle,
        };
      },
      ...otherOptions,
    }),
    [otherOptions, currPageSize, rowStyle, theme]
  );

  const tableComponents = useMemo(
    () => ({
      Actions: ({ actions, ...actionsProps }) => {
        if (actions) {
          // If there is a delete button, move it to the end of the array of actions
          const deleteIndex = findIndex(actions, (action) =>
            typeof action === "function"
              ? action().tooltip === "Delete"
              : action?.action &&
                typeof action.action === "function" &&
                action.action().name === "delete"
          );
          if (deleteIndex !== -1) {
            actions.push(actions.splice(deleteIndex, 1)[0]);
          }

          // Now render everything
          return <MTableActions actions={actions} {...actionsProps} />;
        }

        return null;
      },
      Action: ({ action, ...componentsProps }) => {
        const {
          icon: Icon,
          position,
          tooltip,
          hidden,
          disabled,
          variant,
          isFreeAction,
          ...actionProps
        } = action;
        if (!hidden && position === "toolbar") {
          return (
            <Box mr={2} component="span">
              <PrimaryButton
                startIcon={<Icon color={theme.palette.primary.contrastText} />}
                variant={variant || "contained"}
                disabled={disabled || (!isFreeAction && !editable?.isCreatable)}
                {...actionProps}
              >
                {tooltip}
              </PrimaryButton>
            </Box>
          );
        }

        return <MTableAction action={action} {...componentsProps} />;
      },
      Container: (props) => <Paper {...props} elevation={0} />,
      EditRow: (props) => {
        if (deletionText) {
          set(props, "localization.deleteText", deletionText(props.data));
        }

        return <MTableEditRow {...props} sx={{ height: 50 }} />;
      },
      Body: (props) => {
        return props.data?.length === 0 && !props.showAddRow ? (
          <EmptyTable />
        ) : (
          <MTableBody m={5} {...props} />
        );
      },
      Toolbar: (props) => (
        <S_Toolbar
          {...props}
          backgroundColor={theme.palette.table.searchIcon}
        />
      ),
      ...components,
    }),
    [components, deletionText, editable, theme]
  );

  const tableLocalization = useMemo(
    () => ({
      body: {
        editRow: {
          saveTooltip: "Confirm",
        },
      },
      header: {
        actions: "",
      },
      ...localization,
    }),
    [localization]
  );

  const formattedColumns = columns.map((column) =>
    column?.nowrap
      ? {
          ...column,
          render: (rowData) => {
            // If the cell is a date, use existing render function to format date
            const cell =
              column?.type === "datetime"
                ? column.render(rowData)
                : rowData[column.field];

            return (
              <OverflowTooltip
                title={
                  <Typography
                    variant="h5"
                    className="advancedtable__overflow-tooltip__title"
                  >
                    {cell}
                  </Typography>
                }
              >
                {column?.render
                  ? column.render(rowData)
                  : rowData[column.field]}
              </OverflowTooltip>
            );
          },
        }
      : column
  );

  return (
    <MaterialTable
      title=""
      columns={formattedColumns}
      data={data}
      icons={tableIcons}
      options={tableOptions}
      components={tableComponents}
      localization={tableLocalization}
      editable={editable}
      onRowsPerPageChange={(pageSize) => {
        if (onRowsPerPageChange) {
          onRowsPerPageChange(pageSize);
        }

        setCurrPageSize(pageSize);
      }}
      onPageChange={() => {}}
      sx={{
        "& a": {
          color: theme.palette.text.primary,
        },
        ":&hover": { backgroundColor: theme.palette.neutrals[100] },
        "& svg[data-testid=chevron_right]": {
          color: theme.palette.text.primary,
        },
      }}
      {...props}
    />
  );
};

const S_Toolbar = styled(MTableToolbar)`
  &.MuiToolbar-root.MuiToolbar-gutters {
    & svg[aria-label="Search"] {
      color: ${(props) => props.backgroundColor}
  }
`;

AdvancedTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  options: PropTypes.object,
  components: PropTypes.object,
  icons: PropTypes.object,
  localization: PropTypes.object,
  deletionText: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
};

AdvancedTable.defaultProps = {
  data: undefined,
};

export default withStyles(styles)(AdvancedTable);
