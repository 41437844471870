import { useCallback } from "react";
import { useParams } from "react-router-dom";

import {
  FIELD_VERSION,
  FIELD_DESCRIPTION,
  FIELD_MONITORING_GROUP,
  FIELD_DEFAULT_MONITORING_GROUP,
  FIELD_RETENTION_MODE,
  FIELD_RETENTION_TIME,
  FIELD_MONITORING_GROUP_ENABLED,
  FIELD_DEFAULT_MONITORING_GROUP_ENABLED,
  FIELD_LABELS,
} from "libs/constants/fields";
import { usePipelineVersionCreate } from "libs/data/customized/pipeline";
import { usePipelineVersionsGet } from "libs/data/endpoints/pipelines/pipelines";

import type { PipelineVersionRouteParams } from "./types";
import type { PipelineVersionCreate } from "libs/data/models";
import type { PipelineVersionBaseProps } from "pages/organizations/:organizationName/projects/:projectName/pipelines/:pipelineName/versions/PipelineVersionBaseForm/constants";

export function useCreatePipelineVersion() {
  const { projectName, pipelineName, versionName } =
    useParams<PipelineVersionRouteParams>();
  const { data: pipelineVersion } = usePipelineVersionsGet(
    projectName,
    pipelineName,
    versionName
  );
  const createPipelineVersion = usePipelineVersionCreate(
    projectName,
    pipelineName
  );

  return useCallback(
    (data: PipelineVersionBaseProps) => {
      const retentionTime =
        data[FIELD_RETENTION_MODE] !== "none"
          ? data[FIELD_RETENTION_TIME]
          : undefined;
      const monitoringGroup = data[FIELD_MONITORING_GROUP_ENABLED]
        ? data[FIELD_MONITORING_GROUP]
        : null;
      const defaultMonitoringGroup = data[
        FIELD_DEFAULT_MONITORING_GROUP_ENABLED
      ]
        ? data[FIELD_DEFAULT_MONITORING_GROUP]
        : null;

      const payload = {
        ...pipelineVersion,
        id: null,
        creationDate: null,
        [FIELD_VERSION]: data[FIELD_VERSION],
        [FIELD_DESCRIPTION]: data[FIELD_DESCRIPTION],
        [FIELD_RETENTION_MODE]: data[FIELD_RETENTION_MODE],
        [FIELD_LABELS]: data[FIELD_LABELS],
        [FIELD_MONITORING_GROUP]: monitoringGroup,
        [FIELD_RETENTION_TIME]: retentionTime,
        [FIELD_DEFAULT_MONITORING_GROUP]: defaultMonitoringGroup,
      };

      return createPipelineVersion(payload as unknown as PipelineVersionCreate);
    },
    [createPipelineVersion, pipelineVersion]
  );
}
