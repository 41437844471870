import { MTableEditField } from "@material-table/core";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import Copy from "@mui/icons-material/FileCopyRounded";
import { Grid, Typography, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Lock } from "react-feather";
import { useForm, useFormContext, useWatch } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { AutoCompleteSelectHookForm } from "components/atoms/UncontrolledAutoComplete/AutoCompleteSelectHookForm";
import {
  FIELD_SOURCE_DEPLOYMENT,
  FIELD_SOURCE_VERSION,
} from "libs/constants/fields";
import {
  deploymentEnvironmentVariablesCopy,
  deploymentEnvironmentVariablesList,
  deploymentVersionEnvironmentVariablesCopy,
  deploymentVersionEnvironmentVariablesList,
  useDeploymentEnvironmentVariablesList,
  useDeploymentsList,
  useDeploymentVersionEnvironmentVariablesList,
  useDeploymentVersionsList,
} from "libs/data/endpoints/deployments/deployments";
import { getTzAwareDate } from "libs/utilities/date-util";
import { secretValues } from "libs/utilities/labels-mapping";
import { createErrorNotification } from "libs/utilities/notifications";
import validators from "libs/utilities/validators";

import {
  Icon,
  WarningText,
  Divider,
  Loader,
  PrimaryButton,
} from "components/atoms";
import { BaseTable } from "components/molecules";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type { AxiosError } from "axios";
import type { AutocompleteSelectOption } from "components/atoms/AutoCompleteSelect";
import type { BaseColumn } from "components/molecules/BaseTable";
import type {
  DeploymentList,
  InheritedEnvironmentVariableList,
  EnvironmentVariableCopy,
} from "libs/data/models";

type EnvVarsCopyProps = {
  isPage: boolean;
  onCopySuccess: () => void;
  deploymentName: string;
  deploymentVersionName?: string;
  onCopy?: (copiedEnvVars: InheritedEnvironmentVariableList[]) => void;
};

export const EnvVarsCopy = ({
  isPage,
  deploymentName,
  deploymentVersionName,
  onCopySuccess,
  onCopy,
}: EnvVarsCopyProps) => {
  const { projectName } = useParams<{ projectName: string }>();
  const dispatch = useDispatch();
  const formMethods = useForm();
  const formMethodsContext = useFormContext();
  const methods = isPage ? formMethods : formMethodsContext;
  const { control, getValues, setValue } = methods;
  const theme = useTheme() as AppThemeProps;
  const [variables, setVariables] = useState<
    InheritedEnvironmentVariableList[]
  >([]);

  const selectedDeployment: AutocompleteSelectOption | undefined = useWatch({
    control,
    name: FIELD_SOURCE_DEPLOYMENT,
  });
  const selectedDeploymentVersion: AutocompleteSelectOption | null | undefined =
    useWatch({ control, name: FIELD_SOURCE_VERSION });

  const { data: deployments } = useDeploymentsList(projectName);
  const { data: versions } = useDeploymentVersionsList(
    projectName,
    String(selectedDeployment?.value ?? "")
  );
  const sortedDeployments: DeploymentList[] = useMemo(() => {
    if (deployments) {
      return deployments.sort((a, b) =>
        getTzAwareDate(b?.last_updated || "").isAfter(
          getTzAwareDate(a?.last_updated || "")
        )
          ? 1
          : -1
      );
    } else return [];
  }, [deployments]);

  const currentDeployment = (selectedDeployment?.value ||
    sortedDeployments[0]?.name ||
    "") as string;

  const { data: deploymentVariables, error: deploymentVariablesError } =
    useDeploymentEnvironmentVariablesList(projectName, currentDeployment);

  const {
    data: deploymentVersionVariables,
    error: deploymentVersionVariablesError,
  } = useDeploymentVersionEnvironmentVariablesList(
    projectName,
    currentDeployment,
    (selectedDeploymentVersion?.value as string) ?? ""
  );
  const isLoadingVariables =
    !deploymentVariables &&
    !deploymentVariablesError &&
    !deploymentVersionVariables &&
    !deploymentVersionVariablesError;

  useEffect(() => {
    setValue(FIELD_SOURCE_VERSION, null);
  }, [setValue, selectedDeployment]);

  useEffect(() => {
    setVariables(
      (deploymentVersionVariables || deploymentVariables || []).filter(
        (v) => !v.inheritance_type
      )
    );
  }, [deploymentVariables, deploymentVersionVariables]);

  const deploymentOptions = useMemo(
    () =>
      sortedDeployments.map(({ name }) => ({
        value: name,
        label: name,
      })),
    [sortedDeployments]
  );

  const versionOptions = useMemo(
    () =>
      (versions || []).map(({ version }) => ({
        value: version,
        label: version,
      })),
    [versions]
  );

  const onCopyEnvVars = async () => {
    const values = getValues();
    const request: EnvironmentVariableCopy = {
      [FIELD_SOURCE_DEPLOYMENT]: values[FIELD_SOURCE_DEPLOYMENT]?.value ?? "",
    };

    if (values[FIELD_SOURCE_VERSION]?.value) {
      request[FIELD_SOURCE_VERSION] = values[FIELD_SOURCE_VERSION].value;
    }
    if (onCopy !== undefined) {
      onCopy(
        request[FIELD_SOURCE_VERSION]
          ? await deploymentVersionEnvironmentVariablesList(
              projectName,
              request[FIELD_SOURCE_DEPLOYMENT] ?? "",
              request[FIELD_SOURCE_VERSION] ?? ""
            )
          : await deploymentEnvironmentVariablesList(
              projectName,
              request[FIELD_SOURCE_DEPLOYMENT] ?? ""
            )
      );
      onCopySuccess();
    } else {
      try {
        if (deploymentVersionName) {
          await deploymentVersionEnvironmentVariablesCopy(
            projectName,
            deploymentName,
            deploymentVersionName,
            request
          );
        } else {
          await deploymentEnvironmentVariablesCopy(
            projectName,
            deploymentName,
            request
          );
        }
        onCopySuccess();
      } catch (e) {
        dispatch(createErrorNotification((e as AxiosError)?.message));
      }
    }
  };

  return (
    <Grid container direction="column" wrap="nowrap" spacing={2}>
      <Grid item>
        <AutoCompleteSelectHookForm
          name={FIELD_SOURCE_DEPLOYMENT}
          label="Source deployment"
          options={deploymentOptions ?? []}
          defaultValue={deploymentOptions[0]}
        />
      </Grid>
      <Grid item>
        <AutoCompleteSelectHookForm
          name={FIELD_SOURCE_VERSION}
          label="Source version (optional)"
          options={versionOptions ?? []}
        />
      </Grid>
      <Divider my={2} />
      {isLoadingVariables ? (
        <Loader />
      ) : variables.length ? (
        <Grid container spacing={2} flexDirection="column">
          <Grid item>
            <Typography variant="subtitle1">
              The following variables will be copied.
            </Typography>
          </Grid>
          <Grid item>
            <BaseTable
              columns={columns(theme.palette.error.main) as BaseColumn[]}
              data={variables}
              hasPagination={false}
              hasSearchBar={false}
            />
          </Grid>
          <Grid item>
            <WarningText>
              All variables with the same name will be overwritten by this
              action.
            </WarningText>
          </Grid>
        </Grid>
      ) : (
        <Grid item>
          <Typography variant="subtitle1" color="secondary">
            Selected deployment has no variables.
          </Typography>
        </Grid>
      )}
      <Grid item alignSelf="flex-end">
        <PrimaryButton
          onClick={onCopyEnvVars}
          startIcon={<Copy />}
          disabled={variables?.length === 0}
        >
          Copy
        </PrimaryButton>
      </Grid>
    </Grid>
  );
};

export const columns = (color: string) => [
  {
    title: "Name",
    field: "name",
    nowrap: true,
    editable: (rowData: InheritedEnvironmentVariableList) =>
      !rowData?.inheritance_type,
    validate: (rowData: InheritedEnvironmentVariableList) => {
      if (!validators.envVarName.pattern.test(rowData.name)) {
        return {
          helperText: (
            <WarningText
              component="span"
              variant="h3"
              color={color}
              icon={ErrorOutline}
            >
              {validators.envVarName.message}
            </WarningText>
          ),
        };
      }

      return true;
    },
  },
  {
    title: "Value",
    field: "value",
    nowrap: true,
    editable: true,
    render: (rowData: InheritedEnvironmentVariableList) => (
      <>
        {rowData.secret ? (
          <Icon component={Lock} status="none" />
        ) : (
          <Typography className="env-vars-overview__value">
            {rowData.value}
          </Typography>
        )}
      </>
    ),
  },
  {
    title: "Secret",
    field: "secret",
    editComponent: (props: any) => {
      return <MTableEditField {...props} variant="standard" />;
    },
    lookup: secretValues,
    editable: (rowData: InheritedEnvironmentVariableList) => !rowData?.secret,
    initialEditValue: "false",
    render: (rowData: InheritedEnvironmentVariableList) =>
      secretValues?.[rowData?.secret ? "true" : "false"],
  },
];
