import { Grid } from "@mui/material";
import { Formik } from "formik";
import { isObject, isString } from "lodash";
import { useMemo, useState } from "react";
import * as Yup from "yup";

import {
  FIELD_CONFIGURATION,
  FIELD_CREDENTIALS,
  FIELD_DESCRIPTION,
  FIELD_ENDPOINT_URL,
  FIELD_LABELS,
  FIELD_NAME,
  FIELD_PROVIDER,
  FIELD_REGION,
  FIELD_SIGNATURE_VERSION,
  FIELD_TTL,
} from "libs/constants/fields";
import { BucketDetailProvider } from "libs/data/models";
import { explanations } from "libs/utilities/explanations";
import {
  bucketAutoDeleteValues,
  externalBucketTypes,
} from "libs/utilities/labels-mapping";
import validators from "libs/utilities/validators";
import { PROVIDERS } from "pages/organizations/:organizationName/projects/:projectName/storage/constants";

import { PrimaryButton } from "components/atoms";
import {
  FormikSelect,
  ControlledLabelsInput,
  ControlledRadioGroup,
  FormikTextInput,
  FormSection,
} from "components/molecules";
import type { LabelsDict } from "components/molecules";

import { ConfigurationField } from "./ConfigurationField";
import { CredentialsField } from "./CredentialsField";
import { ProviderSelect } from "./ProviderSelect";
import { BUCKET_OPTIONS, BucketBaseValues, PROVIDER } from "./constants";

import type { BucketBaseProps } from "./constants";
import type { FormikProps, FormikValues, FormikHelpers } from "formik";

interface BucketBaseFormProps {
  editing?: boolean;
  initialValues?: Partial<BucketBaseProps>;
  onSubmit: (
    values: BucketBaseProps,
    helpers: FormikHelpers<BucketBaseProps>
  ) => void;
}

export const BucketBaseForm = ({
  editing = false,
  initialValues = {},
  onSubmit,
}: BucketBaseFormProps) => {
  const [type, setType] = useState(
    editing && initialValues?.provider !== BucketDetailProvider.ubiops
      ? PROVIDER.externalCloud
      : PROVIDER.ubiops
  );
  const mergedInitialValues: BucketBaseProps = useMemo(() => {
    return {
      ...BucketBaseValues,
      ...initialValues,
    } as BucketBaseProps;
  }, [initialValues]);

  const handleOnSubmit = (
    _values: BucketBaseProps,
    actions: FormikHelpers<BucketBaseProps>
  ) => {
    // Note: We don't want to edit the underlying object
    const values = { ..._values };

    // Note: In case the configuration was edited with the json editor it should be parsed
    if (values[FIELD_CONFIGURATION] && isString(values[FIELD_CONFIGURATION])) {
      values[FIELD_CONFIGURATION] = JSON.parse(values[FIELD_CONFIGURATION]);
    }

    // Note: The API provides us with null values but does not accept it back
    const fixableValues = [
      FIELD_ENDPOINT_URL,
      FIELD_REGION,
      FIELD_SIGNATURE_VERSION,
    ];

    fixableValues.forEach((value: string) => {
      if (values[FIELD_CONFIGURATION]?.[value] === null) {
        delete values[FIELD_CONFIGURATION][value];
      }
    });

    return onSubmit(values, actions);
  };

  const validate = (values: BucketBaseProps) => {
    const errors = {} as { [key: string]: string | object };
    const configs = values[FIELD_CONFIGURATION];
    const credentials = values[FIELD_CREDENTIALS];
    if (isObject(configs)) {
      Object.entries(configs).map(([key, value]) => {
        if (!value) {
          errors[FIELD_CONFIGURATION] = {
            ...(errors[FIELD_CONFIGURATION] as object),
            [key]: "This is a required field",
          };
        }
      });
    }
    if (!editing && isObject(credentials)) {
      Object.entries(credentials).map(([key, value]) => {
        if (!value) {
          errors[FIELD_CREDENTIALS] = {
            ...(errors[FIELD_CREDENTIALS] as object),
            [key]: "This is a required field",
          };
        }
      });
    }
    if (!editing && !values[FIELD_NAME]) {
      errors[FIELD_NAME] = "The name is required";
    }

    return errors;
  };

  return (
    <Formik
      initialValues={mergedInitialValues}
      onSubmit={handleOnSubmit}
      validateOnChange
      validate={validate}
    >
      {(control: FormikProps<FormikValues>) => {
        const { submitForm, values, setFieldValue, errors } = control;
        const handleChangeType = (_type: string) => {
          if (_type === PROVIDERS.ubiops) {
            control.setFieldValue(FIELD_PROVIDER, PROVIDERS.ubiops);
          } else {
            control.setFieldValue(FIELD_TTL, undefined);
            control.unregisterField(FIELD_TTL);
          }
          setType(_type);
        };

        const shouldShowCloudDetails =
          type === PROVIDER.externalCloud &&
          control.values[FIELD_PROVIDER] &&
          control.values[FIELD_PROVIDER] !== PROVIDER.ubiops;

        return (
          <>
            {!editing && (
              <FormSection
                description={explanations.storage.defaultProvider}
                title="Create bucket"
              >
                <ControlledRadioGroup
                  onChange={(_type) => handleChangeType(_type as string)}
                  options={BUCKET_OPTIONS}
                  value={type}
                />
              </FormSection>
            )}
            {type !== PROVIDER.ubiops && !editing && (
              <FormSection
                title="Bucket type"
                description={explanations.storage.provider}
              >
                <ProviderSelect control={control} />
              </FormSection>
            )}
            {shouldShowCloudDetails && (
              <>
                <FormSection
                  title="Credentials"
                  description={explanations.storage.credentials(
                    externalBucketTypes.find(
                      (provider) =>
                        provider.value === control.values[FIELD_PROVIDER]
                    )?.label ?? ""
                  )}
                >
                  <CredentialsField
                    control={control}
                    placeholder="***************"
                    editing={editing}
                  />
                </FormSection>
                <FormSection
                  title="Configuration"
                  description={explanations.storage.configuration}
                >
                  <ConfigurationField control={control} />
                </FormSection>
              </>
            )}
            <FormSection
              description={!editing && explanations.storage.bucket_general}
              title="General"
            >
              {!editing && (
                <FormikTextInput
                  control={control}
                  label="Name"
                  name={FIELD_NAME}
                  placeholder="Ex: My-first-bucket"
                  validation={Yup.string()
                    .required(validators.required.message(FIELD_NAME))
                    .matches(
                      validators.name.pattern,
                      validators.name.message(FIELD_NAME)
                    )
                    .test(
                      "reserved_name",
                      validators.name.reservedNameYup("bucket")
                    )}
                  required
                />
              )}
              <FormikTextInput
                control={control}
                label="Description"
                name={FIELD_DESCRIPTION}
                placeholder="My first bucket test"
                minRows={3}
                multiline
              />
              {type === PROVIDERS.ubiops && (
                <FormikSelect
                  control={control}
                  label="Automatically delete files"
                  name={FIELD_TTL}
                  options={bucketAutoDeleteValues}
                />
              )}
            </FormSection>
            <FormSection
              title="Version labels"
              description={explanations.labels.description("environments")}
            >
              <ControlledLabelsInput
                onChange={async (value: LabelsDict) => {
                  await setFieldValue(FIELD_LABELS, value);
                  await control.setFieldTouched(FIELD_LABELS, true);
                }}
                value={values[FIELD_LABELS]}
              />
            </FormSection>
            <Grid item container spacing={5}>
              <Grid item xs={12} sm={5} />

              <Grid item xs={12} sm={7}>
                <PrimaryButton
                  loading={control.isSubmitting}
                  onClick={submitForm}
                  disabled={!!Object.keys(errors).length}
                >
                  {editing ? "Save" : "Create"}
                </PrimaryButton>
              </Grid>
            </Grid>
          </>
        );
      }}
    </Formik>
  );
};
