import { createContext, useContext, useState } from "react";

import type { FC, Dispatch, SetStateAction } from "react";

const ZoomContext = createContext<[boolean, Dispatch<SetStateAction<boolean>>]>(
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  [true, () => {}]
);

export const useZoomContext = () => useContext(ZoomContext);

export const ZoomContextProvider: FC = ({ children }) => {
  const [enabled, setEnabled] = useState(true);

  return (
    <ZoomContext.Provider value={[enabled, setEnabled]}>
      {children}
    </ZoomContext.Provider>
  );
};
