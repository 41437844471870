import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  FormHelperText,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { get } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";

import { useAccordionStyles } from "assets/styles/componentStyles";
import { spacing } from "assets/styles/theme";
import { AutoCompleteSelectHookForm } from "components/atoms/UncontrolledAutoComplete/AutoCompleteSelectHookForm";
import { BOOLEAN_TYPE, FILE_TYPE } from "libs/constants/constants";
import { useBucketsList } from "libs/data/endpoints/files/files";
import { isArrayHasData } from "libs/utilities/utils";
import { widgetsMapping } from "libs/utilities/widgets-helpers";

import { Checkbox, FormTextField, InfoTooltip } from "components/atoms";
import { FormSection } from "components/molecules";

const useCustomStyles = makeStyles({
  paddingBottom: {
    paddingBottom: "20px!important",
  },
});

const FieldWidgetSelector = ({ fieldName, dataType, widget, prefix }) => {
  const classes = useAccordionStyles();
  const customClasses = useCustomStyles();

  const { errors, clearErrors, setValue, control } = useFormContext();
  const error = get(errors, `${prefix}Widgets.${fieldName}.configuration`);

  const [configurations, setConfigurations] = useState([]);
  const [configurationOpen, setConfigurationOpen] = useState(false);

  const { projectName } = useParams();
  const { data: buckets } = useBucketsList(projectName);

  const bucketOptions = useMemo(
    () =>
      buckets?.map((bucket) => ({ label: bucket.name, value: bucket.name })) ||
      [],
    [buckets]
  );

  const defaultType = useMemo(() => {
    const existing = widgetsMapping[prefix][dataType]?.find(
      (item) => item.name === widget?.type
    );
    if (existing) {
      return { label: existing.label, value: existing.name };
    }

    return null;
  }, [dataType, prefix, widget]);

  const bucket = useWatch({
    name: `${prefix}Widgets.${fieldName}.configuration.bucket`,
    control,
    defaultValue: widget?.configuration?.bucket ?? bucketOptions?.[0]?.value,
  });

  useEffect(() => {
    let configs = widgetsMapping[prefix][dataType]?.find(
      (item) => item.name === defaultType?.value
    )?.configuration;
    setConfigurations(configs || []);

    return () => {
      setConfigurations([]);
    };
  }, [dataType, prefix, defaultType]);

  const onTypeChange = (selectedType) => {
    clearErrors(`${prefix}Widgets.${fieldName}.configuration`);
    if (selectedType) {
      let configs = widgetsMapping[prefix][dataType].find(
        (item) => item.name === selectedType.value
      )?.configuration;
      setConfigurations(configs || []);
      if (configs) {
        setConfigurationOpen(true);
      }
    } else {
      setConfigurations([]);
    }
  };

  const onBucketChange = (bucket) => {
    setValue(`${prefix}Widgets.${fieldName}.configuration.bucket`, bucket);
  };

  const getDefaultFieldValue = (value, name, fieldName, defaultValue) => {
    if (Array.isArray(value)) {
      return JSON.stringify(value);
    }

    return value !== undefined && value !== null
      ? value
      : name === "label"
      ? fieldName
      : defaultValue;
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <FormSection
          title={fieldName}
          description={
            <Typography variant="body2">
              Data type: <b>{dataType}</b>
            </Typography>
          }
        >
          <AutoCompleteSelectHookForm
            name={`${prefix}Widgets.${fieldName}.type`}
            options={widgetsMapping[prefix][dataType]?.map(
              ({ name, label }) => ({
                label,
                value: name,
              })
            )}
            defaultValue={defaultType}
            placeholder="Select..."
            onChange={onTypeChange}
            isClearable
          />
          {isArrayHasData(configurations) && (
            <Accordion
              className={classes.accordionWrapper}
              expanded={configurationOpen}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                classes={{ content: classes.accSummaryContent }}
                onClick={() => setConfigurationOpen(!configurationOpen)}
              >
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <Typography variant="h5" color="primary">
                      Settings
                    </Typography>
                  </Grid>
                  <Grid item>
                    <FormHelperText error={!!error} hidden={!error}>
                      Something is wrong with one of the settings
                    </FormHelperText>
                  </Grid>
                </Grid>
              </AccordionSummary>
              {configurations.map(
                (
                  { label, name, type, rules, defaultValue, data_type, help },
                  key
                ) =>
                  data_type === BOOLEAN_TYPE ? (
                    <Checkbox
                      key={key}
                      name={`${prefix}Widgets.${fieldName}.configuration.${name}`}
                      color="primary"
                      label={label}
                      defaultChecked={!!widget?.configuration?.[name]}
                    />
                  ) : prefix === "input" && data_type === FILE_TYPE ? (
                    <Box
                      display="flex"
                      marginY={spacing[8]}
                      alignItems="stretch"
                    >
                      <AutoCompleteSelectHookForm
                        name={`${prefix}Widgets.${fieldName}.configuration.bucket`}
                        label={label}
                        options={bucketOptions}
                        disabled={!bucketOptions?.length}
                        value={bucket}
                        onChange={(option) => onBucketChange(option.value)}
                      />
                      <Grid item xs={1} component={Box} paddingTop={2}>
                        <InfoTooltip>{help}</InfoTooltip>
                      </Grid>
                    </Box>
                  ) : (
                    <Box display="flex" flexDirection="column">
                      <FormTextField
                        key={key}
                        label={label}
                        name={`${prefix}Widgets.${fieldName}.configuration.${name}`}
                        type={type}
                        rules={rules}
                        defaultValue={getDefaultFieldValue(
                          widget?.configuration?.[name],
                          name,
                          fieldName,
                          defaultValue
                        )}
                        className={
                          key + 1 === configurations.length
                            ? customClasses.paddingBottom
                            : ""
                        }
                        tooltip={help}
                      />
                    </Box>
                  )
              )}
            </Accordion>
          )}
        </FormSection>
      </Grid>
    </React.Fragment>
  );
};

FieldWidgetSelector.propTypes = {
  prefix: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  dataType: PropTypes.string.isRequired,
  widget: PropTypes.object,
};

export default FieldWidgetSelector;
