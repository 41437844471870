import { BaseTable } from "components/molecules";

import type { BaseColumn } from "components/molecules/BaseTable";

const columns = [
  {
    width: "10px",
  },
  {
    title: "Contact type",
    field: "type",
    nowrap: true,
    width: "20%",
  },
  {
    title: "Contact information",
    field: "email",
    width: "80%",
  },
];

type NotificationGroupDetailsProps = {
  notificationGroup: { type: string; email: string }[];
};

const NotificationGroupDetails = ({
  notificationGroup,
}: NotificationGroupDetailsProps) => {
  return (
    <BaseTable
      columns={columns as BaseColumn[]}
      data={notificationGroup}
      hasPagination={false}
      hasSearchBar={false}
    />
  );
};

export default NotificationGroupDetails;
