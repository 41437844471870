import * as Yup from "yup";

import {
  FIELD_DESCRIPTION,
  FIELD_LABELS,
  FIELD_RETENTION_TIME,
  FIELD_VERSION,
} from "libs/constants/fields";
import validators from "libs/utilities/validators";

import type { LabelsDict } from "components/molecules";

import type {
  FIELD_DEFAULT_MONITORING_GROUP,
  FIELD_DEFAULT_MONITORING_GROUP_ENABLED,
  FIELD_MONITORING_GROUP,
  FIELD_MONITORING_GROUP_ENABLED,
  FIELD_RETENTION_MODE,
} from "libs/constants/fields";

export interface PipelineVersionBaseProps {
  [FIELD_VERSION]?: string;
  [FIELD_DESCRIPTION]?: string;
  [FIELD_LABELS]?: LabelsDict;
  [FIELD_MONITORING_GROUP_ENABLED]?: boolean;
  [FIELD_DEFAULT_MONITORING_GROUP_ENABLED]?: boolean;
  [FIELD_RETENTION_MODE]?: string;
  [FIELD_RETENTION_TIME]?: string | number;
  [FIELD_MONITORING_GROUP]?: string;
  [FIELD_DEFAULT_MONITORING_GROUP]?: string;
}

export const pipelineVersionBaseValues: PipelineVersionBaseProps = {
  [FIELD_VERSION]: "",
  [FIELD_DESCRIPTION]: "",
  [FIELD_LABELS]: {},
};

export const PipelineVersionBaseSchema = Yup.object().shape({
  [FIELD_VERSION]: Yup.string()
    .required(validators.required.message(FIELD_VERSION))
    .matches(validators.name.pattern, validators.name.message(FIELD_VERSION)),
  [FIELD_DESCRIPTION]: Yup.string(),
  [FIELD_RETENTION_TIME]: Yup.number()
    .nullable()
    .typeError(validators.retention_type.message)
    .min(3600, validators.minimum_retention.message)
    .max(2419200, validators.maximum_retention.message),
});
