import { Link as MuiLink, useTheme } from "@mui/material";
import { forwardRef } from "react";

import NavLink from "../NavLink/NavLink";

import type { LinkProps as MuiLinkProps } from "@mui/material";
import type { Ref } from "react";
import type { NavLinkProps } from "react-router-dom";
import "./Link.scss";

interface LinkProps {
  to: string | Pick<NavLinkProps, "location">;
  className?: "CardHeader" | "SolidBlue";
}

export const Link = forwardRef(function CustomLink(
  {
    children,
    underline = "always",
    variant = "inherit",
    to,
    color,
    className,
    ...props
  }: LinkProps &
    Omit<MuiLinkProps, "innerRef" | "className"> &
    Omit<NavLinkProps, "innerRef">,
  ref: Ref<HTMLAnchorElement>
) {
  const theme = useTheme();
  const accentuateColor = color
    ? theme.palette.primary.main
    : theme.palette.secondary.main;

  return (
    // @ts-ignore
    <MuiLink
      component={NavLink}
      innerRef={ref}
      color={color || "secondary"}
      variant={variant}
      underline={underline}
      to={to}
      className={["Link", className].join("")}
      sx={{
        ":hover": { color: accentuateColor },
        ":visited": { color: accentuateColor },
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      }}
      {...props}
    >
      {children}
    </MuiLink>
  );
});
