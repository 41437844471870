import { getTzAwareDate } from "./date-util";

export const toUpperFirstCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const lastUpdated = (arr: { last_updated?: string; name: string }[]) => {
  return arr?.sort((a, b) =>
    getTzAwareDate(b?.last_updated || "").isAfter(
      getTzAwareDate(a?.last_updated || "")
    )
      ? 1
      : -1
  )?.[0].name;
};
