import ErrorOutline from "@mui/icons-material/ErrorOutline";
import GroupIcon from "@mui/icons-material/Group";
import PersonAdd from "@mui/icons-material/PersonAddRounded";
import { Link, useTheme } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useRouteMatch, useHistory, useParams } from "react-router-dom";

import { FIELD_NAME } from "libs/constants/fields";
import { NOTIFICATION_GROUPS_PERMISSIONS } from "libs/constants/permissions";
import { useNotificationGroupDelete } from "libs/data/customized/monitoring/useNotificationGroupDelete";
import { useNotificationGroupUpdate } from "libs/data/customized/monitoring/useNotificationGroupUpdate";
import { useOrganizationUser } from "libs/data/customized/user";
import { useNotificationGroupsList } from "libs/data/endpoints/monitoring/monitoring";
import { useGoogleAnalytics, useNotificationGroups } from "libs/hooks";
import validators from "libs/utilities/validators";
import { useGetPermissions } from "store/features/permissions";

import {
  IconButton,
  Loader,
  PrimaryButton,
  WarningText,
} from "components/atoms";
import { AdvancedTable } from "components/molecules";
import { NotificationGroupEditCreate } from "components/organisms";

import NotificationGroupDetails from "./NotificationGroupDetails";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type {
  NotificationGroupContact,
  NotificationGroupList,
  NotificationGroupUpdate,
} from "libs/data/models";

const MonitoringNotificationGroups = () => {
  useGoogleAnalytics();
  const theme = useTheme() as AppThemeProps;
  const match = useRouteMatch();
  const history = useHistory();
  const { organizationName, projectName } =
    useParams<{ organizationName: string; projectName: string }>();
  const { data, error, isAdmin } = useOrganizationUser(organizationName);
  const isLoadingUser = !error && !data;

  const [currentPermissions] = useGetPermissions();
  const { data: notificationGroups, error: listError } =
    useNotificationGroupsList(projectName);

  const deleteNotificationGroup = useNotificationGroupDelete(projectName);
  const updateNotificationGroup = useNotificationGroupUpdate(projectName);

  const isLoading = !notificationGroups && !listError;
  useEffect(() => {
    if (
      // isAdmin is false by default before the user is loaded so we need to check if the API call finished
      (data && !isAdmin) ||
      // This makes sure the permissions are actually loaded and are not undefined
      (currentPermissions[NOTIFICATION_GROUPS_PERMISSIONS.list] === false &&
        currentPermissions[NOTIFICATION_GROUPS_PERMISSIONS.get] === false)
    ) {
      history.push(
        `/organizations/${organizationName}/projects/${projectName}/monitoring`
      );
    }
  }, [
    currentPermissions,
    isAdmin,
    organizationName,
    projectName,
    history,
    data,
    error,
  ]);

  const { dialogProps: createDialogProps, toggleDialog: createDialogToggle } =
    useNotificationGroups({
      isUpdate: false,
      selectOrCreate: false,
    });
  const { dialogProps: updateDialogProps, toggleDialog: updateDialogToggle } =
    useNotificationGroups({
      isUpdate: true,
      selectOrCreate: false,
    });

  const onUpdateNotificationGroupName = async (
    oldNotificationGroup: NotificationGroupList,
    newNotificationGroup: NotificationGroupList
  ) => {
    const updatedNotificationGroup: NotificationGroupUpdate = {
      name: oldNotificationGroup.name,
    };
    if (oldNotificationGroup.name !== newNotificationGroup.name) {
      updatedNotificationGroup[FIELD_NAME] = newNotificationGroup.name;
    }

    try {
      await updateNotificationGroup(
        oldNotificationGroup.name,
        updatedNotificationGroup
      );
    } catch (err) {
      // throw err
    }
  };

  const [currentNotificationGroup, setCurrentNotificationGroup] =
    useState<undefined | NotificationGroupList>(undefined);

  const openUpdatedialog = useCallback(
    (notificationGroup) => {
      setCurrentNotificationGroup(notificationGroup);
      updateDialogToggle();
    },
    [setCurrentNotificationGroup, updateDialogToggle]
  );

  const columns = useMemo(
    () => [
      {
        title: "Groups",
        field: "name",
        defaultSort: "asc",
        nowrap: true,
        render: (rowData: NotificationGroupList) => (
          <Link variant="h5">{rowData.name}</Link>
        ),
        validate: (rowData: NotificationGroupList) => {
          if (!validators.name.pattern.test(rowData.name)) {
            return {
              helperText: (
                <WarningText
                  component="span"
                  variant="h3"
                  color={theme.palette.error.main}
                  icon={ErrorOutline}
                >
                  {validators.name.message("notification group name")}
                </WarningText>
              ),
            };
          } else {
            return validators.name.value("project")(rowData.name);
          }
        },
      },
      {
        disableClick: true,
        width: 70,
        render: (rowData: NotificationGroupList) => (
          <div className="actions_container">
            <IconButton
              tooltip="Add members"
              htmlColor="secondary"
              hoverColor="secondary"
              onClick={() => openUpdatedialog(rowData)}
              icon={PersonAdd}
            />
          </div>
        ),
      },
    ],
    [openUpdatedialog, theme]
  );

  return (
    <>
      <BreadcrumbsItem to={match.url}>Notification Groups</BreadcrumbsItem>
      {isLoading || isLoadingUser ? (
        <Loader />
      ) : (
        <>
          <AdvancedTable
            isLoading={false}
            columns={columns}
            data={notificationGroups}
            options={{ detailPanelType: "single" }}
            onRowClick={(
              _event: Event,
              _rowData: NotificationGroupList,
              togglePanel: () => void
            ) => togglePanel()}
            detailPanel={({ rowData }: { rowData: NotificationGroupList }) => {
              return (
                <NotificationGroupDetails
                  notificationGroup={convertRowToTableData(rowData.contacts)}
                />
              );
            }}
            editable={{
              onRowDelete: (group: NotificationGroupList) =>
                new Promise<void>((resolve) => {
                  deleteNotificationGroup(group.name);
                  resolve();
                }),
              onRowUpdate: (
                updated: NotificationGroupList,
                original: NotificationGroupList
              ) =>
                new Promise<void>((resolve, reject) => {
                  if (validators.name.pattern.test(updated.name)) {
                    onUpdateNotificationGroupName(original, updated);
                    resolve();
                  } else {
                    reject();
                  }
                }),
            }}
            localization={{
              body: {
                editRow: {
                  deleteText:
                    "Are you sure you want to remove this notification group from the project?",
                  saveTooltip: "Confirm",
                },
              },
            }}
            title={
              <PrimaryButton
                startIcon={<GroupIcon />}
                onClick={createDialogToggle}
              >
                Create notification group
              </PrimaryButton>
            }
          />
          <NotificationGroupEditCreate
            {...createDialogProps}
            selectedNotificationGroup={currentNotificationGroup}
          />
          <NotificationGroupEditCreate
            {...updateDialogProps}
            selectedNotificationGroup={currentNotificationGroup}
          />
        </>
      )}
    </>
  );
};

const convertRowToTableData = (data: NotificationGroupContact[]) =>
  data.map((contact) => ({
    type: contact.type,
    email: contact.configuration.email_address ?? "",
  }));

export default MonitoringNotificationGroups;
