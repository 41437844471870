import { Grid } from "@mui/material";

import type { FC } from "react";

import "./FormWrapper.scss";

interface FormWrapperProps {
  small?: boolean;
  fullWidth?: boolean;
}

export const FormWrapper: FC<FormWrapperProps> = ({
  small = false,
  fullWidth = false,
  children,
}) => (
  <Grid
    container
    className={
      small
        ? "form-wrapper--small"
        : fullWidth
        ? "form-wrapper--full"
        : "form-wrapper"
    }
    spacing={5}
  >
    {children}
  </Grid>
);
