import Edit from "@mui/icons-material/Edit";
import {
  Grid,
  Typography,
  CardContent,
  CardActions,
  Chip,
  Button,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useContext, useMemo } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { LogOut, LogIn } from "react-feather";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import { DEPLOYMENT_PERMISSIONS } from "libs/constants/permissions";
import { BaseUrlContext } from "libs/contexts";
import { usePermissionValidation } from "libs/data/customized/roles";
import { useDeploymentsGet } from "libs/data/endpoints/deployments/deployments";
import { useRoleAssignmentsPerObjectList } from "libs/data/endpoints/roles/roles";
import { useGoogleAnalytics } from "libs/hooks";
import { routes } from "routes";
import { useGetCurrentOrganization } from "store/features";

import { Card, Divider, Link, NoData } from "components/atoms";
import { InputCard } from "components/molecules";

import type {
  DeploymentDetailsRouteParams,
  InputOutputItem,
} from "pages/organizations/:organizationName/projects/:projectName/deployments/:deploymentName/types";

const useStyles = makeStyles({
  parentFlexRight: {
    display: "flex",
    justifyContent: "flex-end",
  },
});

export const DeploymentConfiguration = () => {
  useGoogleAnalytics();
  const classes = useStyles();
  const theme = useTheme();
  const baseUrl = useContext(BaseUrlContext);
  const match = useRouteMatch();
  const history = useHistory();
  const { organizationName, projectName, deploymentName } =
    useParams<DeploymentDetailsRouteParams>();

  const { data: deployment } = useDeploymentsGet(projectName, deploymentName);

  const organization = useGetCurrentOrganization();

  const [currentPermissions] = usePermissionValidation(
    projectName,
    Object.values(DEPLOYMENT_PERMISSIONS),
    deploymentName,
    "deployment"
  );
  const { data: deploymentPermissions } = useRoleAssignmentsPerObjectList(
    projectName,
    {
      assignee: deploymentName,
      assignee_type: "deployment",
    }
  );

  const items: InputOutputItem[] = useMemo(
    () => [
      {
        label: "Input",
        type: "input_type",
        fields: "input_fields",
        Icon: LogIn,
      },
      {
        label: "Output",
        type: "output_type",
        fields: "output_fields",
        Icon: LogOut,
      },
    ],
    []
  );

  const linkToBucket = (bucketName: string) => {
    return routes.organizations[":organizationName"](organizationName)
      .projects[":projectName"](projectName)
      .storage[":bucketName"](bucketName)
      .general.files.index();
  };

  return (
    <>
      <BreadcrumbsItem to={match.url}>Configurations</BreadcrumbsItem>
      {/* @ts-ignore */}
      <Card variant="outlined">
        <CardActions disableSpacing className={classes.parentFlexRight}>
          <Button
            color="primary"
            variant="contained"
            startIcon={
              <Edit sx={{ fill: theme.palette.primary.contrastText }} />
            }
            onClick={(e) => {
              e.preventDefault();
              history.push(`${baseUrl}/edit`);
            }}
            disabled={
              !currentPermissions[DEPLOYMENT_PERMISSIONS["update"]] ||
              organization?.status !== "active"
            }
          >
            Edit configuration
          </Button>
        </CardActions>
        <CardContent>
          {/* @ts-ignore */}
          <Grid container spacing={2} display="flex" flexdirection="column">
            <Grid item xs={6}>
              <Typography variant="h3">Input and Output</Typography>
              <Grid container spacing={2} style={{ marginTop: "10px" }}>
                {deployment &&
                  items.map((item, key) => (
                    <Grid key={key} item xs={12}>
                      <InputCard
                        // @ts-ignore
                        item={item}
                        type={deployment[item.type]}
                        // @ts-ignore
                        fields={deployment[item.fields]}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Grid>
            <Grid item>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item xs container>
              <Grid item xs={12}>
                <Typography variant="h3" gutterBottom>
                  Deployment permissions
                </Typography>

                <Divider mb={2} />
                {deploymentPermissions?.length ? (
                  <Grid container spacing={1}>
                    {deploymentPermissions?.map((item, index) => (
                      <Grid key={index} item>
                        <Chip
                          label={
                            <Typography variant="body2" component="span">
                              {item.role} | {item.resource_type}:{" "}
                              <Link
                                color="primary"
                                to={linkToBucket(item.resource as string)}
                              >
                                {item.resource}
                              </Link>
                            </Typography>
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Grid container display="flex" justifyContent="center">
                    <NoData text="No permissions configured" />
                  </Grid>
                )}
                <Divider mt={2} />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
