import Plus from "@mui/icons-material/AddBoxRounded";
import Flash from "@mui/icons-material/FlashOn";
import HomeIcon from "@mui/icons-material/Home";
import ContactIcon from "@mui/icons-material/QuestionAnswer";
import { Box, Grid } from "@mui/material";
import { isEmpty } from "lodash";
import React, { useEffect, useState, useContext } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useDispatch } from "react-redux";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import { IlluAddProject } from "assets/images/IlluAddProject";
import { borderRadius, borders, spacing } from "assets/styles/theme";
import { ItemsListCard } from "components/molecules/ItemsListCard";
import { PageHeader } from "components/molecules/PageLayout";
import {
  DOC_LINKS,
  LINK_USING_UBIOPS,
} from "libs/constants/documentation-links";
import { RootUrlContext } from "libs/contexts";
import { useOrganizationUser } from "libs/data/customized/user";
import { useDeviceDetect, useGoogleAnalytics } from "libs/hooks";
import { explanations } from "libs/utilities/explanations";
import { isArrayHasData } from "libs/utilities/utils";
import {
  setProject,
  useGetCurrentOrganization,
  useGetOrganizationFeatures,
  useGetProjects,
} from "store/features";
import { toggleTasksManager, useTaskManager } from "store/features/taskManager";

import { Card, ExternalLink, PrimaryButton } from "components/atoms";
import { Contact, StarterTutorial, PageContainer } from "components/molecules";
import { ExceededLimitsDialog } from "components/organisms";

import { ProjectBox } from "./ProjectBox";
import { AddNewProjectDialog } from "../overview/AddNewProjectDialog";

const OrganizationDashboard = () => {
  const { isMobile } = useDeviceDetect();
  useGoogleAnalytics();
  const [isExceededLimitsDialogOpen, setIsExceededLimitsDialogOpen] =
    useState(false);
  const [maxProjectsCreated, setMaxProjectsCreated] = useState(false);
  const rootUrl = useContext(RootUrlContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { organizationName } = useParams();
  const { isAdmin } = useOrganizationUser(organizationName);

  const { tasks } = useTaskManager();

  const projects = useGetProjects();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  const currentOrganization = useGetCurrentOrganization();
  const organizationFeatures = useGetOrganizationFeatures();

  const handleProjectCreate = () => {
    if (currentOrganization?.status === "active" && !maxProjectsCreated) {
      setCreateDialogOpen(true);
    } else if (maxProjectsCreated) {
      setIsExceededLimitsDialogOpen(true);
    }
  };

  useEffect(() => {
    if (organizationFeatures?.max_projects && isArrayHasData(projects)) {
      setMaxProjectsCreated(
        projects.length >= organizationFeatures?.max_projects
      );
    }
  }, [organizationFeatures, projects]);

  const onProjectClick = (project) => {
    dispatch(setProject(project));
    if (!isEmpty(tasks)) {
      toggleTasksManager();
    }
    history.push(`${rootUrl}/projects/${project.name}`);
  };

  return (
    <PageContainer
      style={{ height: "95%" }}
      contentStyle={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <PageHeader title="Organization dashboard" />
      <BreadcrumbsItem to={match.url}>Dashboard</BreadcrumbsItem>
      <Grid
        container
        spacing={2}
        sx={{
          maxHeight: "85%",
          display: "grid",
          gridTemplateColumns: isMobile ? "repeat(1, 1fr)" : "repeat(2, 1fr)",
          gridTemplateRows: isMobile ? "repeat(4, 1fr)" : "repeat(4, 1fr)",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{ gridArea: isMobile ? "1 / 1 / 2 / 2" : "1 / 1 / 4 / 3" }}
        >
          {projects?.length ? (
            <ItemsListCard
              title="Your projects"
              itemName="project"
              titleIcon={HomeIcon}
              items={projects}
              onCreateClick={handleProjectCreate}
              hasAddBox={isAdmin}
              maxHeight="unset"
              cardContentStyle={{
                height: "100%",
                maxHeight: "80vh",
                overflow: "scroll",
                paddingBottom: spacing[64],
              }}
            >
              {(project) => (
                <ProjectBox
                  item={project}
                  onClick={() => onProjectClick(project)}
                />
              )}
            </ItemsListCard>
          ) : (
            <Card title="Your projects" icon={HomeIcon}>
              <Box display={"flex"} alignItems={"flex-start"} gap={4}>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  border={borders.dashedLight}
                  borderRadius={borderRadius[8]}
                  padding={spacing[20]}
                  gap={2}
                  width={spacing[400]}
                >
                  <IlluAddProject />
                  <PrimaryButton
                    startIcon={<Plus />}
                    onClick={handleProjectCreate}
                  >
                    Create a project
                  </PrimaryButton>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"flex-start"}
                  width={"100%"}
                >
                  <Box marginBottom={spacing[12]}>
                    {explanations.projects.emptyProjects}
                  </Box>
                  <ExternalLink href={LINK_USING_UBIOPS}>
                    Deploying llama-v3 with a customizable front-end
                  </ExternalLink>
                  <ExternalLink href={DOC_LINKS.MISTRAL_RAG}>
                    Deploying mistral with RAG
                  </ExternalLink>
                  <ExternalLink href={DOC_LINKS.DEPLOY_TENSORFLOW}>
                    Deploying a TensorFlow model
                  </ExternalLink>
                </Box>
              </Box>
            </Card>
          )}
        </Grid>

        <Grid
          item
          style={{
            gridArea: isMobile ? "2 / 1 / 3 / 2" : "4 / 1 / 5 / 2",
            maxHeight: spacing[250],
          }}
        >
          <Card title="Tutorials & Examples" icon={Flash}>
            <StarterTutorial info />
          </Card>
        </Grid>
        <Grid
          item
          style={{
            gridArea: isMobile ? "3 / 1 / 4 / 2" : "4 / 2 / 5 / 3",
            maxHeight: spacing[250],
          }}
        >
          <Card title="Get in touch" icon={ContactIcon}>
            <Contact />
          </Card>
        </Grid>
      </Grid>

      <AddNewProjectDialog
        onClose={() => setCreateDialogOpen(false)}
        open={createDialogOpen}
      />
      <ExceededLimitsDialog
        open={isExceededLimitsDialogOpen}
        onClose={() => setIsExceededLimitsDialogOpen(false)}
        name="projects"
      />
    </PageContainer>
  );
};

export default OrganizationDashboard;
