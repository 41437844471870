import styled from "@emotion/styled";
import EditIcon from "@mui/icons-material/Edit";
import { useTheme } from "@mui/material";
import { useEffect } from "react";
import { getBezierPath, useNodes } from "reactflow";

import { borderRadius, borders, spacing } from "assets/styles/theme";
import { usePortal } from "libs/hooks";

import { EdgeDeleteButton } from "./EdgeDeleteButton";
import { EdgeSidebar } from "./EdgeSidebar";
import { useIsSidebarRightOpen, useSidebarRightState } from "../SidebarRight";
import {
  DIAGRAM_SIDEBAR_PARENT_ID,
  OBJECT_REFERENCE_TYPE_OPERATOR,
  OPERATOR_MANY_TO_ONE,
  OPERATOR_ONE_TO_MANY,
} from "../constants";
import { NodeTypes } from "../types";

import type { NodeDataType } from "../types";
import type { AppThemeProps } from "assets/styles/theme/theme";
import type { EdgeProps } from "reactflow";

export const EdgeCustom = (edge: EdgeProps) => {
  const {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    selected,
    source,
    target,
    data,
    markerEnd,
  } = edge;
  const theme = useTheme() as AppThemeProps;
  const nodes = useNodes<NodeDataType>();
  const sourceNode = nodes.find((node) => node.id === source);
  const targetNode = nodes.find((node) => node.id === target);
  const sourceNodeReferenceType =
    sourceNode?.data.pipelineObject.reference_type;
  const sourceNodeReferenceName =
    sourceNode?.data.pipelineObject.reference_name;
  const targetNodeReferenceType =
    targetNode?.data.pipelineObject.reference_type;
  const targetNodeReferenceName =
    targetNode?.data.pipelineObject.reference_name;

  const Portal = usePortal(document.getElementById(DIAGRAM_SIDEBAR_PARENT_ID));

  const isSubRequestsOperatorEdge =
    (targetNodeReferenceType === OBJECT_REFERENCE_TYPE_OPERATOR &&
      targetNodeReferenceName === OPERATOR_ONE_TO_MANY) ||
    (sourceNodeReferenceType === OBJECT_REFERENCE_TYPE_OPERATOR &&
      sourceNodeReferenceName === OPERATOR_MANY_TO_ONE);
  const isDiamondEdge = targetNode?.type === NodeTypes.diamond;
  const isSidebarOpen = useIsSidebarRightOpen();
  const [isInfoSidebarOpen, setIsInfoSidebarOpen] = useSidebarRightState(id);

  useEffect(() => {
    if (data.new) {
      setIsInfoSidebarOpen(true);
    }
  }, [data.new, id, setIsInfoSidebarOpen]);

  const [edgePath, edgeCenterX, edgeCenterY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <Portal>
        <EdgeSidebar
          id={id}
          sourceId={source}
          targetId={target}
          edgeData={data}
          isOpen={isInfoSidebarOpen}
          onClose={() => setIsInfoSidebarOpen(false)}
        />
      </Portal>

      <path
        id={id}
        d={edgePath}
        markerEnd={markerEnd}
        style={{ strokeWidth: 3, ...style }}
        className="react-flow__edge-path"
      />

      <foreignObject
        width={100}
        height={60}
        x={edgeCenterX - 100 / 2}
        y={edgeCenterY - 60 / 2}
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <div>
          {!!(selected || isInfoSidebarOpen) &&
            !(!isInfoSidebarOpen && !!isSidebarOpen) &&
            !isDiamondEdge && (
              <Wrapper backgroundColor={theme.palette.neutrals[100]}>
                <ActionButtonsContainer
                  color={theme.palette.primary.main}
                  hoverColor={theme.palette.secondary.main}
                >
                  {!isSubRequestsOperatorEdge && (
                    <EditIcon onClick={() => setIsInfoSidebarOpen(true)} />
                  )}
                  <EdgeDeleteButton id={id} />
                </ActionButtonsContainer>
              </Wrapper>
            )}
        </div>
      </foreignObject>
    </>
  );
};

const Wrapper = styled.div<{ backgroundColor: string }>`
  display: flex;
  border-radius: ${borderRadius[8]};
  background: ${(props) => props.backgroundColor};
  border: ${borders.tertiary};
  margin-bottom: 0.5rem;
  padding: ${spacing[6]};
  justify-content: space-between;
  cursor: pointer;
  position: absolute;
  z-index: 10000000;
  pointer-events: auto;
`;

const ActionButtonsContainer = styled.g<{ color: string; hoverColor: string }>`
  display: flex;

  .MuiSvgIcon-root {
    color: ${(props) => props.color};

    &:hover {
      color: ${(props) => props.hoverColor};
    }
  }
`;
