import { Box, useTheme } from "@mui/material";
import { Helmet } from "react-helmet";

import { PageTitle } from "components/atoms/Typography/PageTitle";
import { PageBreadcrumbs } from "components/molecules/PageLayout/Header/PageBreadcrumbs";

import { Divider } from "components/atoms";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type { ReactNode } from "react";

export interface HeaderProps {
  actions?: ReactNode;
  title: string | ReactNode;
  children?: ReactNode;
  pageTitle?: ReactNode;
}

export const PageHeader = ({
  actions,
  title,
  pageTitle,
  children,
}: HeaderProps) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <div className="page-title">
      <Helmet>
        <title>UbiOps | {title}</title>
      </Helmet>
      <PageBreadcrumbs />
      <Box
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box mr={3} p={1} flexShrink={1}>
          <PageTitle>{pageTitle ?? title}</PageTitle>
        </Box>
        {children}
        {!!actions && (
          <Box p={1} flexShrink={0} flexGrow={1} display="flex">
            {actions}
          </Box>
        )}
      </Box>
      <Divider mt={1} mb={3} color={theme.palette.border.divider} />
    </div>
  );
};
