export const LINK_COPYRIGHT = "https://ubiops.com/";
export const LINK_PRIVACY = "https://ubiops.com/privacy-policy/";
export const LINK_SAAS = "https://ubiops.com/terms/";
export const LINK_SAAS_FREE =
  "https://ubiops.com/saas-services-tc-ubiops-free/";

export const LINK_SUPPORT =
  "https://ubiops.atlassian.net/servicedesk/customer/portals";
export const LINK_CREATE_BUG =
  "https://ubiops.atlassian.net/servicedesk/customer/portal/2/group/3/create/14";
export const LINK_SUGGEST_IMPROVEMENT =
  "https://ubiops.atlassian.net/servicedesk/customer/portal/2/group/3/create/16";

export const LINK_PRICING = "https://ubiops.com/pricing-and-plans/";
export const LINK_SLACK_INVITE =
  "https://join.slack.com/t/ubiops-community/shared_invite/zt-np02blts-5xyFK0azBOuhJzdRSYwM_w";
export const LINK_YOUTUBE = "https://www.youtube.com/@ubiops";

export const LINK_USING_UBIOPS =
  "https://ubiops.com/deploy-llama3-with-ubiops/";

export const UBIOPS_GITHUB_LINKS = {
  IMPORT_TEMPLATE: "https://github.com/UbiOps/import-template",
  LINK_PYTHON_CLIENT_LIBRARY: "https://github.com/UbiOps/client-library-python",
  LINK_CLI: "https://github.com/UbiOps/command-line-interface",
};
export const UBIOPS_POLICY = "https://ubiops.com/acceptable-fair-use-policy/";
export const TUTORIALS =
  "https://www.youtube.com/channel/UCQBpeuKmGcRWptc2Ldumyrw/videos?app=desktop";
export const DOC_LINKS = {
  DOCS: "https://ubiops.com/docs/",
  DEPLOYMENTS: "https://ubiops.com/docs/deployments/",
  INPUT_OUTPUT_TYPE: "https://ubiops.com/docs/input-output/data-types",
  DEPLOYMENT_ZIP:
    "https://ubiops.com/docs/deployments/deployment-package/deployment-structure/",
  ENVIRONMENT_VARIABLES:
    "https://ubiops.com/docs/deployments/environment-variables/",
  ENVIRONMENTS: "https://ubiops.com/docs/environments/",
  REQUESTS: "https://ubiops.com/docs/data-connections/requests/",
  REQUESTS_SETTINGS: "https://ubiops.com/docs/requests/request-settings/",
  RETENTION_MODE:
    "https://ubiops.com/docs/requests/request-settings/#request-storage",
  STATIC_IP:
    "https://ubiops.com/docs/deployments/deployment-versions/#static-ip-addresses",
  PORT_FORWARDING:
    "https://ubiops.com/docs/deployments/deployment-versions/#opening-up-a-port-from-your-deployment-beta",
  SCALING: "https://ubiops.com/docs/scaling-resource-settings/",
  PIPELINES: "https://ubiops.com/docs/pipelines/",
  CONNECTIONS: "http://ubiops.com/docs/pipelines/#connecting-objects",
  CREATE_PIPELINE: "https://ubiops.com/docs/pipelines/#creating-a-pipeline",
  PIPELINE_VERSIONS: "https://ubiops.com/docs/pipelines/#pipeline-versions",
  OPERATORS: "https://ubiops.com/docs/pipelines/operators",
  REQUEST_SCHEDULES:
    "https://ubiops.com/docs/data-connections/request-schedules/",
  REQUEST_TIMEOUTS: "https://ubiops.com/docs/requests/#request-timeouts",
  REQUEST_EXAMPLES:
    "https://ubiops.com/docs/data-connections/requests/#request-examples",
  IMPORT_EXPORT: "https://ubiops.com/docs/import-export/",
  IMPORT: "https://ubiops.com/docs/import-export/#import",
  ORGANIZATIONS: "https://ubiops.com/docs/organizations/",
  PERMISSION_MANAGEMENT:
    "https://ubiops.com/docs/organizations/user-and-permission-management/",
  SERVICE_USERS: "https://ubiops.com/docs/organizations/service-users/",
  PROJECTS: "https://ubiops.com/docs/organizations/projects/",
  TROUBLESHOOTING: "https://ubiops.com/docs/troubleshooting",

  AUDITING: "https://ubiops.com/docs/auditing/",
  STARTER_TUTORIAL: "https://ubiops.com/docs/starter-tutorial/",
  TRAINING_TUTORIAL:
    "https://ubiops.com/docs/starter-tutorial/ui-starter-tutorial/ui-training-starter-tutorial/",
  TUTORIALS: "https://ubiops.com/docs/ubiops_tutorials/",
  STARTER_TUTORIAL_PIPELINE:
    "https://ubiops.com/docs/starter-tutorial/ui-starter-tutorial/ui-pipeline-starter-tutorial/",
  MULTIPLICATION_DEPLOYMENT:
    "https://ubiops.com/docs/ubiops_tutorials/ready-deployments/multiplication/multiplication#code",
  BUCKETS: "https://ubiops.com/docs/howto/buckets/",
  DEFAULT_BUCKET: "https://ubiops.com/docs/input-output/buckets/",
  BUCKET_PERMISSIONS:
    "https://ubiops.com/docs/input-output/files/#managing-bucket-permissions",
  BUCKET_FILES: "https://ubiops.com/docs/input-output/files/",
  BUCKET_ADVANCED_CONFIG:
    "https://ubiops.com/docs/input-output/#creating-buckets",
  DEPLOYMENT_PACKAGE_STRUCTURE:
    "https://ubiops.com/docs/deployments/deployment-package/deployment-structure/#deployment-package-structure",
  ERROR_POLICY: "https://ubiops.com/docs/pipelines/#on-error-policy",
  TRAINING: "https://ubiops.com/docs/training",
  TRAINING_RUNS: "https://ubiops.com/docs/training/#training-runs",
  CONTACT_US: "https://ubiops.com/contact-us",
  INSTANCE_TYPES:
    "https://ubiops.com/docs/scaling-resource-settings/#instance-type",
  INSTANCE_TYPE_GROUPS:
    "https://ubiops.com/docs/scaling-resource-settings/#instance-type-groups",
  CUSTOM_METRICS: "https://ubiops.com/docs/monitoring/metrics/#custom-metrics",
  MISTRAL_RAG:
    "https://ubiops.com/docs/ubiops_tutorials/rag-mistral-langchain/rag-mistral-langchain/",
  DEPLOY_TENSORFLOW:
    "https://ubiops.com/docs/ubiops_tutorials/tensorflow-example/tensorflow-example/",
  WEBHOOK_CONFIGURATION:
    "https://ubiops.com/docs/monitoring/webhooks/#configuring-a-webhook",
  WEBHOOK_HEADERS:
    "https://ubiops.com/docs/monitoring/webhooks/#headers-for-the-webhook-request",
  WEBHOOK_TEST: "https://ubiops.com/docs/monitoring/webhooks/#testing-webhooks",
};

export const HELP_LINKS = [
  {
    label: "Contact support",
    url: LINK_SUPPORT,
  },
  {
    label: "Report a bug",
    url: LINK_CREATE_BUG,
  },
  {
    label: "Suggest improvement",
    url: LINK_SUGGEST_IMPROVEMENT,
  },
];
