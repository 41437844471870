import { Box, Pagination } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useEffect, useState } from "react";

import { PipelineObjectRequestResultsDetails } from "components/organisms/RequestResultsDialog/PipelineObjectRequestResultsDetails";

import type { PipelineRequestResultsRecord } from "components/organisms/RequestResultsDialog/PipelineRequestResults";

const useStyles = makeStyles(() => ({
  ul: {
    "& .MuiPagination-ul": {
      justifyContent: "center",
    },
  },
}));

export const PaginatedPipelineObjectRequestResultsDetails = ({
  resultsRecord,
  projectName,
  organizationName,
  pipelineName,
  pipelineVersion,
}: {
  resultsRecord: PipelineRequestResultsRecord;
  projectName: string;
  organizationName: string;
  pipelineName: string;
  pipelineVersion: string;
}) => {
  const { subRequests } = resultsRecord;
  const classes = useStyles();
  const isGroupedRequest = !!subRequests?.length;
  const [page, setPage] = useState<number>(1);
  const [request, setRequest] = useState<any>(
    isGroupedRequest ? subRequests?.[0] : resultsRecord
  );

  useEffect(() => {
    if (!isGroupedRequest && resultsRecord.status !== request.status) {
      setRequest(resultsRecord);
    }
  }, [isGroupedRequest, resultsRecord, request.status]);

  const handleChange = (_: unknown, value: number) => {
    setPage(value);
    const nthRequest = subRequests?.[value - 1];
    if (nthRequest) setRequest(subRequests[value - 1]);
  };

  return (
    <Box>
      <PipelineObjectRequestResultsDetails
        projectName={projectName}
        organizationName={organizationName}
        request={request}
        pipelineName={pipelineName}
        pipelineVersion={pipelineVersion}
      />
      {isGroupedRequest && (
        <Pagination
          className={classes.ul}
          count={subRequests.length}
          size="small"
          color="secondary"
          page={page}
          onChange={handleChange}
          showFirstButton={subRequests.length > 50}
          showLastButton={subRequests.length > 50}
          defaultPage={1}
          style={{ padding: 10 }}
        />
      )}
    </Box>
  );
};
