export const IlluEvaluate = () => {
  return (
    <svg
      width="222"
      height="155"
      viewBox="0 0 222 155"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12218_29367)">
        <path
          d="M181.478 97.0805H39.1414C38.1698 97.0794 37.2382 96.6953 36.5512 96.0126C35.8641 95.3298 35.4777 94.404 35.4766 93.4384V6.21565C35.4774 5.50823 35.7605 4.83002 36.2638 4.32979C36.7671 3.82956 37.4496 3.54818 38.1614 3.54736H182.412C183.136 3.54818 183.83 3.83443 184.342 4.3433C184.854 4.85218 185.142 5.54213 185.143 6.26178V93.4384C185.142 94.404 184.756 95.3298 184.069 96.0126C183.382 96.6953 182.45 97.0794 181.478 97.0805Z"
          fill="#F2F2F2"
        />
        <path
          d="M176.286 91.9226H43.632C41.6875 91.9226 40.1055 90.4504 40.1055 88.6408V12.686C40.1055 11.344 41.2778 10.2521 42.7187 10.2521H177.156C178.621 10.2521 179.813 11.362 179.813 12.7261V88.6408C179.813 90.4504 178.231 91.9226 176.286 91.9226Z"
          fill="white"
        />
        <path
          d="M185.092 6.00729H35.4248V4.15306C35.4262 3.18425 35.8143 2.25555 36.5038 1.5707C37.1934 0.885859 38.1281 0.5008 39.103 0.5H181.414C182.389 0.500796 183.323 0.885853 184.013 1.5707C184.702 2.25554 185.09 3.18424 185.092 4.15306V6.00729Z"
          fill="#CCCCCC"
        />
        <path
          d="M42.1096 4.20457C42.635 4.20457 43.061 3.78126 43.061 3.25908C43.061 2.73691 42.635 2.3136 42.1096 2.3136C41.5842 2.3136 41.1582 2.73691 41.1582 3.25908C41.1582 3.78126 41.5842 4.20457 42.1096 4.20457Z"
          fill="white"
        />
        <path
          d="M45.7209 4.20457C46.2464 4.20457 46.6723 3.78126 46.6723 3.25908C46.6723 2.73691 46.2464 2.3136 45.7209 2.3136C45.1955 2.3136 44.7695 2.73691 44.7695 3.25908C44.7695 3.78126 45.1955 4.20457 45.7209 4.20457Z"
          fill="white"
        />
        <path
          d="M49.3323 4.20457C49.8577 4.20457 50.2836 3.78126 50.2836 3.25908C50.2836 2.73691 49.8577 2.3136 49.3323 2.3136C48.8068 2.3136 48.3809 2.73691 48.3809 3.25908C48.3809 3.78126 48.8068 4.20457 49.3323 4.20457Z"
          fill="white"
        />
        <path
          d="M161.684 79.1839H58.883C57.8525 79.1827 56.8645 78.7754 56.1358 78.0512C55.4072 77.327 54.9973 76.3452 54.9961 75.3211V26.0641C54.997 25.2981 55.3035 24.5638 55.8485 24.0222C56.3934 23.4806 57.1323 23.1759 57.9031 23.175H162.618C163.401 23.1759 164.152 23.4855 164.705 24.0357C165.259 24.586 165.57 25.332 165.571 26.1102V75.3211C165.57 76.3452 165.16 77.327 164.432 78.0512C163.703 78.7754 162.715 79.1827 161.684 79.1839ZM57.9031 23.6165C57.2501 23.6173 56.6241 23.8754 56.1624 24.3342C55.7007 24.7931 55.441 25.4152 55.4403 26.0641V75.3211C55.4413 76.2282 55.8044 77.0978 56.4498 77.7392C57.0952 78.3806 57.9703 78.7414 58.883 78.7424H161.684C162.597 78.7414 163.472 78.3806 164.118 77.7392C164.763 77.0978 165.126 76.2282 165.127 75.3211V26.1102C165.126 25.4491 164.862 24.8152 164.391 24.3477C163.921 23.8802 163.283 23.6173 162.618 23.6165H57.9031Z"
          fill="#CCCCCC"
        />
        <path
          d="M62.4376 75.321H158.131C159.074 75.321 159.978 74.9489 160.644 74.2866C161.311 73.6243 161.685 72.726 161.685 71.7893V30.6124C161.685 29.6757 161.311 28.7774 160.644 28.1151C159.978 27.4528 159.074 27.0807 158.131 27.0807H62.4376C61.495 27.0807 60.5911 27.4528 59.9247 28.1151C59.2582 28.7774 58.8838 29.6757 58.8838 30.6124V71.7893C58.8838 72.726 59.2582 73.6243 59.9247 74.2866C60.5911 74.9489 61.495 75.321 62.4376 75.321Z"
          fill="#F2F2F2"
        />
        <path
          d="M73.3027 39.0607C74.4067 39.0607 75.3017 38.1713 75.3017 37.0741C75.3017 35.9769 74.4067 35.0875 73.3027 35.0875C72.1987 35.0875 71.3037 35.9769 71.3037 37.0741C71.3037 38.1713 72.1987 39.0607 73.3027 39.0607Z"
          fill="#CCCCCC"
        />
        <path
          d="M73.3027 46.5656C74.4067 46.5656 75.3017 45.6761 75.3017 44.579C75.3017 43.4818 74.4067 42.5924 73.3027 42.5924C72.1987 42.5924 71.3037 43.4818 71.3037 44.579C71.3037 45.6761 72.1987 46.5656 73.3027 46.5656Z"
          fill="#CCCCCC"
        />
        <path
          d="M73.3027 54.0705C74.4067 54.0705 75.3017 53.181 75.3017 52.0839C75.3017 50.9867 74.4067 50.0973 73.3027 50.0973C72.1987 50.0973 71.3037 50.9867 71.3037 52.0839C71.3037 53.181 72.1987 54.0705 73.3027 54.0705Z"
          fill="#CCCCCC"
        />
        <path
          d="M73.3027 61.5752C74.4067 61.5752 75.3017 60.6858 75.3017 59.5886C75.3017 58.4915 74.4067 57.6021 73.3027 57.6021C72.1987 57.6021 71.3037 58.4915 71.3037 59.5886C71.3037 60.6858 72.1987 61.5752 73.3027 61.5752Z"
          fill="#CCCCCC"
        />
        <path
          d="M149.042 37.2949H83.2973C83.2384 37.2949 83.1819 37.2716 83.1403 37.2302C83.0986 37.1888 83.0752 37.1327 83.0752 37.0741C83.0752 37.0156 83.0986 36.9594 83.1403 36.918C83.1819 36.8767 83.2384 36.8534 83.2973 36.8534H149.042C149.101 36.8534 149.157 36.8767 149.199 36.918C149.241 36.9594 149.264 37.0156 149.264 37.0741C149.264 37.1327 149.241 37.1888 149.199 37.2302C149.157 37.2716 149.101 37.2949 149.042 37.2949Z"
          fill="#CCCCCC"
        />
        <path
          d="M149.042 44.7997H83.2973C83.2384 44.7997 83.1819 44.7765 83.1403 44.7351C83.0986 44.6937 83.0752 44.6375 83.0752 44.579C83.0752 44.5205 83.0986 44.4643 83.1403 44.4229C83.1819 44.3815 83.2384 44.3583 83.2973 44.3583H149.042C149.101 44.3583 149.157 44.3815 149.199 44.4229C149.241 44.4643 149.264 44.5205 149.264 44.579C149.264 44.6375 149.241 44.6937 149.199 44.7351C149.157 44.7765 149.101 44.7997 149.042 44.7997Z"
          fill="#CCCCCC"
        />
        <path
          d="M149.042 52.3045H83.2973C83.2384 52.3045 83.1819 52.2812 83.1403 52.2399C83.0986 52.1985 83.0752 52.1423 83.0752 52.0838C83.0752 52.0252 83.0986 51.9691 83.1403 51.9277C83.1819 51.8863 83.2384 51.863 83.2973 51.863H149.042C149.101 51.863 149.157 51.8863 149.199 51.9277C149.241 51.9691 149.264 52.0252 149.264 52.0838C149.264 52.1423 149.241 52.1985 149.199 52.2399C149.157 52.2812 149.101 52.3045 149.042 52.3045Z"
          fill="#CCCCCC"
        />
        <path
          d="M149.042 59.8094H83.2973C83.2384 59.8094 83.1819 59.7861 83.1403 59.7447C83.0986 59.7033 83.0752 59.6472 83.0752 59.5887C83.0752 59.5301 83.0986 59.474 83.1403 59.4326C83.1819 59.3912 83.2384 59.3679 83.2973 59.3679H149.042C149.101 59.3679 149.157 59.3912 149.199 59.4326C149.241 59.474 149.264 59.5301 149.264 59.5887C149.264 59.6472 149.241 59.7033 149.199 59.7447C149.157 59.7861 149.101 59.8094 149.042 59.8094Z"
          fill="#CCCCCC"
        />
        <path
          d="M95.7363 67.3143H89.2951C89.0007 67.3139 88.7184 67.1975 88.5102 66.9906C88.302 66.7837 88.1849 66.5032 88.1846 66.2106V60.2509C88.1849 59.9583 88.302 59.6777 88.5102 59.4708C88.7184 59.2639 89.0007 59.1476 89.2951 59.1472H95.7363C96.0308 59.1476 96.313 59.2639 96.5212 59.4708C96.7294 59.6777 96.8465 59.9583 96.8469 60.2509V66.2106C96.8465 66.5032 96.7294 66.7837 96.5212 66.9906C96.313 67.1975 96.0308 67.3139 95.7363 67.3143Z"
          fill="#F2682A"
        />
        <path
          d="M110.173 67.3143H103.732C103.437 67.3139 103.155 67.1975 102.947 66.9906C102.739 66.7837 102.621 66.5032 102.621 66.2106V52.746C102.621 52.4534 102.739 52.1729 102.947 51.966C103.155 51.7591 103.437 51.6427 103.732 51.6423H110.173C110.467 51.6427 110.75 51.7591 110.958 51.966C111.166 52.1729 111.283 52.4534 111.283 52.746V66.2106C111.283 66.5032 111.166 66.7837 110.958 66.9906C110.75 67.1975 110.467 67.3139 110.173 67.3143Z"
          fill="#F2682A"
        />
        <path
          d="M124.832 67.3142H118.391C118.096 67.3139 117.814 67.1975 117.606 66.9906C117.398 66.7837 117.281 66.5032 117.28 66.2106V45.2411C117.281 44.9485 117.398 44.668 117.606 44.4611C117.814 44.2542 118.096 44.1378 118.391 44.1375H124.832C125.126 44.1378 125.409 44.2542 125.617 44.4611C125.825 44.668 125.942 44.9485 125.943 45.2411V66.2106C125.942 66.5032 125.825 66.7837 125.617 66.9906C125.409 67.1975 125.126 67.3139 124.832 67.3142Z"
          fill="#F2682A"
        />
        <path
          d="M139.936 67.3143H133.494C133.2 67.314 132.918 67.1976 132.709 66.9907C132.501 66.7838 132.384 66.5033 132.384 66.2106V37.957C132.384 37.6644 132.501 37.3839 132.709 37.177C132.918 36.9701 133.2 36.8537 133.494 36.8534H139.936C140.23 36.8537 140.512 36.9701 140.72 37.177C140.929 37.3839 141.046 37.6644 141.046 37.957V66.2106C141.046 66.5033 140.929 66.7838 140.72 66.9907C140.512 67.1976 140.23 67.314 139.936 67.3143Z"
          fill="#F2682A"
        />
        <path
          d="M149.042 67.3143H83.2973C83.2384 67.3143 83.1819 67.291 83.1403 67.2496C83.0986 67.2082 83.0752 67.1521 83.0752 67.0935C83.0752 67.035 83.0986 66.9789 83.1403 66.9375C83.1819 66.8961 83.2384 66.8728 83.2973 66.8728H149.042C149.101 66.8728 149.157 66.8961 149.199 66.9375C149.241 66.9789 149.264 67.035 149.264 67.0935C149.264 67.1521 149.241 67.2082 149.199 67.2496C149.157 67.291 149.101 67.3143 149.042 67.3143Z"
          fill="#3F3D56"
        />
        <path
          d="M177.928 108.491C178.108 108.22 178.345 107.992 178.624 107.823C178.902 107.653 179.214 107.546 179.539 107.509C179.863 107.473 180.192 107.507 180.501 107.61C180.811 107.713 181.094 107.883 181.331 108.106L188.384 104.489L187.945 108.565L181.315 111.375C180.91 111.745 180.383 111.955 179.834 111.965C179.284 111.974 178.75 111.784 178.332 111.428C177.915 111.073 177.643 110.577 177.569 110.036C177.494 109.495 177.622 108.945 177.928 108.491Z"
          fill="#FFB8B8"
        />
        <path
          d="M183.996 110.011C183.836 109.886 183.718 109.716 183.658 109.523L183.097 107.722C183.037 107.527 183.038 107.318 183.101 107.124C183.164 106.929 183.286 106.759 183.449 106.636L189.166 102.345L195.357 90.9301C195.617 90.5982 195.939 90.3203 196.307 90.1123C196.674 89.9042 197.079 89.7701 197.499 89.7177C197.918 89.6652 198.344 89.6954 198.752 89.8065C199.16 89.9175 199.541 90.1074 199.875 90.3651C200.209 90.6228 200.489 90.9434 200.698 91.3085C200.908 91.6737 201.042 92.0762 201.095 92.4931C201.148 92.91 201.118 93.3332 201.006 93.7385C200.894 94.1438 200.703 94.5232 200.444 94.8551L196.921 102.243C195.853 104.486 194.001 106.266 191.71 107.253L185.01 110.141C184.882 110.196 184.742 110.224 184.602 110.222C184.462 110.221 184.323 110.19 184.196 110.132C184.125 110.1 184.058 110.059 183.996 110.011Z"
          fill="#3F3D56"
        />
        <path
          d="M200.599 86.1663C203.612 86.1663 206.054 83.739 206.054 80.7449C206.054 77.7507 203.612 75.3235 200.599 75.3235C197.586 75.3235 195.144 77.7507 195.144 80.7449C195.144 83.739 197.586 86.1663 200.599 86.1663Z"
          fill="#FFB8B8"
        />
        <path
          d="M198.386 151.864L195.663 151.863L194.368 141.425L198.387 141.426L198.386 151.864Z"
          fill="#FFB8B8"
        />
        <path
          d="M193.719 151.09H198.97V154.376H190.412C190.412 153.945 190.498 153.517 190.664 153.119C190.83 152.72 191.074 152.358 191.381 152.053C191.688 151.747 192.052 151.505 192.453 151.34C192.854 151.175 193.284 151.09 193.719 151.09Z"
          fill="#2F2E41"
        />
        <path
          d="M207.271 151.864L204.548 151.864L203.253 141.426L207.272 141.426L207.271 151.864Z"
          fill="#FFB8B8"
        />
        <path
          d="M202.602 151.09H207.854V154.376H199.296C199.296 153.945 199.381 153.518 199.548 153.119C199.714 152.72 199.957 152.358 200.264 152.053C200.571 151.748 200.936 151.506 201.337 151.341C201.738 151.175 202.168 151.09 202.602 151.09Z"
          fill="#2F2E41"
        />
        <path
          d="M207.47 149.215L204.279 149.215C204.059 149.215 203.846 149.143 203.671 149.01C203.497 148.877 203.371 148.691 203.314 148.48L198.721 131.55C198.714 131.524 198.697 131.501 198.674 131.486C198.651 131.471 198.623 131.465 198.596 131.47C198.569 131.474 198.544 131.489 198.527 131.51C198.51 131.531 198.501 131.558 198.503 131.585L199.431 147.642C199.439 147.784 199.416 147.926 199.364 148.058C199.313 148.191 199.233 148.311 199.13 148.41C199.028 148.509 198.905 148.586 198.77 148.634C198.636 148.682 198.492 148.7 198.35 148.689L194.763 148.391C194.533 148.372 194.317 148.274 194.152 148.115C193.986 147.955 193.88 147.744 193.853 147.516L190.586 113.801L191.85 114.555L203.695 112.556L208.461 148.091C208.48 148.231 208.468 148.374 208.427 148.509C208.386 148.645 208.316 148.77 208.222 148.876C208.128 148.983 208.013 149.068 207.883 149.127C207.753 149.185 207.612 149.215 207.47 149.215Z"
          fill="#2F2E41"
        />
        <path
          d="M194.547 117.359C193.24 117.349 191.96 116.991 190.84 116.322C190.667 116.221 190.529 116.07 190.445 115.889C190.36 115.708 190.332 115.506 190.365 115.309C190.782 112.774 192.96 99.7683 194.942 92.678C195.75 89.7902 197.535 88.2348 200.248 88.0552C204.216 87.7965 207.744 90.8821 208.111 94.942C208.759 102.104 206.127 112.28 204.965 116.323C204.92 116.479 204.837 116.622 204.724 116.739C204.61 116.856 204.47 116.943 204.314 116.994C204.159 117.044 203.994 117.055 203.833 117.027C203.672 116.999 203.52 116.932 203.391 116.832L201.023 115.002C200.877 114.891 200.697 114.835 200.514 114.842C200.331 114.85 200.156 114.921 200.02 115.043C198.024 116.812 196.128 117.359 194.547 117.359Z"
          fill="#3F3D56"
        />
        <path
          opacity="0.2"
          d="M202.378 98.4468L204.377 106.835L201.489 113.898L203.489 107.056L202.378 98.4468Z"
          fill="black"
        />
        <path
          d="M203.712 85.2032C204.197 85.2121 204.68 85.1255 205.131 84.9481C205.33 84.8721 205.536 84.8096 205.734 84.7335C207.485 84.0614 208.638 82.2086 208.679 80.344C208.721 78.4794 207.749 76.6696 206.306 75.4768C204.864 74.2841 202.995 73.6717 201.124 73.53C199.108 73.3774 196.838 73.8853 195.712 75.5535C195.412 75.9975 195.202 76.5363 195.38 77.0724C195.433 77.2342 195.528 77.3793 195.655 77.4932C196.158 77.9379 196.66 77.6035 197.173 77.574C197.877 77.5334 198.51 78.1004 198.738 78.7646C198.965 79.4288 198.865 80.16 198.681 80.8374C198.541 81.2451 198.453 81.6685 198.419 82.0978C198.404 82.3131 198.445 82.5286 198.537 82.7238C198.63 82.9191 198.771 83.0877 198.947 83.2136C199.387 83.4781 199.966 83.3251 200.406 83.0609C200.847 82.7968 201.228 82.4296 201.704 82.2352C202.18 82.0409 202.82 82.084 203.097 82.515C203.185 82.6718 203.244 82.8429 203.273 83.0202C203.521 84.1448 203.464 84.0786 203.712 85.2032Z"
          fill="#2F2E41"
        />
        <path
          d="M195.256 144.291L161.096 132.469C160.51 132.265 160.029 131.839 159.759 131.283C159.489 130.728 159.451 130.088 159.655 129.505L168.532 104.174C168.682 103.748 168.996 103.398 169.406 103.201C169.815 103.004 170.287 102.977 170.716 103.125L206.031 115.347C206.468 115.499 206.826 115.817 207.028 116.231C207.229 116.645 207.257 117.121 207.105 117.556L198.238 142.859C198.033 143.441 197.604 143.919 197.045 144.188C196.486 144.456 195.843 144.494 195.256 144.291Z"
          fill="#E6E6E6"
        />
        <path
          d="M164.011 131.013L193.882 141.351C194.451 141.548 195.075 141.512 195.617 141.252C196.159 140.991 196.575 140.527 196.773 139.962L203.941 119.508C204.139 118.942 204.103 118.322 203.841 117.783C203.579 117.244 203.112 116.831 202.543 116.634L172.673 106.296C172.104 106.099 171.48 106.135 170.938 106.395C170.396 106.656 169.98 107.12 169.782 107.685L162.614 128.139C162.516 128.419 162.474 128.716 162.491 129.012C162.508 129.307 162.584 129.597 162.714 129.864C162.843 130.131 163.025 130.37 163.248 130.567C163.47 130.764 163.73 130.916 164.011 131.013Z"
          fill="white"
        />
        <path
          d="M171.513 116.642L170.033 116.13C169.953 116.102 169.887 116.044 169.85 115.968C169.813 115.892 169.808 115.804 169.836 115.725L170.351 114.255C170.379 114.175 170.438 114.109 170.514 114.073C170.591 114.036 170.679 114.031 170.759 114.059L172.239 114.571C172.319 114.598 172.385 114.657 172.422 114.733C172.458 114.809 172.464 114.896 172.436 114.976L171.921 116.446C171.893 116.526 171.834 116.591 171.757 116.628C171.681 116.665 171.593 116.67 171.513 116.642Z"
          fill="#F2682A"
        />
        <path
          d="M170.178 120.451L168.698 119.939C168.618 119.911 168.552 119.853 168.515 119.777C168.478 119.701 168.473 119.613 168.501 119.534L169.016 118.063C169.044 117.984 169.103 117.918 169.18 117.882C169.256 117.845 169.344 117.84 169.424 117.867L170.904 118.379C170.984 118.407 171.05 118.466 171.087 118.542C171.124 118.618 171.129 118.705 171.101 118.785L170.586 120.255C170.558 120.335 170.499 120.4 170.422 120.437C170.346 120.474 170.258 120.479 170.178 120.451Z"
          fill="#3F3D56"
        />
        <path
          d="M168.843 124.26L167.363 123.748C167.283 123.72 167.217 123.662 167.18 123.586C167.143 123.51 167.138 123.422 167.166 123.343L167.681 121.872C167.709 121.793 167.768 121.727 167.845 121.691C167.921 121.654 168.009 121.649 168.089 121.676L169.569 122.188C169.649 122.216 169.715 122.275 169.752 122.351C169.789 122.427 169.794 122.514 169.766 122.594L169.251 124.064C169.223 124.144 169.164 124.209 169.087 124.246C169.011 124.283 168.923 124.288 168.843 124.26Z"
          fill="#CCCCCC"
        />
        <path
          d="M181.453 119.258L174.325 116.791C174.285 116.778 174.249 116.756 174.217 116.728C174.186 116.701 174.16 116.667 174.141 116.629C174.123 116.592 174.112 116.551 174.11 116.509C174.107 116.467 174.113 116.425 174.127 116.386C174.141 116.346 174.163 116.31 174.191 116.278C174.219 116.247 174.253 116.222 174.291 116.203C174.329 116.185 174.37 116.175 174.412 116.172C174.454 116.17 174.496 116.176 174.536 116.19L181.664 118.657C181.703 118.67 181.74 118.692 181.772 118.72C181.803 118.747 181.829 118.781 181.847 118.819C181.866 118.856 181.876 118.897 181.879 118.939C181.881 118.981 181.875 119.023 181.862 119.062C181.848 119.102 181.826 119.138 181.798 119.17C181.77 119.201 181.736 119.226 181.698 119.245C181.66 119.263 181.619 119.273 181.577 119.276C181.535 119.278 181.493 119.272 181.453 119.258Z"
          fill="#CCCCCC"
        />
        <path
          d="M180.118 123.067L172.99 120.6C172.91 120.572 172.844 120.514 172.807 120.438C172.77 120.362 172.765 120.275 172.793 120.195C172.821 120.115 172.88 120.05 172.956 120.013C173.033 119.976 173.121 119.971 173.201 119.999L180.329 122.466C180.369 122.479 180.405 122.501 180.437 122.528C180.468 122.556 180.494 122.59 180.512 122.628C180.531 122.665 180.541 122.706 180.544 122.748C180.546 122.79 180.541 122.832 180.527 122.871C180.513 122.911 180.491 122.947 180.463 122.979C180.435 123.01 180.401 123.035 180.363 123.053C180.325 123.072 180.284 123.082 180.242 123.084C180.2 123.087 180.158 123.081 180.118 123.067Z"
          fill="#CCCCCC"
        />
        <path
          d="M178.783 126.876L171.655 124.409C171.575 124.382 171.509 124.323 171.472 124.247C171.435 124.171 171.43 124.084 171.458 124.004C171.486 123.924 171.545 123.859 171.621 123.822C171.698 123.785 171.786 123.78 171.866 123.808L178.994 126.275C179.074 126.302 179.14 126.361 179.177 126.437C179.214 126.513 179.219 126.6 179.191 126.68C179.163 126.76 179.104 126.825 179.028 126.862C178.951 126.899 178.863 126.904 178.783 126.876Z"
          fill="#CCCCCC"
        />
        <path
          d="M197.752 128.94C197.804 128.816 197.852 128.69 197.897 128.562C198.483 126.89 198.377 125.056 197.602 123.462C196.826 121.868 195.446 120.646 193.764 120.063L191.421 126.749L197.752 128.94Z"
          fill="#F2682A"
        />
        <path
          d="M190.739 127.155L193.082 120.47C191.844 120.042 190.506 119.983 189.235 120.3C187.963 120.618 186.813 121.298 185.926 122.258C185.04 123.217 184.455 124.414 184.244 125.7C184.033 126.986 184.205 128.305 184.74 129.495C185.274 130.684 186.147 131.692 187.252 132.395C188.356 133.097 189.643 133.463 190.954 133.447C192.265 133.432 193.542 133.035 194.629 132.306C195.716 131.578 196.564 130.549 197.07 129.347L190.739 127.155Z"
          fill="#3F3D56"
        />
        <path
          d="M199.127 117.565C199.1 117.242 199.145 116.917 199.259 116.613C199.373 116.308 199.552 116.033 199.784 115.805C200.017 115.577 200.297 115.403 200.605 115.294C200.912 115.185 201.24 115.145 201.565 115.176L204.869 108.004L207.054 111.48L203.582 117.758C203.494 118.297 203.209 118.785 202.783 119.13C202.357 119.475 201.818 119.652 201.269 119.628C200.719 119.605 200.198 119.382 199.803 119.001C199.408 118.621 199.168 118.11 199.127 117.565Z"
          fill="#FFB8B8"
        />
        <path
          d="M204.846 115.04C204.642 115.04 204.444 114.978 204.276 114.863L202.717 113.79C202.548 113.673 202.42 113.508 202.348 113.317C202.277 113.125 202.267 112.916 202.319 112.719L204.153 105.837L201.937 93.0578C201.934 92.6372 202.015 92.2203 202.175 91.8309C202.334 91.4415 202.57 91.0871 202.867 90.7882C203.165 90.4892 203.519 90.2514 203.909 90.0885C204.299 89.9256 204.718 89.8407 205.141 89.8387C205.564 89.8366 205.983 89.9175 206.375 90.0767C206.767 90.2358 207.123 90.4702 207.423 90.7663C207.724 91.0624 207.963 91.4144 208.126 91.8023C208.29 92.1902 208.375 92.6063 208.376 93.0268L210.192 101C210.744 103.42 210.392 105.957 209.203 108.139L205.724 114.52C205.657 114.642 205.565 114.749 205.454 114.834C205.342 114.919 205.214 114.98 205.078 115.012C205.002 115.031 204.924 115.04 204.846 115.04Z"
          fill="#3F3D56"
        />
        <path
          d="M221.749 154.5H179.548C179.489 154.5 179.433 154.477 179.391 154.435C179.35 154.394 179.326 154.338 179.326 154.279C179.326 154.221 179.35 154.165 179.391 154.123C179.433 154.082 179.489 154.059 179.548 154.059H221.749C221.808 154.059 221.865 154.082 221.906 154.123C221.948 154.165 221.971 154.221 221.971 154.279C221.971 154.338 221.948 154.394 221.906 154.435C221.865 154.477 221.808 154.5 221.749 154.5Z"
          fill="#CCCCCC"
        />
        <path
          d="M109.926 151.754L112.649 151.753L113.945 141.316H109.926L109.926 151.754Z"
          fill="#A0616A"
        />
        <path
          d="M109.231 150.87L114.594 150.87C115.043 150.87 115.487 150.958 115.902 151.128C116.317 151.299 116.693 151.549 117.011 151.865C117.328 152.18 117.58 152.554 117.752 152.967C117.923 153.379 118.012 153.82 118.012 154.266V154.377L109.232 154.377L109.231 150.87Z"
          fill="#2F2E41"
        />
        <path
          d="M99.0424 151.754L101.766 151.753L103.061 141.316H99.042L99.0424 151.754Z"
          fill="#A0616A"
        />
        <path
          d="M98.3477 150.87L103.71 150.87C104.159 150.87 104.604 150.958 105.018 151.128C105.433 151.299 105.81 151.549 106.127 151.865C106.444 152.18 106.696 152.554 106.868 152.967C107.04 153.379 107.128 153.82 107.128 154.266V154.377L98.3478 154.377L98.3477 150.87Z"
          fill="#2F2E41"
        />
        <path
          d="M110.47 79.4905C113.483 79.4905 115.925 77.0632 115.925 74.0691C115.925 71.0749 113.483 68.6477 110.47 68.6477C107.457 68.6477 105.015 71.0749 105.015 74.0691C105.015 77.0632 107.457 79.4905 110.47 79.4905Z"
          fill="#A0616A"
        />
        <path
          d="M114.138 149.501H109.085C108.952 149.501 108.821 149.475 108.699 149.424C108.577 149.373 108.467 149.299 108.374 149.206C108.281 149.112 108.207 149.001 108.158 148.879C108.108 148.757 108.084 148.627 108.085 148.496L108.379 124.65C108.38 124.565 108.348 124.483 108.29 124.421C108.233 124.359 108.153 124.321 108.068 124.315C107.983 124.309 107.899 124.336 107.833 124.39C107.768 124.444 107.725 124.521 107.715 124.605L104.774 148.628C104.744 148.868 104.627 149.09 104.444 149.251C104.262 149.412 104.026 149.501 103.782 149.501H98.6802C98.5423 149.501 98.406 149.473 98.2797 149.418C98.1534 149.363 98.0399 149.283 97.9464 149.182C97.8528 149.082 97.7811 148.963 97.7359 148.833C97.6907 148.704 97.673 148.567 97.6837 148.43L101.053 105.592L101.144 105.582L117.276 103.85L115.136 148.555C115.124 148.81 115.014 149.051 114.828 149.227C114.642 149.403 114.395 149.501 114.138 149.501Z"
          fill="#2F2E41"
        />
        <path
          d="M101.249 108.222C100.99 108.221 100.741 108.12 100.555 107.941C100.369 107.761 100.261 107.517 100.253 107.26C100.165 104.551 100.014 95.1784 101.768 88.2976C102.247 86.4179 103.322 84.7412 104.833 83.5153C106.345 82.2895 108.214 81.5795 110.163 81.4905C112.081 81.3863 113.984 81.8852 115.601 82.9163C117.218 83.9473 118.467 85.4582 119.171 87.2346C122.12 94.674 124.905 103.844 121.432 105.282C116.88 107.167 104.88 108.007 101.31 108.22C101.29 108.221 101.269 108.222 101.249 108.222Z"
          fill="#3F3D56"
        />
        <path
          d="M122.099 68.5896C122.184 68.6772 122.277 68.7578 122.376 68.8309L121.803 79.7629L119.421 80.9748L120.791 84.7864L125.218 83.0481C125.576 82.9077 125.878 82.6558 126.079 82.3302C126.28 82.0046 126.37 81.6229 126.334 81.2424L125.175 68.8419C125.554 68.5605 125.838 68.171 125.99 67.7251C126.141 67.2793 126.152 66.7982 126.022 66.3459C125.891 65.8936 125.625 65.4916 125.259 65.1932C124.893 64.8948 124.444 64.7143 123.972 64.6756C123.5 64.6369 123.028 64.7419 122.617 64.9766C122.207 65.2113 121.878 65.5646 121.675 65.9895C121.472 66.4144 121.403 66.8908 121.479 67.3552C121.555 67.8197 121.771 68.2503 122.099 68.5896Z"
          fill="#A0616A"
        />
        <path
          d="M121.848 80.1698L123.524 84.902C123.571 85.0367 123.589 85.1799 123.576 85.3221C123.563 85.4642 123.519 85.6019 123.448 85.7258C123.377 85.8496 123.279 85.9567 123.162 86.0397C123.045 86.1227 122.912 86.1797 122.771 86.2069L117.83 87.1578C117.135 87.3963 116.374 87.3515 115.713 87.0329C115.051 86.7144 114.544 86.1482 114.302 85.4586C114.06 84.7689 114.103 84.0121 114.422 83.354C114.741 82.696 115.309 82.1904 116.003 81.9481L120.439 79.621C120.566 79.5544 120.706 79.5158 120.849 79.5078C120.993 79.4998 121.136 79.5226 121.27 79.5747C121.404 79.6268 121.525 79.7069 121.624 79.8096C121.724 79.9123 121.8 80.0351 121.848 80.1698Z"
          fill="#3F3D56"
        />
        <path
          d="M98.4698 68.5896C98.384 68.6772 98.2915 68.7578 98.193 68.8309L98.7657 79.7629L101.148 80.9748L99.7771 84.7864L95.3505 83.0481C94.9929 82.9077 94.6907 82.6558 94.4895 82.3302C94.2882 82.0046 94.1986 81.6229 94.2342 81.2424L95.3939 68.8419C95.0144 68.5605 94.7301 68.171 94.5788 67.7251C94.4275 67.2793 94.4164 66.7982 94.547 66.3459C94.6775 65.8936 94.9435 65.4916 95.3096 65.1932C95.6757 64.8948 96.1245 64.7143 96.5964 64.6756C97.0682 64.6369 97.5408 64.7419 97.9511 64.9766C98.3615 65.2113 98.6902 65.5646 98.8936 65.9895C99.0969 66.4144 99.1653 66.8908 99.0896 67.3552C99.0139 67.8197 98.7977 68.2503 98.4698 68.5896Z"
          fill="#A0616A"
        />
        <path
          d="M99.2978 79.5747C99.4315 79.5225 99.5749 79.4997 99.7183 79.5077C99.8618 79.5156 100.002 79.5543 100.129 79.6209L104.565 81.9481C105.258 82.1903 105.827 82.6959 106.146 83.354C106.464 84.0121 106.507 84.7689 106.266 85.4586C106.024 86.1482 105.516 86.7144 104.855 87.0329C104.194 87.3514 103.432 87.3963 102.738 87.1577L97.7967 86.2069C97.6557 86.1797 97.5222 86.1227 97.4054 86.0397C97.2886 85.9566 97.1911 85.8496 97.1197 85.7257C97.0483 85.6019 97.0045 85.4642 96.9914 85.3221C96.9784 85.1799 96.9963 85.0367 97.044 84.902L98.7196 80.1698C98.7672 80.0351 98.8435 79.9122 98.9433 79.8095C99.0431 79.7068 99.164 79.6267 99.2978 79.5747Z"
          fill="#3F3D56"
        />
        <path
          d="M112.588 74.9931C112.56 74.3016 112.734 73.6167 113.091 73.0221C113.447 72.4276 113.971 71.9491 114.596 71.6452C116.255 70.7945 117.296 68.9798 116.84 67.3228C116.255 65.2018 113.351 63.8262 110.997 64.556C110.36 64.7807 109.746 65.0644 109.162 65.4034L107.625 66.2226C106.968 66.5494 106.334 66.9209 105.729 67.3346C104.822 67.9985 104.112 68.8922 103.672 69.9223C103.231 70.9524 103.077 72.0812 103.226 73.1906C103.529 75.3468 104.95 77.3243 106.895 78.6782C107.638 79.2306 108.498 79.6101 109.409 79.7887C110.341 79.9414 111.379 79.7678 112.06 79.1917C113.126 78.2912 113.016 76.7833 112.671 75.5239C112.626 75.3502 112.598 75.1724 112.588 74.9931Z"
          fill="#2F2E41"
        />
        <path
          d="M131.128 154.5H88.9272C88.8683 154.5 88.8118 154.477 88.7701 154.435C88.7285 154.394 88.7051 154.338 88.7051 154.279C88.7051 154.221 88.7285 154.165 88.7701 154.123C88.8118 154.082 88.8683 154.059 88.9272 154.059H131.128C131.187 154.059 131.244 154.082 131.285 154.123C131.327 154.165 131.35 154.221 131.35 154.279C131.35 154.338 131.327 154.394 131.285 154.435C131.244 154.477 131.187 154.5 131.128 154.5Z"
          fill="#CCCCCC"
        />
        <path
          d="M62.2539 114.233L27.432 128.429C26.834 128.672 26.1634 128.67 25.5673 128.422C24.9712 128.174 24.4984 127.702 24.2525 127.108L13.593 101.286C13.4138 100.851 13.4157 100.363 13.5983 99.9285C13.7809 99.4946 14.1293 99.1503 14.567 98.9713L50.5661 84.2945C51.0118 84.1133 51.5116 84.1153 51.9558 84.2999C52.4001 84.4846 52.7525 84.8368 52.9357 85.2793L63.5835 111.073C63.8281 111.667 63.8254 112.334 63.5762 112.926C63.3269 113.518 62.8513 113.988 62.2539 114.233Z"
          fill="#E6E6E6"
        />
        <path
          d="M28.6937 125.297L59.143 112.883C59.7228 112.647 60.1844 112.191 60.4262 111.617C60.668 111.042 60.6702 110.395 60.4323 109.819L51.8249 88.9683C51.7071 88.683 51.5339 88.4235 51.3152 88.2047C51.0965 87.9859 50.8366 87.812 50.5503 87.6931C50.264 87.5741 49.9569 87.5123 49.6466 87.5112C49.3363 87.5102 49.0288 87.5699 48.7417 87.687L18.2924 100.101C18.0053 100.218 17.7442 100.39 17.524 100.608C17.3038 100.825 17.1289 101.083 17.0091 101.368C16.8894 101.652 16.8272 101.957 16.8262 102.266C16.8251 102.574 16.8852 102.88 17.003 103.165L25.6105 124.016C25.7283 124.301 25.9014 124.561 26.1201 124.78C26.3388 124.999 26.5987 125.172 26.885 125.291C27.1713 125.41 27.4784 125.472 27.7887 125.473C28.0991 125.474 28.4066 125.414 28.6937 125.297Z"
          fill="white"
        />
        <path
          d="M51.6567 94.6546L51.209 94.8372L51.2396 94.9113L51.6873 94.7288L51.6567 94.6546Z"
          fill="#E6E6E6"
        />
        <path
          d="M50.335 95.2801L49.4306 95.6488L49.4 95.5746L50.3043 95.2059L50.335 95.2801ZM48.5262 96.0175L47.6218 96.3862L47.5912 96.3121L48.4956 95.9434L48.5262 96.0175ZM46.7174 96.7549L45.8131 97.1237L45.7825 97.0495L46.6868 96.6808L46.7174 96.7549ZM44.9087 97.4924L44.0043 97.8611L43.9737 97.7869L44.8781 97.4182L44.9087 97.4924ZM43.0999 98.2298L42.1955 98.5985L42.1649 98.5244L43.0693 98.1557L43.0999 98.2298ZM41.2912 98.9672L40.3868 99.336L40.3562 99.2618L41.2605 98.8931L41.2912 98.9672ZM39.4824 99.7047L38.578 100.073L38.5474 99.9992L39.4518 99.6305L39.4824 99.7047ZM37.6736 100.442L36.7693 100.811L36.7387 100.737L37.643 100.368L37.6736 100.442ZM35.8649 101.18L34.9605 101.548L34.9299 101.474L35.8343 101.105L35.8649 101.18ZM34.0561 101.917L33.1517 102.286L33.1211 102.212L34.0255 101.843L34.0561 101.917ZM32.2473 102.654L31.343 103.023L31.3124 102.949L32.2167 102.58L32.2473 102.654ZM30.4386 103.392L29.5342 103.761L29.5036 103.686L30.408 103.318L30.4386 103.392ZM28.6298 104.129L27.7255 104.498L27.6948 104.424L28.5992 104.055L28.6298 104.129ZM26.8211 104.867L25.9167 105.235L25.8861 105.161L26.7905 104.793L26.8211 104.867ZM25.0123 105.604L24.1079 105.973L24.0773 105.899L24.9817 105.53L25.0123 105.604ZM23.2036 106.342L22.2992 106.71L22.2686 106.636L23.1729 106.267L23.2036 106.342Z"
          fill="#E6E6E6"
        />
        <path
          d="M21.3647 107.005L20.917 107.187L20.9476 107.261L21.3953 107.079L21.3647 107.005Z"
          fill="#E6E6E6"
        />
        <path
          d="M39.8051 107.543L39.3574 107.725L39.388 107.8L39.8357 107.617L39.8051 107.543Z"
          fill="#E6E6E6"
        />
        <path
          d="M38.4964 108.163L37.6054 108.526L37.5748 108.452L38.4658 108.089L38.4964 108.163ZM36.7145 108.889L35.8235 109.253L35.7929 109.178L36.6839 108.815L36.7145 108.889ZM34.9326 109.616L34.0416 109.979L34.011 109.905L34.902 109.542L34.9326 109.616ZM33.1507 110.342L32.2598 110.706L32.2291 110.631L33.1201 110.268L33.1507 110.342ZM31.3688 111.069L30.4778 111.432L30.4472 111.358L31.3382 110.995L31.3688 111.069ZM29.5869 111.795L28.696 112.159L28.6653 112.084L29.5563 111.721L29.5869 111.795ZM27.805 112.522L26.9141 112.885L26.8835 112.811L27.7744 112.448L27.805 112.522ZM26.0231 113.248L25.1322 113.611L25.1016 113.537L25.9925 113.174L26.0231 113.248Z"
          fill="#E6E6E6"
        />
        <path
          d="M24.2113 113.9L23.7637 114.083L23.7943 114.157L24.2419 113.975L24.2113 113.9Z"
          fill="#E6E6E6"
        />
        <path
          d="M32.4223 114.364L31.9746 114.547L32.0052 114.621L32.4529 114.439L32.4223 114.364Z"
          fill="#E6E6E6"
        />
        <path
          d="M31.0885 114.995L30.1718 115.369L30.1412 115.294L31.0579 114.921L31.0885 114.995ZM29.2552 115.742L28.3385 116.116L28.3079 116.042L29.2246 115.668L29.2552 115.742ZM27.4219 116.49L26.5052 116.864L26.4746 116.789L27.3913 116.416L27.4219 116.49Z"
          fill="#E6E6E6"
        />
        <path
          d="M25.558 117.163L25.1104 117.346L25.141 117.42L25.5886 117.237L25.558 117.163Z"
          fill="#E6E6E6"
        />
        <path
          d="M34.7201 119.932L34.2725 120.115L34.3031 120.189L34.7507 120.006L34.7201 119.932Z"
          fill="#E6E6E6"
        />
        <path
          d="M33.3863 120.562L32.4697 120.936L32.4391 120.862L33.3557 120.488L33.3863 120.562ZM31.553 121.31L30.6364 121.684L30.6058 121.609L31.5224 121.236L31.553 121.31ZM29.7197 122.057L28.8031 122.431L28.7725 122.357L29.6891 121.983L29.7197 122.057Z"
          fill="#E6E6E6"
        />
        <path
          d="M27.8559 122.731L27.4082 122.913L27.4388 122.987L27.8865 122.805L27.8559 122.731Z"
          fill="#E6E6E6"
        />
        <path
          d="M29.9863 122.015L55.79 111.495C56.4145 111.24 56.9116 110.748 57.1721 110.129C57.4327 109.51 57.4354 108.813 57.1798 108.192L51.9663 95.563L51.9159 95.5852L45.5837 110.632L40.8872 108.681C40.8006 108.644 40.7066 108.628 40.6128 108.634C40.5189 108.64 40.4277 108.667 40.3463 108.714C40.224 108.787 40.1294 108.898 40.0771 109.03L36.7585 116.915L33.3247 115.489C33.2506 115.458 33.1711 115.442 33.0908 115.442C33.0104 115.441 32.9309 115.457 32.8565 115.487C32.7822 115.517 32.7147 115.562 32.6577 115.618C32.6007 115.675 32.5554 115.741 32.5245 115.815C32.5025 115.869 30.3852 121.031 29.9863 122.015Z"
          fill="#F2682A"
        />
        <path
          d="M29.9074 122.134L29.833 122.103L32.4882 115.793C32.5585 115.627 32.6924 115.495 32.8606 115.426C33.0287 115.358 33.2174 115.358 33.3851 115.428L36.7511 116.826L40.0508 108.985C40.1211 108.818 40.255 108.686 40.4231 108.618C40.5913 108.549 40.7799 108.55 40.9477 108.619L45.5763 110.543L51.941 95.4177L52.0154 95.4486L45.6196 110.648L40.9166 108.693C40.8433 108.663 40.7647 108.647 40.6853 108.647C40.6058 108.646 40.5271 108.662 40.4536 108.692C40.3801 108.722 40.3133 108.766 40.257 108.821C40.2006 108.877 40.1558 108.943 40.1252 109.016L36.7944 116.931L33.354 115.502C33.206 115.44 33.0396 115.44 32.8912 115.5C32.7428 115.561 32.6247 115.677 32.5626 115.824L29.9074 122.134Z"
          fill="#3F3D56"
        />
        <path
          d="M32.4376 114.963C32.7493 114.963 33.0021 114.711 33.0021 114.402C33.0021 114.092 32.7493 113.841 32.4376 113.841C32.1258 113.841 31.873 114.092 31.873 114.402C31.873 114.711 32.1258 114.963 32.4376 114.963Z"
          fill="#3F3D56"
        />
        <path
          d="M39.8204 108.141C40.1322 108.141 40.3849 107.89 40.3849 107.58C40.3849 107.27 40.1322 107.019 39.8204 107.019C39.5086 107.019 39.2559 107.27 39.2559 107.58C39.2559 107.89 39.5086 108.141 39.8204 108.141Z"
          fill="#3F3D56"
        />
        <path
          d="M51.3155 95.2329C51.6273 95.2329 51.88 94.9817 51.88 94.6719C51.88 94.362 51.6273 94.1108 51.3155 94.1108C51.0037 94.1108 50.751 94.362 50.751 94.6719C50.751 94.9817 51.0037 95.2329 51.3155 95.2329Z"
          fill="#3F3D56"
        />
        <path
          d="M19.718 113.765C20.0115 113.62 20.2694 113.413 20.4735 113.158C20.6776 112.903 20.823 112.606 20.8995 112.29C20.9759 111.973 20.9815 111.643 20.9159 111.324C20.8503 111.005 20.7151 110.704 20.5198 110.442L22.1965 94.1515H18.5731L17.2406 110.059C16.8201 110.414 16.5453 110.91 16.4683 111.453C16.3913 111.996 16.5175 112.549 16.8228 113.005C17.1282 113.462 17.5916 113.792 18.1252 113.932C18.6588 114.072 19.2255 114.012 19.718 113.765Z"
          fill="#FFB8B8"
        />
        <path
          d="M23.0453 85.1609C25.8765 85.1609 28.1716 82.88 28.1716 80.0664C28.1716 77.2528 25.8765 74.9719 23.0453 74.9719C20.2141 74.9719 17.9189 77.2528 17.9189 80.0664C17.9189 82.88 20.2141 85.1609 23.0453 85.1609Z"
          fill="#FFB8B8"
        />
        <path
          d="M24.0102 103.639C23.805 103.639 23.6055 103.572 23.4423 103.448C23.2791 103.325 23.1612 103.151 23.1067 102.955C22.696 101.472 21.6274 98.9644 19.9307 95.502C19.5479 94.7194 19.3359 93.8651 19.3087 92.9953C19.2814 92.1255 19.4395 91.2598 19.7726 90.4549C20.1057 89.6501 20.6063 88.9244 21.2415 88.3253C21.8767 87.7263 22.6321 87.2675 23.4583 86.979C24.2412 86.7043 25.0716 86.5884 25.9004 86.6382C26.7291 86.688 27.5394 86.9025 28.2833 87.269C29.0272 87.6355 29.6896 88.1465 30.2313 88.7719C30.7729 89.3972 31.1829 90.1242 31.4369 90.9098C32.752 95.0072 32.6885 99.3962 32.6001 101.093C32.5891 101.297 32.5111 101.492 32.3779 101.648C32.2446 101.804 32.0636 101.913 31.8625 101.957L24.2117 103.617C24.1455 103.632 24.078 103.639 24.0102 103.639Z"
          fill="#FF6584"
        />
        <path
          d="M31.4194 152.015H33.9781L35.1955 142.207L31.4189 142.207L31.4194 152.015Z"
          fill="#FFB8B8"
        />
        <path
          d="M38.9131 154.376L30.871 154.377L30.8709 151.289L35.8058 151.289C36.2138 151.289 36.6179 151.369 36.9949 151.524C37.3718 151.679 37.7144 151.906 38.0029 152.193C38.2915 152.48 38.5203 152.82 38.6765 153.195C38.8327 153.569 38.9131 153.971 38.9131 154.376Z"
          fill="#2F2E41"
        />
        <path
          d="M5.83203 148.72L8.07446 149.945L13.8964 141.933L10.5872 140.125L5.83203 148.72Z"
          fill="#FFB8B8"
        />
        <path
          d="M11.2539 154.377L4.20667 150.526L5.70359 147.821L10.028 150.183C10.7502 150.578 11.285 151.241 11.5149 152.028C11.7448 152.814 11.6509 153.659 11.2539 154.377Z"
          fill="#2F2E41"
        />
        <path
          d="M31.299 146.571C31.071 146.571 30.8509 146.489 30.6797 146.339C30.5084 146.189 30.3978 145.983 30.3685 145.758L27.1684 121.252C27.1543 121.143 27.106 121.042 27.0306 120.962C26.9551 120.883 26.8563 120.829 26.7482 120.809C26.6401 120.788 26.5284 120.802 26.4288 120.849C26.3293 120.895 26.2471 120.972 26.194 121.068L12.8572 145.114C12.7472 145.311 12.5699 145.461 12.3576 145.539C12.1453 145.616 11.9121 145.615 11.7004 145.536L8.79333 144.436C8.67641 144.391 8.56956 144.324 8.47907 144.238C8.38857 144.153 8.31626 144.05 8.26637 143.936C8.21648 143.821 8.19002 143.699 8.18854 143.574C8.18707 143.45 8.21061 143.327 8.25779 143.211L17.4965 120.657C17.5199 120.6 17.5358 120.54 17.5437 120.478C18.795 110.853 21.9789 105.959 22.9248 104.688C22.9939 104.594 23.0394 104.486 23.0574 104.371C23.0754 104.257 23.0654 104.14 23.0283 104.03L22.8983 103.642C22.8463 103.488 22.8357 103.323 22.8674 103.164C22.8991 103.005 22.972 102.857 23.079 102.734C26.4876 98.8757 32.4288 100.981 32.4884 101.003L32.5181 101.014L32.5368 101.039C39.1986 110.118 36.7355 139.608 36.1741 145.427C36.1531 145.646 36.055 145.85 35.8971 146.004C35.7392 146.158 35.5317 146.251 35.3112 146.267L31.372 146.569C31.3475 146.571 31.3232 146.571 31.299 146.571Z"
          fill="#2F2E41"
        />
        <path
          d="M42.9746 85.9842C42.949 86.0961 42.9323 86.2099 42.9246 86.3244L33.9586 91.4647L31.7793 90.2179L29.4561 93.2405L33.0983 95.8204C33.3925 96.0288 33.7468 96.1367 34.108 96.128C34.4693 96.1192 34.8179 95.9943 35.1016 95.7718L44.347 88.5233C44.7643 88.6768 45.2187 88.7004 45.6497 88.591C46.0808 88.4816 46.4682 88.2444 46.7603 87.9109C47.0524 87.5773 47.2354 87.1633 47.2849 86.7239C47.3345 86.2845 47.2482 85.8406 47.0377 85.4511C46.8272 85.0615 46.5023 84.745 46.1063 84.5434C45.7103 84.3419 45.2619 84.2649 44.8208 84.3228C44.3797 84.3807 43.9668 84.5707 43.637 84.8674C43.3072 85.1642 43.0762 85.5537 42.9746 85.9842Z"
          fill="#FFB8B8"
        />
        <path
          d="M33.6583 91.7082L30.7605 95.4406C30.6781 95.5468 30.5736 95.6341 30.4541 95.6965C30.3347 95.7589 30.2031 95.795 30.0683 95.8024C29.9335 95.8097 29.7987 95.7881 29.6731 95.739C29.5475 95.6899 29.4341 95.6145 29.3404 95.5179L26.0599 92.1325C25.5156 91.7102 25.162 91.0906 25.0767 90.4096C24.9913 89.7286 25.1812 89.0418 25.6046 88.4996C26.028 87.9575 26.6505 87.6044 27.3355 87.5177C28.0206 87.431 28.7122 87.6178 29.2589 88.0371L33.3733 90.3235C33.4911 90.389 33.5933 90.479 33.673 90.5873C33.7526 90.6955 33.8078 90.8196 33.8349 90.951C33.8619 91.0824 33.8601 91.2181 33.8297 91.3488C33.7992 91.4794 33.7408 91.602 33.6583 91.7082Z"
          fill="#FF6584"
        />
        <path
          d="M22.4268 98.1927L17.7587 97.355C17.6258 97.3312 17.4997 97.2792 17.389 97.2026C17.2782 97.1259 17.1853 97.0265 17.1167 96.911C17.0481 96.7955 17.0054 96.6666 16.9914 96.5332C16.9774 96.3998 16.9925 96.265 17.0357 96.1379L18.549 91.6851C18.6755 91.0104 19.0661 90.4131 19.6351 90.0242C20.2041 89.6353 20.9051 89.4864 21.5843 89.6104C22.2636 89.7343 22.8656 90.1209 23.2585 90.6853C23.6514 91.2497 23.803 91.9459 23.6801 92.6213L23.5325 97.3037C23.5282 97.4377 23.4949 97.5693 23.4348 97.6894C23.3748 97.8096 23.2894 97.9154 23.1845 97.9998C23.0795 98.0842 22.9576 98.1451 22.8268 98.1784C22.6961 98.2116 22.5596 98.2165 22.4268 98.1927Z"
          fill="#FF6584"
        />
        <path
          d="M23.8559 78.8947C25.1735 79.7909 26.9035 80.7121 28.2492 79.7577C28.6702 79.4392 28.976 78.9937 29.1208 78.488C29.2657 77.9823 29.2419 77.4435 29.0529 76.9524C28.4085 75.1212 26.6382 74.315 24.9452 73.697C22.7444 72.8936 20.3481 72.2545 18.0761 72.8299C15.8041 73.4052 13.7962 75.5913 14.2063 77.8853C14.5362 79.7302 16.2873 81.2653 16.0387 83.1226C15.7885 84.992 13.6731 85.9759 11.8056 86.31C9.93801 86.6441 7.84819 86.7514 6.50606 88.0845C4.79392 89.7851 5.22147 92.8248 6.83252 94.6203C8.44356 96.4158 10.8747 97.2297 13.2305 97.783C16.3518 98.5161 19.7277 98.9013 22.6921 97.6847C25.6566 96.4681 27.9634 93.1931 27.1047 90.1222C26.742 88.825 25.8875 87.7287 25.0616 86.6614C24.2358 85.5942 23.3996 84.4781 23.0846 83.1687C22.8221 82.0775 23.0164 80.8106 23.7445 79.9921C23.877 79.8488 23.9603 79.6675 23.9826 79.4742C24.0049 79.2809 23.965 79.0856 23.8686 78.9163L23.8559 78.8947Z"
          fill="#2F2E41"
        />
        <path
          d="M43.3948 154.5H1.19379C1.13488 154.5 1.07839 154.477 1.03673 154.435C0.99508 154.394 0.97168 154.338 0.97168 154.279C0.97168 154.221 0.99508 154.165 1.03673 154.123C1.07839 154.082 1.13488 154.059 1.19379 154.059H43.3948C43.4537 154.059 43.5102 154.082 43.5519 154.123C43.5935 154.165 43.6169 154.221 43.6169 154.279C43.6169 154.338 43.5935 154.394 43.5519 154.435C43.5102 154.477 43.4537 154.5 43.3948 154.5Z"
          fill="#CCCCCC"
        />
      </g>
      <defs>
        <clipPath id="clip0_12218_29367">
          <rect
            width="221"
            height="154"
            fill="white"
            transform="translate(0.97168 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
