import { Box, Typography, Grid, Chip } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useParams } from "react-router-dom";

import { useRolesGet } from "libs/data/endpoints/roles/roles";

import { Loader, SecondaryButton, Dialog } from "components/atoms";

import type { AppThemeProps } from "assets/styles/theme/theme";

type RoleDetailsDialogProps = {
  currentRole: string;
  open: boolean;
  toggleDialog: () => void;
};

export const RoleDetailsDialog = ({
  currentRole,
  open,
  toggleDialog,
}: RoleDetailsDialogProps) => {
  const { projectName } = useParams<{ projectName: string }>();
  const theme = useTheme() as AppThemeProps;
  const { data: role, error } = useRolesGet(projectName || "", currentRole);
  const isLoadingRole = !role && !error;

  return (
    <Dialog
      open={open}
      onClose={toggleDialog}
      title="Role details"
      Actions={<SecondaryButton onClick={toggleDialog}>Close</SecondaryButton>}
    >
      {isLoadingRole ? (
        <Loader />
      ) : (
        <>
          <Box my={2}>
            <Typography variant="h5">Name</Typography>
            <Typography variant="subtitle1">{role?.name}</Typography>
          </Box>
          <Box my={2}>
            <Typography variant="h5">Permissions</Typography>
            <Grid container spacing={1}>
              {/* @ts-ignore */}
              {role?.permissions?.map((permission: string) => (
                <Grid item key={permission}>
                  <Chip
                    label={permission}
                    sx={{ background: theme.palette.background.active }}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </>
      )}
    </Dialog>
  );
};
