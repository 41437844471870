import styled from "@emotion/styled";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { TextField, Icon } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { forwardRef, useImperativeHandle, useRef } from "react";

import { borderRadius, borders, shadows, spacing } from "assets/styles/theme";

import type { TextFieldProps } from "@mui/material";
import type { ForwardedRef } from "react";

const useStyles = makeStyles({
  searchIcon: {
    marginInlineEnd: "5px",
  },
  clearIcon: {
    cursor: "pointer",
  },
});

interface SearchBarProps {
  onChange?: (value: string) => void;
}

export const SearchBar = forwardRef(function SearchFieldForwardRef(
  {
    onChange,
    ...props
  }: SearchBarProps & Omit<TextFieldProps, "onChange" | "innerRef">,
  ref: ForwardedRef<HTMLInputElement | undefined>
) {
  const classes = useStyles();

  const innerRef = useRef<HTMLInputElement>();
  useImperativeHandle(ref, () => innerRef.current, []);

  const onClickClear = () => {
    if (innerRef.current) {
      innerRef.current.value = "";
    }
    onChange?.("");
  };

  return (
    <TextField
      InputProps={{
        startAdornment: (
          <Icon component={SearchIcon} className={classes.searchIcon} />
        ),
        endAdornment: (
          <Icon
            onClick={onClickClear}
            component={ClearIcon}
            className={classes.clearIcon}
          />
        ),
      }}
      {...props}
      inputRef={innerRef}
      size="small"
      onChange={(e) => onChange?.(e.target.value)}
    />
  );
});

export const SearchBarCustomStyle = styled(SearchBar)({
  ".MuiInputBase-root": {
    padding: spacing[8],
    borderRadius: borderRadius[5],
    "& > .MuiInputBase-input": {
      padding: "6px 0 7px",
    },
    "&.Mui-focused,&:hover": {
      border: borders.primary,
      boxShadow: shadows.primary,
    },
    ":before,:after": {
      border: "none !important",
    },
  },
});
