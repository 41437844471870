import { Box, Grid } from "@mui/material";
import { useEffect, useState, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { AutoCompleteSelectHookForm } from "components/atoms/UncontrolledAutoComplete/AutoCompleteSelectHookForm";
import {
  FIELD_RETENTION_MODE,
  FIELD_RETENTION_TIME,
} from "libs/constants/fields";
import { explanations } from "libs/utilities/explanations";
import {
  requestRetentionModes,
  getLabel,
  getPossibleRequestRetentionTimes,
} from "libs/utilities/labels-mapping";
import { useGetOrganizationFeatures } from "store/features";

import { InfoTooltip } from "components/atoms";
import { FormSection } from "components/molecules";

import { FormQueueTime } from "./FormQueueTime";

import type { AutocompleteSelectOption } from "components/atoms/AutoCompleteSelect";

type RequestSettingsProps = {
  defaultMode?: string;
  defaultTime?: number;
  noQueueTime?: boolean;
  defaultBatchQueueSize?: number;
  defaultQueueSize?: number;
};

const RequestSettings = ({
  defaultMode,
  defaultTime,
  defaultQueueSize,
  defaultBatchQueueSize,
  noQueueTime = false,
}: RequestSettingsProps) => {
  const { watch } = useFormContext();
  const organizationFeatures = useGetOrganizationFeatures();

  const possibleRequestRetentionTimesOptions = useMemo(
    () =>
      getPossibleRequestRetentionTimes(
        organizationFeatures?.max_retention_requests ?? 0
      ),
    [organizationFeatures]
  );

  // 1 month
  const highestPossibleRequestionRetentionTime =
    possibleRequestRetentionTimesOptions[3];

  const retention_mode = watch(FIELD_RETENTION_MODE);

  const [defaultRetentionMode, setDefaultRetentionMode] = useState(
    requestRetentionModes[2]
  );
  const [defaultRetentionTime, setDefaultRetentionTime] =
    useState<AutocompleteSelectOption>(highestPossibleRequestionRetentionTime);

  useEffect(() => {
    if (defaultMode) {
      setDefaultRetentionMode({
        label: getLabel(requestRetentionModes, defaultMode),
        value: defaultMode,
      });
    }
  }, [defaultMode]);

  useEffect(() => {
    if (defaultTime) {
      setDefaultRetentionTime({
        label: getLabel(possibleRequestRetentionTimesOptions, defaultTime),
        value: defaultTime,
      });
    }
  }, [defaultTime, possibleRequestRetentionTimesOptions]);

  const noRequestRetentionMode = useMemo(
    () =>
      retention_mode && retention_mode.value === requestRetentionModes[0].value,
    [retention_mode]
  );

  return (
    <FormSection
      title="Request settings"
      description={explanations.deployments.versions.requestsSettings}
    >
      <Grid container direction="column" wrap="nowrap" spacing={3}>
        <Grid item display="flex" style={{ paddingRight: "15px" }}>
          <AutoCompleteSelectHookForm
            name={FIELD_RETENTION_MODE}
            id={FIELD_RETENTION_MODE}
            defaultValue={defaultRetentionMode}
            label="Request retention mode"
            options={requestRetentionModes}
            tooltip={explanations.deployments.versions.retentionMode}
          />
          <Grid item xs={1} paddingTop={2}>
            <InfoTooltip>
              {explanations.deployments.versions.retentionMode}
            </InfoTooltip>
          </Grid>
        </Grid>
        {!noRequestRetentionMode && (
          <Grid item display="flex" style={{ paddingRight: "15px" }}>
            <AutoCompleteSelectHookForm
              name={FIELD_RETENTION_TIME}
              id={FIELD_RETENTION_TIME}
              freeSolo
              defaultValue={defaultRetentionTime}
              label="Request retention time (seconds)"
              options={possibleRequestRetentionTimesOptions}
              placeholder="Type and press enter..."
            />
            <Grid item xs={1} component={Box} paddingTop={2}>
              <InfoTooltip>
                {explanations.deployments.versions.retentionMode}
              </InfoTooltip>
            </Grid>
          </Grid>
        )}
        {!noQueueTime && (
          <FormQueueTime
            defaultBatchQueueSize={defaultBatchQueueSize}
            defaultQueueSize={defaultQueueSize}
          />
        )}
      </Grid>
    </FormSection>
  );
};

export default RequestSettings;
