import CloudDone from "@mui/icons-material/CloudDone";
import CloudUpload from "@mui/icons-material/CloudUpload";
import { useRef, useCallback, useMemo, useReducer, useState } from "react";
import { useParams } from "react-router-dom";

import { DEPLOYMENT_PERMISSIONS } from "libs/constants/permissions";
import { useDeploymentVersionsFileUpload } from "libs/data/customized/deployment-versions/useDeploymentVersionsFileUpload";
import { usePermissionValidation } from "libs/data/customized/roles";
import { RevisionListStatus } from "libs/data/models";
import { useDialog } from "libs/hooks";
import {
  getZipFileDirectoryStructure,
  validateZipFileType,
  validateZipFileSize,
} from "libs/utilities/upload-helper";

import {
  CircularProgressWithLabel,
  Icon,
  PrimaryButton,
  SecondaryButton,
  Dialog,
  DialogWarningHeader,
} from "components/atoms";
import { PackageValidationErrorDialog } from "components/organisms";

import type {
  Dispatch,
  DetailedHTMLProps,
  InputHTMLAttributes,
  LegacyRef,
} from "react";

type stateProps = {
  file: undefined | File;
  failedSteps: number[];
  fileStructureError: string;
};

const initialState: stateProps = {
  file: undefined,
  failedSteps: [],
  fileStructureError: "",
};

const reducer = (
  state: stateProps,
  action: { type: string; payload: File | string | boolean }
) => {
  switch (action.type) {
    case "setFile":
      return {
        ...state,
        file: action.payload,
      };
    case "setFailedSteps":
      return {
        ...state,
        failedSteps: [...state.failedSteps, action.payload],
      };
    case "setFileStructureError":
      return {
        ...state,
        fileStructureError: action.payload,
      };
    case "reset":
      return initialState;

    default:
      return state;
  }
};

type UploadPackageButtonProps = {
  deploymentVersionStatus: string;
  deploymentVersionId: string;
  activeRevision: string;
  lastBuildStatus: string;
  lastBuildId: string;
  versionName: string;
};

export const UploadPackageButton = ({
  deploymentVersionStatus,
  deploymentVersionId,
  activeRevision,
  lastBuildStatus,
  versionName,
}: UploadPackageButtonProps) => {
  // todo: fix typing
  const [state, dispatch]: [state: stateProps, dispatch: Dispatch<unknown>] =
    // @ts-expect-error
    useReducer(reducer, initialState);
  const inputFileRef = useRef<LegacyRef<HTMLInputElement> | undefined>();
  const [loading, setLoading] = useState(false);
  const [confirmUploadDialog, setConfirmUploadDialog] = useState(false);
  const uploadErrorDialogMethods = useDialog();
  const { toggleDialog: toggleErrorDialog } = uploadErrorDialogMethods;
  const {
    projectName,
    deploymentName,
  }: { projectName: string; deploymentName: string } = useParams();
  const uploadFile = useDeploymentVersionsFileUpload(
    projectName,
    deploymentName
  );

  const [currentPermissions] = usePermissionValidation(
    projectName,
    Object.values(DEPLOYMENT_PERMISSIONS),
    deploymentName,
    "deployment"
  );

  const lastBuildStatusLoading = useMemo(
    () =>
      lastBuildStatus === RevisionListStatus.building ||
      lastBuildStatus === RevisionListStatus.queued,
    [lastBuildStatus]
  );

  const onUpload = () => {
    if (inputFileRef?.current) {
      // todo: fix typing
      // @ts-expect-error
      inputFileRef.current.click();
      setConfirmUploadDialog(false);
    }
  };

  const onInputClick = (
    event: DetailedHTMLProps<
      InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >
  ) => {
    // todo: fix typing
    // @ts-expect-error
    event.target.value = "";
  };

  const handleFileUpload = useCallback(
    async (file) => {
      setLoading(true);
      await uploadFile(
        {
          version: versionName,
          deployment: deploymentName,
          id: deploymentVersionId,
        },
        { file },
        file.name
      );
      setLoading(false);
      // @ts-expect-error
      inputFileRef.current.value = "";
    },
    [deploymentName, deploymentVersionId, uploadFile, versionName]
  );

  const handleFileValidation = async ({
    target: { files },
  }: {
    target: { files: FileList | null };
  }) => {
    if (files?.length && files[0]) {
      dispatch({ type: "reset" });
      const isFileTypeZip = validateZipFileType(files[0]);
      const isFileSizeValidated = validateZipFileSize(files[0]);
      const fileStructureResult = await getZipFileDirectoryStructure(files[0]);

      if (!isFileTypeZip) dispatch({ type: "setFailedSteps", payload: 0 });
      else if (!isFileSizeValidated)
        dispatch({ type: "setFailedSteps", payload: 1 });
      else if (!fileStructureResult.valid && fileStructureResult?.reason) {
        dispatch({
          type: "setFileStructureError",
          payload: fileStructureResult.reason,
        });
        dispatch({ type: "setFailedSteps", payload: 2 });
      } else {
        dispatch({ type: "setFile", payload: files[0] });
        handleFileUpload(files[0]);
      }
      if (
        !isFileTypeZip ||
        !isFileSizeValidated ||
        (!fileStructureResult.valid && fileStructureResult?.reason)
      )
        toggleErrorDialog();
    }
  };

  return (
    <div>
      <input
        type="file"
        accept=".zip"
        hidden
        onChange={handleFileValidation}
        onClick={onInputClick}
        // @ts-ignore
        ref={inputFileRef}
      />

      <SecondaryButton
        startIcon={
          loading ? (
            <CircularProgressWithLabel size={24} variant="indeterminate" />
          ) : (
            <Icon
              component={
                deploymentVersionStatus === "unavailable"
                  ? CloudUpload
                  : CloudDone
              }
              status={
                deploymentVersionStatus === "unavailable"
                  ? "initialised"
                  : "available"
              }
              disabled={
                !currentPermissions[DEPLOYMENT_PERMISSIONS["version_upload"]]
              }
            />
          )
        }
        onClick={
          activeRevision ? () => setConfirmUploadDialog(true) : () => onUpload()
        }
        disabled={
          deploymentVersionStatus === "building" ||
          !currentPermissions[DEPLOYMENT_PERMISSIONS["version_upload"]] ||
          loading ||
          lastBuildStatusLoading
        }
      >
        Upload deployment file
      </SecondaryButton>

      <Dialog
        open={confirmUploadDialog}
        onClose={() => setConfirmUploadDialog(false)}
        Header={<DialogWarningHeader title="Warning" />}
        Actions={
          <>
            <SecondaryButton
              onClick={() => setConfirmUploadDialog(false)}
              style={{ marginRight: 14 }}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton onClick={onUpload}>Yes, overwrite</PrimaryButton>
          </>
        }
      >
        Are you sure you want to <b>overwrite</b> the existing deployment
        package? You can also create a new version.
        <br />
        Note: changes can take up to one minute to take effect.
      </Dialog>

      <PackageValidationErrorDialog
        failedSteps={state.failedSteps}
        fileStructureError={state.fileStructureError}
        dialogMethods={uploadErrorDialogMethods}
      />
    </div>
  );
};
