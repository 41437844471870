import Plus from "@mui/icons-material/AddBoxRounded";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import { Box, Grid, useTheme } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { AutoCompleteSelectHookForm } from "components/atoms/UncontrolledAutoComplete/AutoCompleteSelectHookForm";
import {
  FIELD_MONITORING_GROUP,
  FIELD_DEFAULT_MONITORING_GROUP,
} from "libs/constants/fields";
import { useNotificationGroups } from "libs/hooks";
import { explanations } from "libs/utilities/explanations";
import { isObjectHasData } from "libs/utilities/utils";

import { Icon, SecondaryButton, Switch } from "components/atoms";
import { FormSection } from "components/molecules";
import { NotificationGroupEditCreate } from "components/organisms";

import type { AppThemeProps } from "assets/styles/theme/theme";

type NotificationGroupsFormProps = {
  notificationGroups: any;
};

const NotificationGroupsForm = ({
  notificationGroups,
}: NotificationGroupsFormProps) => {
  const { getValues, setValue } = useFormContext();
  const theme = useTheme() as AppThemeProps;
  const [monitoringGroupEnabled, setMonitoringGroupEnabled] = useState(
    !!getValues(FIELD_MONITORING_GROUP)
  );

  const [defaultGroupEnabled, setDefaultGroupEnabled] = useState(
    !!getValues(FIELD_DEFAULT_MONITORING_GROUP)
  );

  const monitoringValue = useWatch({ name: FIELD_MONITORING_GROUP });
  const defaultMonitoringValue = useWatch({
    name: FIELD_DEFAULT_MONITORING_GROUP,
  });

  useEffect(() => {
    if (!defaultGroupEnabled && !!defaultMonitoringValue)
      setDefaultGroupEnabled(true);
  }, [defaultGroupEnabled, defaultMonitoringValue]);

  useEffect(() => {
    if (!monitoringGroupEnabled && !!monitoringValue)
      setMonitoringGroupEnabled(true);
  }, [monitoringValue, monitoringGroupEnabled]);

  const notificationGroupOptions = useMemo(() => {
    let options: { label: string; value: string }[] = [];
    if (isObjectHasData(notificationGroups)) {
      options = Object.values(notificationGroups).map((item: any) => ({
        label: item.name,
        value: item.name,
      }));
    }

    return options;
  }, [notificationGroups]);

  const { dialogProps: createDialogProps, toggleDialog: createDialogToggle } =
    useNotificationGroups({
      isUpdate: false,
      onCloseCallback: () => {
        if (!notificationGroupOptions?.length) {
          setMonitoringGroupEnabled(false);
          setDefaultGroupEnabled(false);
        }
      },
      setGroupCallback: (name) => {
        if (monitoringGroupEnabled)
          setValue(FIELD_MONITORING_GROUP, { label: name, value: name });
        if (defaultGroupEnabled)
          setValue(FIELD_DEFAULT_MONITORING_GROUP, {
            label: name,
            value: name,
          });
      },
    });

  const handleMonitoringSwitch = useCallback(() => {
    // Open dialog when there is no notification group options and toggle is enabled
    if (!notificationGroupOptions?.length && !monitoringGroupEnabled) {
      createDialogToggle();
    }
    setMonitoringGroupEnabled(!monitoringGroupEnabled);
    setValue(FIELD_MONITORING_GROUP, null);
  }, [
    notificationGroupOptions?.length,
    monitoringGroupEnabled,
    setValue,
    createDialogToggle,
  ]);

  const handleDefaultMonitoringSwitch = useCallback(() => {
    // Open dialog when there is no notification group options and toggle is enabled
    if (!notificationGroupOptions?.length && !defaultGroupEnabled) {
      createDialogToggle();
    }
    setDefaultGroupEnabled(!defaultGroupEnabled);
    setValue(FIELD_DEFAULT_MONITORING_GROUP, null);
  }, [
    notificationGroupOptions?.length,
    defaultGroupEnabled,
    setValue,
    createDialogToggle,
  ]);

  return (
    <>
      <FormSection
        title="Notifications"
        description={explanations.monitoring.general}
      >
        <Grid container direction="column" wrap="nowrap" spacing={3}>
          <Grid container style={{ gap: "8px", padding: "12px" }}>
            <Box display="flex" justifyContent="center" alignItems="center">
              {monitoringGroupEnabled ? (
                <Icon color="secondary" component={NotificationsActiveIcon} />
              ) : (
                <Icon
                  component={NotificationsOffIcon}
                  style={{
                    color: monitoringGroupEnabled
                      ? theme.palette.secondary.main
                      : theme.palette.neutrals[400],
                  }}
                />
              )}
            </Box>
            <Grid item xs={1}>
              <Switch
                checked={monitoringGroupEnabled}
                onChange={handleMonitoringSwitch}
              />
            </Grid>
            <Box flexGrow={1}>
              <AutoCompleteSelectHookForm
                name={FIELD_MONITORING_GROUP}
                label="Failed requests notification"
                options={notificationGroupOptions}
                tooltip={explanations.monitoring.monitoringGroup}
                disabled={!monitoringGroupEnabled}
              />
            </Box>
          </Grid>
          <Grid container style={{ gap: "8px", padding: "12px" }}>
            <Box display="flex" justifyContent="center" alignItems="center">
              {defaultGroupEnabled ? (
                <Icon color="secondary" component={NotificationsActiveIcon} />
              ) : (
                <Icon
                  component={NotificationsOffIcon}
                  style={{ color: theme.palette.neutrals[400] }}
                />
              )}
            </Box>
            <Grid item xs={1}>
              <Switch
                checked={defaultGroupEnabled}
                onChange={handleDefaultMonitoringSwitch}
              />
            </Grid>
            <Box flexGrow={1}>
              <AutoCompleteSelectHookForm
                name={FIELD_DEFAULT_MONITORING_GROUP}
                label="Finished requests notification"
                options={notificationGroupOptions}
                tooltip={explanations.monitoring.defaultNotificationGroup}
                disabled={!defaultGroupEnabled}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <SecondaryButton startIcon={<Plus />} onClick={createDialogToggle}>
              Create notification group
            </SecondaryButton>
          </Grid>
        </Grid>
      </FormSection>
      <NotificationGroupEditCreate {...createDialogProps} />
    </>
  );
};

export default NotificationGroupsForm;
