import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  notificationGroupsUpdate,
  useNotificationGroupsList,
} from "libs/data/endpoints/monitoring/monitoring";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import type { NotificationGroupUpdate } from "libs/data/models";

export const useNotificationGroupUpdate = (projectName: string) => {
  const { mutate } = useNotificationGroupsList(projectName);
  const dispatch = useDispatch();

  return useCallback(
    async (notificationGroupName: string, data: NotificationGroupUpdate) => {
      try {
        const result = await notificationGroupsUpdate(
          projectName,
          notificationGroupName,
          data
        );

        await mutate();

        dispatch(
          createSuccessNotification(`Notification group has been updated`)
        );

        return result;
      } catch (err: any) {
        dispatch(
          createErrorNotification(err?.message ?? "Something went wrong")
        );

        return;
      }
    },
    [dispatch, mutate, projectName]
  );
};
