import Plus from "@mui/icons-material/AddBoxRounded";
import MakeDefault from "@mui/icons-material/AssignmentTurnedInRounded";
import Trash from "@mui/icons-material/DeleteRounded";
import { Box, IconButton, Typography, Tooltip } from "@mui/material";
import React, { useState, useMemo, useContext } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { FIELD_DEFAULT_VERSION } from "libs/constants/fields";
import { PIPELINE_PERMISSIONS } from "libs/constants/permissions";
import { BaseUrlContext } from "libs/contexts";
import {
  usePipelineUpdate,
  usePipelineVersionDelete,
} from "libs/data/customized/pipeline";
import { usePermissionValidation } from "libs/data/customized/roles";
import { usePipelineVersionsList } from "libs/data/endpoints/pipelines/pipelines";
import {
  getTzAwareDate,
  getTimeFromNow,
  DATE_TIME_FORMAT,
} from "libs/utilities/date-util";
import { explanations } from "libs/utilities/explanations";
import {
  formatLabelsForFilter,
  renderLabels,
} from "libs/utilities/labels-util";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";
import { routes } from "routes";
import { useGetCurrentOrganization } from "store/features";

import {
  HighlightedText,
  SecondaryButton,
  TableLink,
  DeleteDialog,
  ActionDialog,
  Loader,
} from "components/atoms";
import { BaseTable } from "components/molecules";
import { onFilterAdd } from "components/organisms";

const PipelineVersionsOverview = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { organizationName, projectName, pipelineName } = useParams();

  const currentOrganization = useGetCurrentOrganization();

  const [selectedVersion, setSelectedVersion] = useState(undefined);
  const [defaultDialogOpen, setDefaultDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [filters, setFilters] = useState([]);

  const { data: pipelineVersions, mutate: pipelineVersionsMutate } =
    usePipelineVersionsList(
      projectName,
      pipelineName,
      {
        labels: formatLabelsForFilter(filters),
      },
      {
        swr: {
          swrKey: `/projects/${projectName}/pipelines/${pipelineName}/versions`,
        },
      }
    );

  const updatePipeline = usePipelineUpdate(projectName, pipelineName);
  const deletePipelineVersion = usePipelineVersionDelete(
    projectName,
    pipelineName
  );
  const [currentPermissions] = usePermissionValidation(
    projectName,
    Object.values(PIPELINE_PERMISSIONS),
    pipelineName,
    "pipeline"
  );

  const onRowClick = (e, rowData) => {
    const url = routes.organizations[":organizationName"](organizationName)
      .projects[":projectName"](projectName)
      .pipelines[":pipelineName"](pipelineName)
      .versions[":versionName"](rowData.version)
      .index();
    if (e.metaKey || e.ctrlKey) {
      e.preventDefault();
      e.stopPropagation();
      window.open(url, "_blank");
    } else {
      history.push(url);
    }
  };

  const handleDefaultVersionChange = (data) => {
    const newPipeline = {
      [FIELD_DEFAULT_VERSION]: data,
    };

    updatePipeline(newPipeline)
      .then(() => {
        dispatch(createSuccessNotification("The pipeline was updated"));
      })
      .catch((e) => {
        dispatch(createErrorNotification(e.message));
      });
  };

  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const handleDeleteVersion = (versionName) => {
    setIsLoadingDelete(true);
    deletePipelineVersion(versionName)
      .then(async () => {
        await pipelineVersionsMutate();
        dispatch(createSuccessNotification("The pipeline version was deleted"));
      })
      .catch((e) => {
        dispatch(createErrorNotification(e.message));
      })
      .finally(() => {
        setIsLoadingDelete(false);
      });
  };
  const baseUrl = useContext(BaseUrlContext);

  const columns = useMemo(
    () => [
      {
        title: "Version",
        field: "version",
        nowrap: true,
        render: ({ version, default: default_version }) => (
          <TableLink
            variant="bold"
            to={routes.organizations[":organizationName"](organizationName)
              .projects[":projectName"](projectName)
              .pipelines[":pipelineName"](pipelineName)
              .versions[":versionName"](version)
              .index()}
          >
            {version}
            {default_version && <HighlightedText>default</HighlightedText>}
          </TableLink>
        ),
      },
      {
        title: "Created",
        field: "creation_date",
        type: "datetime",
        nowrap: true,
        render: (rowData) =>
          getTzAwareDate(rowData.creation_date).format(DATE_TIME_FORMAT),
      },
      {
        title: "Edited",
        field: "last_updated",
        enableSorting: true,
        type: "datetime",
        nowrap: true,
        render: (rowData) => getTimeFromNow(rowData.last_updated),
        defaultSort: "desc",
      },
      {
        title: "Labels",
        field: "labels",
        render: renderLabels(onFilterAdd(setFilters)),
        width: "30%",
      },
      {
        disableClick: true,
        width: "8%",
        render: (rowData) => (
          <div className="actions_container">
            <Tooltip title="Make default">
              <span>
                <IconButton
                  status="none"
                  disabled={rowData?.default}
                  onClick={(e) => {
                    if (!rowData?.default) e.stopPropagation();
                    setSelectedVersion(rowData);
                    setDefaultDialogOpen(true);
                  }}
                >
                  <MakeDefault />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Delete">
              <span>
                <IconButton
                  color="primary"
                  disabled={
                    !currentPermissions[PIPELINE_PERMISSIONS["version_delete"]]
                  }
                  onClick={(e) => {
                    if (
                      currentPermissions[PIPELINE_PERMISSIONS["version_delete"]]
                    )
                      e.stopPropagation();
                    setSelectedVersion(rowData);
                    setDeleteDialogOpen(true);
                  }}
                >
                  <Trash />
                </IconButton>
              </span>
            </Tooltip>
          </div>
        ),
      },
    ],
    [currentPermissions, organizationName, pipelineName, projectName]
  );

  return (
    <>
      {!pipelineVersions || isLoadingDelete ? (
        <Loader />
      ) : (
        <BaseTable
          columns={columns}
          data={pipelineVersions ?? []}
          onRowClick={onRowClick}
          filters={filters}
          setFilters={setFilters}
          defaultSortColumn="last_updated"
          defaultSortDirection="asc"
          header={
            <Box display="flex">
              <Box mt={0.5} mr={2}>
                <Typography variant="h3">Versions</Typography>
              </Box>
              <Box>
                <SecondaryButton
                  size="small"
                  startIcon={<Plus />}
                  link={`${baseUrl}/versions/create`}
                  disabled={
                    !currentPermissions[
                      PIPELINE_PERMISSIONS["version_create"]
                    ] || currentOrganization.status !== "active"
                  }
                >
                  Create version
                </SecondaryButton>
              </Box>
            </Box>
          }
        />
      )}

      <DeleteDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onDelete={() => handleDeleteVersion(selectedVersion?.version)}
      >
        Are you sure you want to delete version {`"`}
        <b>{selectedVersion?.version}</b>
        {`"`}?
      </DeleteDialog>
      <ActionDialog
        actionButtonText="Make default"
        onClose={() => setDefaultDialogOpen(false)}
        onAction={() => {
          handleDefaultVersionChange(selectedVersion?.version);
          setDefaultDialogOpen(false);
        }}
        open={defaultDialogOpen}
      >
        {explanations.defaultVersionDialog.message(selectedVersion?.version)}
      </ActionDialog>
    </>
  );
};

export default PipelineVersionsOverview;
