import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { PageHeader } from "components/molecules/PageLayout";
import {
  FIELD_DEFAULT_MONITORING_GROUP_ENABLED,
  FIELD_MONITORING_GROUP_ENABLED,
  FIELD_VERSION,
} from "libs/constants/fields";
import {
  usePipelineVersionsGet,
  usePipelineVersionsList,
} from "libs/data/endpoints/pipelines/pipelines";
import { gtmEvent } from "libs/third-parties";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";
import { routes } from "routes";

import { FormWrapper } from "components/atoms";
import { PageContainer } from "components/molecules";

import { useCreatePipelineVersion } from "./useCreatePipelineVersion";
import { PipelineVersionBaseForm } from "../PipelineVersionBaseForm";

import type { PipelineVersionRouteParams } from "./types";
import type { PipelineVersionBaseProps } from "../PipelineVersionBaseForm/constants";
import type { AxiosError } from "axios";
import type { FormikHelpers } from "formik";

export const PipelineVersionDuplicate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { projectName, pipelineName, versionName, organizationName } =
    useParams<PipelineVersionRouteParams>();
  const { data: pipelineVersion } = usePipelineVersionsGet(
    projectName,
    pipelineName,
    versionName
  );
  const { mutate } = usePipelineVersionsList(
    projectName,
    pipelineName,
    undefined,
    {
      swr: {
        swrKey: `/projects/${projectName}/pipelines/${pipelineName}/versions`,
      },
    }
  );

  const duplicatePipelineVersion = useCreatePipelineVersion();

  const handleOnSubmit = async (
    data: any,
    actions: FormikHelpers<PipelineVersionBaseProps>
  ) => {
    try {
      await duplicatePipelineVersion(data);
      await mutate();
    } catch (e: unknown) {
      const error = e as AxiosError;
      createErrorNotification(error.message);

      return;
    }
    actions.setSubmitting(false);
    gtmEvent("pipeline_version_duplicate", {
      event_category: "pipeline_versions",
    });
    dispatch(createSuccessNotification("The pipeline version was duplicated"));
    history.push(
      routes.organizations[":organizationName"](organizationName)
        .projects[":projectName"](projectName)
        .pipelines[":pipelineName"](pipelineName)
        .index()
    );
  };

  return (
    <PageContainer>
      <PageHeader title={`Duplicate "${versionName}"`} />
      <FormWrapper>
        {pipelineVersion && (
          <PipelineVersionBaseForm
            onSubmit={handleOnSubmit}
            initialValues={{
              ...pipelineVersion,
              [FIELD_MONITORING_GROUP_ENABLED]: Boolean(
                pipelineVersion?.monitoring
              ),
              [FIELD_DEFAULT_MONITORING_GROUP_ENABLED]: Boolean(
                pipelineVersion?.default_notification_group
              ),
              [FIELD_VERSION]: `${versionName}-copy`,
            }}
            submitText="Duplicate"
          />
        )}
      </FormWrapper>
    </PageContainer>
  );
};
